import clsx from 'clsx';
import { Markdown, Typography } from '@eucalyptusvc/design-system';

import type { MessageProps } from './types';
import { formatRelativeTime } from './utils';
import React from 'react';
import { MediaAttachment } from '../media-attachment';

export const MessageBubble = React.forwardRef<HTMLDivElement, MessageProps>(
  (
    {
      className,
      type = 'received',
      senderName,
      date,
      chatTheme,
      children,
      isFirst,
      isLast,
      isImage,
      attachments,
      consultationId,
      consultationQuestionId,
    },
    ref,
  ): JSX.Element => {
    const bubbleClasses = clsx(
      chatTheme.bubbleClasses,
      type === 'received' && chatTheme.doctorBubbleClasses,
      type === 'sent' && !isImage && chatTheme.selfBubbleClasses,
      ((type === 'sent' && isImage) || type === 'reminder') &&
        chatTheme.systemBubbleClasses,
      'mb-1',
    );

    return (
      <div ref={ref} className={clsx(className)}>
        {isFirst && senderName && (
          <div className={'mb-2'}>
            <Typography
              isBold
              size="paragraph"
              element="p"
              textAlign={type === 'sent' ? 'right' : 'left'}
            >
              {senderName}
            </Typography>
          </div>
        )}
        <div className={bubbleClasses}>
          {typeof children === 'string' ? (
            <Markdown src={children} />
          ) : (
            children
          )}
        </div>
        {attachments && attachments.length > 0 ? (
          <div className="my-2 space-y-2">
            {attachments.map((attachment) => (
              <MediaAttachment
                key={attachment.id}
                content={attachment}
                consultationId={consultationId}
                consultationQuestionId={consultationQuestionId}
              />
            ))}
          </div>
        ) : null}
        {date && isLast && (
          <div className={'mb-4'}>
            <Typography
              size="small-text"
              element="p"
              textAlign={type === 'sent' ? 'right' : 'left'}
            >
              {formatRelativeTime(date)}
            </Typography>
          </div>
        )}
      </div>
    );
  },
);

MessageBubble.displayName = 'MessageBubble';
