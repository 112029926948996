import { ReactElement } from 'react';
import { Layout } from './layout';
import { PageHeader } from './page-header';
import { AddressForm } from './address-form';
import { getConfig } from '@customer-frontend/config';
import { useGetDefaultClinicianNounByCountryCode } from '../logic/cp-redesign-utils';
import { useIntl } from 'react-intl';
import { WhyWeNeedAddress } from './why-we-need-address';
import { ProblemType } from '@customer-frontend/graphql-types';
import { useShowZendeskWidget } from '@customer-frontend/consultation';

type CollectAddressPageProps = {
  className?: string;
  country: string;
  onSubmit: (validAddress: boolean) => void;
  validateAddressAsShipping?: boolean;
  isSyncExperience?: boolean;
  problemType?: ProblemType;
};

export const CollectAddressPage = ({
  className,
  country,
  onSubmit,
  validateAddressAsShipping,
  isSyncExperience,
  problemType,
}: CollectAddressPageProps): ReactElement => {
  useShowZendeskWidget({ problemType });

  const config = getConfig();
  const { formatMessage } = useIntl();
  const clinicianByCountryCode = useGetDefaultClinicianNounByCountryCode(
    config.countryCode,
  );

  const description = isSyncExperience
    ? formatMessage({
        defaultMessage:
          'Enter the address linked to your Medicare card or Individual Healthcare Identifier',
      })
    : formatMessage(
        {
          defaultMessage:
            'We require this to generate an electronic prescription if your {clinicianByCountryCode} prescribes treatment. If your shipping address is different, you will be able to specify it later.',
        },
        {
          clinicianByCountryCode,
        },
      );

  return (
    <Layout className={className || 'py-10 md:py-12'}>
      {
        <PageHeader
          title={formatMessage({
            defaultMessage: "What's your residential address?",
          })}
          description={description}
        />
      }
      {isSyncExperience && <WhyWeNeedAddress />}
      <AddressForm
        country={country}
        submitButtonText={formatMessage({
          defaultMessage: 'Confirm address',
        })}
        afterSubmit={onSubmit}
        validateAddressAsShipping={
          validateAddressAsShipping &&
          !isSyncExperience &&
          problemType === 'WEIGHT_LOSS'
        }
      />
    </Layout>
  );
};
