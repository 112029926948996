import React from 'react';
import { MultivariateFlagKey } from '../config';
import { useFeatureFlagClient } from './provider';

interface Props {
  children: React.ReactNode;
  flagKey: MultivariateFlagKey;
  truthyFlagValues: string[];
}

export const FeatureFlagMultivariate = ({
  children,
  flagKey,
  truthyFlagValues,
}: Props): React.ReactElement | null => {
  const client = useFeatureFlagClient();
  const flagValue = client.getMultivariate(flagKey, {
    disableExposureTracking: true,
  });
  const enabled = truthyFlagValues.includes(flagValue);
  return enabled ? <>{children}</> : null;
};
