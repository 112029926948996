import {
  Button,
  ButtonPalette,
  Card,
  Typography,
} from '@eucalyptusvc/design-system';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { gql, useSuspenseQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  OrderConfirmedPageQuery,
  OrderConfirmedPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { useTitle, useUrlQuery } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';

const PAGE_QUERY = gql`
  query OrderConfirmedPage($consultationId: String!) {
    profile {
      id
      email
    }
    consultation(id: $consultationId) {
      id
      status
    }
  }
`;

type OrderConfirmedProps = {
  children?: React.ReactNode;
  profileRoute: string;
  palette?: {
    confirmButton?: ButtonPalette;
  };
  extraContent?: JSX.Element;
};

export const OrderConfirmed = ({
  children,
  profileRoute,
  palette,
  extraContent,
}: OrderConfirmedProps): React.ReactElement => {
  const config = getConfig();
  const history = useHistory();
  const { consultationId } = useParams<{ consultationId: string }>();
  const query = useUrlQuery();
  const paidWithZIP = query.get('gateway') === 'ZIP';
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Order confirmed',
      description: 'Page title for the Order confirmed page',
    }),
  );

  const { data } = useSuspenseQuery<
    OrderConfirmedPageQuery,
    OrderConfirmedPageQueryVariables
  >(PAGE_QUERY, {
    errorPolicy: 'all',
    variables: {
      consultationId,
    },
  });
  const profile = data?.profile;
  const consultation = data?.consultation;

  if (!profile || consultation?.status !== 'CUSTOMER_COMPLETED') {
    return <Redirect to={profileRoute} />;
  }

  return (
    <div className="max-w-screen-md pt-10 md:pt-14 gap-8 mx-auto flex flex-col">
      <Typography textAlign="center" isBold size="xl" element="h1">
        <FormattedMessage defaultMessage="Order confirmed" />
      </Typography>
      <Card palette="default">
        <div className="space-y-4">
          <Typography size="large-paragraph">
            <FormattedMessage
              defaultMessage="We've sent your order confirmation to: <strong>{email}</strong>"
              values={{
                email: profile.email,
                strong: (chunks) => (
                  <strong className="font-semibold">{chunks}</strong>
                ),
              }}
            />
          </Typography>
          <Typography size="large-paragraph">
            <FormattedMessage
              defaultMessage="You will receive a tracking code via email when your order ships from our {showPartner, select, true {partner } other {}}pharmacy."
              values={{ showPartner: config.brand !== 'juniper-uk' }}
            />
          </Typography>
        </div>
        {paidWithZIP && (
          <>
            <br />
            <Typography size="large-paragraph">
              <FormattedMessage
                defaultMessage=" Note: all the future payments from {brand} will be charged from your Zip account as a default payment method."
                values={{ brand: config.title }}
              />
            </Typography>
            <br />
            <Typography size="large-paragraph">
              <FormattedMessage defaultMessage="If you would like to change your default payment method, please visit your profile page." />
            </Typography>
          </>
        )}
        {extraContent}
      </Card>
      {children}
      <Button
        palette={palette?.confirmButton}
        isFullWidth
        onClick={() => history.push(profileRoute)}
      >
        <Typography size="large-paragraph">
          <FormattedMessage defaultMessage="Back to profile" />
        </Typography>
      </Button>
    </div>
  );
};
