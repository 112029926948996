import { Typography, BrandTheme } from '@eucalyptusvc/design-system';
import React from 'react';
import { useTheme } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as EditPen } from '../../../assets/payment-methods/edit-pen.svg';
import Sofort from '../../../assets/payment-methods/sofort.webp';

export function SofortPaymentBanner(props: {
  onEdit: () => void;
}): React.ReactElement | null {
  const { formatMessage } = useIntl();
  const theme = useTheme<BrandTheme>();

  const { onEdit } = props;

  return (
    <button
      className="flex flex-col p-3 border border-neutral-200 rounded space-y-3 w-full bg-white"
      aria-label={formatMessage({
        defaultMessage: 'Edit',
        description: 'Aria label for a button to edit payment method',
      })}
      onClick={onEdit}
    >
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row space-x-2">
          <img className="h-5 w-8" src={Sofort} />
          <Typography
            size="paragraph"
            isBold
            color={theme.colors.neutral['700']}
          >
            <FormattedMessage
              defaultMessage="Sofort"
              description="Sofort payment method"
            />
          </Typography>
        </div>
        <EditPen width={16} height={16} />
      </div>
    </button>
  );
}
