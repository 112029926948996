import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Card } from '@eucalyptusvc/design-system';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';

const DEFAULT_TITLE = 'You have successfully updated your payment details!';

const DEFAULT_DESCRIPTION =
  'You will now get access to new features on your subscription product.';

export type UpgradeSuccessProps = {
  icon: string;
  profileRoute: string;
  title?: string;
  description?: string;
};

export const UpgradeSuccess: React.FC<UpgradeSuccessProps> = ({
  icon,
  profileRoute,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
}) => {
  const history = useHistory();
  const config = getConfig();

  return (
    <Card>
      {/* Icon is purely decorative, no value is added by using alt text */}
      <div className="flex flex-col items-center mb-8 md:flex-row">
        <img src={icon} alt="" className="max-h-36 mx-auto mb-8 md:mb-0" />
        <div className="space-y-2 md:ml-8">
          <Typography size="lg" isBold>
            {title}
          </Typography>
          <Typography size="medium-paragraph">{description}</Typography>
        </div>
      </div>
      <Button
        level="primary"
        palette={getPrimaryButtonPalette(config.brand)}
        isFullWidth
        onClick={() => history.push(profileRoute)}
      >
        Go back to profile
      </Button>
    </Card>
  );
};
