import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSignupMutation } from '@customer-frontend/services';
import {
  TextInput,
  Button,
  Typography,
  Checkbox,
} from '@eucalyptusvc/design-system';

import {
  useRequiredValidation,
  useEmailValidation,
  useMinLengthValidation,
} from '@customer-frontend/utils';
import { SignupTemplateProps } from './types';
import { useEnvironment } from '@customer-frontend/environment';
import { FormattedMessage, useIntl } from 'react-intl';
import { getConfig } from '@customer-frontend/config';
import { IntlFullNameInput } from '@customer-frontend/intl';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

export const SignupTemplate = ({
  title,
  subtitle,
  submitText,
  buttonPalette,
  onComplete,
  onError,
  displayImpliedConsent = true,
  linkToLogin = '/login',
  linkToPrivacy = '/privacy',
}: SignupTemplateProps): React.ReactElement => {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const { landingPageUrl } = useEnvironment();
  const marketingConsentUpdatedUI = useFeatureFlagBoolean(
    'FF_MARKETING_CONSENT_UI_UPDATES',
  );
  const showMarketingConsentCheckbox =
    marketingConsentUpdatedUI && config.consentRequired;

  const [signupMutation, { loading }] = useSignupMutation({
    onCompleted: ({ signup }) => {
      if (signup?.token && signup?.user && onComplete) {
        onComplete(signup.user, signup.token);
      }
    },
    onError: (err) => {
      if (onError) {
        onError(err);
      }
    },
  });
  const defaultMarketingConsent = showMarketingConsentCheckbox
    ? true
    : undefined;

  const { register, handleSubmit, errors } = useForm<{
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    marketingConsent: boolean | undefined;
  }>({
    defaultValues: {
      marketingConsent: defaultMarketingConsent,
    },
  });

  const emailLabel = formatMessage({
    defaultMessage: 'Email',
  });
  const emailValidation = {
    ...useRequiredValidation(emailLabel),
    ...useEmailValidation(),
  };
  const passwordValidationLabel = formatMessage({ defaultMessage: 'Password' });
  const passwordValidation = {
    ...useRequiredValidation(passwordValidationLabel),
    ...useMinLengthValidation(passwordValidationLabel, 8),
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        signupMutation({
          variables: {
            email: data.email,
            password: data.password,
            firstName: data.firstName,
            lastName: data.lastName,
            id: undefined,
            marketingCommsConsent: data.marketingConsent,
          },
        });
      })}
      noValidate
    >
      {title && (
        <div className="mb-2">
          <Typography isBold size="xl" element="h1" textAlign="center">
            {title}
          </Typography>
        </div>
      )}
      {subtitle && (
        <div className="mb-8">
          <Typography size="large-paragraph" textAlign="center">
            {subtitle}
          </Typography>
        </div>
      )}
      <IntlFullNameInput
        register={register}
        errors={errors}
        className="mb-3 gap-3 md:mb-6 md:gap-4"
      />
      <div className="mb-3 md:mb-6">
        <TextInput
          ref={register(emailValidation)}
          label={emailLabel}
          name="email"
          type="email"
          errorMessage={errors?.email?.message}
          required
          palette={buttonPalette}
        />
      </div>
      <div className="mb-6">
        <TextInput
          ref={register(passwordValidation)}
          label={passwordValidationLabel}
          name="password"
          type="password"
          errorMessage={errors?.password?.message}
          required
          palette={buttonPalette}
        />
      </div>
      {displayImpliedConsent && (
        <div className="text-center mb-5">
          <Typography size="small-text" element="p">
            <FormattedMessage
              defaultMessage="By clicking below you agree to our <a>Terms & Conditions</a> and <b>Privacy Policy</b>"
              values={{
                a: (chunks) => (
                  <a
                    href={`${landingPageUrl}${config.urlPaths.termsAndConditions}`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline cursor-pointer font-semibold"
                  >
                    {chunks}
                  </a>
                ),
                b: (chunks) => (
                  <a
                    href={`${landingPageUrl}${linkToPrivacy}`}
                    target="_blank"
                    rel="noreferrer"
                    className="underline cursor-pointer font-semibold"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
        </div>
      )}
      {showMarketingConsentCheckbox && (
        <div className="mb-6">
          <Checkbox
            isChecked={defaultMarketingConsent ?? false}
            disabled={loading}
            ref={register}
            name="marketingConsent"
            label={
              <Typography size="paragraph">
                {formatMessage(
                  {
                    defaultMessage:
                      'Please share exclusive offers and news about {brandTitle}’s products and services with me.',
                    description:
                      'Checkbox label for opting in to receive marketing emails',
                  },
                  { brandTitle: config.title },
                )}
              </Typography>
            }
          />
        </div>
      )}
      <Button
        level="primary"
        palette={buttonPalette}
        isLoading={loading}
        isSubmit
        isFullWidth
      >
        {submitText}
      </Button>
      <div className="text-center mt-6">
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="Already have an account? <a>Log in here</a>"
            values={{
              a: (chunks) => (
                <Link to={linkToLogin} className="text-link font-semibold">
                  {chunks}
                </Link>
              ),
            }}
          />
        </Typography>
      </div>
    </form>
  );
};
