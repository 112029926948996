import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import {
  CancelSubscriptionV2MutationResult,
  CancelSubscriptionV2MutationVariables,
} from '@customer-frontend/graphql-types';

const cancelSubscriptionV2Document = gql`
  mutation CancelSubscriptionV2($subscriptionId: String!) {
    cancelSubscriptionV2(subscriptionId: $subscriptionId) {
      id
    }
  }
`;

export function useCancelSubscriptionV2(
  options?: MutationHookOptions<
    CancelSubscriptionV2MutationResult,
    CancelSubscriptionV2MutationVariables
  >,
): MutationTuple<
  CancelSubscriptionV2MutationResult,
  CancelSubscriptionV2MutationVariables
> {
  return useMutation<
    CancelSubscriptionV2MutationResult,
    CancelSubscriptionV2MutationVariables
  >(cancelSubscriptionV2Document, options);
}
