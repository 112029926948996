import { Spinner as ChakraSpinner } from '@chakra-ui/react';
import { useThemeExtension } from '../../theme/shared';
import { LoadingSpinnerPalette, LoadingSpinnerProps } from './types';

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ palette }) => {
  // If component explicitly has `palette` passed, use that. Otherwise, use
  // the value from the brand's theme extension config, or default if none.
  const colorProfile = useThemeExtension<LoadingSpinnerPalette>(
    ['global.defaultColorProfile', 'loading-spinner.defaultColorProfile'],
    'default',
  );
  const variant = palette || colorProfile;

  return <ChakraSpinner speed="0.7s" thickness="0.188rem" variant={variant} />;
};
