import { getConfig } from '@customer-frontend/config';
import { getReadableBrandName } from '@customer-frontend/utils';
import { Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

export const EscriptsConsent: React.FC = () => {
  const config = getConfig();
  return (
    <div style={{ marginTop: '1rem' }}>
      <Typography size="medium-paragraph" isBold>
        <FormattedMessage
          defaultMessage="{brand} will provide your electronic script token directly to one of our network of community pharmacies for dispensing. The pharmacy will not upload any relevant records to your My Health Record unless you inform us otherwise by emailing <a>{email}</a>"
          description=""
          values={{
            brand: getReadableBrandName(config.brand),
            email: config.supportEmail,
            a: (chunks) => <a href={`mailto:${chunks}`}>{chunks}</a>,
          }}
        />
      </Typography>
    </div>
  );
};
