import {
  MutationRemoveTreatmentDiscountArgs,
  Treatment,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { discountCodeFormFragment } from '../graphql';

interface Response {
  treatment: Treatment;
}

const removeTreatmentDiscountMutation = gql`
  ${discountCodeFormFragment}
  mutation RemoveTreatmentDiscount($treatmentId: String!) {
    removeTreatmentDiscount(treatmentId: $treatmentId) {
      id
      treatmentDiscount {
        id
        discountCode {
          ...DiscountCodeForm
        }
      }
    }
  }
`;

export function useRemoveTreatmentDiscount(
  options: MutationHookOptions<Response, MutationRemoveTreatmentDiscountArgs>,
): MutationTuple<Response, MutationRemoveTreatmentDiscountArgs> {
  return useMutation<Response, MutationRemoveTreatmentDiscountArgs>(
    removeTreatmentDiscountMutation,
    options,
  );
}
