import { components } from './components';
import { BrandTheme } from '../types';
import { colors } from './palette';

const theme: BrandTheme = {
  colors,
  components,
  extensions: {
    global: {
      defaultColorProfile: 'default',
    },
    accordion: {
      defaultStyle: 'default',
    },
    avatar: {
      defaultStyle: 'default',
    },
    toast: {
      defaultStyle: 'default',
    },
    'loading-spinner': {
      defaultColorProfile: 'alternate',
    },
    'image-upload': {
      textColor: colors.secondary.navy[300],
      placeholderColor: colors.primary[400],
      selectButtonStyle: 'secondary',
      selectButtonPalette: 'default',
      submitButtonPalette: 'default',
      iconStyle: 'bold',
    },
  },
};

export default theme;
