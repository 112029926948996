import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '@customer-frontend/auth';
import { Typography } from '@eucalyptusvc/design-system';
import { ProblemType, QuizPurpose } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';

export interface ContinueQuizProps {
  isReview: boolean;
  isFollowUp: boolean;
  problemType?: ProblemType;
  purpose?: QuizPurpose;
}

export const ContinueQuiz = (): React.ReactElement => {
  const { loggedInUser } = useAuth();
  return (
    <div className="space-y-6">
      <Typography size="xl" element="h1" isBold>
        <FormattedMessage
          defaultMessage="Welcome back, {name}!"
          description="Welcome message using the customers real name"
          values={{ name: loggedInUser?.shortAddressableName }}
        />
      </Typography>
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="It looks like you haven't completed your consult. Answer a few more questions to help your {isGb, select, true {prescriber} other {practitioner}} determine what treatment will work for you."
          values={{ isGb: getConfig().countryCode === 'GB' }}
        />
      </Typography>
    </div>
  );
};
