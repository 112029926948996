import React from 'react';
import clsx from 'clsx';
import { EducationReading } from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { ContentfulContentType } from '@customer-frontend/contentful';
import { useEducationTheme } from '../../provider';
import { ReadStatusIcon } from './read-status-icon';

interface ReadingButtonProps {
  reading: EducationReading;
  onReadingClick: (readingId: string) => void;
}

export const ReadingButton = ({
  reading,
  onReadingClick,
}: ReadingButtonProps): React.ReactElement => {
  const theme = useEducationTheme();
  const listItemTheme = theme.brand.moduleAccordionItem?.listItem;
  const content = reading.content as ContentfulContentType;
  const title = content.fields?.title;

  const containerClassName = clsx(
    'p-4 w-full flex space-x-3 items-center justify-between',
    listItemTheme?.bgClassName,
  );

  const buttonClassName = clsx(
    'w-8 h-8 flex flex-none items-center justify-center border rounded',
    listItemTheme?.button.bgClassName,
    listItemTheme?.button.borderClassName,
  );

  return (
    <button
      onClick={() => onReadingClick(reading.id)}
      className={containerClassName}
    >
      <div className="flex space-x-4 items-center text-left">
        {reading.readStatus && (
          <div className="flex-none">
            <ReadStatusIcon status={reading.readStatus} />
          </div>
        )}
        <Typography size="medium-paragraph" color={listItemTheme?.textColor}>
          {title}
        </Typography>
      </div>
      <div className={buttonClassName}>
        <div className="h-4">{listItemTheme?.button.chevronIcon}</div>
      </div>
    </button>
  );
};
