import { FunctionComponent, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  LoadingSpinner,
  Typography,
  Button,
  CardPalette,
  ButtonPalette,
  AccordionPalette,
} from '@eucalyptusvc/design-system';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import { useTitle } from '@customer-frontend/utils';
import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import { useAuth } from '@customer-frontend/auth';
import {
  FaqPanel,
  FloatingMessage,
  SendMessage,
} from '@customer-frontend/consultation';
import type { Logger } from '@customer-frontend/logger';

import { ConsultationNotesTabs } from '../notes-tabs';
import { DoctorLetter } from './doctor-letter';
import { gql, useQuery } from '@apollo/client';
import {
  DoctorsNotePageQuery,
  DoctorsNotePageQueryVariables,
} from '@customer-frontend/graphql-types';

export type DoctorsNoteProps = {
  consultationId: string;
  routes: {
    consultation: {
      doctorsNote: string;
      pharmacyInformation: string;
      plan: string;
    };
    profile: string;
  };
  logger: Logger;
  palette?: {
    doctorLetter?: {
      bio?: CardPalette;
      letter?: CardPalette;
    };
    button?: ButtonPalette;
    faq?: AccordionPalette;
  };
  customContent?: React.ReactElement | null;
};

export const DoctorsNotePage: FunctionComponent<DoctorsNoteProps> = ({
  consultationId,
  routes,
  logger,
  palette,
  customContent,
}) => {
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Practitioner Letter',
      description: 'Page title for the Practitioner Letter',
    }),
  );

  const { loggedInUser } = useAuth();

  useHistoryGoBackBehaviour();
  const history = useHistory();

  const { data, loading } = useQuery<
    DoctorsNotePageQuery,
    DoctorsNotePageQueryVariables
  >(
    gql`
      query DoctorsNotePage($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          status
          type
          doctor {
            id
            avatar {
              id
              url
            }
            provider {
              id
              clinicianTitle
              qualifications
            }
            shortClinicianName
            fullClinicianName
            bio
          }
          completedAt
          treatment {
            id
            createdAt
            updatedAt
            product {
              id
              faqs {
                id
                markdown
                title
              }
            }
          }
          patientNotes
        }
      }
    `,
    { variables: { consultationId } },
  );

  const {
    consultation,
    treatment,
    doctor,
    patientNotes,
    prescribedDate,
    faqs,
  } = useMemo(() => {
    const consultation = data?.consultation;
    const { treatment, doctor } = consultation ?? {};

    return {
      consultation,
      treatment,
      doctor,
      patientNotes: consultation?.patientNotes,
      prescribedDate: treatment?.updatedAt ?? treatment?.createdAt,
      faqs: treatment?.product?.faqs ?? [],
    };
  }, [data]);

  const { clinicianMessagingSupported } = getConfig();
  const { isSyncExperience } =
    useConsultationFlowConfig(consultation?.type) ?? {};
  const enableDoctorMessage = clinicianMessagingSupported && !isSyncExperience;

  const onSubmitSendMessage = useCallback(() => {
    setTimeout(() => {
      history.push(routes.profile);
    }, 1500);
  }, [history, routes.profile]);

  const handleButtonClick = useCallback(
    () => history.push(routes.consultation.plan),
    [history, routes.consultation.plan],
  );

  if (loading) {
    return (
      <div className="flex justify-center p-8">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !consultation ||
    !treatment ||
    !doctor ||
    !patientNotes ||
    !prescribedDate
  ) {
    // should be unreachable, useConsultation should be providing all of these
    logger.error('doctors-note: data dependencies missing', {
      consultation: !!consultation,
      treatment: !!treatment,
      doctor: !!doctor,
      patientNotes: !!patientNotes,
      userId: loggedInUser?.id,
      consultationId,
    });

    return (
      <div className="flex justify-center p-8">
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Please refresh the page or try contacting support, there was an issue loading your practitioners note." />
        </Typography>
      </div>
    );
  }

  return (
    <div className="space-y-8 mb-8">
      <ConsultationNotesTabs
        routes={{
          doctorsNote: routes.consultation.doctorsNote,
          pharmacyInformation: routes.consultation.pharmacyInformation,
        }}
        variant="border-around"
      />

      <DoctorLetter
        doctor={doctor}
        patientNotes={patientNotes}
        prescribedDate={prescribedDate}
        palette={palette?.doctorLetter}
      />

      {customContent}

      {enableDoctorMessage && (
        <SendMessage
          doctor={doctor}
          consultationId={consultationId}
          onSubmit={onSubmitSendMessage}
        />
      )}

      <Button isFullWidth onClick={handleButtonClick} palette={palette?.button}>
        <FormattedMessage
          defaultMessage="Back to treatment plan"
          description="Button text indicating back to the treatment plan page"
        />
      </Button>

      <section className="space-y-4">
        <Typography size="lg" isBold>
          <FormattedMessage
            defaultMessage="FAQs"
            description="Acronym describing frequently asked questions"
          />
        </Typography>

        <FaqPanel faqs={faqs} accordionPalette={palette?.faq} />
      </section>

      {enableDoctorMessage && (
        <FloatingMessage
          doctor={doctor}
          consultationId={consultationId}
          onSubmit={onSubmitSendMessage}
        />
      )}
    </div>
  );
};
