import { getConfig } from '@customer-frontend/config';
import { useUpdateResidentialAddressMutation } from '@customer-frontend/services';
import { Address } from '@customer-frontend/graphql-types';

export const useUpdateResidentialAddress = (): {
  loading: boolean;
  updateResidentialAddress: (residentialAddress?: Address) => Promise<void>;
} => {
  const config = getConfig();
  const [updateResidentialMutation, { loading: updateRDLoading }] =
    useUpdateResidentialAddressMutation();

  const updateResidentialAddress = async (
    residentialAddress?: Address,
  ): Promise<void> => {
    if (residentialAddress) {
      await updateResidentialMutation({
        variables: {
          residentialAddress: {
            line1: residentialAddress.line1 ?? '',
            line2: residentialAddress.line2 || undefined,
            city: residentialAddress.city ?? '',
            postalCode: residentialAddress.postalCode ?? '',
            state: residentialAddress.state || undefined,
            country: config.country,
          },
        },
      });
    }
  };

  return {
    loading: updateRDLoading,
    updateResidentialAddress,
  };
};
