import React from 'react';

export const useIntersectionObserver = (
  ref: React.RefObject<Element>,
  params?: {
    root?: Element | Document | null;
    rootMargin?: string;
    threshold?: number | number[];
  },
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

  React.useEffect(() => {
    if (!window.IntersectionObserver || !ref.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]): void => {
      setEntry(entry);
    }, params);

    observer.observe(ref.current);

    return (): void => observer.disconnect();
  }, [params?.root, params?.rootMargin, params?.threshold, ref, params]);

  return entry;
};
