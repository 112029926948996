import React from 'react';
import clsx from 'clsx';
import { EducationModule } from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { useEducationTheme } from '../../provider';
import { ReadingButton } from '../reading-button';
import { TimeToRead } from '../time-to-read';
import { calculateTotalReadingTime, convertMsToMinutes } from '../utils';

interface ModuleAccordionItemProps {
  module: EducationModule;
  onReadingClick: (readingId: string) => void;
  openByDefault?: boolean;
}

export const ModuleAccordionItem = ({
  module,
  onReadingClick,
  openByDefault = false,
}: ModuleAccordionItemProps): React.ReactElement | null => {
  const [open, setOpen] = React.useState(openByDefault);
  const theme = useEducationTheme();
  const { border, button, dividerClassName, timeToRead } =
    theme.brand.moduleAccordionItem ?? {};
  const readings = module.readings;

  if (!readings?.length) {
    return null;
  }

  const containerClassName = clsx(
    'border',
    'overflow-hidden',
    border?.colorClassName,
    border?.radiusClassName,
  );

  const buttonClassName = clsx(
    'p-4 w-full flex justify-between items-center space-x-3',
    border?.colorClassName,
    button?.bgClassName,
    {
      'border-b': open,
    },
  );

  const iconClassName = clsx(
    'w-8 h-8 flex flex-none items-center justify-center transform transition-transform ',
    {
      'rotate-180': open,
    },
  );

  const timeToReadModuleMs = calculateTotalReadingTime(readings);
  const timeToReadModuleMinutes = convertMsToMinutes(timeToReadModuleMs);

  return (
    <div className={containerClassName}>
      <button
        id={`${module.id}-button`}
        onClick={() => setOpen(!open)}
        className={buttonClassName}
        aria-expanded={open}
        aria-controls={`${module.id}-panel`}
      >
        <div className="flex flex-col space-y-2 text-left">
          <Typography size="xs" element="h3" isBold color={button?.textColor}>
            {module.name}
          </Typography>
          <TimeToRead
            icon={timeToRead?.icon}
            colorClassName={timeToRead?.color}
            minutes={timeToReadModuleMinutes}
          />
        </div>
        <div className={iconClassName}>{button?.icon}</div>
      </button>
      {open && (
        <ul
          id={`${module.id}-panel`}
          aria-labelledby={`${module.id}-button`}
          role="region"
          className={clsx('divide-y', dividerClassName)}
        >
          {readings.map((reading) => (
            <li key={reading.id}>
              <ReadingButton
                reading={reading}
                onReadingClick={onReadingClick}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
