import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headingsAllowValue: {
    defaultMessage: 'Allow',
    description:
      'Header for the column where the user will allow or not their consent to store cookies per category',
  },
  headingsCategoryValue: {
    defaultMessage: 'Category',
    description:
      "Header for the column where we'll display the consent category",
  },
  headingsPurposeValue: {
    defaultMessage: 'Purpose',
    description:
      "Header for the column where we explain what we'll use the coookies for",
  },
  headingsToolsValue: {
    defaultMessage: 'Tools',
    description:
      'Header for the column where list the tools relevant to the cookie setting',
  },
  checkboxesNoValue: {
    defaultMessage: 'No',
    description: 'Checkbox label for the opt-out option for the cookie setting',
  },
  checkboxesYesValue: {
    defaultMessage: 'Yes',
    description: 'Checkbox label for the opt-in option for the cookie setting',
  },
  actionButtonsCancelValue: {
    defaultMessage: 'Cancel',
    description: 'Button text to close the cookie settings dialog',
  },
  actionButtonsSaveValue: {
    defaultMessage: 'Save',
    description:
      'Button text to confirm the selected cookie settings in the cookie settings dialog',
  },
  cancelDialogButtonsCancelValue: {
    defaultMessage: 'Yes, Cancel',
    description:
      'Button text on the cancel dialog to confirm that the user wants to close the cookie settings dialog',
  },
  cancelDialogButtonsBackValue: {
    defaultMessage: 'Go Back',
    description:
      'Button text on the cancel dialog to cancel the operation and go back to the cookie settings dialog',
  },
  categoryFunctionalName: {
    defaultMessage: 'Functional',
    description: "The name of the 'functional' category",
  },
  categoryFunctionalDescription: {
    defaultMessage:
      'To monitor the performance of our site and to enhance your browsing experience.',
    description: 'The reason we ask to store cookies for functional purposes.',
  },
  categoryFunctionalExample: {
    defaultMessage:
      'For example, these tools enable you to communicate with us via live chat.',
    description: "Usage example for the 'functional' category.",
  },
  categoryMarketingName: {
    defaultMessage: 'Marketing',
    description: "The name of the 'Marketing' category",
  },
  categoryMarketingDescription: {
    defaultMessage:
      'To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.',
    description: 'The reason we ask to store cookies for Marketing purposes.',
  },
  categoryMarketingExample: {
    defaultMessage:
      'For example, we collect information about which pages you visit to help us present more relevant information.',
    description: "Usage example for the 'Marketing' category.",
  },
  categoryAdvertisingName: {
    defaultMessage: 'Advertising',
    description: "The name of the 'Advertising' category",
  },
  categoryAdvertisingDescription: {
    defaultMessage:
      'To personalize and measure the effectiveness of advertising on our site and other websites.',
    description: 'The reason we ask to store cookies for Advertising purposes.',
  },
  categoryAdvertisingExample: {
    defaultMessage:
      'For example, we may serve you a personalized ad based on the pages you visit on our site.',
    description: "Usage example for the 'Advertising' category.",
  },
  categoryEssentialName: {
    defaultMessage: 'Essential',
    description: "The name of the 'Essential' category",
  },
  categoryEssentialDescription: {
    defaultMessage:
      'We use browser cookies that are necessary for the site to work as intended.',
    description: 'The reason we ask to store cookies for Essential purposes.',
  },
  categoryEssentialExample: {
    defaultMessage:
      'For example, we store your website data collection preferences so we can honor them if you return to our site. You can disable these cookies in your browser settings but if you do the site may not work as intended.',
    description: "Usage example for the 'Essential' category.",
  },
});
