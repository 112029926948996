import { getConfig } from '@customer-frontend/config';
import { Logger } from '@customer-frontend/logger';
import { useNotification } from '@eucalyptusvc/design-system';
import { useState, useEffect } from 'react';
import { getLoadErrorMessage } from './utils';

interface TranslationMap<T> {
  [key: string]: T;
}

export const useMessages = (
  locale: string,
  getTranslationFile?: (
    locale: string,
  ) => Promise<Record<string, unknown>> | undefined,
  logger?: Logger,
): {
  messages: TranslationMap<string>;
  loading: boolean;
} => {
  const config = getConfig();
  const notify = useNotification();
  const [loading, setLoading] = useState<boolean>(!!getTranslationFile);
  const [messages, setMessages] = useState<TranslationMap<string>>({});

  useEffect((): void => {
    setLoading(true);
    const translationPromise = getTranslationFile?.(locale);

    // No translation fetching necessary
    if (!translationPromise) {
      setLoading(false);
      return;
    }

    translationPromise
      .then(({ default: _, ...translations }) => {
        const parsedTranslations = translations as Record<string, string>; // Necessary for parsing JSON
        setMessages(parsedTranslations);
        setLoading(false);
      })
      .catch(() => {
        notify.error({ message: getLoadErrorMessage(locale) });
        logger?.error(`Failed to load language pack for ${config.brand}`);
        setLoading(false);
      });
  }, [locale, getTranslationFile, config, logger, notify]);

  return { messages, loading };
};
