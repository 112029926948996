import { useQuery, gql, QueryResult, DocumentNode } from '@apollo/client';
import { CustomerAttributes } from '@customer-frontend/graphql-types';
import { customerAttributesFragment } from '../graphql/fragments';

export function useCustomerAttributes(
  fragment: DocumentNode = customerAttributesFragment,
): QueryResult<{
  customerAttributes: CustomerAttributes;
}> {
  return useQuery<{ customerAttributes: CustomerAttributes }>(
    gql`
      query CustomerAttributes {
        customerAttributes {
          id
          ...CustomerAttributesInfo
        }
      }
      ${fragment}
    `,
  );
}
