import React, { useState } from 'react';
import { OrganImpactListItems, OrganImpactListItem } from './types';
import { ArrowRight } from '../assets/icons/arrow-right';
import { Modal, Typography } from '@eucalyptusvc/design-system';
import clsx from 'clsx';

const ListItem = ({
  item,
  arrowColor,
}: {
  item: OrganImpactListItem;
  arrowColor: string; // tailwind text color class
}): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const handleModalToggle = (): void => {
    setShowModal(!showModal);
  };

  const organText = item.organText ?? item.organ;

  return (
    <>
      <button
        className="w-full flex flex-row justify-around"
        onClick={handleModalToggle}
      >
        <div className="my-auto px-3">{item.image}</div>
        <div className="w-4/5 pl-2 flex flex-col items-start justify-center">
          <Typography size="medium-paragraph" isBold>
            {organText}
          </Typography>
          <Typography size="medium-paragraph">
            {item.shortDescription}
          </Typography>
        </div>
        <div className={clsx(['my-auto', arrowColor])}>
          <ArrowRight />
        </div>
      </button>
      <Modal
        isOpen={showModal}
        title={`How GLP-1 impacts your ${organText.toLowerCase()}`}
        onClose={handleModalToggle}
      >
        <div className="m-auto p-6 w-min my-4">
          {React.cloneElement(item.image, { isLarge: true })}
        </div>
        <Typography size="medium-paragraph">{item.longDescription}</Typography>
      </Modal>
    </>
  );
};

export const OrganImpactList = ({
  items,
  arrowColor,
}: {
  items: OrganImpactListItems;
  arrowColor: string; // tailwind text color class
}): JSX.Element => (
  <div className="space-y-6 w-full">
    {items.map((item) => (
      <ListItem
        key={item.shortDescription}
        item={item}
        arrowColor={arrowColor}
      />
    ))}
  </div>
);
