import { IUiStorage } from './IUiStorage';
import { storageAvailable } from './storageAvailable';
import { UiStorageType } from './type';

class LocalUiStorage implements IUiStorage {
  isSupported(): boolean {
    return storageAvailable('localStorage');
  }
  getType(): UiStorageType {
    return 'local_storage';
  }
  public setValue(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  public getValue(key: string): string | undefined {
    const value = window.localStorage.getItem(key) ?? undefined;
    if (value && value.startsWith('"') && value.endsWith('"')) {
      return value.substring(1, value.length - 1);
    }
    return value;
  }

  public clearValue(key: string): void {
    window.localStorage.removeItem(key);
  }

  public clear(): void {
    window.localStorage.clear();
  }
}

export const localUiStorage = new LocalUiStorage();
