import React from 'react';
import { useStyleConfig, Box } from '@chakra-ui/react';

import { TypographyProps } from './types';
import { getElementFromSize } from './utils';
import { useThemeExtension } from '../../theme/shared';
import { useResponsive } from '../../hooks';

/** Used to display text and headings.
 *
 *  NOTE: the style is determined by a combination of all props, as well as
 *  what is defined in the design system. Some brands don't allow certain
 *  combinations, e.g. Pilot doesn't have a `size="2xl"` & `isBold={false}`
 *  combo. If you select this combination, it will default to the closest
 *  match, e.g. 2XL Bold.
 *
 *  Also be aware that the component accepts `size` and `element` values that
 *  apply to both headings and text. Please make sure both selections match
 *  either heading or text. Mixing and matching won't work.
 */
export const Typography = ({
  size,
  color,
  element,
  children,
  textAlign,
  decoration,
  isBold = false,
  isItalic = false,
  inheritColor = false,
}: TypographyProps): React.ReactElement => {
  const elementType = element || getElementFromSize(size);
  const isHeading = !['p', 'span'].includes(elementType);
  const { isMobile } = useResponsive();

  const themeKey = isHeading ? 'Heading' : 'Text';
  const styles = useStyleConfig(themeKey, {
    isBold,
    isMobile,
    isItalic,
    variant: size,
  });

  const defaultColor = useThemeExtension<string | undefined>(
    'text.color',
    undefined,
  );

  const fontColor = inheritColor ? undefined : color || defaultColor;

  return (
    <Box
      as={elementType}
      color={fontColor}
      textAlign={textAlign}
      textDecoration={decoration}
      __css={styles}
      className="chakra-text"
    >
      {children}
    </Box>
  );
};
