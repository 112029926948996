import {
  formatCentsToCurrency,
  getProductPricePerDayString,
} from '@customer-frontend/order';
import {
  BillingInterval,
  Maybe,
  Plan,
  Product,
  ProductUsage,
} from '@customer-frontend/graphql-types';
import moment from 'moment';
import pluralize from 'pluralize';
import { useIntl } from 'react-intl';

export const getTickItems = (
  showOnDemandCopy: boolean,
  shippingCycle?: string | null,
): string[] => {
  const tickItemsCopy = [
    'Free discreet shipping',
    'Includes ongoing practitioner support',
    'Cancel anytime ',
  ];
  if (showOnDemandCopy && shippingCycle) {
    tickItemsCopy.unshift(`Ships every ${shippingCycle}`);
  }
  return tickItemsCopy;
};

export const getDemandPerDayCost = (
  total?: number,
  units?: number,
): number | undefined => {
  if (units && total) {
    return total / units;
  }
  return undefined;
};

/**
 * @deprecated please use `useGetShippingCycle` to supprt i18n strings
 */
export const getShippingCycle = (plan: Partial<Plan>): string | undefined => {
  const interval = plan?.interval && planInterval(plan);
  const intervalCount = plan.intervalCount;
  if (interval && intervalCount) {
    // Correctly pluralize, omit count if singular e.g. "month", "2 months", "12 days", etc
    return pluralize(interval, intervalCount, intervalCount > 1);
  }
  return undefined;
};

export const useGetShippingCycle = (
  plan?: {
    interval?: BillingInterval | null;
    intervalCount?: number | null;
  } | null,
): string | undefined => {
  const interval = usePlanInterval(plan);
  const intervalCount = plan?.intervalCount;
  if (interval && intervalCount) {
    // Correctly pluralize, omit count if singular e.g. "month", "2 months", "12 days", etc
    return pluralize(interval, intervalCount, intervalCount > 1);
  }
  return undefined;
};

export const getIsOnDemand = (product?: {
  usage?: ProductUsage | null;
}): boolean => {
  return product?.usage === 'OnDemand';
};

/**
 * @deprecated please use `usePlanInterval` to support i18n strings
 */
const planInterval = (
  plan?: { interval?: BillingInterval | null } | null,
): moment.unitOfTime.Diff | undefined => {
  switch (plan?.interval) {
    case 'Daily':
      return 'day';
    case 'Monthly':
      return 'month';
    case 'Weekly':
      return 'week';
    default:
      return undefined;
  }
};

export const usePlanInterval = (
  plan?: { interval?: BillingInterval | null } | null,
): string | undefined => {
  const { formatMessage } = useIntl();

  switch (plan?.interval) {
    case 'Daily':
      return formatMessage({ defaultMessage: 'day' });
    case 'Monthly':
      return formatMessage({ defaultMessage: 'month' });
    case 'Weekly':
      return formatMessage({ defaultMessage: 'week' });
    default:
      return undefined;
  }
};

export const getInitialShipmentCopy = (
  isOnDemand: boolean,
  numberOfMonthsTillDate: string | null | undefined,
  units: Maybe<number>,
): string => {
  if (isOnDemand && Number.isInteger(units)) {
    return `Initial shipment contains ${units} tablets`;
  }
  if (!isOnDemand && numberOfMonthsTillDate) {
    return `Initial shipment contains a ${numberOfMonthsTillDate} supply.`;
  }
  return '';
};

export const getTimeDiffFromPlan = (
  plan: {
    interval?: BillingInterval | null | undefined;
    intervalCount?: number | null | undefined;
  },
  interval: moment.unitOfTime.Diff,
): number => {
  const scheduledDate = getNextScheduledDate(plan);

  return Math.round(scheduledDate.diff(moment.now(), interval, true));
};

const getNextScheduledDate = (plan: {
  interval?: BillingInterval | null;
  intervalCount?: number | null;
}): moment.Moment => {
  const { interval, intervalCount } = plan;
  const when = moment.utc();
  switch (interval) {
    case 'Daily':
      when.add(intervalCount, 'days');
      break;
    case 'Weekly':
      when.add(intervalCount, 'weeks');
      break;
    case 'Monthly':
      when.add(intervalCount, 'months');
      break;

    default:
      break;
  }
  return when;
};

export const getTimeDiffFromPlanWithInterval = (
  plan: {
    interval?: BillingInterval | null;
    intervalCount?: number | null;
  },
  interval: moment.unitOfTime.Diff,
  pluralize?: boolean,
): string => {
  const scheduledDate = getNextScheduledDate(plan);
  const diff = scheduledDate.diff(moment.now(), interval, true);
  return `${Math.round(diff)} ${interval}${pluralize && diff > 1 ? 's' : ''}`;
};

export const getNumberOfMonthsTillDate = (
  plan?: {
    interval?: BillingInterval | null;
    intervalCount?: number | null;
  } | null,
  pluralize?: boolean,
): string | undefined => {
  if (plan && plan.intervalCount) {
    const interval = planInterval(plan);
    if (interval === 'day' && plan.intervalCount > 31) {
      return getTimeDiffFromPlanWithInterval(plan, 'month', pluralize);
    }
    return (
      interval && getTimeDiffFromPlanWithInterval(plan, interval, pluralize)
    );
  }
  return undefined;
};

export const getCostPerUnit = (product: Product): string => {
  const plan = product?.plan;
  const totalCost = plan?.amount ?? 0;
  const isOnDemand = getIsOnDemand(product);

  if (isOnDemand) {
    const units = product?.units;
    const demandPerDayCost = units && getDemandPerDayCost(totalCost, units);
    if (demandPerDayCost) {
      return `${formatCentsToCurrency(demandPerDayCost)} / tablet`;
    } else {
      return '';
    }
  } else {
    const dailyPerDayCost = getProductPricePerDayString(product);
    return dailyPerDayCost;
  }
};
