import React, { useRef } from 'react';

import { useScript } from '@customer-frontend/utils';
import { Video, WistiaVideoProps } from './types';

const WISTIA_SOURCE = 'https://fast.wistia.com/assets/external/E-v1.js';

export const useInitialiseWistia = (props: WistiaVideoProps): void => {
  const videoRef = useRef<Video>();
  useScript(WISTIA_SOURCE);
  useScript(`https://fast.wistia.com/embed/medias/${props.videoId}.jsonp`);

  React.useEffect(() => {
    if (!window._wq) {
      window._wq = [];
    }
    window._wq.push({
      id: props.videoId,
      onReady: (video: Video): void => {
        videoRef.current = video;

        video.bind('play', () => {
          props?.onPlay?.(
            video.hashedId(),
            video.name(),
            video.percentWatched(),
          );
        });

        video.bind('pause', () => {
          props?.onPause?.(video.hashedId(), video.percentWatched());
        });

        video.bind('percentwatchedchanged', (percent: number) => {
          props?.onPercentageChange?.(video.hashedId(), percent);
        });

        video.bind('end', () => {
          props?.onEnd?.(video.hashedId());
        });
      },
    });

    return () => {
      if (videoRef.current) {
        videoRef.current.unbind('play');
        videoRef.current.unbind('pause');
        videoRef.current.unbind('percentwatchedchanged');
        videoRef.current.unbind('end');
      }
    };
  }, [props]);
};
