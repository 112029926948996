import { getConfig } from '@customer-frontend/config';
import { QuizOutcomeReason } from '@customer-frontend/graphql-types';
import { useIntl } from 'react-intl';

export type RejectedReasonWithExplanation = {
  reason: QuizOutcomeReason;
  heading: string;
  paragraphs: React.ReactNode[];
};

export const useRejectReasons = (): RejectedReasonWithExplanation[] => {
  const { formatMessage } = useIntl();
  const config = getConfig();
  return [
    {
      reason: 'AGE_YOUNG',
      heading: formatMessage({ defaultMessage: 'Under 18 years old' }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            'Adolescents are recommended to seek specialist care through their local community GP for managing weight at this age.',
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'AGE_OLD',
      heading: formatMessage({ defaultMessage: 'Over 75 years old' }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            "You aren't within the age range (18-75) when weight loss medication can be safely prescribed.",
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'AGE_OLD_75',
      heading: formatMessage({ defaultMessage: 'Over 75 years old' }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            "You aren't within the age range (18-75) when weight loss medication can be safely prescribed.",
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'AGE_OLD_85',
      heading: formatMessage({ defaultMessage: 'Over 84 years old' }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            'You are not within the age range (18-84) for which weight loss medication can be safely prescribed.',
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'BMI',
      heading: formatMessage({ defaultMessage: 'BMI too low' }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            'Your BMI is outside our typical range to be safely prescribed weight loss medication. If you are concerned about your weight or body image, we would recommend speaking to your community GP as there may be suitable options available for you.',
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'MENTAL_HEALTH_CONDITIONS',
      heading: formatMessage({ defaultMessage: 'Mental health' }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            "You shared that you've experienced self harm and/or suicidal thoughts. We thank you for being honest with us. Unfortunately, Juniper isn't set up to appropriately support your mental health.",
        }),
        formatMessage({
          defaultMessage:
            "We're limited in the monitoring we can provide via a telehealth platform and have to adhere to TGA guidelines closely.",
        }),
        formatMessage({
          defaultMessage:
            "If you're at risk of harm or need someone to talk to, there are immediate options available.",
        }),
        ...(config.emergencyNumber
          ? [
              formatMessage(
                {
                  defaultMessage: '{emergencyNumber} - Emergency call',
                },
                {
                  emergencyNumber: config.emergencyNumber,
                },
              ),
            ]
          : []),
        ...(config.externalSupportNumber
          ? [
              formatMessage(
                {
                  defaultMessage:
                    '{externalSupportNumber} - {externalSupportLabel}',
                },
                {
                  externalSupportNumber: config.externalSupportNumber,
                  externalSupportLabel: config.externalSupportLabel,
                },
              ),
            ]
          : []),
        ...(config.countryCode === 'AU'
          ? [
              formatMessage({
                defaultMessage: '1300 22 4636 - Beyond Blue chat`,',
              }),
            ]
          : []),
        formatMessage({
          defaultMessage:
            'There are always options available, please consider seeking advice from your GP.',
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'PREGNANCY',
      heading: formatMessage({
        defaultMessage: 'Pregnancy',
      }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            'Weight loss medication is not suitable for use during pregnancy. Your community GP, midwife or obstetrician is best placed to assist you if you are worried about your weight during your pregnancy.',
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
    {
      reason: 'PREGNANCY_OR_BREASTFEEDING',
      heading: formatMessage({
        defaultMessage: 'Pregnancy or breastfeeding',
      }),
      paragraphs: [
        formatMessage({
          defaultMessage:
            "Significant weight loss isn't encouraged for people who are trying for a baby, pregnant or breastfeeding.",
        }),
        formatMessage<React.ReactNode>(
          {
            defaultMessage:
              'You are welcome to chat with our care team to find out more about this situation by <a>contacting us here</a>.',
          },
          {
            a: (str) => (
              <a
                className="text-link"
                target="_blank"
                rel="noreferrer"
                href={config.rejectionContactLink}
              >
                {str}
              </a>
            ),
          },
        ),
      ],
    },
  ];
};
