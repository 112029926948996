import React from 'react';
import { useLocation } from 'react-router-dom';
import { uiStorages } from '@customer-frontend/ui-storage';
const DISCOUNT_CODE_QUERY_STRING = 'discountCode';
const DISCOUNT_CODE_STORAGE_KEY = 'discountCodeFromQueryString';

interface PersistedDiscountFromURL {
  persistedDiscountCode: string | null;
  clearCode: () => void;
}

export const clearDiscountParams = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete(DISCOUNT_CODE_QUERY_STRING);
  const paramString = params.toString();
  const newUrl = paramString
    ? `${window.location.pathname}?${paramString}`
    : window.location.pathname;
  window.history.replaceState({}, '', newUrl);
};

const PersistedDiscountCodeFromQueryStringContext =
  React.createContext<PersistedDiscountFromURL>({
    persistedDiscountCode: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearCode: () => {},
  });

export const usePersistedDiscountFromURL = (): PersistedDiscountFromURL =>
  React.useContext(PersistedDiscountCodeFromQueryStringContext);

type Props = {
  children: React.ReactNode;
};

export const PersistedDiscountFromURLProvider: React.FC<Props> = ({
  children,
}) => {
  const [code, setCode] = React.useState<string | null>(
    uiStorages.session.getValue(DISCOUNT_CODE_STORAGE_KEY) ?? null,
  );
  const { search } = useLocation();
  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search],
  );

  // The main purpose of this hook is to persist the code even if its removed from the query string
  // So only set react state when there is a discountCode value in the query string
  React.useEffect(() => {
    const codeFromQueryString = searchParams.get(DISCOUNT_CODE_QUERY_STRING);
    if (codeFromQueryString) {
      setCode(codeFromQueryString);
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (code) {
      uiStorages.session.setValue(DISCOUNT_CODE_STORAGE_KEY, code);
    } else {
      uiStorages.session.clearValue(DISCOUNT_CODE_STORAGE_KEY);
    }
  }, [code]);

  // Don't return a new clearCode callback each time the code changes
  const clearCode = React.useCallback(() => {
    setCode(null);
  }, []);

  const value = React.useMemo<PersistedDiscountFromURL>(
    () => ({
      persistedDiscountCode: code,
      clearCode,
    }),
    [code, clearCode],
  );

  return (
    <PersistedDiscountCodeFromQueryStringContext.Provider value={value}>
      {children}
    </PersistedDiscountCodeFromQueryStringContext.Provider>
  );
};
