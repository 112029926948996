import {
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  type BackgroundProps,
} from '@chakra-ui/react';
import { useResponsive } from '../../hooks';
import { DrawerProps } from './types';

/**
 * The Drawer component is a panel that slides out from the edge of the screen.
 * It can be useful when you need users to complete a task or view some details without leaving the current page.
 */
export const Drawer = ({
  isOpen,
  children,
  onClose,
  size = 'sm',
  onCloseComplete,
  placement,
  overlayStrength = 'dark',
}: DrawerProps): JSX.Element => {
  const { isMobile } = useResponsive();

  let backgroundColor: BackgroundProps['backgroundColor'];

  switch (overlayStrength) {
    case 'light':
      backgroundColor = 'blackAlpha.200';
      break;
    case 'medium':
      backgroundColor = 'blackAlpha.400';
      break;
    case 'dark':
      backgroundColor = 'blackAlpha.600';
      break;
  }

  return (
    <ChakraDrawer
      isOpen={isOpen}
      size={isMobile ? 'xs' : size}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
      placement={placement}
    >
      <DrawerOverlay backgroundColor={backgroundColor} />
      <DrawerContent overflow="auto">
        <DrawerCloseButton />
        {children}
      </DrawerContent>
    </ChakraDrawer>
  );
};
