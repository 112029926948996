import React, { useContext } from 'react';
import { ThemeContext, getBrandTheme } from './theme';
import { Brand } from '@customer-frontend/types';

export const TrackerThemeContext = React.createContext<ThemeContext>({});

export const useTrackerTheme = (): ThemeContext =>
  useContext(TrackerThemeContext);

export const TrackerThemeProvider = ({
  children,
  brand,
}: {
  children: React.ReactNode;
  brand: Brand;
}): React.ReactElement => {
  return (
    <TrackerThemeContext.Provider
      value={{
        brand: getBrandTheme(brand),
      }}
    >
      {children}
    </TrackerThemeContext.Provider>
  );
};
