import React from 'react';
import { LinkButton } from '@eucalyptusvc/design-system';

import type { ReviewHeaderProps } from './types';
import { DoctorInfo } from '../doctor-info';
import { DoctorModal } from '../doctor-modal';

export const ReviewHeader = ({
  doctor,
  details,
  removeDrPrefix,
  chatTheme,
}: ReviewHeaderProps): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  const hasBio = Boolean(doctor.bio);

  return (
    <div className={chatTheme.doctorInfoClasses}>
      <DoctorInfo
        doctor={doctor}
        details={details}
        removeDrPrefix={removeDrPrefix}
        palette={{ avatar: 'alternate' }}
      >
        {hasBio && (
          <div className="mt-1">
            <LinkButton
              palette={chatTheme.drViewPalette}
              size="sm"
              onClick={handleOpenModal}
            >
              View full profile
            </LinkButton>
          </div>
        )}
      </DoctorInfo>
      {hasBio && (
        <DoctorModal
          isOpen={isModalOpen}
          doctor={doctor}
          details={details}
          removeDrPrefix={removeDrPrefix}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};
