import { StyleFunctionProps, SystemStyleObject } from '@chakra-ui/theme-tools';
import { getWithFallbacks } from '../../shared/fonts';

export const text = {
  baseStyle: (props: StyleFunctionProps): SystemStyleObject => ({
    fontFamily: getWithFallbacks('Inter'),
    fontWeight: props.isBold ? 600 : 400,
    lineHeight: '130%',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontStyle: props.isItalic ? 'italic' : 'normal',
  }),
  variants: {
    'landing-paragraph': {
      fontSize: '1.5rem', // 24px
    },
    'large-paragraph': {
      fontSize: '1.125rem', // 18px
    },
    'medium-paragraph': {
      fontSize: '1rem', // 16px
    },
    paragraph: {
      fontSize: '0.875rem', // 14px
    },
    'small-text': {
      fontSize: '0.75rem', // 12px
    },
  },
};

export const heading = {
  baseStyle: {
    wordBreak: 'break-word',
  },
  variants: {
    '2xl': (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: getWithFallbacks('Right Grotesk'),
      fontSize: props.isMobile ? '2.5rem' : '3rem',
      fontWeight: 700,
      lineHeight: '90%',
    }),
    xl: (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: props.isBold
        ? getWithFallbacks('Right Grotesk')
        : getWithFallbacks('P22 Mackinac Pro'),
      fontSize: props.isMobile ? '2rem' : '2.5rem',
      fontWeight: props.isBold ? 700 : 400,
      lineHeight: props.isBold ? '90%' : '100%',
      letterSpacing: props.isBold ? '' : '-0.03em',
    }),
    lg: (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: props.isBold
        ? getWithFallbacks('Right Grotesk')
        : getWithFallbacks('P22 Mackinac Pro'),
      fontSize: props.isMobile ? '1.5rem' : '2rem',
      fontWeight: props.isBold ? 700 : 400,
      lineHeight: props.isBold ? '90%' : '100%',
      letterSpacing: props.isBold ? '' : '-0.03em',
    }),
    md: (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: props.isBold
        ? getWithFallbacks('Right Grotesk')
        : getWithFallbacks('P22 Mackinac Pro'),
      fontSize: props.isMobile ? '1.25rem' : '1.5rem',
      fontWeight: props.isBold ? 700 : 400,
      lineHeight: props.isBold ? '90%' : '100%',
      letterSpacing: props.isBold ? '' : '-0.03em',
    }),
    sm: (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: getWithFallbacks('Right Grotesk'),
      fontSize: props.isMobile ? '1.125rem' : '1.25rem',
      fontWeight: 700,
      lineHeight: '90%',
    }),
    xs: (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: getWithFallbacks('Inter'),
      fontSize: props.isMobile ? '1rem' : '1.125rem',
      fontWeight: props.isBold ? 600 : 400,
      lineHeight: '130%',
    }),
  },
};
