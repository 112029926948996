import {
  Button,
  Card,
  Typography,
  Modal,
  ButtonLevel,
} from '@eucalyptusvc/design-system';
import { useMemo, useState } from 'react';
import { notificationService } from '@customer-frontend/notifications';
import { SubscriptionPlan } from '@customer-frontend/graphql-types';
import {
  useCancelSubscriptionPlan,
  useSubscriptionPlans,
  formatSubscriptionPlan,
} from '@customer-frontend/services';

interface SubscriptionItemProps {
  subscriptionPlan: SubscriptionPlan;
  cancelButtonLevel?: ButtonLevel;
}

export const SubscriptionItem = ({
  subscriptionPlan,
  cancelButtonLevel = 'secondary',
}: SubscriptionItemProps): React.ReactElement => {
  const { refetch } = useSubscriptionPlans();

  const {
    product,
    supplyAmount,
    billingDescription,
    nextPaymentDate,
    cancelledAt,
    id,
    isActive,
  } = useMemo(
    () => formatSubscriptionPlan(subscriptionPlan),
    [subscriptionPlan],
  );

  const [cancelSubscription] = useCancelSubscriptionPlan({
    onCompleted: () => {
      notificationService.show({
        type: 'success',
        message: 'Subscription cancelled',
      });
      setShowModal(false);
      refetch();
    },
    onError: () => {
      notificationService.show({
        type: 'error',
        message: 'Unable to cancel subscription',
      });
    },
  });

  const handleModalOnClick = (): void => {
    cancelSubscription({
      variables: {
        subscriptionId: id,
      },
    });
  };
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <Card>
        <div className="flex flex-row justify-between">
          <div>
            <div className="pb-2">
              <Typography size="sm">{product.name}</Typography>
            </div>
            <Typography size="medium-paragraph">
              <b>{product.price}&nbsp;/&nbsp;</b>
              {supplyAmount}
            </Typography>
            {isActive ? (
              <>
                <Typography size="medium-paragraph">
                  {billingDescription}
                </Typography>
                <Typography size="medium-paragraph">
                  Next payment {nextPaymentDate}
                </Typography>
              </>
            ) : (
              <Typography size="medium-paragraph">{cancelledAt}</Typography>
            )}
          </div>
          <div className="h-24 w-24 ml-4 rounded-md">
            {product.photo?.url && (
              <img
                className="h-24 w-auto rounded-md"
                src={product.photo?.url}
              />
            )}
          </div>
        </div>
        {isActive && (
          <div className="mt-4">
            <Button
              isFullWidth
              level={cancelButtonLevel}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Cancel subscription
            </Button>
          </div>
        )}
      </Card>
      <Modal
        title={
          <Typography isBold size="md">
            Subscription
          </Typography>
        }
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        <div className="mt-2 space-y-4">
          <div className="flex flex-row">
            <div className="h-16 w-16 ml-4 bg-primary-100 rounded-md">
              {product.photo?.url && (
                <img
                  className="h-16 w-auto rounded-md"
                  src={product.photo?.url}
                />
              )}
            </div>
            <div className="pl-4 my-auto">
              <Typography size="sm">{product.name}</Typography>
              <Typography size="medium-paragraph">
                <b>{product.price}&nbsp;/&nbsp;</b>
                {supplyAmount}
              </Typography>
            </div>
          </div>
          {isActive && (
            <>
              <Typography size="medium-paragraph">
                If you cancel your subscription now, your next shipment
                scheduled on {nextPaymentDate} will also be cancelled.
              </Typography>
              <Button isFullWidth onClick={handleModalOnClick}>
                Cancel subscription
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
