export const space = {
  '2xs': '0.25rem',
  xs: '0.5rem',
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '3rem',
  '2xl': '4rem',
  '3xl': '6rem',
  '4xl': '7.5em',
};

export type ThemeSpacing = keyof typeof space;
