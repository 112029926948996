import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Modal,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { gql, useMutation } from '@apollo/client';
import {
  DelayPurchaseSyncGroupMutation,
  DelayPurchaseSyncGroupMutationVariables,
} from '@customer-frontend/graphql-types';
import { format } from 'date-fns-tz';

const delayPurchaseSyncGroupMutation = gql`
  mutation DelayPurchaseSyncGroup($input: DelayPurchaseSyncGroupInput!) {
    delayPurchaseSyncGroup(input: $input) {
      purchaseSyncGroup {
        id
        status
        sequenceContexts {
          id
          status
        }
      }
    }
  }
`;

type DelayModalProps = {
  onClose: () => void;
  upcomingOrder: { date: string; syncGroupId: string; delayableUntil: string };
  onDelaySuccess: () => void;
  onDelayError: () => void;
};

function toInputString(date: Date): string {
  return format(date, `yyyy-MM-dd'T'HH:mm`);
}

export const DelayModal = ({
  onClose,
  upcomingOrder: { date, delayableUntil, syncGroupId },
  onDelaySuccess,
  onDelayError,
}: DelayModalProps): React.ReactElement => {
  const { formatMessage, formatDate } = useIntl();
  const notify = useNotification();

  const [delayPurchaseSyncGroup, { loading: delayPurchaseSyncGroupLoading }] =
    useMutation<
      DelayPurchaseSyncGroupMutation,
      DelayPurchaseSyncGroupMutationVariables
    >(delayPurchaseSyncGroupMutation, {
      context: {
        skipErrorNotification: true,
      },
    });

  const [dateToDelayTo, setDateToDelayTo] = useState<string>(
    toInputString(new Date(date)),
  );
  const isoTimestampToDelayTo = new Date(dateToDelayTo).toISOString();

  const isInvalidDateToDelayTo =
    isNaN(new Date(dateToDelayTo).getTime()) ||
    isoTimestampToDelayTo < date ||
    isoTimestampToDelayTo > delayableUntil;

  return (
    <Modal
      isOpen
      onClose={onClose}
      title={
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Delay your next order"
            description="Modal title for delaying upcoming order from plan"
          />
        </Typography>
      }
    >
      <div className="space-y-4">
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="You can delay your next order for up to two weeks."
            description="Text on delay next order modal explaining the maximum amount of time an order can be delayed by"
          />
        </Typography>

        <div className="p-4 bg-primary-100 rounded">
          <Typography size="paragraph">
            <FormattedMessage
              defaultMessage="<strong>Please note:</strong> Delaying changes the date of your next order and all subsequent orders."
              description="Text on delay next order modal warning about the side effects of delaying the next order"
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </div>

        <input
          type="datetime-local"
          min={toInputString(new Date(date))}
          max={toInputString(new Date(delayableUntil))}
          value={dateToDelayTo}
          onChange={(e) => setDateToDelayTo(e.target.value)}
          className="border rounded p-2"
        />

        {isInvalidDateToDelayTo && (
          <Typography size="paragraph" color="red">
            <FormattedMessage
              defaultMessage="Please select a time between {minDate} and {maxDate}."
              description="validation message for delay upcoming order"
              values={{
                minDate: formatDate(date, {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                }),
                maxDate: formatDate(delayableUntil, {
                  dateStyle: 'medium',
                  timeStyle: 'short',
                }),
              }}
            />
          </Typography>
        )}

        <Button
          isSubmit
          isFullWidth
          isDisabled={isInvalidDateToDelayTo}
          isLoading={delayPurchaseSyncGroupLoading}
          onClick={async () => {
            try {
              await delayPurchaseSyncGroup({
                variables: {
                  input: {
                    purchaseSyncGroupId: syncGroupId,
                    targetTime: isoTimestampToDelayTo,
                  },
                },
              });
              notify.success({
                message: formatMessage({
                  defaultMessage: 'Your upcoming order has been delayed',
                  description:
                    'Success message after the user delays their upcoming order',
                }),
              });
              onDelaySuccess();
              onClose();
            } catch {
              onDelayError();
              notify.error({
                message: formatMessage({
                  defaultMessage:
                    'Your request to delay your upcoming order has failed. Please try again.',
                  description:
                    'Error message when user attempts to delay their upcoming order and fails',
                }),
              });
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Confirm"
            description="Button text to confirm delaying upcoming order"
          />
        </Button>
      </div>
    </Modal>
  );
};
