import { useForm } from 'react-hook-form';
import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import {
  useConsultation,
  useMoveConsultationPaidToOrderPaid,
} from '@customer-frontend/services';
import {
  Button,
  Divider,
  Dropdown,
  useNotification,
} from '@eucalyptusvc/design-system';
import { useHistory } from 'react-router-dom';
import {
  getDevDoctorOptions,
  getDevProductOptions,
} from './skip-doctor-approve-options';

export const SkipDoctorApproveButton: React.FC<{
  consultationId: string;
  profilePath: string;
}> = ({ consultationId, profilePath }) => {
  const config = getConfig();
  const environment = useEnvironment();
  const history = useHistory();
  const notify = useNotification();

  const { data: consultationData, refetch } = useConsultation(
    consultationId,
    undefined,
    undefined,
    'cache-only',
  );
  const { register, getValues } = useForm();

  const doctorOptions = getDevDoctorOptions(config.brand);
  const productOptions = getDevProductOptions(
    config.brand,
    consultationData?.consultation.type,
  );

  const onCompleted = (): void => {
    notify.success({ message: 'Successfully skipped consultation ahead' });
    history.push(profilePath);
    refetch();
  };

  const [moveConsultationPaidToOrderPaidMutation, { loading }] =
    useMoveConsultationPaidToOrderPaid({
      onCompleted,
    });

  if (environment.isProd) {
    return null;
  }

  const onSubmit = async (): Promise<void> => {
    const values = getValues();
    moveConsultationPaidToOrderPaidMutation({
      variables: {
        consultationId,
        doctorId: values.doctorId || null,
        productId: values.productId || null,
      },
    });
  };

  return (
    <div className="mt-6">
      <Divider variant="separator" />
      <form>
        <Dropdown
          ref={register}
          label="Dev Clinician"
          name="doctorId"
          options={doctorOptions}
        />
        <Dropdown
          ref={register}
          label="Dev Product"
          name="productId"
          options={productOptions}
        />
      </form>
      <div className="mt-4" />
      <Button
        level="primary"
        palette={'alternate'}
        isLoading={loading}
        isFullWidth
        onClick={onSubmit}
      >
        {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
        Skip to Order Paid
      </Button>
    </div>
  );
};
