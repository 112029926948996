/**
 * Reason to call it uiStorage rather than storage is to distinguish between localStorage with localUiStorage
 */
export const LongLivedUiStorageTypes = ['local_storage', 'cookie'] as const;

export const UiStorageTypes = [
  ...LongLivedUiStorageTypes,
  'in_mem',
  'session_storage',
] as const;

export type UiStorageType = (typeof UiStorageTypes)[number];
export type LongLivedUiStorageType = (typeof LongLivedUiStorageTypes)[number];

const uiStorageTypeSet = new Set<string>(UiStorageTypes);

export const isUiStorageType = (s: string): s is UiStorageType => {
  return uiStorageTypeSet.has(s);
};

export const toUiStorageType = (
  s: string | undefined,
): UiStorageType | null => {
  if (s && isUiStorageType(s)) {
    return s;
  }
  return null;
};
