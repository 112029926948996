import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { useInSegment } from '@customer-frontend/services';
import { Tab, TabContainer } from './components';
import { DashboardNavRoute } from './types';
import {
  getDashboardNavRouteFromPathname,
  useEducationPlatformEnabled,
} from './utils';
import { useIntl } from 'react-intl';

export const DashboardNav = ({
  weightLossOrderCount,
}: {
  weightLossOrderCount?: number;
}): React.ReactElement | null => {
  const history = useHistory();
  const location = useLocation();
  const { formatMessage } = useIntl();
  const routeFromPath = getDashboardNavRouteFromPathname(location.pathname);

  const isEducationPlatformEnabled =
    useEducationPlatformEnabled(weightLossOrderCount);
  const showHelpTab = useFeatureFlagBoolean('WEIGHT_SIDE_EFFECTS_FAQ');

  const { data: learnTabActionNotRequired } = useInSegment({
    variables: {
      segmentName: 'WEIGHT_DASHBOARD_LEARN_TAB_ACTION_NOT_REQUIRED',
    },
    skip: !isEducationPlatformEnabled,
  });

  const [activeTab, setActiveTab] = useState<DashboardNavRoute | undefined>(
    routeFromPath,
  );

  const handleOnChange = (navRoute: DashboardNavRoute): void => {
    setActiveTab(navRoute);
    history.push(navRoute);
  };

  if (!isEducationPlatformEnabled && !showHelpTab) {
    return null;
  }

  return (
    <TabContainer>
      <Tab
        label={formatMessage({ defaultMessage: 'Dashboard' })}
        onChange={(): void => handleOnChange(DashboardNavRoute.DASHBOARD)}
        isActive={activeTab === DashboardNavRoute.DASHBOARD}
      />
      {isEducationPlatformEnabled && (
        <Tab
          label={formatMessage({ defaultMessage: 'Learn' })}
          onChange={(): void => handleOnChange(DashboardNavRoute.LEARN)}
          isActive={activeTab === DashboardNavRoute.LEARN}
          actionRequired={learnTabActionNotRequired?.inSegment === false}
        />
      )}
      {showHelpTab && (
        <Tab
          label={formatMessage({ defaultMessage: 'Need help?' })}
          onChange={(): void => handleOnChange(DashboardNavRoute.FAQ)}
          isActive={activeTab === DashboardNavRoute.FAQ}
        />
      )}
    </TabContainer>
  );
};
