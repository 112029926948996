import { gql } from '@apollo/client';
import { useOtcSubscriptionPlanV2ByIdQuery as useOtcSubscriptionPlanV2ById } from '@customer-frontend/graphql-types';
import { subscriptionPlanV2Fragment } from './subscription-plan-v2-fragment';

export const subscriptionPlanV2Query = gql`
  query OtcSubscriptionPlanV2ById($id: String!) {
    subscriptionPlanV2ById(id: $id) {
      ...OtcSubscriptionPlanV2Info
    }
  }
  ${subscriptionPlanV2Fragment}
`;

export { useOtcSubscriptionPlanV2ById };
