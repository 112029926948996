import { MutationTuple, useApolloClient } from '@apollo/client';
import {
  AddUserToSegmentMutation,
  AddUserToSegmentMutationVariables,
} from '@customer-frontend/graphql-types';
import { updateSegmentLocalCache, useAddUserToSegment } from '../segmentation';

export const useCompleteJoinWeightFacebookCommunity = (): MutationTuple<
  AddUserToSegmentMutation,
  AddUserToSegmentMutationVariables
> => {
  const client = useApolloClient();
  return useAddUserToSegment({
    variables: {
      segmentName: 'WEIGHT_FACEBOOK_COMMUNITY_JOINED',
    },
    onCompleted: () => {
      updateSegmentLocalCache(client, 'WEIGHT_FACEBOOK_COMMUNITY_JOINED');
    },
  });
};
