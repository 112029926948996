import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { ReactComponent as ChatBubble } from '../assets/chat-bubble.svg';
import { ReactComponent as Community } from '../assets/star.svg';
import { ReactComponent as DoctorHat } from '../assets/doctor-hat.svg';
import { ReactComponent as ShoppingCart } from '../assets/shipping-cart.svg';

export function InclusionsList(): ReactElement {
  const config = getConfig();
  const itemClassName = 'flex items-center gap-2';

  return (
    <div className="flex flex-col gap-4">
      <Typography isBold size="paragraph">
        <FormattedMessage
          defaultMessage="What's included:"
          description="Confirm purchase page offering perks"
        />
      </Typography>

      <div className={itemClassName}>
        <ShoppingCart />
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="Free and discreet shipping"
            description="Confirm purchase page offering perk"
          />
        </Typography>
      </div>

      <div className={itemClassName}>
        <DoctorHat />
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="Ongoing {isPrescriber, select, true {prescriber} other {practitioner}} and medical support"
            description="Confirm purchase page offering perk"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
      </div>

      <div className={itemClassName}>
        <ChatBubble />
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="1:1 health coaching {hasMobileApp, select, true {in your pocket} other {}}"
            description="Confirm purchase page offering perk"
            values={{ hasMobileApp: config.hasMobileApp }}
          />
        </Typography>
      </div>

      <div className={itemClassName}>
        <Community />
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="Exclusive access to our member-only {hasMobileApp, select, true {app and} other {}} community"
            description="Confirm purchase page offering perk"
            values={{ hasMobileApp: config.hasMobileApp }}
          />
        </Typography>
      </div>
    </div>
  );
}
