export function cookieDisabled(): boolean {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled) {
    // Try to set the cookie, and test if it can be read. If it's still empty, cookies are disabled
    // Got the code from https://stackoverflow.com/questions/4603289/how-to-detect-that-javascript-and-or-cookies-are-disabled
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.includes('testcookie');
  }
  return !cookieEnabled;
}
