import { Divider as ChakraDivider, useStyleConfig } from '@chakra-ui/react';

import { DividerProps } from './types';
import { useGetThemeSize } from '../card/utils';
import { space } from '../../theme/shared/space';

export const Divider = ({
  mb,
  mt,
  my,
  size,
  palette = 'default',
  variant = 'divider',
}: DividerProps): React.ReactElement => {
  const isDivider = variant === 'divider';
  const dividerSize = useGetThemeSize(size);

  const styles = useStyleConfig('Divider', {
    palette,
    variant,
    size: space[dividerSize],
  });

  return (
    <ChakraDivider
      __css={styles}
      my={my && space[my]}
      mt={mt && space[mt]}
      mb={mb && space[mb]}
      width={isDivider ? 'auto' : undefined}
    />
  );
};
