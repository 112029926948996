import { colors as juniperColors } from '@eucalyptusvc/design-system/src/theme/juniper/palette';
import EmptyChartImageEnhanced from '../assets/juniper-empty-chart-enhanced.png';
import EmptyChartImageSimplified from '../assets/juniper-empty-chart-simplified.png';
import { TrackerBrandTheme } from './types';

export const theme: TrackerBrandTheme = {
  trackerChart: {
    customDot: {
      text: {
        colorClassName: 'text-primary-500',
        fontWeightClassName: 'font-normal',
        fontFamily: 'Atlas Grotesk',
        fontSize: 18,
      },
      fill: {
        defaultColorHex: '#dff49f',
        accentColorHex: '#d5bbe9',
      },
      stroke: {
        colorHex: '#133F26',
        width: 1,
      },
    },
    line: {
      colorClassName: 'text-primary-500',
      strokeWidth: 1,
    },
  },
  enhancedTrackerChart: {
    title: {
      color: juniperColors.primary[500],
    },
    text: {
      primaryColor: juniperColors.primary[500],
      secondaryColor: juniperColors.primary[500],
    },
    line: {
      color: juniperColors.secondary.purple[300],
      strokeWidth: 5,
    },
    averageLine: {
      color: juniperColors.primary[400],
      strokeWidth: 2,
    },
    dot: {
      stroke: juniperColors.secondary.purple[300],
      strokeWidth: 2,
      fill: juniperColors.neutral.white,
      radius: 6,
    },
    dotSelected: {
      stroke: juniperColors.primary[500],
      strokeWidth: 2,
      radius: 8,
      fill: juniperColors.neutral.white,
      outer: {
        radius: 16,
        fill: 'rgba(186, 130, 230, 0.5)',
      },
    },
    area: {
      fill: juniperColors.secondary.purple[100],
    },
    cursor: {
      color: juniperColors.primary[500],
    },
  },
  trackerCard: {
    palette: 'alternate',
    emptyState: {
      contentClassName: 'bg-secondary-lime-200 text-primary-500',
    },
  },
  trackerChartLayout: {
    borderColorHex: '#829473',
  },
  trackerContent: {
    titleClassName: 'text-primary-500',
    currentWeight: {
      titleClassName: 'text-primary-500',
      valueClassName: 'text-primary-500',
    },
    weightDifferencePercent: {
      textClassName: 'text-primary-500 font-normal',
      bg: {
        defaultClassName: 'bg-secondary-lime-200',
        accentClassName: 'bg-secondary-lime-100',
      },
    },
    dateClassName: 'text-primary-500',
    weightDecreaseClassName: 'text-success-500',
    weightIncreaseClassName: 'text-primary-500',
  },
  trackerChartEmptyState: {
    containerClassName: 'border border-primary-300 bg-primary-100',
    backgroundImage: {
      simplified: EmptyChartImageSimplified,
      enhanced: EmptyChartImageEnhanced,
    },
  },
};
