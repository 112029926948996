import { DeepPartial, useForm } from 'react-hook-form';
import { useUpdateProfileMutation } from '@customer-frontend/services';
import {
  Button,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';

import { requiredValidation, emailIsValid } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { IntlAddressInput } from '@customer-frontend/intl';

type OTCShippingDetailsFormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
    company: string;
    building: string;
  };
};

type OTCShippingDetailsFormProps = {
  defaultValues?: DeepPartial<OTCShippingDetailsFormData>;
  hideCancel?: boolean;
  onSubmitted?: () => void;
  onCancel: () => void;
};

const messages = defineMessages({
  firstName: {
    defaultMessage: 'First name',
  },
  lastName: {
    defaultMessage: 'Last name',
  },
  phone: {
    defaultMessage: 'Phone',
  },
  email: {
    defaultMessage: 'Email',
  },
});

export const OTCShippingDetailsForm = ({
  defaultValues,
  hideCancel = false,
  onSubmitted,
  onCancel,
}: OTCShippingDetailsFormProps): JSX.Element => {
  const config = getConfig();
  const notify = useNotification();

  const { formatMessage } = useIntl();

  const { formState, register, errors, handleSubmit } =
    useForm<OTCShippingDetailsFormData>({
      defaultValues,
    });

  const [updateProfile] = useUpdateProfileMutation();

  const onSubmit = async (data: OTCShippingDetailsFormData): Promise<void> => {
    try {
      await updateProfile({
        variables: {
          phone: data.phone,
          address: {
            ...data.address,
            country: config.country,
          },
        },
      });

      notify.success({
        message: formatMessage({
          defaultMessage: 'Personal and shipping details successfully updated',
        }),
      });

      onSubmitted?.();
    } catch (err) {
      const descriptions = getErrorMessageDescriptorsFromError(err);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    }
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-3">
        <Typography element="h3" size="sm" isBold>
          <FormattedMessage
            defaultMessage="Personal details"
            description="Title for the personal details section"
          />
        </Typography>
        <div className="flex flex-col space-y-3 md:flex-row md:space-y-0 md:gap-3">
          <TextInput
            ref={register({
              ...requiredValidation(
                formatMessage(messages.firstName).toLowerCase(),
              ),
            })}
            label={formatMessage(messages.firstName)}
            name="firstName"
            placeholder={formatMessage(messages.firstName)}
            errorMessage={errors?.firstName?.message}
            disabled
          />
          <TextInput
            ref={register({
              ...requiredValidation(
                formatMessage(messages.lastName).toLowerCase(),
              ),
            })}
            label={formatMessage(messages.lastName)}
            name="lastName"
            placeholder={formatMessage(messages.lastName)}
            errorMessage={errors?.lastName?.message}
            disabled
          />
        </div>
        <TextInput
          ref={register({
            ...requiredValidation(formatMessage(messages.email)),
            ...emailIsValid,
          })}
          label={formatMessage(messages.email)}
          name="email"
          type="email"
          placeholder={formatMessage(messages.email)}
          errorMessage={errors?.email?.message}
          disabled
        />
        <TextInput
          ref={register({
            ...requiredValidation(formatMessage(messages.phone).toLowerCase()),
          })}
          label={formatMessage(messages.phone)}
          name="phone"
          placeholder={formatMessage(messages.phone)}
          errorMessage={errors?.phone?.message}
        />
      </div>
      <div className="space-y-3">
        <Typography element="h3" size="sm" isBold>
          <FormattedMessage defaultMessage="Shipping details" />
        </Typography>
        <IntlAddressInput
          name="address"
          registerField={register}
          errors={errors?.address}
          useAutoComplete
        />
      </div>
      <div className="flex flex-col md:flex-row-reverse gap-2">
        <Button
          palette="alternate"
          isLoading={formState.isSubmitting}
          isFullWidth
          isSubmit
        >
          <FormattedMessage
            defaultMessage="Save"
            description="Text for the save button on the OTC shipping details page"
          />
        </Button>
        {!hideCancel && (
          <Button
            level="secondary"
            palette="alternate"
            isDisabled={formState.isSubmitting}
            isFullWidth
            onClick={onCancel}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              description="Text for the cancel button on the OTC shipping details page"
            />
          </Button>
        )}
      </div>
    </form>
  );
};
