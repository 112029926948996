import { useAuth } from '@customer-frontend/auth';
import { Typography } from '@eucalyptusvc/design-system';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { ReactComponent as Avatar } from '../../../assets/avatar.svg';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SideBar } from '../side-bar/side-bar';

export const UserSidebarAvatar = (): React.ReactElement => {
  const [isOpen, setOpen] = useState(false);
  const closeSidebar = (): void => setOpen(false);
  const toggleSidebar = (): void => setOpen((open) => !open);

  return (
    <>
      <UserAvatar onClick={toggleSidebar} />
      <SideBar isOpen={isOpen} onClose={closeSidebar} />
    </>
  );
};

const UserAvatar = ({
  onClick,
}: {
  onClick: () => void;
}): React.ReactElement => {
  const { loggedInUser } = useAuth();
  const { formatMessage } = useIntl();

  return (
    <button
      aria-label={formatMessage({
        description:
          'aria label for the button that redirects the user to their user profile',
        defaultMessage: 'profile menu',
      })}
      onClick={onClick}
    >
      <div className="flex items-center mx-2 sm:mx-3 p-3 h-full sm:h-auto">
        <span>
          <Avatar
            aria-label={formatMessage({
              description:
                'aria label for the user avatar displayed in the user profile button',
              defaultMessage: 'User avatar',
            })}
          />
        </span>
        {loggedInUser?.shortAddressableName && (
          <span className="hidden sm:block ml-2">
            <Typography
              size="medium-paragraph"
              color={sharedColors.neutral.white}
            >
              {loggedInUser.shortAddressableName}
            </Typography>
          </span>
        )}
      </div>
    </button>
  );
};
