import React from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  MutationStartQuizApplicationArgs,
  ProblemType,
  QuizApplication,
  QuizPurpose,
} from '@customer-frontend/graphql-types';

export type QuizApplicationInfo = {
  id: string;
  submittedAt: QuizApplication['submittedAt'];
  consultation?: {
    id: string;
  };
};

export function useStartQuizApplication(): (
  problemType: ProblemType,
  quizPurpose?: QuizPurpose | undefined,
  errorWhenFollowUpReminderNotDone?: boolean | undefined,
  skipErrorNotification?: boolean,
) => Promise<QuizApplicationInfo> {
  const [startQuizApplicationMutation] = useMutation<
    {
      quizApplicationInfo: QuizApplicationInfo;
    },
    MutationStartQuizApplicationArgs
  >(
    gql`
      mutation StartQuizApplication(
        $problemType: ProblemType!
        $quizPurpose: QuizPurpose
        $errorWhenFollowUpReminderNotDone: Boolean
      ) {
        quizApplicationInfo: startQuizApplication(
          problemType: $problemType
          quizPurpose: $quizPurpose
          errorWhenFollowUpReminderNotDone: $errorWhenFollowUpReminderNotDone
        ) {
          id
          submittedAt
          consultation {
            id
          }
        }
      }
    `,
  );

  const startQuizApplication = React.useCallback(
    async (
      problemType: ProblemType,
      quizPurpose?: QuizPurpose,
      errorWhenFollowUpReminderNotDone?: boolean,
      skipErrorNotification?: boolean,
    ): Promise<QuizApplicationInfo> => {
      const { data } = await startQuizApplicationMutation({
        variables: {
          problemType,
          quizPurpose,
          errorWhenFollowUpReminderNotDone,
        },
        context: {
          skipErrorNotification,
        },
      });
      if (!data) {
        throw new Error(`Fail to start quiz application`);
      }

      return data.quizApplicationInfo;
    },
    [startQuizApplicationMutation],
  );

  return startQuizApplication;
}
