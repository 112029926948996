/*
 * THIS COMPONENT WAS COPIED FROM THE ORIGINAL IMPLEMENTATION FROM stream-chat-react
 * Original source code:
 * https://github.com/GetStream/stream-chat-react/blob/master/src/components/Message/MessageStatus.tsx
 * Changes in this copy include:
 * - Removal of the avatar component and all relevant dependencies
 */

import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import {
  TooltipUsernameMapper,
  useMessageContext,
  useTranslationContext,
  PopperTooltip,
  MessageDeliveredIcon,
  LoadingIndicator,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

const useEnterLeaveHandlers = <T extends HTMLElement>({
  onMouseEnter,
  onMouseLeave,
}: Partial<
  Record<'onMouseEnter' | 'onMouseLeave', React.MouseEventHandler<T>>
> = {}): {
  handleEnter: React.MouseEventHandler<T>;
  handleLeave: React.MouseEventHandler<T>;
  tooltipVisible: boolean;
} => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleEnter: React.MouseEventHandler<T> = useCallback(
    (e) => {
      setTooltipVisible(true);
      onMouseEnter?.(e);
    },
    [onMouseEnter],
  );

  const handleLeave: React.MouseEventHandler<T> = useCallback(
    (e) => {
      setTooltipVisible(false);
      onMouseLeave?.(e);
    },
    [onMouseLeave],
  );

  return { handleEnter, handleLeave, tooltipVisible };
};

type MessageStatusProps = {
  /* Message type string to be added to CSS class names. */
  messageType?: string;
  /* Allows to customize the username(s) that appear on the message status tooltip */
  tooltipUserNameMapper?: TooltipUsernameMapper;
};

const UnMemoizedMessageStatus: React.ComponentType<MessageStatusProps> = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: MessageStatusProps,
) => {
  const { messageType = 'simple' } = props;

  const { handleEnter, handleLeave, tooltipVisible } =
    useEnterLeaveHandlers<HTMLSpanElement>();

  const { isMyMessage, lastReceivedId, message, threadList } =
    useMessageContext<StreamChatGenerics>('MessageStatus');
  const { t } = useTranslationContext('MessageStatus');
  const [referenceElement, setReferenceElement] =
    useState<HTMLSpanElement | null>(null);

  if (!isMyMessage() || message.type === 'error') {
    return null;
  }

  const rootClassName = `str-chat__message-${messageType}-status str-chat__message-status`;

  const sending = message.status === 'sending';
  const delivered =
    message.status === 'received' &&
    message.id === lastReceivedId &&
    !threadList;

  return (
    <span
      className={rootClassName}
      data-testid={clsx({
        'message-status-received': delivered,
        'message-status-sending': sending,
      })}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      ref={setReferenceElement}
    >
      {sending && (
        <>
          <PopperTooltip
            offset={[0, 5]}
            referenceElement={referenceElement}
            visible={tooltipVisible}
          >
            {t<string>('Sending...')}
          </PopperTooltip>
          <LoadingIndicator />
        </>
      )}

      {delivered && (
        <>
          <PopperTooltip
            offset={[0, 5]}
            referenceElement={referenceElement}
            visible={tooltipVisible}
          >
            {t<string>('Delivered')}
          </PopperTooltip>
          <MessageDeliveredIcon />
        </>
      )}
    </span>
  );
};

export const MessageStatus = React.memo(
  UnMemoizedMessageStatus,
) as typeof UnMemoizedMessageStatus;
