import { WeightDashboardBrandTheme } from './types';
import { colors as juniperColors } from '@eucalyptusvc/design-system/src/theme/juniper/palette';

export const theme: WeightDashboardBrandTheme = {
  tabContainer: {
    bgColorClassName: 'bg-primary-100',
    borderColorClassName: 'border-transparent',
  },
  tab: {
    textColorClassName: 'text-primary-500',
    bgColorClassName: {
      active: 'bg-primary-200',
      default: 'bg-primary-100',
    },
    borderColorClassName: 'border-primary-500',
    unreadIndicator: {
      marginTopClassName: 'mt-1',
      backgroundColourClassName: 'bg-status-error-500',
    },
  },
  coupon: {
    headingColor: juniperColors.secondary.lime[300],
    contentColor: juniperColors.neutral.white,
    cardBgColor: 'bg-primary-500',
  },
};
