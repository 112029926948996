import React, { useState } from 'react';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  LoadingSpinner,
  Markdown,
  Modal,
  Typography,
} from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import {
  MedicationPreferencePageQuery,
  MedicationPreferencePageQueryVariables,
  ProblemType,
  DeleteMedicationPreferenceMutation,
  DeleteMedicationPreferenceMutationVariables,
  UpdateMedicationPreferenceMutation,
  UpdateMedicationPreferenceMutationVariables,
} from '@customer-frontend/graphql-types';
import { ReactComponent as InfoIcon } from '../assets/info.svg';
import { formatCentsToCurrency } from '../../../order/src/logic/pricing';

type MedicationPreferenceProps = {
  consultationId: string;
  consultationPaymentRoute: string;
};

export const MedicationPreference: React.FC<MedicationPreferenceProps> = ({
  consultationId,
  consultationPaymentRoute,
}) => {
  const featureFlagClient = useFeatureFlagClient();
  const history = useHistory();
  useBackButtonBehaviour(() => history.goBack());
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const flagData = featureFlagClient.getJson<{ string: string[] }>(
    'CFG_MULTIPLE_MEDICATION_PREFERENCE_OPTIONS',
  );

  const skusSet = new Set<string>();
  if (flagData) {
    Object.entries(flagData).forEach(([, values]) => {
      values.forEach((v) => skusSet.add(v));
    });
  }
  const skus = Array.from(skusSet);

  const { data, loading } = useQuery<
    MedicationPreferencePageQuery,
    MedicationPreferencePageQueryVariables
  >(
    gql`
      query MedicationPreferencePage(
        $consultationId: String!
        $skus: [String!]
      ) {
        consultation(id: $consultationId) {
          id
          type
          quizApplicationId
          medicationPreferenceProduct {
            id
            defaultProduct {
              id
              photo {
                id
                url
              }
              variants {
                id
                isRefill
                price
              }
            }
          }
        }
        products(
          where: { variants: { some: { inventory: { sku: { in: $skus } } } } }
        ) {
          id
          friendlyName
          name
          problemTypes
          shortDescription
          photo {
            id
            url
          }
          variants {
            id
            price
            isRefill
            inventory {
              id
              sku
            }
          }
        }
      }
    `,
    { variables: { consultationId, skus } },
  );

  const consultation = data?.consultation;

  if (loading || !data || !consultation) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const products = data?.products
    .filter((p) => p.problemTypes.some((pt) => pt.includes(consultation.type)))
    .sort((productA, productB) => {
      const skuA =
        productA.variants.find((v) => !v.isRefill)?.inventory?.sku ?? '';
      const skuB =
        productB.variants.find((v) => !v.isRefill)?.inventory?.sku ?? '';
      return skus.indexOf(skuA) - skus.indexOf(skuB);
    });

  const defaultProduct =
    consultation.medicationPreferenceProduct?.defaultProduct;
  const defaultVariant = defaultProduct?.variants.find((v) => !v.isRefill);

  return (
    <div className="flex flex-col gap-y-4">
      <Modal
        title="What if I pay and I am not suitable?"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="If your {isGb, select, true {prescriber} other {practitioner}} decides that you are not suitable for treatment, or you choose not to proceed, we will refund your payment in full."
            description="Text informing the user what happens if they pay and are not suitable"
            values={{ isGb: getConfig().countryCode === 'GB' }}
          />
        </Typography>
      </Modal>
      <Typography size="lg" isBold>
        <FormattedMessage
          defaultMessage="Do you have a medication preference?"
          description="Text asking the user if they have a medication preference"
        />
      </Typography>
      <Typography size="large-paragraph">
        <FormattedMessage
          defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} will be informed of your preference. If suitable, the medication prescribed is ultimately the decision of the {isGb, select, true {prescriber} other {practitioner}} based on their clinical assessment.{br} If the prescribed medication is different to your preference, we will organise a refund or additional payment to correct any discrepancies. You will be notified if this occurs.{br}If you choose not to proceed, you will receive a full refund."
          description="Text informing the user of the next steps after selecting a medication preference"
          values={{
            br: '\n\n',
            isGb: getConfig().countryCode === 'GB',
          }}
        />
      </Typography>
      <div className="mb-4">
        <button
          className="flex items-center gap-x-2"
          onClick={() => setIsOpen(true)}
        >
          <InfoIcon className="w-5 h-5" />
          <Typography size="medium-paragraph" isBold decoration="underline">
            <FormattedMessage defaultMessage="What if I pay and I am not suitable?" />
          </Typography>
        </button>
      </div>
      {products.map((p) => {
        const variant = p.variants.find((v) => !v.isRefill);
        if (!variant) {
          return;
        }
        const price = formatCentsToCurrency(variant.price);
        return (
          <MedicationPreferenceOption
            key={p.id}
            consultationPaymentRoute={consultationPaymentRoute}
            title={p.friendlyName ?? p.name}
            price={price}
            description={p.shortDescription ?? ''}
            quizApplicationId={consultation.quizApplicationId}
            problemType={consultation.type}
            sku={variant.inventory?.sku}
            imageUrl={p.photo?.url}
          />
        );
      })}
      {defaultVariant && (
        <MedicationPreferenceOption
          consultationPaymentRoute={consultationPaymentRoute}
          title="I don't have a preference"
          price={formatCentsToCurrency(defaultVariant.price)}
          problemType={consultation.type}
          description="You can discuss treatment options with your practitioner. If the prescribed medication is different to the amount paid for your first order, we'll organise a refund or additional payment to correct any discrepancies."
          imageUrl={defaultProduct?.photo?.url}
        />
      )}
    </div>
  );
};

type MedicationPreferenceOptionProps = {
  title: string;
  price: string;
  description: string;
  consultationPaymentRoute: string;
  problemType: ProblemType;
  quizApplicationId?: string | null;
  sku?: string | null;
  imageUrl?: string | null;
};

const MedicationPreferenceOption: React.FC<MedicationPreferenceOptionProps> = ({
  title,
  price,
  description,
  consultationPaymentRoute,
  quizApplicationId,
  problemType,
  sku,
  imageUrl,
}) => {
  const history = useHistory();
  const { brand } = getConfig();

  const [deleteMedicationPreference] = useMutation<
    DeleteMedicationPreferenceMutation,
    DeleteMedicationPreferenceMutationVariables
  >(gql`
    mutation DeleteMedicationPreference(
      $input: DeleteMedicationPreferenceInput!
    ) {
      deleteMedicationPreference(input: $input) {
        success
      }
    }
  `);

  const [updateUserMedicationPreference] = useMutation<
    UpdateMedicationPreferenceMutation,
    UpdateMedicationPreferenceMutationVariables
  >(gql`
    mutation UpdateMedicationPreference(
      $problemType: ProblemType!
      $medicationSkuPrefix: String!
      $quizApplicationId: String
    ) {
      updateMedicationPreference(
        problemType: $problemType
        medicationSkuPrefix: $medicationSkuPrefix
        quizApplicationId: $quizApplicationId
      ) {
        medicationSkuPrefix
      }
    }
  `);

  const handleSelect = async (
    preferredMedication?: string | null,
  ): Promise<void> => {
    if (!preferredMedication) {
      await deleteMedicationPreference({
        variables: { input: { problemType } },
      });
    } else {
      await updateUserMedicationPreference({
        variables: {
          problemType,
          medicationSkuPrefix: preferredMedication,
          quizApplicationId,
        },
      });
    }
    history.push(consultationPaymentRoute);
  };

  return (
    <Card palette="default">
      <div className="flex flex-col">
        <div className="flex gap-4">
          {imageUrl && <img src={imageUrl} className="rounded h-20 w-20" />}
          <div>
            <div className="mb-2">
              <Typography size="sm" isBold>
                <FormattedMessage
                  defaultMessage="{title}"
                  description="Title of the medication option"
                  values={{
                    title,
                  }}
                />
              </Typography>
            </div>
            <div className="mb-4">
              <Typography size="medium-paragraph" isBold>
                <FormattedMessage
                  defaultMessage="{price} for the first order"
                  description="Price for the first order of the medication"
                  values={{
                    price,
                  }}
                />
              </Typography>
            </div>
            <div className="mb-6">
              <Markdown src={description} formatWhitespace />
            </div>
          </div>
        </div>
        <Button
          isFullWidth
          palette={getPrimaryButtonPalette(brand)}
          onClick={() => handleSelect(sku)}
        >
          <FormattedMessage defaultMessage="Select" />
        </Button>
      </div>
    </Card>
  );
};
