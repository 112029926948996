import { gql } from '@apollo/client';

export default gql`
  query FetchChecklist($consultationId: String!) {
    checklistByConsultation(consultationId: $consultationId) {
      id
      consultationId
      attributes {
        age
        tryingStatus
        trimester
        expectedNewborns
      }
      groups {
        id
        title
        items {
          code
          completed
          id
        }
      }
    }
  }
`;
