import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { QueryInSegmentArgs } from '@customer-frontend/graphql-types';
interface Response {
  inSegment: boolean;
}

const inSegmentDocument = gql`
  query InSegment($segmentName: Segment!) {
    inSegment(segmentName: $segmentName)
  }
`;

export function useInSegment(
  options: QueryHookOptions<Response, QueryInSegmentArgs>,
): QueryResult<Response, QueryInSegmentArgs> {
  return useQuery<Response, QueryInSegmentArgs>(inSegmentDocument, options);
}
