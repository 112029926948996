import { gql } from '@apollo/client';

export default gql`
  query ConsultationPricing($id: String!, $discountCode: String) {
    consultation(id: $id) {
      id
      type
      stage
      price(discount: $discountCode) {
        amountOff
        fullPrice
        total
      }
    }
  }
`;
