import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import {
  AccordionList,
  AccordionPalette,
  Button,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { CallSummaryCard } from '@customer-frontend/consultation';
import { ScheduleCallPractitionerBookingWindowFragment } from '@customer-frontend/graphql-types';
import { FaRegCalendarAlt } from 'react-icons/fa';

interface CallConfirmedProps {
  goBackToProfile: () => void;
  reschedule: () => void;
  askQuestion: () => void;
  palette?: AccordionPalette;
  patientEmail: string;
  patientPhone: string;
  bookingWindow: Pick<
    ScheduleCallPractitionerBookingWindowFragment,
    'startAt' | 'endAt'
  >;
  countryCodes: string[];
}

export const CallConfirmed: React.FC<CallConfirmedProps> = ({
  goBackToProfile,
  reschedule,
  askQuestion,
  palette,
  patientEmail,
  patientPhone,
  bookingWindow,
  countryCodes,
}) => {
  const config = getConfig();
  const environment = useEnvironment();
  return (
    <div className="h-full space-y-4 text-center">
      <Typography size="lg" isBold>
        <FormattedMessage defaultMessage="Appointment confirmed" />
      </Typography>
      <div className="space-y-3">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Confirmation of your appointment has been sent to {emailAddress}"
            values={{
              emailAddress: patientEmail,
            }}
          />
        </Typography>
      </div>
      <div className="space-y-8">
        <CallSummaryCard
          brand={config.brand}
          userPhoneNumber={patientPhone}
          customerHasCallScheduled={
            bookingWindow.startAt && bookingWindow.endAt
          }
          bookingWindow={bookingWindow}
          countryCodes={countryCodes}
        />
        <div className="flex flex-col gap-3 justify-center md:flex-row">
          <Button
            level="primary"
            palette={getPrimaryButtonPalette(config.brand)}
            onClick={goBackToProfile}
          >
            <FormattedMessage defaultMessage="Return to profile" />
          </Button>
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            iconRight={<FaRegCalendarAlt />}
            onClick={reschedule}
          >
            <FormattedMessage defaultMessage="Reschedule" />
          </Button>
          <Button
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            onClick={askQuestion}
          >
            <FormattedMessage defaultMessage="Ask a question" />
          </Button>
        </div>
        <div className="text-left">
          <AccordionList
            palette={palette}
            items={[
              {
                title: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage defaultMessage="What to expect" />
                  </Typography>
                ),
                content: (
                  <div className="space-y-4">
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Just prior to your appointment time, you will receive a text message to let you know that your practitioner is about to call." />
                    </Typography>
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Your practitioner will then call you, and if they decide treatment is appropriate for you, you will receive an email asking for you to confirm your medication order from our partner pharmacy." />
                    </Typography>
                    <Typography size="medium-paragraph">
                      <FormattedMessage defaultMessage="Once confirmed, your order will be prepared and it will be sent directly to your address via Australia Post." />
                    </Typography>
                  </div>
                ),
              },
              {
                title: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage defaultMessage="Frequently asked questions" />
                  </Typography>
                ),
                content: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage={`The team has written up answers to all the most common questions our patients ask. To read these, visit our <a>Frequently Asked Questions page.</a>`}
                      values={{
                        a: (chunks) => (
                          <a
                            href={`${environment.landingPageUrl}${config.urlPaths.faq}`}
                            className="text-link"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                      description="Text pointing patients to frequently asked questions and their answers."
                    />
                  </Typography>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
