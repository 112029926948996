import { colors } from './palette';
import { components } from './components';
import { BrandTheme } from '../types';

const theme: BrandTheme = {
  colors,
  components,
  extensions: {
    toast: {
      iconStyle: 'outline',
    },
    text: {
      color: colors.secondary.brown[500],
    },
    'image-upload': {
      textColor: colors.primary[500],
      placeholderColor: colors.primary[500],
      selectButtonStyle: 'primary',
      selectButtonPalette: 'alternate',
      submitButtonPalette: 'alternate',
      iconStyle: 'thin',
    },
  },
};

export default theme;
