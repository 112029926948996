import { useEffect, useMemo } from 'react';
import { StripeCardInput } from './stripe-card-input';
import { ZipInput } from './zip-input';
import { PaymentMethodsFragment } from '@customer-frontend/graphql-types';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { getConfig } from '@customer-frontend/config';
import { gql } from '@apollo/client';
import { getDefaultPaymentMethod } from '@customer-frontend/order';
import clsx from 'clsx';

export type FormPaymentFields =
  | { method: 'STRIPE'; paymentDetailsCompleted: boolean }
  | { method: 'ZIP'; paymentDetailsCompleted: true };

function PaymentMethods(props: {
  fragment: PaymentMethodsFragment;
  control: Control<{ payment: FormPaymentFields }>;
  errors: FieldErrors<{ payment: FormPaymentFields }>;
  name: string;
  borderColorClassName?: string;
}): React.ReactElement {
  const config = getConfig();
  const availablePaymentMethods = useMemo(() => {
    const innerAvailablePaymentMethods: FormPaymentFields['method'][] = [
      'STRIPE',
    ];

    if (config.enableZip) {
      innerAvailablePaymentMethods.push('ZIP');
    }

    return innerAvailablePaymentMethods;
  }, [config.enableZip]);

  const [fieldName] = useMemo(() => {
    return [`${props.name}.method`];
  }, [props.name]);

  useEffect(() => {
    if (
      props.fragment.zip?.valid &&
      props.fragment.defaultPaymentGateway === 'ZIP' &&
      availablePaymentMethods.includes('ZIP')
    ) {
      props.control.setValue(fieldName, 'ZIP');
    } else {
      props.control.setValue(fieldName, 'STRIPE');
    }
  }, [
    fieldName,
    props.control,
    props.fragment.zip?.valid,
    props.fragment.defaultPaymentGateway,
    availablePaymentMethods,
  ]);

  const singlePaymentProvider = availablePaymentMethods.length === 1;

  const defaultPaymentMethod = getDefaultPaymentMethod(
    props.fragment.savedPaymentMethods,
  );

  return (
    <div
      className={clsx(
        'bg-white',
        {
          'border rounded': !singlePaymentProvider,
        },
        !singlePaymentProvider &&
          (props.borderColorClassName || 'border-primary-300'),
      )}
    >
      <Controller
        control={props.control}
        name={fieldName}
        render={({
          onChange,
          value,
        }: {
          onChange: (value: FormPaymentFields['method']) => void;
          value: FormPaymentFields['method'];
        }) => (
          <>
            {availablePaymentMethods.map((paymentMethod, index) => {
              let paymentMethodElement;
              switch (paymentMethod) {
                case 'STRIPE':
                  paymentMethodElement = (
                    <StripeCardInput
                      singlePaymentProvider={singlePaymentProvider}
                      active={value === 'STRIPE'}
                      setActive={() => onChange('STRIPE')}
                      control={props.control}
                      errors={props.errors}
                      defaultPaymentMethod={defaultPaymentMethod}
                    />
                  );
                  break;
                case 'ZIP':
                  paymentMethodElement = (
                    <ZipInput
                      hideCheckbox={singlePaymentProvider}
                      active={value === 'ZIP'}
                      setActive={() => onChange('ZIP')}
                      zip={props.fragment.zip}
                    />
                  );
                  break;
              }

              return (
                <>
                  {paymentMethodElement}
                  {index !== availablePaymentMethods.length - 1 && (
                    <hr
                      className={
                        props.borderColorClassName || 'border-primary-300'
                      }
                    />
                  )}
                </>
              );
            })}
          </>
        )}
      />
    </div>
  );
}

PaymentMethods.fragment = gql`
  fragment PaymentMethods on User {
    id
    defaultPaymentGateway
    savedPaymentMethods {
      id
      gateway
      default
      createdAt
      ... on SavedCardPaymentMethod {
        expiry
        mask
        brand
      }
      ... on SavedZipPaymentMethod {
        valid
      }
      ... on SavedPayPalPaymentMethod {
        email
      }
    }
    zip {
      valid
    }
  }
`;

export { PaymentMethods };
