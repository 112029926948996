import React from 'react';
import { MaskedInputProps } from '../types';
import { useIMask } from 'react-imask';
import { mergeRefs } from '../utils';
import { TextInput } from '../text-input';
import { getMaskConfigFromType } from './masked-input-config';

const BaseMaskedInput = (
  props: MaskedInputProps<HTMLInputElement>,
  forwardedRef: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  const { ref } = useIMask(getMaskConfigFromType(props.maskType));

  return (
    <TextInput ref={mergeRefs(ref, forwardedRef)} {...props} type="text" />
  );
};

/** Use a **MaskedInput** when you need to masked date information from a user, often as
 * part of a form. Controlled component so it is easy to integrate with a form
 * library. */
export const MaskedInput = React.forwardRef<
  HTMLInputElement,
  MaskedInputProps<HTMLInputElement>
>(BaseMaskedInput);
