import {
  isArray,
  breakpoints,
  fromEntries,
  arrayToObjectNotation,
} from '@chakra-ui/utils';

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/media-query/src/media-query.utils.ts
const getClosestValue = <T>(
  values: Record<string, T>,
  breakpoint: string,
): T | undefined => {
  let index = Object.keys(values).indexOf(breakpoint);

  if (index !== -1) {
    return values[breakpoint];
  }

  let stopIndex = breakpoints.indexOf(breakpoint);

  while (stopIndex >= 0) {
    const key = breakpoints[stopIndex];

    if (values[key] != null) {
      index = stopIndex;
      break;
    }
    stopIndex -= 1;
  }

  if (index !== -1) {
    const key = breakpoints[index];
    return values[key];
  }

  return undefined;
};

/**
 * Get the value for the current breakpoint based
 * off a set a responsive values
 *
 * Based off:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/media-query/src/use-breakpoint-value.ts
 *
 * @param values
 * @param breakpoint the current breakpoint
 *
 * @examples
 * const padding = getBreakpointValue(['16px', '24px'], breakpoint)
 * const width = getBreakpointValue({ base: '150px', md: '250px' }, breakpoint)
 */
export const getBreakpointValue = <T>(
  values: Partial<Record<string, T>> | T[],
  breakpoint: string,
): T | undefined => {
  const obj = isArray(values)
    ? fromEntries<Partial<Record<string, T>>>(
        Object.entries(arrayToObjectNotation(values, breakpoints)).map(
          ([key, value]) => [key, value],
        ),
      )
    : values;

  return getClosestValue(obj, breakpoint);
};
