import { gql } from '@apollo/client';

export const reactivateSubscriptionV2Document = gql`
  mutation ReactivateSubscriptionV2($subscriptionId: String!) {
    reactivateSubscriptionV2(subscriptionId: $subscriptionId) {
      id
      subscriptionOrders(pagination: { pageSize: 1, skip: 0 }) {
        id
        createdAt
        order {
          id
          lineItems {
            id
            variant {
              id
              price
            }
          }
        }
      }
    }
  }
`;
