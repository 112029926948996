import { gql, QueryResult, useQuery } from '@apollo/client';
import {
  subscriptionPlanV2Fragment,
  useProfileCards,
} from '@customer-frontend/services';
import { Profile } from './types';
import { BillingDetails } from '@customer-frontend/order';

type UseProfileIncludeOptions = {
  subscriptions?: boolean;
};

export const useProfile = (
  includes?: UseProfileIncludeOptions,
): QueryResult<{
  profile: Profile;
}> => {
  return useQuery<{ profile: Profile }>(
    gql`
      query UseProfile($withSubscriptions: Boolean = false) {
        profile {
          id
          firstName
          lastName
          shortAddressableName
          fullName
          legalFirstName
          legalLastName
          legalFullName
          preferredName
          email
          phone
          isVerified
          birthday
          hasPaidMasterclass
          needsSubscriptionMigration
          isPasswordSet
          weightTreatmentCategory
          ...BillingDetails
          preferredUnitSystem
          address {
            id
            line1
            line2
            city
            state
            postalCode
            prefecture
            municipality
            townArea
            country
            building
            company
          }
          residentialAddress {
            id
            line1
            line2
            city
            state
            postalCode
            prefecture
            municipality
            townArea
            country
            building
            company
          }
          consultations(orderBy: { createdAt: desc }) {
            id
            type
            status
            allowPatientToSendMessageToDoctor
            isApproved
            stage
            patientNotes
            rejectReason
            medium
            completedAt
            updatedAt
            createdAt
            patientQuestions {
              id
              createdAt
            }
            appointment {
              startsAt
              endsAt
            }
            doctor {
              id
              firstName
              lastName
              shortClinicianName
              fullClinicianName
              fullName
              provider {
                id
                clinicianType
                clinicianTitle
              }
            }
            isUpfrontPayment
            latestPractitionerBooking {
              id
              windowStartAt
              windowEndAt
            }
            requiresPrescriberCall
            isInNoPickUpQueue
            screeningQuizApplication {
              id
              submittedAt
            }
            chatThread {
              id
            }
            quizApplication {
              id
              submittedAt
              answers {
                id
                answer
                answersArray
                question {
                  id
                  shortcode
                }
              }
            }
            order {
              id
            }
            treatment {
              id
              status
              refillsLeft
              nextPayment
              pendingFollowUpReminderDate
              isLastOrderUnfulfilled
              hasUnfulfilledOrder
              customerStatedTreatmentStartedAt
              type
              active
              hasScriptExpired
              product {
                id
                name
                friendlyName
                photo {
                  id
                  url
                }
                variants {
                  id
                  inventory {
                    id
                    sku
                  }
                }
              }
              plan {
                id
                amount
              }
              otcSchedules {
                id
                isActive
                quantity
                product {
                  id
                  name
                  productType
                  photo {
                    id
                    url
                  }
                  variants {
                    id
                    price
                  }
                }
              }
            }
            pathologyRequests {
              id
              createdAt
              customerStatedCollectedAt
            }
            reminders {
              id
              canceled
              createdAt
              scheduledAt
              doneAt
            }
            ...UseProfileCardsConsultation
          }
          initialQuizApplications: quizApplications(purpose: INITIAL) {
            id
            createdAt
            updatedAt
            submittedAt
            problemType
            purpose
            outcomes {
              ... on QuizOutcomeV2 {
                id
              }
              ... on NotEligibleQuizOutcome {
                reasons
              }
            }
            ...UseProfileCardsQuizApplication
          }
          memberships {
            id
            type
            createdAt
            membershipTerms(orderBy: { createdAt: desc }, take: 1) {
              id
              status
              startDate
              endDate
              isValid
            }
          }
          subscriptions @include(if: $withSubscriptions) {
            id
            status
            nextPaymentAt
            subscriptionOrders {
              id
              status
              createdAt
            }
            subscriptionPlan {
              ...OtcSubscriptionPlanV2Info
            }
          }
          purchases {
            id
            ...UseProfileCardsPurchase
          }
        }
      }
      ${subscriptionPlanV2Fragment}
      ${useProfileCards.fragment}
      ${BillingDetails.fragment}
    `,
    {
      fetchPolicy: 'network-only',
      variables: {
        withSubscriptions: includes?.subscriptions || false,
      },
    },
  );
};
