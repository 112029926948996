import {
  UseSignUpLeadMutation,
  UseSignUpLeadMutationVariables,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const signUpLeadDocument = gql`
  mutation UseSignUpLead(
    $email: String!
    $id: ID
    $firstName: String
    $lastName: String
    $marketingCommsConsent: Boolean
  ) {
    signUpLead(
      email: $email
      id: $id
      firstName: $firstName
      lastName: $lastName
      marketingCommsConsent: $marketingCommsConsent
    ) {
      token
      user {
        id
        email
        firstName
        lastName
        shortAddressableName
        fullName
      }
    }
  }
`;

export function useSignUpLeadMutation(
  options: MutationHookOptions<
    UseSignUpLeadMutation,
    UseSignUpLeadMutationVariables
  >,
): MutationTuple<UseSignUpLeadMutation, UseSignUpLeadMutationVariables> {
  return useMutation<UseSignUpLeadMutation, UseSignUpLeadMutationVariables>(
    signUpLeadDocument,
    options,
  );
}
