import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { GqlErrorCode } from '@eucalyptusvc/errors';
import { errorMessageDescriptors } from './error-message-descriptors';

export const useFormatErrorMessageFromCode = (): ((
  code?: string,
) => string) => {
  const { formatMessage } = useIntl();

  return useCallback(
    (code = ''): string => {
      const errorMessage =
        errorMessageDescriptors[code] ??
        errorMessageDescriptors[GqlErrorCode.INTERNAL_SERVER_ERROR];
      return formatMessage(errorMessage);
    },
    [formatMessage],
  );
};
