import { Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as DownArrow } from '../../assets/icon/down-arrow.svg';
import { ReactComponent as UpArrow } from '../../assets/icon/up-arrow.svg';
import { useTrackerTheme } from '../../provider';
import { FormattedMessage } from 'react-intl';

const MomentumArrow = ({
  momentum,
}: {
  momentum: number;
}): React.ReactElement | null => {
  if (momentum > 0) {
    return <UpArrow />;
  }

  if (momentum < 0) {
    return <DownArrow />;
  }

  return null;
};

export const WeightLossMomentum = ({
  momentum,
}: {
  momentum: number;
}): React.ReactElement | null => {
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.enhancedTrackerChart;

  return (
    <div className="space-y-1">
      <Typography size="paragraph" color={trackerTheme?.text?.secondaryColor}>
        <FormattedMessage defaultMessage="Your recent momentum" />
      </Typography>
      <div className="flex items-center justify-end space-x-1">
        <MomentumArrow momentum={momentum} />
        <div className="flex items-end space-x-1">
          <Typography size="xl" isBold color={trackerTheme?.text?.primaryColor}>
            {Math.abs(momentum)}
          </Typography>
          <div className="mb-1">
            <Typography
              size="medium-paragraph"
              color={trackerTheme?.text?.primaryColor}
            >
              <FormattedMessage
                defaultMessage="kg / wk"
                description="kilograms per week"
              />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
