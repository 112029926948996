import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { QuestionProps } from './types';
import { Dropdown } from '@eucalyptusvc/design-system';

export function DropdownQuestionInput({
  question,
  isLoading,
}: QuestionProps): JSX.Element {
  const { register, errors, control } = useFormContext();
  const { required } = question;

  return (
    <Controller
      control={control}
      name={question.id}
      render={({ onChange }): JSX.Element => {
        return (
          <Dropdown
            disabled={isLoading}
            name={question.id}
            ref={register}
            options={question.options.map((o: string) => ({
              label: o,
              value: o, // TODO: Consolidate to allow for just an array of strings.
            }))}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value);
            }}
            required={required ?? false}
            errorMessage={errors[question.id]?.message}
          />
        );
      }}
    />
  );
}
