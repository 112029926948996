import {
  DiscountCodeFormFragment,
  DiscountStage,
  DiscountType,
  Maybe,
} from '@customer-frontend/graphql-types';
import { uiStorages } from '@customer-frontend/ui-storage';
import { formatCentsToCurrency } from '../pricing';

const DISCOUNT_CODE_STORAGE_KEY = 'op-discount-code';

const discountStageToKeyMap: Partial<Record<DiscountStage, string>> = {
  CONSULTATION_PAID: 'cp-discount-code',
  ORDER_PAID: 'op-discount-code',
};

export const saveDiscountToLocalStorage = (
  stage: DiscountStage,
  code?: Maybe<string>,
): void => {
  const key = discountStageToKeyMap[stage];
  if (!code || !key) {
    return;
  }

  uiStorages.local.setValue(DISCOUNT_CODE_STORAGE_KEY, code);
};

export const getDiscountFromLocalStorage = (
  stage: DiscountStage,
): string | undefined => {
  const codeKey = discountStageToKeyMap[stage];
  if (!codeKey) {
    return undefined;
  }
  const code = uiStorages.local.getValue(codeKey);

  if (!code) {
    return undefined;
  } else {
    return code;
  }
};

export const removeDiscountFromLocalStorage = (stage: DiscountStage): void => {
  const codeKey = discountStageToKeyMap[stage];
  if (!codeKey) {
    return undefined;
  }
  uiStorages.local.clearValue(codeKey);
};

const calculateDiscountedPriceOnSubtotal = (
  subtotal: number,
  discount?: Maybe<{
    amount: number;
    type: DiscountType;
  }>,
): { totalPrice: number; amountSaved: number } => {
  let totalPrice = subtotal;
  if (discount && discount.amount) {
    switch (discount.type) {
      case 'FIXED':
        totalPrice -= discount.amount * 100;
        break;
      case 'PERCENTAGE':
        totalPrice -= (totalPrice * discount.amount) / 100;
        break;
      default:
        break;
    }
  }

  totalPrice = Math.max(totalPrice, 0);
  totalPrice = Math.ceil(totalPrice);

  return {
    totalPrice,
    amountSaved: subtotal - totalPrice,
  };
};

const calculateDiscountedPriceForProducts = ({
  subtotal,
  products,
  discount,
}: {
  subtotal: number;
  products: { id: string; price: number; quantity?: number }[];
  discount?: Maybe<{
    amount: number;
    type: DiscountType;
    products: { id: string }[];
  }>;
}): { totalPrice: number; amountSaved: number } => {
  if (!products.length) {
    return {
      totalPrice: subtotal,
      amountSaved: 0,
    };
  }

  const totalPrice = products.reduce(
    (acc, curr) =>
      acc +
      calculateDiscountedPriceForProduct({ product: curr, discount })
        .totalPrice,
    0,
  );

  return {
    totalPrice,
    amountSaved: subtotal - totalPrice,
  };
};

export const calculateDiscountedPrice = ({
  subtotal,
  products,
  discount,
}: {
  subtotal: number;
  products: { id: string; price: number; quantity?: number }[];
  discount?: Maybe<{
    amount: number;
    type: DiscountType;
    products: { id: string }[];
  }>;
}): { totalPrice: number; amountSaved: number } => {
  // Empty products array on discounts means the discount applies to the entire order
  if (discount?.products.length) {
    return calculateDiscountedPriceForProducts({
      subtotal,
      products,
      discount,
    });
  }

  return calculateDiscountedPriceOnSubtotal(subtotal, discount);
};

export const calculateDiscountedPriceForProduct = ({
  product,
  discount,
}: {
  product: { id: string; price: number; quantity?: number };
  discount?: Maybe<{
    amount: number;
    type: DiscountType;
    products: { id: string }[];
  }>;
}): { totalPrice: number; amountSaved: number } => {
  const discountAppliesToGivenProduct = discount?.products.some(
    (p) => p.id === product.id,
  );

  // If no quantity is specified for a product, assume 1, as we have been assuming before.
  // TODO(maggiexu-euc): As fast follow, have all quantities be specified from otcSchedules.
  const quantity = product.quantity ?? 1;

  if (!discount || !discountAppliesToGivenProduct) {
    return {
      totalPrice: product.price * quantity,
      amountSaved: 0,
    };
  }

  return calculateDiscountedPriceOnSubtotal(product.price * quantity, {
    ...discount,
    amount:
      // We multiply the discount amount by quantity here since a fixed discount is per product
      // And it needs to multiply by the quantity of those products
      discount.type === 'FIXED' ? discount.amount * quantity : discount.amount,
  });
};

export const getFormattedDiscount = (discountCode: {
  amount: number;
  type: DiscountType;
}): string => {
  if (discountCode.type === 'FIXED') {
    return formatCentsToCurrency(discountCode.amount * 100);
  } else {
    return discountCode.amount + '%';
  }
};

export const isRecurringDiscountCode = (discountCode: {
  stages: DiscountStage[];
}): boolean => {
  const { stages } = discountCode;
  return (
    stages.includes('RECURRING_ORDER_DISCOUNT') && stages.includes('ORDER_PAID')
  );
};

export const getDiscountAppliedText = ({
  code,
  isRecurringDiscount,
  amountSaved,
}: {
  code: DiscountCodeFormFragment;
  isRecurringDiscount?: boolean;
  amountSaved: number;
}): string => {
  if (isRecurringDiscount) {
    let amountSavedString: string;
    const isProductSpecificDiscount = code.products.length > 0;
    if (code.type === 'FIXED' || isProductSpecificDiscount) {
      amountSavedString = `${formatCentsToCurrency(amountSaved)}`;
    } else {
      amountSavedString = `${code.amount}%`;
    }
    return `${amountSavedString} discount has been applied on all future orders`;
  }

  return `Discount applied! You've saved ${formatCentsToCurrency(amountSaved)}`;
};
