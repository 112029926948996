import { getConfig } from '@customer-frontend/config';

export const getPlayerColor = (): string => {
  const { brand } = getConfig();
  switch (brand) {
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
      return '133F26';
    case 'kin':
      return '392F80';
    default:
      return '000000';
  }
};
