import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { MutationUpdateShippingArgs } from '@customer-frontend/graphql-types';

const updateShippingMutation = gql`
  mutation UpdateShipping($address: AddressCreateWithoutUserInput!) {
    updateShipping(address: $address) {
      id
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        building
        company
      }
    }
  }
`;

export function useUpdateShipping(
  options?: MutationHookOptions<Response, MutationUpdateShippingArgs>,
): MutationTuple<Response, MutationUpdateShippingArgs> {
  return useMutation<Response, MutationUpdateShippingArgs>(
    updateShippingMutation,
    options,
  );
}
