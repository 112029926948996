import React, { useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Avatar, Typography } from '@eucalyptusvc/design-system';
import { DoctorInfoFragment as DoctorInfoFragmentType } from '@customer-frontend/graphql-types';
import { ReactComponent as AccordionButtonSvg } from './assets/accordion-button.svg';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared/palette';
import { gql } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';

export const DoctorInfoFragment = gql`
  fragment DoctorInfo on User {
    id
    fullClinicianName
    lastName
    bio
    avatar {
      id
      url
    }
    provider {
      id
      clinicianTitle
    }
  }
`;

export const DoctorInfo: React.FC<{
  doctor: DoctorInfoFragmentType;
}> = ({ doctor }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <div className="bg-primary-100 w-full border-b border-primary-300 px-4 py-2">
      <div className="flex justify-between">
        {isExpanded ? (
          <ExpandedInfo doctor={doctor} />
        ) : (
          <CompressedInfo doctor={doctor} />
        )}
        {doctor.bio && (
          <AccordionButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        )}
      </div>
      {doctor.bio && isExpanded && (
        <div className="pt-2">
          <Typography color={sharedColors.neutral.black} size="paragraph">
            <FormattedMessage
              defaultMessage="{bio}"
              values={{
                bio: doctor.bio,
              }}
            />
          </Typography>
        </div>
      )}
    </div>
  );
};

const ExpandedInfo: React.FC<{ doctor: DoctorInfoFragmentType }> = ({
  doctor,
}) => (
  <div className="flex">
    <Avatar size="lg" src={doctor.avatar?.url} lastName={doctor.lastName} />
    <div className="px-2 flex flex-col justify-center">
      <Typography color={sharedColors.neutral.black} size="paragraph">
        <FormattedMessage
          defaultMessage="<strong>{doctorName}</strong>"
          values={{
            doctorName: doctor.fullClinicianName,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Typography>
      <Typography color={sharedColors.neutral.black} size="paragraph">
        <FormattedMessage
          defaultMessage="{clinicianTitle}"
          values={{
            clinicianTitle: doctor.provider?.clinicianTitle,
          }}
        />
      </Typography>
    </div>
  </div>
);

const CompressedInfo: React.FC<{ doctor: DoctorInfoFragmentType }> = ({
  doctor,
}) => (
  <div className="flex">
    <Avatar size="xs" src={doctor.avatar?.url} lastName={doctor.lastName} />
    <div className="px-2 flex flex-col justify-center">
      <Typography color={sharedColors.neutral.black} size="paragraph">
        <FormattedMessage
          defaultMessage="Your {isGb, select, true {prescriber} other {practitioner}} is <strong>{doctorName}</strong>"
          values={{
            doctorName: doctor.fullClinicianName,
            strong: (chunks) => <strong>{chunks}</strong>,
            isGb: getConfig().countryCode === 'GB',
          }}
        />
      </Typography>
    </div>
  </div>
);

const AccordionButton: React.FC<{
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}> = ({ isExpanded, setIsExpanded }) => (
  <button
    className={clsx('h-7 transition-transform', {
      'transform rotate-180': isExpanded,
    })}
    onClick={() => setIsExpanded(!isExpanded)}
  >
    <AccordionButtonSvg />
  </button>
);
