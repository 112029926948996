import { getConfig } from '@customer-frontend/config';
import { ProblemType } from '@customer-frontend/graphql-types';

export function getZendeskRequestUrl({
  problemType,
  params,
  email,
}: {
  problemType?: ProblemType;
  params?: Record<string, string>;
  email?: string;
}): string | undefined {
  const { zendeskRequestUrl, problemTypeZendeskParams } = getConfig();
  const searchParams = new URLSearchParams({
    ...(problemType && problemTypeZendeskParams?.[problemType]),
    ...params,
    ...(email && { tf_anonymous_requester_email: email }),
  });
  const search = searchParams.size ? `?${searchParams}` : '';
  const url = zendeskRequestUrl
    ? new URL(`${zendeskRequestUrl}${search}`)
    : undefined;

  return url?.toString();
}
