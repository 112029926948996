import { Box } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from '../button';
import { WebcamProps } from './types';

export const Webcam = ({
  styles,
  onSaveScreenshot,
}: WebcamProps): React.ReactElement => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const webcamVideo = useRef<HTMLVideoElement>(null);
  const webcamCanvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const video = webcamVideo.current;
    let stream: MediaStream | null = null;

    const handleVideoLoaded = (): void => {
      setVideoLoaded(true);
    };

    if (video) {
      video.addEventListener('loadedmetadata', handleVideoLoaded);
    }

    if (video && navigator.mediaDevices?.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then((newStream) => {
        stream = newStream;
        video.srcObject = newStream;
        video.play();
      });
    }

    return function cleanup(): void {
      if (video) {
        video.pause();
        video.src = '';
        if (stream) {
          stream.getTracks()[0].stop();
        }
        video.removeEventListener('loadedmetadata', handleVideoLoaded);
      }
    };
  }, []);

  function captureWebcamSnapshot(): void {
    const canvas = webcamCanvas.current;
    const video = webcamVideo.current;

    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      onSaveScreenshot(canvas.toDataURL('image/jpeg', 0.8));
    }
  }

  return (
    <Box sx={styles}>
      <video ref={webcamVideo} style={{ width: '100%' }} />
      <Box mt="12px">
        <Button
          isDisabled={!videoLoaded}
          isFullWidth
          onClick={captureWebcamSnapshot}
        >
          {videoLoaded ? 'Capture' : 'Waiting...'}
        </Button>
      </Box>
      <canvas ref={webcamCanvas} style={{ display: 'none' }} />
    </Box>
  );
};
