import { useAuth } from '@customer-frontend/auth';
import { NotEligibleQuizOutcome } from '@customer-frontend/graphql-types';
import { useQuizApplication } from '@customer-frontend/services';
import {
  Button,
  ButtonPalette,
  Card,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RejectedReasonWithExplanation, useRejectReasons } from './utils';
import { getConfig } from '@customer-frontend/config';
import { useShowZendeskWidget } from '@customer-frontend/consultation';

interface WeightRejectedProps {
  quizApplicationId: string;
  profileRoute: string;
  buttonPallete?: ButtonPalette;
}

export const WeightRejected = ({
  quizApplicationId,
  profileRoute,
  buttonPallete = 'default',
}: WeightRejectedProps): React.ReactElement => {
  const { title, countryCode } = getConfig();
  const history = useHistory();
  const { data: quizApplicationData, loading: quizApplicationLoading } =
    useQuizApplication(quizApplicationId);

  const rejectReasons = useRejectReasons();

  const { loggedInUser } = useAuth();

  useShowZendeskWidget({
    problemType: quizApplicationData?.getQuizApplication?.problemType,
  });

  if (quizApplicationLoading) {
    return (
      <div className="flex justify-center p-5 text-primary-500">
        <LoadingSpinner />
      </div>
    );
  }

  const ineligibleOutcome =
    quizApplicationData?.getQuizApplication?.outcomes?.find(
      (o): o is NotEligibleQuizOutcome =>
        o.__typename === 'NotEligibleQuizOutcome',
    );

  let reasonsForIneligibility: RejectedReasonWithExplanation[] = [];

  if (ineligibleOutcome) {
    const reasons = rejectReasons.filter((item) =>
      ineligibleOutcome.reasons?.includes(item.reason),
    );
    reasonsForIneligibility = reasons;
  }

  return (
    <div className="flex flex-col gap-6">
      <Typography size="xl" element="h1" isBold>
        <FormattedMessage
          defaultMessage="{firstName}, thank you for your answers"
          values={{ firstName: loggedInUser?.shortAddressableName }}
        />
      </Typography>
      <Card>
        <div className="mb-3">
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Your health will always be our top priority. Based on your responses, <strong>{brand}'s Weight Reset {isGb, select, true {Program} other {Programme}}</strong> is unlikely to be suitable for you. Here's why:"
              values={{
                strong: (chunks) => (
                  <strong className="font-strong">{chunks}</strong>
                ),
                brand: title,
                isGb: countryCode === 'GB',
              }}
            />
          </Typography>
        </div>
        <ul>
          {reasonsForIneligibility.map((item) => (
            <li key={item.heading} className="mt-2">
              <Typography isBold size="sm">
                {item.heading}
              </Typography>
              {item.paragraphs.map((paragraph) => (
                <div className="mt-2" key={paragraph?.toString()}>
                  <Typography size="medium-paragraph">{paragraph}</Typography>
                </div>
              ))}
            </li>
          ))}
        </ul>
      </Card>
      <div className="space-y-4 mt-8">
        <Button
          palette={buttonPallete}
          isFullWidth
          onClick={() => history.push(profileRoute)}
        >
          <FormattedMessage defaultMessage="Back home" />
        </Button>
      </div>
    </div>
  );
};
