import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import {
  Button,
  Modal,
  RadioButton,
  Typography,
} from '@eucalyptusvc/design-system';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { add, Duration } from 'date-fns';
import { useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

const calculateDelayedDate = (
  delayWeeks: string,
  nextShipmentDate: Date,
): Date => {
  const delay: Duration = delayWeeks === '2' ? { weeks: 2 } : { months: 1 };
  return add(new Date(nextShipmentDate), delay);
};

interface DelayOptions {
  value: string;
  name: string;
  label: MessageDescriptor;
  delayedDate: Date;
}

interface DelayModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelay: (date: string) => void;
  nextShipmentDate: Date;
  isDelayLoading: boolean;
  allowMonthDelay?: boolean;
}

const labels = defineMessages({
  oneMonthLabel: {
    defaultMessage: 'Delay by 1 month',
    description: 'Label for when a user wants to delay their treatment',
  },
  twoWeekLabel: {
    defaultMessage: 'Delay by 2 weeks',
    description: 'Label for when a user wants to delay their treatment',
  },
});

const getDelayOptions = (nextShipmentDate: Date): DelayOptions[] => {
  return [
    {
      value: '2',
      label: labels.twoWeekLabel,
      name: 'Delay by 2 weeks',
      delayedDate: calculateDelayedDate('2', nextShipmentDate),
    },
    {
      value: '4',
      label: labels.oneMonthLabel,
      name: 'Delay by 1 month',
      delayedDate: calculateDelayedDate('4', nextShipmentDate),
    },
  ];
};

export const DelayTreatmentModal = ({
  isOpen,
  onClose,
  onDelay,
  isDelayLoading,
  nextShipmentDate,
  allowMonthDelay = true,
}: DelayModalProps): JSX.Element => {
  const delayOptions = getDelayOptions(nextShipmentDate);
  const [selected, setSelected] = useState<string>(delayOptions[0].value);
  const { formatMessage } = useIntl();
  const config = getConfig();

  const onDelayClick = async (): Promise<void> => {
    await onDelay(
      calculateDelayedDate(selected, nextShipmentDate).toISOString(),
    );
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({ defaultMessage: 'Delay shipment' })}
    >
      <div className="flex flex-col space-y-8 ">
        {allowMonthDelay ? (
          delayOptions.map(({ name, label, value, delayedDate }) => (
            <div key={value} className="flex flex-col space-y-2">
              <RadioButton
                name={name}
                label={formatMessage(label)}
                value={value}
                isSelected={selected === value}
                onChange={(_, value) => setSelected(value)}
              />

              <Typography size="paragraph" inheritColor>
                <FormattedMessage
                  defaultMessage="We will process this shipment on {formattedDate}"
                  values={{
                    formattedDate: formatDate(
                      mapBrandToAdaptersBrand(config.brand),
                      delayedDate,
                      { day: 'numeric', month: 'long' },
                    ),
                  }}
                />
              </Typography>
            </div>
          ))
        ) : (
          <>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Your shipment will be delayed by 2 weeks. The next estimated shipment date will be <span>{date}</span>."
                values={{
                  span: (chunks) => (
                    <span className="font-medium">{chunks}</span>
                  ),
                  date: formatDate(
                    mapBrandToAdaptersBrand(config.brand),
                    calculateDelayedDate('2', nextShipmentDate),
                    { dateStyle: 'long' },
                  ),
                }}
              />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="If you would like to delay for more than 2 weeks, please email us at <a>{email}</a>."
                values={{
                  a: (chunks) => <a href={config.supportEmail}>{chunks}</a>,
                  email: config.supportEmail,
                }}
              />
            </Typography>
          </>
        )}
        <Button
          isDisabled={!selected}
          isFullWidth
          isLoading={isDelayLoading}
          level="primary"
          onClick={onDelayClick}
        >
          <FormattedMessage
            description="Label for confirm button"
            defaultMessage="Confirm"
          />
        </Button>
      </div>
    </Modal>
  );
};
