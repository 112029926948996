import Cookies from 'js-cookie';

const params = [
  'utm_name',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_campaign',
];

export const utmService = {
  saveUtmsToCookie: (): void => {
    const updateCookie = (key: string, value: string, expires: Date): void => {
      if (value) {
        Cookies.set(key, value, {
          path: '/',
          expires,
        });
      }
    };

    const updateExpiration = (key: string, expires: Date): void => {
      const existingValue = Cookies.get(key);
      if (existingValue) {
        Cookies.set(key, existingValue, {
          expires,
        });
      }
    };

    const removeCookies = (): void => {
      for (let index = 0; index < params.length; index += 1) {
        const param = params[index];
        Cookies.remove(param);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);

    const now = new Date();
    const expires = new Date(now.setMinutes(now.getMinutes() + 30));
    let removed = false;

    for (let index = 0; index < params.length; index += 1) {
      const param = params[index];
      const paramValue = urlParams.get(param);
      updateExpiration(param, expires);
      if (paramValue) {
        if (!removed) {
          removeCookies();
          removed = true;
        }
        updateCookie(param, paramValue, expires);
      }
    }
  },
  getUtmsHeaders: (): Record<string, string> => {
    const headers: Record<string, string> = {};

    const cookieKeys = [...params, 'ajs_anonymous_id'];

    for (let index = 0; index < cookieKeys.length; index += 1) {
      const cookieKey = cookieKeys[index];
      const cookieValue = Cookies.get(cookieKey);
      if (cookieValue) {
        headers[cookieKey] = encodeURIComponent(cookieValue);
      }
    }

    return headers;
  },
  getGaClientId(): string | undefined {
    try {
      return window.ga.getAll()[0].get('clientId');
    } catch (e) {
      return undefined;
    }
  },
};
