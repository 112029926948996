/** ⛔️ DEPRECATED ⛔️
 *  Please use `useNotification` from `@eucalyptusvc/design-system` instead.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  ToastType,
  ToastPalette,
  DesignSystemProvider,
} from '@eucalyptusvc/design-system';
import { Notifications } from './notifications';
import { Brand } from '@customer-frontend/types';

export interface NotificationMessage {
  duration?: number;
  type: ToastType;
  message?: string;
  palette?: ToastPalette;
}

const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong.';

const NOTIFICATION_DURATION_MS = 5000;

const MESSAGES: NotificationMessage[] = [];

const MESSAGE_CONTAINER_ID = '_NOTIFICATION_MESSAGE_CONTAINER_';

let _brand: Brand;

function renderMessages(): void {
  let container = document.getElementById(MESSAGE_CONTAINER_ID);
  if (!container) {
    container = document.createElement('div');
    container.id = MESSAGE_CONTAINER_ID;
    document.body.appendChild(container);
  }
  const root = createRoot(container);
  root.render(
    <DesignSystemProvider brand={_brand}>
      <Notifications
        messages={MESSAGES.map((m) => ({
          type: m.type,
          message: m.message ?? DEFAULT_ERROR_MESSAGE,
        }))}
      />
    </DesignSystemProvider>,
  );
}

/** ⛔️ DEPRECATED ⛔️
 *  Please use `useNotification` from `@eucalyptusvc/design-system` instead.
 */
export const notificationService = {
  init: ({ brand }: { brand: Brand }): void => {
    if (_brand) {
      throw new Error('Brand can only be initialised once');
    }
    _brand = brand;
  },
  show: (message: NotificationMessage): void => {
    if (!_brand) {
      throw new Error('Init method needs to be called');
    }
    MESSAGES.push(message);
    renderMessages();
    setTimeout(() => {
      const index = MESSAGES.findIndex((m) => m === message);
      if (index >= 0) {
        MESSAGES.splice(index, 1);
        renderMessages();
      }
    }, message.duration || NOTIFICATION_DURATION_MS);
  },
};
