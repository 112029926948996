import { useState, useEffect, useCallback } from 'react';
import {
  addOtcToTreatmentMutation,
  removeOtcFromTreatmentMutation,
  updateLineItemMutation,
} from '@customer-frontend/services';
import { notificationService } from '@customer-frontend/notifications';

import { useMutation } from '@apollo/client';
import {
  Maybe,
  Consultation,
  OtcScheduleCadence,
  MutationRemoveOtcProductFromTreatmentArgs,
  MutationUpdateLineItemArgs,
  MutationUpsertOtcProductToTreatmentArgs,
} from '@customer-frontend/graphql-types';
import { OtcProductWithCadence } from './types';
import {
  getActiveOtcProductWithCadenceFromTreatment,
  getUpdatedProducts,
  upsertCartProducts,
} from './utils';

interface CartProduct {
  id: string;
  variants: {
    id: string;
  }[];
}

interface CartProps {
  consultation: Maybe<Consultation>;
  products: Maybe<CartProduct[]>;
}

interface CartResponse {
  loading: boolean;
  cart: OtcProductWithCadence[];
  submitCart: () => Promise<void>;
  removeFromCart: (productId: string) => void;
  addToCart: (
    productId: string,
    cadence: OtcScheduleCadence,
    quantity?: number,
  ) => void;
}

export const useCart = ({
  consultation,
  products,
}: CartProps): CartResponse => {
  const [loading, setLoading] = useState(false);
  const [cartProducts, setCartProducts] = useState<OtcProductWithCadence[]>([]);

  const [updateOrderLineItem] = useMutation<MutationUpdateLineItemArgs>(
    updateLineItemMutation,
  );

  const [addOtcProductToTreatment] =
    useMutation<MutationUpsertOtcProductToTreatmentArgs>(
      addOtcToTreatmentMutation,
    );

  const [removeOtcProductFromTreatment] =
    useMutation<MutationRemoveOtcProductFromTreatmentArgs>(
      removeOtcFromTreatmentMutation,
    );

  useEffect(() => {
    if (!consultation) {
      return;
    }

    if (loading) {
      return;
    }

    const existingTreatmentOtcs = getActiveOtcProductWithCadenceFromTreatment(
      consultation.treatment,
    );

    setCartProducts(existingTreatmentOtcs);
  }, [consultation, loading]);

  const handleAddToCart = (
    productId: string,
    cadence: OtcScheduleCadence,
    quantity?: number,
  ): void => {
    const newCart = upsertCartProducts(cartProducts, {
      id: productId,
      cadence,
      quantity: quantity ?? 1,
    });
    setCartProducts(newCart);
  };

  const handleRemoveFromCart = (productId: string): void => {
    setCartProducts((cartProducts) =>
      cartProducts.filter(({ id }) => id !== productId),
    );
  };

  const handleCartSubmit = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const existingTreatmentOtcs = getActiveOtcProductWithCadenceFromTreatment(
        consultation?.treatment,
      );

      const { addedProducts, removedProductIds, upsertedProducts } =
        getUpdatedProducts(existingTreatmentOtcs, cartProducts);

      const treatmentId = consultation?.treatment?.id;
      const orderId = consultation?.order?.id;

      const updateLineItem = async (
        productId: string,
        quantity: number,
      ): Promise<void> => {
        const product = products?.find((p) => p.id === productId);

        await updateOrderLineItem({
          variables: {
            orderId,
            quantity,
            variantId: product?.variants?.[0]?.id,
          },
        });
      };

      if (!treatmentId || !orderId) {
        notificationService.show({
          type: 'error',
        });
      }

      // Add products not previously in the schedule
      for (const { id: productId, cadence, quantity } of [
        ...addedProducts,
        ...upsertedProducts,
      ]) {
        await updateLineItem(productId, quantity ?? 1);
        await addOtcProductToTreatment({
          variables: {
            productId,
            quantity: quantity ?? 1,
            id: treatmentId,
            source: 'initial',
            cadence,
          },
        });
      }

      // Remove products that were previously part of the treatment.
      for (const productId of removedProductIds) {
        await updateLineItem(productId, 0);
        await removeOtcProductFromTreatment({
          variables: {
            id: treatmentId,
            source: 'initial',
            productId: productId,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }, [
    addOtcProductToTreatment,
    cartProducts,
    consultation?.order?.id,
    consultation?.treatment,
    products,
    removeOtcProductFromTreatment,
    updateOrderLineItem,
  ]);

  return {
    loading,
    cart: cartProducts,
    addToCart: handleAddToCart,
    submitCart: handleCartSubmit,
    removeFromCart: handleRemoveFromCart,
  };
};
