import { ExperimentPaymentPlanVariant } from '@customer-frontend/graphql-types';
import React from 'react';

const getCadenceFromMonths = (months: number): string => {
  if (months < 1) {
    return '';
  }
  return months === 12 ? `1 year` : `${months} month${months > 1 ? 's' : ''}`;
};

export const useExperimentPaymentPlanWithCadence = (
  treatment?: {
    experimentPaymentPlan?: {
      paymentPlan: ExperimentPaymentPlanVariant;
      discountPercentage: number;
      months?: number | null;
    } | null;
  } | null,
):
  | {
      paymentPlan: ExperimentPaymentPlanVariant;
      discountPercentage: number;
      cadence: string;
    }
  | undefined =>
  React.useMemo<
    | {
        paymentPlan: ExperimentPaymentPlanVariant;
        discountPercentage: number;
        cadence: string;
      }
    | undefined
  >(() => {
    if (!treatment?.experimentPaymentPlan) {
      return undefined;
    }
    return {
      paymentPlan: treatment?.experimentPaymentPlan.paymentPlan,
      discountPercentage: treatment?.experimentPaymentPlan.discountPercentage,
      cadence: getCadenceFromMonths(
        treatment?.experimentPaymentPlan.months || 0,
      ),
    };
  }, [treatment]);
