import { type FunctionComponent, useState } from 'react';
import { FloatingMessageForm } from './form';
import { FloatingMessageButton } from './button';
import { Maybe } from '@customer-frontend/graphql-types';

export type FloatingMessageProps = {
  doctor: {
    shortClinicianName: string;
    avatar?: Maybe<{
      url: string;
    }>;
  };
  consultationId: string;
  onSubmit: () => void;
};

export const FloatingMessage: FunctionComponent<FloatingMessageProps> = ({
  doctor,
  consultationId,
  onSubmit,
}) => {
  const [formOpen, setFormOpen] = useState<boolean>(false);

  return (
    <div className="fixed bottom-0 pb-4">
      {formOpen ? (
        <FloatingMessageForm
          onHideClick={() => setFormOpen(false)}
          doctor={doctor}
          consultationId={consultationId}
          onSubmit={onSubmit}
        />
      ) : (
        <FloatingMessageButton
          onClick={() => setFormOpen(true)}
          doctor={doctor}
        />
      )}
    </div>
  );
};
