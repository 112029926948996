import { StyleFunctionProps, SystemStyleObject } from '@chakra-ui/theme-tools';
import { getWithFallbacks } from '../../shared/fonts';

const fontWeightRegular = 400;
const fontWeightBold = 600;

const headingLineHeightDesktop = '120%';
const headingLineHeightMobile = '90%';

export const text = {
  baseStyle: (props: StyleFunctionProps): SystemStyleObject => ({
    fontFamily: getWithFallbacks('PP Neue Montreal'),
    lineHeight: '120%',
    fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontStyle: props.isItalic ? 'italic' : 'normal',
  }),
  variants: {
    'landing-paragraph': {
      fontSize: '1.5rem', // 24px
    },
    'large-paragraph': {
      fontSize: '1.125rem', // 18px
    },
    'medium-paragraph': {
      fontSize: '1rem', // 16px
    },
    paragraph: {
      fontSize: '0.875rem', // 14px
    },
    'small-text': {
      fontSize: '0.75rem', // 12px
    },
  },
};

export const heading = {
  baseStyle: {
    fontFamily: getWithFallbacks('PP Neue Montreal'),
    wordBreak: 'break-word',
  },
  variants: {
    '2xl': (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '2.5rem' : '3rem',
      fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
      lineHeight: props.isMobile
        ? headingLineHeightMobile
        : headingLineHeightDesktop,
    }),
    xl: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '2rem' : '2.5rem',
      fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
      lineHeight: props.isMobile
        ? headingLineHeightMobile
        : headingLineHeightDesktop,
    }),
    lg: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '1.5rem' : '2rem',
      fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
      lineHeight: props.isMobile
        ? headingLineHeightMobile
        : headingLineHeightDesktop,
    }),
    md: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '1.25rem' : '1.5rem',
      fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
      lineHeight: props.isMobile
        ? headingLineHeightMobile
        : headingLineHeightDesktop,
    }),
    sm: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '1.125rem' : '1.25rem',
      fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
      lineHeight: props.isMobile
        ? headingLineHeightMobile
        : headingLineHeightDesktop,
    }),
    xs: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: '1.125rem',
      fontWeight: props.isBold ? fontWeightBold : fontWeightRegular,
      lineHeight: props.isMobile
        ? headingLineHeightMobile
        : headingLineHeightDesktop,
    }),
  },
};
