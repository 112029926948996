import { useIntl } from 'react-intl';
import { Control, Controller } from 'react-hook-form';

import { notificationService } from '@customer-frontend/notifications';
import { ImageUpload } from '@eucalyptusvc/design-system';

type Orientation = 'vertical' | 'horizontal';
interface ControlledImageUploadProps {
  control: Control;
  name: string;
  questionId: string;
  title?: string;
  description?: string;
  imgUrl?: string;
  loading?: boolean;
  errorMessage?: string;
  orientation?: Orientation;
  required?: boolean;
  reviewImageInstructions?: string[];
}

export function ControlledImageUpload(
  props: ControlledImageUploadProps,
): JSX.Element {
  const { formatMessage } = useIntl();
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={
        props.required
          ? {
              required: formatMessage({
                defaultMessage: 'Photo is required',
                description: 'Error message requiring photo',
              }),
            }
          : {}
      }
      render={({ onChange }): JSX.Element => (
        <>
          <ImageUpload
            isLoading={props.loading || false}
            onChange={onChange}
            placeholderUrl={props.imgUrl}
            title={props.title}
            hasSubmit
            description={props.description}
            orientation={props.orientation || 'vertical'}
            hasError={!!props.errorMessage}
            onUploadError={(errorMessage): void =>
              notificationService.show({ type: 'error', message: errorMessage })
            }
            reviewInstructions={props.reviewImageInstructions}
          />
        </>
      )}
    />
  );
}
