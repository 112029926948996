import { useEnvironment } from '@customer-frontend/environment';
import {
  PaymentGateway,
  SavedZipPaymentMethod,
} from '@customer-frontend/graphql-types';
import { ZipModalLauncher, ZipLogo } from '@customer-frontend/order';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

type ZipPaymentBannerProps = {
  classNames: string;
  paymentMethod: SavedZipPaymentMethod;
  onSetPaymentMethodAsDefault: (gateway: PaymentGateway) => void;
  onRemovePaymentMethod?: () => void;
};

export function ZipPaymentBanner({
  classNames,
  paymentMethod,
  onSetPaymentMethodAsDefault,
  onRemovePaymentMethod,
}: ZipPaymentBannerProps) {
  const environment = useEnvironment();

  return (
    <div
      className={clsx(
        'flex flex-grow justify-between items-center',
        classNames,
      )}
    >
      {environment.zipMerchantId && (
        <ZipModalLauncher merchantId={environment.zipMerchantId}>
          <ZipLogo />
        </ZipModalLauncher>
      )}

      <div className="flex items-center space-x-4">
        {!paymentMethod.default && (
          <button
            className="underline cursor-pointer"
            onClick={() => onSetPaymentMethodAsDefault('ZIP')}
          >
            <FormattedMessage
              defaultMessage="Set default"
              description="Button to set default payment method"
            />
          </button>
        )}

        {onRemovePaymentMethod && (
          <button
            className="underline cursor-pointer"
            onClick={onRemovePaymentMethod}
          >
            <FormattedMessage
              defaultMessage="Remove"
              description="Button for removing payment method"
            />
          </button>
        )}
      </div>
    </div>
  );
}
