import { z } from 'zod';

export type UserInfo = {
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  shortAddressableName?: string | null;
  fullName?: string | null;
};

export const UserInfoSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  shortAddressableName: z.string(),
  fullName: z.string(),
});
