import {
  ApolloClient,
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import {
  AddUserToSegmentMutation,
  AddUserToSegmentMutationVariables,
  Segment,
} from '@customer-frontend/graphql-types';

export const inSegmentDocument = gql`
  query InSegment($segmentName: Segment!) {
    inSegment(segmentName: $segmentName)
  }
`;

const addUserToSegmentDocument = gql`
  mutation AddUserToSegment(
    $segmentName: Segment!
    $activeFrom: String
    $activeTo: String
  ) {
    addUserToSegment(
      segmentName: $segmentName
      activeFrom: $activeFrom
      activeTo: $activeTo
    )
  }
`;

export function useAddUserToSegment(
  options: MutationHookOptions<
    AddUserToSegmentMutation,
    AddUserToSegmentMutationVariables
  >,
): MutationTuple<AddUserToSegmentMutation, AddUserToSegmentMutationVariables> {
  return useMutation<
    AddUserToSegmentMutation,
    AddUserToSegmentMutationVariables
  >(addUserToSegmentDocument, options);
}

export function updateSegmentLocalCache(
  client: ApolloClient<unknown>,
  segmentName: Segment,
): void {
  client.writeQuery({
    query: inSegmentDocument,
    data: { inSegment: true },
    variables: { segmentName },
  });
}
