import { useState, forwardRef } from 'react';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import { BaseCheckBoxProps } from './types';
import { getVariant } from './utils';
import { ReactComponent as CheckIcon } from '../../assets/checkbox-icon.svg';
import { FormWrapper } from '../input/form-wrapper';
import { useResponsive } from '../../hooks';
import { Typography } from '../typography';

const BaseCheckbox = (
  {
    name,
    value,
    label,
    disabled,
    onChange,
    iconRight,
    required,
    groupLabel,
    errorMessage,
    showBox = false,
    isChecked: isCheckedProp = false,
    palette = 'default',
  }: BaseCheckBoxProps,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  const isControlled = onChange !== undefined;
  const [internalChecked, setInternalChecked] =
    useState<boolean>(isCheckedProp);

  const { isMobile } = useResponsive();

  const handleCheckToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e);
    } else {
      setInternalChecked(e.target.checked);
    }
  };

  const boxPadding = isMobile
    ? '1.25rem' // 20px Mobile
    : '1rem'; // 16px Desktop
  const padding = showBox ? boxPadding : 0;

  const isCheckedFinal = isControlled ? isCheckedProp : internalChecked;

  const variant = getVariant(!!errorMessage, isCheckedFinal, showBox);

  return (
    <FormWrapper
      label={groupLabel}
      required={required}
      disabled={!!disabled}
      errorMessage={errorMessage}
      palette={palette}
      showMessageErrorIcon
    >
      <ChakraCheckbox
        size="lg"
        ref={ref}
        name={name}
        p={padding}
        value={value}
        spacing="0.5rem"
        variant={variant}
        colorScheme={palette}
        isDisabled={disabled}
        isChecked={isCheckedFinal}
        onChange={handleCheckToggle}
        icon={isCheckedFinal ? <CheckIcon /> : undefined}
      >
        <div className="flex justify-between items-center">
          {typeof label === 'string' ? (
            <Typography size="medium-paragraph">{label}</Typography>
          ) : (
            label
          )}
          {iconRight ? iconRight : null}
        </div>
      </ChakraCheckbox>
    </FormWrapper>
  );
};

/** Use **Checkbox** when a user needs to confirm a choice or toggle something
 * on/off. This can be either controlled or uncontrolled. */
export const Checkbox = forwardRef<HTMLInputElement, BaseCheckBoxProps>(
  BaseCheckbox,
);
