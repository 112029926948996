import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import {
  RefillSubscriptionV2MutationResult,
  RefillSubscriptionV2MutationVariables,
} from '@customer-frontend/graphql-types';

const refillSubscriptionV2Document = gql`
  mutation RefillSubscriptionV2($subscriptionId: String!) {
    refillSubscriptionV2(subscriptionId: $subscriptionId) {
      id
    }
  }
`;

export function useRefillSubscriptionV2(
  options?: MutationHookOptions<
    RefillSubscriptionV2MutationResult,
    RefillSubscriptionV2MutationVariables
  >,
): MutationTuple<
  RefillSubscriptionV2MutationResult,
  RefillSubscriptionV2MutationVariables
> {
  return useMutation<
    RefillSubscriptionV2MutationResult,
    RefillSubscriptionV2MutationVariables
  >(refillSubscriptionV2Document, options);
}
