import { Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as JuniperAdaptingToMedication } from '../assets/juniper-adapting-to-medication.svg';
import { ReactComponent as PilotAdaptingToMedication } from '../assets/pilot-adapting-to-medication.svg';

import { getConfig } from '@customer-frontend/config';
import { WeightTreatmentCategory } from '@customer-frontend/graphql-types';
import { Brand } from '@customer-frontend/types';
import { WISTIA_VIDEOS, WistiaVideo } from '@customer-frontend/video';

const SaxendaMostCommonSideEffects: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
}> = ({ handleVideoPlayed }) => {
  const config = getConfig();
  return (
    <>
      {config.brand === 'juniper' && (
        <WistiaVideo
          videoId={WISTIA_VIDEOS.HOW_TO_ADMINISTER_SAXENDA}
          videoClassName="rounded-b-none"
          onPlay={handleVideoPlayed}
        >
          <div className="rounded rounded-t-none bg-primary-500 p-4">
            <Typography size={'paragraph'} isBold color="white">
              <FormattedMessage defaultMessage="Managing side effects" />
            </Typography>
            <Typography size="paragraph" color="white">
              <FormattedMessage defaultMessage="With Dr Atkinson, Juniper GP" />
            </Typography>
          </div>
        </WistiaVideo>
      )}

      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="The most common side effect of Saxenda is nausea, and about 40% of users will experience this temporarily. The next most common side effect is a temporary mild increase in heart rate, and this is experienced by 34% of users." />
      </Typography>
    </>
  );
};

const OzempicMostCommonSideEffects: React.FC = () => (
  <Typography size="medium-paragraph">
    <FormattedMessage defaultMessage="The most common side effect is nausea, and about 40% of users will experience this temporarily." />
  </Typography>
);

const getMedicationCommonSideEffects = (
  handleVideoPlayed: (id: string, name: string) => void,
  weightTreatmentCategory: WeightTreatmentCategory,
): React.ReactNode => {
  switch (weightTreatmentCategory) {
    case 'saxenda':
      return (
        <SaxendaMostCommonSideEffects handleVideoPlayed={handleVideoPlayed} />
      );
    case 'ozempic':
      return <OzempicMostCommonSideEffects />;
    default:
      // Not ideal as this will show an empty section for a new medication, but I'd rather not default
      // to no side effects or one of the other side effects, as this should be filled in.
      // Given the number of other changes that will be needed to support additional medications, this should
      // be noticed during implementation.
      return null;
  }
};

const images: {
  [key in Brand]?: {
    adaptingToMedication: React.ReactNode;
  };
} = {
  juniper: {
    adaptingToMedication: <JuniperAdaptingToMedication />,
  },
  pilot: {
    adaptingToMedication: <PilotAdaptingToMedication />,
  },
};

export const SideEffectsContent: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
  weightTreatmentCategory: WeightTreatmentCategory;
}> = ({ handleVideoPlayed, weightTreatmentCategory }) => {
  const config = getConfig();
  const adaptingToMedication = images[config.brand]?.adaptingToMedication;
  const email = config.medicalEmail ?? config.supportEmail;
  return (
    <div className="space-y-4">
      {getMedicationCommonSideEffects(
        handleVideoPlayed,
        weightTreatmentCategory,
      )}
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="In some cases patients will experience diarrhoea and vomiting, but the good news is this should pass after a few days or weeks of continued use (depending on the individual) as your body adapts to the medication." />
      </Typography>
      <div className="flex justify-around">{adaptingToMedication}</div>
      <Typography size="xs" isBold>
        <FormattedMessage defaultMessage="Tips to minimise side effects" />
      </Typography>
      <Typography size="medium-paragraph">
        {/* eslint-disable react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
        <span className="text-xl">• </span>
        <FormattedMessage defaultMessage="Eat three regular meals a day, with healthy snacks in between." />
      </Typography>
      <Typography size="medium-paragraph">
        <span className="text-xl">• </span>
        <FormattedMessage defaultMessage="Drink plenty of cold, clear liquids." />
      </Typography>
      <Typography size="medium-paragraph">
        <span className="text-xl">• </span>
        <FormattedMessage defaultMessage="Avoid alcohol." />
      </Typography>
      <Typography size="medium-paragraph">
        <span className="text-xl">• </span>
        {/* eslint-enable react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
        <FormattedMessage defaultMessage="Avoid anti-inflammatory medications (NSAIDs)." />
      </Typography>
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="If you're concerned or need further advice on ways to manage and reduce your side effects, please reach out to your health coach or our medical support team (<a>{email}</a>)."
          values={{
            email,
            a: (chunks) => <a href={`mailto:${email}`}>{chunks}</a>,
          }}
        />
      </Typography>
    </div>
  );
};
