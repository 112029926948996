import React from 'react';
import { UseFormMethods, DeepMap, FieldError } from 'react-hook-form';
import {
  emailIsValid,
  matchesValue,
  minLengthValidation,
  requiredValidation,
} from '@customer-frontend/utils';
import {
  Card,
  LinkButton,
  TextInput,
  Typography,
} from '@eucalyptusvc/design-system';
import { PlanPaymentFormData } from './types';

export const SignupForm = ({
  register,
  errors,
  watch,
  onLoginClick,
}: {
  register: UseFormMethods['register'];
  errors: DeepMap<PlanPaymentFormData, FieldError>;
  watch: UseFormMethods['watch'];
  onLoginClick: () => void;
}): React.ReactElement => {
  return (
    <Card>
      <div className="space-y-4 mb-6">
        <Typography size="lg" isBold>
          Create an account
        </Typography>
        <Typography size="medium-paragraph">
          Already a member?{' '}
          <LinkButton onClick={onLoginClick}>Log in</LinkButton>
        </Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-1">
          <TextInput
            required
            ref={register({
              ...requiredValidation('first name'),
            })}
            label="First name"
            name="firstName"
            placeholder="First name"
            errorMessage={errors?.firstName?.message}
          />
        </div>
        <div className="col-span-1">
          <TextInput
            required
            ref={register({
              ...requiredValidation('last name'),
            })}
            label="Last name"
            name="lastName"
            placeholder="Last name"
            errorMessage={errors?.lastName?.message}
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <TextInput
            required
            ref={register({
              ...requiredValidation('email'),
              ...emailIsValid,
            })}
            label="Email"
            name="email"
            placeholder="Email"
            errorMessage={errors?.email?.message}
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <TextInput
            required
            ref={register({
              ...requiredValidation('phone'),
            })}
            label="Mobile number"
            name="phone"
            placeholder="Phone"
            errorMessage={errors?.phone?.message}
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <TextInput
            required
            name="password"
            ref={register({
              ...requiredValidation('password'),
              ...minLengthValidation('Password', 8),
            })}
            label="Password"
            type="password"
            className="font-password tracking-wider"
            errorMessage={errors?.password?.message}
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <TextInput
            required
            name="confirmPassword"
            ref={register({
              ...requiredValidation('confirm password'),
              ...matchesValue(watch().password, {
                name: 'confirmPassword',
                message: 'Passwords do not match',
              }),
            })}
            label="Confirm Password"
            type="password"
            className="font-password tracking-wider"
            errorMessage={errors?.confirmPassword?.message}
          />
        </div>
      </div>
    </Card>
  );
};
