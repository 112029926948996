import React, { forwardRef } from 'react';
import { Select } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from '../../assets/error-message-icon-diamond.svg';
import { ReactComponent as IconDown } from '../../assets/chevron-down-icon.svg';
import { BaseDropdownProps } from './types';
import { FormWrapper } from '../input/form-wrapper';
import { sharedColors } from '../../theme/shared/palette';

export const BaseDropdown = (
  {
    options,
    label,
    placeholder,
    palette,
    required,
    disabled,
    errorMessage,
    name,
    onChange,
  }: BaseDropdownProps,
  ref: React.Ref<HTMLSelectElement>,
): React.ReactElement => {
  return (
    <FormWrapper
      label={label}
      required={required}
      disabled={!!disabled}
      errorMessage={errorMessage}
      palette={palette}
    >
      <Select
        ref={ref}
        variant="default"
        color={errorMessage ? sharedColors.status.error[500] : ''}
        name={name}
        icon={!errorMessage ? <IconDown /> : <ErrorIcon />}
        placeholder={placeholder}
        onChange={onChange}
      >
        {options.map(({ label, value }) => {
          return (
            <option key={value} value={value}>
              {label}
            </option>
          );
        })}
      </Select>
    </FormWrapper>
  );
};

/** Use a **Dropdown** when you need a user's selection from a set of multiple
 * options.
 *
 * Note that setting a `placeholder` text creates a corresponding option in the
 * dropdown. If you want an option to be selected by default, you will need to
 * omit this prop. */
export const Dropdown = forwardRef<HTMLSelectElement, BaseDropdownProps>(
  BaseDropdown,
);
