import { GeneralQuizVerificationHooksConfig } from './types';
import { useNoVerification } from './use-no-verification';
import { useWeightLossSupportGeneralQuizVerification } from './use-weight-loss-support-general-quiz-verification';

/**
 * This config exists so it makes it easy to add verification around general quizzes. You can implement a hook
 * that aligns with the GeneralQuizVerificationFn contract and if verificationPassed is returned as false, the patient
 * gets redirect to their profile. Please add useNoVerification to your quizCode if no verification is required.
 */
export const generalQuizVerificationHooksConfig: GeneralQuizVerificationHooksConfig =
  {
    weightLossSupport: useWeightLossSupportGeneralQuizVerification,
    skinOnboardingQuiz: useNoVerification,
    skinCheckInQuiz: useNoVerification,
    skinSupportQuiz: useNoVerification,
  };
