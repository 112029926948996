import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Card } from '@eucalyptusvc/design-system';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';

const DEFAULT_DESCRIPTION =
  'Thanks for your order. Once we have processed your items, you will receive a tracking a link via email.';

export type SubscriptionV2ConfirmProps = {
  icon: string;
  profileRoute: string;
  title: string;
  description?: string;
};

export const SubscriptionV2Confirm: React.FC<SubscriptionV2ConfirmProps> = ({
  icon,
  profileRoute,
  title,
  description = DEFAULT_DESCRIPTION,
}) => {
  const history = useHistory();
  const config = getConfig();

  return (
    <Card>
      {/* Icon is purely decorative, no value is added by using alt text */}
      <div className="flex flex-col items-center mb-8 md:flex-row">
        <img src={icon} alt="" className="max-h-36 mx-auto mb-8 md:mb-0" />
        <div className="space-y-2 md:ml-8">
          <Typography size="xl" isBold>
            {title}
          </Typography>
          <Typography size="medium-paragraph">{description}</Typography>
        </div>
      </div>
      <Button
        level="primary"
        palette={getPrimaryButtonPalette(config.brand)}
        isFullWidth
        onClick={() => history.push(profileRoute)}
      >
        Go to profile
      </Button>
    </Card>
  );
};
