import {
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import { MutationUpsertOtcProductToTreatmentArgs } from '@customer-frontend/graphql-types';

import { OtcMutationResponse } from './types';
import { removeOtcFromTreatmentMutation } from './otc';

export const useRemoveOtcFromTreatmentMutation = (
  options?: MutationHookOptions<
    OtcMutationResponse,
    MutationUpsertOtcProductToTreatmentArgs
  >,
): MutationTuple<
  OtcMutationResponse,
  MutationUpsertOtcProductToTreatmentArgs
> => useMutation(removeOtcFromTreatmentMutation, options);
