import { MutationRefillTreatmentArgs } from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const refillTreatmentMutation = gql`
  mutation RefillTreatment($treatmentId: String!) {
    refillTreatment(treatmentId: $treatmentId, isPaused: false) {
      id
      status
      canRefill
      nextPayment
      experimentPaymentPlan {
        id
        nextPaymentAmount
        planEndDate
      }
    }
  }
`;

export function useRefillTreatment(
  options: MutationHookOptions<Response, MutationRefillTreatmentArgs>,
): MutationTuple<Response, MutationRefillTreatmentArgs> {
  return useMutation<Response, MutationRefillTreatmentArgs>(
    refillTreatmentMutation,
    options,
  );
}
