import { Maybe, Sex } from '@customer-frontend/graphql-types';

/**
 * @param sex sex at birth
 * @returns HI service sex type
 */
export const getSexOpt = (
  sex: Maybe<Sex> | undefined,
): 'M' | 'F' | 'I' | 'N' => {
  let sexOpt: 'M' | 'F' | 'I' | 'N' = 'N';
  if (sex === 'MALE') {
    sexOpt = 'M';
  } else if (sex === 'FEMALE') {
    sexOpt = 'F';
  }
  return sexOpt;
};

export const splitMedicareCardExpiryString = (
  medicareCardExpiry: string,
): { validToMonth: number; validToYear: number } => {
  if (medicareCardExpiry.indexOf('/') < 0) {
    return {
      validToMonth: 0,
      validToYear: 0,
    };
  }
  const [month, year] = medicareCardExpiry.split('/');
  const validToMonth = Number(month);
  const validToYear = Number(year);
  if (Number.isInteger(validToMonth) && Number.isInteger(validToYear)) {
    return {
      validToMonth,
      validToYear,
    };
  }
  return {
    validToMonth: 0,
    validToYear: 0,
  };
};
