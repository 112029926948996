import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { DashboardNavRoute } from './types';

export const getDashboardNavRouteFromPathname = (
  pathName: string,
): DashboardNavRoute | undefined => {
  switch (true) {
    case pathName.endsWith(DashboardNavRoute.FAQ):
      return DashboardNavRoute.FAQ;
    case pathName.endsWith(DashboardNavRoute.DASHBOARD):
      return DashboardNavRoute.DASHBOARD;
    case pathName.endsWith(DashboardNavRoute.LEARN):
      return DashboardNavRoute.LEARN;
    default:
      return;
  }
};

export const useEducationPlatformEnabled = (
  weightLossOrderCount?: number,
): boolean => {
  const featureFlagClient = useFeatureFlagClient();
  const educationFlagEvaluationOptions = weightLossOrderCount
    ? { additionalAttributes: { weightLossOrderCount } }
    : undefined;
  const educationPlatformEnrolment = featureFlagClient.getMultivariate(
    'EDUCATION_PLATFORM',
    educationFlagEvaluationOptions,
  );

  return educationPlatformEnrolment === 'variation';
};
