import { useState } from 'react';
import {
  useForm,
  DeepMap,
  FieldError,
  UseFormMethods,
  FormState,
  Control,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { notificationService } from '@customer-frontend/notifications';
import { useAnswerDoctorQuestions } from '@customer-frontend/services';

export interface AnswerDoctorQuestionsFormState {
  answers: Record<string, string>;
}

interface AnswerDoctorQuestionsFormReturn {
  loading: boolean;
  submit: () => Promise<void>;
  register: UseFormMethods['register'];
  errors: DeepMap<AnswerDoctorQuestionsFormState, FieldError>;
  formState: FormState<AnswerDoctorQuestionsFormState>;
  control: Control<AnswerDoctorQuestionsFormState>;
}

export const useAnswerDoctorQuestionsForm = (
  consultationId: string,
  onSubmit: () => Promise<unknown>,
): AnswerDoctorQuestionsFormReturn => {
  const [loading, setLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [answerDoctorQuestions] = useAnswerDoctorQuestions();

  const form = useForm<AnswerDoctorQuestionsFormState>();

  const submit = form.handleSubmit(async ({ answers }) => {
    // filter out null and empty string answers
    const filteredAnswers = Object.fromEntries(
      Object.entries(answers).filter(
        ([, value]) => value != null && value.trim().length > 0,
      ),
    );

    if (Object.keys(filteredAnswers).length === 0) {
      notificationService.show({
        type: 'error',
        message: formatMessage({
          defaultMessage: 'Please answer at least one question',
          description:
            'Error message shown when the user has not answered at least one question',
        }),
      });
      return;
    }

    try {
      setLoading(true);
      await answerDoctorQuestions({
        variables: {
          consultationId: consultationId,
          answers: filteredAnswers,
        },
      });
      await onSubmit();

      notificationService.show({
        type: 'success',
        message: formatMessage({
          defaultMessage: 'Answers submitted.',
          description:
            'Success message shown once the answers have been submitted',
        }),
      });
    } catch (err) {
      notificationService.show({
        type: 'error',
        message: formatMessage({
          defaultMessage: 'Unable to submit answers.',
          description: 'Error message when answers are unable to be submitted',
        }),
      });
      throw new Error('Unable to submit answers');
    } finally {
      setLoading(false);
    }
  });

  return { loading, submit, ...form };
};
