import { FormattedMessage } from 'react-intl';
import { GqlErrorCode } from '@eucalyptusvc/errors';
import { getConfig } from '@customer-frontend/config';

export const MedicareErrorMessage = ({
  errorCodes,
}: {
  errorCodes: GqlErrorCode[];
}): React.ReactElement => {
  const config = getConfig();
  if (errorCodes.includes(GqlErrorCode.IHI_DUPLICATE_IHI)) {
    return (
      <FormattedMessage
        defaultMessage="Your Medicare details are linked to another {title} account.{br}Contact us in chat or email us at <a>email</a> so we can resolve this for you."
        values={{
          title: config.title,
          br: <br />,
          a: () => {
            return (
              <a href={`mailto:${config.supportEmail}`} className="text-link">
                {config.supportEmail}
              </a>
            );
          },
        }}
      />
    );
  }

  if (
    errorCodes.some(
      (code) =>
        code === GqlErrorCode.IHI_MISMATCHING_RECORDS ||
        code === GqlErrorCode.IHI_NO_RECORDS_FOUND,
    )
  ) {
    return (
      <FormattedMessage
        defaultMessage="We can’t verify your Medicare details. This means one or more of the following details doesn’t match your Medicare record: <ul><li>Last name {maidenName}</li><li>Date of birth</li><li>Sex</li></ul>Please check these match and try again.{br}{br}If you need help with your Medicare record, <a>follow the instructions here</a> or message our team using the chat button."
        values={{
          maidenName: config.brand === 'juniper' ? '(or maiden name)' : '',
          br: <br />,
          ul: (chunks) => (
            <ul style={{ marginTop: '1em', marginBottom: '1em' }}>{chunks}</ul>
          ),
          li: (chunks) => (
            <li style={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
              {chunks}
            </li>
          ),
          a: (chunks) => {
            return (
              <a
                href={config?.helpArticles?.ihiLinkingWithMedicare}
                className="text-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </a>
            );
          },
        }}
      />
    );
  }

  return (
    <FormattedMessage
      defaultMessage="Please confirm your details are correct. If you need assistance retrieving or matching your Medicare details, follow the instructions <a>here</a> or message our team via the chat button on the bottom right corner of your screen."
      values={{
        a: (chunks) => {
          return (
            <a
              href={config?.helpArticles?.ihiLinkingWithMedicare}
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          );
        },
      }}
    />
  );
};
