import { useCallback, useState } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { ToggleTabsProps } from './types';
import { useResponsive } from '../../hooks';

/**
 * Toggle tabs let you alternate between related conditions or content easily.
 * Toggle tabs can make it easier for a user to navigate between options,
 * or switch between values for a specific question.
 *
 * Toggle tabs will stretch to fit the container and must contain at least two
 * options. If the label is longer than the button width it will wrap and cause
 * the height of the buttons to stretch.
 *
 * No more than 3 options is recommended unless absolutely necessary.
 */
export const ToggleTabs: React.FC<ToggleTabsProps> = ({
  options,
  initialSelected,
  onChange,
}) => {
  const { isMobile } = useResponsive();
  const [selectedOption, setSelectedOption] = useState(initialSelected);
  const handleChange = useCallback(
    (id: string): void => {
      setSelectedOption(id);
      onChange(id);
    },
    [setSelectedOption, onChange],
  );
  const fontSize = isMobile
    ? '1rem' // 16px Mobile
    : '1.125rem'; // 18px Desktop

  return (
    <ButtonGroup isAttached w="100%" display="flex">
      {options.map(({ id, label }) => (
        <Button
          key={id}
          onClick={() => handleChange(id)}
          variant={selectedOption === id ? 'toggle-tab-selected' : 'toggle-tab'}
          fontSize={fontSize}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
