import { Maybe, UnitSystem } from '@customer-frontend/graphql-types';
import { unit } from 'mathjs';

export const imperialToMetric = (
  major: string | number,
  minor: string | number,
  imperialMajorToMinorConversion: number,
  imperialMinorToMetricConversion: number,
): string => {
  return (
    (Number(major) * imperialMajorToMinorConversion + Number(minor)) *
    imperialMinorToMetricConversion
  )
    .toFixed(2)
    .toString();
};

export const convertHeight = (
  height: string,
  unitSystem?: Maybe<UnitSystem>,
): string => {
  if (unitSystem === 'METRIC') {
    return `${height} cm`;
  }

  if (unitSystem === 'IMPERIAL') {
    const [feet, inches] = unit(Number(height), 'cm').splitUnit(['ft', 'in']);
    const imperialHeight = `${feet.format({
      notation: 'fixed',
      precision: 0,
    })}, ${inches.format({
      notation: 'fixed',
      precision: 0,
    })}`;

    return imperialHeight;
  }

  return height;
};

export const convertAndFormatWeight = (
  weight: string,
  unitSystem?: Maybe<UnitSystem>,
): string => {
  if (unitSystem !== 'IMPERIAL') {
    return `${weight} kg`;
  }

  const [stone, pounds] = unit(Number(weight), 'kg').splitUnit(['stone', 'lb']);
  const imperialWeight = `${stone.format({
    notation: 'fixed',
    precision: 0,
  })}, ${pounds.format({
    notation: 'fixed',
    precision: 0,
  })}`;

  return imperialWeight;
};
