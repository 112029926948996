import {
  MutationUpdateProfileArgs,
  UseUpdateProfileWithAddressMutation,
  UseUpdateProfileWithAddressMutationVariables,
} from '@customer-frontend/graphql-types';
import { gql, MutationTuple, useMutation } from '@apollo/client';

export function useUpdateProfileWithAddressMutation(): MutationTuple<
  UseUpdateProfileWithAddressMutation,
  MutationUpdateProfileArgs
> {
  return useMutation<
    UseUpdateProfileWithAddressMutation,
    UseUpdateProfileWithAddressMutationVariables
  >(
    gql`
      mutation UseUpdateProfileWithAddress(
        $firstName: String
        $lastName: String
        $phone: String
        $password: String
        $address: AddressCreateWithoutUserInput
      ) {
        updateProfile(
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          password: $password
          address: $address
        ) {
          id
        }
      }
    `,
  );
}
