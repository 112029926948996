import {
  datadogLogs,
  HandlerType,
  LogsEvent,
  Logger as DatadogLogger,
} from '@datadog/browser-logs';
import { Brand, AppEnv } from '@customer-frontend/types';

export type LoggerConfig = {
  brand: Brand;
  appEnv: AppEnv;
  datadogToken: string;
};

// This error is caught by an error boundary, but still gets logged to console as an error
// and thus picked up by DataDog. This is not likely to change as per:
// https://github.com/facebook/react/issues/15069
// Hence we want to filter out this error kind to avoid sending them to DataDog in the meantime.
// This array will likely contain additional error kinds as we add additional error boundaries.
const errorKindsToFilterOut = ['ChunkLoadError'];

export type Logger = DatadogLogger;

const errorKindMatches = (error: LogsEvent['error']): boolean =>
  (!!error?.kind && errorKindsToFilterOut.includes(error.kind)) ||
  (!!error?.stack &&
    error.origin === 'console' &&
    errorKindsToFilterOut.some((errorKind) =>
      error?.stack?.startsWith(errorKind),
    ));

export function createLogger(config: LoggerConfig): Logger {
  const service = `${config.brand}-ui-${config.appEnv}`;

  datadogLogs.init({
    clientToken: config.datadogToken,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: config.appEnv,
    version: process.env.REACT_APP_RELEASE_VERSION,
    service,
    beforeSend: (event) => {
      if (errorKindMatches(event.error)) {
        return false;
      }
    },
  });

  datadogLogs.addLoggerGlobalContext('service', service);
  datadogLogs.addLoggerGlobalContext('environment', config.appEnv);

  const logger = datadogLogs.logger;
  logger.setHandler(
    config.appEnv === 'production' ? HandlerType.http : HandlerType.console,
  );
  return logger;
}

export function setLogUser(params: { userId?: string }): void {
  datadogLogs.addLoggerGlobalContext('user_id', params.userId);
}
