import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import {
  SubscriptionCardListFragment as SubscriptionCardListFragmentType,
  useReactivateSubscriptionV2Mutation,
} from '@customer-frontend/graphql-types';
import { Logger } from '@customer-frontend/logger';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { Button, useNotification } from '@eucalyptusvc/design-system';
import { gql } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { getSubscriptionProducts, getSubtotal } from '../utils';
import { SubscriptionActionModal } from './subscription-action-modal';
import { SubscriptionCard } from './subscription-card';

export const subscriptionCardListFragment = gql`
  fragment SubscriptionCardList on SubscriptionV2 {
    id
    status
    subscriptionPlan {
      id
      name
      interval
      intervalCount
      price
      photo {
        id
        url
      }
    }
    nextPaymentAt
  }
`;

export type SubscriptionCardListProps = {
  subscriptions: SubscriptionCardListFragmentType[];
  buildSubscriptionUrl: (subscriptionId: string) => string;
  logger: Logger;
  refetchData?: () => Promise<unknown>;
};

export const SubscriptionCardList: React.FC<SubscriptionCardListProps> = ({
  subscriptions,
  buildSubscriptionUrl,
  logger,
  refetchData,
}) => {
  const config = getConfig();
  const history = useHistory();
  const notify = useNotification();
  const [reactivateLoading, setReactivateLoading] = React.useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = React.useState<
    string | null
  >(null);
  const [reactivateSubscription] = useReactivateSubscriptionV2Mutation();
  const { otc: otcEvents } = useEventService();
  const handleReactivateSubscription = async (
    subscriptionId: string,
    price: number,
  ): Promise<void> => {
    let subscriptionResponse;
    try {
      setReactivateLoading(true);

      subscriptionResponse = await reactivateSubscription({
        variables: {
          subscriptionId,
        },
      });
    } catch {
      // errors handled by graphql client
    } finally {
      setReactivateLoading(false);
    }
    notify.success({
      message: 'Subscription reactivated',
    });

    setSelectedSubscriptionId(null);
    await refetchData?.();

    if (
      !subscriptionResponse?.data?.reactivateSubscriptionV2?.subscriptionOrders
    ) {
      // errors to be thrown by graphql client
      return;
    }

    const latestSubscriptionOrder =
      subscriptionResponse.data.reactivateSubscriptionV2.subscriptionOrders[0];
    if (!latestSubscriptionOrder) {
      throw Error('Unable to find latest subscription order');
    }

    await otcEvents.purchase({
      transaction_id: latestSubscriptionOrder.id,
      value: price,
      currency: config.currency,
      items:
        latestSubscriptionOrder.order?.lineItems?.map((orderLineItem) => ({
          price: orderLineItem.variant.price,
          id: orderLineItem.variant.id,
          quantity: 1,
        })) ?? [],
    });
  };

  if (!subscriptions.length) {
    return null;
  }

  return (
    <>
      {subscriptions.map((subscription) => {
        let action: React.ReactElement | null = null;
        const subscriptionPlan = subscription.subscriptionPlan;
        const products = getSubscriptionProducts(subscriptionPlan);
        const formattedPrice = formatCentsToCurrency(getSubtotal(products), {
          includeDecimals: true,
        });

        switch (subscription.status) {
          case 'PAYMENT_FAILED':
          case 'PROCESSING':
          case 'ACTIVE': {
            action = (
              <Button
                isFullWidth
                level="primary"
                onClick={() =>
                  history.push(buildSubscriptionUrl(subscription.id))
                }
              >
                Manage
              </Button>
            );
            break;
          }

          case 'CANCELED': {
            action = (
              <>
                <Button
                  isFullWidth
                  level="primary"
                  onClick={() => setSelectedSubscriptionId(subscription.id)}
                  isLoading={reactivateLoading}
                  isDisabled={reactivateLoading}
                >
                  Resume subscription
                </Button>
                <SubscriptionActionModal
                  onClose={() => setSelectedSubscriptionId(null)}
                  isOpen={selectedSubscriptionId === subscription.id}
                  onConfirm={() =>
                    handleReactivateSubscription(
                      subscription.id,
                      subscription.subscriptionPlan.price,
                    )
                  }
                  confirmText="Resume now"
                  bodyText={`By resuming your subscription, you will be charged ${formattedPrice} and we will ship your next order.`}
                  headingText="Resume subscription"
                  products={products}
                  planInterval={subscriptionPlan.interval}
                  planIntervalCount={subscriptionPlan.intervalCount}
                  loading={reactivateLoading}
                />
              </>
            );
            break;
          }

          default:
            break;
        }

        return (
          <SubscriptionCard
            key={subscription.id}
            {...subscription.subscriptionPlan}
            status={subscription.status}
            photoUrl={subscription.subscriptionPlan.photo.url}
            price={subscription.subscriptionPlan.price}
            nextPaymentDate={subscription.nextPaymentAt}
            logger={logger}
          >
            {action}
          </SubscriptionCard>
        );
      })}
    </>
  );
};
