import React from 'react';
import { FormattedMessage } from 'react-intl';
import type { Environment } from '@customer-frontend/environment';
import type { Logger } from '@customer-frontend/logger';
import { addRumError } from '@customer-frontend/real-user-monitoring';
import { Button, ButtonPalette, Typography } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';

type Props = {
  profileRoute: string;
  palette?: {
    messageColor?: string;
    refreshButton?: ButtonPalette;
    profileButton?: ButtonPalette;
  };
  environment: Environment;
  logger: Logger;
  children: React.ReactNode;
};

type State = {
  error?: Error;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { environment, logger } = this.props;

    addRumError(error, errorInfo);
    logger.error('Encountered error', { error, errorInfo });

    if (!environment.isProd) {
      // eslint-disable-next-line no-console
      console.error(error);
      // eslint-disable-next-line no-console
      console.error(errorInfo);
    }
  }

  render(): React.ReactNode {
    const { children, profileRoute, palette } = this.props;
    const { error } = this.state;
    const isChunkLoadError = !!error?.name.includes('ChunkLoadError');
    const config = getConfig();

    if (!error) {
      return children;
    }

    return (
      <section className="h-screen flex">
        <div className="w-2/3 md:w-1/3 lg:w-1/4 mx-auto my-auto space-y-8">
          <Typography
            size="sm"
            textAlign="center"
            color={palette?.messageColor}
          >
            {isChunkLoadError ? (
              <FormattedMessage
                defaultMessage="We've released a new update for {brandTitle}, please refresh to continue"
                description="Text prompting the user to refresh the page as there's an updated version of the site"
                values={{ brandTitle: config.title }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="Something has gone wrong, please refresh to continue"
                description="Text prompting the user to refresh the page as we have encountered an error"
              />
            )}
          </Typography>

          <div className="mx-auto flex flex-col gap-y-4">
            <Button
              isFullWidth
              palette={palette?.refreshButton}
              onClick={() => window.location.reload()}
            >
              <FormattedMessage
                defaultMessage="Refresh"
                description="Button text to refresh the page when an unexpected error is encountered"
              />
            </Button>

            {!isChunkLoadError && (
              <Button
                isFullWidth
                level="secondary"
                palette={palette?.profileButton}
                onClick={() => {
                  window.location.pathname = profileRoute;
                }}
              >
                <FormattedMessage
                  defaultMessage="Go to home"
                  description="Button text to go to home page when an unexpected error is encountered"
                />
              </Button>
            )}
          </div>
        </div>
      </section>
    );
  }
}
