import React from 'react';
import { Button, Markdown } from '@eucalyptusvc/design-system';
import { Maybe } from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';

export const SafetyInfo = ({
  safetyInformation,
  cmiUrl,
}: {
  safetyInformation: Maybe<string>;
  cmiUrl: Maybe<string>;
}): React.ReactElement | null => {
  if (!safetyInformation) {
    return null;
  }

  return (
    <div>
      <Markdown src={safetyInformation} formatWhitespace />
      {cmiUrl && (
        <div className="mt-5">
          <Button
            level="secondary"
            isFullWidth
            onClick={() => {
              window.open(cmiUrl, '_blank');
            }}
          >
            <FormattedMessage defaultMessage="Download safety sheet" />
          </Button>
        </div>
      )}
    </div>
  );
};
