import {
  MutationAddTreatmentDiscountArgs,
  Treatment,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { discountCodeFormFragment } from '../graphql';

interface Response {
  treatment: Treatment;
}

const addTreatmentDiscountMutation = gql`
  ${discountCodeFormFragment}
  mutation AddTreatmentDiscount($treatmentId: String!, $discountCode: String!) {
    addTreatmentDiscount(
      treatmentId: $treatmentId
      discountCode: $discountCode
    ) {
      id
      treatmentDiscount {
        id
        discountCode {
          ...DiscountCodeForm
        }
      }
    }
  }
`;

export function useAddTreatmentDiscount(
  options: MutationHookOptions<Response, MutationAddTreatmentDiscountArgs>,
): MutationTuple<Response, MutationAddTreatmentDiscountArgs> {
  return useMutation<Response, MutationAddTreatmentDiscountArgs>(
    addTreatmentDiscountMutation,
    options,
  );
}
