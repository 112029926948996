import {
  gql,
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import {
  MutationUpdateDefaultPaymentGatewayArgs,
  PaymentGateway,
} from '@customer-frontend/graphql-types';

const updateDefaultPaymentGatewayDocument = gql`
  mutation UpdateDefaultPaymentGateway($gateway: PaymentGateway!) {
    updateDefaultPaymentGateway(gateway: $gateway)
  }
`;

interface MutationData {
  gateway: PaymentGateway;
}

export const useUpdateDefaultPaymentGateway = (
  options?: MutationHookOptions<
    MutationData,
    MutationUpdateDefaultPaymentGatewayArgs
  >,
): MutationTuple<MutationData, MutationUpdateDefaultPaymentGatewayArgs> =>
  useMutation<MutationData, MutationUpdateDefaultPaymentGatewayArgs>(
    updateDefaultPaymentGatewayDocument,
    options,
  );
