import {
  Maybe,
  QuizQuestionOptionImageLayout,
} from '@customer-frontend/graphql-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, ListButton } from '@eucalyptusvc/design-system';

import React from 'react';
import { QuestionProps } from './types';
import clsx from 'clsx';

const getWrapperClassName = (
  layout: Maybe<QuizQuestionOptionImageLayout>,
): string => {
  switch (layout) {
    case 'LEFT_ICON':
      return 'w-full flex flex-row-reverse justify-end items-center';
    case 'RIGHT_ICON':
      return 'w-full flex justify-between items-center';
    case 'SQUARE':
      return 'w-full flex flex-col-reverse h-52';
    default:
      return '';
  }
};

const getImageClassName = (
  layout: Maybe<QuizQuestionOptionImageLayout>,
): string => {
  switch (layout) {
    case 'LEFT_ICON':
      return 'h-8 mr-2';
    case 'RIGHT_ICON':
      return 'h-8 ml-2';
    case 'SQUARE':
      return 'py-6 h-full mx-auto';
    case 'FULL_BUTTON':
      return 'max-h-24 mx-auto';
    default:
      return '';
  }
};

export function RadioImgQuestionInput({
  question,
}: QuestionProps): React.ReactElement {
  const { control } = useFormContext();
  const options = question.questionOptions;
  const layout = question.optionImgLayout;

  return (
    <Controller
      control={control}
      name={question.id}
      render={({ onChange }): JSX.Element => (
        <div
          className={clsx({
            'grid grid-cols-2 gap-2 max-w-sm mx-auto': layout === 'SQUARE',
            'space-y-2 align-bottom': layout !== 'SQUARE',
          })}
        >
          {options.map(({ option, imgUrl }) =>
            layout === 'FULL_BUTTON' && imgUrl ? (
              <button
                key={option}
                type="submit"
                className="cursor-pointer w-full"
                onClick={() => {
                  onChange(option);
                }}
              >
                <img src={imgUrl} className={getImageClassName(layout)} />
              </button>
            ) : (
              <ListButton
                key={option}
                isSubmit
                onClick={() => {
                  onChange(option);
                }}
              >
                <div className={getWrapperClassName(layout)}>
                  <Typography size="large-paragraph">{option}</Typography>
                  {imgUrl && (
                    <img src={imgUrl} className={getImageClassName(layout)} />
                  )}
                </div>
              </ListButton>
            ),
          )}
        </div>
      )}
    />
  );
}
