import { useQuery, gql, QueryResult } from '@apollo/client';
import { SubscriptionPlan } from '@customer-frontend/graphql-types';
import { subscriptionPlanFragment } from './subscription-plan-fragment';

export const useSubscriptionPlans = (): QueryResult<{
  subscriptionPlans: SubscriptionPlan[];
}> => {
  return useQuery<{ subscriptionPlans: SubscriptionPlan[] }>(
    gql`
      query OtcSubscriptionPlan {
        subscriptionPlans {
          ...OtcSubscriptionPlanInfo
        }
      }
      ${subscriptionPlanFragment}
    `,
  );
};
