import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link } from '@chakra-ui/react';
import { useResponsiveMultiStyleConfig } from '../../../hooks';
import { NavTabListProps, NavTabProps } from './types';

export const NavTabList: React.FC<NavTabListProps> = ({
  size,
  variant,
  children,
}) => {
  const styles = useResponsiveMultiStyleConfig('NavTabs', { size, variant });

  return (
    <Box as="nav" sx={styles.navTabList}>
      {children}
    </Box>
  );
};

export const NavTab: React.FC<NavTabProps> = ({
  size,
  variant,
  to,
  children,
}) => {
  const styles = useResponsiveMultiStyleConfig('NavTabs', { size, variant });

  return (
    <Link as={NavLink} to={to} sx={styles.navTab}>
      {children}
    </Link>
  );
};
