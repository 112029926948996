import { UserInfo } from './type';
import React, { useState, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { gql, useApolloClient } from '@apollo/client';
import { Logger, setLogUser } from '@customer-frontend/logger';
import {
  setRumUser,
  clearRumUser,
} from '@customer-frontend/real-user-monitoring';
import { AuthService } from './AuthService';
import { UiStorageType } from '@customer-frontend/ui-storage';
import { useNotification } from '@eucalyptusvc/design-system';
import { useEnvironment } from '@customer-frontend/environment';

interface Auth {
  isLoggedIn: boolean;
  hasManuallyLoggedOut: boolean;
  loggedInUser?: UserInfo;
  isImpersonatingUser: boolean;
  logout: () => void;
  login: (token: string, user: UserInfo) => void;
  updateUser: (user: UserInfo) => void;
  switchStorageType: (type: UiStorageType) => void;
}

export const AuthContext = React.createContext<Auth>({
  isLoggedIn: false,
  hasManuallyLoggedOut: false,
  isImpersonatingUser: false,
  logout: () => {
    throw new Error('AuthProvider is required');
  },
  login: () => {
    throw new Error('AuthProvider is required');
  },
  updateUser: () => {
    throw new Error('AuthProvider is required');
  },
  switchStorageType: () => {
    throw new Error('AuthProvider is required');
  },
});

export const useAuth = (): Auth => React.useContext(AuthContext);

export const AuthProvider = ({
  authService,
  children,
  onAuthChanged,
  logger,
}: {
  authService: AuthService;
  children: React.ReactNode;
  onAuthChanged: (userInfo?: UserInfo) => void;
  logger: Logger;
}): React.ReactElement => {
  const environment = useEnvironment();
  const [loggedInUser, setLoggedInUser] = useState<UserInfo | undefined>(
    authService.getUserInfo(),
  );
  const [isImpersonatingUser, setIsImpersonatingUser] = useState(
    authService.isImpersonatingUser(),
  );
  const notify = useNotification();
  const apolloClient = useApolloClient();

  const [isLoggedIn, setLoggedIn] = useState<boolean>(
    !!authService.getAccessToken(),
  );
  const [hasManuallyLoggedOut, setHasManuallyLoggedOut] =
    useState<boolean>(false);

  const logout = React.useCallback((): void => {
    setLoggedInUser(undefined);
    setLoggedIn(false);
    authService.clear();
    setHasManuallyLoggedOut(true);
    clearRumUser();
  }, [authService]);

  const login = React.useCallback(
    (token: string, user: UserInfo): void => {
      authService.setAccessToken(token);
      authService.setUserInfo(user);
      setLoggedInUser(user);
      setLoggedIn(true);
      setHasManuallyLoggedOut(false);
    },
    [authService],
  );

  const updateUser = React.useCallback(
    (user: UserInfo) => {
      authService.setUserInfo(user);
      setLoggedInUser(user);
    },
    [authService],
  );

  const switchStorageType = React.useCallback(
    (type: UiStorageType) => {
      authService.switchAuthStorageType(type);
    },
    [authService],
  );

  const refreshUser = React.useCallback(() => {
    apolloClient
      .query<{ profile: UserInfo }>({
        query: gql`
          query AuthProviderProfile {
            profile {
              id
              firstName
              lastName
              shortAddressableName
              fullName
              email
            }
          }
        `,
      })
      .then((resp) => {
        updateUser(resp.data.profile);
      });
  }, [apolloClient, updateUser]);

  useEffect(() => {
    setLogUser({
      userId: loggedInUser?.id ?? undefined,
    });
    if (loggedInUser) {
      setRumUser(loggedInUser.id);
    }
    if (window.zE && loggedInUser) {
      try {
        window.zE('webWidget', 'identify', {
          name: loggedInUser.fullName,
          email: loggedInUser.email,
        });
      } catch (error) {
        logger.error(`error thrown from Zendesk identify`, error);
      }
    }
    onAuthChanged(loggedInUser);
  }, [loggedInUser, onAuthChanged, logger]);

  // impersonationToken is provided as a query param to impersonate users
  // if the impersonationToken exists, add to local storage and set loggedIn to true
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const impersonationToken = urlParams.get('impersonationToken');
    if (impersonationToken) {
      authService.setAccessToken(impersonationToken);
      refreshUser();
      setLoggedIn(!!impersonationToken);

      const url = new URL(window.location.toString());
      url.searchParams.delete('impersonationToken');
      window.history.replaceState({ path: url.toString() }, '', url.toString());
    }
  }, [authService, refreshUser]);

  useEffect(() => {
    if (!isLoggedIn) {
      setIsImpersonatingUser(false);
    }
    setIsImpersonatingUser(authService.isImpersonatingUser());
  }, [authService, setIsImpersonatingUser, isLoggedIn]);

  useEffectOnce(() => {
    if (authService.getAccessToken() && !loggedInUser) {
      refreshUser();
    }
  });

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'c' && event.ctrlKey && event.metaKey) {
        navigator.clipboard.writeText(loggedInUser?.id ?? '').then(() => {
          notify.success({ message: 'Copied user id to clipboard' });
        });
      }
    },
    [loggedInUser?.id, notify],
  );

  useEffect(() => {
    if (environment.appEnv !== 'production') {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (environment.appEnv !== 'production') {
        window.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [handleKeyPress, environment]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        hasManuallyLoggedOut,
        loggedInUser,
        isImpersonatingUser,
        logout,
        login,
        updateUser,
        switchStorageType,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
