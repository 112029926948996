export interface BuildReadingRouteParams {
  programId?: string;
  collectionId?: string;
}

export const buildReadingRoute = (
  readingId: string,
  params?: BuildReadingRouteParams,
): string => {
  const baseUrl = `/education/readings/${readingId}`;

  const searchParams = new URLSearchParams({
    ...(params?.programId && { programId: params.programId }),
    ...(params?.collectionId && {
      collectionId: params.collectionId,
    }),
  }).toString();

  return `${baseUrl}${searchParams.length ? `?${searchParams}` : ''}`;
};
