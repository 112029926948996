import { gql, QueryResult, useQuery } from '@apollo/client';
import { User } from '@customer-frontend/graphql-types';

export const GET_PROFILE_CONSULTATIONS_QUERY = gql`
  query UseProfileConsultations {
    profile {
      id
      hasPaidMasterclass
      consultations(orderBy: { createdAt: desc }) {
        id
        type
        createdAt
        stage
        isApproved
        allowPatientToSendMessageToDoctor
        status
        pathologyRequests {
          id
        }
        doctor {
          id
          firstName
          lastName
          shortClinicianName
          fullClinicianName
          fullName
          provider {
            id
            clinicianType
            clinicianTitle
          }
        }
        patientQuestions {
          id
          createdAt
        }
        reminders {
          id
          canceled
          createdAt
          scheduledAt
          doneAt
        }
        quizApplication {
          id
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useProfileConsultations = (): QueryResult<{ profile: User }> => {
  return useQuery<{ profile: User }>(GET_PROFILE_CONSULTATIONS_QUERY, {
    fetchPolicy: 'network-only',
  });
};
