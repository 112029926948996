import { gql } from '@apollo/client';
import { useIntl } from 'react-intl';
import { type UsePurchaseCardContentPurchaseFragment } from '@customer-frontend/graphql-types';
import { type OrderDetailsProps } from '@customer-frontend/order';

export function usePurchaseCardContent({
  purchase,
}: {
  purchase?: UsePurchaseCardContentPurchaseFragment;
}): OrderDetailsProps | null {
  const { formatMessage } = useIntl();

  if (!purchase) {
    return null;
  }

  let refillsLeft: number | undefined;
  if (purchase.orderTimeline?.length !== 0) {
    refillsLeft = purchase.orderTimeline?.filter(
      ({ status }) => status === 'UPCOMING',
    ).length;
  }

  const orderItems =
    purchase.nextOrder?.products?.map((p) => ({
      name: p.friendlyName || p.name || '',
    })) ?? [];

  const treatmentName = purchase.offering?.friendlyName;

  const hasActiveContext = purchase.contexts?.some(
    (c) =>
      c.status === 'ACTIVE' &&
      c.sequence?.products?.some((p) => ['OTC', 'RX'].includes(p.productType)),
  );

  switch (purchase.status) {
    case 'AWAITING_PAYMENT':
      return {
        treatmentName,
        status: formatMessage({
          defaultMessage: 'Your order is being processed',
          description: 'Status label informing users their order is processing',
        }),
        orderItems: [],
      };
    case 'AWAITING_VALID_SCRIPTS':
      return {
        treatmentName,
        status: formatMessage({
          defaultMessage: 'Your order is being processed',
          description: 'Status label informing users their order is processing',
        }),
        orderItems: [],
        refillsLeft,
      };
    case 'ACTIVE':
      if (hasActiveContext) {
        return {
          treatmentName,
          status: formatMessage({ defaultMessage: 'Active' }),
          nextPayment: purchase.nextOrder?.date,
          orderItems,
        };
      } else {
        return {
          treatmentName,
          status: formatMessage({ defaultMessage: 'Paused' }),
          orderItems: [],
        };
      }
    case 'PAUSED':
      return {
        treatmentName,
        status: formatMessage({ defaultMessage: 'Paused' }),
        orderItems: [],
      };
    case 'COMPLETED':
      return {
        treatmentName,
        status: formatMessage({ defaultMessage: 'Completed' }),
        orderItems: [],
      };
    default:
      return null;
  }
}

usePurchaseCardContent.fragment = gql`
  fragment UsePurchaseCardContentPurchase on Purchase {
    id
    status
    offering {
      id
      friendlyName
    }
    orderTimeline {
      id
      status
    }
    nextOrder {
      id
      date
      products {
        id
        friendlyName
        name
      }
    }
    contexts {
      id
      status
      sequence {
        id
        products {
          id
          productType
        }
      }
    }
  }
`;
