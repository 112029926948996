import { z } from 'zod';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { ProductVariantInventorySku } from './product';

const evermedConfigSchema = z.object({
  fulfillableSkus: z.array(z.string()),
});

export const useIsEvermedProduct = (
  product?: ProductVariantInventorySku | null,
): boolean => {
  const { fulfillableSkus } = evermedConfigSchema.parse(
    useFeatureFlagClient().getJson<string[]>('EVERMED_CONFIG'),
  );

  return Boolean(
    product &&
      product.variants.some(
        (variant) =>
          variant.inventory?.sku &&
          fulfillableSkus.includes(variant.inventory.sku),
      ),
  );
};
