import { MedicareAnswerOpted } from './types';

const MEDICARE_ANSWER_OPTED_LS_KEY = 'medicare-answer-opted';

export const saveMedicareAnsOptedToSS = (opted: MedicareAnswerOpted): void => {
  sessionStorage.setItem(MEDICARE_ANSWER_OPTED_LS_KEY, opted);
};

export const getMedicareAnsOptedFromSS = ():
  | MedicareAnswerOpted
  | undefined => {
  const optedStr: string | null = sessionStorage.getItem(
    MEDICARE_ANSWER_OPTED_LS_KEY,
  );
  if (optedStr) {
    const optedEnum: MedicareAnswerOpted = <MedicareAnswerOpted>optedStr;
    return optedEnum;
  }
  return;
};

export const removeMedicareAnsOptedFromSS = (): void => {
  sessionStorage.removeItem(MEDICARE_ANSWER_OPTED_LS_KEY);
};
