export interface MedicareComponentAttributes {
  firstName: string;
  lastName: string;
  birthday: string;
  sex: 'M' | 'F' | 'I' | 'N';
  medicareNumber: string;
  medicareIrn: string;
  medicareCardExpiry: string;
}

export enum MedicareFormOnPage {
  postQuiz = 'POST_QUIZ',
  postFollowUpQuiz = 'POST_FOLLOW_UP_QUIZ',
  postReviewQuiz = 'POST_REVIEW_QUIZ',
  consultPaid = 'CP_CONF',
  patientProfile = 'PATIENT_PROF',
}

export enum MedicareAnswerOpted {
  SubmittedMedicare = 'SubmittedMedicare',
  NoMedicare = 'NoMedicare',
  GiveMedicareLater = 'GiveMedicareLater',
}
