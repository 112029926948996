import { getConfig } from '@customer-frontend/config';
import type {
  ConsultationAnswerType,
  ConsultationReminder,
} from '@customer-frontend/graphql-types';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

type SupportedAnswerType = Extract<
  ConsultationAnswerType,
  'IMAGE' | 'TEXT' | 'OBSERVATION'
>;

const SUPPORTED_ANSWERS: Readonly<SupportedAnswerType[]> = [
  'IMAGE',
  'TEXT',
  'OBSERVATION',
];

export const isSupportedAnswerType = (
  type: unknown,
): type is SupportedAnswerType => {
  return SUPPORTED_ANSWERS.includes(type as SupportedAnswerType);
};

type ReminderMessageInfo = {
  message: string;
  status: 'CREATED' | 'CANCELLED' | 'SENT';
  createdAt: Date;
  id: string;
};

export const buildReminderMessageInfo = (
  reminders?: (ConsultationReminder | null | undefined)[] | null,
): ReminderMessageInfo[] => {
  if (!reminders) {
    return [];
  }

  const { brand } = getConfig();
  const messageInfo: ReminderMessageInfo[] = [];
  for (const reminder of reminders) {
    if (!reminder) {
      continue;
    }
    messageInfo.push({
      id: `${reminder.id}_created`,
      createdAt: reminder.createdAt,
      message: `Reminder has been scheduled for ${formatDate(
        mapBrandToAdaptersBrand(brand),
        reminder.scheduledAt,
        { dateStyle: 'full' },
      )}.`,
      status: 'CREATED',
    });
    if (reminder.canceled) {
      messageInfo.push({
        id: `${reminder.id}_canceled`,
        createdAt: reminder.doneAt,
        message: 'Reminder has been cancelled',
        status: 'CANCELLED',
      });
    } else if (reminder.doneAt) {
      messageInfo.push({
        id: `${reminder.id}_sent`,
        createdAt: reminder.doneAt,
        message:
          'Reminder: Please let your practitioner know whether you would like to proceed.',
        status: 'SENT',
      });
    }
  }
  return messageInfo;
};
