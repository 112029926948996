import {
  Button,
  Card,
  CardPalette,
  Divider,
  LinkButton,
  Typography,
} from '@eucalyptusvc/design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useEnvironment } from '@customer-frontend/environment';
import { ReactComponent as ExternaLinkIcon } from '../assets/external-link.svg';
import { getZendeskRequestUrl } from '@customer-frontend/utils';

interface AlternateMedicationsCardProps {
  isDoctorsNote: boolean;
  isMedicationShortagePhasePhentop: boolean;
  isMedicationShortagePhaseOzempic: boolean;
  medicationImage: string;
  medicationProgram: string;
  paletteVariant: CardPalette;
  showDivider?: boolean;
}

export const AlternateMedicationsCard = ({
  isDoctorsNote,
  isMedicationShortagePhasePhentop,
  isMedicationShortagePhaseOzempic,
  medicationImage,
  medicationProgram,
  paletteVariant,
  showDivider,
}: AlternateMedicationsCardProps): React.ReactElement => {
  if (isMedicationShortagePhasePhentop) {
    return (
      <MedicationShortagePhasePhentopCard
        isDoctorsNote={isDoctorsNote}
        medicationImage={medicationImage}
        medicationProgram={medicationProgram}
        paletteVariant={paletteVariant}
        showDivider={showDivider}
      />
    );
  }
  if (isMedicationShortagePhaseOzempic) {
    return (
      <MedicationShortagePhaseOzempicCard
        medicationImage={medicationImage}
        paletteVariant={paletteVariant}
        showDivider={showDivider}
      />
    );
  }
  return <></>;
};

interface MedicationShortagePhasePhentopCardProps {
  isDoctorsNote: boolean;
  medicationImage: string;
  medicationProgram: string;
  paletteVariant: CardPalette;
  showDivider?: boolean;
}

export const MedicationShortagePhasePhentopCard = ({
  isDoctorsNote,
  medicationImage,
  medicationProgram,
  paletteVariant,
  showDivider,
}: MedicationShortagePhasePhentopCardProps): React.ReactElement => {
  const environment = useEnvironment();
  const faqLink = `${environment.landingPageUrl}/profile/alternative-medications`;
  const eligibleLink = getZendeskRequestUrl({ problemType: 'WEIGHT_LOSS' });

  return (
    <Card palette={paletteVariant}>
      <div className="space-y-4">
        <div className={showDivider ? 'space-y-4' : 'space-y-2'}>
          <Typography size="md" isBold>
            {isDoctorsNote ? (
              <FormattedMessage defaultMessage="Saxenda is temporarily out of stock" />
            ) : (
              <FormattedMessage defaultMessage="Other medication programs available during stock out" />
            )}
          </Typography>
          {showDivider && <Divider />}
          <Typography size="medium-paragraph">
            {isDoctorsNote ? (
              <FormattedMessage defaultMessage="While we await incoming stock of GLP-1 medications, our practitioners are recommending a bridging program to help you keep momentum." />
            ) : (
              <FormattedMessage defaultMessage="As an interim solution while GLP-1 medication is out of stock, we recommend a bridging program to keep your momentum." />
            )}
          </Typography>
        </div>
        <div className="flex flex-col md:flex-row gap-y-2 gap-x-5">
          <div className="bg-white rounded border border-primary-300 p-4 flex-1 flex flex-flow-col space-x-4 justify-between">
            <div className="flex flex-row space-x-4">
              <div>
                <img src={medicationImage} className="w-16 h-16" alt="" />
              </div>
              <div className="flex flex-col space-y-1">
                <Typography isBold size="paragraph">
                  <FormattedMessage
                    defaultMessage="Phentermine / Topiramate"
                    description="Name of the alternate medication"
                  />
                </Typography>
                <Typography size="paragraph">
                  <FormattedMessage
                    defaultMessage="Daily tablet"
                    description="Form of alternate medication"
                  />
                </Typography>
                <Typography isBold size="paragraph">
                  <FormattedMessage
                    defaultMessage="$199 / month"
                    description="Pricing for the alternate medication"
                  />
                </Typography>
              </div>
            </div>
          </div>
          <div className="bg-white rounded border border-primary-300 p-4 flex-1 flex flex-row space-x-4 justify-between">
            <div className="flex flex-row space-x-4">
              <div>
                <img src={medicationProgram} className="w-16 h-16" alt="" />
              </div>
              <div className="flex flex-col space-y-1">
                <Typography isBold size="paragraph">
                  <FormattedMessage
                    defaultMessage="LCD Accelerate Program"
                    description="Title of suggested program"
                  />
                </Typography>
                <Typography size="paragraph">
                  <FormattedMessage
                    defaultMessage="12 week program"
                    description="Length of suggested program"
                  />
                </Typography>
                <Typography isBold size="paragraph">
                  <FormattedMessage
                    defaultMessage="$58.80 <a>$196</a> / month"
                    description="Pricing for suggested program"
                    values={{
                      a: (chunk) => <del>{chunk}</del>,
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <Button
          isFullWidth
          level="primary"
          palette="alternate"
          onClick={() => window.open(eligibleLink)}
        >
          <FormattedMessage
            defaultMessage="See if I'm eligible"
            description="Button allowing user to check if they are eligible for alternative medications"
          />
        </Button>
        <div className="flex justify-center">
          <LinkButton
            iconLeft={<ExternaLinkIcon />}
            onClick={() => window.open(faqLink)}
          >
            <FormattedMessage
              defaultMessage="Read FAQ"
              description="Link allowing user to read the FAQ on alternative medications"
            />
          </LinkButton>
        </div>
      </div>
    </Card>
  );
};

interface MedicationShortagePhaseOzempicCardProps {
  medicationImage: string;
  paletteVariant: CardPalette;
  showDivider?: boolean;
}

export const MedicationShortagePhaseOzempicCard = ({
  medicationImage,
  paletteVariant,
  showDivider,
}: MedicationShortagePhaseOzempicCardProps): React.ReactElement => {
  const environment = useEnvironment();
  const faqLink = `${environment.landingPageUrl}/profile/alternative-medications`;
  const eligibleLink = getZendeskRequestUrl({ problemType: 'WEIGHT_LOSS' });

  return (
    <Card palette={paletteVariant}>
      <div className="space-y-4">
        <div className={showDivider ? 'space-y-4' : 'space-y-2'}>
          <Typography size="md" isBold>
            <FormattedMessage defaultMessage="Important Medication Update" />
          </Typography>
          {showDivider && <Divider />}
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Due to ongoing shortages, we are unable to fulfil orders of Saxenda. We have been working hard to find suitable alternatives." />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="As a result, Juniper is offering Ozempic, an alternative GLP-1 treatment that is more affordable and proven to be highly effective in treating diabetes and obesity. You may be eligible for this treatment." />
          </Typography>
        </div>
        <div className="flex flex-col md:flex-row gap-y-2 gap-x-5">
          <div className="bg-white rounded border border-primary-300 p-4 flex-1 flex flex-flow-col space-x-4 justify-between">
            <div className="flex flex-row space-x-4">
              <div>
                <img src={medicationImage} className="w-16 h-16" alt="" />
              </div>
              <div className="flex flex-col space-y-1">
                <Typography isBold size="paragraph">
                  <FormattedMessage
                    defaultMessage="Semaglutide (Ozempic)"
                    description="Name of the alternate medication"
                  />
                </Typography>
                <Typography size="paragraph">
                  <FormattedMessage
                    defaultMessage="Once-a-week medication"
                    description="Form of alternate medication"
                  />
                </Typography>
                <Typography isBold size="paragraph">
                  <FormattedMessage
                    defaultMessage="$285 / month"
                    description="Pricing for the alternate medication"
                  />
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <Button
          isFullWidth
          level="primary"
          palette="alternate"
          onClick={() => window.open(eligibleLink)}
        >
          <FormattedMessage
            defaultMessage="See if I'm eligible"
            description="Button allowing user to check if they are eligible for alternative medications"
          />
        </Button>
        <div className="flex justify-center">
          <LinkButton
            iconLeft={<ExternaLinkIcon />}
            onClick={() => window.open(faqLink)}
          >
            <FormattedMessage
              defaultMessage="Read FAQ"
              description="Link allowing user to read the FAQ on alternative medications"
            />
          </LinkButton>
        </div>
      </div>
    </Card>
  );
};
