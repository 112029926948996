import React, { ElementType, ReactElement } from 'react';
import { Route, RouteProps, Redirect, RedirectProps } from 'react-router-dom';
import { useAuth } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import { DefaultLayout } from './default-layout';
import * as qs from 'query-string';

export interface PrivateRouteProps extends RouteProps {
  layout?: ElementType;
}

export function PrivateRoute({
  children,
  layout: Layout = DefaultLayout,
  ...rest
}: PrivateRouteProps): ReactElement {
  const auth = useAuth();
  const { loginUrl } = getConfig();

  return (
    <Route
      {...rest}
      render={({ location }): React.ReactNode => {
        const redirectProps: RedirectProps = {
          to: {
            pathname: loginUrl,
          },
        };

        if (!auth.hasManuallyLoggedOut) {
          redirectProps.to.search = qs.stringify({
            redirect: `${location.pathname}${location.search}${location.hash}`,
          });
        }

        return auth.isLoggedIn ? (
          <Layout key={rest.path}>{children}</Layout>
        ) : (
          <Redirect {...redirectProps} />
        );
      }}
    />
  );
}
