import React from 'react';
import { useIntl } from 'react-intl';
import type {
  ProblemType,
  QuizPurpose,
} from '@customer-frontend/graphql-types';
import { useAuth } from '@customer-frontend/auth';
import { Typography } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';

export interface StartQuizProps {
  isReview: boolean;
  isFollowUp: boolean;
  problemType?: ProblemType;
  purpose?: QuizPurpose;
}

export const StartQuiz = ({
  isReview,
  isFollowUp,
}: StartQuizProps): React.ReactElement => {
  const { loggedInUser } = useAuth();
  const { formatMessage } = useIntl();
  let title;
  let body;
  switch (true) {
    case isReview:
      title = formatMessage(
        {
          defaultMessage: 'Let’s get you on your way, {name}!',
        },
        { name: loggedInUser?.shortAddressableName },
      );
      body = formatMessage(
        {
          defaultMessage:
            'Your {isGb, select, true {prescriber} other {practitioner}} is waiting on the other side. But first, let’s get an update on your health details.',
        },
        { isGb: getConfig().countryCode === 'GB' },
      );
      break;
    case isFollowUp:
      title = formatMessage(
        {
          defaultMessage: 'Let’s get you on your way, {name}!',
        },
        { name: loggedInUser?.shortAddressableName },
      );
      body = formatMessage(
        {
          defaultMessage:
            'Your {isGb, select, true {prescriber} other {practitioner}} is waiting on the other side for your follow up consult. But first, let’s get an update on your health details.',
        },
        { isGb: getConfig().countryCode === 'GB' },
      );
      break;
    default:
      title = formatMessage({
        defaultMessage: 'You’re one step closer!',
      });
      body = formatMessage({
        defaultMessage:
          'We need to ask you a few more questions to see whether you’re suitable for the treatment.',
      });
  }

  return (
    <>
      <Typography element="h1" size="xl" isBold>
        {title}
      </Typography>

      <Typography size="medium-paragraph">{body}</Typography>
    </>
  );
};
