import { useForm } from 'react-hook-form';
import { GqlErrorCode } from '@eucalyptusvc/errors';
import { UseMedicareDetailsFormReturn } from './types';
import { useUpdateMedicareDetails } from '../use-update-healthcare-details';
import { MedicareComponentAttributes } from '../../components/types';

export const useMedicareDetailsForm = (
  defaultValues: Partial<MedicareComponentAttributes>,
  validationLevel: 'required' | 'all',
  beforeSubmit?: (validFormat: boolean) => void,
  afterSubmit?: (result: boolean, errorCodes: GqlErrorCode[]) => void,
): UseMedicareDetailsFormReturn => {
  const { register, handleSubmit, errors, trigger, setValue, getValues } =
    useForm<MedicareComponentAttributes>({
      reValidateMode: 'onSubmit',
      defaultValues,
    });
  const skipErrorNotification = validationLevel === 'required';
  const { updateMedicareDetails, loading } = useUpdateMedicareDetails(
    skipErrorNotification,
    afterSubmit,
  );
  const submit = handleSubmit(
    async ({
      firstName,
      lastName,
      sex,
      birthday,
      medicareNumber,
      medicareIrn,
      medicareCardExpiry,
    }) => {
      if (beforeSubmit) {
        beforeSubmit(true);
      }
      await updateMedicareDetails({
        firstName,
        lastName,
        medicareNumber,
        medicareIrn,
        sex,
        birthday,
        medicareCardExpiry,
      });
    },
    async () => {
      if (beforeSubmit) {
        beforeSubmit(false);
      }
    },
  );

  return {
    register,
    errors,
    submit,
    trigger,
    loading,
    setValue,
    getValues,
  };
};
