import {
  DiscountCodeFormFragment,
  DiscountStage,
} from '@customer-frontend/graphql-types';

export const isDiscountValid = (
  code: DiscountCodeFormFragment,
  stage: DiscountStage,
  products: { id: string }[],
  skipProductValidation: boolean,
): boolean => {
  // If there is no products in the discount code, it applies to the whole order
  const someProductInCartIncludedInDiscountCode = products.some(({ id }) =>
    code.products.some((codeProduct) => codeProduct.id === id),
  );
  if (
    !skipProductValidation &&
    code.products.length &&
    !someProductInCartIncludedInDiscountCode
  ) {
    return false;
  }

  if (code.stages.length === 0 || !stage) {
    return true;
  }

  return code.stages.some((cStage) => cStage === stage);
};
