import clsx from 'clsx';

type LayoutProps = {
  className?: string;
  children: React.ReactNode;
};

export const Layout = ({
  className,
  children,
}: LayoutProps): React.ReactElement => {
  return (
    <section
      className={clsx('max-w-screen-sm px-4 mx-auto space-y-6', className)}
    >
      {children}
    </section>
  );
};
