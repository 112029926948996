import { useState } from 'react';
import { Button } from '@eucalyptusvc/design-system';
import { Maybe, QuizQuestion } from '@customer-frontend/graphql-types';
import { useQuizState } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';

import { getSecondaryButtonPalette } from './helpers';
import { useEnvironment } from '@customer-frontend/environment';

type Question = {
  id: QuizQuestion['id'];
  shortcode?: QuizQuestion['shortcode'];
  type: QuizQuestion['type'];
  options?: QuizQuestion['options'];
};

export const SkipQuizButton: React.FC<{
  quizQuestions: Question[];
}> = ({ quizQuestions }) => {
  const state = useQuizState();
  const [loading, setLoading] = useState(false);
  const config = getConfig();
  const environment = useEnvironment();

  if (environment.appEnv === 'production') {
    return null;
  }

  const completeQuiz = async (): Promise<void> => {
    if (!quizQuestions) {
      return;
    }

    const answers = quizQuestions.map(
      (
        question,
      ): {
        questionId: string;
        answer: Maybe<string>;
        answerList: Maybe<string[]>;
        source: Maybe<string>;
        answerId: Maybe<string>;
      } => {
        const determineAnswer = (question: Question): string | string[] => {
          const shortcodeAnswers: { [shortcode: string]: string } = {
            phone: '0404040404',
            sex: 'MALE',
            dob: '1991-06-26',
            weight: '100',
            waistSize: '36',
          };
          if (
            question.shortcode &&
            Object.keys(shortcodeAnswers).includes(question.shortcode)
          ) {
            return shortcodeAnswers[question.shortcode];
          }

          switch (question.type) {
            case 'TEXTFIELD':
              return 'dummy answer';
            case 'TEXTAREA':
              return 'dummy answer';
            case 'CHECKBOX':
              return question.options.slice(-1);
            case 'RADIO':
              return question.options[question.options.length - 1];
            case 'NUMBER':
              return '100';
            case 'DATE':
              return '1992-01-15T00:00:00.000Z';
            case 'UNITISED_NUMERIC':
              return '100';
            default:
              return '';
          }
        };
        const answer = determineAnswer(question);
        return {
          answer: Array.isArray(answer) ? undefined : answer,
          questionId: question.id,
          answerList: Array.isArray(answer) ? answer : undefined,
          source: '',
          answerId: undefined,
        };
      },
    );

    if (state.completeQuiz) {
      try {
        setLoading(true);
        await state.completeQuiz({ answers });
      } catch {
        /* defer to global error handler */
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Button
      level="secondary"
      palette={getSecondaryButtonPalette(config.brand)}
      isLoading={loading}
      isFullWidth
      onClick={completeQuiz}
    >
      {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
      Complete Quiz
    </Button>
  );
};
