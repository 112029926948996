import { MedicareAnswerOpted, getMedicareAnsOptedFromSS } from '.';

const incorrectDetailsBodyText =
  "We're having trouble verifying your Medicare details. Please enter your details exactly as they appear on your Medicare card.";

export const MedicareInstructions = ({
  medicareDetailsCollected,
}: {
  medicareDetailsCollected: boolean;
}): React.ReactElement => {
  if (
    !medicareDetailsCollected &&
    getMedicareAnsOptedFromSS() === MedicareAnswerOpted.SubmittedMedicare
  ) {
    return <>{incorrectDetailsBodyText}</>;
  }
  return (
    <>
      <p>
        We use this to generate eScripts so we can process your orders faster.
        Enter your details exactly as they appear on your Medicare card.
      </p>
      <br />
      <p>We can’t currently provide Medicare rebates.</p>
    </>
  );
};
