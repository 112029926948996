import { getConfig } from '@customer-frontend/config';
import { WeightTreatmentCategory } from '@customer-frontend/graphql-types';
import { Brand } from '@customer-frontend/types';
import { WISTIA_VIDEOS, WistiaVideo } from '@customer-frontend/video';
import { EmbeddedVideo, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as JuniperOzempicDosage05Plus1mg } from '../assets/juniper-ozempic-dosage-0.5mg+1.0mg.svg';
import { ReactComponent as JuniperOzempicDosage05mgMaintenance } from '../assets/juniper-ozempic-dosage-0.5mg-maintenance.svg';
import { ReactComponent as JuniperOzempicDosage1mgMaintenance } from '../assets/juniper-ozempic-dosage-1.0mg-maintenance.svg';
import { ReactComponent as JuniperOzempicDosageDefault } from '../assets/juniper-ozempic-dosage-default.svg';
import { ReactComponent as JuniperSaxendaDosageLow } from '../assets/juniper-saxenda-dosage-low.svg';
import { ReactComponent as PilotOzempicDosage05Plus1mg } from '../assets/pilot-ozempic-dosage-0.5mg+1.0mg.svg';
import { ReactComponent as PilotOzempicDosage05mgMaintenance } from '../assets/pilot-ozempic-dosage-0.5mg-maintenance.svg';
import { ReactComponent as PilotOzempicDosage1mgMaintenance } from '../assets/pilot-ozempic-dosage-1.0mg-maintenance.svg';
import { ReactComponent as PilotOzempicDosageDefault } from '../assets/pilot-ozempic-dosage-default.svg';
import { ReactComponent as PilotSaxendaDosageLow } from '../assets/pilot-saxenda-dosage-low.svg';
import { ReactComponent as PilotOzempicDosage025Plus05mg } from '../assets/pilot-ozempic-dosage-0.25mg+0.5mg.svg';
import { ReactComponent as JuniperOzempicDosage025Plus05mg } from '../assets/juniper-ozempic-dosage-0.25mg+0.5mg.svg';

const defaultOzempicSKUsJuniper = ['WGT-JUN-OZMPC-01', 'WGT-JUN-OZMPD-01'];
const defaultOzempicSKUsPilot = ['WGT-PIL-OZMPC-01'];
const defaultOzempicSKUs = [
  ...defaultOzempicSKUsJuniper,
  ...defaultOzempicSKUsPilot,
];
const pointFivePlusOneMgOzempicSKUsJuniper = [
  'WGT-JUN-OZMPS-01',
  'WGT-JUN-OZMPT-01',
];
const pointFivePlusOneMgOzempicSKUsPilot = ['WGT-PIL-OZMPS-01'];
const pointFivePlusOneMgOzempicSKUs = [
  ...pointFivePlusOneMgOzempicSKUsJuniper,
  ...pointFivePlusOneMgOzempicSKUsPilot,
];
const point5MgMaintenanceOzempicSKUsJuniper = [
  'WGT-JUN-OZMPM-05',
  'WGT-JUN-OZMCM-05',
];

const point5MgMaintenanceOzempicSKUsPilot = ['WGT-PIL-OZMPM-05'];

const point5MgMaintenanceOzempicSKUs = [
  ...point5MgMaintenanceOzempicSKUsJuniper,
  ...point5MgMaintenanceOzempicSKUsPilot,
];

const oneMgMaintenanceOzempicSKUsJuniper = [
  'WGT-JUN-OZMPM-01',
  'WGT-JUN-OZPCM-01',
];
const oneMgMaintenanceOzempicSKUsPilot = ['WGT-PIL-OZMPM-01'];

const oneMgMaintenanceOzempicSKUs = [
  ...oneMgMaintenanceOzempicSKUsJuniper,
  ...oneMgMaintenanceOzempicSKUsPilot,
];

const pointTwoFivePlusPointFiveOzempicSKUsPilot = ['WGT-PIL-OZMPN-05'];
const pointTwoFivePlusPointFiveOzempicSKUsJuniper = ['WGT-JUN-OZMPN-05'];

const pointTwoFivePlusPointFiveOzempicSKUs = [
  ...pointTwoFivePlusPointFiveOzempicSKUsJuniper,
  ...pointTwoFivePlusPointFiveOzempicSKUsPilot,
];

const imageProps = {
  height: 'unset',
  width: 'unset',
  className: 'w-full md:w-1/2 md:mx-auto',
};

const images: {
  [key in Brand]?: {
    ozempicDosage05Plus1mg: React.ReactNode;
    ozempicDosage05mgMaintenance: React.ReactNode;
    ozempicDosage1mgMaintenance: React.ReactNode;
    ozempicDosageDefault: React.ReactNode;
    saxendaDosageLow: React.ReactNode;
    ozempicDosage025mgPlus05mg: React.ReactNode;
  };
} = {
  pilot: {
    ozempicDosage05Plus1mg: <PilotOzempicDosage05Plus1mg {...imageProps} />,
    ozempicDosage05mgMaintenance: (
      <PilotOzempicDosage05mgMaintenance {...imageProps} />
    ),
    ozempicDosage1mgMaintenance: (
      <PilotOzempicDosage1mgMaintenance {...imageProps} />
    ),
    ozempicDosageDefault: <PilotOzempicDosageDefault {...imageProps} />,
    saxendaDosageLow: <PilotSaxendaDosageLow {...imageProps} />,
    ozempicDosage025mgPlus05mg: (
      <PilotOzempicDosage025Plus05mg {...imageProps} />
    ),
  },
  juniper: {
    ozempicDosage05Plus1mg: <JuniperOzempicDosage05Plus1mg {...imageProps} />,
    ozempicDosage05mgMaintenance: (
      <JuniperOzempicDosage05mgMaintenance {...imageProps} />
    ),
    ozempicDosage1mgMaintenance: (
      <JuniperOzempicDosage1mgMaintenance {...imageProps} />
    ),
    ozempicDosageDefault: <JuniperOzempicDosageDefault {...imageProps} />,
    saxendaDosageLow: <JuniperSaxendaDosageLow {...imageProps} />,
    ozempicDosage025mgPlus05mg: (
      <JuniperOzempicDosage025Plus05mg {...imageProps} />
    ),
  },
};
const getOzempicDosageSection = (
  sku: string,
  brand: Brand,
): React.ReactNode => {
  const ozempicDosageDefault = images[brand]?.ozempicDosageDefault;
  const ozempicDosage05Plus1mg = images[brand]?.ozempicDosage05Plus1mg;
  const ozempicDosage1mgMaintenance =
    images[brand]?.ozempicDosage1mgMaintenance;
  const ozempicDosage05mgMaintenance =
    images[brand]?.ozempicDosage05mgMaintenance;
  const ozempicDosage025mgPlus05mg = images[brand]?.ozempicDosage025mgPlus05mg;

  if (defaultOzempicSKUs.includes(sku)) {
    return (
      <>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="We will start with a low dosage of 0.25mg and increase to 1.0mg over 2 months." />
        </Typography>
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage defaultMessage="Ozempic is administered once a week via a titrating dosing schedule." />
        </Typography>
        {ozempicDosageDefault}
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="These dosage increases are not compulsory - only increase the dosage if tolerated. Please speak to our medication support team if you wish to remain on a lower dose of Ozempic." />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Do not fast track the schedule or exceed your weekly dose. This will not increase your weight loss, but will put you at higher risk of side effects." />
        </Typography>
      </>
    );
  } else if (pointFivePlusOneMgOzempicSKUs.includes(sku)) {
    return (
      <>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="We will start with a low dosage of 0.5mg per week and increase to 1.0mg per week after 1 month." />
        </Typography>
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage defaultMessage="Ozempic is administered once a week via a titrating dosing schedule." />
        </Typography>
        {ozempicDosage05Plus1mg}
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="These dosage increases are not compulsory - only increase the dosage if tolerated. Please speak to our medication support team if you wish to remain on a lower dose of Ozempic." />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Do not fast track the schedule or exceed your weekly dose. This will not increase your weight loss, but will put you at higher risk of side effects." />
        </Typography>
      </>
    );
  } else if (point5MgMaintenanceOzempicSKUs.includes(sku)) {
    return (
      <>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Each week, we will continue using the same dosage setting on the pen of 0.5mg." />
        </Typography>
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage defaultMessage="Ozempic is administered once a week via a titrating dosing schedule." />
        </Typography>
        {ozempicDosage05mgMaintenance}
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Do not fast track the schedule or exceed your weekly dose. This will not increase your weight loss, but will put you at higher risk of side effects." />
        </Typography>
      </>
    );
  } else if (oneMgMaintenanceOzempicSKUs.includes(sku)) {
    return (
      <>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="You are now in the maintenance phase where your dose will remain 1.0 mg per week." />
        </Typography>
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage defaultMessage="Ozempic is administered once a week via a titrating dosing schedule." />
        </Typography>
        {ozempicDosage1mgMaintenance}
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Do not fast track the schedule or exceed your weekly dose. This will not increase your weight loss, but will put you at higher risk of side effects." />
        </Typography>
      </>
    );
  } else if (pointTwoFivePlusPointFiveOzempicSKUs.includes(sku)) {
    return (
      <>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="We will start with a low dosage of 0.25mg and increase to 0.5mg after 4 weeks." />
        </Typography>
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage defaultMessage="Ozempic is administered once a week via a titrating dosing schedule." />
        </Typography>
        {ozempicDosage025mgPlus05mg}
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="These dosage increases are not compulsory - only increase the dosage if tolerated. Please speak to our medication support team if you wish to remain on a lower dose of Ozempic." />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Do not fast track the schedule or exceed your weekly dose. This will not increase your weight loss, but will put you at higher risk of side effects." />
        </Typography>
      </>
    );
  }
  return null;
};

const SaxendaDosageAndAdministration: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
}> = ({ handleVideoPlayed }) => {
  const config = getConfig();

  const saxendaDosageLow = images[config.brand]?.saxendaDosageLow;

  return (
    <div className="space-y-6">
      {config.brand === 'juniper' && (
        <WistiaVideo
          videoId={WISTIA_VIDEOS.HOW_TO_ADMINISTER_SAXENDA}
          videoClassName="rounded-b-none"
          onPlay={handleVideoPlayed}
        >
          <div className="rounded rounded-t-none bg-primary-500 p-4">
            <Typography size={'paragraph'} isBold color="white">
              <FormattedMessage defaultMessage="How to administer Saxenda" />
            </Typography>
            <Typography size="paragraph" color="white">
              <FormattedMessage defaultMessage="With Dr Atkinson, Juniper GP" />
            </Typography>
          </div>
        </WistiaVideo>
      )}
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="We will start with a low dosage of 0.6mg and increase to 1.2mg from the second week and onwards." />
      </Typography>
      {saxendaDosageLow}
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="The dosing schedule is designed to help get the most from your medication and minimise side effects by slowly increasing your dose. If you are experiencing side effects, there is no clinical need to increase your dose until your side effects become more manageable." />
      </Typography>
      <div className="space-y-2">
        <Typography size="xs" isBold>
          <FormattedMessage defaultMessage="How to administer Saxenda" />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Saxenda is administered daily using a painless injection pen with a very small needle. It is best taken at the same time each day, with or without food." />
        </Typography>
      </div>
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="You’ll receive step-by-step administration instructions with your medication delivery. Until then, you can familiarise yourself with the pen by watching this tutorial video:" />
      </Typography>
      <EmbeddedVideo youtubeId="6lxs1-DQZ9E" />
    </div>
  );
};

const OzempicDosageAndAdministration: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
  productSku: string;
}> = ({ handleVideoPlayed, productSku }) => {
  const config = getConfig();
  return (
    <div className="space-y-6">
      {getOzempicDosageSection(productSku, config.brand)}
      <div className="space-y-2">
        <Typography size="xs" isBold>
          <FormattedMessage defaultMessage="How to administer Ozempic" />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="The medication comes in a pen with a very tiny needle. You twist the end to select the dosage, then inject yourself in your upper thigh, upper arm or abdomen (not in a vein or muscle). Most of my patients are pretty comfortable with this after the first use as the pen makes choosing the dosage easy." />
        </Typography>
      </div>
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="You’ll receive step-by-step administration instructions with your medication delivery. Until then, you can familiarise yourself with the pen by watching this tutorial video:" />
      </Typography>
      {config.brand === 'juniper' && (
        <WistiaVideo
          videoId={WISTIA_VIDEOS.HOW_TO_ADMINISTER_OZEMPIC}
          videoClassName="rounded-b-none"
          onPlay={handleVideoPlayed}
        >
          <div className="rounded rounded-t-none bg-primary-500 p-4">
            <Typography size={'paragraph'} isBold color="white">
              <FormattedMessage defaultMessage="How to administer Ozempic" />
            </Typography>
            <Typography size="paragraph" color="white">
              <FormattedMessage defaultMessage="With Lydia, Registered Pharmacist" />
            </Typography>
          </div>
        </WistiaVideo>
      )}
    </div>
  );
};

export const DosageContent: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
  weightTreatmentCategory: WeightTreatmentCategory;
  productSku: string;
}> = ({ handleVideoPlayed, weightTreatmentCategory, productSku }) => {
  switch (weightTreatmentCategory) {
    case 'saxenda':
      return (
        <SaxendaDosageAndAdministration handleVideoPlayed={handleVideoPlayed} />
      );
    case 'ozempic':
      return (
        <OzempicDosageAndAdministration
          handleVideoPlayed={handleVideoPlayed}
          productSku={productSku}
        />
      );
    default:
      return <></>;
  }
};
