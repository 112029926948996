import { GuaranteeListItem } from './types';

export const WEIGHT_SHAKE_GUARANTEES: GuaranteeListItem[] = [
  {
    headline: 'Replace meals, not nutrients',
    byline: '29g of protein, just 205 calories per serve',
  },
];

export const T_SUPPORT_GUARANTEES: GuaranteeListItem[] = [
  {
    headline: 'A healthy balance of vitamins and minerals',
    byline: 'Essential ingredients for growth and performance',
  },
];

export const COMMON_GUARANTEES: GuaranteeListItem[] = [
  {
    headline: 'Free shipping, always',
    byline: 'Never run out with automatic refills',
  },
  {
    headline: 'No lock-in contracts',
    byline: 'Pause or cancel at any time - no questions asked',
  },
];
