import {
  toUiStorageType,
  LongLivedUiStorageType,
  uiStorages,
  UiStorageType,
} from '@customer-frontend/ui-storage';

const AUTH_STORAGE_TYPE_KEY = 'auth_storage_type_key';

export class AuthStorageTypeService {
  private readonly uiStorage = uiStorages.local;
  constructor(private readonly defaultStorageType: LongLivedUiStorageType) {}

  isUsingDefault(): boolean {
    return this.getType() === this.getDefaultType();
  }

  getDefaultType(): UiStorageType {
    return this.defaultStorageType;
  }

  getType(): UiStorageType {
    return (
      toUiStorageType(this.uiStorage.getValue(AUTH_STORAGE_TYPE_KEY)) ??
      this.defaultStorageType
    );
  }

  setType(type: UiStorageType): void {
    this.uiStorage.setValue(AUTH_STORAGE_TYPE_KEY, type);
  }

  resetToDefault(): void {
    this.uiStorage.clearValue(AUTH_STORAGE_TYPE_KEY);
  }
}
