import { Card, Divider } from '@eucalyptusvc/design-system';
import { FC } from 'react';

type NextReadingCardTemplateProps = {
  header: JSX.Element;
  showDivider?: boolean;
  children: React.ReactNode;
};

export const NextReadingCardTemplate: FC<NextReadingCardTemplateProps> = ({
  children,
  header,
  showDivider,
}) => (
  <div className="col-span-2 md:col-span-1 w-full">
    <Card>
      <div className="flex flex-col space-y-3 md:space-y-4">
        <div className="flex flex-row items-center space-x-2">{header}</div>
        {showDivider && <Divider />}
        <div className="flex items-center space-x-3">{children}</div>
      </div>
    </Card>
  </div>
);
