import { useApolloClient } from '@apollo/client';
import { InSegmentQuery, Segment } from '@customer-frontend/graphql-types';
import { useEffectOnce } from 'react-use';
import {
  inSegmentDocument,
  updateSegmentLocalCache,
  useAddUserToSegment,
} from './useAddUserToSegment';

export const useAddUserToSegmentIfNotAlreadyIn = (
  segmentName: Segment,
): void => {
  const client = useApolloClient();
  const [addUserToSegment] = useAddUserToSegment({
    variables: {
      segmentName,
    },
  });

  useEffectOnce(() => {
    const asyncCheckAndAddToSegment = async (): Promise<void> => {
      const inSegment = await client.query<InSegmentQuery>({
        query: inSegmentDocument,
        variables: { segmentName },
      });
      if (!inSegment.data?.inSegment) {
        await addUserToSegment();
        updateSegmentLocalCache(client, segmentName);
      }
    };
    asyncCheckAndAddToSegment();
  });
};
