import { getConfig } from '@customer-frontend/config';
import { Maybe } from '@customer-frontend/graphql-types';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { Markdown, Typography } from '@eucalyptusvc/design-system';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type OrderDetailsProps = {
  treatmentName?: string;
  treatmentDescription?: string | null;
  nextPayment?: Maybe<string>;
  refillsLeft?: Maybe<number>;
  status: string;
  orderItems: { name: string }[];
};

export const OrderDetails = ({
  treatmentName,
  treatmentDescription,
  status,
  nextPayment,
  refillsLeft,
  orderItems,
}: OrderDetailsProps): ReactElement => {
  const config = getConfig();

  return (
    <div>
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="Status: <strong>{status}</strong>"
          description="Label for treatment status field"
          values={{
            status,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Typography>

      {refillsLeft !== undefined && (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Refills left: <strong>{refillsLeft}</strong>"
            description="Label for how many refills the user has on their treatment"
            values={{
              refillsLeft,
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
      )}

      {treatmentName && (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Your treatment: <strong>{treatmentName}</strong>"
            description="Label for the users treatment name"
            values={{
              treatmentName,
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
      )}

      {nextPayment && (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Next order date: <strong>{nextPayment}</strong>"
            description="Label for the ship date of the users next order"
            values={{
              nextPayment: formatDate(
                mapBrandToAdaptersBrand(config.brand),
                nextPayment,
                {
                  dateStyle: 'short',
                },
              ),
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </Typography>
      )}

      {treatmentDescription && (
        <div className="mt-4">
          <Markdown src={treatmentDescription} />
        </div>
      )}

      {orderItems.length > 0 && (
        <div className="mt-4">
          <Typography isBold size="medium-paragraph">
            <FormattedMessage defaultMessage="Order contains:" />
          </Typography>
          <ul>
            {orderItems.map((product) => (
              <li key={product.name}>
                <Typography size="medium-paragraph">{product.name}</Typography>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
