import {
  useQuery,
  gql,
  QueryResult,
  DocumentNode,
  FetchPolicy,
} from '@apollo/client';
import {
  Consultation,
  QueryConsultationArgs,
} from '@customer-frontend/graphql-types';
import { consultationFragment } from '../graphql/fragments';

export function useConsultation(
  id: string,
  skip?: boolean,
  fragment: DocumentNode = consultationFragment,
  fetchPolicy?: FetchPolicy,
): QueryResult<{ consultation: Consultation }, QueryConsultationArgs> {
  return useQuery<{ consultation: Consultation }, QueryConsultationArgs>(
    gql`
      query UseConsultation($id: String!) {
        consultation(id: $id) {
          ...ConsultationInfo
        }
      }
      ${fragment}
    `,
    {
      variables: {
        id,
      },
      skip: !id || skip,
      fetchPolicy,
    },
  );
}
