import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import {
  MutationCancelSubscriptionPlanArgs,
  SubscriptionPlan,
} from '@customer-frontend/graphql-types';
import { subscriptionPlanFragment } from './subscription-plan-fragment';

interface Response {
  cancelSubscriptionPlan: SubscriptionPlan;
}

const cancelSubscriptionMutation = gql`
  mutation CancelSubscriptionPlan($subscriptionId: String!) {
    cancelSubscriptionPlan(subscriptionId: $subscriptionId) {
      ...OtcSubscriptionPlanInfo
    }
  }
  ${subscriptionPlanFragment}
`;

export function useCancelSubscriptionPlan(
  options: MutationHookOptions<Response, MutationCancelSubscriptionPlanArgs>,
): MutationTuple<Response, MutationCancelSubscriptionPlanArgs> {
  return useMutation<Response, MutationCancelSubscriptionPlanArgs>(
    cancelSubscriptionMutation,
    options,
  );
}
