import { gql, useMutation } from '@apollo/client';
import {
  IConsultationPaidEvent,
  useEventService,
} from '@customer-frontend/events';
import {
  useCpPaidEventFieldsQuery,
  MutationPayConsultationArgs,
} from '@customer-frontend/graphql-types';
import { convertCurrencyBaseUnit } from '@customer-frontend/order';
import { useConsultation } from '@customer-frontend/services';
import { getConfig } from '@customer-frontend/config';
import { useIntl } from 'react-intl';

export const useSendCPEvent = (
  consultationId: string,
): {
  sendCPEvent: () => void;
} => {
  const event = useEventService();
  const { currency, country, consultationPriceCents } = getConfig();

  const { data: consultationData } = useConsultation(consultationId);

  const { data: profileFields } = useCpPaidEventFieldsQuery();

  const { firstName, lastName, phone, id } = profileFields?.profile ?? {};

  const consultationPaidEvent: IConsultationPaidEvent = {
    userId: id,
    consultationId,
    firstName,
    lastName,
    phoneNumber: phone,
    currency,
    country,
    problemType: consultationData?.consultation?.type ?? '',
    value: consultationPriceCents
      ? convertCurrencyBaseUnit(consultationPriceCents)
      : 0,
  };

  const sendCPEvent = (): void => {
    event.consultation.paid(consultationPaidEvent);
  };
  return {
    sendCPEvent,
  };
};

export const GET_CP_PAID_EVENT_FIELDS = gql`
  query CpPaidEventFields {
    profile {
      id
      firstName
      lastName
      isPasswordSet
      email
      phone
    }
  }
`;

export const GET_EMAIL_AND_PHONE_AND_PASSWORD_STATUS = gql`
  query EmailPhonePasswordStatus {
    validPhoneRegions {
      id
      countryCode
    }
    profile {
      id
      email
      phone
      isPasswordSet
    }
  }
`;

export const CREATE_ACCOUNT_CP = gql`
  mutation CreateAccountCpJuniper($phone: String, $password: String) {
    updateProfile(phone: $phone, password: $password) {
      id
      phone
      isPasswordSet
    }
  }
`;

export const useConfirmFreeWeightConsultation = ({
  consultationId,
}: {
  consultationId: string;
}): {
  completeConsultationPay: () => Promise<void>;
  loading: boolean;
} => {
  const [payConsultation, { loading }] =
    useMutation<MutationPayConsultationArgs>(
      gql`
        mutation ConfirmConsultationJuniper($id: String!, $couponCode: String) {
          payConsultation(id: $id, couponCode: $couponCode) {
            id
          }
        }
      `,
    );

  const completeConsultationPay = async (): Promise<void> => {
    await payConsultation({
      variables: {
        id: consultationId,
        couponCode: 'FREEWEIGHT',
      },
    });
  };

  return {
    completeConsultationPay,
    loading,
  };
};

export const useGetDefaultClinicianNounByCountryCode = (
  countryCode: string,
): string => {
  const { formatMessage } = useIntl();

  if (countryCode === 'AU') {
    return formatMessage({
      defaultMessage: 'practitioner',
    });
  }

  return formatMessage({
    defaultMessage: 'prescriber',
  });
};
