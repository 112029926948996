import { getConfig } from '@customer-frontend/config';
import { Brand } from '@customer-frontend/types';
import { FormattedMessage } from 'react-intl';

type Step = {
  timeLabel: string;
  percentLostLabel: string;
  weightLostLabel: string;
};

const colorsMap: {
  [key in Brand]?: {
    lineFill: string;
    circleFill: string;
    rectFill: string;
  };
} = {
  pilot: {
    lineFill: '#1974CD',
    circleFill: '#1974CD',
    rectFill: '#D7EA92',
  },
  juniper: {
    lineFill: '#133F26',
    circleFill: '#D5BBE9',
    rectFill: '#D5BBE9',
  },
};

export const WeightGraph: React.FC<{
  initialWeight: number;
  unit: 'kg' | 'lbs';
  stepOne: Step;
  stepTwo: Step;
}> = ({ initialWeight, unit, stepOne, stepTwo }) => {
  const config = getConfig();

  const { lineFill, circleFill, rectFill } = colorsMap[config.brand] || {};

  if (!lineFill || !circleFill || !rectFill) {
    return <></>;
  }
  return (
    <svg
      className="w-full"
      viewBox="0 0 500 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path opacity={0.3} strokeWidth={1.408} d="M9.445 6.335h495.194" />
        <path
          opacity={0.3}
          stroke="#B5C1B2"
          d="M6.093 175.072v-175m83 175v-175m81 174.928V0m75 175V0m77 175V0m83 175V0m81 175V0M5.5 174.5h491"
        />
        <circle
          opacity={0.3}
          cx={486.137}
          cy={139.137}
          r={7.637}
          stroke={circleFill}
          strokeWidth={3}
        />
        <circle
          opacity={0.2}
          cx={486.137}
          cy={139.137}
          r={8.637}
          stroke="#BA82E6"
          strokeWidth={5}
        />
        <path
          d="M6 29s83.912 69.213 253.235 79.101C428.558 117.989 484 139 484 139"
          stroke={lineFill}
        />
        <circle
          cx={486.137}
          cy={139.137}
          r={6.637}
          fill={circleFill}
          stroke="#133F26"
        />
        <circle cx={5.5} cy={28.5} r={4} fill={circleFill} stroke="#133F26" />
        <circle cx={245} cy={108} r={4.5} fill={circleFill} stroke="#133F26" />
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={16}
          letterSpacing="0em"
          fontWeight={600}
        >
          <tspan x={0} y={15.992}>
            {initialWeight}
            {unit}
          </tspan>
        </text>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={16}
          letterSpacing="0em"
          fontWeight={600}
        >
          <tspan x={221} y={93.992}>
            {stepOne.weightLostLabel}
          </tspan>
        </text>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={16}
          letterSpacing="0em"
          fontWeight={600}
        >
          <tspan x={434} y={116.992}>
            {stepTwo.weightLostLabel}
          </tspan>
        </text>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={16}
          letterSpacing="0em"
        >
          <tspan x={2.758} y={198.47}>
            <FormattedMessage defaultMessage="Now" />
          </tspan>
        </text>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={16}
          letterSpacing="0em"
        >
          <tspan x={458.383} y={198.992}>
            {stepTwo.timeLabel}
          </tspan>
        </text>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={16}
          letterSpacing="0em"
        >
          <tspan x={228.031} y={198.992}>
            {stepOne.timeLabel}
          </tspan>
        </text>
        <rect
          x={221}
          y={55}
          width={50.273}
          height={19.636}
          rx={2.405}
          fill={rectFill}
        />
        <g clipPath="url(#b)">
          <path
            d="m232.013 64.835.397.39a.417.417 0 0 1 0 .597l-3.47 3.417a.431.431 0 0 1-.605 0l-3.472-3.418a.415.415 0 0 1 0-.595l.397-.39a.433.433 0 0 1 .612.006l2.05 2.118v-5.05c0-.234.191-.422.429-.422h.571c.238 0 .429.188.429.422v5.051l2.05-2.118a.43.43 0 0 1 .612-.007Z"
            fill="#133F26"
          />
        </g>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={12}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={236.636} y={70.168}>
            {stepOne.percentLostLabel}
          </tspan>
        </text>
        <rect
          x={434}
          y={79}
          width={61.273}
          height={19.636}
          rx={2.405}
          fill={rectFill}
        />
        <g clipPath="url(#c)">
          <path
            d="m445.013 88.835.397.39a.417.417 0 0 1 0 .597l-3.47 3.417a.431.431 0 0 1-.605 0l-3.472-3.418a.415.415 0 0 1 0-.595l.397-.39a.433.433 0 0 1 .612.006l2.05 2.118v-5.05c0-.234.191-.422.429-.422h.571c.238 0 .429.188.429.422v5.051l2.05-2.118a.43.43 0 0 1 .612-.007Z"
            fill={lineFill}
          />
        </g>
        <text
          fill="#133F26"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Atlas Grotesk"
          fontSize={12}
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x={449.636} y={94.168}>
            {stepTwo.percentLostLabel}
          </tspan>
        </text>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h500v205H0z" />
        </clipPath>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="translate(224.636 60.924)"
            d="M0 0h8v9H0z"
          />
        </clipPath>
        <clipPath id="c">
          <path
            fill="#fff"
            transform="translate(437.636 84.924)"
            d="M0 0h8v9H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
