import { MutationSubmitPatientPharmacistQuestionArgs } from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

interface Response {
  submitPatientPharmacistQuestion: boolean;
}

const submitPatientPharmacistQuestionMutation = gql`
  mutation SubmitPatientPharmacistQuestion(
    $treatmentId: String!
    $messageText: String!
    $contactMethod: ContactMethod
  ) {
    submitPatientPharmacistQuestion(
      treatmentId: $treatmentId
      messageText: $messageText
      contactMethod: $contactMethod
    )
  }
`;

export function useSubmitPatientPharmacistQuestion(
  options: MutationHookOptions<
    Response,
    MutationSubmitPatientPharmacistQuestionArgs
  >,
): MutationTuple<Response, MutationSubmitPatientPharmacistQuestionArgs> {
  return useMutation<Response, MutationSubmitPatientPharmacistQuestionArgs>(
    submitPatientPharmacistQuestionMutation,
    options,
  );
}
