import { useAuth } from '@customer-frontend/auth';
import React from 'react';
import { LoginAvatar } from './login-avatar';
import { UserSidebarAvatar } from './user-sidebar-avatar';

export const NavigationAvatar = (): React.ReactElement => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <UserSidebarAvatar /> : <LoginAvatar />;
};
