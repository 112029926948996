import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput, TextInputProps } from '@eucalyptusvc/design-system';
import { QuestionProps } from './types';
import { useRequiredValidation } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import { useIntl } from 'react-intl';

interface Props extends QuestionProps {
  type: 'number' | 'text' | 'masked-date';
  inputProps?: TextInputProps;
}

export function BaseInputQuestionInput({
  type,
  question,
  inputProps,
}: Props): React.ReactElement {
  const { formatMessage } = useIntl();
  const config = getConfig();
  const { register, errors } = useFormContext();
  const { required } = question;

  const answerValidation = useRequiredValidation(
    formatMessage({ defaultMessage: 'Answer' }),
  );

  return (
    <TextInput
      autoFocus
      name={question.id}
      ref={register({
        ...(required ? answerValidation : {}),
      })}
      type={type}
      palette={config.brand === 'pilot' ? 'alternate' : 'default'}
      required={required ?? false}
      errorMessage={errors[question.id]?.message}
      {...inputProps}
    />
  );
}
