import {
  PartsStyleObject,
  SystemStyleObject,
  PartsStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools';
import { getBreakpointValue } from '../../../utils/getBreakpointValue';

type ImageUploadStylesProps = {
  props: StyleFunctionProps;
  styleOverrides: {
    container: SystemStyleObject;
    placeholder: SystemStyleObject;
    sectionContainer?: SystemStyleObject;
  };
};

export const imageUploadParts = [
  'image',
  'title',
  'webcam',
  'actions',
  'container',
  'description',
  'placeholder',
  'instructions',
  'sectionContainer',
];

const getContainerStyles = (
  overrides: SystemStyleObject,
): SystemStyleObject => {
  return {
    p: '1rem',
    ...overrides,
  };
};

const getPlaceholderStyles = (
  overrides: SystemStyleObject,
): SystemStyleObject => {
  return {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: 'base',
    cursor: 'pointer',
    ...overrides,
  };
};

const getSectionContainerStyles = (
  overrides: SystemStyleObject = {},
): SystemStyleObject => ({
  display: 'flex',
  flexDirection: 'column',
  ...overrides,
});

const getBaseStyles: PartsStyleFunction = ({ orientation, breakpoint: bp }) => {
  const isHorizontal = orientation === 'horizontal';

  return {
    title: {
      mb: '1rem',
      textAlign: 'center',
    },
    description: {
      mb: '2rem',
      textAlign: 'center',
    },
    imageActionContainer: {
      display: 'flex',
      gap: '1rem',
      mt: '1rem',
      flexDirection: isHorizontal
        ? getBreakpointValue(['column', 'row'], bp)
        : 'column',
    },
    actions: {
      flex: 'auto',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      display: isHorizontal
        ? getBreakpointValue(['block', 'flex'], bp)
        : 'block',
      '& :not(:first-of-type):last-of-type': {
        mt: '0.5rem',
      },
    },
    image: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      borderRadius: 'base',
    },
    instructions: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      mb: '1rem',
    },
    webcam: { mt: '2rem' },
    placeholderImage: {
      padding: isHorizontal ? '2.5rem' : '5rem',
    },
  };
};

export const getImageUploadStyles = ({
  props,
  styleOverrides,
}: ImageUploadStylesProps): PartsStyleObject => ({
  ...getBaseStyles(props),
  container: getContainerStyles(styleOverrides.container),
  placeholder: getPlaceholderStyles(styleOverrides.placeholder),
  sectionContainer: getSectionContainerStyles(styleOverrides.sectionContainer),
});
