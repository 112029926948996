import { SystemStyleObject } from '@chakra-ui/react';
import { sharedColors } from '../../shared/palette';

interface ToastThemeProps {
  borderRadius: string;
}

export const getToastComponent = (
  props: ToastThemeProps,
): SystemStyleObject => ({
  Toast: {
    baseStyle: {
      borderRadius: props.borderRadius,
      px: '1rem',
      py: '0.5rem',
      display: 'inline-flex',
      gap: '0.5rem',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0px 8px 8px rgba(0,0,0,0.1)',
    },
    variants: {
      success: {
        color: sharedColors.status.success[100],
        bg: sharedColors.status.success[500],
      },
      error: {
        color: sharedColors.status.error[100],
        bg: sharedColors.status.error[500],
      },
      warning: {
        color: '#000000',
        bg: sharedColors.status.warning[500],
      },
      info: {
        color: sharedColors.status.info[100],
        bg: sharedColors.status.info[500],
      },
      'success-alternate': {
        color: sharedColors.status.success[500],
        bg: sharedColors.status.success[100],
      },
      'error-alternate': {
        color: sharedColors.status.error[500],
        bg: sharedColors.status.error[100],
      },
      'warning-alternate': {
        color: '#000000',
        bg: sharedColors.status.warning[100],
      },
      'info-alternate': {
        color: sharedColors.status.info[500],
        bg: sharedColors.status.info[100],
      },
    },
  },
});
