import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  ApolloProvider,
} from '@apollo/client';
import { notificationService } from '@customer-frontend/notifications';
import { Logger } from '@customer-frontend/logger';
import { AppEnv } from '@customer-frontend/types';
import { utmService } from '@customer-frontend/utm';
import { isSyntheticTest } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import possibleTypesResult from '@customer-frontend/graphql-types/src/possible-types.generated';
import { useFormatErrorMessageFromCode } from './useFormattedErrorMessage';
import { useEffect, useState } from 'react';

type ClientProviderProps = {
  apiUrl: string;
  appEnv: AppEnv;
  getToken?: () => string | undefined;
  logger: Logger;
  children: React.ReactNode;
  onUnauthenticated?: () => void;
  onSuccessResponse?: () => void;
};

const requestLoggerLink = new ApolloLink((operation, forward) => {
  // eslint-disable-next-line no-console
  console.log(operation.operationName, operation.variables);
  return forward(operation);
});

const getApolloLink = ({
  formatErrorMessageFromCode,
  apiUrl,
  getToken,
  onSuccessResponse,
  onUnauthenticated,
  logger,
  appEnv,
}: {
  apiUrl: string;
  appEnv: AppEnv;
  getToken?: () => string | undefined;
  logger: Logger;
  onUnauthenticated?: () => void;
  onSuccessResponse?: () => void;
  formatErrorMessageFromCode: (code: string) => string;
}): ApolloLink => {
  const { brand } = getConfig();

  const httpLink = new HttpLink({
    uri: `${apiUrl}/graphql`,
    headers: {
      brand,
      accept: 'multipart/mixed; deferSpec=20220824, application/json',
    },
  });

  const withToken = setContext((_, { headers }) => {
    const token = getToken ? getToken() : undefined;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'page-url': window.location.href,
        ...utmService.getUtmsHeaders(),
        _ga: utmService.getGaClientId(),
        ...(isSyntheticTest()
          ? {
              'x-synthetic': 'customer-frontend',
            }
          : {}),
      },
    };
  });

  const errorHandler = onError(({ networkError, graphQLErrors, operation }) => {
    if (graphQLErrors) {
      if (
        graphQLErrors.some((it) => it.extensions?.code === 'UNAUTHENTICATED')
      ) {
        if (onUnauthenticated) {
          onUnauthenticated();
        }
      }
      graphQLErrors.forEach(({ extensions, message }) => {
        if (!operation.getContext()?.skipErrorNotification) {
          notificationService.show({
            type: 'error',
            message: formatErrorMessageFromCode(extensions?.code),
          });
        }
        logger.warn(message, {
          operation: operation.operationName,
          from: 'apollo',
        });
      });
    }

    if (networkError) {
      logger.error(networkError.message, {
        operation: operation.operationName,
        from: 'apollo',
      });
    }
  });

  let link = withToken;

  if (appEnv !== 'production') {
    link = link.concat(requestLoggerLink);
  }

  const postResponseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const { response: res } = context;
      if (res?.status === 200 && onSuccessResponse) {
        onSuccessResponse();
      }
      return response;
    });
  });

  link = link.concat(errorHandler).concat(postResponseLink).concat(httpLink);

  return link;
};

export const ClientProvider: React.FC<ClientProviderProps> = ({
  logger,
  apiUrl,
  getToken,
  onUnauthenticated,
  onSuccessResponse,
  appEnv,
  children,
}) => {
  const formatErrorMessageFromCode = useFormatErrorMessageFromCode();
  const [apolloClient] = useState<ApolloClient<NormalizedCacheObject>>(() => {
    const link = getApolloLink({
      logger,
      apiUrl,
      getToken,
      onSuccessResponse,
      onUnauthenticated,
      appEnv,
      formatErrorMessageFromCode,
    });

    return new ApolloClient({
      cache: new InMemoryCache({
        possibleTypes: possibleTypesResult.possibleTypes,
      }),
      connectToDevTools: appEnv !== 'production',
      link,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    });
  });

  useEffect(() => {
    const link = getApolloLink({
      logger,
      apiUrl,
      getToken,
      onSuccessResponse,
      onUnauthenticated,
      appEnv,
      formatErrorMessageFromCode,
    });

    apolloClient.setLink(link);
  }, [
    formatErrorMessageFromCode,
    logger,
    apiUrl,
    getToken,
    onSuccessResponse,
    onUnauthenticated,
    appEnv,
    apolloClient,
  ]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
