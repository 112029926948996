import { WeightInAppOnboardingChecklistQuery } from '@customer-frontend/graphql-types';
import {
  WeightInAppOnboardingTaskStatus as TaskStatus,
  WeightInAppOnboardingTaskVisibility,
} from './types';

export const useWeightInAppOnboarding = (
  checklist: WeightInAppOnboardingChecklistQuery | undefined,
): WeightInAppOnboardingTaskVisibility | null => {
  if (!checklist) {
    return null;
  }

  const hasJoinedFacebookCommunity = checklist.weightFacebookCommunityJoined;
  const facebookCommunityJoinedStatus = hasJoinedFacebookCommunity
    ? TaskStatus.FINISHED
    : TaskStatus.PENDING;

  return {
    showChecklist: areAnyTasksPending(facebookCommunityJoinedStatus),
    facebookCommunityStatus: facebookCommunityJoinedStatus,
  };
};

const areAnyTasksPending = (...tasks: TaskStatus[]): boolean => {
  return tasks.some((task) => task === TaskStatus.PENDING);
};
