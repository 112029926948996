import React from 'react';
import { Button, Card, Typography } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { hasCheckedInToday } from '../utils';
import { FormattedMessage } from 'react-intl';

interface CheckInCardProps {
  lastCheckIn: string | null;
  loading?: boolean;
  icon: React.ReactElement;
  startTrackerQuiz: () => void;
}

const CheckInReady = ({
  startTrackerQuiz,
}: CheckInCardProps): React.ReactElement => {
  const config = getConfig();

  return (
    <>
      <Typography size="md" isBold>
        {/* No Emoji in Juniper */}
        {config.brand !== 'juniper' && <span className="mr-2">👋</span>}
        <FormattedMessage defaultMessage="Welcome back!" />
      </Typography>
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="It’s time for your check-in to see how you’re progressing." />
      </Typography>
      <Button
        level="primary"
        palette={'alternate'}
        isFullWidth
        onClick={startTrackerQuiz}
      >
        <FormattedMessage defaultMessage="Check-in" />
      </Button>
    </>
  );
};

const RecentlyCheckedIn = ({ icon }: CheckInCardProps): React.ReactElement => {
  return (
    <div className="flex flex-row space-x-3 pr-4">
      <div className="mt-1">{icon}</div>
      <Typography size="md" isBold>
        <FormattedMessage defaultMessage="You’ve done your check-in for today." />
      </Typography>
    </div>
  );
};

const LoadingState = (): React.ReactElement => {
  return (
    <div className="animate-pulse flex flex-col space-y-3">
      <div className="w-32 bg-gray-200 h-6 rounded-md" />
      <div className="w-full bg-gray-200 h-20 rounded-md" />
    </div>
  );
};

export const CheckInCard = (props: CheckInCardProps): React.ReactElement => {
  const { lastCheckIn, loading = false } = props;
  const checkedInToday = hasCheckedInToday(lastCheckIn);

  return (
    <Card>
      <div className="space-y-3 md:space-y-4">
        {loading ? (
          <LoadingState />
        ) : checkedInToday ? (
          <RecentlyCheckedIn {...props} />
        ) : (
          <CheckInReady {...props} />
        )}
      </div>
    </Card>
  );
};
