import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import {
  MutationUpdateResidentialAddressArgs,
  ResidentialAddress,
} from '@customer-frontend/graphql-types';

interface Response {
  useUpdateResidentialAddress: ResidentialAddress;
}

const updateResidentialAddressDocument = gql`
  mutation UpdateResidentialAddress(
    $residentialAddress: ResidentialAddressCreateInput!
  ) {
    updateResidentialAddress(residentialAddress: $residentialAddress) {
      id
    }
  }
`;

export function useUpdateResidentialAddressMutation(
  options?: MutationHookOptions<Response, MutationUpdateResidentialAddressArgs>,
): MutationTuple<Response, MutationUpdateResidentialAddressArgs> {
  return useMutation<Response, MutationUpdateResidentialAddressArgs>(
    updateResidentialAddressDocument,
    options,
  );
}
