import { ReactComponent as ImagePictureUploadBold } from '../../assets/ImagePictureUpload/image-picture-upload-bold.svg';
import { ReactComponent as ImagePictureUploadThin } from '../../assets/ImagePictureUpload/image-picture-upload-thin.svg';

export const ImagePlaceholder = ({
  fill,
  stroke,
  variant = 'bold',
}: {
  fill: string;
  stroke: string;
  variant?: 'bold' | 'thin';
}): JSX.Element => {
  return variant === 'bold' ? (
    <ImagePictureUploadBold fill={fill} />
  ) : (
    <ImagePictureUploadThin stroke={stroke} />
  );
};
