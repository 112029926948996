import { getMedicareAnsOptedFromSS } from '../components/utils';
import { MedicareAnswerOpted } from '../components/types';
import { ProblemType } from '@customer-frontend/graphql-types';
import { FeatureFlagClient } from '@customer-frontend/feature-flags';
import { getConfig } from '@customer-frontend/config';

export const optedNoMedicare = (): boolean =>
  getMedicareAnsOptedFromSS() === MedicareAnswerOpted.NoMedicare;

const isProblemTypeArray = (
  problemTypes: unknown,
): problemTypes is ProblemType[] =>
  Array.isArray(problemTypes) && problemTypes.every(getProblemTypeFromString);

const getProblemTypeFromString = (str?: string): undefined | ProblemType => {
  if (!str || !isValidProblemType(str)) {
    return undefined;
  }

  return str;
};

const isValidProblemType = (str: string): str is ProblemType => {
  switch (str as ProblemType) {
    case 'ACNE':
    case 'ANTI_AGEING':
    case 'BREASTFEEDING':
    case 'COLD_SORES':
    case 'CONTRACEPTION':
    case 'ERECTILE':
    case 'FERTILITY':
    case 'FERTILITY_HORMONE':
    case 'HAIR':
    case 'HERPES':
    case 'INFANT_SLEEP':
    case 'MENTAL_HEALTH_GOOD':
    case 'MENTAL_HEALTH_MEH':
    case 'MENTAL_HEALTH_SAD':
    case 'DEPRECATED_OTC':
    case 'POSTPARTUM':
    case 'POSTPARTUM_NUTRITION':
    case 'POSTPARTUM_MENTAL_HEALTH':
    case 'POSTPARTUM_PHYSIO':
    case 'PRECONCEPTION_FERTILITY':
    case 'PRECONCEPTION_NUTRITION':
    case 'PRECONCEPTION_MENTAL_HEALTH':
    case 'PRECONCEPTION_PHYSIO':
    case 'PREGNANCY':
    case 'PREGNANCY_NUTRITION':
    case 'PREGNANCY_MENTAL_HEALTH':
    case 'PREGNANCY_PHYSIO':
    case 'PREMATURE':
    case 'SKIN_GENERAL':
    case 'SLEEP':
    case 'UTI':
    case 'STI':
    case 'GENETIC_TEST':
    case 'WEIGHT_LOSS':
    case 'MENOPAUSE':
    case 'WOMENS_HEALTH':
      return true;
    default:
      return false;
  }
};

export const isMedicareEnabled = (
  featureFlagClient: FeatureFlagClient,
  problemType?: ProblemType,
  disableExposureTracking?: boolean,
): boolean => {
  const config = getConfig();
  if (!problemType) {
    return false;
  }
  const potentialDisabledProblemTypes = featureFlagClient.getJson<
    ProblemType[]
  >('DISABLED_MEDICARE_COLLECTION_PROBLEM_TYPES', {
    disableExposureTracking,
    defaultValue: [],
  });
  let disabledProblemTypes: ProblemType[] = [];
  if (isProblemTypeArray(potentialDisabledProblemTypes)) {
    disabledProblemTypes = potentialDisabledProblemTypes;
  }
  const isEnabled =
    config.enableMedicare && !disabledProblemTypes.includes(problemType);
  return isEnabled;
};
