import { WeightDashboardBrandTheme } from './types';

export const theme: WeightDashboardBrandTheme = {
  tabContainer: {
    bgColorClassName: 'bg-primary-400',
    borderColorClassName: 'border-primary-300',
  },
  tab: {
    textColorClassName: 'text-white',
    bgColorClassName: {
      active: 'bg-white bg-opacity-10',
      default: 'bg-primary-400',
    },
    borderColorClassName: 'border-secondary-sky-300',
    unreadIndicator: {
      marginTopClassName: 'mt-0.5',
      backgroundColourClassName: 'bg-neutral-white',
    },
  },
};
