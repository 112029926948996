import { ReactElement } from 'react';
import { AspectRatio } from '@chakra-ui/react';
import { YoutubeVideoProps } from './types';

export const EmbeddedVideo = ({
  youtubeId,
  url,
  maxWidth = '100%',
  ratio = 8 / 5,
}: YoutubeVideoProps): ReactElement => (
  <AspectRatio maxW={maxWidth} ratio={ratio}>
    <iframe
      src={url || `https://www.youtube-nocookie.com/embed/${youtubeId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </AspectRatio>
);
