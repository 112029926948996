import React, { ReactElement } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  MutationPasswordResetArgs,
  TriggerPasswordResetPayload,
} from '@customer-frontend/graphql-types';
import {
  TextInput,
  Button,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  matchesValue,
  useUrlQuery,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { ResetPasswordTemplateProps } from './types';
import { useIntl, FormattedMessage } from 'react-intl';

const resetPasswordDocument = gql`
  mutation PasswordReset(
    $email: String
    $password: String
    $resetToken: String
  ) {
    passwordReset(email: $email, password: $password, resetToken: $resetToken) {
      ok
    }
  }
`;

export const ResetPasswordTemplate = ({
  buttonPalette,
  onComplete,
  showHeader = true,
}: ResetPasswordTemplateProps): ReactElement => {
  const notify = useNotification();
  const url = useUrlQuery();
  const { formatMessage } = useIntl();
  const email = url.get('email');
  const resetToken = url.get('token');

  const handleError = (): void => {
    notify.error({
      message: formatMessage({
        defaultMessage:
          'An error has occurred while attempting to reset your password. Please contact support.',
      }),
    });
  };

  const [passwordReset, { loading }] = useMutation<
    {
      passwordReset: TriggerPasswordResetPayload;
    },
    MutationPasswordResetArgs
  >(resetPasswordDocument, {
    onCompleted: (result) => {
      if (result.passwordReset.ok) {
        onComplete();
      } else {
        handleError();
      }
    },
  });

  const handleFormSubmit = (data: { password: string }): void => {
    if (email && resetToken && data.password) {
      passwordReset({
        variables: { email, resetToken, password: data.password },
      });
    } else {
      notify.error({
        message: formatMessage({
          defaultMessage:
            'Something has gone wrong. Please retry the link you received in your email and try again.',
        }),
      });
    }
  };

  const { register, handleSubmit, watch, errors } = useForm<{
    password: string;
    confirmPassword: string;
  }>();

  const passwordLabel = formatMessage({
    defaultMessage: 'Password',
  });
  const passwordValidation = useRequiredValidation(passwordLabel);

  const confirmPasswordLabel = formatMessage({
    defaultMessage: 'Confirm password',
  });
  const confirmPasswordValidation = {
    ...useRequiredValidation(confirmPasswordLabel),
    ...matchesValue(watch().password, {
      name: 'confirmPassword',
      message: formatMessage({
        defaultMessage: 'Passwords do not match',
        description: 'Error message for when two passwords dont match',
      }),
    }),
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      {showHeader && (
        <div className="text-center mb-8 space-y-2">
          <Typography size="lg" element="h1">
            <FormattedMessage
              defaultMessage="Reset Password"
              description="Title of reset password page"
            />
          </Typography>
          <Typography size="large-paragraph">
            <FormattedMessage defaultMessage="Create your new password using the form below." />
          </Typography>
        </div>
      )}
      <div className="mb-3 md:mb-6">
        <TextInput
          required
          ref={register(passwordValidation)}
          label={passwordLabel}
          name="password"
          type="password"
          errorMessage={errors?.password?.message}
          palette={buttonPalette}
        />
      </div>
      <div className="mb-8">
        <TextInput
          required
          ref={register(confirmPasswordValidation)}
          label={confirmPasswordLabel}
          name="confirmPassword"
          type="password"
          errorMessage={errors?.confirmPassword?.message}
          palette={buttonPalette}
        />
      </div>
      <Button
        level="primary"
        palette={buttonPalette}
        isLoading={loading}
        isSubmit
        isFullWidth
      >
        <FormattedMessage defaultMessage="Reset password" />
      </Button>
    </form>
  );
};
