import { ApolloError } from '@apollo/client';
import { GqlErrorCode } from '@eucalyptusvc/errors';
import { MessageDescriptor } from 'react-intl';
import { errorMessageDescriptors } from './error-message-descriptors';

export const getErrorMessageDescriptorsFromError = (
  error: unknown,
  defaultDescriptor?: MessageDescriptor,
): [MessageDescriptor, ...MessageDescriptor[]] => {
  if (error instanceof ApolloError) {
    const messageDescriptors = error.graphQLErrors
      .map((error) => errorMessageDescriptors[error.extensions?.code])
      .filter((messageDescriptor) => !!messageDescriptor);

    if (messageDescriptors.length > 0) {
      return messageDescriptors as [MessageDescriptor, ...MessageDescriptor[]];
    }
  }

  if (defaultDescriptor) {
    return [defaultDescriptor];
  }

  return [errorMessageDescriptors[GqlErrorCode.INTERNAL_SERVER_ERROR]];
};
