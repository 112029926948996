/**
 * Check if localSession or sessionStorage is supported in browser or not.
 * @param type
 * @returns
 */
export function storageAvailable(
  type: 'localStorage' | 'sessionStorage',
): boolean {
  try {
    const storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}
