import { Config } from '../type';
import { defaultConfig } from './default';

export const SoftwareConfig: Config = {
  ...defaultConfig,
  brand: 'software',
  analyticsBrand: 'software',
  loginUrl: '/login',
  title: 'Software',
  stripeApi: 'CHARGE',
  country: 'Australia',
  countryCode: 'AU',
  locale: 'en-AU',
  currency: 'AUD',
  supportEmail: 'hello@skin.software',
  helpCentreUrl: 'https://help.skin.software/hc/en-us',
  zendeskRequestUrl: 'https://help.skin.software/hc/en-us/requests/new',
  problemTypeZendeskParams: {
    ACNE: { ticket_form_id: '15566373346201' },
    ANTI_AGEING: { ticket_form_id: '15566373346201' },
    HAIR: { ticket_form_id: '15566376605977' },
    SKIN_GENERAL: { ticket_form_id: '15566373346201' },
  },
  contactUsUrl: 'https://www.skin.software/contact-us',
  enableZip: true,
  showOnboardingQuiz: true,
  enableMedicare: true,
  urlPaths: {
    ...defaultConfig.urlPaths,
    privacyPolicy: '/privacy',
  },
};
