import { FormattedMessage } from 'react-intl';
import { Maybe, ResidentialAddress } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { Typography } from '@eucalyptusvc/design-system';
import { AddressFields } from './types';

type IntlAddressViewProps = {
  address: Maybe<AddressFields> | Maybe<ResidentialAddress>;
};

export const IntlAddressView = ({
  address,
}: IntlAddressViewProps): React.ReactElement => {
  const { countryCode } = getConfig();

  if (!address) {
    return (
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="No address saved"
          description="Message that there is no address saved"
        />
      </Typography>
    );
  }

  switch (countryCode) {
    case 'JP':
      return (
        <div>
          <Typography size="medium-paragraph">{address.postalCode}</Typography>
          <Typography size="medium-paragraph">{address.prefecture}</Typography>
          <Typography size="medium-paragraph">
            {address.municipality}
          </Typography>
          <Typography size="medium-paragraph">{address.townArea}</Typography>
          <Typography size="medium-paragraph">{address.country}</Typography>
        </div>
      );
    default: {
      return (
        <div>
          <Typography size="medium-paragraph">{address.line1}</Typography>
          {address.line2 && (
            <Typography size="medium-paragraph">{address.line2}</Typography>
          )}
          <Typography size="medium-paragraph">
            {address.city} {address.postalCode}
          </Typography>
          <Typography size="medium-paragraph">{address.state}</Typography>
          <Typography size="medium-paragraph">{address.country}</Typography>
          <Typography size="medium-paragraph">{address.building}</Typography>
          <Typography size="medium-paragraph">{address.company}</Typography>
        </div>
      );
    }
  }
};
