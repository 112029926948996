import clsx from 'clsx';
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Brand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';
import { ApolloError, gql } from '@apollo/client';
import {
  Typography,
  Button,
  ButtonPalette,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  useRefreshPaymentDataQuery,
  useRemoveZip,
  useUpdateDefaultPaymentGateway,
  useUpdatePayment,
  useEnablePaymentIntent,
} from '@customer-frontend/services';
import {
  Maybe,
  PaymentGateway,
  BillingDetailsFragment,
  SavedCardPaymentMethod,
  SavedPayPalPaymentMethod,
  SavedZipPaymentMethod,
  SavedSofortPaymentMethod,
} from '@customer-frontend/graphql-types';
import { PaymentCardForm } from './stripe-payment-form/payment-card';
import {
  getDefaultPaymentMethod,
  getNonDefaultPaymentMethods,
  getStripePaymentMethod,
  getZipPaymentMethod,
} from '../logic/payment/stripe';
import { FormattedMessage, useIntl } from 'react-intl';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import {
  CardPaymentBanner,
  ZipPaymentBanner,
  SofortPaymentBanner,
  PaypalPaymentBanner,
} from '@customer-frontend/page-templates';
import { PaymentIcons } from './payment-icons';

interface BillingDetailsProps {
  hasZip: Maybe<boolean>;
  savedPaymentMethods: BillingDetailsFragment['savedPaymentMethods'];
  hidePaymentMethodLabel?: boolean;
  cancelButtonVisibility?: 'default' | 'always';
  onCancel?: () => void;
  returnUrl?: string;
  enableEditing?: boolean;
}

// NOTE: This needs to be improved (temporary solution)
const getPaymentOptionStyles = (brand: Brand): string => {
  const common = 'p-4';

  switch (brand) {
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
    case 'pilot':
      return `${common} rounded border border-primary-300`;
    case 'kin':
      return 'px-4 py-3 rounded border border-primary-800';
    case 'software':
      return `${common} rounded-lg bg-gray-100`;
    default:
      return '';
  }
};

const getPaymentOptionEditStyles = (brand: Brand): string => {
  const common = 'p-4';

  switch (brand) {
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
    case 'pilot':
      return `${common} rounded border border-primary-300`;
    case 'software':
      return `${common} rounded-lg bg-gray-100`;
    default:
      return '';
  }
};

const getSecondaryButtonPalette = (brand: Brand): ButtonPalette => {
  switch (brand) {
    case 'pilot': {
      return 'white';
    }

    case 'kin': {
      return 'alternate';
    }

    default: {
      return 'default';
    }
  }
};

const getPrimaryButtonPalette = (brand: Brand): ButtonPalette => {
  switch (brand) {
    case 'pilot':
    case 'kin': {
      return 'alternate';
    }

    default: {
      return 'default';
    }
  }
};

export const BillingDetails = ({
  hasZip,
  savedPaymentMethods,
  hidePaymentMethodLabel = false,
  cancelButtonVisibility = 'default',
  returnUrl,
  onCancel,
  enableEditing = false,
}: BillingDetailsProps): React.ReactElement => {
  const defaultPaymentMethod = getDefaultPaymentMethod(savedPaymentMethods);
  const nonDefaultPaymentMethods =
    getNonDefaultPaymentMethods(savedPaymentMethods);

  /**
   * When a user "edits" a Stripe payment method, they are in fact creating a new
   * payment method. As a result, every time, a new payment method is added to their
   * Stripe record. This if...else statement ensures that we only display the latest
   * added payment method in their account settings.
   */
  let otherPaymentMethod: Maybe<
    | SavedCardPaymentMethod
    | SavedPayPalPaymentMethod
    | SavedSofortPaymentMethod
    | SavedZipPaymentMethod
  >;
  if (defaultPaymentMethod?.gateway === 'ZIP') {
    if (nonDefaultPaymentMethods?.length) {
      otherPaymentMethod = nonDefaultPaymentMethods[0];
    }
  } else {
    const zipPaymentMethodIfAvailable = getZipPaymentMethod(
      nonDefaultPaymentMethods,
    );
    if (zipPaymentMethodIfAvailable) {
      otherPaymentMethod = zipPaymentMethodIfAvailable;
    }
  }

  const [isInitiallyEditing, setIsInitiallyEditing] = useState(
    !defaultPaymentMethod || enableEditing,
  );

  const hasSavedStripePaymentMethod =
    !!getStripePaymentMethod(savedPaymentMethods);
  // only allow customers to move between payment methods, guaranteeing
  // at least one is set at all times
  const canEditPaymentMethods = hasZip && hasSavedStripePaymentMethod;
  const canRemoveZip = savedPaymentMethods
    ? savedPaymentMethods.length > 1
    : false;

  if (isInitiallyEditing) {
    return (
      <div className="space-y-4 mb-2">
        <AddOrEditPaymentMethod
          returnUrl={returnUrl}
          onCancelEditing={
            defaultPaymentMethod && hasSavedStripePaymentMethod
              ? () => setIsInitiallyEditing(false)
              : cancelButtonVisibility === 'always'
              ? onCancel
              : undefined
          }
          onEditingComplete={() => setIsInitiallyEditing(false)}
        />
      </div>
    );
  }

  return (
    <div className="space-y-4 mb-2">
      {defaultPaymentMethod && (
        <div>
          {!hidePaymentMethodLabel && (
            <div className="uppercase mb-3">
              <Typography element="span" size="paragraph">
                <FormattedMessage
                  defaultMessage="Default payment method"
                  description="Label for a users default payment method"
                />
              </Typography>
            </div>
          )}

          <PaymentMethod
            returnUrl={returnUrl}
            paymentMethod={defaultPaymentMethod}
            canRemoveZip={canRemoveZip}
          />
        </div>
      )}

      {canEditPaymentMethods && !!otherPaymentMethod ? (
        <div>
          {!hidePaymentMethodLabel && (
            <div className="uppercase mb-3">
              <Typography element="span" size="paragraph">
                <FormattedMessage
                  defaultMessage="Other payment options"
                  description="Prompt for the user to look into other payment options"
                />
              </Typography>
            </div>
          )}

          <div className="flex flex-col gap-2">
            <PaymentMethod
              paymentMethod={otherPaymentMethod}
              canRemoveZip={canRemoveZip}
              returnUrl={returnUrl}
            />
          </div>
        </div>
      ) : null}

      {!otherPaymentMethod && defaultPaymentMethod?.gateway === 'ZIP' ? (
        <AddAdditionalPaymentMethod returnUrl={returnUrl} />
      ) : null}
    </div>
  );
};

type PaymentMethodProps = {
  paymentMethod: Maybe<
    | SavedCardPaymentMethod
    | SavedPayPalPaymentMethod
    | SavedSofortPaymentMethod
    | SavedZipPaymentMethod
  >;
  returnUrl?: string;
  canRemoveZip?: boolean;
};

function PaymentMethod({
  paymentMethod,
  returnUrl,
  canRemoveZip,
}: PaymentMethodProps) {
  const config = getConfig();
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  const [updateDefaultPaymentMethod] = useUpdateDefaultPaymentGateway();
  const [refreshUserPaymentData] = useRefreshPaymentDataQuery({
    fetchPolicy: 'network-only',
  });
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const [removeZip] = useRemoveZip();

  const paymentOptionStyles = getPaymentOptionStyles(config.brand);

  const setDefaultPaymentGateway = async (
    gateway: PaymentGateway,
  ): Promise<void> => {
    try {
      await updateDefaultPaymentMethod({
        variables: {
          gateway,
        },
      });
      await refreshUserPaymentData();
    } catch (err) {
      const descriptions = getErrorMessageDescriptorsFromError(err);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    }
  };

  const removeZipFromPaymentMethods = async (): Promise<void> => {
    try {
      await removeZip();
      await setDefaultPaymentGateway('STRIPE');
      await refreshUserPaymentData();
    } catch (err) {
      notify.error({
        message: formatMessage({
          defaultMessage: 'Failed to remove Zip. Please try again',
          description: 'Error message for failing to remove Zip payment method',
        }),
      });
    }
  };

  let paymentMethodElement;
  switch (paymentMethod?.__typename) {
    case 'SavedCardPaymentMethod': {
      paymentMethodElement = (
        <CardPaymentBanner
          onEdit={() => setEditPaymentMethod(true)}
          paymentMethod={paymentMethod}
        />
      );
      break;
    }

    case 'SavedPayPalPaymentMethod': {
      paymentMethodElement = (
        <PaypalPaymentBanner
          onEdit={() => setEditPaymentMethod(true)}
          paymentMethod={paymentMethod}
        />
      );
      break;
    }

    case 'SavedSofortPaymentMethod': {
      paymentMethodElement = (
        <SofortPaymentBanner onEdit={() => setEditPaymentMethod(true)} />
      );
      break;
    }

    case 'SavedZipPaymentMethod': {
      paymentMethodElement = (
        <ZipPaymentBanner
          classNames={paymentOptionStyles}
          paymentMethod={paymentMethod}
          onSetPaymentMethodAsDefault={() => setDefaultPaymentGateway('ZIP')}
          onRemovePaymentMethod={
            canRemoveZip ? removeZipFromPaymentMethods : undefined
          }
        />
      );
      break;
    }

    default: {
      return null;
    }
  }

  if (editPaymentMethod) {
    return (
      <AddOrEditPaymentMethod
        returnUrl={returnUrl}
        onCancelEditing={() => setEditPaymentMethod(false)}
        onEditingComplete={() => setEditPaymentMethod(false)}
      />
    );
  }

  return (
    <div className="flex">
      {paymentMethodElement}

      {!paymentMethod.default &&
        paymentMethod.__typename !== 'SavedZipPaymentMethod' && (
          <button
            className="underline cursor-pointer ml-2"
            onClick={() => setDefaultPaymentGateway(paymentMethod.gateway)}
          >
            <FormattedMessage
              defaultMessage="Set default"
              description="Button to set default payment method"
            />
          </button>
        )}
    </div>
  );
}

type AddOrEditPaymentMethodProps = {
  returnUrl?: string;
  onCancelEditing?: () => void;
  onEditingComplete: () => void;
};

function AddOrEditPaymentMethod({
  returnUrl,
  onEditingComplete,
  onCancelEditing,
}: AddOrEditPaymentMethodProps) {
  const [isSavingOrUpdatingPaymentMethod, setIsSavingOrUpdatingPaymentMethod] =
    useState(false);
  const enablePaymentIntent = useEnablePaymentIntent();
  const [updatePayment] = useUpdatePayment({
    onCompleted: () => setIsSavingOrUpdatingPaymentMethod(false),
  });
  const [updateDefaultPaymentMethod] = useUpdateDefaultPaymentGateway();
  const [refreshUserPaymentData] = useRefreshPaymentDataQuery({
    fetchPolicy: 'network-only',
  });
  const notify = useNotification();
  const stripe = useStripe();
  const stripeElements = useElements();
  const config = getConfig();
  const { formatMessage } = useIntl();

  const paymentOptionEditStyles = getPaymentOptionEditStyles(config.brand);

  const setDefaultPaymentGateway = async (
    gateway: PaymentGateway,
  ): Promise<void> => {
    try {
      await updateDefaultPaymentMethod({
        variables: {
          gateway,
        },
      });
      await refreshUserPaymentData();
    } catch (err) {
      const descriptions = getErrorMessageDescriptorsFromError(err);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    }
  };

  const savePaymentDetailsWithStripe = async () => {
    setIsSavingOrUpdatingPaymentMethod(true);

    try {
      if (!stripeElements || !stripe) {
        return;
      }

      if (!enablePaymentIntent) {
        const cardElement = stripeElements.getElement(CardElement);

        if (!cardElement) {
          return;
        }

        const { token, error } = await stripe.createToken(cardElement);

        if (error || !token?.card) {
          throw new Error(
            error?.message ??
              formatMessage({
                defaultMessage: 'Payment method is invalid.',
                description: 'Error message for invalid payment method',
              }),
          );
        }

        await updatePayment({
          variables: {
            stripeTokenId: token.id,
          },
        });
      } else {
        if (returnUrl) {
          const { error } = await stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements: stripeElements,
            confirmParams: {
              return_url: returnUrl,
            },
          });
          if (error) {
            throw error;
          }
        } else {
          throw new Error(
            formatMessage({
              defaultMessage: 'Missing returnUrl',
              description: 'Error message for missing url',
            }),
          );
        }
      }
      await setDefaultPaymentGateway('STRIPE');

      setIsSavingOrUpdatingPaymentMethod(false);
      onEditingComplete();
    } catch (err) {
      if (err instanceof ApolloError) {
        const descriptions = getErrorMessageDescriptorsFromError(err);
        descriptions.forEach((descriptor) =>
          notify.error({ message: formatMessage(descriptor) }),
        );
      } else {
        notify.error({ message: err.message });
      }

      setIsSavingOrUpdatingPaymentMethod(false);
    }
  };

  return (
    <div className={clsx('w-full', paymentOptionEditStyles)}>
      <PaymentCardForm />
      <div className="flex justify-between pt-4 space-x-4">
        {onCancelEditing && (
          <Button
            eventElementName="accountPageBillingDetailsCancelButton"
            isFullWidth
            level="secondary"
            palette={getSecondaryButtonPalette(config.brand)}
            isDisabled={isSavingOrUpdatingPaymentMethod}
            onClick={onCancelEditing}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              description="Button that cancels action"
            />
          </Button>
        )}
        <Button
          eventElementName="accountPageBillingDetailsSaveButton"
          isSubmit
          isFullWidth
          palette={getPrimaryButtonPalette(config.brand)}
          isLoading={isSavingOrUpdatingPaymentMethod}
          onClick={savePaymentDetailsWithStripe}
        >
          <FormattedMessage
            defaultMessage="Save"
            description="Button to save billing details"
          />
        </Button>
      </div>
    </div>
  );
}

type AddAdditionalPaymentMethodProps = {
  returnUrl?: string;
};

function AddAdditionalPaymentMethod({
  returnUrl,
}: AddAdditionalPaymentMethodProps) {
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
  const config = getConfig();

  const paymentOptionStyles = getPaymentOptionStyles(config.brand);

  if (isAddingPaymentMethod) {
    return (
      <div>
        <div className="uppercase mb-3">
          <Typography element="span" size="paragraph">
            <FormattedMessage
              defaultMessage="Other payment options"
              description="Prompt for the user to look into other payment options"
            />
          </Typography>
        </div>

        <AddOrEditPaymentMethod
          returnUrl={returnUrl}
          onCancelEditing={() => setIsAddingPaymentMethod(false)}
          onEditingComplete={() => setIsAddingPaymentMethod(false)}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="uppercase mb-3">
        <Typography element="span" size="paragraph">
          <FormattedMessage
            defaultMessage="Other payment options"
            description="Prompt for the user to look into other payment options"
          />
        </Typography>
      </div>

      <div
        className={clsx(
          'flex justify-between items-center',
          paymentOptionStyles,
        )}
      >
        <div className="flex items-center">
          <PaymentIcons renderSeal={false} />
          &nbsp;&nbsp;
          <span className="ml-4">
            <FormattedMessage
              defaultMessage="Credit / Debit card"
              description="Label for credit / debit card"
            />
          </span>
        </div>
        <button
          className="underline cursor-pointer"
          onClick={() => setIsAddingPaymentMethod(true)}
        >
          <FormattedMessage
            defaultMessage="Add"
            description="Button to add card details"
          />
        </button>
      </div>
    </div>
  );
}

BillingDetails.fragment = gql`
  fragment BillingDetails on User {
    id
    defaultPaymentGateway
    savedPaymentMethods {
      id
      gateway
      default
      createdAt
      ... on SavedCardPaymentMethod {
        expiry
        mask
        brand
      }
      ... on SavedZipPaymentMethod {
        valid
      }
      ... on SavedPayPalPaymentMethod {
        email
      }
    }
    zip {
      valid
    }
  }
`;
