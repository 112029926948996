import clsx from 'clsx';
import {
  ImageUpload,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import type { ImageUploadInputProps } from './types';

export const ImageUploadInput = ({
  className,
  palette = 'default',
  errorMessage,
  ...props
}: ImageUploadInputProps): JSX.Element => {
  const notify = useNotification();

  const handleUploadError = (message: string): void => {
    notify.error({ message });
  };

  return (
    <div className={className}>
      <ImageUpload
        {...props}
        hasError={Boolean(errorMessage)}
        onUploadError={handleUploadError}
      />
      {errorMessage && (
        <div
          className={clsx('mt-1', {
            'text-status-error-500': palette === 'default',
            'text-status-error-100': palette === 'alternate',
          })}
        >
          <Typography size="small-text" inheritColor isBold>
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
