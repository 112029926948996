import React from 'react';
import clsx from 'clsx';
import { EducationReading } from '@customer-frontend/graphql-types';
import { useEducationTheme } from '../provider';
import { ReadingButton } from './reading-button';

interface ReadingCollectionListProps {
  readings: EducationReading[];
  onItemClick: (readingId: string) => void;
}

export const ReadingCollectionList = ({
  readings,
  onItemClick,
}: ReadingCollectionListProps): React.ReactElement | null => {
  const theme = useEducationTheme();

  if (!readings.length) {
    return null;
  }

  const handleOnItemClick = (readingId: string): void => {
    onItemClick(readingId);
  };

  return (
    <ul
      className={clsx(
        'divide-y border overflow-hidden',
        theme.brand.readingCollectionList?.containerClassName,
      )}
    >
      {readings.map((reading) => (
        <li key={reading.id}>
          <ReadingButton reading={reading} onReadingClick={handleOnItemClick} />
        </li>
      ))}
    </ul>
  );
};
