import { PrescriberType } from '@customer-frontend/doctor';
import { Config } from '../type';
import { defaultConfig } from './default';
import { ja } from 'date-fns/locale';

export const JuniperJpConfig: Config = {
  ...defaultConfig,
  analyticsBrand: 'juniper',
  brand: 'juniper-jp',
  loginUrl: '/login',
  title: 'Juniper',
  consentRequired: false,
  prescriberType: PrescriberType.DOCTOR,
  stripeApi: 'PAYMENT_INTENT',
  country: '日本',
  countryCode: 'JP',
  locale: 'ja',
  currency: 'JPY',
  emergencyNumber: '119',
  supportEmail: 'contact@myjuniper.jp',
  medicalEmail: 'contact@myjuniper.jp',
  rejectionContactLink: 'mailto:contact@myjuniper.jp',
  helpCentreUrl: 'https://help.myjuniper.jp/hc/ja-jp',
  zendeskRequestUrl: 'https://help.myjuniper.jp/hc/ja-jp/requests/new',
  weightPausingAccountZendeskParams: {
    tf_24440312151065: 'japan_change_or_edit_account',
    tf_24399915765273: 'account_pause_japan',
  },
  weightResumeNowZendeskParams: {
    tf_24440312151065: 'japan_change_or_edit_account',
    tf_24399915765273: 'account_restart_japan',
  },
  contactUsUrl: 'https://www.myjuniper.jp/contact-us',
  clinicianMessagingSupported: false,
  scalesProvided: false,
  moneyBackGuaranteeEnabled: false,
  healthCoachingEnabled: false,
  weightFacebookCommunityUrl: undefined,
  weekendSupportEnabled: false,
  addressConfig: {
    numberBeforeStreet: false,
    showStateInput: false,
    validateShippingAddressWithinRange: false,
  },
  dateConfig: {
    placeholder: 'YYYY/MM/DD',
    format: 'yyyy/MM/dd',
    type: 'masked-date-reversed',
    datefnsLocale: ja,
  },
  urlPaths: {
    privacyNotice: '/privacy',
    privacyPolicy: '/privacy',
    termsAndConditions: '/terms',
    termsOfSale: '/terms',
    termsOfService: '/terms',
    cookieNotice: '/cookie-notice',
    about: '/about',
    contact: '/contact-us',
    waitList: '/wait-list',
    faq: '/faqs',
  },
  weightFaqReadingCollectionId: '4123e28b-ab04-435c-9473-d8ba3550dbc1',
  selfServiceCancelEnabled: false,
  localisedAssets: {
    juniperArchway: '/assets/juniper-archway-2-de.svg',
    juniperProductDesktopImage: '/assets/juniper-product-desktop-de.png',
    juniperProductMobileImage: '/assets/juniper-product-mobile-de.png',
    appWeightTracking: '/assets/app-weight-tracking-de.png',
    medicationBox: '/assets/medication-box-de.png',
    phoneCall: '/assets/phone-call-jp.svg',
    program: {
      boxPen: '/assets/program/box-pen-jp.webp',
      boxPen2x: '/assets/program/box-pen-jp@2x.webp',
      boxPen3x: '/assets/program/box-pen-jp@3x.webp',
      chat: '/assets/program/chat-jp.webp',
      chat2x: '/assets/program/chat-jp@2x.webp',
      chat3x: '/assets/program/chat-jp@3x.webp',
      team: '/assets/program/team-jp.webp',
      team2x: '/assets/program/team-jp@2x.webp',
      team3x: '/assets/program/team-jp@3x.webp',
      food: '/assets/program/food-jp.webp',
      food2x: '/assets/program/food-jp@2x.webp',
      food3x: '/assets/program/food-jp@3x.webp',
      community: '/assets/program/community.webp',
      community2x: '/assets/program/community@2x.webp',
      community3x: '/assets/program/community@3x.webp',
      members: '/assets/program/members.webp',
      members2x: '/assets/program/members@2x.webp',
      members3x: '/assets/program/members@3x.webp',
      statisticOne: '/assets/program/statistic-one-jp.svg',
      statisticTwo: '/assets/program/statistic-two-jp.svg',
      statisticThree: '/assets/program/statistic-three-jp.svg',
      staceyBeforeAfter: '/assets/program/staceybeforeafter.webp',
      staceyBeforeAfter2x: '/assets/program/staceybeforeafter@2x.webp',
      staceyBeforeAfter3x: '/assets/program/staceybeforeafter@3x.webp',
      rebekahBeforeAfter: '/assets/program/rebekahbeforeafter.webp',
      rebekahBeforeAfter2x: '/assets/program/rebekahbeforeafter@2x.webp',
      rebekahBeforeAfter3x: '/assets/program/rebekahbeforeafter@3x.webp',
    },
    consultation: {
      fullRefund: '/assets/consultation/circle-dollar-lavender-jp.svg',
      cancel: '/assets/consultation/circle-cancel-lavender-jp.svg',
      thirtyDay: '/assets/consultation/circle-30day-lavender-jp.svg',
      initialScreening: '/assets/consultation/initial-screening-jp.webp',
      paymentForTreatment: '/assets/consultation/payment-for-treatment-jp.webp',
      medicalScreening: '/assets/consultation/medical-screening-jp.webp',
      treatmentDelivery: '/assets/consultation/treatment-delivery-jp.webp',
      startWelcome: '/assets/consultation/juniper-box.webp',
    },
  },
  externalSupportNumber: '0120-783-556',
  externalSupportLabel: 'いのちの電話',
  batchedOzempicEnabled: false,
  twoDayDelivery: false,
  consultationCreateAccountPage: true,
  collectAddressPage: true,
  enableMedicare: false,
  flexiBrand: true,
  purchaseActivationFlow: {
    moneyBackGuaranteeDays: 30,
  },
};
