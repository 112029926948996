import type { SystemStyleObject } from '@chakra-ui/theme-tools';

interface FormThemeProps {
  colors: {
    container: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getFormStyles = (props: FormThemeProps): SystemStyleObject => ({
  baseStyle: {
    container: {
      width: '100%',
      position: 'relative',
    },
    label: {
      display: 'flex',
    },
    requiredIndicator: {
      marginStart: '4px',
      height: '1px',
    },
    helperText: {
      marginTop: 0,
    },
  },
});

const FormError: SystemStyleObject = {
  baseStyle: {
    text: {
      marginTop: '4px',
    },
  },
};

const FormLabel: SystemStyleObject = {
  baseStyle: {
    display: 'flex',
    marginBottom: '0px',
    lineHeight: '120%',
  },
};

export const getFormComponents = (
  styleProps: FormThemeProps,
): Record<string, SystemStyleObject> => ({
  Form: getFormStyles(styleProps),
  FormError,
  FormLabel,
});
