import {
  Maybe,
  ProblemType,
  ProductType,
} from '@customer-frontend/graphql-types';

interface OtcProduct {
  priority: number;
  productType: ProductType;
  problemTypes: ProblemType[];
  variants: {
    inventory?: Maybe<{
      sku?: string | null | undefined;
    }>;
  }[];
}

export const getOtcProductsForProblemType = <T extends OtcProduct>(
  products: T[],
  problemType: ProblemType,
  disabledAttachmentSkus: string[] = [],
): T[] => {
  const otcProducts =
    products.filter((p) => {
      const isOtcType =
        p.productType === 'OTC' && p.problemTypes.includes(problemType);
      const isDisabledSku = disabledAttachmentSkus.some((disabledSku) =>
        p.variants.some(({ inventory }) => inventory?.sku === disabledSku),
      );
      return isOtcType && !isDisabledSku;
    }) ?? [];
  return otcProducts.sort((a, b) => b.priority - a.priority);
};
