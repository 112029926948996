import { ActionModal, Typography } from '@eucalyptusvc/design-system';
import React from 'react';

export type SubscriptionTwoActionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onSecondaryAction: () => void;
  confirmText: string;
  secondaryActionText: string;
  headingText: string;
  bodyText: string;
  primaryActionLoading: boolean;
  secondaryActionLoading?: boolean;
};

export const SubscriptionTwoActionsModal: React.FC<
  SubscriptionTwoActionsModalProps
> = ({
  isOpen,
  onClose,
  onConfirm,
  onSecondaryAction,
  headingText,
  bodyText,
  confirmText,
  secondaryActionText,
  primaryActionLoading,
  secondaryActionLoading,
}) => {
  return (
    <ActionModal
      title={headingText}
      primaryAction={{
        label: confirmText,
        onClick: onConfirm,
        isLoading: primaryActionLoading,
      }}
      secondaryAction={{
        label: secondaryActionText,
        onClick: onSecondaryAction,
        isLoading: secondaryActionLoading ?? false,
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="space-y-4">
        <Typography size="medium-paragraph">{bodyText}</Typography>
      </div>
    </ActionModal>
  );
};
