import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import {
  AccordionPalette,
  Button,
  ButtonPalette,
  CardPalette,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { FaqMarkdownItem, FaqPanel } from '@customer-frontend/consultation';
import { Logger } from '@customer-frontend/logger';
import {
  DoctorLetterPageQuery,
  DoctorLetterPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { useTitle } from '@customer-frontend/utils';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import {
  ConsultationNotesTabs,
  DoctorLetter,
} from '@customer-frontend/page-templates';

type DoctorLetterPageProps = {
  consultationId: string;
  routes: {
    consultation: {
      doctorsNote: string;
      safetyInformation: string;
      reviewSubstitution: string;
      reviewFollowUpOrReview: string;
      purchaseActivationReview: string;
    };
    profile: string;
  };
  palette?: {
    doctorLetter?: {
      bio?: CardPalette;
      letter?: CardPalette;
    };
    button?: ButtonPalette;
    faq?: AccordionPalette;
  };
  logger: Logger;
};

export const DoctorLetterPage = ({
  consultationId,
  routes,
  palette,
  logger,
}: DoctorLetterPageProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  useTitle(
    formatMessage({
      defaultMessage: 'Practitioner letter',
      description: 'Page title for the Practitioner letter',
    }),
  );
  const history = useHistory();

  const { data, loading } = useQuery<
    DoctorLetterPageQuery,
    DoctorLetterPageQueryVariables
  >(
    gql`
      query DoctorLetterPage($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          purchasePrompt {
            id
          }
          patientNotes
          doctor {
            id
            ...DoctorLetterComponent
          }
          prescribedSequence {
            id
            createdAt
            sequence {
              id
              faqs {
                id
                rank
                question
                answerMarkdown
              }
            }
          }
        }
      }

      ${DoctorLetter.fragment}
    `,
    { variables: { consultationId } },
  );

  const {
    consultation,
    doctor,
    patientNotes,
    prescribedSequence,
    prescribedDate,
    faqs,
  } = useMemo(() => {
    const consultation = data?.consultation;
    const prescribedSequence = consultation?.prescribedSequence;
    const faqs: FaqMarkdownItem[] =
      prescribedSequence?.sequence?.faqs?.map((faq) => ({
        title: faq.question,
        markdown: faq.answerMarkdown,
        rank: faq.rank,
      })) ?? [];

    return {
      consultation,
      doctor: consultation?.doctor,
      patientNotes: consultation?.patientNotes,
      prescribedSequence,
      prescribedDate: prescribedSequence?.createdAt,
      faqs,
    };
  }, [data]);

  const goToNextRoute = (): void => {
    let nextRoute = routes.profile;

    switch (consultation?.purchasePrompt?.__typename) {
      case 'SubstitutePurchasePrompt':
      case 'ConfirmPurchasePrompt':
        nextRoute = routes.consultation.purchaseActivationReview;
        break;
      case 'FurPurchasePrompt':
        nextRoute = routes.consultation.reviewFollowUpOrReview;
        break;
    }

    return history.push(nextRoute);
  };

  useBackButtonBehaviour(goToNextRoute);

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (
    !consultation ||
    !doctor ||
    !patientNotes ||
    !prescribedSequence ||
    !prescribedDate
  ) {
    throw new Error('Unable to load practitioner notes');
  }

  if (!consultation.purchasePrompt) {
    logger.error(
      `no purchase prompt for consultation id "${consultationId}", redirecting to profile`,
    );
    return <Redirect to={routes.profile} />;
  }

  return (
    <article className="max-w-screen-md px-4 pt-10 md:pt-14 pb-10 md:pb-24 mx-auto flex flex-col gap-8">
      <ConsultationNotesTabs
        routes={{
          doctorsNote: routes.consultation.doctorsNote,
          pharmacyInformation: routes.consultation.safetyInformation,
        }}
        variant="border-around"
      />

      <DoctorLetter
        doctor={doctor}
        patientNotes={patientNotes}
        prescribedDate={prescribedDate}
        palette={palette?.doctorLetter}
      />

      <Button isFullWidth onClick={goToNextRoute} palette={palette?.button}>
        <FormattedMessage
          defaultMessage="Back to treatment plan"
          description="Button text indicating back to the treatment plan page"
        />
      </Button>

      <Divider variant="separator" mt="sm" />

      <section className="space-y-4">
        <Typography isBold size="lg">
          <FormattedMessage
            defaultMessage="FAQs"
            description="Doctor letter FAQ title"
          />
        </Typography>

        <FaqPanel faqs={faqs} accordionPalette={palette?.faq} />
      </section>
    </article>
  );
};
