import { FormattedMessage } from 'react-intl';
import { Card, Markdown, Typography } from '@eucalyptusvc/design-system';

type DeliveryInformationProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  markdown: string;
};

export const DeliveryInformation = ({
  Icon,
  markdown,
}: DeliveryInformationProps): React.ReactElement => {
  return (
    <Card>
      <div className="flex items-center gap-2 mb-2">
        <Icon className="fill-current" />
        <Typography isBold size="medium-paragraph">
          <FormattedMessage defaultMessage="A note about the delivery of your medication" />
        </Typography>
      </div>
      <Markdown src={markdown} formatWhitespace />
    </Card>
  );
};
