import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRefillCooldown } from '@customer-frontend/utils';
import { Typography, TypographySize } from '@eucalyptusvc/design-system';

export const RefillCooldown = ({
  link,
  cooldownActiveUntil,
  size = 'small-text',
}: {
  link: string;
  cooldownActiveUntil?: string;
  size?: TypographySize;
}): React.ReactElement => {
  const { isCooldownActive } = useRefillCooldown(cooldownActiveUntil);

  if (!isCooldownActive) {
    return <></>;
  }

  return (
    <Typography size={size}>
      <FormattedMessage
        defaultMessage={`Unfortunately, you cannot refill this right now. If you need an early refill now, you can<a> make a request here </a>with the enquiry type <p>Early Refill</p>.`}
        values={{
          a: (chunks) => (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              {chunks}
            </a>
          ),
          p: (chunks) => <p className="font-bold inline">{chunks}</p>,
        }}
      />
    </Typography>
  );
};
