import React from 'react';
import { ConsentManager } from '@segment/consent-manager';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { ActionsBlockProps } from '@segment/consent-manager/types/types';
import { FormattedMessage } from 'react-intl';
import { useEnvironment } from '@customer-frontend/environment';
import { usePreferencesDialogTemplate } from './use-preferences-dialog-template';
import { getConfig } from '@customer-frontend/config';

/**
 * This is a wrapper for the the Segment Consent Manager, which loads the destinations from segment
 * and allows the user to accept/deny them based on category.
 *
 * This delays loading the analytics library until cookies have been accepted.
 *
 * For more detail see here: https://github.com/segmentio/consent-manager
 */

export const CookieConsentManager: React.FC = () => {
  const environment = useEnvironment();
  const config = getConfig();
  const preferencesDialogTemplate = usePreferencesDialogTemplate();

  if (!config.consentRequired) {
    return null;
  }

  const bannerContent = (
    <Typography size="medium-paragraph">
      <FormattedMessage
        defaultMessage={`We use essential cookies to make our website work. We would also like to use other cookies to allow you to get the best experience when using our website. These other cookies will be set only if you accept. To learn more about our use of cookies, visit our <a> Cookie Notice. </a>`}
        description="Text explaining what the cookie consent is and why we need it to provide our service"
        values={{
          a: (chunks) => (
            <a
              href={`${environment.landingPageUrl}${config.urlPaths.cookieNotice}`}
              className="text-link"
            >
              {chunks}
            </a>
          ),
        }}
      />
    </Typography>
  );

  const bannerActions = ({
    acceptAll,
    changePreferences,
  }: ActionsBlockProps): React.ReactElement => (
    <div className="m-2 md:mx-4 md:my-2 space-y-4">
      <Button isFullWidth onClick={acceptAll}>
        <FormattedMessage
          description="Text for the button that the user clicks when they consent to the usage of cookies"
          defaultMessage="Accept all"
        />
      </Button>
      <Button isFullWidth onClick={changePreferences} level="secondary">
        <FormattedMessage
          description="Text for the button that the user clicks they can determine the cookie consent settings they're agreeing to"
          defaultMessage="Cookie settings"
        />
      </Button>
    </div>
  );

  return (
    <div className="fixed bottom-0 border text-base z-100">
      <ConsentManager
        writeKey={environment.segmentKey}
        bannerContent={bannerContent}
        bannerSubContent=""
        preferencesDialogTitle={
          <FormattedMessage
            description="Title for the cookie settings dialog"
            defaultMessage="Website Data Collection Preferences"
          />
        }
        preferencesDialogContent={
          <FormattedMessage
            description="Description for the cookie settings dialog. Explains its purpose and impact."
            defaultMessage="We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site."
          />
        }
        preferencesDialogTemplate={preferencesDialogTemplate}
        cancelDialogTitle={
          <FormattedMessage
            description="Title for the cancel cookie settings dialog"
            defaultMessage="Are you sure you want to cancel?"
          />
        }
        cancelDialogContent={
          <FormattedMessage
            description="Description for the cancel cookie settings dialog. Explains its purpose and impact."
            defaultMessage="Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy."
          />
        }
        bannerBackgroundColor="white"
        bannerTextColor="black"
        bannerActionsBlock={bannerActions}
        bannerHideCloseButton={true}
        defaultDestinationBehavior="imply"
      />
    </div>
  );
};
