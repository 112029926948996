import React, { useState, useCallback } from 'react';
import { Dropdown, Button } from '@eucalyptusvc/design-system';
import { useEnvironment } from '@customer-frontend/environment';

export type MockDataSet = {
  id: string;
  name: string;
  values: {
    value: number;
    effectiveFrom: string;
  }[];
};

export const MockDataToggle = ({
  mockData,
  onSelectDataSet,
}: {
  mockData: MockDataSet[];
  onSelectDataSet: (selected?: MockDataSet) => void;
}): React.ReactElement | null => {
  const environment = useEnvironment();
  const [isDemoDataVisible, setIsDemoDataVisible] = useState(true);
  const showMockData =
    environment.appEnv === 'development' && isDemoDataVisible;
  const onChangeDropdown = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedDataSet = mockData.find(
        (mockDataUser) => mockDataUser.id === e.target.value,
      );

      onSelectDataSet(selectedDataSet);
    },
    [mockData, onSelectDataSet],
  );

  const dropdownOptions = [
    {
      label: 'Select a demo data set (Currently showing your tracker data)',
      value: 'NULL',
    },
    ...mockData.map((mockDataSet) => ({
      label: mockDataSet.name,
      value: mockDataSet.id,
    })),
  ];

  if (!showMockData) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      <Dropdown
        name="userId"
        onChange={onChangeDropdown}
        options={dropdownOptions}
      />
      <Button level="primary" onClick={() => setIsDemoDataVisible(false)}>
        Hide
      </Button>
    </div>
  );
};
