import { useQuery, gql, QueryResult } from '@apollo/client';
import { QuizApplicationQuery } from '@customer-frontend/graphql-types';

export const getQuizApplicationFragment = gql`
  fragment QuizApplication on QuizApplication {
    id
    createdAt
    customerId
    consultation {
      id
      status
      stage
      isUpfrontPayment
      medium
      canSchedulePractitionerBooking
    }
    purpose
    problemType
    answers(orderBy: { createdAt: asc }) {
      id
      answer
      answersArray
      englishAnswer: answer(languageCode: EN_AU)
      englishAnswersArray: answersArray(languageCode: EN_AU)
      question {
        id
        shortcode
      }
    }
    submittedAt
    quiz {
      id
      default
      quizCode
      questions {
        id
        title
        type
        description
        required
        optionImgLayout
        imgUrl
        shortcode
        options
        questionOptions {
          id
          questionId
          option
          position
          imgUrl
          popup {
            id
            title
            content
            linkOutText
            imgUrl
          }
        }
        popup {
          id
          title
          content
          linkOutText
          imgUrl
        }
      }
      logics {
        id
        from {
          id
        }
        elseJump {
          id
        }
        jumps {
          id
          conditions(orderBy: { order: asc }) {
            id
            question {
              id
            }
            value
            operator
            nextCondition
          }
          to {
            id
          }
        }
      }
      skips {
        id
        from {
          id
        }
        conditions(orderBy: { order: asc }) {
          id
          question {
            id
          }
          value
          operator
          nextCondition
        }
      }
      questionsOrder
    }
    responses {
      id
      question {
        id
        type
        title
        shortcode
        options {
          value
        }
      }
      response {
        answers {
          value
          imageUrl
        }
        flag {
          level
        }
      }
    }
    outcomes {
      ... on QuizOutcomeV2 {
        id
      }
      ... on NotEligibleQuizOutcome {
        reasons
      }
    }
  }
`;

export function useQuizApplication(
  id: string,
): QueryResult<QuizApplicationQuery> {
  return useQuery<QuizApplicationQuery>(
    gql`
      query QuizApplication($id: String!) {
        getQuizApplication(id: $id) {
          ...QuizApplication
        }
      }
      ${getQuizApplicationFragment}
    `,
    {
      variables: {
        id,
      },
    },
  );
}
