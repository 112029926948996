import { Maybe } from '@customer-frontend/graphql-types';

export const getQuizAnswersByShortcode = (
  responses: Maybe<
    {
      question?: { shortcode?: string | null } | null;
      response?: { answers?: { value: string }[] | null } | null;
    }[]
  >,
  shortcode: string,
): { value: string }[] | null | undefined => {
  return responses?.find((r) => r.question?.shortcode === shortcode)?.response
    ?.answers;
};

export const getQuizAnswerByShortcode = (
  responses: Maybe<
    {
      question?: { shortcode?: string | null } | null;
      response?: { answers?: { value: string }[] | null } | null;
    }[]
  >,
  shortcode: string,
): string | undefined => {
  return getQuizAnswersByShortcode(responses, shortcode)?.[0]?.value;
};
