import { useState, type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Button,
  TextArea,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { FetchResult, gql, useMutation } from '@apollo/client';
import {
  useMinLengthValidation,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { addPatientQuestionToConsultationMutation } from '@customer-frontend/services';
import { PrescriberType } from '@customer-frontend/doctor';
import { getConfig } from '@customer-frontend/config';
import { ReactComponent as BubbleQuestion } from '../../../assets/bubble-question.svg';
import { SendMessageConfirmModal } from './send-message-confirm-modal';
import {
  Maybe,
  OfferingSendMessageDoctorFragment,
} from '@customer-frontend/graphql-types';

type SendMessageProps = {
  consultationId: string;
  doctor?: Maybe<OfferingSendMessageDoctorFragment>;
  profileRoute: string;
};

export function SendMessage({
  consultationId,
  doctor,
  profileRoute,
}: SendMessageProps): ReactElement {
  const history = useHistory();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const [showModal, setShowModal] = useState(false);
  const config = getConfig();
  const isJuniperAU = config.brand === 'juniper';

  const { register, handleSubmit, errors, getValues } = useForm<{
    faqDoctorQuestion: string;
  }>();
  const [createQuestion, { loading }] = useMutation(
    addPatientQuestionToConsultationMutation,
    {
      onCompleted: () => {
        notify.success({
          message: formatMessage({
            defaultMessage:
              'Thanks! Your prescriber will answer your question shortly.',
          }),
        });
        history.replace(profileRoute);
      },
    },
  );

  const sendMessageValidationMessage = formatMessage({
    defaultMessage: 'Question or message',
  });
  const sendMessageValidation = {
    ...useRequiredValidation(sendMessageValidationMessage),
    ...useMinLengthValidation(sendMessageValidationMessage, 8),
  };

  const handleCreateQuestion = (): Promise<FetchResult> => {
    const faqDoctorQuestion = getValues('faqDoctorQuestion');
    return createQuestion({
      variables: {
        question: faqDoctorQuestion,
        consultationId,
        trigger: 'question',
      },
    });
  };

  return (
    <>
      <form
        className="flex flex-col"
        onSubmit={handleSubmit(() => {
          if (isJuniperAU) {
            setShowModal(true);
          } else {
            return handleCreateQuestion();
          }
        })}
      >
        <div className="mb-2">
          <Typography size="xs" isBold>
            <BubbleQuestion
              className="inline text-primary-500"
              style={{ marginRight: '6px', marginBottom: '2px' }}
            />
            <FormattedMessage
              defaultMessage="Ask {doctorName} a question"
              values={{ doctorName: doctor?.fullClinicianName }}
            />
          </Typography>
        </div>

        <Typography size="small-text">
          <FormattedMessage
            defaultMessage="*Please only use this if you have medical questions for your {isPrescriber, select, true {prescriber} other {practitioner}}, and make sure to ask them all in one message."
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>

        <div className="mt-2 space-y-4 relative">
          <TextArea
            name="faqDoctorQuestion"
            ref={register(sendMessageValidation)}
            errorMessage={errors?.faqDoctorQuestion?.message}
            placeholder={formatMessage({
              defaultMessage: 'Type your response here',
            })}
            rows={7}
          />

          <div className="absolute bottom-3 right-2 z-10">
            <Button isLoading={loading} level="secondary" isSubmit>
              <FormattedMessage
                defaultMessage="Send"
                description="Button label to send a message"
              />
            </Button>
          </div>
        </div>
      </form>

      <SendMessageConfirmModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onYesClick={handleCreateQuestion}
      />
    </>
  );
}

SendMessage.fragment = gql`
  fragment OfferingSendMessageDoctor on User {
    id
    fullClinicianName
    avatar {
      id
      url
    }
  }
`;
