import React, { useMemo } from 'react';

import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  DiscountCodeFormFragment,
  DiscountStage,
  Maybe,
  PaymentFormFragment,
  PaymentGateway,
} from '@customer-frontend/graphql-types';
import { gql } from '@apollo/client';
import { getReadableBrandName } from '@customer-frontend/utils';
import {
  Card,
  Divider,
  Typography,
  ButtonPalette,
} from '@eucalyptusvc/design-system';
import { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { StripePaymentForm } from '../stripe-payment-form';
import { ZipLogo, ZipModalLauncher } from '../zip-marketing';
import { DiscountCodeForm } from './discount-code-form';

interface PaymentFormProps {
  subtotal: number;
  totalPrice: number;
  onEdit: () => void;
  isLoading: boolean;
  stage: DiscountStage;
  discountCode?: DiscountCodeFormFragment;
  gateway: Maybe<PaymentGateway>;
  zip?: Maybe<{ valid: boolean }>;
  isEditingStripeDetails: boolean;
  defaultDiscountCode?: string | null;
  headerComponent?: React.ReactNode;
  savedPaymentMethods?: PaymentFormFragment['savedPaymentMethods'];

  onAlternativePaymentMethod: (event: PaymentRequestTokenEvent) => void;
  onDiscountCodeChange: (code?: DiscountCodeFormFragment) => void;
  onGatewayChange: (value: React.SetStateAction<Maybe<PaymentGateway>>) => void;
  hasExperimentPaymentPlan?: boolean;
  products: { id: string; price: number }[];
  skipDiscountCodeProductValidation?: boolean;
  applyDiscountCodeButtonPalette?: ButtonPalette;
}

const Checkbox = ({
  checked,
  onClick,
}: {
  checked: boolean;
  onClick: () => void;
}): React.ReactElement => {
  return (
    <div
      className="inline-flex w-5 h-5 items-center justify-center flex-none cursor-pointer"
      onClick={onClick}
    >
      <div className="w-full h-full flex items-center justify-center rounded-full border-2 border-gray-300">
        {checked && <div className="h-3 w-3 rounded-full bg-black" />}
      </div>
    </div>
  );
};

const PaymentForm = ({
  stage,
  onEdit,
  gateway,
  subtotal,
  totalPrice,
  isLoading,
  discountCode,
  savedPaymentMethods,
  zip,
  headerComponent,
  onGatewayChange,
  defaultDiscountCode,
  onDiscountCodeChange,
  isEditingStripeDetails,
  onAlternativePaymentMethod,
  hasExperimentPaymentPlan = false,
  products,
  skipDiscountCodeProductValidation,
  applyDiscountCodeButtonPalette,
}: PaymentFormProps): React.ReactElement => {
  const featureFlagClient = useFeatureFlagClient();
  const config = getConfig();
  const environment = useEnvironment();

  const availablePaymentMethods = useMemo(() => {
    const innerAvailablePaymentMethods: ('STRIPE' | 'ZIP')[] = ['STRIPE'];

    if (config.enableZip) {
      innerAvailablePaymentMethods.push('ZIP');
    }

    return innerAvailablePaymentMethods;
  }, [config.enableZip]);

  const singlePaymentProvider = availablePaymentMethods.length === 1;

  const handleGatewayChange = (gateway: PaymentGateway): void => {
    onGatewayChange((currGateway) =>
      currGateway === gateway ? null : gateway,
    );
  };

  const showDiscountCodeForm = useMemo(() => {
    if (hasExperimentPaymentPlan) {
      return false;
    }
    if (stage === 'ORDER_PAID') {
      return featureFlagClient.getBoolean('ORDER_PAID_DISCOUNTS');
    } else if (stage === 'CONSULTATION_PAID') {
      return featureFlagClient.getBoolean('CONSULT_DISCOUNTS');
    }
    return false;
  }, [stage, featureFlagClient, hasExperimentPaymentPlan]);

  return (
    <div
      className={clsx({
        'cursor-not-allowed opacity-50 pointer-events-none': isLoading,
      })}
    >
      <div>
        {headerComponent || (
          <div className="mb-3">
            <Typography size="md" isBold>
              <FormattedMessage defaultMessage="Payment details" />
            </Typography>
          </div>
        )}
        {showDiscountCodeForm && (
          <div className="mb-4">
            <DiscountCodeForm
              stage={stage}
              code={discountCode}
              subtotal={subtotal}
              defaultCode={defaultDiscountCode}
              onChange={onDiscountCodeChange}
              products={products}
              skipProductValidation={skipDiscountCodeProductValidation}
              buttonPalette={applyDiscountCodeButtonPalette}
            />
          </div>
        )}
        <Card hidden={singlePaymentProvider}>
          <>
            {availablePaymentMethods.map((paymentMethod, index) => {
              let paymentMethodElement;
              switch (paymentMethod) {
                case 'STRIPE':
                  paymentMethodElement = (
                    <div className="-ml-5 -mr-5 px-5 space-y-4">
                      {!singlePaymentProvider && (
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <Checkbox
                              checked={gateway === 'STRIPE'}
                              onClick={() => handleGatewayChange('STRIPE')}
                            />
                            <div className="ml-4">
                              <Typography size="paragraph">
                                <FormattedMessage defaultMessage="Debit / Credit Card" />
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                      {gateway === 'STRIPE' && (
                        <StripePaymentForm
                          totalPrice={totalPrice}
                          savedPaymentMethods={savedPaymentMethods}
                          isEditingStripeDetails={isEditingStripeDetails}
                          onEdit={onEdit}
                          onAlternativePaymentMethod={
                            onAlternativePaymentMethod
                          }
                          solePaymentProvider={singlePaymentProvider}
                        />
                      )}
                    </div>
                  );
                  break;
                case 'ZIP':
                  paymentMethodElement = (
                    <div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          {!singlePaymentProvider && (
                            <div className="mr-4">
                              <Checkbox
                                checked={gateway === 'ZIP'}
                                onClick={() => handleGatewayChange('ZIP')}
                              />
                            </div>
                          )}
                          <div className="flex items-center">
                            <ZipModalLauncher
                              merchantId={environment.zipMerchantId ?? ''}
                            >
                              <ZipLogo />
                              <div className="ml-2 underline cursor-pointer whitespace-nowrap">
                                <Typography size="small-text">
                                  <FormattedMessage
                                    defaultMessage="Learn more"
                                    description="Text to learn more about Zip as a payment provider"
                                  />
                                </Typography>
                              </div>
                            </ZipModalLauncher>
                          </div>
                        </div>
                        <div className="hidden sm:block">
                          <Typography size="paragraph">
                            <FormattedMessage
                              defaultMessage="From $10 a week"
                              description="Describes the minimum payment to use Zip"
                            />
                          </Typography>
                        </div>
                      </div>
                      {gateway === 'ZIP' && (
                        <div className="mt-4 space-y-4">
                          {zip?.valid ? (
                            <Typography size="paragraph">
                              <FormattedMessage defaultMessage="By confirming payment, you will be charged to your connected Zip account." />
                            </Typography>
                          ) : (
                            <>
                              <Typography size="paragraph">
                                <FormattedMessage defaultMessage="After clicking confirm payment, you will be directed to Zip's website to complete your purchase securely. When your Zip transaction is complete, you will be returned to this site." />
                              </Typography>
                              <Typography size="paragraph">
                                <FormattedMessage
                                  defaultMessage="<strong>Please note</strong>: all future payments on {brand} will be charged to your Zip account. You can change this setting in your profile anytime."
                                  values={{
                                    strong: (chunks) => (
                                      <strong>{chunks}</strong>
                                    ),
                                    brand: getReadableBrandName(config.brand),
                                  }}
                                />
                              </Typography>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  );
                  break;
              }

              return (
                <div key={paymentMethod}>
                  {paymentMethodElement}
                  {index !== availablePaymentMethods.length - 1 && (
                    <Divider variant="separator" />
                  )}
                </div>
              );
            })}
          </>
        </Card>
      </div>
    </div>
  );
};

PaymentForm.fragment = gql`
  fragment PaymentForm on User {
    id
    defaultPaymentGateway
    savedPaymentMethods {
      id
      gateway
      default
      createdAt
      ... on SavedCardPaymentMethod {
        expiry
        mask
        brand
      }
      ... on SavedZipPaymentMethod {
        valid
      }
      ... on SavedPayPalPaymentMethod {
        email
      }
    }
    zip {
      valid
    }
  }
`;

export { PaymentForm };
