import { FieldErrors, UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { TextInput } from '@eucalyptusvc/design-system';
import { useRequiredValidation } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import clsx from 'clsx';

type FullNameFormFields = {
  firstName: string;
  lastName: string;
};

type IntlFullNameInputProps = {
  className?: string;
  register: UseFormMethods<FullNameFormFields>['register'];
  errors: FieldErrors<FullNameFormFields>;
};

export const IntlFullNameInput = ({
  className,
  register,
  errors,
}: IntlFullNameInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const firstNameLabel = formatMessage({
    defaultMessage: 'First name',
    description: 'Label for first name input field',
  });

  const lastNameLabel = formatMessage({
    defaultMessage: 'Last name',
    description: 'Label for last name input field',
  });

  const firstNameValidation = useRequiredValidation(firstNameLabel);
  const lastNameValidation = useRequiredValidation(lastNameLabel);

  const config = getConfig();

  switch (config.locale) {
    case 'ja': // Eastern naming style - Family name before given name
      return (
        <div
          className={clsx('grid grid-cols-1 md:grid-cols-2 w-full', className)}
        >
          <div>
            <TextInput
              autoFocus
              label={lastNameLabel}
              ref={register(lastNameValidation)}
              name="lastName"
              errorMessage={errors?.lastName?.message}
            />
          </div>
          <div>
            <TextInput
              label={firstNameLabel}
              ref={register(firstNameValidation)}
              name="firstName"
              errorMessage={errors?.firstName?.message}
            />
          </div>
        </div>
      );

    default: // Western naming style - Given name before family name
      return (
        <div
          className={clsx('grid grid-cols-1 md:grid-cols-2 w-full', className)}
        >
          <div>
            <TextInput
              autoFocus
              label={firstNameLabel}
              ref={register(firstNameValidation)}
              name="firstName"
              errorMessage={errors?.firstName?.message}
            />
          </div>
          <div>
            <TextInput
              label={lastNameLabel}
              ref={register(lastNameValidation)}
              name="lastName"
              errorMessage={errors?.lastName?.message}
            />
          </div>
        </div>
      );
  }
};
