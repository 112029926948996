import { getConfig } from '@customer-frontend/config';
import { Environment } from '@customer-frontend/environment';
import { OnErrorFn } from '@formatjs/intl';

const isEnglishLocale = (locale: string): boolean => {
  return locale.startsWith('en');
};

export const getCorrectLocale = (env: Environment): string => {
  const { locale } = getConfig();

  if (env.isProd || isEnglishLocale(locale)) {
    return locale;
  }

  return navigator.language; // Used for local debugging
};

export const getLoadErrorMessage = (locale: string): string => {
  // NOTE: This is hardcoded on purpose, as it's a result of failing to load languages
  // German
  if (locale.toLowerCase().match('de')) {
    return 'Etwas ist schief gelaufen, bitte erfrischen.';
  }
  // Japanese
  if (locale.toLowerCase().match('ja')) {
    return '問題が発生しました。更新して再試行してください。';
  }

  return 'Something went wrong, please refresh and try again.';
};

export const getErrorHandler = (): {
  onError: OnErrorFn;
} => {
  const { locale } = getConfig();

  if (!isEnglishLocale(locale)) {
    return {
      onError: (err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          // eslint-disable-next-line no-console
          console.error('Missing translation', err.message);
          return;
        }
        throw err;
      },
    };
  }

  return {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError: () => {}, // Disable errors for brands all other brands, as they're not in other languages yet.
  };
};
