import { Plan } from '@customer-frontend/graphql-types';
import {
  MedicationPreference,
  MedicationPreferenceErxMedication,
  MedicationPreferenceProduct,
} from './types';

export const getMedicationPreferenceFormattedNames = (
  erxMedicines: MedicationPreferenceErxMedication[] | null,
): string => {
  if (!erxMedicines) {
    return '';
  }
  return erxMedicines
    .map((erxMedicine) => `${erxMedicine.genericName}`)
    .join(', ');
};

export const getMedicationTradeNames = (
  erxMedicines: MedicationPreferenceErxMedication[],
): string => {
  return erxMedicines.map((erxMedicine) => erxMedicine.productName).join(', ');
};

export const getMedicationPreferenceProduct = (
  medicationPreference: MedicationPreference,
): MedicationPreferenceProduct | null => {
  return (
    medicationPreference?.preferredProduct ||
    medicationPreference?.defaultProduct ||
    null
  );
};

export const getMedicationPreferenceRecurringPrice = (
  context: MedicationPreference,
): number | null => {
  const preferredProduct = getMedicationPreferenceProduct(context);
  if (!preferredProduct) {
    return null;
  }
  return (
    preferredProduct.variants.find((variant) => variant.isRefill)?.price || null
  );
};

export const getMedicationPreferencePlan = (
  context: MedicationPreference,
): Partial<Plan> | null => {
  const preferredProduct = getMedicationPreferenceProduct(context);
  if (!preferredProduct) {
    return null;
  }
  return preferredProduct.plan || null;
};

export const getMedicationPreferenceInitialSku = (
  context: MedicationPreference,
): string | null => {
  const preferredProduct = getMedicationPreferenceProduct(context);
  if (!preferredProduct) {
    return null;
  }
  return (
    preferredProduct.variants.find((variant) => !variant.isRefill)?.inventory
      ?.sku ?? null
  );
};
