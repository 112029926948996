import React from 'react';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { NavTabList, NavTab } from '@eucalyptusvc/design-system';

interface OfferingLayoutProps {
  routes: {
    offering: {
      plan: string;
      notes: string;
      faq: string;
    };
    profile: string;
  };
  children: React.ReactElement;
}

export function OfferingLayout({
  routes,
  children,
}: OfferingLayoutProps): React.ReactElement {
  const history = useHistory();
  useBackButtonBehaviour(() => history.push(routes.profile));

  return (
    <section className="max-w-screen-md px-4 pt-10 md:pt-14 pb-10 md:pb-24 mx-auto flex flex-col gap-8">
      <NavTabList>
        <NavTab to={routes.offering.plan}>
          <FormattedMessage
            defaultMessage="Plan"
            description="Link to page with the patient's offering plan"
          />
        </NavTab>

        <NavTab to={routes.offering.notes}>
          <FormattedMessage
            defaultMessage="Clinical notes"
            description="Link to page with clinical notes on patient's offering"
          />
        </NavTab>

        <NavTab to={routes.offering.faq}>
          <FormattedMessage
            defaultMessage="FAQs"
            description="Link to page with frequently asked questions about the treatment"
          />
        </NavTab>
      </NavTabList>

      {children}
    </section>
  );
}
