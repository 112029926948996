import React from 'react';

export interface Progress {
  lastStepIndex: number;
  currentStepIndex: number;
}

export const SetProgressContext = React.createContext<
  React.Dispatch<React.SetStateAction<Progress | null>>
>(() => {
  /* do nothing */
});

export function useProgress(
  currentStepIndex: number,
  lastStepIndex: number,
): void {
  const setProgress = React.useContext(SetProgressContext);
  React.useEffect(() => {
    // When these change, report back to NavBar
    setProgress({ lastStepIndex, currentStepIndex });

    // When the component that uses this hook unmounts,
    // we want to clear the progress indicator.
    return (): void => {
      setProgress(null);
    };
  }, [setProgress, currentStepIndex, lastStepIndex]);
}
