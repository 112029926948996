import React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { TextInput } from '@eucalyptusvc/design-system';
import { QuestionProps } from './types';
import {
  combineRules,
  useValidMaskedDate,
  useMaxMaskedDateExclusive,
  useMinMaskedDateInclusive,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { QuizQuestion } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { useIntl } from 'react-intl';

export function DateQuestionInput({
  question,
  isLoading,
}: QuestionProps): React.ReactElement {
  const { brand, dateConfig } = getConfig();
  const { register, errors } = useFormContext();
  const { required } = question;

  const validationRules = useValidationRules(question);

  return (
    <TextInput
      autoFocus
      autoComplete="off"
      name={question.id}
      disabled={isLoading}
      ref={register(validationRules)}
      palette={brand === 'pilot' ? 'alternate' : 'default'}
      type={dateConfig.type}
      required={required ?? false}
      errorMessage={errors[question.id]?.message}
      placeholder={dateConfig.placeholder}
    />
  );
}

const useValidationRules = (question: QuizQuestion): RegisterOptions => {
  const { required, shortcode } = question;
  const { formatMessage } = useIntl();

  const MAX = new Date();
  MAX.setFullYear(new Date().getFullYear() - 14);

  const maxDateRule = useMaxMaskedDateExclusive(MAX);
  const minDateRule = useMinMaskedDateInclusive(new Date('1900-01-01'));
  const requiredRule = useRequiredValidation(
    formatMessage({ defaultMessage: 'Answer' }),
  );

  const rules = [useValidMaskedDate()];

  if (required) {
    rules.push(requiredRule);
  }

  if (shortcode === 'dob') {
    rules.push(maxDateRule, minDateRule);
  }

  return combineRules(...rules);
};
