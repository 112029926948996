import { type ReactElement } from 'react';
import { getConfig } from '@customer-frontend/config';
import { Button, Modal, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

type SendMessageConfirmModalProps = {
  onYesClick: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export function SendMessageConfirmModal({
  onYesClick,
  ...modalProps
}: SendMessageConfirmModalProps): ReactElement {
  const config = getConfig();

  return (
    <Modal
      {...modalProps}
      title={
        <Typography isBold size="md">
          <FormattedMessage defaultMessage="Heads up!" />
        </Typography>
      }
    >
      <div className="space-y-2">
        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="By asking a question, your consult will be reopened and you won't be able to place an order until your {isGb, select, true {prescriber} other {practitioner}} responds to you."
            values={{ isGb: config.countryCode === 'GB' }}
          />
        </Typography>

        {config.helpCentreUrl && (
          <Typography size="paragraph">
            <FormattedMessage
              defaultMessage="If your question is in relation to how the program works, you may find the answer in our <link>Help Centre</link>."
              values={{
                link: (text) => (
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="text-link"
                    href={config.helpCentreUrl}
                  >
                    {text}
                  </a>
                ),
              }}
            />
          </Typography>
        )}

        <Typography size="paragraph">
          <FormattedMessage defaultMessage="Do you still want to ask a question?" />
        </Typography>
      </div>

      <div className="mt-8 gap-4 flex flex-col sm:justify-end sm:flex-row">
        <Button level="secondary" onClick={onYesClick}>
          <FormattedMessage defaultMessage="Yes" />
        </Button>

        <Button level="primary" onClick={modalProps.onClose}>
          <FormattedMessage defaultMessage="No" />
        </Button>
      </div>
    </Modal>
  );
}
