export const CustomCheckbox = ({
  checked,
  hidden,
}: {
  checked: boolean;
  hidden?: boolean;
}): React.ReactElement | null => {
  if (hidden) {
    return null;
  }
  return (
    <div className="inline-flex w-5 h-5 items-center justify-center flex-none cursor-pointer rounded-full border border-primary-500">
      {checked && <div className="h-3 w-3 rounded-full bg-primary-500" />}
    </div>
  );
};
