import React from 'react';
import { useAuth } from '@customer-frontend/auth';
import { useLoginMutation } from './useLoginMutation';

type Credentials = {
  email: string;
  password: string;
};

export const useLogin = (): {
  login: (credentials: Credentials) => Promise<void>;
  loading: boolean;
} => {
  const { login: setLoginState } = useAuth();

  const [loginMutation, { loading }] = useLoginMutation();

  const login = React.useCallback(
    async ({ email, password }: Credentials): Promise<void> => {
      const { data } = await loginMutation({
        variables: {
          email,
          password,
        },
      });
      if (data?.auth.token && data.auth.user) {
        setLoginState(data.auth.token, data.auth.user);
      }
    },
    [loginMutation, setLoginState],
  );
  return {
    login,
    loading,
  };
};
