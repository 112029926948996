import { Controller } from 'react-hook-form';
import { TextArea } from '@eucalyptusvc/design-system';

import {
  ImageUploadInput,
  parseImageUploadString,
} from '../image-upload-input';

import type { QuestionAnswerInputProps } from './types';

export const QuestionAnswerInput = ({
  id,
  name,
  control,
  type,
  problemType,
  question,
  errorMessage,
  imageInstructions,
  chatTheme,
  doctor,
  consultationId,
  submitPending,
  submit,
  createObservationInput,
  getPlaceholderImgUrl,
}: QuestionAnswerInputProps): JSX.Element => {
  const reviewInstructions =
    imageInstructions && problemType
      ? imageInstructions[problemType]
      : undefined;
  if (type === 'IMAGE') {
    return (
      <div className="self-end w-4/5 xl:w-3/4 my-4">
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ value, onChange }) => {
            const placeholderUrl =
              getPlaceholderImgUrl !== undefined
                ? getPlaceholderImgUrl({
                    problemType,
                    question,
                  })
                : undefined;

            return (
              <ImageUploadInput
                palette="alternate"
                orientation="vertical"
                placeholderUrl={placeholderUrl}
                reviewInstructions={reviewInstructions}
                errorMessage={errorMessage}
                value={parseImageUploadString(value)}
                onChange={onChange}
                hasSubmit
                isLoading={submitPending}
              />
            );
          }}
        />
      </div>
    );
  }

  if (type === 'OBSERVATION' && createObservationInput) {
    return (
      <div className="self-end w-4/5 xl:w-3/4">
        {createObservationInput(
          id,
          control,
          doctor,
          consultationId,
          submitPending,
          submit,
          problemType,
          errorMessage,
        )}
      </div>
    );
  }

  return (
    <div className="mb-2">
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={(props) => {
          return (
            <TextArea
              {...props}
              label="Reply here"
              placeholder="Your answer"
              palette={chatTheme.textInputPalette}
              rows={4}
              errorMessage={errorMessage}
            />
          );
        }}
      />
    </div>
  );
};
