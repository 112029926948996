import {
  Button,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { FC, VFC } from 'react';
import { useWeightDashboardTheme } from '../provider';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { formatCentsToCurrency } from '@customer-frontend/order';

type DashboardCouponProps = {
  bottomRightImage: FC;
  couponCode: string;
  learnMoreLink: string;
};

export const DashboardCoupon: VFC<DashboardCouponProps> = ({
  bottomRightImage: BottomRightImage,
  couponCode,
  learnMoreLink,
}) => {
  const notify = useNotification();
  const { brand } = useWeightDashboardTheme();
  const couponTheme = brand?.coupon;

  const copyCode = (): void => {
    navigator.clipboard.writeText(couponCode);
    notify.success({ message: 'Referral code copied' });
  };

  const cardClassName = clsx(
    'relative border rounded border-primary-300 bg-primary-500 p-6',
    couponTheme?.cardBgColor,
  );

  return (
    <div className="col-span-2 md:col-span-1 w-full">
      <div className={cardClassName}>
        <div className="absolute bottom-0 right-0 overflow-hidden">
          <div className="-mr-2">
            <BottomRightImage />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Typography size="md" color={couponTheme?.headingColor} isBold>
              <FormattedMessage
                defaultMessage="Refer a friend and receive a {amount} Prezzee voucher"
                values={{
                  amount: formatCentsToCurrency(10000),
                }}
              />
            </Typography>
            <Typography
              size="medium-paragraph"
              color={couponTheme?.contentColor}
            >
              <FormattedMessage defaultMessage="Give a friend this code to take 20% off their first month on the Weight Reset Program. You’ll receive a voucher when they use it to sign up." />
            </Typography>
          </div>
          <div className="flex items-center gap-3">
            <TextInput value={couponCode} />
            <Button onClick={copyCode}>
              <FormattedMessage
                defaultMessage="Copy code"
                description="Button that copies code to clipboard"
              />
            </Button>
          </div>
          <div>
            <a
              href={learnMoreLink}
              className="inline-block underline font-medium text-white decoration-white hover:opacity-75 opacity-100"
            >
              <FormattedMessage defaultMessage="Learn more" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
