import { useEffect, useState, ReactFragment } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import {
  DiscountCodeForm,
  formatCentsToCurrency,
  getStripePaymentMethod,
  PaymentPayload,
  StripePaymentForm,
  useConsultationPaymentHandler,
  usePersistedDiscountFromURL,
  useShippingForm,
} from '@customer-frontend/order';
import {
  Consultation,
  User,
  DiscountCodeFormFragment,
} from '@customer-frontend/graphql-types';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import { TermsAndConditions } from '@customer-frontend/post-quiz';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { mustBeTrue } from '@customer-frontend/utils';
import {
  useEnablePaymentIntent,
  useProfile,
} from '@customer-frontend/services';
import { useEventService } from '@customer-frontend/events';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { FormattedMessage, useIntl } from 'react-intl';

export const ConsultationPaymentForm = ({
  consultation,
  user,
  setDiscount,
  discount,
  originalPrice,
  totalPrice,
  defaultDiscountCode,
  onPaymentSuccess,
  termsAndConditionsPath,
  privacyPolicyPath,
  paymentIntentReturnUrl,
  shouldShowPaymentFormLabel = true,
}: {
  consultation: Consultation;
  user: User;
  setDiscount: (value?: DiscountCodeFormFragment) => void;
  discount?: DiscountCodeFormFragment;
  originalPrice: number;
  totalPrice: number;
  defaultDiscountCode?: string | null;
  onPaymentSuccess: () => void;
  termsAndConditionsPath: string;
  privacyPolicyPath: string;
  shouldShowPaymentFormLabel?: boolean;
  paymentIntentReturnUrl?: string;
}): React.ReactElement => {
  const config = getConfig();
  const environment = useEnvironment();
  const notify = useNotification();
  const stripePaymentMethod = getStripePaymentMethod(user?.savedPaymentMethods);
  const [editingCardDetails, setEditingCardDetails] = useState(
    !stripePaymentMethod,
  );
  const event = useEventService();
  const shouldUsePaymentIntents = useEnablePaymentIntent();

  const { data } = useProfile();
  const userInfo = data?.profile;
  const { clearCode } = usePersistedDiscountFromURL();
  const { handlePayment, loading: paymentLoading } =
    useConsultationPaymentHandler({
      user,
      gateway: 'STRIPE',
      consultation,
      detailsChanged: editingCardDetails,
      usePaymentIntents: shouldUsePaymentIntents,
      paymentIntentReturnUrl,
      onPaymentSuccess: () => {
        clearCode();
        onPaymentSuccess();
      },
    });

  const featureFlagClient = useFeatureFlagClient();
  const showDiscountForm = featureFlagClient.getBoolean('CONSULT_DISCOUNTS');
  const marketingConsentUpdatedUI = featureFlagClient.getBoolean(
    'FF_MARKETING_CONSENT_UI_UPDATES',
  );
  const showCardForm = totalPrice > 0;
  const isShippingAddressValidationRequired =
    consultation.type === 'WEIGHT_LOSS';

  const {
    setValue,
    handleSubmit,
    validate: validateShippingForm,
    register,
    errors,
  } = useShippingForm<{
    consentEmail?: boolean;
    consentLegal?: boolean;
  }>({
    enableAddressValidation: isShippingAddressValidationRequired,
    phone: user.phone,
    lastName: user.legalLastName,
    firstName: user.legalFirstName,
    shippingAddress: user.address,
    residentialAddress: user.residentialAddress,
  });

  useEffect(() => {
    setValue('residentialAddress', {
      ...user.residentialAddress,
    });
    if (user.address?.line1) {
      setValue('shippingAddress', {
        ...user.address,
      });
    }
  }, [setValue, user]);

  const shouldBlockPayment = paymentLoading;

  const submit = (payload?: Partial<PaymentPayload>): (() => Promise<void>) =>
    handleSubmit(
      async ({ firstName, lastName, phone, shippingAddress, consentEmail }) => {
        const paymentPayload = {
          ...payload,
          firstName,
          lastName,
          phone,
          value: totalPrice,
          couponCode: discount?.code,
          ...(shippingAddress
            ? {
                address: {
                  ...shippingAddress,
                  country: config.country,
                },
              }
            : {}),
        };

        const analyticsData =
          config.consentRequired && typeof consentEmail === 'boolean'
            ? { consentEmail }
            : {};

        if (userInfo?.id && consentEmail) {
          event.identify(userInfo, 'EMAIL');
        }

        try {
          handlePayment(paymentPayload, analyticsData);
        } catch (error) {
          const descriptions = getErrorMessageDescriptorsFromError(error);
          descriptions.forEach((descriptor) =>
            notify.error({ message: formatMessage(descriptor) }),
          );
        }
      },
    );

  const handleAlternativePaymentMethod = async (
    payload: Partial<PaymentPayload>,
  ): Promise<void> => {
    const valid = await validateShippingForm();
    if (!valid) {
      payload.stripeEvent?.complete('fail');
      return;
    }

    return submit(payload)();
  };

  const { formatMessage } = useIntl();

  return (
    <form onSubmit={submit()} className="space-y-12">
      <div className="space-y-8">
        <Card>
          <div className="space-y-4">
            <Typography size="md" isBold>
              <FormattedMessage defaultMessage="Payment details" />
            </Typography>
            <Divider variant="separator" />
            {showDiscountForm && (
              <div className="mb-4 md:max-w-xs">
                <DiscountCodeForm
                  stage="CONSULTATION_PAID"
                  code={discount}
                  subtotal={originalPrice}
                  defaultCode={defaultDiscountCode}
                  onChange={(discount): void => setDiscount(discount)}
                  products={[]}
                  buttonPalette="default"
                />
              </div>
            )}
            {showCardForm && (
              <>
                <StripePaymentForm
                  totalPrice={totalPrice}
                  savedPaymentMethods={user?.savedPaymentMethods}
                  isEditingStripeDetails={editingCardDetails}
                  onEdit={() => setEditingCardDetails(true)}
                  onAlternativePaymentMethod={(event): Promise<void> =>
                    handleAlternativePaymentMethod({ stripeEvent: event })
                  }
                  shouldShowFormLabel={shouldShowPaymentFormLabel}
                />
              </>
            )}
          </div>
        </Card>
        {config.consentRequired && !marketingConsentUpdatedUI ? (
          <div className="space-y-4">
            <Checkbox
              isChecked={false}
              ref={register}
              name="consentEmail"
              label={formatMessage({
                defaultMessage:
                  'I would like to receive offers and news via email about the products and services of Juniper and related brands',
                description:
                  'text on the payment form to opt in for marketing content',
              })}
            />
            <Checkbox
              isChecked={false}
              ref={register({
                ...mustBeTrue(
                  formatMessage({
                    defaultMessage:
                      'To continue, you must agree to the terms of service',
                    description:
                      'error message on payment form if the user does not accept the terms of service',
                  }),
                ),
              })}
              errorMessage={errors?.consentLegal?.message}
              name="consentLegal"
              label={
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="I have read and understand the <a>Terms of service</a>, <b>Terms of Sale</b> and <c>Privacy Notice</c>"
                    description="text on the payment to form with links to the terms of service, terms of sale and privacy notice"
                    values={{
                      a: (chunks: ReactFragment) => (
                        <a
                          className="underline"
                          href={`${environment.landingPageUrl}${config.urlPaths.termsOfService}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                      b: (chunks: ReactFragment) => (
                        <a
                          className="underline"
                          href={`${environment.landingPageUrl}${config.urlPaths.termsOfSale}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                      c: (chunks: ReactFragment) => (
                        <a
                          className="underline"
                          href={`${environment.landingPageUrl}${config.urlPaths.privacyNotice}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </Typography>
              }
            />
          </div>
        ) : (
          <div className="text-center">
            <TermsAndConditions
              termsAndConditionsPath={termsAndConditionsPath}
              privacyPolicyPath={privacyPolicyPath}
            />
          </div>
        )}
        <Button
          isFullWidth
          isSubmit
          palette={getPrimaryButtonPalette(config.brand)}
          isLoading={paymentLoading}
          isDisabled={shouldBlockPayment}
        >
          <FormattedMessage
            defaultMessage="Confirm {totalPrice} payment"
            values={{ totalPrice: formatCentsToCurrency(totalPrice) }}
          />
        </Button>
      </div>
    </form>
  );
};
