import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { useWeightProgramName } from '@customer-frontend/utils';
import { Typography } from '@eucalyptusvc/design-system';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

interface SaxendaDelayCardProps {
  isPrePurchase?: boolean;
}

export const SaxendaDelayCard = ({
  isPrePurchase,
}: SaxendaDelayCardProps): React.ReactElement | null => {
  const client = useFeatureFlagClient();

  const programName = useWeightProgramName();
  const isDelayCardEnabled = client.getBoolean('SAXENDA_SHIPPING_DELAYS');

  if (!isDelayCardEnabled) {
    return null;
  }

  return (
    <div className="bg-status-warning-100 border border-status-warning-500 rounded-md p-4 space-y-2 text-black">
      <div className="flex items-center space-x-1">
        <FaExclamationTriangle />
        <Typography size="xs" isBold>
          <FormattedMessage defaultMessage="Saxenda order delays" />
        </Typography>
      </div>
      {isPrePurchase ? (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Delivery of your first order may be delayed by up to 2 weeks. The national distributor of Saxenda is experiencing a temporary shortage due to high order volumes. <br /><br />By completing your order now, you will secure a place in the {programName} Program. Once stock becomes available, you’ll be among the first to receive your welcome kit."
            description=""
            values={{
              programName,
            }}
          />
        </Typography>
      ) : (
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Dispatch of your first order may be delayed by up to 2 weeks. We’ll keep you updated on your delivery status via email. We thank you for your patience." />
        </Typography>
      )}
    </div>
  );
};
