import { PrescriberType } from '@customer-frontend/doctor';
import { Config } from '../type';
import { defaultConfig } from './default';
import { de } from 'date-fns/locale';

export const JuniperDeConfig: Config = {
  ...defaultConfig,
  analyticsBrand: 'juniper',
  brand: 'juniper-de',
  loginUrl: '/login',
  title: 'Juniper',
  consentRequired: true,
  prescriberType: PrescriberType.DOCTOR,
  stripeApi: 'PAYMENT_INTENT',
  country: 'Deutschland',
  countryCode: 'DE',
  locale: 'de-DE',
  currency: 'EUR',
  emergencyNumber: '112',
  supportEmail: 'hallo@myjuniper.com',
  rejectionContactLink:
    'https://germany-juniper.zendesk.com/hc/de/requests/new?ticket_form_id=25855789787545',
  helpCentreUrl: 'https://germany-juniper.zendesk.com/hc/de',
  zendeskRequestUrl: 'https://germany-juniper.zendesk.com/hc/de/requests/new',
  contactUsUrl: 'https://www.myjuniper.com/de/kontakt',
  clinicianMessagingSupported: false,
  scalesProvided: false,
  moneyBackGuaranteeEnabled: false, // Disabled at launch
  healthCoachingEnabled: false,
  weightFacebookCommunityUrl: 'https://www.facebook.com/groups/722446976117855',
  weekendSupportEnabled: false,
  addressConfig: {
    numberBeforeStreet: false,
    showStateInput: false,
    validateShippingAddressWithinRange: false,
  },
  dateConfig: {
    placeholder: 'DD.MM.YYYY',
    format: 'dd.MM.yyyy',
    type: 'masked-date-period',
    datefnsLocale: de,
  },
  urlPaths: {
    privacyNotice: '/datenschutzerklarung',
    privacyPolicy: '/datenschutzerklarung',
    termsAndConditions: '/allgemeine-geschaftsbedingungen',
    termsOfSale: '/allgemeine-geschaftsbedingungen',
    termsOfService: '/allgemeine-geschaftsbedingungen',
    cookieNotice: '/cookie-hinweis',
    about: '/uber',
    contact: '/kontakt',
    waitList: '/wait-list',
    faq: '/faqs',
  },
  selfServiceCancelEnabled: false,
  localisedAssets: {
    juniperArchway: '/assets/juniper-archway-2-de.svg',
    juniperProductDesktopImage: '/assets/juniper-product-desktop-de.png',
    juniperProductMobileImage: '/assets/juniper-product-desktop-de.png',
    appWeightTracking: '/assets/app-weight-tracking-de.png',
    medicationBox: '/assets/medication-box-de.png',
    phoneCall: '/assets/phone-call.svg',
    program: {
      boxPen: '/assets/program/box-pen.webp',
      boxPen2x: '/assets/program/box-pen@2x.webp',
      boxPen3x: '/assets/program/box-pen@3x.webp',
      chat: '/assets/program/chat.webp',
      chat2x: '/assets/program/chat@2x.webp',
      chat3x: '/assets/program/chat@3x.webp',
      team: '/assets/program/team.webp',
      team2x: '/assets/program/team@2x.webp',
      team3x: '/assets/program/team@3x.webp',
      food: '/assets/program/food.webp',
      food2x: '/assets/program/food@2x.webp',
      food3x: '/assets/program/food@3x.webp',
      community: '/assets/program/community.webp',
      community2x: '/assets/program/community@2x.webp',
      community3x: '/assets/program/community@3x.webp',
      members: '/assets/program/members.webp',
      members2x: '/assets/program/members@2x.webp',
      members3x: '/assets/program/members@3x.webp',
      statisticOne: '/assets/program/statistic-one.svg',
      statisticTwo: '/assets/program/statistic-two.svg',
      statisticThree: '/assets/program/statistic-three.svg',
      staceyBeforeAfter: '/assets/program/staceybeforeafter.webp',
      staceyBeforeAfter2x: '/assets/program/staceybeforeafter@2x.webp',
      staceyBeforeAfter3x: '/assets/program/staceybeforeafter@3x.webp',
      rebekahBeforeAfter: '/assets/program/rebekahbeforeafter.webp',
      rebekahBeforeAfter2x: '/assets/program/rebekahbeforeafter@2x.webp',
      rebekahBeforeAfter3x: '/assets/program/rebekahbeforeafter@3x.webp',
    },
    consultation: {
      practitioners: {
        one: '/assets/consultation/care-team-stella.webp',
        two: '/assets/consultation/care-team-georgia.webp',
        three: '/assets/consultation/care-team-drvickers.webp',
        four: '/assets/consultation/care-team-lydia.webp',
        five: '/assets/consultation/care-team-ben.webp',
        six: '/assets/consultation/care-team-kim.webp',
      },
      initialScreening: '/assets/consultation/initial-screening-au.webp',
      paymentForTreatment: '/assets/consultation/payment-for-treatment-au.webp',
      medicalScreening: '/assets/consultation/medical-screening-au.webp',
      treatmentDelivery: '/assets/consultation/treatment-delivery-au.webp',
    },
  },
  externalSupportNumber: '116 117',
  externalSupportLabel: 'Ärztlicher Bereitschaftsdienst',
  batchedOzempicEnabled: true,
  twoDayDelivery: true,
};
