import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, useControllableState } from '@chakra-ui/react';

import { Modal } from '../modal';
import { Webcam } from './webcam';
import { ImageUploadProps } from './types';
import { ReviewImage } from './review-image';
import { SelectImage } from './select-image';
import { convertPngToJpeg, isPng } from './utils';
import { useResponsiveMultiStyleConfig } from '../../hooks';

export const ImageUpload = ({
  title,
  isLoading,
  orientation = 'vertical',
  placeholderUrl,
  reviewInstructions,
  hasError,
  hasSubmit,
  description,
  defaultValue,
  value,
  onChange,
  onUploadError,
}: ImageUploadProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [internalValue, setInternalValue] = useControllableState({
    defaultValue,
    value,
    onChange: (value) => onChange?.(JSON.stringify(value)),
  });

  const [showWebcam, setShowWebcam] = useState(false);

  const styles = useResponsiveMultiStyleConfig('ImageUpload', {
    orientation,
    hasError,
  });

  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = (): void => {
    fileInput?.current?.click();
  };

  const handleTakePhoto = (): void => {
    setShowWebcam(true);
  };

  const handleRetake = (): void => {
    // We need to reset the fileInput ref, so that selecting the same image works (if needed)
    if (fileInput?.current) {
      fileInput.current.value = '';
    }
    setInternalValue(null);
  };

  const hideWebcam = (): void => {
    setShowWebcam(false);
  };

  const handleSaveScreenshot = (url: string): void => {
    setInternalValue({
      fileName: 'screenshot.jpeg',
      fileBufferString: url,
    });

    setShowWebcam(false);
  };

  const fileTypeAndSizeValid = (file: File): boolean => {
    const incorrectFileType = !['image/jpeg', 'image/png'].includes(file.type);
    const fileSizeExceeded = file.size > 20000000;

    if (incorrectFileType || fileSizeExceeded) {
      const errMessage = formatMessage(
        {
          defaultMessage:
            'Image must be {incorrectFileType, select, true {a JPEG or PNG} other {less than 20 MB}}.',
          description: 'Error message for uploading an image',
        },
        { incorrectFileType },
      );
      onUploadError?.(errMessage);
      return false;
    }

    return true;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const fileList: FileList | null = e?.target?.files;
    const file = fileList?.[0];
    if (!file) {
      return;
    }

    if (!fileTypeAndSizeValid(file)) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event): void => {
      const imgBlob = event?.target?.result;
      if (typeof imgBlob === 'string') {
        if (isPng(file.name)) {
          convertPngToJpeg(imgBlob, (convertedImgBlob: string) => {
            setInternalValue({
              fileName: file.name.replace('png', 'jpeg'),
              fileBufferString: convertedImgBlob,
            });
          });
        } else {
          setInternalValue({
            fileName: file.name,
            fileBufferString: imgBlob,
          });
        }
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <Box sx={styles.container}>
      {internalValue ? (
        <ReviewImage
          styles={styles}
          isLoading={isLoading}
          reviewInstructions={reviewInstructions}
          imgUrl={internalValue.fileBufferString}
          hasSubmit={hasSubmit}
          onRetake={handleRetake}
        />
      ) : (
        <SelectImage
          title={title}
          styles={styles}
          description={description}
          placeholderUrl={placeholderUrl}
          triggerInput={handleClick}
          triggerTakePhoto={handleTakePhoto}
          reviewInstructions={reviewInstructions}
        />
      )}
      {showWebcam && (
        <Modal isOpen onClose={hideWebcam}>
          <Webcam
            styles={styles.webcam}
            onSaveScreenshot={handleSaveScreenshot}
          />
        </Modal>
      )}
      <input
        ref={fileInput}
        style={{ display: 'none' }}
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleFileChange}
      />
    </Box>
  );
};
