import { Typography, TypographySize } from '@eucalyptusvc/design-system';
import { VFC } from 'react';
import clsx from 'clsx';
import { useFormatLocalizedTimeToReadFn } from './utils';

type TimeToReadProps = {
  icon?: JSX.Element;
  isBold?: boolean;
  colorClassName?: string;
  textColor?: string;
  minutes: number;
  textSize?: TypographySize;
  formatStringFn?: (minutes: number) => string;
};

export const TimeToRead: VFC<TimeToReadProps> = ({
  icon,
  isBold,
  colorClassName,
  textColor = 'inherit',
  minutes,
  textSize = 'small-text',
  formatStringFn,
}) => {
  const containerClass = clsx(
    'flex flex-row items-center space-x-2',
    colorClassName,
  );

  const formatLocalizedTimeToRead = useFormatLocalizedTimeToReadFn();

  return (
    <div className={containerClass}>
      {icon}
      <Typography size={textSize} isBold={isBold} color={textColor}>
        {formatStringFn?.(minutes) ?? formatLocalizedTimeToRead(minutes)}
      </Typography>
    </div>
  );
};
