import { gql } from '@apollo/client';

export const subscriptionPlanV2Fragment = gql`
  fragment OtcSubscriptionPlanV2Info on SubscriptionPlanV2 {
    id
    name
    description
    photo {
      id
      url
    }
    interval
    intervalCount
    price
  }
`;
