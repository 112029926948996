import { getConfig } from '@customer-frontend/config';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

const isoDateFormat = 'yyyy-MM-dd';

export const formatLocaleDateToISO = (answer: string): string => {
  const { dateConfig } = getConfig();

  const parsedDate = parse(answer, dateConfig.format, new Date());

  return format(parsedDate, isoDateFormat);
};

export const formatISODateToLocale = (answer: string): string => {
  const { dateConfig } = getConfig();

  const parsedDate = parse(answer, isoDateFormat, new Date());

  return format(parsedDate, dateConfig.format);
};
