import { gql, useSuspenseQuery } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import {
  SwitchOfferingsReceiptPageQuery,
  SwitchOfferingsReceiptPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { Button, Divider, Typography } from '@eucalyptusvc/design-system';
import { formatCurrency, formatDate } from '@eucalyptusvc/lib-localization';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

export function OfferingSwitchReceiptPage(props: { profileRoute: string }) {
  const history = useHistory();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const { data } = useSuspenseQuery<
    SwitchOfferingsReceiptPageQuery,
    SwitchOfferingsReceiptPageQueryVariables
  >(
    gql`
      query SwitchOfferingsReceiptPage($purchaseId: ID!) {
        purchase(id: $purchaseId) {
          id
          offering {
            id
            advertisedName
          }
          supersededBy {
            id
            offering {
              id
              advertisedName
            }
            nextOrder {
              id
              totalAmount
              date
            }
          }
        }
      }
    `,
    {
      variables: { purchaseId },
    },
  );

  let content = null;
  if (
    !data.purchase?.offering ||
    !data?.purchase?.supersededBy?.offering ||
    !data.purchase.supersededBy.nextOrder
  ) {
    content = (
      <Typography textAlign="center" size="medium-paragraph">
        <FormattedMessage defaultMessage="There has been an error loading your receipt." />
      </Typography>
    );
  } else {
    content = (
      <>
        <Typography size="lg" isBold>
          <FormattedMessage defaultMessage="Success! Your plan has been changed" />
        </Typography>
        <div className="flex flex-col gap-2">
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Your plan has changed from <b>{oldPlanName}</b> to <b>{newPlanName}</b>."
              values={{
                oldPlanName: data.purchase.offering.advertisedName,
                newPlanName: data.purchase.supersededBy.offering.advertisedName,
                b: (v) => <strong>{v}</strong>,
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="For your next order you will be charged {nextOrderCost} with your upcoming order being charged on {upcomingOrderDate}."
              values={{
                nextOrderCost: formatCurrency(
                  mapBrandToAdaptersBrand(getConfig().brand),
                  data.purchase.supersededBy.nextOrder.totalAmount,
                  { includeSymbol: true, includeDecimals: true },
                ),
                upcomingOrderDate: formatDate(
                  mapBrandToAdaptersBrand(getConfig().brand),
                  data.purchase.supersededBy.nextOrder.date,
                  {},
                ),
              }}
            />
          </Typography>
        </div>
      </>
    );
  }

  return (
    <section className="max-w-screen-md px-4 pt-10 md:pt-14 pb-10 md:pb-24 mx-auto flex flex-col gap-4">
      {content}
      <Divider variant="separator" />
      <Button onClick={() => history.push(props.profileRoute)}>
        <FormattedMessage defaultMessage="Go to profile" />
      </Button>
    </section>
  );
}
