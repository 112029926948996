import { gql } from '@apollo/client';
import { experimentPaymentPlanFragment } from '../fragments/experimentPaymentPlan';

export const cancelExperimentPaymentPlan = gql`
  mutation CancelExperimentPaymentPlan($treatmentId: String!) {
    cancelExperimentPaymentPlan(treatmentId: $treatmentId) {
      id
      experimentPaymentPlan {
        ...ExperimentPaymentPlan
      }
    }
  }
  ${experimentPaymentPlanFragment}
`;
