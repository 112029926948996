import React from 'react';
import { gql } from '@apollo/client';
import { useStartQuizApplicationWithQuizCodeMutation } from '@customer-frontend/graphql-types';

export type QuizApplicationWithQuizCodeInfo = {
  id: string;
};

export const startQuizApplicationWithQuizCode = gql`
  mutation StartQuizApplicationWithQuizCode($quizCode: String!) {
    quizApplicationInfo: startQuizApplicationWithQuizCode(quizCode: $quizCode) {
      id
    }
  }
`;

export const useStartQuizApplicationWithQuizCode =
  (): typeof startQuizApplicationWithQuizCode => {
    const [startQuizAppWithQuizCode] =
      useStartQuizApplicationWithQuizCodeMutation();

    const startQuizApplicationWithQuizCode = React.useCallback(
      async (quizCode: string): Promise<QuizApplicationWithQuizCodeInfo> => {
        const { data } = await startQuizAppWithQuizCode({
          variables: {
            quizCode,
          },
        });

        if (!data?.quizApplicationInfo) {
          throw new Error(
            `Failed to start quiz application with quiz code ${quizCode}`,
          );
        }

        return data.quizApplicationInfo;
      },
      [startQuizAppWithQuizCode],
    );

    return startQuizApplicationWithQuizCode;
  };
