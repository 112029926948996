import React, { useEffect, useRef, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { gql } from '@apollo/client';
import { useTheme } from '@chakra-ui/react';
import {
  useCreateOrRetrieveStripeSetupIntentMutation,
  useStripePublicKeyLazyQuery,
} from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { BrandTheme, LoadingSpinner } from '@eucalyptusvc/design-system';
import { useEnvironment } from '@customer-frontend/environment';
import { type Logger } from '@customer-frontend/logger';

/** Used for type generation */
export const createOrRetrieveStripeSetupIntentDocument = gql`
  mutation CreateOrRetrieveStripeSetupIntent {
    createOrRetrieveStripeSetupIntent {
      id
      clientSecret
    }
  }
`;
export const getStripePublicKeyDocument = gql`
  query StripePublicKey {
    stripeAccount {
      publicKey
    }
  }
`;

export const StripeProvider = ({
  api,
  children,
  stripeSdkOnly = false,
  logger,
}: {
  api: 'paymentIntents' | 'charges';
  children: React.ReactNode;
  stripeSdkOnly?: boolean; // This is used for a scenario we only need stripe SDK but doesn't need to make a payment.
  logger: Logger;
}): React.ReactElement => {
  const { locale } = getConfig();
  const environment = useEnvironment();
  const theme = useTheme<BrandTheme>();
  const [createOrRetrieveStripeSetupIntent] =
    useCreateOrRetrieveStripeSetupIntentMutation();
  const [getStripePublicKeyQuery] = useStripePublicKeyLazyQuery({
    context: {
      skipErrorNotification: true,
    },
  });
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const isInitialised = useRef(false);

  useEffect(() => {
    if (api === 'paymentIntents' && !stripeSdkOnly && !isInitialised.current) {
      // StrictMode makes this run twice which creates two stripe customers and results in error the first time this is loaded
      // this is not trivial to handle in API as we already check for existing customer
      isInitialised.current = true;
      createOrRetrieveStripeSetupIntent().then((response) => {
        if (response.data?.createOrRetrieveStripeSetupIntent) {
          setClientSecret(
            response.data.createOrRetrieveStripeSetupIntent.clientSecret,
          );
        }
      });
    }
  }, [createOrRetrieveStripeSetupIntent, api, stripeSdkOnly]);

  useEffect(() => {
    if (clientSecret || api === 'charges' || stripeSdkOnly) {
      getStripePublicKeyQuery().then(({ data: stripeAccountData }) => {
        const publicKey =
          stripeAccountData?.stripeAccount?.publicKey || environment.stripe;
        const promise = loadStripe(publicKey).catch((error: unknown) => {
          if (error instanceof Error) {
            logger.error(
              `Loading Stripe within StripeProvider: ${error.message}`,
            );
          }
          if (typeof error === 'string') {
            logger.error(`Loading Stripe within StripeProvider: ${error}`);
          }

          logger.error('Loading Stripe within StripeProvider', { error });
          return null;
        });
        setStripePromise(promise);
      });
    }
  }, [
    clientSecret,
    api,
    getStripePublicKeyQuery,
    stripeSdkOnly,
    logger,
    environment.stripe,
  ]);

  if (
    (!clientSecret && api === 'paymentIntents' && !stripeSdkOnly) ||
    !stripePromise
  ) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        locale: locale as StripeElementLocale,
        clientSecret: clientSecret ?? undefined,
        appearance: {
          theme: 'flat',
          variables: {
            borderRadius: '4px',
            colorPrimary: theme.colors.primary[500],
            colorBackground: theme.colors.primary[100],
            colorText: theme.colors.primary[500],
          },
        },
      }}
    >
      {children}
    </Elements>
  );
};
