import { SystemStyleObject } from '@chakra-ui/react';

interface AccordionThemeProps {
  colors: {
    icon: string;
    border: string;
    text: string;
    focusColor: string;
  };
}

export const getAccordionStyle = (
  props: AccordionThemeProps,
): SystemStyleObject => ({
  baseStyle: {
    container: {
      paddingY: '16px',
      paddingLeft: '8px',
    },
    button: {
      padding: 0,
      _focus: {
        boxShadow: `0 0 0 0.188rem ${props.colors.focusColor}`,
      },
    },
    panel: {
      padding: '0.5rem',
      paddingRight: 0,
    },
    icon: {
      fontSize: '2rem',
    },
  },
  variants: {
    default: {
      container: {
        color: props.colors.text,
        borderColor: props.colors.border,
      },
      icon: {
        color: props.colors.icon,
      },
    },
    alternate: {
      container: {
        color: 'white',
        borderColor: 'white',
      },
      icon: {
        color: 'white',
      },
    },
    light: {
      container: {
        borderColor: 'white',
        padding: 0,
        shadow: 'none',
      },
      button: {
        color: props.colors.text,
        _hover: {
          backgroundColor: 'white',
          shadow: 'none',
        },
        _focus: {
          backgroundColor: 'white',
          shadow: 'none',
        },
      },
      icon: {
        color: props.colors.icon,
      },
    },
  },
});
