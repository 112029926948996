import React from 'react';
import { Typography, ActionModal } from '@eucalyptusvc/design-system';
import { SubscriptionPlanProduct } from '../types';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { formatSubscriptionCadence } from '../utils';
import { SubscriptionPlanInterval } from '@customer-frontend/graphql-types';

export type SubscriptionActionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  confirmText: string;
  headingText: string;
  bodyText: string;
  products: SubscriptionPlanProduct[];
  planInterval: SubscriptionPlanInterval;
  planIntervalCount: number;
  loading: boolean;
};

export const SubscriptionActionModal: React.FC<
  SubscriptionActionModalProps
> = ({
  isOpen,
  onClose,
  onConfirm,
  headingText,
  bodyText,
  confirmText,
  products,
  planInterval,
  planIntervalCount,
  loading,
}) => {
  return (
    <ActionModal
      title={headingText}
      primaryAction={{
        label: confirmText,
        onClick: onConfirm,
        isLoading: loading,
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="space-y-4">
        <Typography size="medium-paragraph">{bodyText}</Typography>
        <div className="bg-primary-100 rounded p-4">
          {products.map(({ name, photoUrl, price }) => (
            <div
              className="flex flex-row items-center justify-start"
              key={name}
            >
              <div className="flex rounded overflow-hidden w-20 h-auto">
                <img src={photoUrl} alt="" />
              </div>
              <div className="flex-1 ml-4 space-y-2">
                <Typography size="sm">{name}</Typography>
                <div className="flex items-center space-x-1">
                  <Typography size="paragraph" isBold>
                    {formatCentsToCurrency(price)}
                  </Typography>
                  <Typography size="paragraph">
                    /
                    {formatSubscriptionCadence(planInterval, planIntervalCount)}
                  </Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ActionModal>
  );
};
