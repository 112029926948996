import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Dropdown, TextArea, Typography } from '@eucalyptusvc/design-system';
import {
  maxLengthValidation,
  requiredValidation,
} from '@customer-frontend/utils';
import type {
  DeepMap,
  FieldError,
  UseFormMethods,
  ValidateResult,
} from 'react-hook-form';

import {
  DELIVERY_DROPDOWN_OPTIONS,
  DELIVERY_OPTIONS,
  OTHER_DELIVERY_INSTRUCTIONS,
  UserAddressPayload,
} from '../logic';
import { FormattedMessage } from 'react-intl';
import { AddressFields } from '@customer-frontend/intl';

type DeliveryInstructionsProps = {
  registerField: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  onChange?: (address: Partial<AddressFields>) => void;
  name: string;
  palette?: 'default' | 'alternate' | 'white';
  errors?: DeepMap<Record<string, string>, FieldError>;
};

export type DeliveryInstructionsFields = {
  deliveryOption: string;
  deliveryOther: string;
};

// This regex case insensitively matches strings containing “po box”,
// “post office”, “post shop”, “parcel locker” or any variation where
// characters ‘_’ or ‘-’ or whitespace can be optionally inserted.
const DELIVERY_RULES =
  /(po(_|-|\s?)*box)|(post(_|-|\s)*(office|shop))|(parcel(_|-|\s)*locker)/gim;

export const deliveryInstructionsToFields = (
  instructions?: string | null,
): DeliveryInstructionsFields => {
  if (instructions == null) {
    return {
      deliveryOption: DELIVERY_OPTIONS[0],
      deliveryOther: '',
    };
  }
  return DELIVERY_OPTIONS.includes(instructions)
    ? {
        deliveryOption: instructions,
        deliveryOther: '',
      }
    : {
        deliveryOption: OTHER_DELIVERY_INSTRUCTIONS,
        deliveryOther: instructions,
      };
};

export const fieldsToDeliveryInstructions = (
  fields: DeliveryInstructionsFields,
): string => {
  if (fields.deliveryOption === '') {
    return DELIVERY_OPTIONS[0];
  }
  return fields.deliveryOption === OTHER_DELIVERY_INSTRUCTIONS
    ? fields.deliveryOther
    : fields.deliveryOption;
};

export const addressFieldsToPayload = (
  addressFields: AddressFields,
): UserAddressPayload => {
  const { deliveryOther, deliveryOption, ...common } = addressFields;
  return {
    ...common,
    deliveryInstructions: fieldsToDeliveryInstructions({
      deliveryOther: deliveryOther ?? DELIVERY_OPTIONS[0],
      deliveryOption: deliveryOption ?? '',
    }),
  };
};

export const DeliveryInstructions = ({
  registerField,
  onChange,
  watch,
  name,
  palette,
  errors,
}: DeliveryInstructionsProps): React.ReactElement => {
  const deliveryOptionRef = useRef<HTMLSelectElement | null>();
  const deliveryOtherRef = useRef<HTMLTextAreaElement | null>();

  const currentDeliveryOption = watch(`${name}.deliveryOption`);
  const isOtherDeliveryInstructions = !DELIVERY_OPTIONS.includes(
    currentDeliveryOption,
  );

  const onDeliveryChange = useCallback((): void => {
    if (onChange) {
      onChange({
        deliveryOption: deliveryOptionRef.current?.value,
        deliveryOther: deliveryOtherRef.current?.value,
      });
    }
  }, [onChange]);

  return (
    <div>
      <div className="flex flex-col mb-3 gap-2">
        <Typography isBold size="sm">
          <FormattedMessage defaultMessage="Provide delivery instructions" />
        </Typography>
        <Typography size="paragraph">
          <FormattedMessage defaultMessage="Our carrier can only deliver to residential addresses and easily-located business addresses. They can't deliver to post offices, PO boxes, or parcel lockers." />
        </Typography>
      </div>
      <div>
        <Dropdown
          ref={(ref): void => {
            deliveryOptionRef.current = ref;
            registerField({
              ...requiredValidation('deliveryOption'),
            })(ref);
          }}
          label="Delivery option"
          name={`${name}.deliveryOption`}
          errorMessage={errors?.deliveryOption?.message}
          palette={palette}
          options={DELIVERY_DROPDOWN_OPTIONS}
          onChange={onDeliveryChange}
        />
      </div>
      <div className={clsx(!isOtherDeliveryInstructions && 'hidden')}>
        <TextArea
          ref={(ref): void => {
            deliveryOtherRef.current = ref;
            registerField({
              validate: {
                delivery: (value: string): ValidateResult => {
                  return DELIVERY_RULES.test(value)
                    ? "Our carrier can't deliver to post offices, PO boxes or parcel lockers"
                    : true;
                },
                required: (value: string): ValidateResult => {
                  if (!isOtherDeliveryInstructions) {
                    return true;
                  }
                  return typeof value === 'string'
                    ? value.trim().length > 0 ||
                        'Delivery instructions are required'
                    : true;
                },
              },
              ...maxLengthValidation('delivery instructions', 80),
            })(ref);
          }}
          rows={2}
          label=""
          name={`${name}.deliveryOther`}
          errorMessage={errors?.deliveryOther?.message}
          palette={palette}
          onChange={onDeliveryChange}
        />
      </div>
    </div>
  );
};
