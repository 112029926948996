import React from 'react';
import { EventService } from './service';

const EventServiceContext = React.createContext<EventService | undefined>(
  undefined,
);

export const useEventService = (): EventService => {
  const service = React.useContext(EventServiceContext);
  if (!service) {
    throw new Error('Missing EucalyptusEventProvider');
  }
  return service;
};

export const useEventServiceDirectContext = (): EventService | undefined => {
  return React.useContext(EventServiceContext);
};

export const EventServiceProvider = ({
  children,
  service,
}: {
  service: EventService;
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <EventServiceContext.Provider value={service}>
      {children}
    </EventServiceContext.Provider>
  );
};
