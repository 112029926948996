import { datadogRum } from '@datadog/browser-rum';
import React, { useState, useRef } from 'react';
import cuid from 'cuid';
import {
  initialize,
  LDClient,
  LDSingleKindContext,
} from 'launchdarkly-js-client-sdk';
import { useEventService } from '@customer-frontend/events';
import { notificationService } from '@customer-frontend/notifications';
import { useAuth, UserInfo } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import { Logger } from '@customer-frontend/logger';
import { uiStorages } from '@customer-frontend/ui-storage';
import {
  BooleanFlagKey,
  FlagConfigOptions,
  BooleanFeatureFlags,
  MultivariateFlagKey,
  MultivariateFeatureFlags,
  MultivariateEnrolmentStatus,
  JsonFlagConfigOptions,
  JsonFlagKey,
  JsonFlags,
  FlagValue,
} from '../config';
import { Brand } from '@customer-frontend/types';
import { useEnvironment } from '@customer-frontend/environment';

const CUID_STORAGE_KEY = 'cuid';
const ANONYMOUS_LD_KEY = 'anonymous';
const ANONYMOUS_CUID_BRANDS: Brand[] = [
  // 'pilot',
  // 'juniper',
];

export interface FeatureFlagClient {
  getBoolean: (flagKey: BooleanFlagKey, options?: FlagConfigOptions) => boolean;
  useDynamicBoolean: (
    flagKey: BooleanFlagKey,
    options?: FlagConfigOptions,
  ) => boolean;
  getMultivariate: (
    flagKey: MultivariateFlagKey,
    options?: FlagConfigOptions,
  ) => MultivariateEnrolmentStatus;
  getCustom: <V>(
    flagKey: string,
    options: FlagConfigOptions & {
      defaultValue: V; // Required for custom flags
    },
  ) => V;
  getJson: <V>(
    flagKey: JsonFlagKey,
    options?: JsonFlagConfigOptions<V>,
  ) => V | undefined;
  ldClient: LDClient | undefined;
  anonymousCuid: string | undefined;
}

export const FeatureFlagContext = React.createContext<FeatureFlagClient>({
  getBoolean: (flagKey) => BooleanFeatureFlags[flagKey].defaultValue,
  useDynamicBoolean: (flagKey) => BooleanFeatureFlags[flagKey].defaultValue,
  getMultivariate: (flagKey) => MultivariateFeatureFlags[flagKey].defaultValue,
  getCustom: (_flagKey, options) => options.defaultValue,
  getJson: (_flagKey, options) => options?.defaultValue,
  ldClient: undefined,
  anonymousCuid: undefined,
});

export const useFeatureFlagClient = (): FeatureFlagClient =>
  React.useContext(FeatureFlagContext);

export const useFeatureFlagBoolean = (key: BooleanFlagKey): boolean => {
  const client = useFeatureFlagClient();
  return client.getBoolean(key);
};

export const useFeatureFlagJson = <V,>(
  key: JsonFlagKey,
  options: JsonFlagConfigOptions<V>,
): V | undefined => {
  const client = useFeatureFlagClient();
  return client.getJson(key, options);
};

const getAnonymousCuid = (
  brand: Brand,
  isLoggedIn: boolean,
): string | undefined => {
  if (ANONYMOUS_CUID_BRANDS.includes(brand) && !isLoggedIn) {
    const existingCuid = uiStorages.local.getValue(CUID_STORAGE_KEY);
    if (!existingCuid) {
      const newCuid = cuid();
      uiStorages.local.setValue(CUID_STORAGE_KEY, newCuid);
      return newCuid;
    } else {
      return existingCuid;
    }
  } else {
    if (uiStorages.local.getValue(CUID_STORAGE_KEY)) {
      uiStorages.local.clearValue(CUID_STORAGE_KEY);
    }
  }
};

export const FeatureFlagProvider = ({
  children,
  logger,
}: {
  children: React.ReactNode;
  logger: Logger;
}): React.ReactElement => {
  const { brand } = getConfig();
  const { launchDarklyClientID } = useEnvironment();
  const [ldClient, setLDClient] = useState<LDClient>();
  const [loading, setLoading] = useState(true);

  const { loggedInUser, isLoggedIn } = useAuth();
  const [anonymousCuid, setAnonymousCuid] = useState<string | undefined>(
    getAnonymousCuid(brand, isLoggedIn),
  );
  const event = useEventService();

  const flagExposures = useRef<Record<string, FlagValue | undefined>>({});

  const trackFlagExposureEvent = (
    user: UserInfo | undefined,
    flagKey: string,
    flagValue?: FlagValue,
    alwaysEmit = false,
    additionalAttributes: object = {},
  ): void => {
    if (!alwaysEmit && flagKey in flagExposures.current) {
      // debounce duplicate flag exposure events
      return;
    }

    if (!user) {
      // At the time of exposure, the user state may have changed (e.g logging in)
      // This fixes the race condition in which that event occurs, and an exposure event was
      // incorrectly fired for that user. Because we enrol by user ID, once they've logged in,
      // they will be assigned a different cohort (which should trigger a re-render + this function again)
      return;
    }

    event.featureFlag.exposed({
      flagKey,
      flagStringValue:
        typeof flagValue === 'object' &&
        !Array.isArray(flagValue) &&
        flagValue !== null
          ? JSON.stringify(flagValue)
          : `${flagValue}`,
      additionalAttributes: JSON.stringify(additionalAttributes),
    });

    flagExposures.current[flagKey] = flagValue;
  };

  const getBoolean = (
    flagKey: BooleanFlagKey,
    options: FlagConfigOptions = {},
  ): boolean => {
    const flag = BooleanFeatureFlags[flagKey];

    const flagValue =
      ldClient !== undefined
        ? ldClient.variation(flag.flagKey, flag.defaultValue)
        : flag.defaultValue;

    if (options.disableExposureTracking !== true) {
      trackFlagExposureEvent(loggedInUser, flag.flagKey, flagValue, false);
    }

    return flagValue;
  };

  const useDynamicBoolean = (
    flagKey: BooleanFlagKey,
    options: FlagConfigOptions = {},
  ): boolean => {
    const flag = BooleanFeatureFlags[flagKey];

    const [flagValue, setFlagValue] = useState<boolean>(
      ldClient !== undefined
        ? ldClient.variation(flag.flagKey, flag.defaultValue)
        : flag.defaultValue,
    );

    if (options.disableExposureTracking !== true) {
      trackFlagExposureEvent(loggedInUser, flag.flagKey, flagValue, false);
    }

    // this hook is specifically for dynamic feature flags!
    ldClient?.on(`change:${flag.flagKey}`, (newFlagValue: boolean) => {
      setFlagValue(newFlagValue);
    });

    return flagValue;
  };

  const getMultivariate = (
    flagKey: MultivariateFlagKey,
    options: FlagConfigOptions = {},
  ): MultivariateEnrolmentStatus => {
    const flag = MultivariateFeatureFlags[flagKey];
    const flagValue =
      ldClient !== undefined
        ? ldClient.variation(flag.flagKey, flag.defaultValue)
        : flag.defaultValue;

    if (options.disableExposureTracking !== true) {
      trackFlagExposureEvent(
        loggedInUser,
        flag.flagKey,
        flagValue,
        false,
        options.additionalAttributes,
      );
    }
    if (
      !(
        flagValue === 'control' ||
        flagValue === 'not-enrolled' ||
        flagValue === 'variation'
      )
    ) {
      logger.warn(
        `unexpected multivarate flag value: "${flagValue}" for flag "${flag}"`,
      );
      return flag.defaultValue;
    }
    return flagValue;
  };

  const getCustom = <Custom,>(
    flagKey: string,
    options: FlagConfigOptions & {
      defaultValue: Custom;
    },
  ): Custom => {
    const flagValue =
      ldClient !== undefined
        ? ldClient.variation(flagKey, options.defaultValue)
        : options.defaultValue;

    if (options.disableExposureTracking !== true) {
      trackFlagExposureEvent(
        loggedInUser,
        flagKey,
        flagValue,
        false,
        options.additionalAttributes,
      );
    }

    return flagValue;
  };

  const getJson = <T,>(
    flagKey: JsonFlagKey,
    options: JsonFlagConfigOptions<T | undefined> = { defaultValue: undefined },
  ): T | undefined => {
    const flagValue =
      ldClient !== undefined
        ? ldClient.variation(JsonFlags[flagKey], options.defaultValue)
        : options.defaultValue;

    if (options.disableExposureTracking !== false) {
      trackFlagExposureEvent(loggedInUser, flagKey, flagValue, false);
    }

    return flagValue;
  };

  React.useEffect(() => {
    // We want to maintain the value for anonymousCuid after the user
    // logs in in order to avoid reusing the same anonymousCuid across
    // multiple signups which may leave the user in a state that they
    // can't signup because our code keeps using the same anonymousCuid
    // and causes an id collision when attempting signup.
    setAnonymousCuid(getAnonymousCuid(brand, isLoggedIn));
  }, [brand, isLoggedIn]);

  React.useEffect(() => {
    (async (): Promise<void> => {
      try {
        flagExposures.current = {};
        const userId = loggedInUser?.id || anonymousCuid;
        const userContext: LDSingleKindContext = {
          kind: 'user',
          key: userId || ANONYMOUS_LD_KEY, // note: do not pass null | undefined here when anonymous is true to prevent higher usage of client side MAUs. instead provide `anonymous` as the key
          anonymous: !userId,
          brand,
        };

        const ldClient = initialize(launchDarklyClientID, userContext, {
          inspectors: [
            {
              type: 'flag-used',
              name: 'dd-inspector',
              method: (key, detail) => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value);
              },
            },
          ],
        });

        ldClient.on('failed', () => {
          logger.error(
            `LaunchDarkly client failed to initialise, all flags will be evaluated with their default value`,
          );
        });

        await new Promise<void>((resolve) => {
          ldClient.on('ready', () => {
            resolve();
          });
        });

        setLDClient(ldClient);
        setLoading(false);
      } catch (e) {
        logger.warn('Failed to fetch feature flags', e);
        return notificationService.show({
          type: 'error',
          message: 'Failed to load page configuration, please refresh.',
        });
      }
    })();
  }, [loggedInUser?.id, launchDarklyClientID, logger, brand, anonymousCuid]);

  return (
    <FeatureFlagContext.Provider
      value={{
        getBoolean,
        useDynamicBoolean,
        getMultivariate,
        getCustom,
        getJson,
        ldClient,
        anonymousCuid,
      }}
    >
      {!loading && children}
    </FeatureFlagContext.Provider>
  );
};
