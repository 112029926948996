import { Avatar, Typography } from '@eucalyptusvc/design-system';
import type { DoctorInfoProps } from './types';

export const DoctorInfo = ({
  doctor,
  details,
  removeDrPrefix,
  palette,
  children,
}: DoctorInfoProps): JSX.Element => {
  const { firstName, lastName, avatar, fullClinicianName, fullName } = doctor;

  return (
    <div className="flex gap-4 md:gap-5">
      <Avatar
        palette={palette?.avatar}
        size="lg"
        src={avatar?.url}
        firstName={firstName}
        lastName={lastName}
      />
      <div className="space-y-1">
        <Typography element="h3" size="md" isBold>
          {removeDrPrefix ? fullName : fullClinicianName}
        </Typography>
        {details && <Typography size="paragraph">{details}</Typography>}
        {children}
      </div>
    </div>
  );
};
