import type {
  Maybe,
  ProblemType,
  User,
  ConsultationQuestion,
  ConsultationPatientQuestion,
  ConsultationAnswerType,
  ConsultationReminder,
  ConsultationStatus,
} from '@customer-frontend/graphql-types';
import { ChatTheme } from '../types';
import { Control } from 'react-hook-form';
import { MessageDescriptor } from 'react-intl';

export type ReviewThreadProps = {
  consultationId: string;
  consultationStatus: ConsultationStatus;
  problemType: ProblemType;
  canMessage: boolean;
  doctor?: Maybe<User>;
  doctorQuestions?: ConsultationQuestion[];
  patientQuestions?: ConsultationPatientQuestion[];
  reminders?: Maybe<Array<Maybe<ConsultationReminder>>>;
  imageInstructions?: Partial<
    Record<ProblemType, Array<string | MessageDescriptor>>
  >;
  requiredAlertIcon?: JSX.Element;
  chatTheme: ChatTheme;
  onSubmit?: (() => void) | (() => Promise<unknown>);
  createObservationInput?: (
    id: string,
    control: Control,
    doctor: Maybe<User>,
    consultationId: string,
    submitPending: boolean,
    submit: () => Promise<void>,
    problemType: ProblemType,
    errorMessage?: string,
  ) => JSX.Element;
  getPlaceholderImgUrl?: (params: {
    problemType?: ProblemType;
    question?: string;
  }) => string | undefined;
};

export const MessageTypes = {
  DOCTOR_MESSAGE: 'DOCTOR_MESSAGE',
  PATIENT_MESSAGE: 'PATIENT_MESSAGE',
  REMINDER: 'REMINDER',
} as const;

export interface ThreadMessage {
  id: string;
  allowedAnswerType?: ConsultationAnswerType;
  messageType: (typeof MessageTypes)[keyof typeof MessageTypes];
  ref: React.RefObject<HTMLDivElement>;
  date: Date;
  answered?: boolean;
  component: (isFirst: boolean, isLast: boolean) => JSX.Element;
}
