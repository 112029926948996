import React from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useStartQuizApplicationWithQuizCode } from '@customer-frontend/services';
import { LoadingSpinner, useNotification } from '@eucalyptusvc/design-system';

export const StartGeneralQuiz = ({
  buildQuizUrl,
  profileRoute,
}: {
  buildQuizUrl: (applicationId: string) => string;
  profileRoute: string;
}): React.ReactElement => {
  const history = useHistory();
  const notify = useNotification();
  const { quizCode } = useParams<Partial<{ quizCode: string }>>();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const startQuizApplicationWithQuizCode =
    useStartQuizApplicationWithQuizCode();

  React.useEffect(() => {
    if (quizCode) {
      startQuizApplicationWithQuizCode(quizCode)
        .then((quizApplication) => {
          return history.push({
            pathname: buildQuizUrl(quizApplication.id),
            search: location.search,
          });
        })
        .catch(() => {
          notify.error({
            message: formatMessage({
              defaultMessage: "Sorry, that quiz isn't available",
              description: 'Error message when quiz isnt available',
            }),
          });
          return history.push(profileRoute);
        });
    }
  }, [
    history,
    notify,
    quizCode,
    buildQuizUrl,
    profileRoute,
    formatMessage,
    startQuizApplicationWithQuizCode,
    location.search,
  ]);

  return (
    <div className="flex justify-center p-5">
      <LoadingSpinner />
    </div>
  );
};
