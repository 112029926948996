import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TextInput } from '@eucalyptusvc/design-system';
import { QuestionProps } from './types';
import {
  useEmailValidation,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { useIntl } from 'react-intl';

export function EmailQuestionInput({
  question,
  isLoading,
}: QuestionProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { register, errors } = useFormContext();
  const { required } = question;

  const emailValidation = useEmailValidation();
  const emailRequiredValidation = useRequiredValidation(
    formatMessage({
      defaultMessage: 'Email',
    }),
  );

  return (
    <TextInput
      autoFocus
      disabled={isLoading}
      name={question.id}
      ref={register({
        ...(required ? emailRequiredValidation : {}),
        ...emailValidation,
      })}
      type="email"
      required={required ?? false}
      errorMessage={errors[question.id]?.message}
    />
  );
}
