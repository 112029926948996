import { ObservedMeasurement } from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { useTrackerTheme } from '../../provider';
import { FormattedMessage } from 'react-intl';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';

export const SelectedTrackerEntry = ({
  weight,
  waist,
}: {
  weight?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>;
  waist?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>;
}): React.ReactElement | null => {
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.enhancedTrackerChart;
  const config = getConfig();

  if (!weight) {
    return null;
  }

  const dateSelectedWeight = formatDate(
    mapBrandToAdaptersBrand(config.brand),
    weight.effectiveFrom,
    {
      dateStyle: 'medium',
    },
  );

  return (
    <div className="space-y-1">
      <Typography size="paragraph" color={trackerTheme?.text?.secondaryColor}>
        {dateSelectedWeight}
      </Typography>
      <div className="flex items-end gap-1">
        <Typography size="xl" isBold color={trackerTheme?.text?.primaryColor}>
          {weight.value}
        </Typography>
        <div className="mb-1">
          <Typography
            size="medium-paragraph"
            color={trackerTheme?.text?.primaryColor}
          >
            kg
          </Typography>
        </div>
      </div>
      <Typography size="paragraph" color={trackerTheme?.text?.secondaryColor}>
        <FormattedMessage
          defaultMessage="Waist: {waist}"
          values={{
            waist: waist?.value ? `${waist.value} cm` : '-',
          }}
        />
      </Typography>
    </div>
  );
};
