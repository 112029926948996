import {
  gql,
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import {
  MutationUpdateShippingArgs,
  UpdateShippingAddressMutationResult,
} from '@customer-frontend/graphql-types';

const updateShippingDocument = gql`
  mutation UpdateShippingAddress($address: AddressCreateWithoutUserInput!) {
    updateShipping(address: $address) {
      id
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        building
        company
      }
    }
  }
`;

export function useUpdateShippingAddress(
  options?: MutationHookOptions<
    UpdateShippingAddressMutationResult,
    MutationUpdateShippingArgs
  >,
): MutationTuple<
  UpdateShippingAddressMutationResult,
  MutationUpdateShippingArgs
> {
  return useMutation<
    UpdateShippingAddressMutationResult,
    MutationUpdateShippingArgs
  >(updateShippingDocument, options);
}
