import { sharedColors } from '../shared/palette';

// primary
const primary100 = '#FFEEED';
const primary200 = '#FFB9B7';
const primary300 = '#FF716D';
const primary400 = '#FF544F';
const primary500 = '#E04D49';
const primary600 = '#0B345D';

// secondary
const brown100 = '#A49B96';
const brown200 = '#7F716B';
const brown300 = '#48342B';
const brown400 = '#220A00';
const brown500 = '#140600';

const cream100 = '#FFF9F5';
const cream200 = '#FBF4EE';
const cream300 = '#ECE5DE';
const cream400 = '#E0D9D2';

const grey100 = '#F5F5F5';
const grey200 = '#E8E7E7';
const grey300 = '#D9D9D9';
const grey400 = '#C2C2C2';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    brown: {
      100: brown100,
      200: brown200,
      300: brown300,
      400: brown400,
      500: brown500,
    },
    cream: {
      100: cream100,
      200: cream200,
      300: cream300,
      400: cream400,
    },
    grey: {
      100: grey100,
      200: grey200,
      300: grey300,
      400: grey400,
    },
  },
  tertiary: {
    cream: '#FDFCF4',
    olive: '#7A7F2F',
  },
  reserved: {},
};
