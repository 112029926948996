import { Brand } from '@customer-frontend/types';
import { theme as juniperTheme } from './juniper-theme';
import { theme as pilotTheme } from './pilot-theme';
import { EducationBrandTheme } from './types';

export const getBrandTheme = (
  brand: Brand,
): EducationBrandTheme | undefined => {
  switch (brand) {
    case 'pilot':
      return pilotTheme;
    case 'compound-uk': // TODO: https://linear.app/eucalyptus/issue/ENG-1789/%5Bcustomer-frontend%5D-compound-design-system
    case 'juniper':
    case 'juniper-uk':
    case 'juniper-de':
    case 'juniper-jp':
      return juniperTheme;
  }
};
