import { getConfig } from '@customer-frontend/config';
import { ZipModalLauncher } from '@customer-frontend/order';
import ZipLogo from '../../assets/zip-icon.webp';
import { getReadableBrandName } from '@customer-frontend/utils';
import { Typography, BrandTheme } from '@eucalyptusvc/design-system';
import { useTheme } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { CustomCheckbox } from './custom-checkbox';
import { useEnvironment } from '@customer-frontend/environment';
import { PaymentMethodsFragment } from '@customer-frontend/graphql-types';
import clsx from 'clsx';

export function ZipInput(props: {
  hideCheckbox: boolean;
  active: boolean;
  setActive: () => void;
  zip: PaymentMethodsFragment['zip'];
}): React.ReactElement {
  const config = getConfig();
  const environment = useEnvironment();
  const theme = useTheme<BrandTheme>();

  if (!environment.zipMerchantId) {
    throw new Error('zip is not configured');
  }

  const { hideCheckbox, active, setActive } = props;

  return (
    <div>
      <button
        className={clsx(
          'flex items-center justify-between py-5 px-4 md:px-5 w-full',
          !active && 'cursor-pointer',
        )}
        onClick={!active ? setActive : undefined}
        tabIndex={!active ? undefined : -1}
        type="button"
      >
        <div className="flex items-center w-full">
          <CustomCheckbox checked={active} hidden={hideCheckbox} />

          <div className="ml-3 flex items-center w-full">
            <div className="flex items-center justify-between w-full">
              <Typography size="paragraph" isBold color="black">
                <FormattedMessage defaultMessage="Zip" />
              </Typography>
              <img className="w-8 h-5" src={ZipLogo} />
            </div>
          </div>
        </div>
      </button>
      {active && (
        <div className="space-y-4 pb-5 px-4 md:px-5">
          {props.zip?.valid ? (
            <Typography size="small-text" color={theme.colors.neutral['900']}>
              <FormattedMessage defaultMessage="By confirming payment, you will be charged to your connected Zip account." />
            </Typography>
          ) : (
            <div className="flex flex-col space-y-2">
              <Typography size="small-text" color={theme.colors.neutral['900']}>
                <FormattedMessage defaultMessage="After clicking confirm payment, you will be directed to Zip's website to complete your purchase securely. When your Zip transaction is complete, you will be returned to this site." />
              </Typography>
              <Typography size="small-text" color={theme.colors.neutral['900']}>
                <FormattedMessage
                  defaultMessage="<strong>Please note</strong>: all future payments on {brand} will be charged to your Zip account. You can change this setting in your profile anytime."
                  values={{
                    strong: (chunks) => <strong>{chunks}</strong>,
                    brand: getReadableBrandName(config.brand),
                  }}
                />
              </Typography>
              <ZipModalLauncher merchantId={environment.zipMerchantId}>
                <div className="underline cursor-pointer whitespace-nowrap items-center flex">
                  <Typography
                    size="small-text"
                    color={theme.colors.neutral['900']}
                  >
                    <FormattedMessage
                      defaultMessage="Learn more about Zip"
                      description="Text to learn more about Zip as a payment provider"
                    />
                  </Typography>
                </div>
              </ZipModalLauncher>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
