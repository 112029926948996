import { type ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { gql, useApolloClient } from '@apollo/client';
import type { SetupIntent } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import { z } from 'zod';
import { getConfig } from '@customer-frontend/config';
import {
  ICustomerOfferingsPurchasedEvent,
  useEventService,
} from '@customer-frontend/events';
import {
  PurchaseOfferingsFromIntentHandlerMutation,
  PurchaseOfferingsFromIntentHandlerMutationVariables,
  PurchaseOfferingsIntentHandlerPageQuery,
  PurchaseOfferingsIntentHandlerPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { LoadingSpinner, useNotification } from '@eucalyptusvc/design-system';
import { StripeProvider } from '@customer-frontend/services';
import { base64ToUnicode } from '@customer-frontend/utils';
import { uiStorages } from '@customer-frontend/ui-storage';
import { type Logger } from '@customer-frontend/logger';
import { clearPreselectedOfferingSelection } from './preselection-utils';

const inputSchema = z.object({
  purchaseGroupId: z.string().uuid(),
  pricingSessionId: z.string().uuid(),
  consultationId: z.optional(z.nullable(z.string().cuid())),
  couponCode: z.optional(z.nullable(z.string())),
  expectedChargeAmount: z.number(),
  gateway: z.enum(['STRIPE', 'ZIP']),
  onCompleteRoute: z.string().optional(),
  shippingAddress: z
    .object({
      line1: z.string().optional(),
      line2: z.string().optional(),
      city: z.string().optional(),
      postalCode: z.string().optional(),
      state: z.string().optional(),
      country: z.string().optional(),
      prefecture: z.string().optional(),
      municipality: z.string().optional(),
      townArea: z.string().optional(),
      deliveryInstructions: z.string().optional(),
    })
    .optional(),
  source: z.enum(['OP', 'CP']),
  consent: z.boolean(),
  offerings: z.array(
    z.object({
      offeringId: z.string().uuid(),
      addons: z
        .array(
          z.object({
            offeringId: z.string().uuid(),
            sequenceSelections: z.array(
              z.object({
                sequenceId: z.string().uuid(),
                sequenceSetId: z.string().uuid(),
              }),
            ),
          }),
        )
        .optional()
        .nullable(),
      sequenceSelections: z.array(
        z.object({
          sequenceId: z.string().uuid(),
          sequenceSetId: z.string().uuid(),
        }),
      ),
    }),
  ),
});

export type PurchaseOfferingsIntentHandlerInput = z.infer<typeof inputSchema>;

const mutation = gql`
  mutation PurchaseOfferingsFromIntentHandler($input: PurchaseOfferingsInput!) {
    purchaseOfferings(input: $input) {
      purchaseGroup {
        id
        consultation {
          id
          status
          purchasePrompt {
            id
          }
        }
      }
    }
  }
`;

const query = gql`
  query PurchaseOfferingsIntentHandlerPage(
    $consultationId: String
    $pricingSessionId: ID!
    $offeringSelections: [OfferingSelectionInput!]!
    $couponCodes: [String!]
  ) {
    profile {
      id
      email
      zip {
        valid
      }
    }
    initialPurchasePrice(
      consultationId: $consultationId
      pricingSessionId: $pricingSessionId
      offeringSelections: $offeringSelections
      couponCodes: $couponCodes
    ) {
      id
      amount
      discountAmount
      offeringPriceLists {
        id
        offering {
          id
          problemTypes
        }
        sequencePriceLists {
          id
          sequence {
            id
          }
          pricedItems {
            id
            quantity
            unitPrice
            unitPriceDiscount
            variant {
              id
              product {
                id
                problemTypes
                name
              }
              inventory {
                id
                sku
              }
            }
          }
        }
      }
    }
  }
`;

const seenPurchaseGroupIds = new Set<string>();

type PurchaseOfferingsIntentHandlerPageProps = {
  routes: {
    profile: string;
  };
  logger: Logger;
};

function Inner({
  routes,
  logger,
}: PurchaseOfferingsIntentHandlerPageProps): ReactElement {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const notify = useNotification();
  const client = useApolloClient();
  const stripe = useStripe();
  const events = useEventService();

  const successWithPaymentMessage = formatMessage({
    defaultMessage: 'Your payment has been made successfully!',
    description: 'Success message for successful payment',
  });

  const successMessage = formatMessage({
    defaultMessage: 'Success.',
    description:
      'Generic success message for offering purchases that do not require a positive payment',
  });

  const errorMessage = formatMessage({
    defaultMessage:
      'Your purchase may not have been successfully processed due to an error.',
    description: 'Error message for unsuccessful purchase due to an error',
  });

  useEffect(() => {
    if (!stripe) {
      // Stripe will be null temporarily while the parent context is being
      // initialised.
      return;
    }

    (async () => {
      try {
        const url = new URL(window.location.toString());
        const offeringsLocalStorageKey = url.searchParams.get(
          'purchaseOfferingsInputStorageKey',
        );
        if (!offeringsLocalStorageKey) {
          throw new Error('purchaseOfferingsInputStorageKey is not provided');
        }
        const pInput = uiStorages.local.getValue(offeringsLocalStorageKey);
        if (!pInput) {
          throw new Error('purchaseOfferingsInput is not set in local storage');
        }

        const input = inputSchema.parse(
          JSON.parse(base64ToUnicode(decodeURIComponent(pInput))),
        );

        const { source, onCompleteRoute, ...purchaseOfferingsInput } = input;

        const redirectUrl = onCompleteRoute ? onCompleteRoute : routes.profile;

        // This guards against the unmounting and remounting of this component causing
        // this effect to run multiple times.
        // https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
        // Follows this pattern.
        if (seenPurchaseGroupIds.has(input.purchaseGroupId)) {
          return;
        }
        seenPurchaseGroupIds.add(input.purchaseGroupId);

        let couponCodes: string[] | undefined;
        if (input.couponCode === null) {
          couponCodes = [];
        }
        if (typeof input.couponCode === 'string') {
          couponCodes = [input.couponCode];
        }

        const {
          data: { initialPurchasePrice, profile },
        } = await client.query<
          PurchaseOfferingsIntentHandlerPageQuery,
          PurchaseOfferingsIntentHandlerPageQueryVariables
        >({
          query,
          variables: {
            consultationId: input.consultationId,
            couponCodes,
            offeringSelections: input.offerings,
            pricingSessionId: input.pricingSessionId,
          },
        });

        if (!initialPurchasePrice) {
          throw new Error(
            `expected to find an initialPurchasePrice for pricing session with id: "${input.pricingSessionId}"`,
          );
        }

        let setupIntent: SetupIntent | undefined;
        let zipCheckoutId: string | undefined;

        if (input.gateway === 'STRIPE') {
          const clientSecret = url.searchParams.get(
            'setup_intent_client_secret',
          );

          if (clientSecret) {
            const setupIntentResult = await stripe.retrieveSetupIntent(
              clientSecret,
            );

            if (!setupIntentResult.setupIntent?.payment_method) {
              throw new Error('setupIntent is null');
            }

            setupIntent = setupIntentResult.setupIntent;
          }
        }

        if (input.gateway === 'ZIP' && !profile?.zip?.valid) {
          const zipResult = url.searchParams.get('result');

          switch (zipResult) {
            case 'approved': {
              const checkoutId = url.searchParams.get('checkoutId');
              if (!checkoutId) {
                throw new Error('expected to find a checkoutId from Zip');
              }
              zipCheckoutId = checkoutId;
              break;
            }
            case 'referred':
              throw new Error(
                'Your Zip application is pending approval. Please try another payment method.',
              );
            case 'cancelled':
              throw new Error(
                'Your Zip application is cancelled. Please try another payment method.',
              );
            default:
              throw new Error(
                'Your Zip application was declined. Please try another payment method.',
              );
          }
        }

        const event: ICustomerOfferingsPurchasedEvent = {
          userId: profile?.id,
          email: profile?.email,
          source: source,
          purchaseGroupId: input.purchaseGroupId,
          orderId: input.purchaseGroupId,
          pricingSessionId: input.pricingSessionId,
          currency: getConfig().currency,
          coupon: input.couponCode ?? undefined,
          total: initialPurchasePrice.amount / 100,
          amount: initialPurchasePrice.amount,
          discount: initialPurchasePrice.discountAmount / 100,
          problemTypes: [],
          offerings: [],
          productNames: '',
        };

        const productNames: string[] = [];

        const allProblemTypes = new Set<string>();

        for (const opl of initialPurchasePrice.offeringPriceLists) {
          if (!opl.offering) {
            throw new Error(
              `expected to find offering in offering price list with id: "${opl.id}"`,
            );
          }
          const problemTypes = new Set<string>();

          for (const pt of opl.offering.problemTypes ?? []) {
            allProblemTypes.add(pt);
            problemTypes.add(pt);
          }

          event.offerings.push({
            id: opl.offering.id,
            problemTypes: Array.from(problemTypes),
            products: opl.sequencePriceLists.flatMap((spl) => {
              return spl.pricedItems.map((pi) => {
                if (!pi.variant) {
                  throw new Error(
                    `expected to find variant on priced item with id: "${pi.id}"`,
                  );
                }
                if (!pi.variant.inventory?.sku) {
                  throw new Error(
                    `expected to find sku for priced item with id: "${pi.id}"`,
                  );
                }
                productNames.push(pi.variant.product.name);

                return {
                  sku: pi.variant.inventory.sku,
                  problemTypes: pi.variant.product.problemTypes,
                  quantity: pi.quantity,
                  price: pi.unitPrice,
                  discount: pi.unitPriceDiscount,
                  name: pi.variant.product.name,
                };
              });
            }),
          });
        }

        event.productNames = productNames.join(',');

        event.problemTypes = Array.from(allProblemTypes);

        const resp = await client.mutate<
          PurchaseOfferingsFromIntentHandlerMutation,
          PurchaseOfferingsFromIntentHandlerMutationVariables
        >({
          mutation,
          errorPolicy: 'all',
          variables: {
            input: {
              ...purchaseOfferingsInput,
              stripePaymentMethodId: setupIntent?.payment_method?.toString(),
              zipCheckoutId,
            },
          },
          context: {
            skipErrorNotification: true,
          },
        });

        if (resp.data?.purchaseOfferings) {
          events.flexi.customerOfferingsPurchased(event);
          clearPreselectedOfferingSelection();
          uiStorages.local.clearValue(offeringsLocalStorageKey);

          if (initialPurchasePrice.amount > 0) {
            notify.success({ message: successWithPaymentMessage });
          } else {
            notify.success({ message: successMessage });
          }

          history.replace(redirectUrl);
        } else {
          notify.error({ message: errorMessage });
          history.replace(routes.profile);
        }
      } catch (error) {
        notify.error({ message: errorMessage });
        logger.error('error handling purchase offerings intent', {
          error,
          message: error?.message,
        });
        history.replace(routes.profile);
      }
    })();
  }, [
    client,
    errorMessage,
    formatMessage,
    history,
    notify,
    stripe,
    successMessage,
    successWithPaymentMessage,
    events.flexi,
    routes,
    logger,
  ]);

  return (
    <div className="flex justify-center p-5">
      <LoadingSpinner />
    </div>
  );
}

export function PurchaseOfferingsIntentHandlerPage({
  routes,
  logger,
}: PurchaseOfferingsIntentHandlerPageProps): JSX.Element {
  return (
    <StripeProvider api="paymentIntents" logger={logger}>
      <Inner routes={routes} logger={logger} />
    </StripeProvider>
  );
}
