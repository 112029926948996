import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { PaymentIcons } from '../payment-icons';
import { getConfig } from '@customer-frontend/config';
import { capitaliseString } from '@customer-frontend/utils';
import { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { useStripePaymentRequest } from '@customer-frontend/order';
import { getStripeElementStyle } from '../../logic/payment/stripe';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { Typography, LoadingSpinner } from '@eucalyptusvc/design-system';
import { useEnablePaymentIntent } from '@customer-frontend/services';
import { useIntl } from 'react-intl';

export const PaymentCardDisplay = ({
  brand,
  mask,
  expiry,
  onClickEdit,
}: {
  brand: string;
  mask: string;
  expiry: string;
  onClickEdit?(): void;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex justify-between items-center">
      <Typography size="paragraph">
        {/* eslint-disable-next-line react/jsx-no-literals,formatjs/no-literal-string-in-jsx */}
        <span className="capitalize">{brand}</span> •••• •••• •••• {mask}{' '}
        {expiry}
      </Typography>
      {/* TODO: Discuss icon buttons */}
      {!!onClickEdit && (
        <button
          className="text-primary-darker p-2 leading-tight"
          aria-label={formatMessage({
            defaultMessage: 'Edit',
            description: 'Aria label for a button to edit payment method',
          })}
          onClick={onClickEdit}
        >
          <FaPencilAlt />
        </button>
      )}
    </div>
  );
};

interface PaymentRequestButtonProps {
  onAlternativePaymentMethod: (event: PaymentRequestTokenEvent) => void;
  totalPrice: number;
}

const PaymentRequestButton = ({
  totalPrice,
  onAlternativePaymentMethod,
}: PaymentRequestButtonProps): React.ReactElement | null => {
  const config = getConfig();
  const paymentRequestOptions = useStripePaymentRequest({
    label: `${capitaliseString(config.brand)} Services`,
    totalPrice,
    onTokenReceived: onAlternativePaymentMethod,
  });

  return paymentRequestOptions ? (
    <PaymentRequestButtonElement
      options={{ paymentRequest: paymentRequestOptions }}
    />
  ) : null;
};

interface PaymentCardFormProps {
  paymentRequest?: PaymentRequestButtonProps;
  errorTextColor?: string;
  solePaymentProvider?: boolean;
}

// Note: Component used for payment and saving card details therefore we conditionally render PaymentRequestButton
export const PaymentCardForm = ({
  paymentRequest,
  errorTextColor = sharedColors.status.error[500],
  solePaymentProvider = false,
}: PaymentCardFormProps): React.ReactElement => {
  const [cardError, setCardError] = React.useState('');

  const config = getConfig();
  const stripe = useStripe();
  const stripeElements = useElements();
  const enablePaymentIntent = useEnablePaymentIntent();

  const stripeElementStyles = getStripeElementStyle(config.brand);

  return (
    <>
      {!stripe || !stripeElements ? (
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="space-y-4">
          {/* Payment request button not compatible with payment intents */}
          {!enablePaymentIntent && paymentRequest && (
            <PaymentRequestButton {...paymentRequest} />
          )}
          {enablePaymentIntent && (
            <PaymentElement
              className={solePaymentProvider ? 'border-none md:p-0' : ''}
              options={{
                terms: {
                  card: 'never',
                },
                wallets: {
                  applePay: 'never',
                },
              }}
            />
          )}
          {!enablePaymentIntent && (
            <>
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: stripeElementStyles,
                }}
                onChange={({ error }): void => {
                  if (error) {
                    setCardError(error.message);
                  } else {
                    setCardError('');
                  }
                }}
              />
              {cardError && (
                <Typography size="small-text" color={errorTextColor} isBold>
                  {cardError}
                </Typography>
              )}
            </>
          )}
          <PaymentIcons renderCards={!solePaymentProvider} />
        </div>
      )}
    </>
  );
};
