import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import { Logger } from '@customer-frontend/logger';

import {
  PurchaseActivationFlowProvider,
  PurchaseActivationFlowRoutes,
} from './provider';
import { Layout, PurchaseActivationFlowLayoutStyles } from './layout';
import { ReviewSectionStyles } from './review-section';
import { AddDetailsSectionStyles } from './add-details-section';
import { ConfirmSectionStyles } from './confirm-section';
import { TierSelectionSectionStyles } from './tier-selection-section';
import { ReceiptSectionStyles } from './receipt-section';
import { AddonSectionStyles } from './add-on-section';

const ReviewSection = React.lazy(() => import('./review-section'));
const AddonSection = React.lazy(() => import('./add-on-section'));
const ConfirmSection = React.lazy(() => import('./confirm-section'));
const ReceiptSection = React.lazy(() => import('./receipt-section'));
const PurchasePromptGuard = React.lazy(() => import('./purchase-prompt-guard'));
const AddDetailsSection = React.lazy(() => import('./add-details-section'));
const TierSelectionSection = React.lazy(
  () => import('./tier-selection-section'),
);

type PurchaseActivationFlowStyles = {
  layout: PurchaseActivationFlowLayoutStyles;
  reviewSection: ReviewSectionStyles;
  addDetailsSection: AddDetailsSectionStyles;
  confirmSection: ConfirmSectionStyles;
  tierSelectionSection: TierSelectionSectionStyles;
  addonSection: AddonSectionStyles;
  receiptSection: ReceiptSectionStyles;
};

export function PurchaseActivationFlow({
  consultationId,
  logger,
  routes,
  styles,
  thankYouIcon,
  downloadAppPrompt,
}: {
  consultationId: string;
  logger: Logger;
  routes: PurchaseActivationFlowRoutes;
  styles: PurchaseActivationFlowStyles;
  thankYouIcon: React.ReactElement;
  downloadAppPrompt?: React.ReactNode;
}) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/receipt`}>
        <ReceiptSection
          routes={routes}
          styles={styles.receiptSection}
          logger={logger}
          downloadAppPrompt={downloadAppPrompt}
          thankYouIcon={thankYouIcon}
        />
      </Route>
      <PurchasePromptGuard
        consultationId={consultationId}
        logger={logger}
        routes={routes}
      >
        <PurchaseActivationFlowProvider
          consultationId={consultationId}
          logger={logger}
          routes={routes}
        >
          <Layout styles={styles.layout}>
            {/* Local suspense boundary here to keep the spinner within the activation flow as we load the different sections */}
            <React.Suspense
              fallback={
                <div className="flex justify-center p-5">
                  <LoadingSpinner />
                </div>
              }
            >
              <Route path={`${path}/review`}>
                <ReviewSection styles={styles.reviewSection} />
              </Route>
              <Route path={`${path}/tier-selection`}>
                <TierSelectionSection styles={styles.tierSelectionSection} />
              </Route>
              <Route path={`${path}/add-ons`}>
                <AddonSection styles={styles.addonSection} />
              </Route>
              <Route path={`${path}/add-details`}>
                <AddDetailsSection styles={styles.addDetailsSection} />
              </Route>
              <Route path={`${path}/confirm`}>
                <ConfirmSection
                  styles={styles.confirmSection}
                  logger={logger}
                />
              </Route>
            </React.Suspense>
          </Layout>
        </PurchaseActivationFlowProvider>
      </PurchasePromptGuard>
    </Switch>
  );
}
