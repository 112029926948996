import { Box, Img } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '../../hooks';
import { SelectImageProps } from './types';
import { Typography } from '../typography';
import { Button, ButtonLevel, ButtonPalette } from '../button';
import { useThemeExtension } from '../../theme/shared';
import { ImagePlaceholder } from './image-placeholder';
import { ImageInstructions } from './image-instructions';

export const SelectImage = ({
  title,
  styles,
  placeholderUrl,
  triggerInput,
  description,
  triggerTakePhoto,
  reviewInstructions,
}: SelectImageProps): JSX.Element => {
  const { isMobile } = useResponsive();

  const buttonLevel = useThemeExtension<ButtonLevel>(
    'image-upload.selectButtonStyle',
    'primary',
  );

  const buttonPalette = useThemeExtension<ButtonPalette>(
    'image-upload.selectButtonPalette',
    'default',
  );

  const textColor = useThemeExtension<string>('image-upload.textColor', '#000');

  const placeholderColor = useThemeExtension<string>(
    'image-upload.placeholderColor',
    '#000',
  );

  const iconStyle = useThemeExtension<'bold' | 'thin'>(
    'image-upload.iconStyle',
    'bold',
  );

  return (
    <Box sx={styles.sectionContainer}>
      {title && (
        <Box sx={styles.title}>
          <Typography isBold size="md" color={textColor}>
            {title}
          </Typography>
        </Box>
      )}
      {description && (
        <Box sx={styles.description}>
          <Typography size="large-paragraph">{description}</Typography>
        </Box>
      )}
      <Box sx={styles.imageActionContainer}>
        <Box sx={styles.placeholder}>
          {placeholderUrl ? (
            <Box maxW="100%" maxH="100%">
              <Img src={placeholderUrl} alt="placeholder" />
            </Box>
          ) : (
            <Box
              sx={styles.placeholderImage}
              onClick={() => {
                if (isMobile) {
                  triggerTakePhoto && triggerTakePhoto();
                } else {
                  triggerInput && triggerInput();
                }
              }}
            >
              <ImagePlaceholder
                fill={placeholderColor}
                stroke={placeholderColor}
                variant={iconStyle}
              />
            </Box>
          )}
        </Box>
        <Box py="0.5rem">
          <ImageInstructions
            textColor={textColor}
            reviewInstructions={reviewInstructions}
          />
        </Box>
        <Box sx={styles.actions}>
          {triggerInput && (
            <Button
              level={buttonLevel}
              palette={buttonPalette}
              isFullWidth
              onClick={triggerInput}
            >
              <FormattedMessage
                defaultMessage="{isMobile, select, true {Take} other {Select}} a photo"
                description="Button label for taking a photo"
                values={{ isMobile }}
              />
            </Button>
          )}
          {triggerTakePhoto && !isMobile && (
            <Button
              level={buttonLevel}
              palette={buttonPalette}
              isFullWidth
              onClick={triggerTakePhoto}
            >
              <FormattedMessage
                defaultMessage="Take a photo"
                description="Button label for taking a photo"
              />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
