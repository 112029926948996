import { Markdown, Modal, Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { ReactComponent as QuestionCircleIcon } from '../assets/question-circle.svg';
import clsx from 'clsx';
import { Maybe } from '@customer-frontend/graphql-types';

type QuizQuestionPopupViewProps = {
  linkOutText?: Maybe<string>;
  title?: Maybe<string>;
  content?: Maybe<string>;
  imgUrl?: Maybe<string>;
  inline?: boolean;
};

export const QuestionPopup: React.FunctionComponent<
  QuizQuestionPopupViewProps
> = ({ linkOutText, title, content, imgUrl, inline }) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const togglePopup = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    // prevent parent question button onClick from triggering
    e.preventDefault();
    setShowPopup(!showPopup);
  };

  return (
    <>
      <div
        className={clsx('flex items-center cursor-pointer ', {
          'absolute top-3 right-3 h-8 w-8 justify-center': inline,
          'mb-4': !inline,
        })}
        onClick={togglePopup}
      >
        <div className="flex justify-center items-center">
          <QuestionCircleIcon />
        </div>
        {linkOutText && (
          <div className="ml-2">
            <Typography size="paragraph">{linkOutText}</Typography>
          </div>
        )}
      </div>
      <Modal
        title={title ?? ''}
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <div className="text-left cursor-default">
          {imgUrl && (
            <img src={imgUrl} className="mx-auto mb-4 h-auto max-h-52" />
          )}
          <Markdown src={content ?? ''} />
        </div>
      </Modal>
    </>
  );
};
