import {
  gql,
  QueryTuple,
  useLazyQuery,
  OperationVariables,
  LazyQueryHookOptions,
} from '@apollo/client';

const refreshUserPaymentDataDocument = gql`
  query RefreshUserPaymentDataProfile {
    profile {
      id
      zip {
        valid
      }
      savedPaymentMethods {
        id
        createdAt
        default
        gateway
        ... on SavedCardPaymentMethod {
          brand
          mask
          expiry
        }
        ... on SavedPayPalPaymentMethod {
          email
        }
        ... on SavedZipPaymentMethod {
          valid
        }
      }
    }
  }
`;

export const useRefreshPaymentDataQuery = (
  options?: LazyQueryHookOptions<Response, OperationVariables>,
): QueryTuple<Response, OperationVariables> =>
  useLazyQuery<Response, OperationVariables>(
    refreshUserPaymentDataDocument,
    options,
  );
