import { gql } from '@apollo/client';

export const subscribePlanV2Document = gql`
  mutation SubscribePlanV2($subscriptionPlanId: String!, $token: String) {
    subscribePlanV2(subscriptionPlanId: $subscriptionPlanId, token: $token) {
      id
      subscriptionOrders(pagination: { pageSize: 1, skip: 0 }) {
        id
        order {
          id
          lineItems {
            id
            variant {
              id
              price
            }
          }
        }
      }
    }
  }
`;
