import { useAuth } from './provider';
import { useEffectOnce } from 'react-use';

export function useClearAuth(): void {
  const auth = useAuth();
  useEffectOnce(() => {
    if (auth.isLoggedIn) {
      auth.logout();
    }
  });
}
