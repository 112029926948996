import { datadogRum } from '@datadog/browser-rum';
import { Brand, AppEnv } from '@customer-frontend/types';

export type RumConfig = {
  brand: Brand;
  apiUrl: string;
  appEnv: AppEnv;
  datadogRumApplicationId: string;
  datadogRumToken: string;
};
export function createRum(config: RumConfig): void {
  const service = `${config.brand}-ui-${config.appEnv}`;

  datadogRum.init({
    applicationId: config.datadogRumApplicationId,
    clientToken: config.datadogRumToken,
    site: 'datadoghq.com',
    service,
    env: config.appEnv,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0, // Disable session replays
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask',
    enableExperimentalFeatures: ['feature_flags'],
    allowedTracingUrls: [config.apiUrl],
    version: process.env.REACT_APP_RELEASE_VERSION,
  });
}

export function setRumUser(userId: string): void {
  datadogRum.setUser({ id: userId });
}

export function clearRumUser(): void {
  datadogRum.clearUser();
}

export function addRumError(
  ...params: Parameters<typeof datadogRum.addError>
): void {
  datadogRum.addError(...params);
}
