import { getWithFallbacks } from '../../shared/fonts';
import {
  getLinkButtonVariants,
  getListButtonVariants,
  getToggleTabsVariants,
} from '../../shared/components';
import { colors } from '../palette';

export const button = {
  baseStyle: {
    fontFamily: getWithFallbacks('PP Neue Montreal'),
    fontWeight: 600,
    borderRadius: '7.5rem', // 120px
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 0.188rem ${colors.secondary.yellow[300]}`, // 3px
    },
    _disabled: {
      opacity: 0.3,
    },
  },
  sizes: {
    sm: {
      h: '2.125rem', // 34px
      px: '1rem', // 16px
    },
    md: {
      h: '3rem', // 48px
      px: '1.5rem', // 24px
    },
    lg: {
      h: '3.5rem', // 56px
      px: '1.5rem', // 24px
    },
  },
  variants: {
    primary: {
      color: colors.neutral.white,
      bg: colors.primary[600],
      _hover: {
        bg: colors.primary[500],
        _disabled: {
          bg: colors.primary[600],
        },
      },
      _focus: {
        bg: colors.primary[500],
      },
    },
    'primary-alternate': {
      color: colors.neutral.white,
      bg: colors.primary[600],
      _hover: {
        bg: colors.primary[500],
        _disabled: {
          bg: colors.primary[600],
        },
      },
      _focus: {
        bg: colors.primary[500],
      },
    },
    'primary-white': {
      color: colors.primary[600],
      bg: 'white',
      _hover: {
        _disabled: {
          bg: 'white',
        },
      },
      _focus: {
        boxShadow: `0 0 0 0.188rem ${colors.primary[400]}`, // 3px
      },
    },
    secondary: {
      color: colors.primary[600],
      bg: 'transparent',
      border: `0.063rem solid ${colors.primary[600]}`, // 1px
      _hover: {
        bg: colors.primary[200],
      },
      _focus: {
        bg: colors.primary[200],
      },
    },
    'secondary-alternate': {
      color: colors.primary[600],
      bg: 'transparent',
      border: `0.063rem solid ${colors.primary[600]}`, // 1px
      _hover: {
        bg: colors.primary[200],
      },
      _focus: {
        bg: colors.primary[200],
      },
    },
    'secondary-white': {
      color: 'white',
      bg: 'transparent',
      border: `0.063rem solid white`, // 1px
      _hover: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
      _focus: {
        bg: 'rgba(255, 255, 255, 0.3)',
        boxShadow: `0 0 0 0.188rem ${colors.primary[400]}`, // 3px
      },
      _disabled: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
    },
    ...getLinkButtonVariants({
      default: {
        color: colors.primary[600],
        hoverColor: colors.primary[600],
        focusColor: colors.primary[400],
        textDecoration: 'underline',
      },
      alternate: {
        color: colors.primary[600],
        hoverColor: colors.primary[600],
        focusColor: colors.primary[400],
        textDecoration: 'underline',
      },
      white: {
        color: 'white',
        hoverColor: colors.neutral[200],
        focusColor: colors.primary[300],
        textDecoration: 'underline',
      },
    }),
    ...getListButtonVariants({
      textColor: colors.neutral.black,
      focusColor: colors.primary[200],
      background: colors.neutral.white,
      focusBackground: colors.secondary.yellow[200],
      activeBackground: colors.secondary.yellow[200],
      border: {
        default: colors.neutral.black,
        hover: colors.primary[400],
        active: colors.neutral.black,
        error: colors.status.error[500],
      },
    }),
    ...getToggleTabsVariants({
      focusColor: colors.secondary.yellow[300],
      fontColor: colors.primary[600],
      selectedColor: colors.secondary.yellow[200],
      fontWeight: 600,
      borderColor: {
        default: colors.primary[400],
        selected: colors.primary[600],
      },
    }),
  },
};
