import { TreatedForWeightLossQuery } from '@customer-frontend/graphql-types';
import { useMemo } from 'react';
import { GeneralQuizVerificationFn } from './types';
import { gql, useQuery } from '@apollo/client';

export const useWeightLossSupportGeneralQuizVerification: GeneralQuizVerificationFn =
  () => {
    const { data, loading } = useQuery<TreatedForWeightLossQuery>(gql`
      query TreatedForWeightLoss {
        profile {
          id
          isBeingTreated(problemType: WEIGHT_LOSS)
        }
      }
    `);

    const profile = data?.profile;

    const verificationPassed = useMemo(() => {
      if (profile) {
        return !!profile.isBeingTreated;
      }
    }, [profile]);

    return { verificationPassed, verificationLoading: loading };
  };
