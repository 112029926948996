import {
  ButtonLevel,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { useSubscriptionPlans } from '@customer-frontend/services';
import { SubscriptionPlan } from '@customer-frontend/graphql-types';
import { NoActiveSubscriptions } from './no-active-subscriptions';
import { SubscriptionItem } from './subscription-item';

interface SubscriptionProps {
  contactEmail: string;
  cancelButtonLevel?: ButtonLevel;
}

const Subscriptions = ({
  contactEmail,
  cancelButtonLevel,
}: SubscriptionProps): React.ReactElement => {
  const { data, loading } = useSubscriptionPlans();
  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }
  const activeSubscriptions = data?.subscriptionPlans?.filter(
    (plan) => plan.status !== 'canceled',
  );
  const inactiveSubscriptions =
    data?.subscriptionPlans?.filter((plan) => plan.status === 'canceled') ?? [];

  const showInactiveSubs = inactiveSubscriptions.length !== 0;
  const showActiveSubs = activeSubscriptions?.length !== 0;

  const hasNoSubscriptions = !(showActiveSubs || showInactiveSubs);

  return (
    <div className="min-h-screen max-w-full mx-4">
      <div className="mx-auto w-full md:max-w-5xl">
        {hasNoSubscriptions && <NoActiveSubscriptions />}
        <div className="grid md:grid-cols-2 mb-4">
          {showActiveSubs && (
            <>
              <div className="md:col-span-2 pb-4">
                <Typography isBold size="md">
                  Active subscriptions
                </Typography>
              </div>
              {activeSubscriptions?.map((item: SubscriptionPlan) => (
                <div key={item.id} className="mr-2 mb-2">
                  <SubscriptionItem
                    subscriptionPlan={item}
                    cancelButtonLevel={cancelButtonLevel}
                  />
                </div>
              ))}
              <div className="md:col-span-2 py-4">
                <Typography isBold size="md">
                  Address and payment details
                </Typography>
              </div>
              <div className="md:col-span-2 pb-6">
                <Typography size="medium-paragraph">
                  To change your shipping address, billing address or payment
                  method, please email{' '}
                  <a href={`mailto:${contactEmail}`}>{contactEmail}</a>. Our
                  team will get to you within 24 hours.
                </Typography>
              </div>
            </>
          )}
          {showInactiveSubs && (
            <>
              <div className="md:col-span-2 pb-4">
                <Typography isBold size="md">
                  Cancelled subscriptions
                </Typography>
              </div>
              {inactiveSubscriptions?.map((item: SubscriptionPlan) => (
                <div key={item.id} className="mr-2 mb-2">
                  <SubscriptionItem key={item.id} subscriptionPlan={item} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
