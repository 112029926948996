import React, { useEffect, useState } from 'react';
import { gql, useQuery, ApolloClient, useApolloClient } from '@apollo/client';
import {
  ChatProviderQuery,
  ChatTokenQuery,
} from '@customer-frontend/graphql-types';
import { Logger as StreamLogger, StreamChat } from 'stream-chat';
import { Logger } from '@customer-frontend/logger';
import { useAuth } from '@customer-frontend/auth';

interface ChatProviderProps {
  streamAppKey: string;
  logger: Logger;
  children?: React.ReactNode;
}

const tokenProvider =
  (apolloClient: ApolloClient<unknown>, isLoggedIn: () => boolean) =>
  async () => {
    if (!isLoggedIn()) {
      throw new Error('not logged in, cannot fetch token');
    }
    const resp = await apolloClient.query<ChatTokenQuery>({
      query: gql`
        query ChatToken {
          chatToken
        }
      `,
      fetchPolicy: 'network-only',
    });
    if (resp.error) {
      throw resp.error;
    }
    if (!resp.data.chatToken) {
      throw new Error('user does not have a chat token');
    }
    return resp.data.chatToken;
  };

type EucChatContext = {
  client: StreamChat | null;
  refreshClient: () => void;
};

const EucChatContext = React.createContext<EucChatContext>({
  client: null,
  refreshClient() {
    // noop
  },
});

export const ChatProvider: React.FC<ChatProviderProps> = ({
  streamAppKey,
  logger,
  children,
}) => {
  const { isLoggedIn } = useAuth();
  const apolloClient = useApolloClient();
  const { data } = useQuery<ChatProviderQuery>(
    gql`
      query ChatProvider {
        profile {
          id
        }
      }
    `,
    { skip: !isLoggedIn },
  );

  const [client, setClient] = useState<StreamChat | null>(null);
  const [refreshClientCount, setRefreshClientCount] = useState(0);
  const refreshClient = React.useRef(() => {
    setRefreshClientCount(refreshClientCount + 1);
  });

  useEffect(() => {
    if (!streamAppKey || !data?.profile?.id) {
      return;
    }

    const streamLogger: StreamLogger = (logLevel, message, extraData) => {
      if (logLevel !== 'error' && logLevel !== 'warn') {
        return;
      }
      logger.log(message, extraData, logLevel);
    };
    const newClient = new StreamChat(streamAppKey, {
      logger: streamLogger,
      timeout: 10000,
    });

    let didUserConnectInterrupt = false;
    let errorConnectingUser = false;
    const connectUser = newClient
      .connectUser(
        {
          id: data.profile.id,
        },
        tokenProvider(apolloClient, () => isLoggedIn),
      )
      .catch((e) => {
        if (e instanceof Error) {
          if (e.message.includes('user does not have a chat token')) {
            errorConnectingUser = true;
            return;
          }
        }
        logger.error('Failed to connect user', e);
      })
      .then(() => {
        if (!didUserConnectInterrupt && !errorConnectingUser) {
          setClient(newClient);
        }
      });

    return () => {
      didUserConnectInterrupt = true;
      connectUser.then(() => {
        setClient(null);
        newClient.disconnectUser().catch((e) => {
          logger.error('Failed to disconnect user', e);
        });
      });
    };
  }, [
    logger,
    streamAppKey,
    data?.profile?.id,
    apolloClient,
    isLoggedIn,
    refreshClientCount,
  ]);

  const providerValue = React.useMemo((): EucChatContext => {
    if (!client) {
      return {
        client: null,
        refreshClient() {
          refreshClient.current();
        },
      };
    }
    return {
      client,
      refreshClient() {
        refreshClient.current();
      },
    };
  }, [client]);

  return (
    <EucChatContext.Provider value={providerValue}>
      {children}
    </EucChatContext.Provider>
  );
};

export const useEucChatContext = (): EucChatContext =>
  React.useContext(EucChatContext);
