import { gql } from '@apollo/client';

export const requestReviewTreatmentSupportDocument = gql`
  mutation RequestReviewTreatmentSupport(
    $body: String!
    $category: TreatmentReviewCategory!
    $subject: String!
    $treatmentId: String!
    $quizApplicationId: String
  ) {
    requestReviewTreatmentSupport(
      body: $body
      category: $category
      subject: $subject
      treatmentId: $treatmentId
      quizApplicationId: $quizApplicationId
    ) {
      id
    }
  }
`;
