import React from 'react';
import { gql } from '@apollo/client';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Avatar, Typography, LinkButton } from '@eucalyptusvc/design-system';
import { mustBe } from '@customer-frontend/utils';
import { formatDoctorName } from '../utils';
import { PrescriberType, TitleType } from '../types';
import {
  DoctorInfoComponentFragment,
  Maybe,
} from '@customer-frontend/graphql-types';

interface Props {
  doctor: Maybe<DoctorInfoComponentFragment>;
  details?: (string | null | undefined)[] | null;
  className?: string | null;
  titleType?: TitleType;
  handleReadBioClick?: () => void;
}

function DoctorInfo({
  doctor,
  details,
  className,
  titleType = PrescriberType.DOCTOR,
  handleReadBioClick,
}: Props): React.ReactElement | null {
  if (!doctor) {
    return null;
  }

  return (
    <div className={clsx('flex items-center gap-4', className)}>
      <div className="flex-none">
        <Avatar size="lg" src={doctor.avatar?.url} lastName={doctor.lastName} />
      </div>

      <div>
        <Typography size="large-paragraph" isBold>
          {formatDoctorName(doctor, titleType)}
        </Typography>

        {details?.filter(mustBe).map((detail) => (
          <Typography key={detail} size="paragraph">
            {detail}
          </Typography>
        ))}

        {!!handleReadBioClick && (
          <LinkButton onClick={handleReadBioClick} size="sm">
            <FormattedMessage defaultMessage="Read bio" />
          </LinkButton>
        )}
      </div>
    </div>
  );
}

DoctorInfo.fragment = gql`
  fragment DoctorInfoComponent on User {
    id
    fullName
    fullClinicianName
    shortClinicianName
    lastName
    bio
    avatar {
      id
      url
    }
    provider {
      id
      clinicianTitle
      qualifications
    }
  }
`;

export { DoctorInfo };
