import { Brand } from '@customer-frontend/types';
import React, { useContext } from 'react';
import { getBrandTheme, ThemeContext } from './theme';
import { defaultTheme } from './theme/default-theme';

const EducationThemeContext = React.createContext<ThemeContext>({
  brand: defaultTheme,
});

export const useEducationTheme = (): ThemeContext =>
  useContext(EducationThemeContext);

export const EducationThemeProvider = ({
  children,
  brand,
}: {
  children: React.ReactNode;
  brand: Brand;
}): React.ReactElement => {
  return (
    <EducationThemeContext.Provider
      value={{
        brand: getBrandTheme(brand) || defaultTheme,
      }}
    >
      {children}
    </EducationThemeContext.Provider>
  );
};
