import { gql, useSuspenseQuery } from '@apollo/client';
import { useAuth } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import { useFeatureFlagJson } from '@customer-frontend/feature-flags';
import {
  UseFlexiEnabledQuery,
  UseFlexiEnabledQueryVariables,
} from '@customer-frontend/graphql-types';
import { z } from 'zod';

export function useFlexiEnabled(consultationId: string): boolean {
  const config = getConfig();
  const schema = z.array(z.enum(['WEIGHT_LOSS']));
  const auth = useAuth();

  const { data } = useSuspenseQuery<
    UseFlexiEnabledQuery,
    UseFlexiEnabledQueryVariables
  >(
    gql`
      query UseFlexiEnabled($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          type
          stage
        }
      }
    `,
    {
      skip: config.flexiBrand || !auth.isLoggedIn,
      variables: { consultationId },
      fetchPolicy: 'cache-first',
    },
  );

  const bnplProblemTypes = schema.parse(
    useFeatureFlagJson('FLEXI_BNPL_PROBLEM_TYPES', {
      disableExposureTracking: true,
      defaultValue: [],
    }),
  );

  const furConsultationProblemTypes = schema.parse(
    useFeatureFlagJson('FLEXI_FUR_CONSULTATION_PROBLEM_TYPES', {
      disableExposureTracking: true,
      defaultValue: [],
    }),
  );

  const consultationStage = data?.consultation?.stage;
  const consultationProblemType = data?.consultation?.type;

  if (config.flexiBrand) {
    return true;
  }

  switch (consultationStage) {
    case 'INITIAL':
      return bnplProblemTypes.some(
        (problemType) => problemType === consultationProblemType,
      );

    case 'FOLLOW_UP':
    case 'REVIEW':
      return furConsultationProblemTypes.some(
        (problemType) => problemType === consultationProblemType,
      );

    default:
      return false;
  }
}
