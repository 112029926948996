import {
  BackButtonBehaviour,
  SetBackButtonBehaviourContext,
  useDownloadAppBannerContext,
} from '@customer-frontend/services';
import { ReactComponent as JuniperLogo } from '../../../assets/juniper-logo.svg';
import clsx from 'clsx';
import React from 'react';
import { logger } from 'utils/logging';
import { NavigationAvatar } from '../avatar';
import { Progress, SetProgressContext } from '@customer-frontend/navbar';
import { routes } from '../../../utils/routes';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { MobileAppBanner } from './mobile-app-banner';
import { useAuth } from '@customer-frontend/auth';

function ProgressIndicator(props: {
  progress: Progress | null;
}): React.ReactElement | null {
  if (!props.progress) {
    return null;
  }

  if (props.progress.currentStepIndex < 0 || props.progress.lastStepIndex < 0) {
    logger.warn('Indices must be greater than or equal to 0.');
    return null;
  }

  if (props.progress.currentStepIndex > props.progress.lastStepIndex) {
    logger.warn('Current step index cannot be greater than last step index.');
    return null;
  }

  const widthPercentage =
    (100 * (props.progress.currentStepIndex + 1)) /
    (props.progress.lastStepIndex + 1);

  return (
    <div className="w-full h-1 absolute">
      <div className="w-full absolute h-full" />
      <div
        style={{ width: `${widthPercentage}%` }}
        className="absolute h-full bg-secondary-cream-300"
      />
    </div>
  );
}

function BackButton(props: {
  onClick: BackButtonBehaviour;
}): React.ReactElement {
  return (
    <button
      className={clsx(
        'flex',
        'h-12',
        'w-12',
        'items-center',
        'justify-center',
        'cursor-pointer',
        'font-header',
        'text-2xl',
        'text-white',
      )}
      onClick={props.onClick.callback}
    >
      {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
      ←
    </button>
  );
}

export function NavBarLayout(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const subClasses = 'w-1/3 flex items-center';
  const [progress, setProgress] = React.useState<Progress | null>(null);
  const [backButtonBehaviour, setBackButtonBehaviour] =
    React.useState<BackButtonBehaviour | null>(null);
  const { formatMessage } = useIntl();
  const { show: shouldShowDownloadBanner } = useDownloadAppBannerContext();
  const { isImpersonatingUser } = useAuth();

  return (
    <>
      <SetBackButtonBehaviourContext.Provider value={setBackButtonBehaviour}>
        <SetProgressContext.Provider value={setProgress}>
          <section
            className={clsx(
              shouldShowDownloadBanner ? 'pt-30' : 'pt-18',
              'absolute w-screen sm:w-full mx-auto h-full min-h-full',
            )}
          >
            {props.children}
          </section>
        </SetProgressContext.Provider>
      </SetBackButtonBehaviourContext.Provider>
      {shouldShowDownloadBanner && !isImpersonatingUser && <MobileAppBanner />}
      <div
        className={clsx(
          (shouldShowDownloadBanner || isImpersonatingUser) && 'mt-12',
          'fixed w-full z-10',
        )}
      >
        <div className="select-none bg-primary-500 flex h-18">
          <div className={clsx(subClasses, 'justify-start')}>
            {backButtonBehaviour !== null && (
              <div className="mx-2 sm:mx-3">
                <BackButton onClick={backButtonBehaviour} />
              </div>
            )}
          </div>
          <div className={clsx(subClasses, 'justify-center')}>
            <Link
              aria-label={formatMessage({
                defaultMessage: 'Navigate home',
                description:
                  'Aria label for a link that navigates to the home page',
              })}
              to={routes.profile}
            >
              <JuniperLogo
                aria-label={formatMessage({
                  defaultMessage: 'Juniper logo',
                  description: 'Aria label for the Juniper logo',
                })}
              />
            </Link>
          </div>
          <div className={clsx(subClasses, 'justify-end')}>
            <NavigationAvatar />
          </div>
        </div>
        <ProgressIndicator progress={progress} />
      </div>
    </>
  );
}
