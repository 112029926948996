import React from 'react';
import { useFormContext } from 'react-hook-form';
import { QuestionProps } from './types';
import { ControlledRadioButton } from './submit-radio';
import { QuestionPopup } from '../question-popup';

function sortOptions<T extends { position?: number | null }>(
  options: T[] = [],
): T[] {
  return [...options] //Prisma array type is readonly so it needs to be spread into new array
    .sort((a, b) => {
      if (a?.position && b?.position) {
        return a.position - b.position;
      }
      return 0;
    });
}

export function RadioQuestionInput({
  question,
  isLoading,
}: QuestionProps): React.ReactElement {
  const { control } = useFormContext();
  const options = sortOptions(question.questionOptions);

  return (
    <div>
      {options.map(
        ({ option, popup, id }) =>
          option && (
            <div className="mb-3 last:mb-0 relative" key={option}>
              <ControlledRadioButton
                name={question.id}
                control={control}
                value={id}
                label={option}
                disabled={isLoading}
              >
                {popup && <QuestionPopup inline {...popup} />}
              </ControlledRadioButton>
            </div>
          ),
      )}
    </div>
  );
}
