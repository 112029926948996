import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import {
  Maybe,
  PurchaseOrderMutation,
  usePurchaseOrderMutation,
} from '@customer-frontend/graphql-types';
import { gql } from '@apollo/client';
import { useState } from 'react';
import { useGetStripeToken } from '../use-get-stripe-token';
import {
  PurchaseOrderPayload,
  PurchaseOrderReturnType,
  StripePaymentData,
  UsePurchaseOrderProps,
} from './types';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { useNotification } from '@eucalyptusvc/design-system';
import { useIntl } from 'react-intl';

// Mutation used for purchasing masterclass
export const masterclassPaymentDocument = gql`
  mutation PurchaseOrder($couponCode: String, $token: String) {
    purchaseOrder(couponCode: $couponCode, token: $token) {
      id
      status
    }
  }
`;

export const useMasterclassPurchaseHandler = ({
  userId,
  detailsChanged,
  onPaymentSuccess,
}: UsePurchaseOrderProps): PurchaseOrderReturnType => {
  const events = useEventService();
  const config = getConfig();
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const getStripeToken = useGetStripeToken();

  const [loading, setLoading] = useState<boolean>(false);

  const [purchaseOrder] = usePurchaseOrderMutation();

  const handleStripePayment = async (
    payload: PurchaseOrderPayload,
  ): Promise<PurchaseOrderMutation['purchaseOrder']> => {
    let stripePaymentData: Maybe<StripePaymentData>;

    if (detailsChanged) {
      const { token, error } = await getStripeToken(payload.stripeEvent);

      if (error || !token?.card) {
        throw new Error(error?.message ?? 'Payment method is invalid.');
      }

      stripePaymentData = {
        token: token.id,
      };
    }

    try {
      const { data } = await purchaseOrder({
        variables: {
          couponCode: payload.couponCode,
          ...stripePaymentData,
        },
      });
      payload.stripeEvent?.complete('success');
      return data?.purchaseOrder;
    } catch (e) {
      payload.stripeEvent?.complete('fail');
      throw e;
    }
  };

  const handlePayment = async (
    payload: PurchaseOrderPayload,
  ): Promise<void> => {
    try {
      setLoading(true);

      const purchaseOrder = await handleStripePayment(payload);

      events.order.paid({
        ...payload,
        country: config.country,
        currency: config.currency,
        orderId: purchaseOrder?.id ?? '',
        userId,
        value: payload.value ? (payload.value / 100).toFixed(2) : '0.00',
      });
      onPaymentSuccess();
    } catch (error) {
      const descriptions = getErrorMessageDescriptorsFromError(error);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePayment,
    loading,
  };
};
