import { Accordion as ChakraAccordion } from '@chakra-ui/react';
import { AccordionItem } from './accordion-item';
import { AccordionProps, AccordionPalette } from './types';
import { useThemeExtension } from '../../theme/shared';
import {
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventService,
} from '@customer-frontend/events';

export const Accordion = ({
  size = 'md',
  title,
  content,
  palette,
  openByDefault = false,
  headingLevel = 3,
  trimBottom,
  eventElementName,
  index,
  onChange,
}: AccordionProps): React.ReactElement => {
  const themeStyle = useThemeExtension<AccordionPalette>(
    'accordion.defaultStyle',
    'default',
  );
  const variant = palette || themeStyle;
  const eventService = useEventService();

  return (
    <ChakraAccordion
      allowToggle
      variant={variant}
      defaultIndex={openByDefault ? 0 : undefined}
      index={index}
      onChange={(i) => {
        if (onChange) {
          onChange();
        }

        if (!eventElementName) {
          return;
        }
        let interaction: UIInteractionInteraction =
          UIInteractionInteraction.OPENED;
        if (i === -1) {
          interaction = UIInteractionInteraction.CLOSED;
        }
        eventService.uiInteractionTracked({
          elementName: eventElementName,
          elementType: UIInteractionElementType.ACCORDION,
          interaction,
          pageUrl: window.location.toString(),
        });
      }}
    >
      <AccordionItem
        title={title}
        content={content}
        size={size}
        headingLevel={headingLevel}
        trimBottom={trimBottom}
      />
    </ChakraAccordion>
  );
};
