import { ObservedMeasurement } from '@customer-frontend/graphql-types';
import React, { FC } from 'react';
import { Dot, DotProps } from 'recharts';
import { useTrackerTheme } from '../../../provider';
import { TrackerGraphPoint, transformMeasurementToGraphPoint } from '../utils';

type CustomizedDotProps = DotProps & {
  selected?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>;
  payload?: TrackerGraphPoint;
};

export const CustomizedDot: FC<CustomizedDotProps> = ({
  cx,
  cy,
  payload,
  selected,
}): React.ReactElement<SVGElement> => {
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.enhancedTrackerChart;

  const selectedGraphPoint = selected
    ? transformMeasurementToGraphPoint(selected)
    : undefined;
  const isSelected =
    payload?.effectiveFrom === selectedGraphPoint?.effectiveFrom;

  const dotTheme = isSelected ? trackerTheme?.dotSelected : trackerTheme?.dot;

  return (
    <>
      {isSelected && (
        <Dot
          cx={cx}
          cy={cy}
          r={trackerTheme?.dotSelected?.outer.radius}
          fill={trackerTheme?.dotSelected?.outer.fill}
        />
      )}

      <Dot
        cx={cx}
        cy={cy}
        r={dotTheme?.radius}
        stroke={dotTheme?.stroke}
        strokeWidth={dotTheme?.strokeWidth}
        fill={dotTheme?.fill}
      />
    </>
  );
};
