import { defineMessages, defineMessage, MessageDescriptor } from 'react-intl';

export const DEFAULT_MESSAGE = defineMessage({
  defaultMessage: 'Thank you for completing your first tracking',
});

const gainedWeightBelowCurve = defineMessages({
  one: {
    defaultMessage: 'Life has ups and downs, so does weight. You’ve got this',
  },
  two: {
    defaultMessage: 'Don’t let a small gain get you down, keep going',
  },
  three: {
    defaultMessage: 'Fluctuations are fine. Don’t let this throw you off',
  },
  four: {
    defaultMessage: 'Don’t stress, occasional weight gains are natural',
  },
});

export const GAINED_WEIGHT_BELOW_CURVE: MessageDescriptor[] = Object.values(
  gainedWeightBelowCurve,
);

const gainedWeightAboveCurve = defineMessages({
  one: {
    defaultMessage: 'Don’t focus on fluctuations, focus on healthy habits',
  },
  two: {
    defaultMessage: 'An occasional fluctuation is totally normal, keep going',
  },
  three: {
    defaultMessage: 'Gains happen. Let’s try to get back on track',
  },
  four: {
    defaultMessage: 'You’ve hit a little bump on your journey. That’s okay',
  },
});

export const GAINED_WEIGHT_ABOVE_CURVE: MessageDescriptor[] = Object.values(
  gainedWeightAboveCurve,
);

const lostWeightBelowCurve = defineMessages({
  one: {
    defaultMessage: 'Great progress, give yourself a pat on the back',
  },
  two: {
    defaultMessage: 'Well done! You’re exceeding our expectations',
  },
  three: {
    defaultMessage: 'You’re beating our average! Keep it up',
  },
  four: {
    defaultMessage: 'Impressive! Keep doing what you’re doing',
  },
});

export const LOST_WEIGHT_BELOW_CURVE: MessageDescriptor[] =
  Object.values(lostWeightBelowCurve);

const lostWeightAboveCurve = defineMessages({
  one: {
    defaultMessage: 'You’re heading in the right direction, keep it going',
  },
  two: {
    defaultMessage: 'You’re getting back on track, wonderful work',
  },
  three: {
    defaultMessage: 'Your weight is going down and things are looking up',
  },
  four: {
    defaultMessage: 'This loss is a win for your weight. Well done!',
  },
});

export const LOST_WEIGHT_ABOVE_CURVE: MessageDescriptor[] =
  Object.values(lostWeightAboveCurve);

const noChange = defineMessages({
  one: {
    defaultMessage:
      'No change doesn’t mean no progress. What else did you achieve this week?',
  },
  two: {
    defaultMessage:
      'An occasional week without weight loss is normal, don’t stress',
  },
});

export const NO_CHANGE: MessageDescriptor[] = Object.values(noChange);
