import { type ReactElement, useState } from 'react';
import { gql } from '@apollo/client';
import { DoctorInfo } from '../doctor-info';
import {
  Card,
  Modal,
  Typography,
  type CardPalette,
} from '@eucalyptusvc/design-system';
import { DoctorBioComponentFragment } from '@customer-frontend/graphql-types';

type DoctorBioProps = {
  doctor: DoctorBioComponentFragment;
  palette?: CardPalette;
};

export function DoctorBio({ doctor, palette }: DoctorBioProps): ReactElement {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const toggleModalOpen = (): void => {
    setModalOpen((isOpen) => !isOpen);
  };

  return (
    <Card palette={palette}>
      <DoctorInfo
        doctor={doctor}
        details={[
          doctor.provider?.clinicianTitle,
          doctor.provider?.qualifications,
        ]}
        handleReadBioClick={toggleModalOpen}
      />

      <Modal isOpen={modalOpen} onClose={toggleModalOpen}>
        <div className="space-y-4">
          <DoctorInfo
            doctor={doctor}
            details={[
              doctor.provider?.clinicianTitle,
              doctor.provider?.qualifications,
            ]}
          />

          <Typography size="medium-paragraph">{doctor.bio}</Typography>
        </div>
      </Modal>
    </Card>
  );
}

DoctorBio.fragment = gql`
  fragment DoctorBioComponent on User {
    id
    bio
    provider {
      clinicianTitle
      qualifications
    }
    ...DoctorInfoComponent
  }

  ${DoctorInfo.fragment}
`;
