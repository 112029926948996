import { useIntl } from 'react-intl';
import { Checkbox, OptionPalette } from '@eucalyptusvc/design-system';
import { FieldValue, FieldValues, UseFormMethods } from 'react-hook-form';
import { mustBeTrue, scrollToRef } from '@customer-frontend/utils';
import { useEffect, useRef } from 'react';

export type ReviewedInformationFormFields = {
  hasConfirmedSafetyInformation: boolean;
};

type ConfirmReviewedInformationFormProps = {
  form: UseFormMethods<
    FieldValue<FieldValues> & { hasConfirmedSafetyInformation: boolean }
  >;
  palette?: OptionPalette;
};

export function ConfirmReviewedInformationForm({
  form,
  palette,
}: ConfirmReviewedInformationFormProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (form.errors.hasConfirmedSafetyInformation?.message) {
      scrollToRef(formRef, 150);
    }
  }, [form.errors.hasConfirmedSafetyInformation?.message]);

  return (
    <div ref={formRef}>
      <Checkbox
        palette={palette ?? 'default'}
        isChecked={false}
        ref={form.register({
          ...mustBeTrue(
            formatMessage({
              defaultMessage:
                'To continue, you must confirm you have reviewed the clinical and safety information',
              description:
                'Error message shown when patient has not confirmed they have reviewed their treatment information',
            }),
          ),
        })}
        name="hasConfirmedSafetyInformation"
        errorMessage={form.errors.hasConfirmedSafetyInformation?.message}
        label={formatMessage({
          defaultMessage:
            'I confirm I have read and understood the clinical and safety information regarding my treatment.',
          description:
            'Checkbox label explaining that the user is confirming they have read the safety information and are happy to proceed',
        })}
      />
    </div>
  );
}
