import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, Modal } from '@eucalyptusvc/design-system';
import { ReactComponent as QuestionMark } from './assets/question-filled.svg';
import { getConfig } from '@customer-frontend/config';

export const WhyWeNeedAddress: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { helpArticles, countryCode } = getConfig();

  return (
    <>
      <div
        className="flex gap-2 cursor-pointer items-center"
        onClick={() => setIsOpen(true)}
      >
        <QuestionMark />
        <Typography size="paragraph" decoration="underline" isBold>
          <FormattedMessage defaultMessage="Why we need your residential address" />
        </Typography>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <Typography size="md" isBold>
            <FormattedMessage defaultMessage="Why we need your residential address" />
          </Typography>
        }
      >
        <div className="flex flex-col gap-4">
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="We need your residential address to identify your <b>Individual Healthcare Identifier (IHI)</b>. We require your IHI to generate an electronic prescription if your {isGb, select, true {prescriber} other {practitioner}} prescribes treatment."
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
                isGb: countryCode === 'GB',
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="For most Australian residents, we can identify your IHI using the <b>address</b> linked to your Medicare card or IHI."
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="If you need assistance finding your IHI details, follow the instructions <a>here</a> or chat with our team via the chat button on the bottom right corner of your screen."
              values={{
                a: (chunks) => (
                  <strong>
                    <a
                      href={helpArticles?.healthcareIdentifier}
                      target="_blank"
                      rel="noreferrer"
                      className="text-link"
                    >
                      {chunks}
                    </a>
                  </strong>
                ),
              }}
            />
          </Typography>
        </div>
      </Modal>
    </>
  );
};
