import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { useWeightProgramName } from '@customer-frontend/utils';
import {
  AccordionList,
  AccordionPalette,
  Typography,
} from '@eucalyptusvc/design-system';
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessage,
  useIntl,
} from 'react-intl';

const FormattedListItem = ({
  content,
}: {
  content: MessageDescriptor | string;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <li>
      <div className="flex gap-1 px-2">
        <div className="w-2">
          {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
          •
        </div>
        <div>
          {typeof content === 'string' ? (
            <Typography size="medium-paragraph">{content}</Typography>
          ) : (
            <Typography size="medium-paragraph">
              {formatMessage(content)}
            </Typography>
          )}
        </div>
      </div>
    </li>
  );
};

const AUSTRALIAN_HEALTH_INSURANCE_PROVIDERS = [
  'AHM',
  'Bupa',
  'CBHS',
  'Defence',
  'Frank Health',
  'GU Health',
  'HBF',
  'HCF',
  'HIF',
  'Medibank Private',
  'NIB',
  'Nurses and Midwives Health',
  'Teachers Health Fund',
];

export const UpfrontConsultPayFAQs = ({
  palette,
  showLowDosageFaq,
}: {
  palette?: AccordionPalette;
  showLowDosageFaq?: boolean;
}): React.ReactElement => {
  const { title, helpArticles, prescriberType, hasMobileApp } = getConfig();
  const programName = useWeightProgramName();

  const faqs = [];

  faqs.push({
    title: (
      <Typography size="medium-paragraph" isBold>
        <FormattedMessage defaultMessage="What is your refund policy and cancellation procedure?" />
      </Typography>
    ),
    content: (
      <div className="space-y-4">
        <Typography size="large-paragraph" isBold>
          <FormattedMessage defaultMessage="Refunds" />
        </Typography>
        <Typography size="medium-paragraph" isBold decoration="underline">
          <FormattedMessage defaultMessage="Consults" />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="If you’re found unsuitable during your consult or decide not to proceed, we’ll refund you the full amount you’ve paid." />
        </Typography>
        <Typography size="medium-paragraph" isBold decoration="underline">
          <FormattedMessage
            defaultMessage="{programName} Program"
            values={{
              programName,
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="For {brand} members, the refund cutoff time is 11:59pm the day before the applicable order processing date of your subscription. You can view the processing date in your Profile and you’ll receive an email reminder a few days before each order processed."
            values={{ brand: title }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="Subject to rights that cannot be excluded under Australian Consumer Law, cancellations after the Refund Cutoff Time will not be subject to a refund." />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="In some circumstances, if you cancel your order after the refund cutoff time you may receive a refund. Those circumstances are:" />
        </Typography>
        <ul>
          <FormattedListItem
            content={defineMessage({
              defaultMessage:
                'Our partner pharmacy cannot fulfil your order due to lack of stock',
            })}
          />
          <FormattedListItem
            content={defineMessage({
              defaultMessage: 'A product or pricing error is identified',
            })}
          />
          <FormattedListItem
            content={defineMessage({
              defaultMessage: 'A product is faulty',
            })}
          />
        </ul>
        <Typography size="large-paragraph" isBold>
          <FormattedMessage defaultMessage="Cancellations" />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="You can cancel your {brand} treatment plan anytime via your Profile by following the steps <a>here</a>."
            values={{
              brand: title,
              a: (chunks) => (
                <a
                  href={helpArticles?.cancelTreatment}
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="If you wish to cancel for only a few weeks, you can delay your treatment instead. This way, you continue to have access to the educational content{hasMobileApp, select, true { plus tracking your progress via the {brand} mobile app} other {}}."
            values={{
              hasMobileApp,
              brand: title,
            }}
          />
        </Typography>
      </div>
    ),
  });

  if (showLowDosageFaq) {
    faqs.push({
      title: (
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage defaultMessage="Has the dosage schedule changed for new patients?" />
        </Typography>
      ),
      content: (
        <div className="space-y-4">
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Due to current supply challenges affecting the availability of the high dose Ozempic pre-filled pens (1mg), we are advising that patients adhere to a lower dosage schedule of 0.25mg and 0.5mg pre-filled pens." />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="{brand} has secure access to pens that deliver 0.25mg and 0.5mg - these doses are highly effective for weight loss, helping average patients to lose between 14 - 16.2% of their body weight in 12 months."
              values={{
                brand: title,
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="It is common for many people to stay between 0.25 - 0.5mg long-term." />
          </Typography>
        </div>
      ),
    });
  }

  faqs.push({
    title: (
      <Typography size="medium-paragraph" isBold>
        <FormattedMessage defaultMessage="How does the 30-day money-back guarantee work?" />
      </Typography>
    ),
    content: (
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="We know every weight loss journey is different. If you decide the {programName} Program isn’t for you, you can cancel your treatment plan within 30 days of receiving your first order, and we'll issue you a full refund."
          values={{
            programName,
          }}
        />
      </Typography>
    ),
  });

  faqs.push({
    title: (
      <Typography size="medium-paragraph" isBold>
        <FormattedMessage defaultMessage="Can I claim on private health insurance?" />
      </Typography>
    ),
    content: (
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="{brand} treatments can be claimed through some private health insurers, depending on your individual policy including:"
            values={{ brand: title }}
          />
        </Typography>
        <ul>
          {AUSTRALIAN_HEALTH_INSURANCE_PROVIDERS.map((provider) => (
            <FormattedListItem key={provider} content={provider} />
          ))}
        </ul>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Check out our Private Health Insurance guide <a>here</a> and contact your provider to find out if your treatment is covered."
            values={{
              a: (chunks) => (
                <a
                  href={helpArticles?.healthInsurance}
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="We provide pharmacy invoices to claim with your private health insurance. Once you have purchased your treatment plan, please follow instructions <a>here</a> to access your pharmacy invoice."
            values={{
              a: (chunks) => (
                <a
                  href={helpArticles?.pharmacyInvoices}
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Typography>
      </div>
    ),
  });

  faqs.push({
    title: (
      <Typography size="medium-paragraph" isBold>
        <FormattedMessage defaultMessage="How does the consultation work?" />
      </Typography>
    ),
    content: (
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="After you’ve completed your payment and medical screening, the next step is to have a phone call with your {isPrescriber, select, true {prescriber} other {practitioner}}. They’ll call the phone number you’ve provided, at a time that works for you."
            values={{
              isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="During the call you can expect your {isPrescriber, select, true {prescriber} other {practitioner}} to confirm the information you’ve provided during the consultation, ask any follow up questions, and answer queries you have about your treatment."
            values={{
              isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Lastly, your {isPrescriber, select, true {prescriber} other {practitioner}} will determine if the program is suitable for you."
            values={{
              isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
      </div>
    ),
  });

  faqs.push({
    title: (
      <Typography size="medium-paragraph" isBold>
        <FormattedMessage
          defaultMessage="How does the {isPrescriber, select, true {prescriber} other {practitioner}} determine this program is right for me?"
          values={{
            isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      </Typography>
    ),
    content: (
      <div className="space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="To determine if the {programName} Program is right for you, your {isPrescriber, select, true {prescriber} other {practitioner}} will take into account your medical history, current health status, and weight journey."
            values={{
              programName,
              isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="If suitable, your {isPrescriber, select, true {prescriber} other {practitioner}} will confirm the best treatment option for you."
            values={{
              isPrescriber: prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
      </div>
    ),
  });

  return <AccordionList palette={palette} items={faqs} trimBottom={true} />;
};
