import { getConfig } from '@customer-frontend/config';
import { ObservedMeasurement } from '@customer-frontend/graphql-types';
import { Typography, useResponsive } from '@eucalyptusvc/design-system';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ReactComponent as AvgLineLegendIcon } from '../../assets/icon/avg-line-legend-icon.svg';
import { useTrackerTheme } from '../../provider';
import { CustomCursor } from './chart-utils/custom-cursor';
import { CustomizedDot } from './chart-utils/customized-dot';
import { EmptyChartState } from '../empty-chart-state';
import { SelectedTrackerEntry } from './selected-tracker-entry';
import {
  areEntriesAcrossTwoDifferentYears,
  calculateAvgWeightLossLinePoints,
  getXAxisBoundaries,
  getYAxisBoundaries,
  gridVerticalCoordinatesGenerator,
  transformGraphPointToMeasurement,
  transformMeasurementToGraphPoint,
  xAxisTickFormatterFactory,
} from './utils';
import { WeightLossMomentum } from './weight-loss-momentum';
import { useIntl, FormattedMessage } from 'react-intl';
import { useWeightProgramName } from '@customer-frontend/utils';

// This variable controls whether to disable scrolling on mobile
let disableScrolling = false;

const preventDefault = (e: Event): void => {
  if (disableScrolling) {
    e.preventDefault();
    e.stopPropagation();
  }
};
const enableScrollingOnDrag = (): void => {
  if (disableScrolling) {
    disableScrolling = false;
  }
};
const disableScrollingOnDrag = (): void => {
  if (!disableScrolling) {
    disableScrolling = true;
  }
};
const addEventListenersToPreventAndReenableScrolling = (): void => {
  // We need to add these event listeners when the page loads so that
  // they are in action instantenously when the browser receives a touch event
  document.addEventListener('touchstart', preventDefault, {
    passive: false,
  });
  document.addEventListener('touchmove', preventDefault, {
    passive: false,
  });
  document.addEventListener('touchend', enableScrollingOnDrag, {
    passive: true,
  });
};
const removeEventListenersToPreventAndReenableScrolling = (): void => {
  document.removeEventListener('touchstart', preventDefault);
  document.removeEventListener('touchmove', preventDefault);
  document.removeEventListener('touchend', enableScrollingOnDrag);
};

type ChartOnClickProps = {
  activePayload?: {
    payload: { effectiveFrom: number; value: number };
  }[];
};

type EnhancedTrackerChartProps = {
  weight: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[];
  waist: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[];
  momentum?: number;
};

export const EnhancedTrackerChart: FC<EnhancedTrackerChartProps> = ({
  weight,
  waist,
  momentum,
}) => {
  const { title } = getConfig();
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.enhancedTrackerChart;
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();

  useEffect(() => {
    addEventListenersToPreventAndReenableScrolling();
    return removeEventListenersToPreventAndReenableScrolling;
  }, []);

  const [savedSelectedWeightPoint, setSavedSelectedWeightPoint] = useState<
    Pick<ObservedMeasurement, 'effectiveFrom' | 'value'> | undefined
  >(undefined);
  const [savedHoveredWeightPoint, setSavedHoveredWeightPoint] = useState<
    Pick<ObservedMeasurement, 'effectiveFrom' | 'value'> | undefined
  >(undefined);

  useEffect(() => {
    if (!weight.length) {
      setSavedSelectedWeightPoint(undefined);
    }

    setSavedSelectedWeightPoint(weight[weight.length - 1]);
  }, [weight]);

  const selectedWaistPoint = useMemo(() => {
    const displayedWeightEffectiveFrom =
      savedHoveredWeightPoint?.effectiveFrom ||
      savedSelectedWeightPoint?.effectiveFrom;

    return waist.find(
      ({ effectiveFrom }) => effectiveFrom === displayedWeightEffectiveFrom,
    );
  }, [waist, savedSelectedWeightPoint, savedHoveredWeightPoint]);

  const programName = useWeightProgramName();

  const firstWeight = useMemo(() => {
    let measurement: (typeof weight)[0] | undefined;

    weight.forEach((w) => {
      if (
        !measurement ||
        new Date(w.effectiveFrom).getTime() <
          new Date(measurement.effectiveFrom).getTime()
      ) {
        measurement = w;
      }
    });

    return measurement;
  }, [weight]);

  if (!weight.length || !firstWeight) {
    return (
      <EmptyChartState
        title={formatMessage({
          defaultMessage: 'Unlock by completing check-in',
          description:
            'Label covering feature that is locked until a user checks in',
        })}
        body={formatMessage(
          {
            defaultMessage:
              'Complete your first check-in to see your potential {programName} journey.',
            description: 'hello',
          },
          {
            programName,
          },
        )}
      />
    );
  }

  const transformedTrackerPoints = weight.map(transformMeasurementToGraphPoint);
  const { xMinBoundary, xMaxBoundary } = getXAxisBoundaries(
    transformedTrackerPoints,
  );
  const { yMinBoundary, yMaxBoundary } = getYAxisBoundaries(
    transformedTrackerPoints,
  );

  const onChartClick = (props: ChartOnClickProps): void => {
    const selectedWeightEntry = props?.activePayload?.[0]?.payload;
    if (selectedWeightEntry) {
      const selectedWeightMeasurement =
        transformGraphPointToMeasurement(selectedWeightEntry);
      if (
        savedSelectedWeightPoint?.effectiveFrom !==
        selectedWeightEntry.effectiveFrom
      ) {
        setSavedSelectedWeightPoint(selectedWeightMeasurement);
        setSavedHoveredWeightPoint(undefined);
      }
    }
  };

  const onMobileDrag = (props: ChartOnClickProps): void => {
    disableScrollingOnDrag();
    const selectedWeightEntry = props?.activePayload?.[0]?.payload;
    if (selectedWeightEntry) {
      const selectedWeightMeasurement =
        transformGraphPointToMeasurement(selectedWeightEntry);
      if (
        selectedWeightMeasurement.effectiveFrom !==
        savedSelectedWeightPoint?.effectiveFrom
      ) {
        setSavedSelectedWeightPoint(selectedWeightMeasurement);
      }
    }
  };

  const onMouseMoveDesktop = (props: ChartOnClickProps): void => {
    const hoveredWeightEntry = props?.activePayload?.[0]?.payload;

    if (hoveredWeightEntry) {
      const hoveredWeightMeasurement =
        transformGraphPointToMeasurement(hoveredWeightEntry);
      if (
        hoveredWeightMeasurement.effectiveFrom !==
        savedHoveredWeightPoint?.effectiveFrom
      ) {
        setSavedHoveredWeightPoint(hoveredWeightMeasurement);
      }
    }
  };

  const onMouseLeaveDesktop = (): void => {
    if (savedHoveredWeightPoint) {
      setSavedHoveredWeightPoint(undefined);
    }
  };

  const weightEntryToShow = savedHoveredWeightPoint || savedSelectedWeightPoint;

  const averageLine = calculateAvgWeightLossLinePoints(firstWeight, {
    xMin: xMinBoundary,
    xMax: xMaxBoundary,
  });

  return (
    <div className="flex flex-col">
      <div className="flex justify-between pb-4 w-full">
        <SelectedTrackerEntry
          weight={weightEntryToShow}
          waist={selectedWaistPoint}
        />
        {
          // Momentum can be 0, which we still want to render
          momentum !== undefined && <WeightLossMomentum momentum={momentum} />
        }
      </div>
      <div className="relative">
        <div className="absolute right-1 z-10 flex flex-row items-center gap-1">
          <AvgLineLegendIcon
            style={{ stroke: trackerTheme?.averageLine?.color }}
          />
          <Typography size="small-text" isItalic>
            <FormattedMessage
              defaultMessage="Avg. weight loss for {title}"
              description="title refers to the name of the brand"
              values={{
                title,
              }}
            />
          </Typography>
        </div>
        <div className="h-72 absolute w-full">
          <ResponsiveContainer>
            <ComposedChart
              data={averageLine}
              margin={{ bottom: 31, left: 2, right: 2 }}
            >
              <XAxis
                hide
                ticks={[xMinBoundary, xMaxBoundary]}
                dataKey="effectiveFrom"
                tickLine={false}
                domain={['dataMin', 'dataMax']}
                type="number"
              />
              <YAxis
                hide
                dataKey="value"
                domain={[yMinBoundary, yMaxBoundary]}
              />
              <Line
                type="basis"
                dataKey="value"
                strokeLinecap="round"
                strokeWidth={trackerTheme?.averageLine?.strokeWidth}
                stroke={trackerTheme?.averageLine?.color}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeDasharray="6 4"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="h-72">
          <ResponsiveContainer>
            <ComposedChart
              margin={{ left: 1, right: 1 }}
              data={transformedTrackerPoints}
              onMouseUp={onChartClick}
              onMouseMove={isMobile ? onMobileDrag : onMouseMoveDesktop}
              onMouseLeave={isMobile ? undefined : onMouseLeaveDesktop}
            >
              <CartesianGrid
                verticalCoordinatesGenerator={gridVerticalCoordinatesGenerator}
                horizontal={false}
              />
              <XAxis
                dataKey="effectiveFrom"
                tickFormatter={xAxisTickFormatterFactory(
                  areEntriesAcrossTwoDifferentYears(transformedTrackerPoints),
                )}
                ticks={[xMinBoundary, xMaxBoundary]}
                tickLine={false}
                domain={['dataMin', 'dataMax']}
                interval={'preserveStartEnd'}
                type="number"
                allowDataOverflow
              />
              <YAxis
                hide
                dataKey="value"
                domain={[yMinBoundary, yMaxBoundary]}
              />
              <Area
                dataKey="value"
                fill={trackerTheme?.area?.fill}
                isAnimationActive={false}
              />
              <Tooltip content={<></>} cursor={<CustomCursor />} />
              <Line
                dataKey="value"
                strokeWidth={trackerTheme?.line?.strokeWidth}
                stroke={trackerTheme?.line?.color}
                dot={<CustomizedDot selected={savedSelectedWeightPoint} />}
                isAnimationActive={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
