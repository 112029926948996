import { gql, QueryResult, useQuery } from '@apollo/client';
import { User } from '@customer-frontend/graphql-types';

export const GET_PROFILE_AND_RES_ADDRESS_QUERY = gql`
  query UseProfileAndResidentialAddress {
    profile {
      id
      firstName
      lastName
      birthday
      residentialAddress {
        id
        line1
        line2
        city
        postalCode
        state
        country
      }
    }
  }
`;

export const useProfileAndResidentialAddress = (): QueryResult<{
  profile: User;
}> => {
  return useQuery<{ profile: User }>(GET_PROFILE_AND_RES_ADDRESS_QUERY, {
    fetchPolicy: 'network-only',
  });
};
