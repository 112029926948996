import { SystemStyleObject, PartsStyleObject } from '@chakra-ui/theme-tools';
import { sharedColors } from '../palette';

interface SelectThemeProps {
  colors: {
    focus: string;
    text?: string;
    border: string;
    icon?: string;
  };
  borderRadius: string;
}

export const getSelectStyles = (
  props: SelectThemeProps,
): Record<string, PartsStyleObject | SystemStyleObject> => ({
  variants: {
    default: {
      field: {
        mb: '4px',
        mt: '4px',
        px: '1rem',
        bg: '#fff',
        color: props.colors.text,
        border: '1px solid',
        borderRadius: props.borderRadius,
        borderColor: props.colors.border,
        _focus: {
          boxShadow: `0 0 0 0.188rem ${props.colors.focus}`,
        },
      },
      icon: {
        ml: '0.5rem',
        color: props.colors.icon || sharedColors.neutral.black,
      },
    },
  },
  sizes: {
    md: {
      field: { height: '48px', width: '100%' },
      icon: { width: '16px', height: '9px' },
    },
  },
});
