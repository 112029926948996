import { MessageDescriptor, useIntl } from 'react-intl';
import { ObservedMeasurement } from '@customer-frontend/graphql-types';
import { Typography } from '@eucalyptusvc/design-system';
import { useTrackerTheme } from '../../provider';
import {
  DEFAULT_MESSAGE,
  NO_CHANGE,
  GAINED_WEIGHT_ABOVE_CURVE,
  GAINED_WEIGHT_BELOW_CURVE,
  LOST_WEIGHT_ABOVE_CURVE,
  LOST_WEIGHT_BELOW_CURVE,
} from './constants';
import {
  transformMeasurementToGraphPoint,
  getXAxisBoundaries,
  calculateAvgWeightLossLinePoints,
  getCurrentPointOnAvgWeightLossLine,
  getWeeklyMotivationalMessageFromSet,
} from './utils';

type MotivationalMessageProps = {
  weight: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[];
  momentum?: number;
};

const getMotivationalMessage = (
  weight: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[],
  momentum?: number,
): MessageDescriptor | undefined => {
  if (!weight.length) {
    return;
  }

  if (momentum === undefined) {
    return DEFAULT_MESSAGE;
  }

  const currentPoint = transformMeasurementToGraphPoint(
    weight[weight.length - 1],
  );

  if (momentum === 0) {
    return getWeeklyMotivationalMessageFromSet(NO_CHANGE, currentPoint);
  }

  const transformedTrackerPoints = weight.map(transformMeasurementToGraphPoint);
  const { xMinBoundary, xMaxBoundary } = getXAxisBoundaries(
    transformedTrackerPoints,
  );
  const averageLine = calculateAvgWeightLossLinePoints(weight[0], {
    xMin: xMinBoundary,
    xMax: xMaxBoundary,
  });

  const currentPointOnAvgWeightLossLine = getCurrentPointOnAvgWeightLossLine(
    averageLine,
    transformMeasurementToGraphPoint(currentPoint),
  );

  if (!currentPointOnAvgWeightLossLine) {
    return;
  }

  const gainedMomentum = momentum > 0;
  const isAboveAvgWeightLoss =
    currentPoint.value > currentPointOnAvgWeightLossLine;

  let motivationalMessage: MessageDescriptor[] = [];
  switch (true) {
    case gainedMomentum && isAboveAvgWeightLoss:
      motivationalMessage = GAINED_WEIGHT_ABOVE_CURVE;
      break;
    case gainedMomentum && !isAboveAvgWeightLoss:
      motivationalMessage = GAINED_WEIGHT_BELOW_CURVE;
      break;
    case !gainedMomentum && isAboveAvgWeightLoss:
      motivationalMessage = LOST_WEIGHT_ABOVE_CURVE;
      break;
    case !gainedMomentum && !isAboveAvgWeightLoss:
      motivationalMessage = LOST_WEIGHT_BELOW_CURVE;
      break;
  }

  return getWeeklyMotivationalMessageFromSet(motivationalMessage, currentPoint);
};

export const MotivationalMessage = ({
  weight,
  momentum,
}: MotivationalMessageProps): React.ReactElement | null => {
  const { formatMessage } = useIntl();
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.enhancedTrackerChart;
  const message = getMotivationalMessage(weight, momentum);

  if (!message) {
    return null;
  }

  return (
    <Typography size="lg" isBold color={trackerTheme?.title?.color}>
      {formatMessage(message)}
    </Typography>
  );
};
