import {
  JsonFlags,
  useFeatureFlagClient,
} from '@customer-frontend/feature-flags';
import { ProblemType } from '@customer-frontend/graphql-types';
import { z } from 'zod';

const consultFlowConfigSchema = z.object({
  isUpfrontPayment: z.boolean(),
  requiresScreeningQuiz: z.boolean(),
  requiresPrescriberCall: z.boolean(),
  decoupleInitialQuiz: z.boolean(),
  requiresPrescriberBooking: z.boolean(),
  isSyncExperience: z.boolean(),
  isMultipleMedication: z.boolean().optional(),
  zendeskEnabled: z.boolean().optional(),
});

const consultationFlowConfigFlagSchema = z.record(
  z.string(),
  consultFlowConfigSchema,
);

export type ConsultationFlowConfig = z.infer<typeof consultFlowConfigSchema>;
type ConsultationFlowConfigFlagValue = z.infer<
  typeof consultationFlowConfigFlagSchema
>;

export const useConsultationFlowConfig = (
  problemType?: ProblemType | null,
): ConsultationFlowConfig | null => {
  const featureFlagClient = useFeatureFlagClient();
  if (!problemType) {
    return null;
  }

  const flagData = featureFlagClient.getJson<ConsultationFlowConfigFlagValue>(
    'CONSULTATION_FLOW_CONFIG',
    {
      defaultValue: {
        '*': {
          isUpfrontPayment: false,
          requiresScreeningQuiz: false,
          requiresPrescriberCall: false,
          decoupleInitialQuiz: false,
          requiresPrescriberBooking: false,
          isSyncExperience: false,
          isMultipleMedication: false,
          zendeskEnabled: false,
        },
      },
    },
  );

  const consultFlowConfig =
    consultationFlowConfigFlagSchema.safeParse(flagData);

  if (!consultFlowConfig.success) {
    throw new Error(
      `Invalid config flag value set for ${JsonFlags.CONSULTATION_FLOW_CONFIG}`,
    );
  }
  const configForProblemType = consultFlowConfig.data[problemType];

  if (configForProblemType) {
    return configForProblemType;
  }

  const defaultConfig = consultFlowConfig.data['*'];

  if (!defaultConfig) {
    throw new Error(
      `Missing default config for feature flag ${JsonFlags.CONSULTATION_FLOW_CONFIG}`,
    );
  }

  return defaultConfig;
};
