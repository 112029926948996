import {
  Accordion,
  Typography,
  useResponsive,
} from '@eucalyptusvc/design-system';
import { Header } from './header';
import { OrderSummary, OrderSummaryStyles } from './order-summary';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { usePurchaseActivationFlow } from './provider';
import { formatCurrency } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';

export type PurchaseActivationFlowLayoutStyles = {
  mainBgColorClassName: string;
  sideBgColorClassName: string;
  headerBorderClassName: string;
  headerTextClassName: string;
  orderSummary: OrderSummaryStyles;
};

export function Layout({
  children,
  styles,
}: React.PropsWithChildren & {
  styles: PurchaseActivationFlowLayoutStyles;
}) {
  const { isDesktop } = useResponsive();
  const { orderSummary } = usePurchaseActivationFlow();
  const config = getConfig();

  if (!isDesktop) {
    return (
      <div className={clsx('min-h-full', styles.mainBgColorClassName)}>
        <div className="px-2 py-3">
          <Header />
        </div>
        <div
          className={clsx(
            'border-t bg-white',
            styles.orderSummary.card.borderColor,
          )}
        >
          <Accordion
            title={
              <div className="flex flex-row w-full items-center justify-between p-4">
                <Typography size="paragraph" isBold>
                  <FormattedMessage defaultMessage="Order summary" />
                </Typography>
                {typeof orderSummary?.amount === 'number' && (
                  <Typography size="paragraph">
                    {formatCurrency(
                      mapBrandToAdaptersBrand(config.brand),
                      orderSummary.amount,
                      {
                        includeSymbol: true,
                        includeDecimals: true,
                      },
                    )}
                  </Typography>
                )}
              </div>
            }
            content={
              <div
                className={clsx(
                  'border-t',
                  styles.orderSummary.card.dividerBorderColor,
                )}
              >
                <OrderSummary styles={styles.orderSummary} />
              </div>
            }
            palette="light"
          />
        </div>
        <main
          className={clsx(
            'pt-10 pb-8 px-4 border-t',
            styles.orderSummary.card.dividerBorderColor,
          )}
        >
          {children}
        </main>
      </div>
    );
  }

  return (
    <div className={clsx('min-h-full', styles.mainBgColorClassName)}>
      <div className="w-2/3">
        <div className="py-12 max-w-[834px] px-8 w-full pb-12 ml-auto">
          <div
            className={clsx(
              'pb-6 border-b',
              styles.headerBorderClassName,
              styles.headerTextClassName,
            )}
          >
            <Header />
          </div>
          <main className="pt-8">{children}</main>
        </div>
      </div>
      <div
        className={clsx(
          'w-1/3 fixed top-0 right-0 h-screen',
          styles.sideBgColorClassName,
        )}
      >
        <OrderSummary styles={styles.orderSummary} />
      </div>
    </div>
  );
}
