import { VFC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { BuildReadingRouteParams } from '../../utils';
import { NextReadingCardContent } from './next-reading-card-content';
import { ReadingsFinished } from './readings-finished';
import {
  NextReadingCardQuery,
  NextReadingCardQueryVariables,
} from '@customer-frontend/graphql-types';

type NextReadingCardProps = {
  createReadingRoute: (
    readingId: string,
    params?: BuildReadingRouteParams,
  ) => string;
  programId: string;
};

export const NextReadingCard: VFC<NextReadingCardProps> = ({
  createReadingRoute,
  programId,
}) => {
  const history = useHistory();
  const { data, loading } = useQuery<
    NextReadingCardQuery,
    NextReadingCardQueryVariables
  >(
    gql`
      query NextReadingCard($id: ID!) {
        educationProgram(id: $id) {
          id
          nextUnreadReading {
            id
            content
            startedAt
            timeToReadMs
          }
        }
      }
    `,
    {
      variables: { id: programId },
    },
  );
  const reading = data?.educationProgram?.nextUnreadReading;

  if (loading) {
    return (
      <div className="animate-pulse flex flex-col space-y-3">
        <div className="w-full bg-gray-200 h-56 rounded-md" />
      </div>
    );
  }

  if (!reading) {
    return <ReadingsFinished />;
  }

  const goToUnreadReading = (): void => {
    history.push(createReadingRoute(reading.id, { programId }));
  };

  return (
    <NextReadingCardContent
      onBeginPress={goToUnreadReading}
      reading={reading}
    />
  );
};
