import { gql } from '@apollo/client';

export const experimentPaymentPlanFragment = gql`
  fragment ExperimentPaymentPlan on ExperimentTreatmentPaymentPlan {
    id
    paymentPlan
    discountPercentage
    months
    version
  }
`;
