import { ComponentStyleConfig } from '@chakra-ui/react';

import { getImageUploadStyles, imageUploadParts as parts } from '../../shared';
import { colors } from '../palette';

export const ImageUpload: ComponentStyleConfig = {
  parts,
  baseStyle: (props) =>
    getImageUploadStyles({
      props,
      styleOverrides: {
        container: {
          borderRadius: '0.25rem',
          bg: colors.primary[400],
          border: props.hasError
            ? `1px solid ${colors.status.error[500]}`
            : 'none',
        },
        placeholder: {
          border: `1px dashed ${colors.primary[400]}`,
          backgroundColor: colors.secondary.cream[100],
        },
        sectionContainer: {
          color: colors.secondary.cream[100],
        },
      },
    }),
};
