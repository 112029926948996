import { getConfig } from '@customer-frontend/config';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

export const useEnablePaymentIntent = (): boolean => {
  const config = getConfig();

  const enablePaymentIntentFeatureFlag = useFeatureFlagBoolean(
    'ENABLE_CUSTOMER_FRONTEND_STRIPE_PAYMENT_INTENT',
  );

  if (config.stripeApi === 'PAYMENT_INTENT') {
    return true;
  }

  return enablePaymentIntentFeatureFlag;
};
