import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  Typography,
  LoadingSpinner,
} from '@eucalyptusvc/design-system';
import {
  useProfile,
  useQuizApplication,
  useReopenApplication,
} from '@customer-frontend/services';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';

import { getFormattedQuizResponse } from './utils';
import { notificationService } from '@customer-frontend/notifications';
import { getConfig } from '@customer-frontend/config';
import { useIntl, FormattedMessage } from 'react-intl';

interface QuizSummaryProps {
  quizUrl: string;
  canRestart?: boolean;
  newQuizPath?: boolean;
  goBack?: () => void;
  onRestart?: () => void;
}

export const QuizSummary = ({
  quizUrl,
  newQuizPath,
  canRestart = true,
  goBack,
  onRestart,
}: QuizSummaryProps): React.ReactElement => {
  const config = getConfig();
  const history = useHistory();
  const profileResp = useProfile();
  const { formatMessage } = useIntl();
  const preferredUnitSystem = profileResp?.data?.profile?.preferredUnitSystem;
  const { quizApplicationId } = useParams<{ quizApplicationId: string }>();

  const { data: quizApplicationData, loading: loadingQuizApplication } =
    useQuizApplication(quizApplicationId);

  const { reopenApplication, loading: loadingReopenApplication } =
    useReopenApplication();

  const handleBackClick = React.useCallback((): void => {
    if (goBack) {
      goBack();
    } else {
      history.goBack();
    }
  }, [history, goBack]);

  const handleRedoClick = async (): Promise<void> => {
    const quizId = quizApplicationData?.getQuizApplication?.id;
    if (!quizId) {
      notificationService.show({
        type: 'error',
        message: formatMessage({
          defaultMessage: 'Unable to restart quiz',
          description: 'Error message when users cannot restart quiz',
        }),
      });
      throw Error('Unable to restart quiz, could not get quiz id');
    }

    await reopenApplication(quizId);

    if (onRestart) {
      onRestart();
      return;
    }

    if (newQuizPath) {
      return history.push(`/quiz/${quizId}?restart=true`);
    }

    return history.push(quizUrl);
  };

  return (
    <div className="max-w-2xl mx-auto px-4 md:px-8">
      <ul className="md:space-y-6 space-y-4">
        <div className="flex items-center justify-between mb-8">
          <Typography size="xl" element="h1" isBold>
            <FormattedMessage defaultMessage="Your Answers" />
          </Typography>
          {canRestart && (
            <div className="flex justify-end flex-shrink-0">
              <Button
                level="secondary"
                palette={getSecondaryButtonPalette(config.brand)}
                onClick={handleRedoClick}
                isLoading={loadingReopenApplication}
              >
                <FormattedMessage defaultMessage="Restart quiz" />
              </Button>
            </div>
          )}
        </div>
        {loadingQuizApplication ? (
          <div className="flex justify-center w-full pt-4">
            <LoadingSpinner />
          </div>
        ) : (
          quizApplicationData?.getQuizApplication?.responses?.map(
            (quizResponse) => {
              return (
                <React.Fragment key={quizResponse.question?.id}>
                  <li className="space-y-2">
                    <Typography size="large-paragraph" isBold>
                      {quizResponse.question?.title}
                    </Typography>
                    {getFormattedQuizResponse(
                      quizResponse,
                      preferredUnitSystem,
                    )}
                  </li>
                  <hr />
                </React.Fragment>
              );
            },
          )
        )}
      </ul>
      {!loadingQuizApplication && (
        <div className="flex flex-col space-y-4 items-center mt-8">
          <Button
            level="primary"
            palette={getPrimaryButtonPalette(config.brand)}
            isFullWidth
            onClick={handleBackClick}
          >
            <FormattedMessage defaultMessage="Go back" />
          </Button>
          {canRestart && (
            <Button
              isFullWidth
              level="secondary"
              palette={getSecondaryButtonPalette(config.brand)}
              onClick={handleRedoClick}
              isLoading={loadingReopenApplication}
            >
              <FormattedMessage defaultMessage="Restart quiz" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
