import { type FunctionComponent } from 'react';
import {
  Button,
  TextArea,
  useNotification,
  Typography,
  Card,
} from '@eucalyptusvc/design-system';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
  useMinLengthValidation,
  useRequiredValidation,
} from '@customer-frontend/utils';
import { addPatientQuestionToConsultationMutation } from '@customer-frontend/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { getConfig } from '@customer-frontend/config';

type FormFields = { faqDoctorQuestion: string };

export type SendMessageProps = {
  consultationId: string;
  doctor: { shortClinicianName: string };
  onSubmit: () => void;
};

export const SendMessage: FunctionComponent<SendMessageProps> = ({
  consultationId,
  doctor,
  onSubmit,
}) => {
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const questionValidationMessage = formatMessage({
    defaultMessage: 'Question or message',
    description: 'Label for the question field when sending a doctor a message',
  });
  const questionValidation = {
    ...useRequiredValidation(questionValidationMessage),
    ...useMinLengthValidation(questionValidationMessage, 8),
  };

  const doctorName = doctor.shortClinicianName;

  const { register, handleSubmit, errors } = useForm<FormFields>();

  const [createQuestion, { loading }] = useMutation(
    addPatientQuestionToConsultationMutation,
    {
      onCompleted: () => {
        notify.success({
          message: formatMessage(
            {
              defaultMessage:
                'Thanks! Your {isGb, select, true {prescriber} other {practitioner}} will answer your question shortly.',
              description:
                'Message shown after successfully sending a doctor a message',
            },
            { isGb: getConfig().countryCode === 'GB' },
          ),
        });

        onSubmit();
      },

      onError: () => {
        notify.error({
          message: formatMessage({
            defaultMessage:
              'We couldn’t send your message to the practitioner. Please try again.',
            description:
              'Error message indicating that a form could not be submitted',
          }),
        });
      },
    },
  );

  const submitQuestion = async (data: FormFields): Promise<void> => {
    await createQuestion({
      variables: {
        question: data.faqDoctorQuestion,
        consultationId,
        trigger: 'question',
      },
    });
  };

  return (
    <Card>
      <form
        className="flex flex-col space-y-4"
        onSubmit={handleSubmit(submitQuestion)}
      >
        <Typography size="md" isBold>
          <FormattedMessage
            defaultMessage="Have questions? Ask {doctorName}"
            description="Introduction to send message form on Practitioner's Letter"
            values={{ doctorName }}
          />
        </Typography>

        <Typography size="paragraph">
          <FormattedMessage
            defaultMessage="You can only send one message. Be sure to include all information in this message. "
            description="Reminder about sending only one message"
          />
        </Typography>

        <div>
          <TextArea
            name="faqDoctorQuestion"
            ref={register(questionValidation)}
            errorMessage={errors?.faqDoctorQuestion?.message}
            placeholder={formatMessage(
              {
                defaultMessage: 'Send {doctorName} a message.',
                description:
                  'Placeholder for text area when sending a doctor a message',
              },
              { doctorName },
            )}
          />

          <div className="flex justify-end mt-2">
            <Button isLoading={loading} level="secondary" isSubmit>
              <FormattedMessage
                defaultMessage="Send Message"
                description="Button to submit a message to the doctor"
              />
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
};
