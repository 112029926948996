import { useAuth } from '@customer-frontend/auth';
import {
  Maybe,
  WeightTreatmentCategory,
} from '@customer-frontend/graphql-types';
import { OrganImpactList } from '@customer-frontend/personalisation';
import { WISTIA_VIDEOS, WistiaVideo } from '@customer-frontend/video';
import { Card, Typography } from '@eucalyptusvc/design-system';
import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { organCardData } from './organ-card-data';
import { WeightGraph } from './weight-graph';
import { getConfig } from '@customer-frontend/config';

const percentLess = (initial: number, less: number): number =>
  Math.floor(initial * (1 - less));

const SaxendaMedicationInformation: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
  initialWeight: Maybe<number>;
}> = ({ handleVideoPlayed, initialWeight }): ReactElement => {
  const { loggedInUser: customer } = useAuth();
  const config = getConfig();

  return (
    <>
      {config.brand === 'juniper' && (
        <WistiaVideo
          videoId={WISTIA_VIDEOS.WHAT_IS_SAXENDA}
          videoClassName="rounded-b-none"
          onPlay={handleVideoPlayed}
        >
          <div className="rounded rounded-t-none bg-primary-500 p-4">
            <Typography size={'paragraph'} isBold color="white">
              <FormattedMessage defaultMessage="What is Saxenda?" />
            </Typography>
            <Typography size="paragraph" color="white">
              <FormattedMessage defaultMessage="With Dr Atkinson, Juniper GP" />
            </Typography>
          </div>
        </WistiaVideo>
      )}

      {initialWeight && (
        <div className="w-full md:w-2/3 mx-auto">
          <Card palette="alternate">
            <div className="space-y-2">
              <Typography size="md" element="h2" isBold>
                <FormattedMessage
                  defaultMessage="{customerName}'s recommended goal"
                  values={{ customerName: customer?.shortAddressableName }}
                />
              </Typography>
              <div className="flex justify-center w-full">
                <WeightGraph
                  initialWeight={initialWeight}
                  unit="kg"
                  stepOne={{
                    timeLabel: '6mo',
                    percentLostLabel: '4-5%',
                    weightLostLabel: `${percentLess(
                      initialWeight,
                      0.05,
                    )}-${percentLess(initialWeight, 0.04)}kg`,
                  }}
                  stepTwo={{
                    timeLabel: '12mo',
                    percentLostLabel: '8-9%',
                    weightLostLabel: `${percentLess(
                      initialWeight,
                      0.09,
                    )}-${percentLess(initialWeight, 0.08)}kg`,
                  }}
                />
              </div>
              <Typography size="small-text" element="p">
                <FormattedMessage defaultMessage="The above is a recommended minimum weight goal from your practitioner. Overtime, you will work together with your health coach to set your personal goal and timeline." />
              </Typography>
            </div>
          </Card>
        </div>
      )}
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="<strong>Saxenda is a weight loss medication that regulates your appetite and keeps you feeling fuller for longer. </strong> It contains an active ingredient called liraglutide which is made using a naturally occurring hormone known as glucagon-like-peptide (GLP-1) — which is produced in the gut. Here’s how Saxenda works in your body:"
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Typography>
    </>
  );
};

const OzempicMedicationInformation: React.FC<{
  initialWeight: Maybe<number>;
}> = ({ initialWeight }): ReactElement => {
  return (
    <>
      {initialWeight && (
        <div className="w-full md:w-2/3 mx-auto">
          <Card palette="alternate">
            <div className="space-y-2">
              <Typography size="md" element="h2" isBold>
                <FormattedMessage defaultMessage="Weight progress example" />
              </Typography>
              <div className="flex justify-center w-full">
                <WeightGraph
                  initialWeight={initialWeight}
                  unit="kg"
                  stepOne={{
                    timeLabel: '3mo',
                    percentLostLabel: '~5%',
                    weightLostLabel: `${percentLess(initialWeight, 0.05)}kg`,
                  }}
                  stepTwo={{
                    timeLabel: '6mo',
                    percentLostLabel: '~9.2%',
                    weightLostLabel: `${percentLess(initialWeight, 0.092)}kg`,
                  }}
                />
              </div>
              <Typography size="small-text" element="p">
                <FormattedMessage defaultMessage="This is an example of what a Juniper patient’s weight progress might look like on the Weight Reset Program." />
              </Typography>
            </div>
          </Card>
        </div>
      )}
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="<strong>Ozempic is a weight loss medication that regulates your appetite and keeps you feeling fuller for longer. </strong>{br}{br}On average, patients lose 5-9.2% of body weight over 3-6 months in conjunction with sustainable lifestyle changes.{br}{br}It contains an active ingredient called Semiglutide which is made using a naturally occurring hormone known as glucagon-like-peptide (GLP-1) — which is produced in the gut. Here’s how Ozempic works in your body:"
          values={{
            br: <br />,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Typography>
    </>
  );
};

const getMedicationInformation = (
  weightTreatmentCategory: WeightTreatmentCategory,
  handleVideoPlayed: (id: string, name: string) => void,
  initialWeight: Maybe<number>,
): React.ReactNode => {
  switch (weightTreatmentCategory) {
    case 'saxenda':
      return (
        <SaxendaMedicationInformation
          handleVideoPlayed={handleVideoPlayed}
          initialWeight={initialWeight}
        />
      );
    case 'ozempic':
      return <OzempicMedicationInformation initialWeight={initialWeight} />;
    default:
      return null;
  }
};

export const MedicationContent: React.FC<{
  handleVideoPlayed: (id: string, name: string) => void;
  initialWeight: Maybe<number>;
  weightTreatmentCategory: WeightTreatmentCategory;
}> = ({
  handleVideoPlayed,
  initialWeight,
  weightTreatmentCategory,
}): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div className="space-y-6 pt-6">
      {getMedicationInformation(
        weightTreatmentCategory,
        handleVideoPlayed,
        initialWeight,
      )}
      <OrganImpactList
        items={organCardData.map((data) => {
          return {
            ...data,
            organText: formatMessage(data.organText),
            shortDescription: formatMessage(data.shortDescription),
            longDescription: formatMessage(data.longDescription),
          };
        })}
        arrowColor="text-primary-300"
      />
    </div>
  );
};
