import { sharedColors } from '../shared/palette';

// primary
const primary100 = '#E8F1FA';
const primary200 = '#BAD5F0';
const primary300 = '#75ACE2';
const primary400 = '#1974CD';
const primary500 = '#0D5194';
const primary600 = '#0B345D';

// secondary
const sky100 = '#E3F7FA';
const sky200 = '#ABE8EF';
const sky300 = '#84EDFB';
const navy100 = '#D1D9DE';
const navy200 = '#768E9B';
const navy300 = '#1A4359';
const cream100 = '#FBF9F3';
const cream200 = '#F1ECDF';
const cream300 = '#E5DECC';

// tertiary
const cloudBlue = '#E7F9FB';

// reserved (for conditions/products/etc)
const ed = '#A5CA97';
const skin = '#FDEBAC';
const pe = '#F5BE8B';
const mental = '#8BBFB3';
const sleep = '#BABCED';
const weight = '#D7EA92';
const hair = '#EC9A88';
const herpes = '#D5B8E2';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    sky: {
      100: sky100,
      200: sky200,
      300: sky300,
    },
    navy: {
      100: navy100,
      200: navy200,
      300: navy300,
    },
    cream: {
      100: cream100,
      200: cream200,
      300: cream300,
    },
  },
  tertiary: {
    cloudBlue,
  },
  reserved: {
    ed,
    skin,
    pe,
    mental,
    sleep,
    weight,
    hair,
    herpes,
  },
};
