import React from 'react';
import {
  Box,
  FormLabel,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Flex,
} from '@chakra-ui/react';
import { FormWrapperProps } from './types';
import { Typography } from '../../typography';
import { sharedColors } from '../../../theme/shared';
import { FaExclamationTriangle } from 'react-icons/fa';
import { ReactComponent as ErrorIcon } from '../../../assets/error-message-icon.svg';

export const FormWrapper = ({
  hint,
  label,
  children,
  required,
  disabled,
  errorMessage,
  showMessageErrorIcon,
  showInputIcon,
  palette = 'default',
}: FormWrapperProps): React.ReactElement => {
  const showHint = hint && !errorMessage;
  const hasError = !!errorMessage;
  const errorTextColor =
    palette === 'default'
      ? sharedColors.status.error[500]
      : sharedColors.neutral.white;

  return (
    <FormControl
      isDisabled={disabled}
      isRequired={required}
      isInvalid={hasError}
    >
      <InputGroup>
        <Box width="100%">
          {label && (
            <FormLabel color={hasError ? errorTextColor : 'inherit'}>
              <Typography isBold size="paragraph">
                {label}
              </Typography>
            </FormLabel>
          )}
          <InputGroup>
            {children}
            {hasError && showInputIcon && (
              <InputRightElement
                height="100%"
                mr="6px"
                // TODO: Explore how best to style this when doing new icons
              >
                {/* TODO: Update with our own icons */}
                <FaExclamationTriangle
                  color={sharedColors.status.error[500]}
                  size="16px"
                />
              </InputRightElement>
            )}
          </InputGroup>
          {showHint && (
            <FormHelperText _peerDisabled={{ opacity: 0.4 }}>
              <Typography size="small-text" isBold>
                {hint}
              </Typography>
            </FormHelperText>
          )}
          {hasError && (
            <FormErrorMessage paddingTop={palette === 'alternate' ? 1 : 0}>
              <Flex
                alignItems="center"
                backgroundColor={
                  palette === 'alternate'
                    ? sharedColors.status.error[500]
                    : 'transparent'
                }
                rounded={2}
                padding={1}
              >
                {showMessageErrorIcon && (
                  <Box color={errorTextColor} paddingRight={2}>
                    <ErrorIcon />
                  </Box>
                )}
                <Typography size="small-text" color={errorTextColor} isBold>
                  {errorMessage}
                </Typography>
              </Flex>
            </FormErrorMessage>
          )}
        </Box>
      </InputGroup>
    </FormControl>
  );
};
