import {
  Card,
  Button,
  Typography,
  ButtonPalette,
  TypographySize,
} from '@eucalyptusvc/design-system';
import { Maybe } from '@customer-frontend/graphql-types';
import { FormattedMessage } from 'react-intl';
import { formatCentsToCurrency } from '../logic/pricing';

interface OtcProduct {
  id: string;
  name: string;
  description?: Maybe<string>;
  photo?: Maybe<{
    url: string;
  }>;
  variants: Array<{
    price: number;
  }>;
}

interface OtcProductCardProps {
  product: OtcProduct;
  isInCart: boolean;
  priceColor?: string;
  isLoading?: boolean;
  addButtonPalette?: ButtonPalette;
  descriptionSize?: TypographySize;
  onAddClick: (productId: string) => void;
  onRemoveClick: (productId: string) => void;
}

export const OtcProductCard = ({
  product,
  isInCart,
  isLoading,
  onAddClick,
  priceColor,
  onRemoveClick,
  addButtonPalette,
  descriptionSize = 'paragraph',
}: OtcProductCardProps): React.ReactElement => {
  return (
    <Card>
      <div className="flex items-center">
        <img src={product.photo?.url} className="w-20 h-auto mr-4" />
        <div className="w-full space-y-2">
          <Typography size="md" isBold>
            {product.name}
          </Typography>
          <Typography size="md" element="p" isBold color={priceColor}>
            {formatCentsToCurrency(product.variants[0].price)}
          </Typography>
        </div>
      </div>
      <div className="mt-4 space-y-4">
        <Typography size={descriptionSize}>
          <span className="whitespace-pre-line">{product.description}</span>
        </Typography>
        {isInCart ? (
          <Button
            isFullWidth
            level="secondary"
            isLoading={isLoading}
            isDisabled={isLoading}
            onClick={() => onRemoveClick(product.id)}
          >
            <FormattedMessage defaultMessage="Remove from order" />
          </Button>
        ) : (
          <Button
            isFullWidth
            isLoading={isLoading}
            isDisabled={isLoading}
            palette={addButtonPalette}
            onClick={() => onAddClick(product.id)}
          >
            <FormattedMessage defaultMessage="Add to order" />
          </Button>
        )}
      </div>
    </Card>
  );
};
