import React from 'react';
import { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { UseGetStripeToken, StripeTokenResponse } from './types';

export const useGetStripeToken = (): UseGetStripeToken => {
  const stripe = useStripe();
  const stripeElements = useElements();

  const getStripeToken = React.useCallback(
    async (
      stripeEvent?: PaymentRequestTokenEvent,
    ): Promise<StripeTokenResponse> => {
      if (stripeEvent) {
        return { token: stripeEvent.token };
      } else {
        const cardElement = stripeElements?.getElement(CardElement);

        if (!cardElement || !stripe) {
          throw new Error('Something went wrong, please refresh & try again');
        }

        return await stripe.createToken(cardElement);
      }
    },
    [stripe, stripeElements],
  );

  return getStripeToken;
};
