import { colors as juniperColors } from '@eucalyptusvc/design-system/src/theme/juniper/palette';
import { EducationBrandTheme } from './types';
import { ReactComponent as JuniperChevronRight } from '../assets/icons/juniper-chevron-right.svg';
import { ReactComponent as JuniperChevronDown } from '../assets/icons/juniper-chevron-down.svg';
import { ReactComponent as JuniperCircleOutline } from '../assets/icons/juniper-circle-outline.svg';
import { ReactComponent as JuniperCircleProgress } from '../assets/icons/juniper-circle-progress.svg';
import { ReactComponent as JuniperCircleTick } from '../assets/icons/juniper-circle-tick.svg';
import { ReactComponent as JuniperTimeOutline } from '../assets/icons/juniper-time-outline.svg';
import { ReactComponent as JuniperBook } from '../assets/icons/juniper-book.svg';

export const theme: EducationBrandTheme = {
  nextReadingDashboardCard: {
    textColor: juniperColors.neutral.black,
    readStatusIcon: {
      inProgress: <JuniperCircleProgress />,
      unread: <JuniperBook />,
      read: <JuniperBook />,
    },
    timeToRead: {
      icon: <JuniperTimeOutline />,
      colorClassName: 'text-primary-500',
    },
  },
  readingCollectionList: {
    button: {
      bgClassName: 'bg-transparent group-hover:bg-primary-100',
      icon: <JuniperChevronRight />,
    },
    containerClassName: 'rounded border-primary-300 divide-primary-300',
  },
  moduleAccordionItem: {
    button: {
      bgClassName: 'bg-primary-100',
      textColor: juniperColors.primary[500],
      icon: <JuniperChevronDown />,
    },
    border: {
      colorClassName: 'border-primary-200',
      radiusClassName: 'rounded',
    },
    dividerClassName: 'divide-primary-200',
    listItem: {
      textColor: juniperColors.neutral.black,
      bgClassName: 'bg-transparent hover:bg-neutral-white',
      button: {
        chevronIcon: <JuniperChevronRight />,
        borderClassName: 'border-primary-500',
        bgClassName: 'bg-transparent',
      },
      readStatusIcon: {
        unread: <JuniperCircleOutline />,
        read: <JuniperCircleTick />,
        inProgress: <JuniperCircleProgress />,
      },
    },
    timeToRead: {
      icon: <JuniperTimeOutline />,
      color: 'text-primary-500',
    },
  },
  nextReadingHeroCard: {
    color: 'text-neutral-black',
    buttonPalette: 'default',
    containerClassName: 'rounded',
    timeRemainingIcon: <JuniperTimeOutline />,
    timeRemainingTextBold: false,
  },
};
