import { Typography, Button } from '@eucalyptusvc/design-system';
import { useHistory } from 'react-router-dom';

export const NoActiveSubscriptions = (): React.ReactElement => {
  const history = useHistory();
  const handleOnClick = (): void => {
    history.push('/profile');
  };

  return (
    <div className="space-y-4 flex justify-center flex-col">
      <Typography isBold size="md">
        You have no active subscriptions.
      </Typography>
      <Button onClick={handleOnClick}>Back to profile</Button>
    </div>
  );
};
