import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  DiscountCodeFormFragment,
  Maybe,
} from '@customer-frontend/graphql-types';
import {
  useAddTreatmentDiscount,
  useRemoveTreatmentDiscount,
} from '@customer-frontend/services';
import { getAllProductsFromTreatment } from '@customer-frontend/treatment';
import { useNotification } from '@eucalyptusvc/design-system';
import { usePersistedDiscountFromURL } from '../persisted-discount-from-url';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';

export type UseActiveTreatmentDiscountLogicResponse = {
  products: { id: string; price: number; quantity?: number }[];
  handleApplyDiscount: (
    discountCode?: DiscountCodeFormFragment | undefined,
  ) => void;
  handleRemoveDiscount: () => void;
  autoAddDiscountedProductsFlagValue: boolean;
  persistedDiscountCode: string | null;
  discountCode?: DiscountCodeFormFragment;
  addDiscountToTreatment: ({
    discountCode: { code },
  }: {
    discountCode: DiscountCodeFormFragment;
  }) => void;
  loading: boolean;
  showConfetti: boolean;
};

// This hook needs to support flexiplans purchases.
// https://linear.app/eucalyptus/issue/COR-973/[customer-frontend]-add-flexiplans-support-to
export const useActiveTreatmentDiscountLogic = ({
  treatment,
}: {
  treatment: Maybe<{
    id: string;
    treatmentDiscount?: {
      discountCode?: DiscountCodeFormFragment | null;
    } | null;
    product: { id: string };
    plan?: {
      amount: number;
    } | null;
    otcSchedules: {
      isActive: boolean;
      product: { id: string; variants: { price?: number }[] };
      quantity?: number;
    }[];
    nextPaymentAmount?: number | null;
  }>;
}): UseActiveTreatmentDiscountLogicResponse => {
  const notify = useNotification();
  const { persistedDiscountCode, clearCode } = usePersistedDiscountFromURL();
  const featureFlagClient = useFeatureFlagClient();
  const autoAddDiscountedProductsFlagValue = featureFlagClient.getBoolean(
    'AUTO_ADD_DISCOUNTED_PRODUCTS',
  );

  const [discountCode, setDiscountCode] = useState<
    DiscountCodeFormFragment | undefined
  >(treatment?.treatmentDiscount?.discountCode ?? undefined);

  useEffect(() => {
    if (treatment?.treatmentDiscount?.discountCode) {
      setDiscountCode(treatment.treatmentDiscount.discountCode);
    }
  }, [treatment?.treatmentDiscount?.discountCode]);

  const [showConfetti, setShowConfetti] = useState(false);
  const { formatMessage } = useIntl();

  const [
    addTreatmentDiscountMutation,
    { loading: addTreatmentDiscountMutationLoading },
  ] = useAddTreatmentDiscount({
    onCompleted: () => {
      if (
        discountCode?.products.length &&
        discountCode.type === 'PERCENTAGE' &&
        discountCode.amount === 100
      ) {
        setShowConfetti(true);

        notify.success({
          message: formatMessage({
            defaultMessage:
              "Success! We've added this item to your cart and applied a 100% discount.",
          }),
        });
      } else {
        notify.success({
          message: formatMessage({
            defaultMessage: 'Discount added to treatment',
          }),
        });
      }
    },
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage: "'Unable to add discount to treatment'",
        }),
      });
    },
  });

  const [removeTreatmentDiscount] = useRemoveTreatmentDiscount({
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage: 'Unable to remove discount, please try again.',
        }),
      });
    },
    onCompleted: () => {
      notify.success({
        message: formatMessage({
          defaultMessage: 'Discount removed',
        }),
      });
      setDiscountCode(undefined);
    },
  });

  const handleApplyDiscount = useCallback(
    (discountCode?: DiscountCodeFormFragment): void => {
      if (discountCode) {
        setDiscountCode(discountCode);
      }
    },
    [],
  );

  const handleRemoveDiscount = useCallback(() => {
    if (discountCode) {
      setDiscountCode(undefined);
    }
    if (treatment?.id && treatment?.treatmentDiscount) {
      removeTreatmentDiscount({
        variables: {
          treatmentId: treatment.id,
        },
      });
    }
  }, [discountCode, removeTreatmentDiscount, treatment]);

  const addDiscountToTreatment = useCallback(
    ({
      discountCode: { code },
    }: {
      discountCode: DiscountCodeFormFragment;
    }) => {
      if (
        treatment?.id &&
        treatment?.treatmentDiscount?.discountCode?.code !== code
      ) {
        addTreatmentDiscountMutation({
          variables: {
            discountCode: code,
            treatmentId: treatment?.id,
          },
        });
        clearCode();
      }
    },
    [
      addTreatmentDiscountMutation,
      clearCode,
      treatment?.id,
      treatment?.treatmentDiscount?.discountCode?.code,
    ],
  );

  const products = useMemo(
    () => getAllProductsFromTreatment(treatment),
    [treatment],
  );

  return {
    products,
    handleApplyDiscount,
    handleRemoveDiscount,
    addDiscountToTreatment,
    autoAddDiscountedProductsFlagValue,
    loading: addTreatmentDiscountMutationLoading,
    persistedDiscountCode,
    discountCode,
    showConfetti,
  };
};
