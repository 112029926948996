import {
  getConfig,
  useConsultationFlowConfig,
} from '@customer-frontend/config';
import {
  DiscountCodeFormFragment,
  ProblemType,
  useConsultationPricingQuery,
} from '@customer-frontend/graphql-types';
import {
  calculateDiscountedPrice,
  usePersistedDiscountFromURL,
} from '@customer-frontend/order';
import { useConsultation, useProfile } from '@customer-frontend/services';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { ConsultationPaymentForm } from './payment-form';
import { ConsultPayPageView, ConsultPayPageViewProps } from './view';

interface ConsultPayPageProps extends ConsultPayPageViewProps {
  onPaymentSuccess: () => void;
  getFreeDiscountCode?: (problemType: ProblemType) => string | undefined;
  termsAndConditionsPath: string;
  privacyPolicyPath: string;
  consultationId: string;
  nextRoute: string;
  collectAddressRoute: string;
  createAccountRoute: string;
  profileRoute: string;
  paymentIntentReturnUrl?: string;
}

export const ConsultPayPage: React.FC<ConsultPayPageProps> = ({
  onPaymentSuccess,
  getFreeDiscountCode = () => undefined,
  consultationId,
  termsAndConditionsPath,
  privacyPolicyPath,
  nextRoute,
  collectAddressRoute,
  createAccountRoute,
  profileRoute,
  paymentIntentReturnUrl,
  ...viewProps
}) => {
  const { formatMessage } = useIntl();
  const config = getConfig();
  const { data: profileData, loading: profileLoading } = useProfile();

  const { data: consultData, loading: consultLoading } =
    useConsultation(consultationId);

  const { data: pricingData } = useConsultationPricingQuery({
    variables: {
      id: consultationId,
    },
  });

  const consultConfig = useConsultationFlowConfig(
    consultData?.consultation?.type,
  );

  const consultPrice = pricingData?.consultation?.price?.fullPrice || 0;

  const { persistedDiscountCode } = usePersistedDiscountFromURL();
  const [discount, setDiscount] = useState<DiscountCodeFormFragment>();

  const { totalPrice } = calculateDiscountedPrice({
    subtotal: consultPrice || 0,
    discount,
    products: [],
  });

  const consultationStatus = consultData?.consultation.status;

  if (profileLoading || consultLoading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (!profileData || !consultData || !consultConfig) {
    return <Redirect to={profileRoute} />;
  }

  if (!consultConfig.isSyncExperience && !consultConfig.isUpfrontPayment) {
    const hasAddress = profileData.profile.residentialAddress;
    const isPasswordSet = profileData.profile.isPasswordSet;
    if (!hasAddress || !isPasswordSet) {
      return <Redirect to={collectAddressRoute} />;
    }
    const hasPhone = !!profileData.profile.phone;
    if (!hasPhone) {
      return <Redirect to={createAccountRoute} />;
    }
  }

  if (!!consultationStatus && consultationStatus !== 'AWAITING_PAYMENT') {
    return <Redirect to={nextRoute} />;
  }

  const renderPaymentForm = (): React.ReactNode => {
    return (
      <ConsultationPaymentForm
        consultation={consultData?.consultation}
        user={profileData?.profile}
        discount={discount}
        setDiscount={setDiscount}
        originalPrice={consultPrice}
        totalPrice={totalPrice}
        defaultDiscountCode={
          getFreeDiscountCode?.(consultData?.consultation.type) ??
          persistedDiscountCode
        }
        onPaymentSuccess={onPaymentSuccess}
        termsAndConditionsPath={termsAndConditionsPath}
        privacyPolicyPath={privacyPolicyPath}
        shouldShowPaymentFormLabel={false}
        paymentIntentReturnUrl={paymentIntentReturnUrl}
      />
    );
  };
  return (
    <ConsultPayPageView
      {...viewProps}
      renderPaymentForm={renderPaymentForm}
      title={formatMessage(
        {
          defaultMessage:
            'Pay to secure your {isGb, select, true {prescriber} other {prescriber}} consultation',
          description:
            'title text on the confirm consultation page requesting the user to pay for their consultation',
        },
        {
          isGb: config.countryCode === 'GB',
        },
      )}
      description={formatMessage(
        {
          defaultMessage:
            "If it turns out the {isGb, select, true {program} other {programme}} isn't suitable for you, we'll refund you this payment.",
          description: 'text on the payment page describing a refund',
        },
        {
          isGb: config.countryCode === 'GB',
        },
      )}
    />
  );
};
