import { useEffect, useState, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { getConfig } from '@customer-frontend/config';

/**
 * useStripePaymentRequest provides access to a Stripe PaymentRequest
 */
export const useStripePaymentRequest = ({
  label,
  totalPrice,
  onTokenReceived,
}: {
  label: string;
  totalPrice: number;
  onTokenReceived: (event: PaymentRequestTokenEvent) => void;
}): PaymentRequest | null => {
  const config = getConfig();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );
  const totalPriceRef = useRef(totalPrice);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const paymentRequestProps = {
      country: config.countryCode,
      currency: config.currency.toLowerCase(),
      total: {
        label,
        amount: totalPrice,
      },
    };

    if (paymentRequest) {
      if (totalPriceRef.current !== totalPrice) {
        // This should handle the scenario where a discount changes
        // the totalPrice of the payment request
        totalPriceRef.current = totalPrice;
        paymentRequest.update(paymentRequestProps);
      }
    } else {
      const newPaymentRequest = stripe.paymentRequest(paymentRequestProps);

      newPaymentRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(newPaymentRequest);
        }
      });
    }
  }, [
    stripe,
    label,
    totalPrice,
    config.currency,
    config.countryCode,
    paymentRequest,
  ]);

  useEffect(() => {
    if (paymentRequest === null) {
      return;
    }

    paymentRequest.on('token', (event) => {
      onTokenReceived(event);
    });

    return (): void => {
      paymentRequest.off('token');
    };
  }, [paymentRequest, onTokenReceived]);

  return paymentRequest;
};
