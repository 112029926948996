import { Logger } from '@customer-frontend/logger';
import {
  LongLivedUiStorageType,
  IUiStorage,
  uiStorages,
  UiStorageType,
} from '@customer-frontend/ui-storage';
import { AuthStorageTypeService } from './AuthStorageTypeService';

import { UserInfo, UserInfoSchema } from './type';
import jwtDecode from 'jwt-decode';

export class AuthService {
  private readonly authStorageTypeService: AuthStorageTypeService;

  constructor(
    private readonly tokenKey: string,
    private readonly userKey: string,
    readonly defaultAuthStorageType: LongLivedUiStorageType,
    private readonly logger: Logger,
  ) {
    if (!uiStorages.isSupported(defaultAuthStorageType)) {
      this.logger.warn(
        `[auth] ${defaultAuthStorageType} storage is not supported`,
      );
    }
    this.authStorageTypeService = new AuthStorageTypeService(
      defaultAuthStorageType,
    );

    if (
      !this.getAccessToken() &&
      !this.authStorageTypeService.isUsingDefault()
    ) {
      // If auth token doesn't exist in current storage, make sure we use the default storage type
      // It also makes sure we clear up all existing auth tokens and auth storage type when page gets refreshed
      this.authStorageTypeService.resetToDefault();
      // Clear the credentials in default storage
      this.clearCredentials();
    }
  }

  private get authStorage(): IUiStorage {
    return uiStorages.get(this.authStorageTypeService.getType());
  }

  /**
   * Change the storage type based on user's selection.
   * @param authStorageType
   * @returns
   */
  public switchAuthStorageType(authStorageType: UiStorageType): void {
    // clear the auth data in current storage
    this.clearCredentials();
    this.authStorageTypeService.setType(authStorageType);
    if (!uiStorages.isSupported(authStorageType)) {
      this.logger.warn(
        `[auth] ${authStorageType} storage is not supported (not switching)`,
      );
      return;
    }
    // clear the auth data in new storage
    this.clearCredentials();
  }

  public getUserInfo(): UserInfo | undefined {
    const token = this.getAccessToken();
    if (!token) {
      return undefined;
    }
    const value = this.authStorage.getValue(this.userKey);
    if (value === null || value === undefined) {
      return value;
    }
    const output = UserInfoSchema.safeParse(JSON.parse(value));
    if (!output.success) {
      return undefined;
    }

    return output.data;
  }
  public setUserInfo(userInfo: UserInfo): void {
    this.authStorage.setValue(this.userKey, JSON.stringify(userInfo));
  }
  public getAccessToken(): string | undefined {
    return this.authStorage.getValue(this.tokenKey);
  }
  public setAccessToken(accessToken: string): void {
    this.authStorage.setValue(this.tokenKey, accessToken);
  }

  public clear(): void {
    this.clearCredentials();
    if (!this.authStorageTypeService.isUsingDefault()) {
      // Make sure we will use the default storage type
      this.authStorageTypeService.resetToDefault();
      this.clearCredentials();
    }
  }

  private clearCredentials(): void {
    this.authStorage.clearValue(this.tokenKey);
    this.authStorage.clearValue(this.userKey);
  }

  public isImpersonatingUser(): boolean {
    const token = this.getAccessToken();
    if (!token) {
      return false;
    }

    const decoded = jwtDecode<{ impersonatedByAdminId?: string }>(token);
    return !!decoded.impersonatedByAdminId;
  }

  public touch(): void {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      this.setAccessToken(accessToken);
    }
    const userInfo = this.getUserInfo();
    if (userInfo) {
      this.setUserInfo(userInfo);
    }
  }
}
