import type { Brand } from '@customer-frontend/types';
import type { ButtonPalette } from '@eucalyptusvc/design-system';
import {
  Quiz,
  QuizQuestion,
  Maybe,
  QuizConditions,
  QuizValidationCondition,
  CustomerAttributes,
} from '@customer-frontend/graphql-types';
import { add, isBefore } from 'date-fns';

export interface AnswerApplicationQuestionAnswersResponse {
  id: string;
  englishAnswer?: string | null;
  englishAnswersArray: Array<string>;
  question?: { id: string } | null;
}

interface QuestionAndAnswer {
  question: QuizQuestion;
  answer?: AnswerApplicationQuestionAnswersResponse;
}

function evalOperator(
  a: Maybe<string | string[]>,
  operator: string,
  b: Maybe<string>,
): boolean {
  switch (operator) {
    case 'IS':
      return a === b;
    case 'IS_NOT':
      return a !== b;
    case 'CONTAINS':
      return !!a && !!b ? a.includes(b) : false;
    case 'NOT_CONTAINS':
      return !a || (b ? !a.includes(b) : false);
    default:
      return false;
  }
}

export function calculateNextQuestionId(
  quiz: Quiz,
  answers: Maybe<AnswerApplicationQuestionAnswersResponse[]>,
  currentQuestionId: string,
): Maybe<string> {
  const {
    questions: tempQuestions,
    skips,
    logics,
    questionsOrder: tempOrder,
  } = quiz;

  if (!tempQuestions) {
    throw new Error('Can not find any questions');
  }

  if (!answers) {
    answers = [];
  }

  const questionsOrder = removeDuplicate(
    tempOrder.filter((id) => tempQuestions.some((it) => it.id === id)),
  );

  const questions = tempQuestions.filter((it) =>
    questionsOrder.includes(it.id),
  );

  // Hash question and answer to question ID for faster reference,
  // because logical jumps can require checking multiple question's answers
  const questionAndAnswerMap: Record<string, QuestionAndAnswer> =
    questions.reduce((idMap, current) => {
      return {
        ...idMap,
        [current.id]: {
          question: current,
          answer: null,
        },
      };
    }, {});

  answers.forEach((answer) => {
    if (answer.question?.id && questionAndAnswerMap[answer.question.id]) {
      questionAndAnswerMap[answer.question.id].answer = answer;
    }
  });

  const nextIndexQuestionId = (): string | undefined => {
    const index = questionsOrder.indexOf(currentQuestionId);
    if (index < questionsOrder.length - 1) {
      return questionsOrder[index + 1];
    }
  };

  const conditionsSatisfied = (
    conditions: Maybe<QuizConditions[]>,
    questionId: string,
  ): boolean => {
    if (!conditions) {
      return false;
    }
    let prevOperator: QuizValidationCondition | null = null;
    let result = false;
    for (const currCondition of conditions) {
      // If jump is runnning on current question, but there is no answer, do not try run logic
      if (
        currCondition?.question?.id === questionId &&
        (!currCondition?.question?.id ||
          !questionAndAnswerMap[currCondition?.question?.id]?.answer)
      ) {
        return false;
      }
      const currAnswer = questionAndAnswerMap[currCondition.question.id].answer;
      const conditionResult = evalOperator(
        currAnswer?.englishAnswer || currAnswer?.englishAnswersArray,
        currCondition.operator,
        currCondition.value,
      );
      if (prevOperator) {
        if (prevOperator === 'AND') {
          result = result && conditionResult;
        } else if (prevOperator === 'OR') {
          result = result || conditionResult;
        }
      } else {
        result = conditionResult;
      }
      prevOperator = currCondition.nextCondition ?? null;
    }
    return result;
  };

  const toSkip = (questionId: string): boolean => {
    const logicalSkip = skips?.find((skip) => {
      return skip.from?.id === questionId;
    });
    if (logicalSkip) {
      return conditionsSatisfied(logicalSkip.conditions, questionId);
    }
    return false;
  };

  const nextQuestionHandler = (): Maybe<string> => {
    // If this flow has no logical jumps, return next question
    if (!logics) {
      return nextIndexQuestionId();
    }

    // If this question has no logical jumps associated with it, return the next question
    const logicalJump = logics.find(
      (logic) => logic.from?.id === currentQuestionId,
    );
    if (!logicalJump) {
      return nextIndexQuestionId();
    }

    if (logicalJump.jumps) {
      const jump = logicalJump.jumps.find((jump) =>
        conditionsSatisfied(jump.conditions, currentQuestionId),
      );
      if (jump?.to?.id) {
        return jump?.to?.id;
      }
    }
    if (logicalJump.elseJump?.id) {
      return logicalJump.elseJump.id;
    }
    return nextIndexQuestionId();
  };

  let nextId = nextQuestionHandler();

  while (nextId && toSkip(nextId)) {
    currentQuestionId = nextId;
    nextId = nextQuestionHandler();
  }

  return nextId;
}

export function calculatePreviousQuestionId(
  quiz: Quiz,
  answers: Maybe<AnswerApplicationQuestionAnswersResponse[]>,
  currentQuestionId: string,
): Maybe<string> {
  const { questions, questionsOrder } = quiz;
  if (!questions) {
    throw new Error('Can not find any questions');
  }

  let curr: Maybe<string> = questionsOrder[0];
  while (curr) {
    const nextQuestionId = calculateNextQuestionId(quiz, answers, curr);
    if (nextQuestionId === currentQuestionId) {
      return curr;
    }
    curr = nextQuestionId;
  }
  return undefined;
}

function removeDuplicate(arr: string[]): string[] {
  return arr.filter((item, pos) => arr.indexOf(item) === pos);
}

export const getPrefillForShortcode = (
  customerAttributes: Maybe<CustomerAttributes>,
  shortcode: Maybe<string>,
): string => {
  if (shortcode === 'allergies') {
    return customerAttributes?.allergies || '';
  } else if (shortcode === 'conditions') {
    return customerAttributes?.conditions || '';
  } else if (shortcode === 'currentMedications') {
    return customerAttributes?.medications || '';
  }
  return '';
};

export const isWithin4Weeks = (dateString: string): boolean => {
  const date = new Date(dateString);
  const fourWeeks = add(new Date(), { weeks: 4 });
  return isBefore(date, fourWeeks);
};

export const getPrimaryButtonPalette = (brand: Brand): ButtonPalette => {
  return brand === 'pilot' ? 'alternate' : 'default';
};

export const getSecondaryButtonPalette = (brand: Brand): ButtonPalette => {
  return brand === 'pilot' ? 'white' : 'default';
};

export const calculateFirstUnansweredQuestionId = (
  quiz: Quiz,
  answers: Maybe<AnswerApplicationQuestionAnswersResponse[]>,
): string | undefined => {
  if (!quiz || !answers) {
    return;
  }

  let firstUnansweredQuestionId: string | null | undefined =
    quiz.questionsOrder[0];
  if (!answers?.length) {
    return firstUnansweredQuestionId;
  }

  let counter = 0;

  while (firstUnansweredQuestionId) {
    // This will get triggered if the quiz has some logic error (e.g. backwards jump)
    if (counter > quiz.questions.length) {
      throw new Error(
        'Counter limit: cannot calculate find unanswered question.',
      );
    }

    const hasAnsweredQuestion = answers?.some(
      (a) => a.question?.id === firstUnansweredQuestionId,
    );
    const questionType = quiz.questions.find(
      (q) => q.id === firstUnansweredQuestionId,
    )?.type;
    const isNonAnswerQuestionType =
      questionType === 'CONTINUE' ||
      questionType === 'REVIEW' ||
      questionType === 'TRANSITION';

    const nextQuestion = calculateNextQuestionId(
      quiz,
      answers,
      firstUnansweredQuestionId,
    );

    // allow landing on continue, review or transition if its the last question
    if (isNonAnswerQuestionType && !nextQuestion) {
      break;
    }

    if (!hasAnsweredQuestion && !isNonAnswerQuestionType) {
      break;
    }

    firstUnansweredQuestionId = nextQuestion;

    counter++;
  }
  return firstUnansweredQuestionId ?? quiz.questionsOrder[0];
};
