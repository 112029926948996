import { gql, useMutation } from '@apollo/client';
import { useNotification } from '@eucalyptusvc/design-system';
import { useIntl } from 'react-intl';

export const useRefillTreatmentProps = ({
  treatment,
}: {
  treatment?: {
    id?: string;
  };
}): {
  loading: boolean;
  onRefill: () => void;
} => {
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const [refillTreatment, { loading }] = useMutation(
    gql`
      mutation TreatmentRefillJuniper($treatmentId: String!) {
        refillTreatment(treatmentId: $treatmentId, isPaused: false) {
          id
          status
          canRefill
          nextPayment
          refillsLeft
        }
      }
    `,
    {
      onCompleted: () =>
        notify.success({
          message: formatMessage({
            defaultMessage: 'Your refill request has been successful.',
            description:
              'Success message when user successfully requests to get their next shipment early',
          }),
        }),
      onError: () => {
        notify.error({
          message: formatMessage({
            defaultMessage: 'Your refill request has failed. Please try again.',
            description:
              'Error message when user request to get their next shipment early fails',
          }),
        });
      },
      context: {
        skipErrorNotification: true,
      },
    },
  );

  const handleRefill = (): void => {
    if (treatment?.id) {
      refillTreatment({
        variables: {
          treatmentId: treatment.id,
        },
      });
    }
  };

  return {
    loading,
    onRefill: handleRefill,
  };
};
