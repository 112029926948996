import { UserInfo } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';

const logInitialRequiredError = (): void => {
  // console.error will be caught by data dog
  // eslint-disable-next-line no-console
  console.error(`Your segment api is not initialized`);
};

type SegmentEvent = typeof window.analytics.SegmentEvent;

const isIdentifyPayload = (
  payload: SegmentEvent['payload'],
): payload is typeof window.analytics.IdentifyPayload => {
  return payload.obj.type === 'identify';
};

export const init = ({
  segmentKey,
  consentRequired,
}: {
  segmentKey: string;
  consentRequired: boolean;
}): void => {
  const head = document.head;
  const segmentScript = document.createElement('script');
  const loadAnalytics = consentRequired
    ? ''
    : `analytics.load('${segmentKey}');`;

  segmentScript.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
    ${loadAnalytics}  
    if (window.gtag) { gtag('config', 'G-9QLTXBJ9TX') };
    }}();`;

  head.appendChild(segmentScript);

  const config = getConfig();

  window.analytics.addSourceMiddleware((event: SegmentEvent) => {
    if (isIdentifyPayload(event.payload)) {
      event.payload.obj.traits.brand = config.analyticsBrand;
      event.payload.obj.traits.countryCode = config.countryCode;
    } else {
      event.payload.obj.properties.brand = config.analyticsBrand;
      event.payload.obj.properties.countryCode = config.countryCode;
    }
    event.next(event.payload);
  });
};

export const track = async (
  user: UserInfo | undefined,
  event: string,
  properties?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
): Promise<void> => {
  const analytics = window.analytics;
  if (analytics) {
    return new Promise((resolve) => {
      analytics.track(
        event,
        user
          ? { ...properties, email: user.email, userId: user.id }
          : properties,
        options,
        resolve,
      );
    });
  } else {
    logInitialRequiredError();
  }
};

export const page = async (
  user: UserInfo | undefined,
  category?: string,
  name?: string,
  properties?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
): Promise<void> => {
  const analytics = window.analytics;
  if (analytics) {
    return new Promise((resolve) => {
      analytics.page(
        category,
        name,
        user ? { ...properties, email: user.email } : properties,
        options,
        resolve,
      );
    });
  } else {
    logInitialRequiredError();
  }
};

export const identify = async (
  userId: string,
  traits: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
): Promise<void> => {
  const { analytics } = window;
  if (analytics) {
    return new Promise((resolve) => {
      analytics.identify(userId, traits, options, resolve);
    });
  } else {
    logInitialRequiredError();
  }
};
