import clsx from 'clsx';
import { isSameDay, isToday } from 'date-fns';
import {
  Button,
  LoadingSpinner,
  RadioButtonGroup,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  UpsertPractitionerBookingMutation,
  UpsertPractitionerBookingMutationVariables,
  ScheduleCallPageTemplateQuery,
  ScheduleCallPageTemplateQueryVariables,
  ScheduleCallPractitionerBookingWindowFragment,
  ConsultationStatus,
  CheckSelectedTimeQuery,
  CheckSelectedTimeQueryVariables,
  ScheduleCallPhoneNumberModalFragment,
} from '@customer-frontend/graphql-types';
import { useParams } from 'react-router-dom';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import { CannotBookErrorMessage } from './cannot-book-error-message';
import {
  phoneNumberModalFragment,
  ScheduleCallPhoneNumberModal,
} from './phone-number-modal';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { validateIntlMobileNumberAgainstCountryCodes } from '@customer-frontend/utils';

type SchedulePhoneCallProps = SchedulePhoneCallViewExposedProps & {
  onConfirm: () => void;
  goBackToProfile: () => void;
};

type SchedulePhoneCallViewExposedProps = {
  selectedToggleBackgroundClass?: string;
  selectedToggleBorderClass?: string;
  selectedToggleTitleColor?: string;
  toggleBorderClass?: string;
  sectionTitlesColor?: string;
};

type SchedulePhoneCallViewProps = SchedulePhoneCallViewExposedProps & {
  bookingWindows: ScheduleCallPractitionerBookingWindowFragment[];
  preselectedWindowId: string | undefined;
  onSubmit: (windowId: string) => void;
  isLoading: boolean;
  canSchedulePractitionerBooking: boolean | undefined;
  consultationStatus: ConsultationStatus | undefined;
  clinicianName: string | undefined;
  goBackToProfile: () => void;
  phoneNumber?: string | null;
  validPhoneRegions: ScheduleCallPhoneNumberModalFragment['validPhoneRegions'];
};

export const SchedulePhoneCall: React.FC<SchedulePhoneCallProps> = ({
  onConfirm,
  goBackToProfile,
  ...viewProps
}) => {
  const { consultationId } = useParams<{ consultationId: string }>();
  const { formatMessage } = useIntl();
  const notification = useNotification();
  const { data, loading, refetch } = useQuery<
    ScheduleCallPageTemplateQuery,
    ScheduleCallPageTemplateQueryVariables
  >(
    gql`
      ${phoneNumberModalFragment}
      fragment ScheduleCallUser on User {
        id
        phone
      }
      fragment ScheduleCallPractitionerBookingWindow on PractitionerBookingWindow {
        id
        startAt
        endAt
        available
      }
      query ScheduleCallPageTemplate($consultationId: String!) {
        ...ScheduleCallPhoneNumberModal
        consultation(id: $consultationId) {
          id
          customer {
            ...ScheduleCallUser
          }
          latestPractitionerBooking {
            id
          }
          status
          canSchedulePractitionerBooking
          doctor {
            id
            shortClinicianName
          }
          practitionerBookingWindows {
            ...ScheduleCallPractitionerBookingWindow
          }
          nextAvailableCallWindowRange {
            id
            startWindow {
              id
            }
            endWindow {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        consultationId,
      },
      pollInterval: 300000, // 5 minutes
    },
  );

  const [upsertPractitionerBookingMutation, { loading: isMutationLoading }] =
    useMutation<
      UpsertPractitionerBookingMutation,
      UpsertPractitionerBookingMutationVariables
    >(gql`
      mutation UpsertPractitionerBooking(
        $input: UpsertPractitionerBookingInput!
      ) {
        upsertPractitionerBooking(input: $input) {
          consultation {
            id
            chatThread {
              id
            }
            latestPractitionerBooking {
              id
              windowStartAt
              windowEndAt
            }
          }
        }
      }
    `);

  const [checkSelectedTime, { loading: checkSelectedTimeLoading }] =
    useLazyQuery<CheckSelectedTimeQuery, CheckSelectedTimeQueryVariables>(gql`
      query CheckSelectedTime($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          practitionerBookingWindows {
            id
            available
          }
        }
      }
    `);

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const bookingWindows = data?.consultation?.practitionerBookingWindows || [];

  const acceptSuggestedTime = async (
    selectedWindowId: string,
  ): Promise<void> => {
    try {
      const { data: checkSelectedTimeData } = await checkSelectedTime({
        variables: {
          consultationId,
        },
      });
      if (
        !checkSelectedTimeData?.consultation?.practitionerBookingWindows?.some(
          (b) => b.id === selectedWindowId && b.available,
        )
      ) {
        refetch().then(() => {
          notification.warning({
            message: formatMessage({
              defaultMessage:
                'The time you selected is no longer available. Please try again.',
              description:
                'Error message when a user tried to make a booking but the selected time is no longer available',
            }),
          });
        });
        return;
      }
      await upsertPractitionerBookingMutation({
        variables: {
          input: {
            consultationId,
            bookingId: data?.consultation?.latestPractitionerBooking?.id,
            startWindowId: selectedWindowId,
          },
        },
      });
      onConfirm();
    } catch (e) {
      // graphql errors are handled by middleware
    }
  };

  return (
    <div className="max-w-screen-sm py-6 px-4 mx-auto">
      <SchedulePhoneCallView
        {...viewProps}
        phoneNumber={data?.consultation?.customer.phone}
        preselectedWindowId={
          data?.consultation?.nextAvailableCallWindowRange?.startWindow.id
        }
        bookingWindows={bookingWindows}
        canSchedulePractitionerBooking={
          data?.consultation?.canSchedulePractitionerBooking ?? undefined
        }
        clinicianName={
          data?.consultation?.doctor?.shortClinicianName ?? undefined
        }
        consultationStatus={data?.consultation?.status ?? undefined}
        onSubmit={acceptSuggestedTime}
        isLoading={isMutationLoading || checkSelectedTimeLoading || loading}
        goBackToProfile={goBackToProfile}
        validPhoneRegions={data?.validPhoneRegions}
      />
    </div>
  );
};

export const SchedulePhoneCallView = ({
  phoneNumber,
  preselectedWindowId,
  bookingWindows,
  canSchedulePractitionerBooking,
  consultationStatus,
  clinicianName,
  onSubmit,
  selectedToggleBackgroundClass = 'bg-primary-500',
  selectedToggleBorderClass = 'border-primary-500',
  selectedToggleTitleColor = sharedColors.neutral.white,
  toggleBorderClass = 'border-primary-300',
  sectionTitlesColor = sharedColors.neutral['700'],
  isLoading,
  goBackToProfile,
  validPhoneRegions,
}: SchedulePhoneCallViewProps): React.ReactElement => {
  useHistoryGoBackBehaviour();
  const { brand } = getConfig();
  const [selectedWindowId, setSelectedWindowId] = useState<string>(
    preselectedWindowId || '',
  );
  const selectedWindow = bookingWindows.find(
    (w) => w.id === preselectedWindowId,
  );
  const preSelectedDate = new Date(selectedWindow?.startAt || 0).toDateString();
  const [selectedDate, setSelectedDate] = useState<string>(preSelectedDate);
  const [showPhoneNumberModal, setShowPhoneNumberModal] = useState<boolean>(
    !phoneNumber ||
      !validateIntlMobileNumberAgainstCountryCodes(
        phoneNumber,
        validPhoneRegions?.map((v) => v.countryCode) ?? [],
      ),
  );

  const visibleWindows = bookingWindows.filter((w) =>
    isSameDay(new Date(w.startAt), new Date(selectedDate)),
  );

  const uniqueDates = new Set<string>(
    bookingWindows.map(({ startAt }) => new Date(startAt).toDateString()),
  );
  const numberOfDays = uniqueDates.size;

  const handlePrimaryCtaClick = (): void => {
    onSubmit(selectedWindowId);
  };

  const handleModalClose = (): void => {
    setShowPhoneNumberModal(false);
  };

  return (
    <div className="space-y-6 items-center text-center">
      <ScheduleCallPhoneNumberModal
        isOpen={showPhoneNumberModal}
        onClose={handleModalClose}
        validPhoneRegions={validPhoneRegions}
      />
      {canSchedulePractitionerBooking ? (
        <>
          <Typography size="lg" isBold>
            <FormattedMessage defaultMessage="Select medical appointment" />
          </Typography>
          <div className="space-y-4 text-left">
            <div className="space-y-2">
              <Typography
                size="medium-paragraph"
                isBold
                color={sectionTitlesColor}
              >
                <FormattedMessage
                  defaultMessage="Select date"
                  description="subheading on schedule phone call screen prompting patient to select a date only (not time)"
                />
              </Typography>
              <div
                className={clsx('grid gap-2', {
                  ['grid-flow-col']: numberOfDays <= 4,
                  ['sm:grid-cols-none sm:grid-flow-col']: numberOfDays > 4,
                  ['grid-cols-3']: numberOfDays > 4 && numberOfDays <= 6,
                  ['grid-cols-4']: numberOfDays > 6,
                })}
              >
                {Array.from(uniqueDates).map((dateString) => {
                  const isChecked = dateString === selectedDate;
                  const date = new Date(dateString);
                  const isDisabled = !bookingWindows.some(
                    (w) => isSameDay(new Date(w.startAt), date) && w.available,
                  );
                  return (
                    <label
                      key={dateString}
                      className={clsx(
                        'flex flex-col rounded h-16 p-2 items-center justify-center border cursor-pointer',
                        {
                          [`${selectedToggleBackgroundClass} ${selectedToggleBorderClass} text-neutral-white`]:
                            isChecked,
                          [`bg-neutral-white ${toggleBorderClass}`]: !isChecked,
                          ['opacity-50']: isDisabled,
                        },
                      )}
                    >
                      <input
                        className="hidden"
                        type="radio"
                        name="date"
                        value={dateString}
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={() => {
                          setSelectedDate(dateString);
                        }}
                      />
                      <Typography
                        size="medium-paragraph"
                        isBold
                        color={
                          isChecked
                            ? selectedToggleTitleColor
                            : sharedColors.neutral.black
                        }
                      >
                        {isToday(date) ? (
                          <FormattedMessage defaultMessage="Today" />
                        ) : (
                          <>
                            {formatDate(mapBrandToAdaptersBrand(brand), date, {
                              weekday: 'short',
                            })}
                          </>
                        )}
                      </Typography>
                      <Typography
                        size="medium-paragraph"
                        color={
                          isChecked
                            ? selectedToggleTitleColor
                            : sharedColors.neutral.black
                        }
                      >
                        {!isToday(date) &&
                          formatDate(mapBrandToAdaptersBrand(brand), date, {
                            day: '2-digit',
                            month: 'short',
                          })}
                      </Typography>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="space-y-2">
              <Typography
                size="medium-paragraph"
                isBold
                color={sectionTitlesColor}
              >
                <FormattedMessage
                  defaultMessage="Select time"
                  description="subheading on schedule phone call screen prompting patient to select a time frame of the day"
                />
              </Typography>
              <RadioButtonGroup
                showBox
                name="contactMethod"
                value={selectedWindowId}
                options={visibleWindows.map((w) => ({
                  disabled: !w.available,
                  label: `${formatDate(
                    mapBrandToAdaptersBrand(brand),
                    w.startAt,
                    { hour: 'numeric' },
                  )} - ${formatDate(mapBrandToAdaptersBrand(brand), w.endAt, {
                    hour: 'numeric',
                    timeZoneName: 'short',
                  })}`,
                  value: w.id,
                }))}
                onChange={(value: string) => {
                  setSelectedWindowId(value);
                }}
              />
            </div>
          </div>
          <div className="space-y-4">
            <Button
              palette={getPrimaryButtonPalette(brand)}
              isFullWidth
              onClick={handlePrimaryCtaClick}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              <FormattedMessage defaultMessage="Confirm" />
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography size="lg" isBold>
            <FormattedMessage defaultMessage="Call cannot be rescheduled" />
          </Typography>
          <CannotBookErrorMessage
            status={consultationStatus}
            clinicianName={clinicianName}
            goBackToProfile={goBackToProfile}
          />
        </>
      )}
    </div>
  );
};
