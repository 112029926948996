import { useLocation } from 'react-router-dom';

export const isIOS = (): boolean => {
  if (navigator && navigator.userAgent) {
    const isIpad =
      navigator.userAgent.includes('Mac') && 'ontouchend' in document;
    return /iPad|iPhone|iPod/.test(navigator.userAgent) || isIpad;
  }

  return false;
};

export const useUrlQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const purgeUrlParams = (): void => {
  const { pathname } = new URL(window.location.href);
  window.history.replaceState({}, '', pathname);
};
