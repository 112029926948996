import {
  User,
  MutationUpdateProfileArgs,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { userInfoFragment } from '..';

interface Response {
  updateProfile: User;
}

const updateProfileDocument = gql`
  mutation UseUpdateProfileAttrs(
    $firstName: String
    $lastName: String
    $birthday: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      birthday: $birthday
    ) {
      ...UserInfo
    }
  }
  ${userInfoFragment}
`;

export function useUpdateProfileAttrs(
  options: MutationHookOptions<Response, MutationUpdateProfileArgs>,
): MutationTuple<Response, MutationUpdateProfileArgs> {
  return useMutation<Response, MutationUpdateProfileArgs>(
    updateProfileDocument,
    options,
  );
}
