export const hasPendingPathologyRequest = (consultation: {
  pathologyRequests?:
    | (
        | {
            result?: {
              id: string;
            } | null;
          }
        | null
        | undefined
      )[]
    | null;
}): boolean => {
  if (!consultation?.pathologyRequests) {
    return false;
  }
  const pathologyRequestsWithoutResult = consultation.pathologyRequests.filter(
    (req) => !req?.result,
  ); // No result recorded for test

  return pathologyRequestsWithoutResult.length > 0;
};
