export const getVariant = (
  error: boolean,
  checked: boolean,
  boxed: boolean,
): string | undefined => {
  if (error) {
    return boxed ? 'box-error' : 'error';
  }
  if (boxed) {
    return checked ? 'box-active' : 'box';
  }
  return checked ? 'active' : undefined;
};
