import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { MutationReactivateMembershipArgs } from '@customer-frontend/graphql-types';

const reactivateMembership = gql`
  mutation ReactivateMembership($type: ProblemType!) {
    reactivateMembership(type: $type) {
      id
    }
  }
`;

export function useReactivateMembership(
  options: MutationHookOptions<Response, MutationReactivateMembershipArgs>,
): MutationTuple<Response, MutationReactivateMembershipArgs> {
  return useMutation<Response, MutationReactivateMembershipArgs>(
    reactivateMembership,
    options,
  );
}
