import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { ProblemType } from '@customer-frontend/graphql-types';
import { isMedicareEnabled } from '../utils';
import { useIHI } from '../logic';

interface Props {
  children: React.ReactNode;
  problemType?: ProblemType;
}
export const MedicareEnabled = ({
  children,
  problemType,
}: Props): React.ReactElement | null => {
  const client = useFeatureFlagClient();
  const { canRouteToMedicare } = useIHI(problemType);
  if (!problemType) {
    return null;
  }
  const enabled = isMedicareEnabled(client, problemType) && canRouteToMedicare;
  return enabled ? <>{children}</> : null;
};
