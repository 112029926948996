import { Typography, BrandTheme } from '@eucalyptusvc/design-system';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@chakra-ui/react';
import {
  Maybe,
  SavedCardPaymentMethod,
} from '@customer-frontend/graphql-types';
import Visa from '../../../assets/payment-methods/visa.webp';
import Amex from '../../../assets/payment-methods/amex.webp';
import MasterCard from '../../../assets/payment-methods/mastercard.webp';
import { ReactComponent as EditPen } from '../../../assets/payment-methods/edit-pen.svg';

function cardImageByBrand(brand?: Maybe<string>) {
  switch (brand?.toLowerCase()) {
    case 'visa':
      return Visa;
    case 'amex':
      return Amex;
    case 'mastercard':
      return MasterCard;
    default:
      return undefined;
  }
}

export function CardPaymentBanner(props: {
  onEdit: () => void;
  paymentMethod: SavedCardPaymentMethod;
}): React.ReactElement | null {
  const { formatMessage } = useIntl();
  const theme = useTheme<BrandTheme>();
  const { onEdit, paymentMethod } = props;

  const cardImgSrc = cardImageByBrand(paymentMethod.brand);

  return (
    <button
      className="flex flex-col p-3 border border-neutral-200 rounded space-y-3 w-full bg-white"
      aria-label={formatMessage({
        defaultMessage: 'Edit',
        description: 'Aria label for a button to edit payment method',
      })}
      onClick={onEdit}
    >
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-row space-x-2">
          {cardImgSrc && <img className="w-8 h-5" src={cardImgSrc} />}
          <Typography
            size="paragraph"
            isBold
            color={theme.colors.neutral['700']}
          >
            <span className="capitalize">{paymentMethod.brand}</span>
          </Typography>
        </div>
        <EditPen width={16} height={16} />
      </div>
      <div className="flex flex-row space-x-3">
        <Typography size="paragraph" color={theme.colors.neutral['700']}>
          <FormattedMessage
            defaultMessage="•••• •••• •••• {mask}"
            description="Payment card masked value"
            values={{
              mask: paymentMethod.mask,
            }}
          />
        </Typography>
        <Typography size="paragraph" color={theme.colors.neutral['700']}>
          <FormattedMessage
            defaultMessage="Expiry {date}"
            description="Payment card expiry date"
            values={{
              date: paymentMethod.expiry,
            }}
          />
        </Typography>
      </div>
    </button>
  );
}
