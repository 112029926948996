import { MutationPauseTreatmentArgs } from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const pauseTreatmentMutation = gql`
  mutation PauseUserTreatment(
    $id: String!
    $reason: String
    $isReview: Boolean
    $reasonExtended: String
  ) {
    pauseTreatment(
      id: $id
      reason: $reason
      isReview: $isReview
      reasonExtended: $reasonExtended
    ) {
      id
      status
    }
  }
`;

export function usePauseTreatment(
  options: MutationHookOptions<Response, MutationPauseTreatmentArgs>,
): MutationTuple<Response, MutationPauseTreatmentArgs> {
  return useMutation<Response, MutationPauseTreatmentArgs>(
    pauseTreatmentMutation,
    options,
  );
}
