import { gql, useQuery } from '@apollo/client';
import {
  MedicareRedirectQuery,
  MedicareRedirectQueryVariables,
} from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';
import { useIHI } from '../';

const MEDICARE_REDIRECT_QUERY = gql`
  query MedicareRedirect($id: String!) {
    consultation(id: $id) {
      id
      type
      stage
    }
    profile {
      id
      residentialAddress {
        id
      }
    }
  }
`;

export const useMedicareRedirect = (
  consultationId: string,
  nextPath: string,
  profilePath: string,
): { loading: boolean } => {
  const history = useHistory();
  const { data, loading } = useQuery<
    MedicareRedirectQuery,
    MedicareRedirectQueryVariables
  >(MEDICARE_REDIRECT_QUERY, {
    variables: { id: consultationId },
    fetchPolicy: 'cache-and-network',
  });

  const { ihiCollected, ihiDataLoading, medicareDetailsCollected } = useIHI(
    data?.consultation?.type,
  );

  const isInitial = data?.consultation?.stage === 'INITIAL';

  if (loading || ihiDataLoading) {
    return { loading: true };
  }

  if (ihiCollected || medicareDetailsCollected) {
    if (!isInitial) {
      history.push(profilePath);
    } else {
      history.push(nextPath);
    }
  }

  return { loading: false };
};
