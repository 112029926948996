import { getConfig } from '@customer-frontend/config';
import { CheckoutLayout } from '@customer-frontend/page-templates';
import {
  SkipDoctorApproveButton,
  useGetDefaultClinicianNounByCountryCode,
} from '@customer-frontend/post-quiz';
import {
  Button,
  ButtonPalette,
  Card,
  Divider,
  Typography,
} from '@eucalyptusvc/design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

interface ConsultationConfirmationPageView {
  MainIcon: React.FC;
  isConsultFree: boolean;
  onCtaClick: () => void;
  email?: string;
  buttonPalette: ButtonPalette;
  profilePath: string;
  children: React.ReactNode;
}

export const ConsultationConfirmationPageView: React.FC<
  ConsultationConfirmationPageView
> = ({
  MainIcon,
  onCtaClick,
  isConsultFree,
  children,
  email,
  buttonPalette,
  profilePath,
}) => {
  const { consultationId } = useParams<{ consultationId: string }>();
  const config = getConfig();
  const clinicianNoun = useGetDefaultClinicianNounByCountryCode(
    config.countryCode,
  );

  return (
    <CheckoutLayout>
      <div className="space-y-8 md:space-y-9">
        <div className="flex justify-center">
          <MainIcon />
        </div>
        <Card>
          <Typography size="md" element="h1" isBold>
            <FormattedMessage
              defaultMessage="{isConsultFree, select, true {You're ready to take the next steps} other {We've received your payment}}"
              description="text on the confirm consultation page to show if they need to pay for the consultation or not"
              values={{
                isConsultFree,
              }}
            />
          </Typography>
          <Divider variant="separator" />
          <div className="space-y-4">
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Your {clinicianNoun} will review your responses."
                description="text on confirmation page to show the user that practitioner will be reviewing their consultation answers"
                values={{
                  clinicianNoun,
                }}
              />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="You'll be notified if they have further questions for you."
                description="text on confirmation page to notify the user that practitioner will have more questions for them"
              />
            </Typography>
            {!isConsultFree && (
              <Typography size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="We have sent a receipt to {hasEmail, select, true {<strong>{email}</strong>} other {your email}}"
                  description="text to show where the user has been notified after the consultation has been confirmed"
                  values={{
                    hasEmail: !!email,
                    email: email,
                    strong: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </Typography>
            )}
          </div>
        </Card>
        {children}
        <Button
          level="secondary"
          palette={buttonPalette}
          isFullWidth
          onClick={onCtaClick}
        >
          <FormattedMessage
            defaultMessage="Back to home"
            description="button text on the confirm consultation page to return the user back to profile"
          />
        </Button>
      </div>
      <div className="mt-4">
        <SkipDoctorApproveButton
          consultationId={consultationId}
          profilePath={profilePath}
        />
      </div>
    </CheckoutLayout>
  );
};
