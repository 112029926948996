import { gql } from '@apollo/client';

export const userInfoFragment = gql`
  fragment UserInfo on User {
    id
    email
    firstName
    lastName
    shortAddressableName
    fullName
    phone
    role
    isPasswordSet
    defaultPaymentGateway
    birthday
    preferredUnitSystem
    residentialAddress {
      id
      line1
      line2
      city
      postalCode
      state
      country
      company
      building
    }
    address {
      id
      line1
      line2
      city
      postalCode
      state
      country
      company
      building
    }
    savedPaymentMethods {
      id
      gateway
      default
      ... on SavedCardPaymentMethod {
        brand
        expiry
        mask
      }
      ... on SavedPayPalPaymentMethod {
        email
      }
      ... on SavedZipPaymentMethod {
        valid
      }
    }
    zip {
      valid
    }
  }
`;
