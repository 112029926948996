import React from 'react';

import {
  ModalOverlay,
  ModalContent,
  Modal as ChakraModal,
} from '@chakra-ui/react';

import { ModalWrapperProps } from './types';

export const ModalWrapper = ({
  isOpen,
  onClose,
  children,
  variant,
}: ModalWrapperProps): React.ReactElement => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      size={variant}
      scrollBehavior="inside"
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>{children}</ModalContent>
    </ChakraModal>
  );
};
