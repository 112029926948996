import React, { ElementType } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { DefaultLayout } from './default-layout';

interface PublicRouteProps extends RouteProps {
  layout?: ElementType;
}

export const PublicRoute = ({
  children,
  layout: Layout = DefaultLayout,
  ...rest
}: PublicRouteProps): React.ReactElement => {
  return (
    <Route {...rest}>
      <Layout key={rest.path}>{children}</Layout>
    </Route>
  );
};
