import React from 'react';
import { usePathologyLocations } from '@customer-frontend/services';
import {
  Button,
  Typography,
  LoadingSpinner,
} from '@eucalyptusvc/design-system';

export const PathologyLocations = (): React.ReactElement | null => {
  const { value: pathologyLocationsData, loading: pathologyLocationsLoading } =
    usePathologyLocations();

  const pathologyLocations = pathologyLocationsData?.pathologyLocations;

  if (pathologyLocationsLoading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  return pathologyLocations?.length ? (
    <div className="space-y-5">
      <Typography size="medium-paragraph">Locations near you:</Typography>
      <ul className="space-y-3 flex flex-col">
        {pathologyLocations
          .filter((location) => location.url != null)
          .map((pathologyLocation) => (
            <li key={pathologyLocation.url}>
              <Button
                level="secondary"
                isFullWidth
                onClick={() => {
                  if (pathologyLocation.url) {
                    window.open(pathologyLocation.url);
                  }
                }}
              >
                {pathologyLocation.display}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  ) : (
    <></>
  );
};
