import { useCallback } from 'react';
import { BillingInterval } from '@customer-frontend/graphql-types';
import { formatCentsToCurrency, getRxVariantPrice, useCadenceString } from '.';
import { useIntl } from 'react-intl';

export const useGetLocalisedProductPriceFormatters = (
  product?: {
    plan?: {
      interval?: BillingInterval;
      intervalCount?: number;
    } | null;
    variants: {
      isRefill: boolean;
      price?: number | null;
    }[];
  } | null,
): {
  getLocalisedProductPricePerCadenceString: () => string;
} => {
  const { formatMessage } = useIntl();
  const productCadence = useCadenceString(product);

  const getLocalisedProductPricePerCadenceString = useCallback(() => {
    let variantPrice;
    if (product) {
      variantPrice = getRxVariantPrice(product);
    }
    if (!variantPrice) {
      return '';
    }

    const formatVariantPrice = formatCentsToCurrency(variantPrice);

    return formatMessage(
      {
        defaultMessage: `{pricePerCadence} for the first {cadence}`,
        description:
          'Text explaining how much money the customer is paying per cadence (i.e $2 for months, week, day)',
      },
      {
        cadence: productCadence,
        pricePerCadence: formatVariantPrice,
      },
    );
  }, [product, productCadence, formatMessage]);

  return {
    getLocalisedProductPricePerCadenceString,
  };
};
