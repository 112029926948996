import { colors as pilotColors } from '@eucalyptusvc/design-system/src/theme/pilot/palette';
import EmptyChartImageEnhanced from '../assets/pilot-empty-chart-enhanced.png';
import EmptyChartImageSimplified from '../assets/pilot-empty-chart-simplified.png';
import { TrackerBrandTheme } from './types';

export const theme: TrackerBrandTheme = {
  trackerChart: {
    customDot: {
      text: {
        colorClassName: 'text-primary-800',
        fontWeightClassName: 'font-semibold',
        fontFamily: 'Right Grotesk',
        letterSpacing: 2,
      },
    },
    line: {
      colorClassName: 'text-warm-500',
    },
  },
  enhancedTrackerChart: {
    title: {
      color: pilotColors.neutral.white,
    },
    text: {
      primaryColor: pilotColors.primary[400],
      secondaryColor: pilotColors.secondary.navy[300],
    },
    line: {
      color: pilotColors.primary[400],
      strokeWidth: 5,
    },
    averageLine: {
      color: pilotColors.secondary.navy[300],
      strokeWidth: 2,
    },
    dot: {
      stroke: pilotColors.primary[400],
      strokeWidth: 2,
      fill: pilotColors.neutral.white,
      radius: 6,
    },
    dotSelected: {
      stroke: pilotColors.primary[500],
      strokeWidth: 2,
      radius: 8,
      fill: pilotColors.primary[100],
      outer: {
        radius: 16,
        fill: 'rgba(25, 116, 205, 0.5)',
      },
    },
    area: {
      fill: pilotColors.reserved.weight,
    },
    cursor: {
      color: pilotColors.neutral[500],
    },
  },
  trackerContent: {
    titleClassName: 'text-primary-900',
    currentWeight: {
      titleClassName: 'text-primary-400',
      valueClassName: 'text-primary-400',
    },
    dateClassName: 'text-gray-400 font-bold uppercase',
    weightDifferencePercent: {
      textClassName: 'text-tracker-improved-500 font-semibold font-header',
      bg: {
        defaultClassName: 'bg-tracker-improved-300',
        accentClassName: 'bg-tracker-improved-300',
      },
    },
    weightDecreaseClassName: 'text-success-500',
    weightIncreaseClassName: 'text-primary-700',
  },
  trackerCard: {
    emptyState: {
      contentClassName: 'bg-primary-50',
    },
  },
  trackerChartEmptyState: {
    containerClassName: 'border border-neutral-300 bg-neutral-100',
    backgroundImage: {
      simplified: EmptyChartImageSimplified,
      enhanced: EmptyChartImageEnhanced,
    },
  },
};
