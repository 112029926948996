import { FormattedMessage, useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ActionModal,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { IntlMobileNumberInput } from '@customer-frontend/intl';
import { gql, useMutation } from '@apollo/client';
import {
  ScheduleCallPhoneNumberModalFragment,
  ScheduleCallUpdatePhoneNumberMutation,
  ScheduleCallUpdatePhoneNumberMutationVariables,
} from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';

type ScheduleCallPhoneNumberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  validPhoneRegions: ScheduleCallPhoneNumberModalFragment['validPhoneRegions'];
};

export const phoneNumberModalFragment = gql`
  fragment ScheduleCallPhoneNumberModal on Query {
    validPhoneRegions {
      id
      countryCode
    }
  }
`;

export const ScheduleCallPhoneNumberModal = ({
  isOpen,
  onClose,
  validPhoneRegions,
}: ScheduleCallPhoneNumberModalProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const [updatePhoneNumberMutation, { loading: isMutationLoading }] =
    useMutation<
      ScheduleCallUpdatePhoneNumberMutation,
      ScheduleCallUpdatePhoneNumberMutationVariables
    >(gql`
      mutation ScheduleCallUpdatePhoneNumber($phone: String) {
        updateProfile(phone: $phone) {
          id
          phone
        }
      }
    `);
  const form = useForm({
    defaultValues: {
      phone: '',
    },
  });

  const { register, handleSubmit, errors } = form;

  const onSubmit = handleSubmit(async ({ phone }): Promise<void> => {
    try {
      await updatePhoneNumberMutation({
        variables: {
          phone,
        },
      });
      onClose();
    } catch (e) {
      // graphql errors are handled by middleware
    }
  });

  const handleSubmitClick = (): void => {
    onSubmit();
  };

  if (!validPhoneRegions) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <ActionModal
      actionRequired
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        defaultMessage: 'Please enter your phone number',
        description: 'Modal heading prompting customer to enter phone number',
      })}
      primaryAction={{
        label: 'Submit',
        onClick: handleSubmitClick,
        isLoading: isMutationLoading,
      }}
    >
      <div className="flex flex-col space-y-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Please enter a valid phone number so that you can receive a call from one of our {isGb, select, true {prescribers} other {practitioners}}."
            description="Subheading on phone call modal screen prompting patient to enter phone number to receive practitioner call"
            values={{ isGb: getConfig().countryCode === 'GB' }}
          />
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={onSubmit}>
            <div>
              <IntlMobileNumberInput
                countryCodes={validPhoneRegions.map(
                  ({ countryCode }) => countryCode,
                )}
                register={register}
                errorMessage={errors?.phone?.message}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </ActionModal>
  );
};
