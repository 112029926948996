import { ComponentStyleConfig } from '@chakra-ui/react';
import { getImageUploadStyles, imageUploadParts as parts } from '../../shared';
import { colors } from '../palette';

export const ImageUpload: ComponentStyleConfig = {
  parts,
  baseStyle: (props) =>
    getImageUploadStyles({
      props,
      styleOverrides: {
        container: {
          border: '1px solid',
          borderRadius: '1rem',
          bg: colors.primary[100],
          borderColor: props.hasError
            ? colors.status.error[500]
            : colors.primary[600],
        },
        placeholder: {
          border: `1px dashed ${colors.primary[600]}`,
          backgroundColor: colors.primary[100],
        },
      },
    }),
};
