import React from 'react';
import { notificationService } from '@customer-frontend/notifications';
import { Button } from '@eucalyptusvc/design-system';
import { isIOS, showFile } from '@customer-frontend/utils';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';

export const PathologyReferralDownload = ({
  signedReferralPdfUrl,
  customerLastName,
}: {
  signedReferralPdfUrl?: string;
  customerLastName?: string | null;
}): React.ReactElement => {
  const config = getConfig();
  const [downloadingReferral, setDownloadingReferral] = React.useState(false);

  const showDownloadErrorNotification = (): void => {
    notificationService.show({
      type: 'error',
      message:
        'Could not download your referral, please contact customer support.',
    });
  };

  const handleDownloadReferral = React.useCallback(async (): Promise<void> => {
    try {
      setDownloadingReferral(true);

      if (!signedReferralPdfUrl) {
        showDownloadErrorNotification();
        return;
      }

      // Open new tab for iOS, as blob download is not supported
      //
      if (isIOS()) {
        window.open(signedReferralPdfUrl);
        return;
      }

      const res = await fetch(signedReferralPdfUrl);
      const blob = await res.blob();
      showFile(blob, `${customerLastName ?? 'pathology'}-request.pdf`);
    } catch {
      showDownloadErrorNotification();
    } finally {
      setDownloadingReferral(false);
    }
  }, [signedReferralPdfUrl, customerLastName]);

  return (
    <Button
      level="primary"
      palette={getPrimaryButtonPalette(config.brand)}
      isFullWidth
      onClick={handleDownloadReferral}
      isLoading={downloadingReferral}
    >
      <div className="flex space-x-3 items-center justify-center">
        <span>Download form</span>
      </div>
    </Button>
  );
};
