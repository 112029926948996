import { FunctionComponent, ReactNode } from 'react';
import clsx from 'clsx';

export type CheckoutLayoutProps = {
  padding?: 'sm' | 'md' | 'lg' | 'xl';
  paddingMd?: 'sm' | 'md' | 'lg' | 'xl';
  wide?: boolean;
  children: ReactNode;
};

export const CheckoutLayout: FunctionComponent<CheckoutLayoutProps> = ({
  padding = 'xl',
  paddingMd = 'xl',
  wide = false,
  children,
}) => (
  <main
    className={clsx(`max-w-screen-sm mx-auto px-4`, {
      'md:max-w-screen-md': wide,
      'py-4': padding === 'sm',
      'py-6': padding === 'md',
      'py-8': padding === 'lg',
      'py-12': padding === 'xl',
      'md:py-4': paddingMd === 'sm',
      'md:py-6': paddingMd === 'md',
      'md:py-8': paddingMd === 'lg',
      'md:py-12': paddingMd === 'xl',
    })}
  >
    {children}
  </main>
);
