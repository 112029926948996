import { ExperimentPaymentPlanVariant } from '@customer-frontend/graphql-types';
import { getNumberOfOrdersFromVariant } from './get-number-of-orders-from-variant';

export const calculatePaymentPlanChargeAmount = ({
  recurringCharge,
  oneTimeCharge,
  experimentPaymentPlan,
}: {
  recurringCharge: number;
  oneTimeCharge: number;
  experimentPaymentPlan: {
    paymentPlan: ExperimentPaymentPlanVariant;
    discountPercentage: number;
  };
}): { totalPrice: number; amountSaved: number; perOrder: number } => {
  const totalPrice = Math.ceil(
    recurringCharge *
      getNumberOfOrdersFromVariant(experimentPaymentPlan.paymentPlan) *
      (1 - experimentPaymentPlan.discountPercentage / 100) +
      oneTimeCharge,
  );

  return {
    totalPrice,
    amountSaved:
      recurringCharge *
        getNumberOfOrdersFromVariant(experimentPaymentPlan.paymentPlan) +
      oneTimeCharge -
      totalPrice,
    perOrder: Math.ceil(
      totalPrice /
        getNumberOfOrdersFromVariant(experimentPaymentPlan.paymentPlan),
    ),
  };
};
