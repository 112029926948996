import { useMutation, gql, MutationTuple } from '@apollo/client';
import { MutationAnswerDoctorQuestionsArgs } from '@customer-frontend/graphql-types';

const answerDoctorsQuestionMutation = gql`
  mutation AnswerDoctorQuestions($consultationId: String!, $answers: Json!) {
    answerDoctorQuestions(consultationId: $consultationId, answers: $answers) {
      id
    }
  }
`;

export const useAnswerDoctorQuestions = (): MutationTuple<
  Response,
  MutationAnswerDoctorQuestionsArgs
> =>
  useMutation<Response, MutationAnswerDoctorQuestionsArgs>(
    answerDoctorsQuestionMutation,
  );
