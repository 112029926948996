import { IUiStorage } from './IUiStorage';
import { UiStorageType } from './type';

const MEM: Record<string, string | undefined> = {};

class InMemUiStorage implements IUiStorage {
  isSupported(): boolean {
    return true;
  }
  public getType(): UiStorageType {
    return 'in_mem';
  }
  public setValue(key: string, value: string): void {
    MEM[key] = value;
  }
  public getValue(key: string): string | undefined {
    return MEM[key];
  }
  public clearValue(key: string): void {
    delete MEM[key];
  }
  public clear(): void {
    for (const key of Object.keys(MEM)) {
      delete MEM[key];
    }
  }
}

export const inMemUiStorage = new InMemUiStorage();
