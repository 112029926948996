import { ReactElement, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { GqlErrorCode } from '@eucalyptusvc/errors';
import {
  Button,
  Card,
  CardPalette,
  MaskedInput,
  Divider,
  Dropdown,
  LoadingSpinner,
  TextInput,
  Typography,
  TypographySize,
} from '@eucalyptusvc/design-system';
import { ProblemType } from '@customer-frontend/graphql-types';

import {
  combineRules,
  medicareCardExpiryValidation,
  medicareIrnValidation,
  medicareNumberValidation,
  maxMaskedDateExclusive,
  minMaskedDateInclusive,
  validMaskedDate,
  requiredValidation,
  formatISODateToLocale,
} from '@customer-frontend/utils';
import { useMedicareDetailsForm, usePatientAndHealthcareAttrs } from '../logic';
import { optedNoMedicare } from '../utils';
import { MedicareComponentAttributes, MedicareFormOnPage } from './types';
import { notificationService } from '@customer-frontend/notifications';
import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import { useAuth } from '@customer-frontend/auth';
import { MedicareInstructions } from '.';
import { Brand } from '@customer-frontend/types';
import { ReactComponent as Warning } from '../assets/warning.svg';
import { ReactComponent as LockIcon } from '../assets/lock-alt.svg';
import { FillInMedicareDetails } from './fill-in-medicare-details/fill-in-medicare-details';
import { MedicareErrorMessage } from './medicare-error-message';
import { FormattedMessage } from 'react-intl';

const rowClasses = 'flex flex-col md:flex-row md:mb-3 mt-0 md:space-x-3';
const columnSubClasses = 'w-full mb-3 md:mb-0 md:w-1/2';

const medicareCardImgUrls: Partial<Record<Brand, string>> = {
  software:
    'https://assets-juniper.s3.ap-southeast-2.amazonaws.com/medicare+card.png',
  juniper:
    'https://assets-juniper.s3.ap-southeast-2.amazonaws.com/medicare+card.png',
  pilot:
    'https://assets-pilot.s3.ap-southeast-2.amazonaws.com/medicare+card.png',
  kin: 'https://assets-juniper.s3.ap-southeast-2.amazonaws.com/medicare+card.png',
};

type MedicareCollectionFormProps = {
  validationLevel: 'required' | 'all';
  submitButtonText: string;
  afterSubmit?: (
    areMedicareDetailsValid: boolean,
    medicareErrorCodes: GqlErrorCode[],
  ) => void;
  onPage: MedicareFormOnPage;
  nextRoute?: string;
  cardPalette?: CardPalette;
  headerSize?: TypographySize;
  bodySize?: TypographySize;
  onCancel?: () => void;
  problemType?: ProblemType | undefined;
  isMedicareExperimentEnabled?: boolean;
  WrapperComponent?: React.ComponentType;
  secondaryTextColor?: string;
  autoFocus?: boolean;
};

export const MedicareCollectionForm = ({
  validationLevel,
  submitButtonText,
  afterSubmit,
  onPage,
  nextRoute,
  cardPalette,
  headerSize = 'lg',
  onCancel,
  bodySize = 'paragraph',
  problemType,
  WrapperComponent: WrapperComponentProp,
  /** Tailwind class for the desired brand color */
  secondaryTextColor,
  autoFocus = false,
}: MedicareCollectionFormProps): ReactElement => {
  const buttonRowClasses = onCancel ? 'mt-4 grid grid-cols-2 gap-4' : '';
  const [hasError, setHasError] = useState(false);
  const [errorCodes, setErrorCodes] = useState([] as GqlErrorCode[]);
  const event = useEventService();
  const { loggedInUser } = useAuth();
  const config = getConfig();
  const { consultationId } = useParams<{ consultationId: string }>();
  const {
    firstName,
    lastName,
    birthday,
    sex,
    medicareDetailsCollected,
    loading: patientAttrsLoading,
  } = usePatientAndHealthcareAttrs();
  const isConsultPaid = onPage === MedicareFormOnPage.consultPaid;
  const isPostQuiz = [
    MedicareFormOnPage.postFollowUpQuiz,
    MedicareFormOnPage.postReviewQuiz,
    MedicareFormOnPage.postQuiz,
  ].includes(onPage);

  const defaultValues: Partial<MedicareComponentAttributes> = {
    firstName,
    lastName,
    sex,
    birthday: (birthday && formatISODateToLocale(birthday)) ?? '',
  };
  const onMedicareSave = (
    areMedicareDetailsValid: boolean,
    medicareErrorCodes: GqlErrorCode[],
  ): void => {
    if (validationLevel === 'all' && areMedicareDetailsValid) {
      notificationService.show({
        type: 'success',
        message: 'Medicare details saved',
      });
    }

    setHasError(!areMedicareDetailsValid);
    setErrorCodes(medicareErrorCodes);

    if (afterSubmit) {
      afterSubmit(areMedicareDetailsValid, medicareErrorCodes);
    }
  };

  // For analytics, we want to know if our users are struggling with our current
  // inline validation in the UI.
  const onMedicareSubmitBeforeApiCall = (validFormat: boolean): void => {
    if (validFormat) {
      // This analytics event is fired when the user submits medicare details with valid format.
      // It is to be removed when the HI service is enabled (we will replace it with medicare hi verified event).
      event.medicare.formatValid({
        user: loggedInUser?.id,
        email: loggedInUser?.email,
        problemType,
        trigger: onPage,
        consultationId,
      });
    } else {
      event.medicare.formatInvalid({
        user: loggedInUser?.id,
        email: loggedInUser?.email,
        problemType,
        trigger: onPage,
        consultationId,
      });
    }
  };

  const { register, setValue, errors, submit, loading, getValues } =
    useMedicareDetailsForm(
      defaultValues,
      validationLevel,
      onMedicareSubmitBeforeApiCall,
      onMedicareSave,
    );

  useEffect(() => {
    setValue('firstName', firstName ?? '');
    setValue('lastName', lastName ?? '');
    setValue('sex', sex ?? '');
    setValue('birthday', (birthday && formatISODateToLocale(birthday)) ?? '');
  }, [setValue, firstName, lastName, sex, birthday]);

  if (patientAttrsLoading) {
    return (
      <div className="flex justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (medicareDetailsCollected) {
    if (
      [
        MedicareFormOnPage.postQuiz,
        MedicareFormOnPage.postFollowUpQuiz,
        MedicareFormOnPage.postReviewQuiz,
      ].includes(onPage) &&
      nextRoute
    ) {
      return <Redirect to={nextRoute} />;
    } else if (isConsultPaid) {
      return <></>;
    }
  }

  if (isConsultPaid && optedNoMedicare()) {
    return <></>;
  }

  const validationRules = {
    required: {
      medicareNumber: {
        ...requiredValidation('Medicare number'),
      },
      medicareIrn: {
        ...requiredValidation('Medicare IRN'),
      },
      medicareCardExpiry: {
        ...requiredValidation('Medicare Card Expiry'),
      },
    },
    all: {
      medicareNumber: combineRules(
        requiredValidation('Medicare number'),
        medicareNumberValidation(),
      ),
      medicareIrn: combineRules(
        requiredValidation('Medicare IRN'),
        medicareIrnValidation(),
      ),
      medicareCardExpiry: combineRules(
        requiredValidation('Medicare Card Expiry'),
        medicareCardExpiryValidation(),
      ),
    },
  };

  const WrapperComponent = WrapperComponentProp || Card;

  return (
    <WrapperComponent palette={cardPalette}>
      <form onSubmit={submit}>
        <div className="mb-2">
          {!isPostQuiz && (
            <Typography size={headerSize} isBold>
              Medicare details
            </Typography>
          )}
          {isConsultPaid && <Divider mt="sm" />}
          <div className="space-y-6 mt-8">
            {isConsultPaid && (
              <Typography size={bodySize}>
                <MedicareInstructions
                  medicareDetailsCollected={medicareDetailsCollected}
                />
              </Typography>
            )}
            <div className="space-y-4 mb-2 mt-4">
              <img
                className="w-full"
                src={
                  medicareCardImgUrls[config.brand] ||
                  medicareCardImgUrls.juniper
                }
                alt="medicare card info"
              />
              <TextInput
                type="medicare-number"
                name="medicareNumber"
                label="Card number"
                ref={register(validationRules[validationLevel].medicareNumber)}
                errorMessage={errors?.medicareNumber?.message}
              />
              <div className={rowClasses}>
                <div className={columnSubClasses}>
                  <TextInput
                    name="firstName"
                    label="First name"
                    ref={register({
                      ...requiredValidation('First name'),
                    })}
                    errorMessage={errors?.firstName?.message}
                    autoFocus={autoFocus}
                  />
                </div>
                <div className={columnSubClasses}>
                  <TextInput
                    name="lastName"
                    label="Last name"
                    ref={register({
                      ...requiredValidation('Last name'),
                    })}
                    errorMessage={errors?.lastName?.message}
                  />
                </div>
              </div>
              <div className={rowClasses}>
                <div className={columnSubClasses}>
                  <TextInput
                    name="medicareIrn"
                    type="medicare-irn"
                    label="Individual reference no."
                    ref={register(validationRules[validationLevel].medicareIrn)}
                    errorMessage={errors?.medicareIrn?.message}
                    placeholder="e.g. 1"
                  />
                </div>
                <div className={columnSubClasses}>
                  <MaskedInput
                    name="medicareCardExpiry"
                    label="Expiry"
                    ref={register(
                      validationRules[validationLevel].medicareCardExpiry,
                    )}
                    errorMessage={errors?.medicareCardExpiry?.message}
                    maskType="expiry"
                  />
                </div>
              </div>
            </div>

            <>
              <Divider />
              <div className={rowClasses}>
                <div className={columnSubClasses}>
                  <MaskedInput
                    name="birthday"
                    label="Date of birth"
                    ref={register(
                      combineRules(
                        requiredValidation('birthday'),
                        validMaskedDate(),
                        maxMaskedDateExclusive(new Date()),
                        minMaskedDateInclusive(new Date('1900-01-01')),
                      ),
                    )}
                    maskType="date"
                    errorMessage={errors?.birthday?.message}
                  />
                </div>
                <div className="md:w-1/2">
                  <Dropdown
                    ref={register}
                    label="Sex"
                    name="sex"
                    options={[
                      {
                        label: 'Male',
                        value: 'M',
                      },
                      {
                        label: 'Female',
                        value: 'F',
                      },
                      {
                        label: 'Other',
                        value: 'I',
                      },
                      {
                        label: 'Prefer not to say',
                        value: 'N',
                      },
                    ]}
                  />
                </div>
              </div>
            </>
            <div className={`${buttonRowClasses} space-y-2`}>
              {!!onCancel && (
                <Button level="secondary" onClick={onCancel}>
                  Cancel
                </Button>
              )}
              <>
                <Button
                  isFullWidth
                  isSubmit
                  isLoading={loading}
                  palette="default"
                >
                  {submitButtonText}
                </Button>
                <div className="flex gap-2 items-center justify-center">
                  <LockIcon />
                  <div className={secondaryTextColor}>
                    <Typography inheritColor size="small-text">
                      <FormattedMessage
                        description="Assures patients that the service is ISO27001 and GDPR compliant"
                        defaultMessage="ISO-27001 certified & GDPR compliant"
                      />
                    </Typography>
                  </div>
                </div>
              </>
              <FillInMedicareDetails
                setValue={setValue}
                getValues={getValues}
                register={register}
              />
            </div>
          </div>

          {hasError && (
            <div className="mt-6 bg-status-error-100 border border-status-error-500 p-4 flex flex-row space-x-2 items-start">
              <div className="mt-0.5">
                <Warning />
              </div>
              <Typography size="medium-paragraph">
                <MedicareErrorMessage errorCodes={errorCodes} />
              </Typography>
            </div>
          )}
        </div>
      </form>
    </WrapperComponent>
  );
};
