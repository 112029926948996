import { colors as pilotColors } from '@eucalyptusvc/design-system/src/theme/pilot/palette';
import { EducationBrandTheme } from './types';
import { ReactComponent as PilotChevronRight } from '../assets/icons/pilot-chevron-right.svg';
import { ReactComponent as PilotChevronDown } from '../assets/icons/pilot-chevron-down.svg';
import { ReactComponent as PilotCircleOutline } from '../assets/icons/pilot-circle-outline.svg';
import { ReactComponent as PilotCircleProgressDark } from '../assets/icons/pilot-circle-progress-dark.svg';
import { ReactComponent as PilotCircleProgressLight } from '../assets/icons/pilot-circle-progress-light.svg';
import { ReactComponent as PilotCircleTick } from '../assets/icons/pilot-circle-tick.svg';
import { ReactComponent as PilotTimeOutline } from '../assets/icons/pilot-time-outline.svg';
import { ReactComponent as PilotBook } from '../assets/icons/pilot-book.svg';

export const theme: EducationBrandTheme = {
  nextReadingDashboardCard: {
    textColor: pilotColors.secondary.navy[300],
    readStatusIcon: {
      inProgress: <PilotCircleProgressLight />,
      unread: <PilotBook />,
      read: <PilotBook />,
    },
    timeToRead: {
      icon: <PilotTimeOutline />,
      colorClassName: 'text-secondary-navy-300',
    },
  },
  readingCollectionList: {
    button: {
      bgClassName: 'bg-white bg-opacity-10 group-hover:bg-opacity-20',
      icon: <PilotChevronRight />,
    },
    containerClassName: 'rounded-lg border-neutral-white divide-neutral-white',
  },
  moduleAccordionItem: {
    button: {
      bgClassName: 'bg-primary-100',
      textColor: pilotColors.secondary.navy[300],
      icon: <PilotChevronDown />,
    },
    border: {
      colorClassName: 'border-primary-300',
      radiusClassName: 'rounded-lg',
    },
    dividerClassName: 'divide-primary-300',
    listItem: {
      textColor: pilotColors.neutral.white,
      bgClassName: 'bg-transparent hover:bg-primary-500',
      button: {
        chevronIcon: <PilotChevronRight />,
        borderClassName: 'border-neutral-white',
        bgClassName: 'bg-neutral-white bg-opacity-10',
      },
      readStatusIcon: {
        unread: <PilotCircleOutline />,
        read: <PilotCircleTick />,
        inProgress: <PilotCircleProgressDark />,
      },
    },
    timeToRead: {
      icon: <PilotTimeOutline />,
      color: 'text-secondary-navy-300',
    },
  },
  nextReadingHeroCard: {
    color: 'text-neutral-white',
    buttonPalette: 'alternate',
    containerClassName: 'rounded-lg',
    timeRemainingIcon: <PilotTimeOutline />,
    timeRemainingTextBold: true,
  },
};
