import { MutationDelayTreatmentArgs } from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const delayTreatmentMutation = gql`
  mutation UseDelayTreatment(
    $id: String!
    $newDate: String!
    $trigger: String
  ) {
    delayTreatment(id: $id, newDate: $newDate, trigger: $trigger) {
      id
      nextPayment
      status
    }
  }
`;

export function useDelayTreatment(
  options?: MutationHookOptions<Response, MutationDelayTreatmentArgs>,
): MutationTuple<Response, MutationDelayTreatmentArgs> {
  return useMutation<Response, MutationDelayTreatmentArgs>(
    delayTreatmentMutation,
    options,
  );
}
