import { gql } from '@apollo/client';

export const quizApplicationAnswerFragment = gql`
  fragment QuizApplicationAnswer on QuizApplication {
    id
    problemType
    customerId
    submittedAt
    answers(orderBy: { createdAt: asc }) {
      id
      answer
      answersArray
      englishAnswer: answer(languageCode: EN_AU)
      englishAnswersArray: answersArray(languageCode: EN_AU)
      question {
        id
        shortcode
      }
    }
    consultation {
      id
      status
      isUpfrontPayment
    }
    responses {
      id
      question {
        id
        type
        title
        shortcode
        options {
          value
        }
      }
      response {
        answers {
          value
          imageUrl
        }
        flag {
          level
        }
      }
    }
  }
`;
