import type {
  SystemStyleObject,
  PartsStyleObject,
} from '@chakra-ui/theme-tools';
import { sharedColors } from '../palette';

interface InputThemeProps {
  colors: {
    border: string;
    borderFocused: string;
    shadow: string;
    bg?: string;
    text?: string;
  };
  font: string;
}

const baseFieldStyle: SystemStyleObject = {
  px: '1rem',
  outline: 0,
  paddingInlineStart: '16px',
  paddingInlineEnd: '16px',
  marginTop: '4px',
  marginBottom: '4px',
};

const getVariantOutlineFieldStyle = (
  props: InputThemeProps,
): SystemStyleObject => ({
  borderRadius: '4px',
  bg: props.colors.bg,
  color: props.colors.text,
  borderColor: props.colors.border, // Needed because tailwind
  border: `1px solid ${props.colors.border}`,
  _hover: {
    borderColor: props.colors.border, // Needed because tailwind
  },
  _focus: {
    border: `2px solid ${props.colors.borderFocused}`,
    borderColor: props.colors.borderFocused, // Needed because tailwind
    boxShadow: `0px 0px 0px 3px ${props.colors.shadow}`,
  },
  _invalid: {
    border: `1px solid ${sharedColors.status.error[500]}`,
    borderColor: sharedColors.status.error[500], // Needed because tailwind
    boxShadow: 'none',
  },
});

const getInputStyles = (
  props: InputThemeProps,
): Record<string, PartsStyleObject> => ({
  baseStyle: {
    field: {
      minHeight: '48px',
      fontFamily: props.font,
      ...baseFieldStyle,
    },
  },
  variants: {
    outline: {
      field: getVariantOutlineFieldStyle(props),
    },
  },
});

const getTextareaStyles = (
  props: InputThemeProps,
): Record<string, PartsStyleObject | SystemStyleObject> => ({
  baseStyle: {
    paddingTop: '12px',
    fontFamily: props.font,
    ...baseFieldStyle,
  },
  variants: {
    outline: getVariantOutlineFieldStyle(props),
  },
});

export const getInputComponents = (
  styleProps: InputThemeProps,
): Record<string, SystemStyleObject> => ({
  Input: getInputStyles(styleProps),
  Textarea: getTextareaStyles(styleProps),
});
