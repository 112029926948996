import { useNotification, LoadingSpinner } from '@eucalyptusvc/design-system';
import { defineMessage, useIntl } from 'react-intl';
import { useStripe } from '@stripe/react-stripe-js';
import { useUrlQuery, purgeUrlParams } from '@customer-frontend/utils';
import { ReactElement, useCallback, useEffect } from 'react';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { StripeProvider, useConfirmOrder } from '@customer-frontend/services';
import { Logger } from '@customer-frontend/logger';

const confirmedOrders = new Set<string>();
const seenConfirmOrderIs = new Set<string>();

type OrderStripePaymentResultHandlerProps = {
  consultationId: string;
  onComplete: () => void;
  onError: () => void;
  logger: Logger;
};

export function OrderStripePaymentResultHandlerWithProvider({
  consultationId,
  onComplete,
  onError,
  logger,
}: OrderStripePaymentResultHandlerProps): ReactElement {
  const handleComplete = useCallback((): void => {
    onComplete();
  }, [onComplete]);

  const handleError = useCallback((): void => {
    onError();
  }, [onError]);

  return (
    <StripeProvider stripeSdkOnly api="paymentIntents" logger={logger}>
      <OrderStripePaymentResultHandler
        consultationId={consultationId}
        onComplete={handleComplete}
        onError={handleError}
        logger={logger}
      />
    </StripeProvider>
  );
}

function OrderStripePaymentResultHandler({
  consultationId,
  onComplete,
  onError,
  logger,
}: OrderStripePaymentResultHandlerProps): ReactElement {
  const notification = useNotification();
  const stripe = useStripe();
  const query = useUrlQuery();
  const { formatMessage } = useIntl();
  const clientSecret = query.get('setup_intent_client_secret');
  const redirectStatus = query.get('redirect_status');
  const confirmOrderId = query.get('confirm_order_id');

  const [confirmOrder] = useConfirmOrder();

  const handleComplete = useCallback((): void => {
    onComplete();
  }, [onComplete]);

  const handleError = useCallback((): void => {
    onError();
  }, [onError]);

  useEffect(() => {
    (async () => {
      if (confirmedOrders.has(consultationId)) {
        handleComplete();
        return;
      }

      if (redirectStatus === 'failed') {
        notification.error({
          message: formatMessage({
            defaultMessage:
              'Failed to confirm your order payment. Please try again',
          }),
          duration: 3000,
        });
        handleError();
        return;
      }

      if (stripe && clientSecret) {
        purgeUrlParams();
        try {
          const data = await stripe.retrieveSetupIntent(clientSecret);
          if (data && data.setupIntent) {
            if (confirmOrderId && seenConfirmOrderIs.has(confirmOrderId)) {
              return;
            }
            const paymentMethod = data.setupIntent.payment_method;
            if (paymentMethod) {
              if (confirmOrderId) {
                seenConfirmOrderIs.add(confirmOrderId);
              }
              await confirmOrder({
                variables: {
                  consultationId,
                  stripePaymentMethodId: `${paymentMethod}`,
                },
              });
              confirmedOrders.add(consultationId);
              notification.success({
                message: formatMessage({
                  defaultMessage: `Your payment has been made successfully!`,
                }),
              });
              handleComplete();
            }
          }
        } catch (e) {
          logger.error('Failed to handle stripe return page for customer', {
            error: e,
          });
          const descriptions = getErrorMessageDescriptorsFromError(
            e,
            defineMessage({
              defaultMessage:
                'Failed to confirm your order payment. Please refresh your page and try again',
            }),
          );
          descriptions.forEach((descriptor) =>
            notification.error({
              message: formatMessage(descriptor),
              duration: 10000,
            }),
          );
          handleError();
        }
      }
    })();
  }, [
    confirmOrderId,
    stripe,
    notification,
    clientSecret,
    formatMessage,
    confirmOrder,
    consultationId,
    logger,
    handleComplete,
    handleError,
    redirectStatus,
  ]);
  return (
    <div className="flex justify-center mt-6">
      <LoadingSpinner />
    </div>
  );
}
