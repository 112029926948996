import React from 'react';
import {
  Button,
  TextInput,
  Typography,
  ButtonPalette,
} from '@eucalyptusvc/design-system';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { calculateDiscountedPrice, getDiscountAppliedText } from '../../logic';
import { BaseDiscountCodeFormProps } from './types';
import { ReactComponent as CircleTick } from './assets/circle-tick-green.svg';
import { useDiscountCodeForm } from './use-discount-code-form';
import { FormattedMessage, useIntl } from 'react-intl';

interface DiscountCodeFormProps extends BaseDiscountCodeFormProps {
  subtotal: number;
  buttonPalette?: ButtonPalette;
}

export const DiscountCodeForm = ({
  code,
  stage,
  subtotal,
  onChange,
  defaultCode,
  buttonPalette = 'default',
  products,
  skipProductValidation,
}: DiscountCodeFormProps): React.ReactElement => {
  const {
    handleInputPress,
    handleDiscountChange,
    validateDiscountCodeLoading,
    isRecurringDiscount,
    errorMessage,
    handleDiscountApply,
  } = useDiscountCodeForm({
    code,
    stage,
    onChange,
    defaultCode,
    products,
    skipProductValidation,
  });

  const { amountSaved } = React.useMemo(
    () =>
      calculateDiscountedPrice({
        subtotal,
        discount: code,
        products,
      }),
    [code, products, subtotal],
  );
  const { formatMessage } = useIntl();

  return (
    <div>
      <div className="flex items-end space-x-4">
        <TextInput
          type="text"
          name="discountCode"
          label={formatMessage({
            defaultMessage: 'Discount code',
            description: 'Label for the discount code field',
          })}
          onKeyPress={handleInputPress}
          onChange={handleDiscountChange}
          defaultValue={defaultCode ?? ''}
        />
        <div className="my-1">
          <Button
            level="secondary"
            isLoading={validateDiscountCodeLoading}
            palette={buttonPalette}
            onClick={handleDiscountApply}
          >
            <FormattedMessage
              defaultMessage="Apply"
              description="Button text to apply a discount code"
            />
          </Button>
        </div>
      </div>
      {code && (
        <div className="mt-2 bg-green-100 p-1 rounded-md">
          <Typography
            color={sharedColors.status.success[500]}
            size="small-text"
            isBold
          >
            <span className="flex items-center justify-center space-x-2">
              <CircleTick className="fill-current overflow-visible mr-1" />
              {getDiscountAppliedText({
                code,
                isRecurringDiscount,
                amountSaved,
              })}
            </span>
          </Typography>
        </div>
      )}
      {errorMessage && (
        <div className="mt-2 bg-red-100 py-1 px-2 rounded-md">
          <Typography
            color={sharedColors.status.error[500]}
            size="small-text"
            isBold
          >
            {errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};
