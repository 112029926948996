import React from 'react';

import {
  Stack,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';

import { Button } from '../button';
import { Typography } from '../typography';
import { ActionModalProps } from './types';
import { ModalWrapper } from './wrapper';
import { useResponsive } from '../../hooks';
import { useThemeExtension } from '../../theme/shared';
import { LoadingSpinner } from '../loading-spinner';

export const ActionModal = ({
  title,
  isOpen,
  onClose,
  children,
  size = 'md',
  primaryAction,
  actionRequired,
  secondaryAction,
  contentsLoading,
}: ActionModalProps): React.ReactElement => {
  const { isMobile } = useResponsive();
  const handleClose = (): void => {
    if (!actionRequired) {
      onClose();
    }
  };

  const responsiveVariant = `${size}${isMobile ? '-mobile' : ''}`;
  const buttonStackDirection = isMobile ? 'column-reverse' : 'row';
  const isTitleBold =
    useThemeExtension<boolean>('modal.isTitleBold', false) === true;
  const actionIsLoading =
    primaryAction?.isLoading || secondaryAction?.isLoading || false;

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={handleClose}
      variant={responsiveVariant}
    >
      <ModalHeader>
        {!contentsLoading && (
          <>
            <Typography size="md" isBold={isTitleBold}>
              {title}
            </Typography>
            {!actionRequired && (
              <ModalCloseButton
                isDisabled={actionIsLoading}
                height="16px"
                width="16px"
              />
            )}
          </>
        )}
      </ModalHeader>
      <ModalBody>
        {contentsLoading ? (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner palette="default" />
          </Box>
        ) : (
          <Box mb="48px">{children}</Box>
        )}
      </ModalBody>
      <ModalFooter>
        {!contentsLoading && (
          <Stack
            spacing="8px"
            direction={buttonStackDirection}
            width={isMobile ? '100%' : 'auto'}
          >
            {secondaryAction && (
              <Button
                level="secondary"
                onClick={secondaryAction.onClick}
                isLoading={secondaryAction.isLoading}
                isDisabled={actionIsLoading}
              >
                {secondaryAction.label}
              </Button>
            )}
            {primaryAction && (
              <Button
                level="primary"
                onClick={primaryAction.onClick}
                isLoading={primaryAction.isLoading}
                isDisabled={actionIsLoading}
              >
                {primaryAction.label}
              </Button>
            )}
          </Stack>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
};
