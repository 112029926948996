import { RefObject } from 'react';

export const scrollToRef = (ref: RefObject<HTMLElement>, offset = 40): void => {
  if (ref.current) {
    window.scrollTo({
      top: ref.current.offsetTop - offset,
      left: 0,
      behavior: 'smooth',
    });
  }
};
