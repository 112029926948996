import { gql, QueryResult, useQuery } from '@apollo/client';
import { ProfileAndHealthcareQuery } from '@customer-frontend/graphql-types';

const GET_PROFILE_HEALTH_QUERY = gql`
  query ProfileAndHealthcare {
    profile {
      id
      legalFirstName
      legalLastName
      birthday
      residentialAddress {
        id
      }
      medicalProfile {
        id
        sex
      }
      healthCareDetails {
        id
        hasMedicareDetails
        hasIndividualHealthcareIdentifier
      }
    }
  }
`;

export const useProfileAndHealthcare =
  (): QueryResult<ProfileAndHealthcareQuery> => {
    return useQuery<ProfileAndHealthcareQuery>(GET_PROFILE_HEALTH_QUERY, {
      fetchPolicy: 'cache-and-network',
    });
  };
