import { Card, Typography, Divider } from '@eucalyptusvc/design-system';
import { SubscriptionPlanProduct } from '../types';

import { formatCentsToCurrency } from '@customer-frontend/order';
import { getBaseTextColorClassName } from '../utils';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

export type NextShipmentCardProps = {
  products: SubscriptionPlanProduct[];
  nextPaymentDate: string;
  subTotalCents: number;
  grandTotalCents: number;
};

export const NextShipmentCard: React.FC<NextShipmentCardProps> = ({
  products,
  subTotalCents,
  grandTotalCents,
  nextPaymentDate,
}) => {
  const config = getConfig();
  const baseTextColor = getBaseTextColorClassName(config.brand);
  return (
    <Card>
      <div className="space-y-4">
        <Typography size="lg" isBold>
          Your next shipment
        </Typography>
        {products.map(({ name, photoUrl, price }) => (
          <div className="flex flex-row items-center justify-start" key={name}>
            <div className="flex rounded overflow-hidden w-16 h-auto">
              <img src={photoUrl} alt="" />
            </div>
            <div className="flex-1 ml-4 mr-4">
              <Typography size="large-paragraph">{name}</Typography>
            </div>
            <Typography size="sm" isBold>
              {formatCentsToCurrency(price, { includeDecimals: true })}
            </Typography>
          </div>
        ))}
        <Divider />
        <div className="flex flex-row justify-between">
          <Typography size="sm" isBold>
            Subtotal
          </Typography>
          <Typography size="sm" isBold>
            {formatCentsToCurrency(subTotalCents, { includeDecimals: true })}
          </Typography>
        </div>
        <div className="flex flex-row justify-between pb-4">
          <Typography size="sm" isBold>
            Next order total
          </Typography>
          <Typography size="sm" isBold>
            {formatCentsToCurrency(grandTotalCents, { includeDecimals: true })}
          </Typography>
        </div>
        <Typography size="small-text" color={baseTextColor}>
          This will automatically be deducted from your default payment method
          on the{' '}
          {formatDate(mapBrandToAdaptersBrand(config.brand), nextPaymentDate, {
            dateStyle: 'long',
          })}
          .
        </Typography>
      </div>
    </Card>
  );
};
