import React from 'react';
import clsx from 'clsx';
import { Typography } from '@eucalyptusvc/design-system';
import { useWeightDashboardTheme } from './../provider';
import { ActionRequiredPing } from './action-required-ping';

export const Tab = ({
  label,
  isActive,
  onChange,
  actionRequired = false,
}: {
  label: string;
  isActive: boolean;
  actionRequired?: boolean;
  onChange: () => void;
}): React.ReactElement => {
  const { brand: brandTheme } = useWeightDashboardTheme();
  const handleOnClick = (): void => {
    onChange();
  };
  const activeClasses = clsx(
    brandTheme?.tab?.bgColorClassName?.active,
    brandTheme?.tab?.borderColorClassName,
  );
  const defaultClasses = clsx(
    'border-transparent',
    brandTheme?.tab?.bgColorClassName?.default,
  );

  return (
    <button
      className={clsx(
        'flex-1 flex items-center justify-center py-4 md:py-6 border-b-2',
        brandTheme?.tab?.textColorClassName,
        {
          [activeClasses]: isActive,
          [defaultClasses]: !isActive,
        },
      )}
      onClick={handleOnClick}
    >
      <Typography size="medium-paragraph" isBold={isActive}>
        {label}
      </Typography>
      {actionRequired && (
        <div className="flex items-center relative h-full">
          <div
            className={`absolute left-1 ${brandTheme?.tab?.unreadIndicator?.marginTopClassName}`}
          >
            <ActionRequiredPing />
          </div>
        </div>
      )}
    </button>
  );
};
