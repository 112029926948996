import React from 'react';
import {
  QuizQuestion,
  QuizApplicationResponse,
  MutationAnswerApplicationQuestionsArgs,
  QuizApplication,
  QuizQuestionType,
} from '@customer-frontend/graphql-types';

export interface NextParams {
  answerId?: string;
  answer?: string;
  answersArray?: string[];
  question: {
    id: string;
    type: QuizQuestionType;
  };
}

export interface QuizState {
  activeQuestionIndex?: number;
  question?: QuizQuestion;
  answer?: QuizApplicationResponse;
  next: (params: NextParams) => Promise<void>;
  loading?: boolean;
  prefill?: string;
  completeQuiz?: (
    args: Omit<MutationAnswerApplicationQuestionsArgs, 'applicationId'>,
  ) => Promise<void>;
  quizApplication?: QuizApplication;
}

const QuizStateContext = React.createContext<QuizState>({
  next: (): Promise<void> => {
    return Promise.reject('quiz state consumer used outside of context');
  },
  completeQuiz: (): Promise<void> => {
    return Promise.reject('quiz state consumer used outside of context');
  },
});

export const QuizStateProvider = QuizStateContext.Provider;
export const QuizStateConsumer = QuizStateContext.Consumer;
export function useQuizState(): QuizState {
  return React.useContext(QuizStateContext);
}
