import {
  Consultation,
  Maybe,
  OtcScheduleCadence,
  Product,
  Treatment,
} from '@customer-frontend/graphql-types';

export interface OtcProductWithCadence {
  id: string;
  cadence: OtcScheduleCadence;
  quantity?: number;
}

export const getActiveOtcProductIdsFromConsultation = (
  consultation?: Consultation,
): string[] => {
  return (
    consultation?.treatment?.otcSchedules
      .filter((sched) => sched.isActive)
      .map((sched) => sched.productId) ?? []
  );
};
export const getActiveOtcProductWithCadenceFromTreatment = (
  treatment: Maybe<Treatment>,
): OtcProductWithCadence[] => {
  return (
    treatment?.otcSchedules
      .filter((sched) => sched.isActive)
      .map((sched) => ({
        id: sched.productId || sched.product.id,
        cadence: sched.cadence,
        quantity: sched.quantity,
      })) ?? []
  );
};

export const getFilteredOtcProducts = (
  products: Maybe<Product[]>,
): {
  pinnedProduct: Maybe<Product>;
  unpinnedProducts: Product[];
} => {
  let pinnedProduct: Maybe<Product>;
  const unpinnedProducts: Product[] = [];

  if (products) {
    products.forEach((product) => {
      if (!product.variants?.[0]?.public) {
        return;
      }

      if (product.priority > 0 && !pinnedProduct) {
        pinnedProduct = product;
      } else {
        unpinnedProducts.push(product);
      }
    });
  }

  return { pinnedProduct, unpinnedProducts };
};

export const upsertCartProducts = (
  cartProducts: OtcProductWithCadence[],
  newProduct: OtcProductWithCadence,
): OtcProductWithCadence[] => {
  const productInCartIndex = cartProducts.findIndex(
    (cProd) => cProd.id === newProduct.id,
  );

  // <0 = Not in cart
  if (productInCartIndex < 0) {
    return [...cartProducts, newProduct];
  } else {
    const newProducts = [...cartProducts];
    newProducts[productInCartIndex] = newProduct;
    return newProducts;
  }
};

export const getUpdatedProducts = (
  existingValues: OtcProductWithCadence[],
  updatedValues: OtcProductWithCadence[],
): {
  upsertedProducts: OtcProductWithCadence[];
  addedProducts: OtcProductWithCadence[];
  removedProductIds: string[];
} => {
  const addedProducts = updatedValues.filter(function (obj) {
    return !existingValues.some(function (obj2) {
      return obj.id === obj2.id;
    });
  });

  const upsertedProducts = updatedValues.filter(function (obj) {
    return existingValues.some(
      (obj2) =>
        obj2.id === obj.id &&
        (obj2.cadence !== obj.cadence || obj2.quantity !== obj.quantity),
    );
  });

  const removedProductIds = existingValues
    .filter(function (obj) {
      return !updatedValues.some(function (obj2) {
        return obj.id === obj2.id;
      });
    })
    .map((p) => p.id);

  return {
    addedProducts,
    removedProductIds,
    upsertedProducts,
  };
};
