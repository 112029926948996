import { type ReactElement } from 'react';
import { getConfig } from '@customer-frontend/config';
import { Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

export function AnyQuestions(): ReactElement {
  const config = getConfig();

  return (
    <section className="space-y-4">
      <Typography isBold size="md">
        <FormattedMessage
          defaultMessage="Any questions?"
          description="Text thats asks if the patient has any questions"
        />
      </Typography>

      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="Please feel free to send us an email to <a>{supportEmail}</a> and we will be sure to clarify any questions you may have."
          description="Text that tells the patient to email support if they have any questions."
          values={{
            a: (chunks) => (
              <a
                className="font-semibold underline"
                href={`mailto:${config.supportEmail}`}
              >
                {chunks}
              </a>
            ),
            supportEmail: config.supportEmail,
          }}
        />
      </Typography>
    </section>
  );
}
