import {
  TriggerPasswordResetPayload,
  MutationTriggerPasswordResetArgs,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const triggerPasswordResetDocument = gql`
  mutation TriggerPasswordReset($email: String!) {
    triggerPasswordReset(email: $email) {
      ok
    }
  }
`;

interface Response {
  triggerPasswordReset: TriggerPasswordResetPayload;
}

export function useTriggerPasswordResetMutation(
  options: MutationHookOptions<Response, MutationTriggerPasswordResetArgs>,
): MutationTuple<Response, MutationTriggerPasswordResetArgs> {
  return useMutation<Response, MutationTriggerPasswordResetArgs>(
    triggerPasswordResetDocument,
    options,
  );
}
