import React from 'react';
import { useForm } from 'react-hook-form';
import {
  combineRules,
  formatLocaleDateToISO,
  maxMaskedDateInclusive,
  minMaskedDateInclusive,
  requiredValidation,
  validMaskedDate,
} from '@customer-frontend/utils';
import { notificationService } from '@customer-frontend/notifications';
import {
  Modal,
  Typography,
  TextInput,
  Button,
} from '@eucalyptusvc/design-system';
import { useUpdatePathologyCustomerStatedCollectedAt } from '@customer-frontend/services';
import { useIntl, FormattedMessage } from 'react-intl';

export const AwaitingPathologyTestModal = ({
  pathologyRequest,
  showModal,
  setShowModal,
}: {
  pathologyRequest: {
    id: string;
    createdAt: string;
  };
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}): React.ReactElement | null => {
  const { formatMessage } = useIntl();
  const { register, errors, handleSubmit } = useForm<{ collectedAt: string }>();
  const [updatePathologyCustomerStatedCollectedAtMutation, { loading }] =
    useUpdatePathologyCustomerStatedCollectedAt();
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleFormSubmit = handleSubmit(async ({ collectedAt }) => {
    const formattedCollectedAtDate = formatLocaleDateToISO(collectedAt);

    try {
      await updatePathologyCustomerStatedCollectedAtMutation({
        variables: {
          collectedAt: formattedCollectedAtDate,
          pathologyRequestId: pathologyRequest.id,
        },
      });
      setShowConfirm(true);
    } catch {
      notificationService.show({
        type: 'error',
        message: formatMessage({
          defaultMessage:
            'Could not mark test as complete, please contact customer support.',
        }),
      });
    }
  });

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      title={
        showConfirm
          ? formatMessage({ defaultMessage: 'Thanks!' })
          : formatMessage({
              defaultMessage:
                'Let us know the date when you did your blood test',
            })
      }
    >
      {showConfirm ? (
        <div className="p-5 space-y-6">
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Your practitioner will reach out to you in 2-3 business days with your results." />
          </Typography>
        </div>
      ) : (
        <form className="space-y-4" onSubmit={handleFormSubmit}>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="This will help us keep an eye out for your results."
              description="Explains why we are collecting a piece of information"
            />
          </Typography>
          <div>
            <TextInput
              ref={register(
                combineRules(
                  requiredValidation('date'),
                  validMaskedDate(),
                  minMaskedDateInclusive(new Date(pathologyRequest.createdAt)),
                  maxMaskedDateInclusive(new Date()),
                ),
              )}
              placeholder="DD/MM/YYYY"
              type="masked-date"
              label="Test date"
              name="collectedAt"
              errorMessage={errors?.collectedAt?.message}
            />
          </div>
          <Button isFullWidth level="primary" isSubmit isLoading={loading}>
            <FormattedMessage defaultMessage="Submit" />
          </Button>
        </form>
      )}
    </Modal>
  );
};
