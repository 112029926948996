import { defineMessages } from 'react-intl';

export const appBlurbMessages = {
  medication: defineMessages({
    title: {
      defaultMessage: 'Prescribed medication',
    },
  }),
  support: defineMessages({
    title: {
      defaultMessage: 'Unlimited medical support',
    },
    description: {
      defaultMessage:
        'Contact the Juniper medical support team for assistance anytime.',
    },
  }),
  tools: defineMessages({
    title: {
      defaultMessage: 'Weight loss and health tracking tools',
    },
    description: {
      defaultMessage:
        'Set a goal weight in your app and track progress as you hit milestones along the way. Get deeper insights by connecting health and fitness devices.',
    },
  }),
  content: defineMessages({
    title: {
      defaultMessage: '100+ hours of content including workouts',
    },
    description: {
      defaultMessage:
        'Complete daily actions and work towards goals that are realistic for you. Build muscle, burn fat and fuel yourself with the right foods.',
    },
  }),
};
