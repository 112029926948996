import { Modal, Markdown } from '@eucalyptusvc/design-system';

import type { DoctorModalProps } from './types';
import { DoctorInfo } from '../doctor-info';

export const DoctorModal = ({
  isOpen,
  doctor,
  removeDrPrefix,
  details,
  onClose,
}: DoctorModalProps): JSX.Element => {
  const { bio } = doctor;

  return (
    <Modal
      isOpen={isOpen}
      title={
        <DoctorInfo
          doctor={doctor}
          removeDrPrefix={removeDrPrefix}
          details={details}
        />
      }
      onClose={onClose}
    >
      {bio && <Markdown src={bio} />}
    </Modal>
  );
};
