import React from 'react';
import { QuestionProps } from './types';
import { BaseInputQuestionInput } from './base-input-question-input';

export function NumberQuestionInput({
  question,
  isLoading,
}: QuestionProps): React.ReactElement {
  return (
    <BaseInputQuestionInput
      question={question}
      type="number"
      isLoading={isLoading}
      inputProps={{
        step: 0.01,
      }}
    />
  );
}
