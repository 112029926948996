import { useCallback, useState } from 'react';
import {
  ShippingAddressValidationInput,
  UseAddressValidationReturn,
} from './types';
import { useIsShippingAddressWithinRangeQuery } from '@customer-frontend/services';

export const useShippingAddressValidation = (): UseAddressValidationReturn => {
  const [isShippingAddressWithinRange, setIsShippingAddressWithinRange] =
    useState<boolean>(true);

  const [
    isShippingAddressWithinRangeQuery,
    { loading: isValidatingShippingAddress },
  ] = useIsShippingAddressWithinRangeQuery({
    onCompleted: (data) => {
      setIsShippingAddressWithinRange(data.shippingAddressWithinRange);
    },
    onError: () => {
      // we do not want block user from paying for order when there is an error on api call
      setIsShippingAddressWithinRange(true);
    },
    fetchPolicy: 'network-only',
  });

  const handleAddressValidation = useCallback(
    async (address: ShippingAddressValidationInput): Promise<void> => {
      await isShippingAddressWithinRangeQuery({
        variables: {
          line1: address.line1 || '',
          line2: address.line2 || '',
          suburb: address.city || '',
          postcode: address.postalCode || '',
        },
      });
    },
    [isShippingAddressWithinRangeQuery],
  );
  return {
    handleAddressValidation,
    isShippingAddressWithinRange,
    isValidatingShippingAddress,
  };
};
