import { ObservedMeasurement } from '@customer-frontend/graphql-types';
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInMilliseconds,
  differenceInWeeks,
  isValid,
  startOfMonth,
  getWeek,
} from 'date-fns';
import { weeklyAverageWeightLossJourney } from './average-weight-loss-journey';
import { MessageDescriptor } from 'react-intl';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';

export type TrackerGraphPoint = {
  value: number;
  effectiveFrom: number;
};

const calculateGridPoints = (
  gridLeftPoint: number,
  gridRightPoint: number,
): number[] => {
  const coordScale = gridRightPoint - gridLeftPoint;
  const numberOfGridLines = 7;
  const interval = coordScale / (numberOfGridLines - 1);
  const base = gridLeftPoint;
  const pointsInBetweenBoundaries = [...Array(numberOfGridLines - 2)].map(
    (_, i) => {
      return base + interval * (i + 1);
    },
  );
  return [gridLeftPoint, ...pointsInBetweenBoundaries, gridRightPoint];
};

export const gridVerticalCoordinatesGenerator = (props: {
  offset: { left: number; right: number };
  width: number;
}): number[] => {
  const gridStartingXPoint = props.offset.left;
  const gridEndingXPoint = props.width - props.offset.right;
  return calculateGridPoints(gridStartingXPoint, gridEndingXPoint);
};

export const xAxisTickFormatterFactory =
  (showYear: boolean) =>
  (epochDate: number): string => {
    const config = getConfig();

    return epochDate && isValid(new Date(epochDate))
      ? formatDate(mapBrandToAdaptersBrand(config.brand), epochDate, {
          month: 'short',
          year: showYear ? 'numeric' : undefined,
        })
      : '';
  };

const MAX_POINTS_TO_ADD_LEFT_GAP = 5;
const LEFT_DAY_MARGIN_IF_GRAPH_NOT_FULL = 3;
const RIGHT_DAY_MARGIN = 5;
export const getXAxisBoundaries = (
  entries: TrackerGraphPoint[],
): { xMinBoundary: number; xMaxBoundary: number } => {
  if (!entries || !entries.length) {
    return { xMinBoundary: 0, xMaxBoundary: 0 };
  }
  const { min, max } = getMinAndMaxEffectiveFroms(entries);
  const minTime = min.getTime();
  const leftDayTimeMargin = addDays(
    new Date(0),
    LEFT_DAY_MARGIN_IF_GRAPH_NOT_FULL,
  ).getTime();
  const xMinBoundary =
    entries.length < MAX_POINTS_TO_ADD_LEFT_GAP
      ? minTime - leftDayTimeMargin
      : minTime;

  const startOfNextMonthDate = startOfNextMonth(new Date(max));
  const xMaxBoundary = addDays(
    startOfNextMonthDate,
    RIGHT_DAY_MARGIN,
  ).getTime();

  return { xMinBoundary, xMaxBoundary };
};

const startOfNextMonth = (currentDate: Date): Date => {
  return startOfMonth(addMonths(currentDate, 1));
};

export const transformMeasurementToGraphPoint = (
  observation: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>,
): TrackerGraphPoint => ({
  value: observation.value,
  effectiveFrom: new Date(observation.effectiveFrom).getTime(),
});

export const transformGraphPointToMeasurement = (
  observation: TrackerGraphPoint,
): Pick<ObservedMeasurement, 'effectiveFrom' | 'value'> => ({
  value: observation.value,
  effectiveFrom: new Date(observation.effectiveFrom).toISOString(),
});

export const areEntriesAcrossTwoDifferentYears = (
  entries: TrackerGraphPoint[],
): boolean => {
  const { min, max } = getMinAndMaxEffectiveFroms(entries);
  return max.getFullYear() - min.getFullYear() >= 1;
};

const getMinAndMaxEffectiveFroms = (
  entries: TrackerGraphPoint[],
): { min: Date; max: Date } => {
  const effectiveFroms = entries.map((entry) => entry.effectiveFrom);
  const min = new Date(Math.min(...effectiveFroms));
  const max = new Date(Math.max(...effectiveFroms));
  return { min, max };
};

const Y_AXIS_MARGIN_PERCENTAGE = 30;
const DEFAULT_Y_AXIS_MARGIN = 2;

export const getYAxisBoundaries = (
  entries: TrackerGraphPoint[],
): { yMinBoundary: number; yMaxBoundary: number } => {
  if (!entries || !entries.length) {
    return { yMinBoundary: 0, yMaxBoundary: 0 };
  }
  const { minWeight, maxWeight } = getMinAndMaxWeight(entries);
  const desiredMargin =
    entries.length > 1
      ? (maxWeight - minWeight) * (Y_AXIS_MARGIN_PERCENTAGE / 100)
      : DEFAULT_Y_AXIS_MARGIN;
  return {
    yMinBoundary: minWeight - desiredMargin,
    yMaxBoundary: maxWeight + desiredMargin,
  };
};

const getMinAndMaxWeight = (
  entries: TrackerGraphPoint[],
): { minWeight: number; maxWeight: number } => {
  const weightValues = entries.map((entry) => entry.value);
  const minWeight = Math.min(...weightValues);
  const maxWeight = Math.max(...weightValues);
  return { minWeight, maxWeight };
};

const MS_IN_WEEK = 604800000;
const STARTING_AVERAGE_WEIGHT_MARGIN_KG = 1;
const AVERAGE_WEIGHT_LOSS_AFTER_SUPPLIED_VALUES_KG = 0.1;

const getDiffInWeeksRoundedUp = (
  dateLeft: number,
  dateRight: number,
): number => {
  return Math.ceil(differenceInMilliseconds(dateLeft, dateRight) / MS_IN_WEEK);
};

const roundToOneDecimalPoint = (number: number): number => {
  return Math.round(number * 10) / 10;
};

const buildNextAverageGraphPoint = (
  previousPoint: TrackerGraphPoint,
  currentWeightLoss: number,
): TrackerGraphPoint => ({
  value: roundToOneDecimalPoint(previousPoint.value - currentWeightLoss),
  effectiveFrom: addWeeks(previousPoint.effectiveFrom, 1).getTime(),
});

export const calculateAvgWeightLossLinePoints = (
  firstEntry: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>,
  xBoundariesOnGraph: { xMin: number; xMax: number },
): TrackerGraphPoint[] => {
  const weeksBetweenBoundaries = getDiffInWeeksRoundedUp(
    xBoundariesOnGraph.xMax,
    xBoundariesOnGraph.xMin,
  );

  const weeksSinceFirstEntry = differenceInWeeks(
    xBoundariesOnGraph.xMin,
    new Date(firstEntry.effectiveFrom),
  );

  const averageMeasurementsToCalculateInitialWeight =
    weeklyAverageWeightLossJourney.slice(0, weeksSinceFirstEntry);
  const sumOfLossesForInitialWeight =
    averageMeasurementsToCalculateInitialWeight.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
  const initialWeight =
    firstEntry.value +
    STARTING_AVERAGE_WEIGHT_MARGIN_KG -
    sumOfLossesForInitialWeight;

  const initialValue: TrackerGraphPoint = {
    value: initialWeight,
    effectiveFrom: xBoundariesOnGraph.xMin,
  };

  const averageWeightLossValuesToUseForLine =
    weeklyAverageWeightLossJourney.slice(
      weeksSinceFirstEntry,
      weeksSinceFirstEntry + weeksBetweenBoundaries,
    );

  const numberOfAvgWeightLossValuesToAddAfterLineEnd =
    weeksBetweenBoundaries - averageWeightLossValuesToUseForLine.length;

  const continuedAverageWeightLossValuesToAdd = Array(
    numberOfAvgWeightLossValuesToAddAfterLineEnd,
  ).fill(AVERAGE_WEIGHT_LOSS_AFTER_SUPPLIED_VALUES_KG);

  const averageWeightLossValuesToUseForLineWithContinuedValues = [
    ...averageWeightLossValuesToUseForLine,
    ...continuedAverageWeightLossValuesToAdd,
  ];

  const averageLinePointsForGraph =
    averageWeightLossValuesToUseForLineWithContinuedValues.reduce(
      (graphPoints, currentAverageWeightLoss) => {
        const previousPoint = graphPoints[graphPoints.length - 1];
        const nextPoint = buildNextAverageGraphPoint(
          previousPoint,
          currentAverageWeightLoss,
        );
        graphPoints.push(nextPoint);
        return graphPoints;
      },
      [initialValue],
    );

  return averageLinePointsForGraph;
};

/**
 * This util finds the exact value on the average weight loss line at the
 * effectiveFrom of the provided tracker entry. This can be used to determine
 * if we are above or below the line at any point.
 *
 * It is determined by:
 * - Find the surrounding entries in the avg line
 * - Determine percentage across the avg line period the data point lands on
 * - Apply percentage to the difference in value between the 2 points
 *
 * e.g.
 * - currentPoint is 3.5 days into the week (on the cadence determined from first tracker entry)
 * - At week start the avg line is 90, week end is 89.5
 * - The value difference is 0.5, time difference is 50%
 * - Take off 0.5 * 0.5 = 0.25 = 89.75
 */
export const getCurrentPointOnAvgWeightLossLine = (
  avgWeightLossPoints: TrackerGraphPoint[],
  currentPoint: TrackerGraphPoint,
): number | undefined => {
  // Find the surrounding entries in the avg line given the timestamp of the currentPoint
  const nextPointOnAvgLineIdx = avgWeightLossPoints.findIndex(
    (point) => point.effectiveFrom > currentPoint.effectiveFrom,
  );
  const nextPointOnAvgLine = avgWeightLossPoints[nextPointOnAvgLineIdx];
  const previousPointOnAvgLine = avgWeightLossPoints[nextPointOnAvgLineIdx - 1];

  if (!nextPointOnAvgLine || !previousPointOnAvgLine) {
    return;
  }

  // Should be anywhere between 0 and MS_IN_WEEK (the average line period)
  const timeBetweenPrevAvgAndCurrent =
    currentPoint.effectiveFrom - previousPointOnAvgLine.effectiveFrom;
  const percentageTimeInPeriod = timeBetweenPrevAvgAndCurrent / MS_IN_WEEK;
  const valueDiffBetweenPrevAndNextAvg =
    nextPointOnAvgLine.value - previousPointOnAvgLine.value;
  const valueDiffBetweenPrevAvgAndCurrent =
    percentageTimeInPeriod * valueDiffBetweenPrevAndNextAvg;
  const currentPointOnAvgLine =
    previousPointOnAvgLine.value + valueDiffBetweenPrevAvgAndCurrent;

  return currentPointOnAvgLine;
};

export const getWeeklyMotivationalMessageFromSet = (
  messages: MessageDescriptor[],
  currentPoint: TrackerGraphPoint,
): MessageDescriptor => {
  const weekOfYear = getWeek(currentPoint.effectiveFrom) - 1;
  const messageIndex = weekOfYear % messages.length;

  return messages[messageIndex];
};
