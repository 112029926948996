export const ArrowRight = (): React.ReactElement => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.937505 9.87498L4.81751 5.99498L0.937505 2.11498C0.750252 1.92814 0.64502 1.67449 0.64502 1.40998C0.64502 1.14546 0.750252 0.891807 0.937505 0.704976C1.32751 0.314976 1.95751 0.314976 2.34751 0.704976L6.93751 5.29498C7.32751 5.68498 7.32751 6.31498 6.93751 6.70498L2.34751 11.295C1.95751 11.685 1.32751 11.685 0.937505 11.295C0.557505 10.905 0.547505 10.265 0.937505 9.87498Z"
      className="fill-current"
    />
  </svg>
);
