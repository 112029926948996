import { VFC } from 'react';
import { useWeightDashboardTheme } from '../provider';
import clsx from 'clsx';

export const ActionRequiredPing: VFC = () => {
  const { brand: brandTheme } = useWeightDashboardTheme();

  const indicatorAnimationClass = clsx(
    'animate-pingSlow absolute inline-flex h-2 w-2 rounded-full',
    brandTheme?.tab?.unreadIndicator?.backgroundColourClassName,
  );

  const indicatorClass = clsx(
    'relative inline-flex rounded-full h-2 w-2',
    brandTheme?.tab?.unreadIndicator?.backgroundColourClassName,
  );

  return (
    <span className="flex items-center justify-center h-4 w-4">
      <span className={indicatorAnimationClass} />
      <span className={indicatorClass} />
    </span>
  );
};
