import { Button, Divider, Dropdown } from '@eucalyptusvc/design-system';
import { useEnvironment } from '@customer-frontend/environment';
import {
  DevPatient,
  getDevMedicarePatientDetails,
} from './dev-medicare-patients';

export const FillInMedicareDetails: React.FC<{
  register: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (name: string, value: any) => void;
  getValues: (fieldName: string) => DevPatient;
}> = ({ register, setValue, getValues }) => {
  const environment = useEnvironment();

  if (environment.appEnv === 'production') {
    return null;
  }

  const setValues = (): void => {
    const selectedPatient = getValues('dev-patient');
    const patientDetails = getDevMedicarePatientDetails(selectedPatient);

    setValue('firstName', patientDetails.firstName);
    setValue('lastName', patientDetails.lastName);
    setValue('birthday', patientDetails.birthday);
    setValue('sex', patientDetails.sex);
    setValue('medicareNumber', patientDetails.medicareNumber);
    setValue('medicareIrn', patientDetails.medicareIrn);
    setValue('medicareCardExpiry', patientDetails.medicareCardExpiry);
  };

  return (
    <div className="mt-4">
      <Divider />
      <Dropdown
        ref={register}
        label="Dev Medicare Patient"
        name="dev-patient"
        options={[
          {
            label: 'Aoul Copeland',
            value: 'aoul-copeland',
          },
          {
            label: 'Beryl Hewitt',
            value: 'beryl-hewitt',
          },
          {
            label: 'Beverly Gay',
            value: 'beverly-gay',
          },
          {
            label: 'Brian Alexander',
            value: 'brian-alexander',
          },
          {
            label: 'Brooke Corbett',
            value: 'brooke-corbett',
          },
          {
            label: 'Claude Jacobson',
            value: 'claude-jacobson',
          },
          {
            label: 'Daniella Lamb',
            value: 'daniella-lamb',
          },
          {
            label: 'Desiree Bowles',
            value: 'desiree-bowles',
          },
          {
            label: 'Faye Jenkins',
            value: 'faye-jenkins',
          },
          {
            label: 'Gretel Boyette',
            value: 'gretel-boyette',
          },
          {
            label: 'Herb Sheep',
            value: 'herb-sheep',
          },
          {
            label: 'Horacio Green',
            value: 'horacio-green',
          },
          {
            label: 'Jermiah Clapp',
            value: 'jermiah-clapp',
          },
          {
            label: 'Layla Lanier',
            value: 'layla-lanier',
          },
          {
            label: 'Lisa Barber',
            value: 'lisa-barber',
          },
          {
            label: 'Luke Kelley',
            value: 'luke-kelley',
          },
        ]}
      />
      <div className="mt-2" />
      <Button
        level="secondary"
        palette="default"
        isFullWidth
        onClick={setValues}
      >
        Fill in Medicare details
      </Button>
    </div>
  );
};
