import React from 'react';
import { Token, PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { useGetStripeToken } from '../use-get-stripe-token';
import { UseStripeTokenHandler, UseStripeTokenHandlerParams } from './types';

export const useStripeTokenHandler = ({
  onTokenReceived,
  detailsChanged,
}: UseStripeTokenHandlerParams): UseStripeTokenHandler => {
  const getStripeToken = useGetStripeToken();
  const [loading, setLoading] = React.useState(false);

  const handleTokenReceived = async (
    stripeEvent?: PaymentRequestTokenEvent,
  ): Promise<void> => {
    try {
      setLoading(true);
      let token: Token | undefined;
      if (detailsChanged) {
        const { token: stripeToken, error } = await getStripeToken(stripeEvent);

        if (error || !stripeToken?.card) {
          throw new Error(error?.message ?? 'Payment method is invalid');
        }

        token = stripeToken;
      }

      await onTokenReceived(token);

      stripeEvent?.complete('success');
    } catch (e) {
      stripeEvent?.complete('fail');
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return {
    handleTokenReceived,
    loading,
  };
};
