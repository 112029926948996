import { Typography } from '@eucalyptusvc/design-system';
import { useScript } from '@customer-frontend/utils';
import { FunctionComponent } from 'react';
import { useEnvironment } from '@customer-frontend/environment';

// https://developers.zip.co/docs/assets-implementation (not very helpful...)

/**
 * ZipModalLauncher wraps it's children in a clickable div.
 * Clicking on the the div or it's children will launch the Zip modal.
 * The contents of the modal come from Zip.
 * @param merchantId the public Zip merchant id, unique per brand
 */
export const ZipModalLauncher: FunctionComponent<{
  merchantId: string;
  children: React.ReactNode;
}> = ({ children, merchantId }) => {
  const environment = useEnvironment();
  useScript(
    'https://static.zipmoney.com.au/lib/js/zm-widget-js/dist/zip-widget.min.js',
  );

  return (
    <div
      id="zip-product-widg"
      className="flex items-center cursor-pointer"
      data-zm-merchant={merchantId}
      data-env={environment.appEnv === 'production' ? 'production' : 'sandbox'}
      data-zm-widget="popup"
      data-zm-popup-asset="termsdialog"
    >
      {children}
    </div>
  );
};

export const ZipLogo: FunctionComponent<{ className?: string }> = ({
  className = 'h-7 mr-2',
}) => (
  <img
    src="https://static.zipmoney.com.au/assets/default/footer-tile/footer-tile-new.png"
    className={className}
  />
);

export const ZipTagline: FunctionComponent = () => (
  // eslint-disable-next-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx
  <Typography size="small-text">Zip now, pay later</Typography>
);
