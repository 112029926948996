import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { useProfile } from '@customer-frontend/services';
import { SubscriptionCardList } from '@customer-frontend/subscriptions';
import { Logger } from '@customer-frontend/logger';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';

export type SubscriptionV2CancelledProps = {
  profileRoute: string;
  buildSubscriptionUrl: (subscriptionId: string) => string;
  logger: Logger;
};

export const SubscriptionV2Cancelled: React.FC<
  SubscriptionV2CancelledProps
> = ({ profileRoute, buildSubscriptionUrl, logger }) => {
  const history = useHistory();
  const config = getConfig();
  const { data, loading, refetch } = useProfile({
    subscriptions: true,
  });

  const subscriptionsV2 = data?.profile.subscriptions;

  const cancelledV2Subscriptions = React.useMemo(() => {
    return subscriptionsV2?.filter(({ status }) => status === 'CANCELED');
  }, [subscriptionsV2]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-3">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <Typography size="xl" isBold element="h1">
        Cancelled subscriptions
      </Typography>
      {config.brand === 'pilot' && (
        <Divider variant="separator" palette="alternate" />
      )}
      {cancelledV2Subscriptions?.length ? (
        <SubscriptionCardList
          subscriptions={cancelledV2Subscriptions}
          buildSubscriptionUrl={buildSubscriptionUrl}
          logger={logger}
          refetchData={refetch}
        />
      ) : (
        <>
          <Typography size="large-paragraph">
            You have no cancelled subscriptions.
          </Typography>
          <Button
            level="primary"
            palette={getPrimaryButtonPalette(config.brand)}
            isFullWidth
            onClick={() => history.push(profileRoute)}
          >
            Back to profile
          </Button>
        </>
      )}
    </div>
  );
};
