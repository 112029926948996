import React from 'react';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { OfflinePage } from './view';

interface OfflineProps {
  brandName: string;
  image?: string;
  children: React.ReactNode;
}

export const OfflineWrapper: React.FC<OfflineProps> = ({
  children,
  ...pageProps
}) => {
  const client = useFeatureFlagClient();

  const offline = client.useDynamicBoolean('OFFLINE_FOR_MAINTENANCE');

  if (offline) {
    return <OfflinePage {...pageProps} />;
  }

  return <>{children}</>;
};
