import React from 'react';
import clsx from 'clsx';

/**
 * This is a banner. It has some words in it. It currently is hard-coded to run along
 * the top of the screen. Feel free to update that.
 */

type Props = {
  children: React.ReactNode;
  classname?: string;
};

export const Banner: React.FC<Props> = ({ children, classname }) => {
  return (
    <div
      className={clsx(
        classname,
        'flex fixed flex-col justify-center w-full z-100 min-h-13.5 p-2 md:p-0',
      )}
    >
      {children}
    </div>
  );
};
