import { TypographySize, Element } from './types';

export const getElementFromSize = (size: TypographySize): Element => {
  switch (size) {
    case '2xl':
      return 'h1';
    case 'xl':
      return 'h2';
    case 'lg':
      return 'h3';
    case 'md':
      return 'h4';
    case 'sm':
      return 'h5';
    case 'xs':
      return 'h6';
    case 'small-text':
      return 'span';
    default:
      return 'p';
  }
};
