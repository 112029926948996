import { getConfig } from '@customer-frontend/config';
import {
  IConsultationPaidEvent,
  useEventService,
} from '@customer-frontend/events';
import {
  usePayConsultation,
  useUpdateShipping,
  useZipConsultationPayment,
} from '@customer-frontend/services';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { useNotification } from '@eucalyptusvc/design-system';
import {
  DiscountCodeFormFragment,
  Maybe,
} from '@customer-frontend/graphql-types';
import { useState, useCallback } from 'react';
import { useGetStripeToken } from '../use-get-stripe-token';
import {
  PaymentPayloadWithOptionalAddress,
  ReturnTypeWithOptionalAddress,
  StripePaymentData,
  UsePaymentHandlerProps,
} from './types';
import {
  convertCurrencyBaseUnit,
  getZipURLParams,
  saveDiscountToLocalStorage,
} from '../../';
import { useIntl } from 'react-intl';
import { useStripe, useElements } from '@stripe/react-stripe-js';

export const useConsultationPaymentHandler = ({
  user,
  gateway,
  discount,
  consultation,
  detailsChanged,
  onPaymentSuccess,
  usePaymentIntents,
  paymentIntentReturnUrl,
}: UsePaymentHandlerProps): ReturnTypeWithOptionalAddress => {
  const events = useEventService();
  const config = getConfig();
  const getStripeToken = useGetStripeToken();
  const stripe = useStripe();
  const elements = useElements();

  const notify = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [payConsultation] = usePayConsultation();
  const [updateShipping] = useUpdateShipping();
  const [initiateZipConsultationPayment] = useZipConsultationPayment();
  const { formatMessage } = useIntl();

  const handleStripeChargePayment = async (
    payload: PaymentPayloadWithOptionalAddress,
  ): Promise<Maybe<PaymentRequestTokenEvent>> => {
    let stripePaymentData: Maybe<StripePaymentData>;

    if (detailsChanged && payload.value > 0) {
      const { token, error } = await getStripeToken(payload.stripeEvent);

      if (error || !token?.card) {
        throw new Error(error?.message ?? 'Payment method is invalid.');
      }

      stripePaymentData = {
        token: token.id,
      };
    }

    try {
      await payConsultation({
        variables: {
          id: consultation.id,
          gateway: 'STRIPE',
          ...stripePaymentData,
          ...payload,
        },
      });
      payload.stripeEvent?.complete('success');

      return payload.stripeEvent;
    } catch (e) {
      payload.stripeEvent?.complete('fail');
      throw e;
    }
  };

  const handleStripePaymentIntentPayment = async (
    payload: PaymentPayloadWithOptionalAddress,
  ): Promise<void> => {
    if (detailsChanged && payload.value > 0) {
      if (!(paymentIntentReturnUrl && stripe && elements)) {
        throw new Error('expected stripe to be initialized');
      }
      const returnUrl = new URL(paymentIntentReturnUrl);
      if (payload.couponCode) {
        returnUrl.searchParams.set('couponCode', payload.couponCode);
      }
      const { error } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl.toString(),
        },
      });
      if (error) {
        throw new Error(
          error?.message ??
            formatMessage({
              defaultMessage: 'Failed to confirm your payment',
              description: 'Error message for failing to confirm payment',
            }),
        );
      }
    } else {
      await payConsultation({
        variables: {
          id: consultation.id,
          gateway: 'STRIPE',
          ...payload,
        },
      });
    }
  };

  const initiateZipCheckout = useCallback(
    async (paymentPayload: { discount?: DiscountCodeFormFragment }) => {
      const { discount } = paymentPayload;

      if (discount) {
        saveDiscountToLocalStorage('CONSULTATION_PAID', discount.code);
      }

      const response = await initiateZipConsultationPayment({
        variables: {
          redirectUrl: window.location.href,
          consultationId: consultation.id,
          couponCode: discount?.code,
        },
      });

      if (!response?.data?.initiateZipConsultationPayment) {
        throw new Error('Unable to initiate zip checkout');
      }

      return response.data.initiateZipConsultationPayment.redirectUrl;
    },
    [initiateZipConsultationPayment, consultation.id],
  );

  const handleZipPayment = async (
    paymentPayload: PaymentPayloadWithOptionalAddress,
  ): Promise<void> => {
    const { zipCheckoutId } = getZipURLParams();

    await payConsultation({
      variables: {
        id: consultation.id,
        zipCheckoutId,
        gateway: 'ZIP',
        method: 'zipMethod',
        ...paymentPayload,
      },
    });
  };

  const handlePayment = async (
    payload: PaymentPayloadWithOptionalAddress,
    analyticsData?: Record<string, string | boolean | undefined>,
  ): Promise<void> => {
    try {
      setLoading(true);

      if (payload.address) {
        await updateShipping({
          variables: {
            address: payload.address,
          },
        });
      }

      if (gateway === 'ZIP') {
        if (user.zip?.valid || payload.zipCheckoutId) {
          await handleZipPayment(payload);
        } else {
          const zipUrl = await initiateZipCheckout({
            discount,
          });
          return window.location.assign(zipUrl);
        }
      }

      if (gateway === 'STRIPE') {
        if (usePaymentIntents) {
          await handleStripePaymentIntentPayment(payload);
        } else {
          await handleStripeChargePayment(payload);
        }
      }

      const consultationPaidEvent: IConsultationPaidEvent = {
        currency: config.currency,
        consultationId: consultation.id,
        country: config.country,
        firstName: user.legalFirstName,
        lastName: user.legalLastName,
        phoneNumber: user.phone,
        problemType: consultation.type,
        userId: user.id,
        value: convertCurrencyBaseUnit(payload.value),
        method:
          gateway === 'ZIP'
            ? 'zip'
            : payload.stripeEvent?.walletName ?? 'cardEntry',
        ...analyticsData,
      };
      events.consultation.paid(consultationPaidEvent);
      onPaymentSuccess();
    } catch (error) {
      const descriptions = getErrorMessageDescriptorsFromError(error);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    handlePayment,
    loading,
  };
};
