import React from 'react';
import { useFormContext } from 'react-hook-form';
import { QuestionProps } from './types';
import { TextArea } from '@eucalyptusvc/design-system';
import { useRequiredValidation } from '@customer-frontend/utils';
import { useIntl } from 'react-intl';

export function TextareaQuestionInput({
  question,
  isLoading,
}: QuestionProps): React.ReactElement {
  const { formatMessage } = useIntl();
  const { register, errors } = useFormContext();
  const { required } = question;

  const answerRequiredValidation = useRequiredValidation(
    formatMessage({
      defaultMessage: 'Answer',
    }),
  );

  return (
    <TextArea
      autoFocus
      rows={6}
      name={question.id}
      disabled={isLoading}
      ref={register({ ...(required ? answerRequiredValidation : {}) })}
      errorMessage={errors[question.id]?.message}
    />
  );
}
