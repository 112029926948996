import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { notificationService } from '@customer-frontend/notifications';

export const getZipURLParams = (): {
  result: string | null;
  zipCheckoutId: string | null;
} => {
  const urlParams = new URLSearchParams(window.location.search);

  const result = urlParams.get('result');
  const zipCheckoutId = urlParams.get('checkoutId');

  return { result, zipCheckoutId };
};

export const removeParamsFromLocation = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('result');
  urlParams.delete('checkoutId');
  return urlParams.toString();
};

export enum ZipStatus {
  APPROVED = 'approved',
  REFERRED = 'referred',
  CANCELLED = 'cancelled',
}

export const useZipRedirect = ({
  enabled,
  onRedirectFromZip,
  onApproved,
}: {
  onRedirectFromZip: () => void;
  onApproved: (zipCheckoutId: string) => void;
  enabled: boolean;
}): void => {
  const history = useHistory();

  useEffect(() => {
    // When redirected from zip, they provide some search params to indicate payment status
    const { result, zipCheckoutId } = getZipURLParams();

    // Only run if these parameters are present. They are reset at the end of this effect to ensure it's only run once.
    if (result && zipCheckoutId && enabled) {
      onRedirectFromZip();

      switch (result) {
        case ZipStatus.APPROVED:
          onApproved(zipCheckoutId);
          break;
        case ZipStatus.REFERRED:
          notificationService.show({
            type: 'error',
            message:
              'Your Zip application is pending approval. Please try another payment method.',
          });
          break;
        case ZipStatus.CANCELLED:
          // Do nothing, valid flow
          break;
        default:
          notificationService.show({
            type: 'error',
            message:
              'Your Zip purchase was declined. Please try another payment method.',
          });
      }

      // Reset URL params once action has occured. These are saved as initial state regardless
      history.replace({
        search: removeParamsFromLocation(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);
};
