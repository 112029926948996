import { useNotification } from '@eucalyptusvc/design-system';
import Confetti from 'react-confetti';
import { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { useOrderPaidFreeProductToast } from './use-order-paid-free-product-toast';
import { useIntl } from 'react-intl';

export const FreeProductToast: React.FunctionComponent<{
  showConfetti?: boolean;
  showToast?: boolean;
}> = ({ showConfetti = false, showToast = false }) => {
  const notify = useNotification();
  const { width } = useWindowSize();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (showToast) {
      notify.success({
        message: formatMessage({
          defaultMessage:
            "Success! We've added this item to your cart and applied a 100% discount.",
        }),
      });
    }
  }, [formatMessage, notify, showToast]);

  return showConfetti ? (
    <Confetti
      recycle={false}
      width={width}
      height={document.scrollingElement?.scrollHeight}
      tweenDuration={5000}
      numberOfPieces={400}
      confettiSource={{ x: 0, y: 0, w: width, h: window.scrollY }}
    />
  ) : null;
};

export { useOrderPaidFreeProductToast };
