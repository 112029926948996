import React, { useState, useRef, useEffect, ReactElement } from 'react';
import {
  Button,
  Card,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactComponent as LinkChain } from '../assets/link-chain.svg';
import { ReactComponent as GreenTick } from '../assets/green-tick-v2.svg';
import { MedicareCollectionForm } from './medicare-collection-form';
import { MedicareFormOnPage } from './types';
import { colors as pilotBrandColors } from '@eucalyptusvc/design-system/src/theme/pilot/palette';
import { usePatientAndHealthcareAttrs } from '../logic';
import { scrollToRef } from '@customer-frontend/utils';
import { optedNoMedicare } from '../utils';

const getContent = (
  isMedicareLinked: boolean,
): {
  icon: ReactElement;
  title: string;
  content: string;
} => {
  if (isMedicareLinked) {
    return {
      icon: <GreenTick width="20px" className="mr-2" />,
      title: 'Medicare card linked',
      content: 'You’ve enabled e-scripts and faster order processing.',
    };
  }
  return {
    icon: <LinkChain width="20px" className="mr-2" />,
    title: 'Link your Medicare card (optional)',
    content:
      'To enable e-scripts and faster order processing, link your Medicare card to your profile.',
  };
};

export const MedicareCollectionCard: React.FC = () => {
  const { medicareDetailsCollected, loading: loadingMedicareDetails } =
    usePatientAndHealthcareAttrs();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMedicareLinked, setIsMedicareLinked] = useState(
    medicareDetailsCollected,
  );
  const divRef = useRef(null);

  const onLinkCard = (): void => {
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const onAfterSubmit = (areMedicareDetailsValid: boolean): void => {
    setIsExpanded(!areMedicareDetailsValid);
    setIsMedicareLinked(areMedicareDetailsValid);
    scrollToRef(divRef, 96);
  };

  const { icon, title, content } = getContent(isMedicareLinked);

  useEffect(() => {
    setIsMedicareLinked(medicareDetailsCollected);
  }, [medicareDetailsCollected]);

  if (optedNoMedicare()) {
    return null;
  }

  if (loadingMedicareDetails) {
    return (
      <div className="text-center">
        <LoadingSpinner palette="alternate" />
      </div>
    );
  }

  return (
    <Card>
      <div ref={divRef} className="space-y-4">
        <div
          className="flex items-center"
          style={{
            color: pilotBrandColors.primary[400],
          }}
        >
          {icon}
          <Typography size="lg" isBold>
            {title}
          </Typography>
        </div>
        <Typography size="medium-paragraph">{content}</Typography>
        {isExpanded ? (
          <MedicareCollectionForm
            validationLevel="all"
            submitButtonText="Link card"
            onPage={MedicareFormOnPage.consultPaid}
            WrapperComponent={React.Fragment}
            isMedicareExperimentEnabled={true}
            afterSubmit={onAfterSubmit}
          />
        ) : (
          !isMedicareLinked && (
            <Button level="secondary" isFullWidth onClick={onLinkCard}>
              Link card
            </Button>
          )
        )}
      </div>
    </Card>
  );
};
