import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  AccordionList,
  AccordionPalette,
  Card,
  Divider,
  LinkButton,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import type {
  OfferingFaqQuery,
  OfferingFaqQueryVariables,
} from '@customer-frontend/graphql-types';
import {
  FaqMarkdownItem,
  FaqPanel,
  FaqItem,
} from '@customer-frontend/consultation';
import { getConfig } from '@customer-frontend/config';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { getZendeskRequestUrl, useTitle } from '@customer-frontend/utils';
import { PausePurchaseModal } from './pause-purchase-modal';

type OfferingFaqPageProps = {
  routes: {
    profile: string;
  };
  palette?: {
    faq?: AccordionPalette;
  };
};

export const OfferingFaqPage = ({
  routes,
  palette,
}: OfferingFaqPageProps): React.ReactElement => {
  const history = useHistory();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const [showModal, setShowModal] = useState(false);
  const pauseAllowed = useFeatureFlagBoolean('ALLOW_PAUSING_PURCHASES');
  const config = getConfig();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Frequently asked questions (FAQs)',
      description: 'Page title for the Offering FAQs page',
    }),
  );

  const { data, loading } = useQuery<
    OfferingFaqQuery,
    OfferingFaqQueryVariables
  >(gql`
    query OfferingFaq {
      profile {
        id
        email
        purchases {
          id
          status
          contexts {
            id
            sequence {
              __typename
              id
              faqs {
                id
                question
                answerMarkdown
              }
            }
          }
        }
      }
    }
  `);

  const purchase = data?.profile?.purchases?.find((p) => p.id === purchaseId);

  const prescribableContext = purchase?.contexts?.find(
    (c) => c.sequence?.__typename === 'PrescribableSequence',
  );
  const prescribableSequence =
    prescribableContext?.sequence?.__typename === 'PrescribableSequence'
      ? prescribableContext.sequence
      : undefined;

  const faqs: FaqMarkdownItem[] =
    prescribableSequence?.faqs?.map((faq) => ({
      title: faq?.question,
      markdown: faq?.answerMarkdown,
    })) ?? [];

  const contactUsUrl = getZendeskRequestUrl({
    params: config.weightPausingAccountZendeskParams,
    email: data?.profile?.email,
  });

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (!purchase || !prescribableSequence) {
    return <Redirect to={routes.profile} />;
  }

  let cancelMessage = (
    <FormattedMessage
      defaultMessage="To cancel your treatment, please contact us <a>here</a>"
      description="Cancel subscription FAQ text"
      values={{
        a: (chunks) => {
          return (
            <a
              className="underline cursor"
              href={contactUsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          );
        },
      }}
    />
  );

  if (purchase.status === 'ACTIVE') {
    cancelMessage = (
      <FormattedMessage
        defaultMessage="If you want to cancel one or more of your subscriptions, please email us at <a>{email}</a>.{br}{br}If you wish to resume in future, you may be required to complete a review consult with a practitioner.{br}{br}If you have enough medication or product and don’t want to cancel, you can <b>delay your next order</b> instead."
        description="Cancel subscription FAQ text"
        values={{
          email: config.supportEmail,
          a: () => {
            return (
              <a href={`mailto:${config.supportEmail}`} className="text-link">
                {config.supportEmail}
              </a>
            );
          },
          b: (chunks) => <strong>{chunks}</strong>,
          br: <br />,
        }}
      />
    );

    if (config.pausePurchaseFaqTypeformUrl) {
      cancelMessage = (
        <FormattedMessage
          defaultMessage="If you want to cancel one or more of your subscriptions, click <a>here.</a>{br}{br}If you wish to resume in future, you may be required to complete a review consult with a practitioner.{br}{br}If you have enough medication or product and don’t want to cancel, you can <b>delay your next order</b> instead."
          description="Cancel subscription FAQ text"
          values={{
            a: (chunks) => (
              <a
                className="text-link"
                href={config.pausePurchaseFaqTypeformUrl}
              >
                {chunks}
              </a>
            ),
            b: (chunks) => <strong>{chunks}</strong>,
            br: <br />,
          }}
        />
      );
    }

    if (pauseAllowed) {
      cancelMessage = (
        <FormattedMessage
          defaultMessage="If you want to cancel one or more of your subscriptions, <a>click here.</a>{br}{br}If you wish to resume in future, you may be required to complete a review consult with a practitioner.{br}{br}If you have enough medication or product and don’t want to cancel, you can <b>delay your next order</b> instead."
          description="Cancel subscription FAQ text"
          values={{
            a: (chunks) => (
              <LinkButton onClick={() => setShowModal(true)}>
                {chunks}
              </LinkButton>
            ),
            b: (chunks) => <strong>{chunks}</strong>,
            br: <br />,
          }}
        />
      );
    }
  }

  const staticFaqs: FaqItem[] = [
    {
      title: formatMessage({ defaultMessage: 'How do I cancel?' }),
      content: <Typography size="medium-paragraph">{cancelMessage}</Typography>,
    },
  ];

  return (
    <article className="space-y-8">
      <Typography isBold size="lg">
        <FormattedMessage
          defaultMessage="Frequently asked questions"
          description="Heading for the Offering FAQ page"
        />
      </Typography>

      <Card>
        <section className="space-y-4">
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Medication"
              description="Frequently asked questions about medication"
            />
          </Typography>
          {!faqs.length && <Divider variant="separator" mt="sm" />}
          <FaqPanel faqs={faqs} accordionPalette={palette?.faq} />
        </section>
      </Card>

      <Card>
        <section className="space-y-4">
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="General"
              description="General frequently asked questions"
            />
          </Typography>
          <AccordionList items={staticFaqs} palette={palette?.faq} />
        </section>
      </Card>

      <PausePurchaseModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        purchaseId={purchaseId}
        onComplete={() => {
          setShowModal(false);
          history.push(routes.profile);
        }}
      />
    </article>
  );
};
