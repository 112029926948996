import { QueryShippingAddressWithinRangeArgs } from '@customer-frontend/graphql-types';
import {
  gql,
  QueryHookOptions,
  useLazyQuery,
  QueryTuple,
} from '@apollo/client';

interface Response {
  shippingAddressWithinRange: boolean;
}

export const isShippingAddressWithinRangeQuery = gql`
  query IsShippingAddressWithinRange(
    $line1: String!
    $line2: String!
    $postcode: String!
    $suburb: String!
  ) {
    shippingAddressWithinRange(
      line1: $line1
      line2: $line2
      postcode: $postcode
      suburb: $suburb
    )
  }
`;

export function useIsShippingAddressWithinRangeQuery(
  options: QueryHookOptions<Response, QueryShippingAddressWithinRangeArgs>,
): QueryTuple<Response, QueryShippingAddressWithinRangeArgs> {
  return useLazyQuery<Response, QueryShippingAddressWithinRangeArgs>(
    isShippingAddressWithinRangeQuery,
    options,
  );
}
