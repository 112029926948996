import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@customer-frontend/auth';
import { useEventService } from '@customer-frontend/events';

export const RouteTracker = (): null => {
  const prevRoute = React.useRef('');
  const location = useLocation();
  const user = useAuth().loggedInUser;
  const event = useEventService();

  React.useEffect(() => {
    if (prevRoute.current !== location.pathname) {
      event.page(
        '',
        location.pathname,
        {
          path: location.pathname + location.search,
          referrer: prevRoute.current,
        },
        {
          integrations: {
            All: true,
            ...(!!user?.id && {
              'Google Analytics': {
                clientId: user.id,
              },
            }),
          },
        },
      );
      prevRoute.current = location.pathname;
    }
  }, [location, user, event]);

  return null;
};
