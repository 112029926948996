import React, { useRef } from 'react';
import { Textarea } from '@chakra-ui/react';
import { useCleave } from '../utils';
import { FormWrapper } from '../form-wrapper';
import { TextAreaProps } from '../types';

const BaseTextArea = (
  {
    hint,
    label,
    value,
    onBlur,
    onChange,
    disabled,
    required,
    className,
    onKeyPress,
    placeholder,
    errorMessage,
    type = 'text',
    rows = 3,
    name,
    palette = 'default',
  }: TextAreaProps,
  forwardedRef: React.Ref<HTMLTextAreaElement>,
): React.ReactElement => {
  const ref = useRef<null | HTMLTextAreaElement>(null);
  useCleave({ ref, inputType: type, onChange });

  return (
    <FormWrapper
      hint={hint}
      label={label}
      required={required}
      disabled={!!disabled}
      errorMessage={errorMessage}
      showInputIcon
      palette={palette}
    >
      <Textarea
        name={name}
        ref={(el: HTMLTextAreaElement): void => {
          ref.current = el;
          if (forwardedRef instanceof Function) {
            forwardedRef(el);
          }
        }}
        data-peer // Required to dim hint when disabled
        value={value}
        onBlur={onBlur}
        variant="outline" // TODO: Explore new variants.
        className={className}
        onKeyPress={onKeyPress}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
      />
    </FormWrapper>
  );
};

/** Use a **TextArea** when you need to collect extended or multi-line text
 * data from a user. This is a controlled component so it is easy to integrate
 * with form libraries. */
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  BaseTextArea,
);
