import { BLOCKS, MARKS, INLINES, Document } from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Typography } from '@eucalyptusvc/design-system';

type RichTextProps = {
  document?: Document;
};

export const RichText = ({
  document,
}: RichTextProps): React.ReactElement | null => {
  if (!document) {
    return null;
  }

  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode): React.ReactNode => (
        <span className="font-bold">{text}</span>
      ),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography size="medium-paragraph">{children}</Typography>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a
          className="underline"
          target="_blank"
          href={node.data.uri}
          rel="noreferrer"
        >
          {children}
        </a>
      ),
      [BLOCKS.HEADING_1]: (_node, children): React.ReactNode => (
        <div className="pt-8">
          <Typography size="2xl" isBold>
            {children}
          </Typography>
        </div>
      ),
      [BLOCKS.HEADING_2]: (_node, children): React.ReactNode => (
        <div className="pt-8">
          <Typography size="xl" isBold>
            {children}
          </Typography>
        </div>
      ),
      [BLOCKS.HEADING_3]: (_node, children): React.ReactNode => (
        <div className="pt-8">
          <Typography size="lg" isBold>
            {children}
          </Typography>
        </div>
      ),
      [BLOCKS.HEADING_4]: (_node, children): React.ReactNode => (
        <div className="pt-8">
          <Typography size="md" isBold>
            {children}
          </Typography>
        </div>
      ),
      [BLOCKS.HEADING_5]: (_node, children): React.ReactNode => (
        <div className="pt-8">
          <Typography size="sm" isBold>
            {children}
          </Typography>
        </div>
      ),
      [BLOCKS.HEADING_6]: (_node, children): React.ReactNode => (
        <div className="pt-8">
          <Typography size="xs" isBold>
            {children}
          </Typography>
        </div>
      ),
      [BLOCKS.UL_LIST]: (_node, children): React.ReactNode => (
        <ul className="list-disc list-outside pl-6">{children}</ul>
      ),
      [BLOCKS.OL_LIST]: (_node, children): React.ReactNode => (
        <ol className="list-decimal list-outside marker:inherit pl-6">
          {children}
        </ol>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, details, description } = node?.data?.target?.fields ?? {};

        if (!file?.url) {
          // asset is not an image
          return null;
        }

        return (
          <figure className="flex flex-col items-center justify-center mx-auto space-y-4">
            <div className="rounded-lg overflow-hidden">
              <img
                src={`https://${file.url}`}
                height={details?.image?.height}
                width={details?.image?.width}
                alt={description ?? ''}
              />
            </div>
            {description && (
              <figcaption className="text-center">
                <Typography size="paragraph">{description}</Typography>
              </figcaption>
            )}
          </figure>
        );
      },
      [BLOCKS.TABLE]: (_node, children): React.ReactNode => (
        <table className="table-fixed w-full">
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_node, children): React.ReactNode => (
        <th className="text-left">{children}</th>
      ),
      [BLOCKS.TABLE_CELL]: (_node, children): React.ReactNode => (
        <td>{children}</td>
      ),
      [BLOCKS.QUOTE]: (_node, children) => (
        <div className="flex flex-row space-x-3">
          <div className="border-l-2 rounded" />
          <div>{children}</div>
        </div>
      ),
    },
  };

  return (
    <div className="space-y-4">
      {documentToReactComponents(document, options)}
    </div>
  );
};
