import { ProblemType } from '@customer-frontend/graphql-types';
import { Brand } from '@customer-frontend/types';

export const getDevDoctorOptions = (
  brand: Brand,
): Array<{
  label: string;
  value: string;
}> => {
  switch (brand) {
    case 'juniper':
      return [
        {
          label: 'Dr Alexey Pepper',
          value: 'ckttm5maq00905ks4u2m445td',
        },
        {
          label: 'Dr John Gillies',
          value: 'clhy5vlmq0000rl01dgt33fuv',
        },
        {
          label: 'Dr Nick Gillies',
          value: 'cl7h84whv00530so3zrjrj4ad',
        },
      ];
    case 'pilot':
      return [
        {
          label: 'Dr Alexey Pepper',
          value: 'ckno98sg500253eldaunav0ix',
        },
        {
          label: 'Dr John Gillies',
          value: 'clhybkton0000kw0167lk1juo',
        },
      ];
    default:
      return [{ label: 'Default', value: '' }];
  }
};

export const getDevProductOptions = (
  brand: Brand,
  problemType?: ProblemType,
): Array<{
  label: string;
  value: string;
}> => {
  if (brand === 'juniper') {
    if (problemType === 'WEIGHT_LOSS') {
      return [
        {
          label: 'Ozempic - Default',
          value: 'clhphcc0g0002ol01llg4iu2g',
        },
        {
          label: 'Metabolic Reset Program (Saxenda - dev)',
          value: 'ckv8yqdaa01093yo67ue5vsps',
        },
        {
          label: 'Test Mounjaro',
          value: 'clnaxbogf0000td01rwfnjjoc',
        },
      ];
    } else if (problemType === 'MENOPAUSE') {
      return [
        {
          label: 'Estrogel Pro Initial',
          value: 'clhgu26yr0012ze01akmmdft7',
        },
      ];
    }
  } else if (brand === 'pilot') {
    if (problemType === 'WEIGHT_LOSS') {
      return [
        {
          label: 'Metabolic Reset Program (Saxenda - dev)',
          value: 'ckv8yqdaa01093yo67ue5vsps',
        },
        {
          label: 'Ozempic - Default',
          value: 'cli9rfxdn0009kn016dn5pizh',
        },
      ];
    }
  }

  return [{ label: 'Default', value: '' }];
};
