import { Typography } from '@eucalyptusvc/design-system';
import { ReactComponent as Tick } from '../assets/tick.svg';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type StageStyle =
  | 'circleFilled'
  | 'circle'
  | 'circleStrong'
  | 'circleStatic';

export type OrderTimelineProps = {
  truncate?: number;
  orders: {
    id: string;
    title?: React.ReactNode;
    primaryLabel: React.ReactNode;
    secondaryLabel?: React.ReactNode;
    orderNumberAdornment?: React.ReactNode;
    primaryLabelAdornment?: React.ReactNode;
    strikethroughPrice?: React.ReactNode;
    price?: string;
    pricePerCadence?: string;
    showPricePredictedDisclaimer?: boolean;
    stage: StageStyle;
  }[];
};

export const OrderTimeline: React.FC<OrderTimelineProps> = ({
  orders: ordersArray,
  truncate,
}) => {
  const orders = truncate ? ordersArray.slice(0, truncate) : ordersArray;
  const asterisk = '*';
  return (
    <div>
      {orders.map((o, i) => (
        <div
          key={o.id}
          className="flex flex-row justify-between text-primary-600 relative"
        >
          <div className="flex flex-row space-x-2">
            <div
              className={clsx('flex flex-col items-center w-8', {
                'justify-start': i === orders.length - 1,
                'justify-center': i < orders.length - 1,
              })}
              style={{
                minWidth: '32px',
              }}
            >
              {/* Circle */}
              <div
                className={clsx({
                  'h-8 w-8 rounded-full border-[6px] border-primary-600 border-opacity-20':
                    o.stage === 'circleStrong',
                  'absolute top-1.5': o.stage !== 'circleStrong',
                })}
              >
                <div
                  className={clsx(
                    'flex justify-center items-center rounded-full',
                    {
                      'h-3 w-3 bg-primary-300': o.stage === 'circleFilled',
                      'h-5 w-5 border-4 bg-primary-600 border-primary-600':
                        o.stage === 'circleStrong',
                      'h-3 w-3 bg-white border-2 border-primary-600':
                        o.stage === 'circle' || o.stage === 'circleStatic',
                    },
                  )}
                >
                  {o.stage === 'circleStrong' && (
                    <Tick className="fill-current h-2" color="white" />
                  )}
                </div>
              </div>
              {/* Line */}
              <div
                className={clsx({
                  'w-0.5 border-primary-300 border-l-2 mb-px':
                    o.stage === 'circleFilled',
                  'w-0.5 border-primary-600 border-l-2 mb-px': [
                    'circleStatic',
                    'circleStrong',
                    'circle',
                  ].includes(o.stage),
                  'border-dashed': true,
                  'h-3': i === orders.length - 1 && o.stage !== 'circleStrong',
                  'flex-grow': i < orders.length - 1,
                  'mt-1.5': i === 0 && o.stage !== 'circleStrong',
                  '-mt-1.5': i > 0 && i < orders.length - 1,
                })}
              />
            </div>
            {/* Order information */}
            <div
              className={clsx('mb-5.5 gap-y-2', {
                'opacity-60': o.stage === 'circleFilled',
                'mt-1': o.stage === 'circleStrong',
              })}
            >
              <div className="flex space-x-2 items-center">
                <Typography size="medium-paragraph" isBold>
                  {o.title || (
                    <FormattedMessage
                      defaultMessage="Order {number}{truncated, select, true{+} other{}}"
                      description="Specifies nth order in treatment timeline"
                      values={{
                        number: i + 1,
                        truncated:
                          ordersArray.length > orders.length &&
                          i >= orders.length - 1,
                      }}
                    />
                  )}
                </Typography>
                {o.orderNumberAdornment}
              </div>
              <div className="flex gap-2 items-center">
                {o.primaryLabelAdornment}
                <div className="mt-1 text-sm font-light pr-2">
                  {o.primaryLabel}
                </div>
              </div>
              {o.secondaryLabel && (
                <Typography size="small-text">{o.secondaryLabel}</Typography>
              )}
            </div>
          </div>
          {/* Price */}
          <div className="flex flex-col items-end">
            <div className="flex flex-col sm:flex-row-reverse sm:space-x-1 sm:space-x-reverse">
              {o.price && (
                <Typography size="medium-paragraph" isBold>
                  <span
                    className={clsx('break-normal', {
                      'opacity-60': o.stage === 'circleFilled',
                    })}
                  >
                    {o.price}
                  </span>
                  {o.showPricePredictedDisclaimer && <span>{asterisk}</span>}
                </Typography>
              )}
              {o.strikethroughPrice}
            </div>
            {o.pricePerCadence && (
              <Typography size="small-text">
                <span className="whitespace-nowrap">{o.pricePerCadence}</span>
              </Typography>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
