import { PatientHealthCareAttributes } from './types';
import { useProfileAndHealthcare } from '@customer-frontend/services';
import { getSexOpt } from '@customer-frontend/utils';

export const usePatientAndHealthcareAttrs = (): PatientHealthCareAttributes => {
  const resp = useProfileAndHealthcare();
  const {
    legalFirstName,
    legalLastName,
    birthday,
    medicalProfile,
    healthCareDetails,
  } = resp?.data?.profile || {};
  const sexOpt = getSexOpt(medicalProfile?.sex);

  return {
    firstName: legalFirstName ?? '',
    lastName: legalLastName ?? '',
    birthday: birthday ?? '',
    sex: sexOpt,
    medicareDetailsCollected: healthCareDetails?.hasMedicareDetails ?? false,
    ihiCollected: healthCareDetails?.hasIndividualHealthcareIdentifier ?? false,
    loading: resp.loading,
  };
};
