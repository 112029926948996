import isWithinInterval from 'date-fns/isWithinInterval';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatRelative from 'date-fns/formatRelative';
import sub from 'date-fns/sub';
import { capitaliseString } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';

export const formatRelativeTime = (date: Date): string => {
  const { dateConfig, brand } = getConfig();

  const now = new Date();
  const withinDay = isWithinInterval(date, {
    start: sub(now, { hours: 24 }),
    end: now,
  });
  const withinYesterday = isWithinInterval(date, {
    start: sub(now, { hours: 48 }),
    end: sub(now, { hours: 24 }),
  });
  if (withinDay) {
    return capitaliseString(
      formatDistanceToNow(date, {
        addSuffix: true,
        locale: dateConfig.datefnsLocale,
      }),
    );
  } else if (withinYesterday) {
    return capitaliseString(
      formatRelative(date, now, { locale: dateConfig.datefnsLocale }),
    );
  } else {
    return formatDate(mapBrandToAdaptersBrand(brand), date, {
      dateStyle: 'medium',
      timeStyle: 'medium',
    });
  }
};
