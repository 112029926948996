import { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import {
  Button,
  Typography,
  LoadingSpinner,
  Markdown,
  Card,
  Divider,
  Checkbox,
  ButtonPalette,
  AccordionPalette,
  CardPalette,
} from '@eucalyptusvc/design-system';
import {
  TreatmentStatus,
  ConsultationPlanQueryVariables,
  ConsultationPlanQuery,
} from '@customer-frontend/graphql-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { mustBeTrue, useTitle } from '@customer-frontend/utils';
import { getConfig } from '@customer-frontend/config';
import { FaqPanel } from '@customer-frontend/consultation';

const QUERY = gql`
  query ConsultationPlan($consultationId: String!) {
    consultation(id: $consultationId) {
      id
      isUpfrontPayment
      typeIsFlexi
      completedAt
      doctor {
        id
        fullName
        fullClinicianName
        shortClinicianName
        lastName
        avatar {
          id
          url
        }
      }
      patientNotes
      customer {
        id
        firstName
      }
      treatment {
        id
        status
        product {
          id
          plan {
            id
            interval
            intervalCount
          }
          variants {
            id
            isRefill
            price
            inventory {
              id
              sku
            }
          }
          photo {
            id
            url
          }
          name
          friendlyName
          shortDescription
          faqs {
            id
            title
            markdown
          }
        }
        experimentAvailablePaymentPlans {
          paymentPlan
        }
      }
    }
    customerAttributes {
      id
      menopause {
        menopauseSymptoms
      }
    }
    inBatchingExperimentSegment: inSegment(
      segmentName: PRICING_BATCHING_EXPERIMENT_VARIATION
    )
  }
`;

type Form = {
  isApproved: boolean;
};

type Props = {
  consultationId: string;
  palette: {
    submitButton: ButtonPalette;
    faqAccordion: AccordionPalette;
    planCard: CardPalette;
  };
  routes: {
    profile: string;
    doctorsNote: string;
    pharmacyInformation: string;
    next: (inBatchingExperiment: boolean) => string;
    planDetails: string | null;
  };
};

export const ConsultationPlanPage: React.FC<Props> = ({
  consultationId,
  routes,
  palette,
}) => {
  useHistoryGoBackBehaviour();
  const { data, loading } = useQuery<
    ConsultationPlanQuery,
    ConsultationPlanQueryVariables
  >(QUERY, { variables: { consultationId } });
  const history = useHistory();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Treatment Plan',
      description: 'Page title for the Consultation Plan page',
    }),
  );

  const consultation = data?.consultation;
  const customer = consultation?.customer;
  const doctor = consultation?.doctor;
  const product = consultation?.treatment?.product;
  const treatmentStatus = consultation?.treatment?.status;

  const config = getConfig();

  const form = useForm<Form>({
    defaultValues: { isApproved: false },
  });

  useEffect(() => {
    const planStatuses: TreatmentStatus[] = ['CREATED', 'FOLLOW_UP', 'REVIEW'];
    if (treatmentStatus && !planStatuses.includes(treatmentStatus)) {
      history.replace(routes.profile);
    }
  }, [treatmentStatus, history, routes.profile]);

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (!consultation) {
    return <Redirect to={routes.profile} />;
  }

  if (consultation.typeIsFlexi) {
    return <Redirect to={routes.profile} />;
  }

  if (!product) {
    return <Redirect to={routes.profile} />;
  }

  const inBatchingExperiment =
    !!data.inBatchingExperimentSegment &&
    !!data.consultation?.treatment?.experimentAvailablePaymentPlans.length;

  const onSubmit = (): void => {
    history.push(routes.next(inBatchingExperiment));
  };

  const medicalSupportEmail = config.medicalEmail ?? config.supportEmail;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <section className="space-y-6 pb-16">
          <div className="space-y-4">
            <Typography isBold size="lg" element="h1">
              <FormattedMessage
                defaultMessage="Hey {name}, {doctorName} has prescribed your treatment"
                description="Treatment plan heading using the customers name"
                values={{
                  name: customer?.firstName ?? 'there',
                  doctorName: doctor?.shortClinicianName,
                }}
              />
            </Typography>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Before confirming your order, please review your treatment plan, the letter from {doctorName} and safety information from your pharmacy. If you have any questions, please contact our Medical Support team: <a>{medicalSupportEmail}</a>"
                description="Explanation for user to review the doctor and pharmacy information"
                values={{
                  doctorName: doctor?.shortClinicianName,
                  medicalSupportEmail,
                  a: (chunks) => (
                    <a
                      className="text-link"
                      href={`mailto:${medicalSupportEmail}`}
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Typography>
            <Typography size="medium-paragraph" isItalic>
              <FormattedMessage
                defaultMessage="Note: you will not receive your treatment until you confirm your order."
                description="Warning to user that they won't receive their order until they complete these steps and confirm their order"
              />
            </Typography>
          </div>
          <TreatmentCard
            doctorName={
              doctor?.shortClinicianName ??
              formatMessage({
                defaultMessage: 'your practitioner',
                description:
                  'placeholder for referring to practitioner when their name is not available',
              })
            }
            product={product}
            routes={routes}
            palette={{ card: palette.planCard }}
          />

          <Button palette={palette.submitButton} isSubmit isFullWidth>
            <FormattedMessage
              defaultMessage="Continue to confirm order"
              description="Button text to continue to the confirm order page"
            />
          </Button>
          <div className="space-y-4 mt-8">
            <Typography size="lg" isBold>
              <FormattedMessage
                defaultMessage="FAQs"
                description="Acronym describing frequently asked questions"
              />
            </Typography>
            <FaqPanel
              accordionPalette={palette.faqAccordion}
              faqs={product.faqs}
            />
          </div>
        </section>
      </form>
    </FormProvider>
  );
};

const TreatmentCard = ({
  product,
  doctorName,
  routes,
  palette,
}: {
  doctorName: string;
  product: {
    photo?: {
      url: string;
    } | null;
    id: string;
    name: string;
    friendlyName?: string | null;
    shortDescription?: string | null;
  };
  routes: {
    doctorsNote: string;
    pharmacyInformation: string;
    planDetails: string | null;
  };
  palette: {
    card: CardPalette;
  };
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const { register, errors } = useFormContext<Form>();

  const { planDetails } = routes;

  return (
    <Card palette={palette.card}>
      <Typography isBold size="md">
        <FormattedMessage
          defaultMessage="Your Treatment Plan"
          description="Treatment plan heading"
        />
      </Typography>
      <Divider variant="separator" mt="xs" />
      <div className="flex mb-4 justify-between items-start flex-col sm:flex-row">
        <div className="flex mb-2">
          <img
            src={product.photo?.url}
            className="rounded h-16 w-16 bg-primary-200"
          />
          <div className="ml-4 my-auto">
            <Typography isBold size="sm">
              {product.friendlyName || product.name}
            </Typography>
          </div>
        </div>
      </div>
      <div className="space-y-4">
        {product.shortDescription && (
          <Markdown src={product.shortDescription} />
        )}
        <Button
          level="secondary"
          onClick={() => history.push(routes.doctorsNote)}
          isFullWidth
        >
          <FormattedMessage
            defaultMessage="View letter from {doctorName}"
            description="Button text to go to the doctor letter page"
            values={{ doctorName }}
          />
        </Button>

        {planDetails && (
          <Button
            level="secondary"
            onClick={() => history.push(planDetails)}
            isFullWidth
          >
            <FormattedMessage
              defaultMessage="View plan details"
              description="Button text to go to the plan details page"
            />
          </Button>
        )}

        <Button
          level="secondary"
          onClick={() => history.push(routes.pharmacyInformation)}
          isFullWidth
        >
          <FormattedMessage
            defaultMessage="View pharmacy safety information"
            description="Button text to go to the pharmacy information page"
          />
        </Button>

        <div>
          <Checkbox
            isChecked={false}
            ref={register({
              ...mustBeTrue(
                formatMessage({
                  defaultMessage:
                    'To continue, you must confirm you have reviewed the clinical and safety information',
                  description:
                    'Error message shown when patient has not confirmed they have reviewed their treatment information',
                }),
              ),
            })}
            name="isApproved"
            errorMessage={errors.isApproved?.message}
            label={formatMessage({
              defaultMessage:
                'I confirm I have read and understood the clinical and safety information regarding my treatment.',
              description:
                'Checkbox label explaning that the user is confirming they have read the safety information and are happy to proceed',
            })}
          />
        </div>
      </div>
    </Card>
  );
};
