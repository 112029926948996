export type AppEnv = 'local' | 'development' | 'production';

export function getAppEnv(s: string): AppEnv {
  switch (s.toLowerCase()) {
    case 'prod':
    case 'production':
      return 'production';
    case 'dev':
    case 'development':
      return 'development';
    case 'local':
      return 'local';
  }
  throw new Error(`Invalid appEnv: ${s}`);
}
