import { Button, Modal, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage, useIntl } from 'react-intl';

export const FinishedReadingsModal = ({
  showModal,
  setShowModal,
  backToProgram,
}: {
  showModal: boolean;
  setShowModal: (state: boolean) => void;
  backToProgram: () => void;
}): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      title={formatMessage({
        defaultMessage: 'More readings to come',
        description:
          'Title telling users more readings will be available in the future',
      })}
      isOpen={showModal}
      onClose={() => setShowModal(false)}
    >
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="Take a break for now, we'll let you know when more readings become available." />
      </Typography>

      <div className="pt-6">
        <Button isFullWidth level="primary" onClick={backToProgram}>
          <FormattedMessage defaultMessage="Back to dashboard" />
        </Button>
      </div>
    </Modal>
  );
};
