import { ProblemType } from '@customer-frontend/graphql-types';

export const takeTheLatestByProblemType = <
  T extends {
    createdAt: unknown;
  },
>(
  inputs: T[],
  getProblemType: (a: T) => ProblemType,
): T[] => {
  // Try not mutate the inputs.
  const array = [...inputs];
  array.sort((a, b) => {
    return `${b.createdAt}`.localeCompare(`${a.createdAt}`);
  });
  const set = new Set<ProblemType>();
  const result: T[] = [];
  array.forEach((a) => {
    const p = getProblemType(a);
    if (set.has(p)) {
      return;
    }
    result.push(a);
    set.add(p);
  });
  return result;
};
