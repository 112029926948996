import { BillingInterval } from '@customer-frontend/graphql-types';
import { useIntl } from 'react-intl';
import { CartLineItem, isOtc } from '../line-items';
import {
  formatCurrency,
  convertCurrencyBaseUnit as convertCurrencyBaseUnitLib,
} from '@eucalyptusvc/lib-localization';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
export * from './localised-formatters';

export const convertCurrencyBaseUnit = (cents: number): number => {
  const { brand } = getConfig();

  return convertCurrencyBaseUnitLib(mapBrandToAdaptersBrand(brand), cents);
};

export const formatCentsToCurrency = (
  cents: number,
  options?: {
    includeDecimals?: boolean;
    includeSymbol?: boolean;
    omitZeroDecimals?: boolean;
  },
): string => {
  const { brand } = getConfig();

  return formatCurrency(mapBrandToAdaptersBrand(brand), cents, {
    includeDecimals: options?.includeDecimals ?? false,
    includeSymbol: options?.includeSymbol ?? true,
    omitZeroDecimals: options?.omitZeroDecimals ?? false,
  });
};

export const calculatePricePerWeekInCents = (
  monthlyCostInCents: number,
): number => {
  const MONTHS_IN_YEAR = 12;
  const WEEKS_IN_YEAR = 52;
  return (monthlyCostInCents * MONTHS_IN_YEAR) / WEEKS_IN_YEAR;
};

export const getLineItemPrice = (
  lineItem: CartLineItem,
  isUpfrontPayment?: boolean,
): number => {
  if (isOtc(lineItem)) {
    return lineItem.price || 0;
  } else {
    // Paid consultation prescription
    return isUpfrontPayment ? 0 : lineItem.price ?? 0;
  }
};

/**
 * Accounts for quantity and product specific discounts.
 */
export const getFinalLineItemPrice = (
  lineItem: CartLineItem,
  isUpfrontPayment?: boolean,
): number => {
  if (lineItem.productSpecificDiscount) {
    return lineItem.productSpecificDiscount.totalPrice;
  }
  return (
    getLineItemPrice(lineItem, isUpfrontPayment) * (lineItem.quantity ?? 1)
  );
};

export const getTotalPrice = (
  lineItems: CartLineItem[],
  isUpfrontPayment?: boolean,
): number => {
  return lineItems?.reduce((total, item) => {
    const itemPrice = getLineItemPrice(item, isUpfrontPayment);
    return total + itemPrice * (item.quantity ?? 1);
  }, 0);
};

export const getProductPricePerDayString = (product: {
  plan?: {
    interval?: BillingInterval;
    intervalCount?: number;
  } | null;
  variants: {
    isRefill: boolean;
    price?: number | null;
  }[];
}): string => {
  const data = getPlanIntervalData(product);
  const variantPrice = getRxVariantPrice(product);
  if (!data || !variantPrice) {
    return '';
  }

  const { interval, intervalCount } = data;
  const numDays = convertIntervalCountToDays(intervalCount, interval);
  const dailyPrice = formatCentsToCurrency(variantPrice / numDays);

  return `${dailyPrice} / day`;
};

export const getRxProductVariant = <
  T extends {
    variants: {
      isRefill: boolean;
    }[];
  },
>(
  product?: T | null,
): T['variants'][0] | undefined => {
  return product?.variants?.find((variant) => variant && !variant.isRefill);
};

export const getRxVariantPrice = (product: {
  variants: {
    isRefill: boolean;
    price?: number | null;
  }[];
}): number | undefined => {
  const variant = getRxProductVariant(product);
  return variant?.price ?? undefined;
};

export const getPlanIntervalData = (
  product?: {
    plan?: {
      interval?: BillingInterval;
      intervalCount?: number;
    } | null;
  } | null,
): { interval: BillingInterval; intervalCount: number } | undefined => {
  const interval = product?.plan?.interval;
  const intervalCount = product?.plan?.intervalCount;

  if (!interval || !intervalCount) {
    return;
  }

  return {
    interval,
    intervalCount,
  };
};

export const DAYS_IN_MONTH = 30.4;

export const convertIntervalCountToDays = (
  intervalCount: number,
  interval: BillingInterval,
): number => {
  switch (interval) {
    case 'Daily':
      return intervalCount;
    case 'Monthly':
      return intervalCount * DAYS_IN_MONTH;
    case 'Weekly':
      return intervalCount * 7;
  }
};

/**
 * @deprecated please use 'useFormatInterval' to support i18n text.
 */
export function formatInterval(
  interval: BillingInterval,
  intervalCount: number,
): string {
  let formattedInterval = '';

  switch (interval) {
    case 'Daily':
      formattedInterval = 'day';
      break;
    case 'Weekly':
      formattedInterval = 'week';
      break;
    case 'Monthly':
      formattedInterval = 'month';
      break;
  }
  return `${formattedInterval}${intervalCount === 1 ? '' : 's'}`;
}

export function useFormatInterval(
  interval?: BillingInterval,
  intervalCount?: number,
): string {
  const { formatMessage } = useIntl();
  let formattedInterval = '';

  switch (interval) {
    case 'Daily':
      formattedInterval = formatMessage(
        { defaultMessage: 'day{intervalCount, plural, one {} other {s}}' },
        {
          intervalCount: intervalCount || 0,
        },
      );
      break;
    case 'Weekly':
      formattedInterval = formatMessage(
        {
          defaultMessage: 'week{intervalCount, plural, one {} other {s}}',
        },
        {
          intervalCount: intervalCount || 0,
        },
      );
      break;
    case 'Monthly':
      formattedInterval = formatMessage(
        { defaultMessage: 'month{intervalCount, plural, one {} other {s}}' },
        {
          intervalCount: intervalCount || 0,
        },
      );
      break;
  }
  return `${formattedInterval}`;
}

export const getProductPricePerCadenceString = (product: {
  variants: {
    isRefill: boolean;
    price?: number | null;
  }[];
  plan?:
    | {
        interval?: BillingInterval | undefined;
        intervalCount?: number | undefined;
      }
    | null
    | undefined;
}): string => {
  const variantPrice = getRxVariantPrice(product);
  const cadence = getCadenceString(product);
  if (!variantPrice || !cadence) {
    return '';
  }

  const formatVariantPrice = formatCentsToCurrency(variantPrice);

  return `${formatVariantPrice} for ${cadence}`;
};

/**
 * @deprecated please use `useCadenceString` to support i18n text
 */
export const getCadenceString = (product?: {
  plan?: {
    interval?: BillingInterval;
    intervalCount?: number;
  } | null;
}): string => {
  const data = getPlanIntervalData(product);
  if (!data) {
    return '';
  }

  const { interval, intervalCount } = data;
  return `${intervalCount} ${formatInterval(interval, intervalCount)}`;
};
export const useCadenceString = (
  product?: {
    plan?: {
      interval?: BillingInterval;
      intervalCount?: number;
    } | null;
  } | null,
): string => {
  const data = getPlanIntervalData(product);
  const intervalText = useFormatInterval(data?.interval, data?.intervalCount);

  if (!data) {
    return '';
  }

  return `${data.intervalCount} ${intervalText}`;
};

export const getCadenceStringInMonths = (product?: {
  plan?: {
    interval?: BillingInterval;
    intervalCount?: number;
  } | null;
}): string => {
  const data = getPlanIntervalData(product);
  if (!data) {
    return '';
  }

  let { intervalCount } = data;
  const { interval } = data;
  if (interval === 'Daily') {
    intervalCount = Math.round(intervalCount / DAYS_IN_MONTH);
  } else if (interval === 'Weekly') {
    intervalCount = Math.round(intervalCount / 4);
  }
  return `${intervalCount} ${formatInterval('Monthly', intervalCount)}`;
};
