import { Typography } from '@eucalyptusvc/design-system';
import React from 'react';
import { Maybe } from '@customer-frontend/graphql-types';
import { FormattedMessage, useIntl } from 'react-intl';

export const ImageResponse = ({
  imageUrl,
}: {
  imageUrl: Maybe<string>;
}): React.ReactElement => {
  const { formatMessage } = useIntl();

  if (!imageUrl) {
    return (
      <div className="max-w-sm my-4">
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage
            defaultMessage="No Answer"
            description="Fallback answer for quiz questions"
          />
        </Typography>
      </div>
    );
  }

  return (
    <div className="max-w-sm my-4">
      <a href={imageUrl} target="_">
        <img
          src={imageUrl}
          alt={formatMessage({ defaultMessage: 'Patient answer' })}
        />
      </a>
    </div>
  );
};
