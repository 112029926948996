import { EventService } from '@customer-frontend/events';
import { getConfig } from '@customer-frontend/config';
import { authService } from './auth';
import { logger } from 'utils/logging';
import { environment } from '../environment';

const config = getConfig();

export const eventService = new EventService(
  () => authService.getUserInfo(),
  environment.appEnv,
  logger,
  environment.segmentKey,
  config.consentRequired,
);
