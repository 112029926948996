import {
  gql,
  useMutation,
  MutationTuple,
  OperationVariables,
  MutationHookOptions,
} from '@apollo/client';

const removeZipDocument = gql`
  mutation RemoveZip {
    removeZip
  }
`;

export const useRemoveZip = (
  options?: MutationHookOptions<Response, OperationVariables>,
): MutationTuple<Response, OperationVariables> =>
  useMutation<Response, OperationVariables>(removeZipDocument, options);
