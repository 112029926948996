// status colours
const error100 = '#fff2f2';
const error500 = '#af2828';
const warning100 = '#ffefd0';
const warning500 = '#ffc247';
const success100 = '#f6fdf2';
const success500 = '#14763e';
const info100 = '#e8f0f8';
const info500 = '#24517d';

// neutral shades
const white = '#ffffff';
const neutral100 = '#f2f2f2';
const neutral200 = '#d9d9d9';
const neutral300 = '#cccccc';
const neutral400 = '#b3b3b3';
const neutral500 = '#999999';
const neutral600 = '#737373';
const neutral700 = '#595959';
const neutral800 = '#404040';
const neutral900 = '#1a1a1a';
const black = '#000000';

export const sharedColors = {
  status: {
    error: {
      100: error100,
      500: error500,
    },
    warning: {
      100: warning100,
      500: warning500,
    },
    success: {
      100: success100,
      500: success500,
    },
    info: {
      100: info100,
      500: info500,
    },
  },
  neutral: {
    white,
    100: neutral100,
    200: neutral200,
    300: neutral300,
    400: neutral400,
    500: neutral500,
    600: neutral600,
    700: neutral700,
    800: neutral800,
    900: neutral900,
    black,
  },
};
