import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardPalette,
  LoadingSpinner,
  TextInput,
  Typography,
  TypographySize,
} from '@eucalyptusvc/design-system';

import { DobFormInput } from '@customer-frontend/form-input';
import {
  formatISODateToLocale,
  formatLocaleDateToISO,
  useRequiredValidation,
} from '@customer-frontend/utils';
import {
  useUpdateResidentialAddress,
  useUpdateProfileAttrs,
} from '@customer-frontend/services';
import { notificationService } from '@customer-frontend/notifications';
import { usePatientProfileAndAddressAttrs } from '../logic';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { IntlAddressInput } from '@customer-frontend/intl';

type ConfirmDetailsFormData = {
  firstName: string;
  lastName: string;
  birthday: string;
  residentialAddress: {
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
};

const labels = defineMessages({
  firstNameLabel: { defaultMessage: 'First name' },
  lastNameLabel: {
    defaultMessage: 'Last name',
  },
});

export const ConfirmDetailsForm = ({
  country,
  submitButtonText,
  afterSubmit,
  cardPalette,
  headerSize,
}: {
  country: string;
  submitButtonText: string;
  afterSubmit?: (validAddress: boolean) => void;
  cardPalette?: CardPalette;
  headerSize?: TypographySize;
}): JSX.Element => {
  const config = getConfig();
  const {
    profileData: { firstName, lastName, birthday, residentialAddress },
    loading,
  } = usePatientProfileAndAddressAttrs();

  const { register, errors, setValue, handleSubmit } =
    useForm<ConfirmDetailsFormData>();

  useEffect(() => {
    setValue('firstName', firstName ?? '');
    setValue('lastName', lastName ?? '');
    setValue('birthday', (birthday && formatISODateToLocale(birthday)) ?? '');
    setValue('residentialAddress', {
      ...residentialAddress,
      country,
    });
  }, [
    country,
    setValue,
    firstName,
    lastName,
    birthday,
    residentialAddress,
    loading,
  ]);

  const [updateProfileAttrsMutation] = useUpdateProfileAttrs({});
  const [updateResidentialMutation] = useUpdateResidentialAddress({});
  const { formatMessage } = useIntl();

  const submit = handleSubmit(
    async ({ firstName, lastName, birthday, residentialAddress }) => {
      try {
        await updateProfileAttrsMutation({
          variables: {
            firstName,
            lastName,
            birthday: formatLocaleDateToISO(birthday),
          },
        });
        await updateResidentialMutation({
          variables: {
            residentialAddress: {
              line1: residentialAddress.line1,
              line2: residentialAddress.line2 || undefined,
              city: residentialAddress.city,
              postalCode: residentialAddress.postalCode,
              state: residentialAddress.state,
              country: country, // required because react hook form wont set values for disabled fields
            },
          },
        });
        if (afterSubmit) {
          afterSubmit(true);
        }
      } catch (err) {
        notificationService.show({
          type: 'error',
          message: formatMessage(
            {
              defaultMessage:
                '{hasError, select, true {{errorMessage}} other {Unable to update details}}',
            },
            {
              hasError: !!err?.message,
              errorMessage: err?.message,
            },
          ),
        });
        if (afterSubmit) {
          afterSubmit(false);
        }
      }
    },
  );

  const firstNameValidation = useRequiredValidation(
    formatMessage(labels.firstNameLabel),
  );
  const lastNameValidation = useRequiredValidation(
    formatMessage(labels.lastNameLabel),
  );

  if (loading) {
    return (
      <div className="flex justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <form onSubmit={submit}>
      <Card palette={cardPalette}>
        <Typography size={headerSize ? headerSize : 'lg'} isBold>
          <FormattedMessage defaultMessage="Personal details" />
        </Typography>
        <div className="space-y-4 mt-4 mb-2">
          <TextInput
            name="firstName"
            label={formatMessage(labels.firstNameLabel)}
            ref={register(firstNameValidation)}
            errorMessage={errors?.firstName?.message}
          />
          <TextInput
            name="lastName"
            label={formatMessage(labels.lastNameLabel)}
            ref={register(lastNameValidation)}
            errorMessage={errors?.lastName?.message}
          />
          <DobFormInput
            name="birthday"
            register={register}
            errorMessage={errors?.birthday?.message}
          />
          <div className="col-span-1 md:col-span-2">
            <IntlAddressInput
              className="mt-3"
              name="residentialAddress"
              registerField={register}
              errors={errors.residentialAddress ?? {}}
              useAutoComplete
            />
          </div>
        </div>

        <Button
          isFullWidth
          isSubmit
          palette={getPrimaryButtonPalette(config.brand)}
        >
          {submitButtonText}
        </Button>
      </Card>
    </form>
  );
};
