import { TypographySize } from '../typography';
import { AccordionSize } from './types';

export const getTextSizeProp = (size: AccordionSize): TypographySize => {
  switch (size) {
    case 'sm':
      return 'paragraph';
    case 'md':
      return 'medium-paragraph';
    case 'lg':
      return 'large-paragraph';
  }
};
