import { gql, QueryResult, useQuery, QueryHookOptions } from '@apollo/client';
import { Product, QueryProductsArgs } from '@customer-frontend/graphql-types';

export const addOtcToTreatmentMutation = gql`
  mutation UpsertOtcProductToTreatment(
    $id: String!
    $productId: String!
    $cadence: OtcScheduleCadence!
    $quantity: Int
    $source: String
  ) {
    upsertOtcProductToTreatment(
      id: $id
      productId: $productId
      cadence: $cadence
      quantity: $quantity
      source: $source
    ) {
      id
      otcSchedules {
        id
        createdAt
        updatedAt
        cadence
        isActive
        quantity
        product {
          id
          name
          variants {
            id
            price
          }
          photo {
            id
            url
          }
        }
      }
    }
  }
`;

export const removeOtcFromTreatmentMutation = gql`
  mutation RemoveOtcProductFromTreatment(
    $id: String!
    $productId: String!
    $source: String
  ) {
    removeOtcProductFromTreatment(
      id: $id
      productId: $productId
      source: $source
    ) {
      id
      otcSchedules {
        id
        isActive # this changes to false if removed
      }
    }
  }
`;

export const updateLineItemMutation = gql`
  mutation UpdateLineItem(
    $orderId: String!
    $quantity: Int!
    $variantId: String!
  ) {
    updateLineItem(
      orderId: $orderId
      quantity: $quantity
      variantId: $variantId
    ) {
      id
    }
  }
`;

type UseOtcProductsData = { products: Product[] };

export function useOtcProducts(
  options?: QueryHookOptions<UseOtcProductsData, QueryProductsArgs>,
): QueryResult<UseOtcProductsData, QueryProductsArgs> {
  return useQuery(
    gql`
      query Products {
        products(productType: OTC) {
          id
          name
          description
          priority
          productType
          problemTypes

          photo {
            id
            url
          }

          variants {
            id
            price
            public
            inventory {
              id
              sku
            }
          }
        }
      }
    `,
    options,
  );
}

// Used to fetch the available Nutrition support product by SKU
export const nutritionSupportProductQuery = gql`
  query NutritionSupportProducts($productSKU: String) {
    products(
      productType: CONTENT
      where: {
        variants: { some: { inventory: { sku: { equals: $productSKU } } } }
      }
    ) {
      id
      name
      description
      priority
      productType
      problemTypes
      photo {
        id
        url
      }
      variants {
        id
        price
        public
        inventory {
          id
          sku
        }
      }
    }
  }
`;

const specialisedNutritionSupportTreatmentFragment = gql`
  fragment NutritionSupportTreatment on Treatment {
    id
    product {
      id
      name
      friendlyName
      variants {
        id
        inventory {
          id
          sku
        }
      }
    }
    specialisedNutritionSupportSchedule: otcSchedules(
      where: {
        product: {
          variants: { some: { inventory: { sku: { equals: $productSKU } } } }
        }
        isActive: { equals: true }
      }
    ) {
      id
      product {
        id
        name
        variants {
          id
          name
          price
        }
      }
    }
  }
`;

// productSKU may be undefined if patient is not in experiment
export const specialisedNutritionSupport = gql`
  ${specialisedNutritionSupportTreatmentFragment}
  query SpecialisedNutritionSupport(
    $treatmentId: String!
    $productSKU: String
  ) {
    treatment(id: $treatmentId) {
      ...NutritionSupportTreatment
    }
  }
`;
