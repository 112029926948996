import React, { useState } from 'react';
import {
  LinkButton,
  Typography,
  LoadingSpinner,
} from '@eucalyptusvc/design-system';
import { NoDeliveryModal } from './no-delivery-modal';
import { FormattedMessage } from 'react-intl';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { getConfig } from '@customer-frontend/config';
import { ReactComponent as Warning } from './assets/warning-triangle.svg';

interface ShippingValidationStatusProps {
  isValidatingShippingAddress: boolean;
  isShippingAddressWithinRange: boolean;
  isShippingAddressCompleted: boolean;
  isResidential?: boolean;
  onNoAlternateAddress?: () => void;
}

export const ShippingValidationStatus = ({
  isValidatingShippingAddress,
  isShippingAddressWithinRange,
  isShippingAddressCompleted,
  isResidential = false,
  onNoAlternateAddress,
}: ShippingValidationStatusProps): React.ReactElement => {
  const config = getConfig();
  const [showModal, setShowModal] = useState(false);
  const onOpenModal = (): void => {
    setShowModal(true);
  };

  const onCloseModal = (): void => {
    setShowModal(false);
  };

  const handleReturnToProfile = (): void => {
    if (onNoAlternateAddress) {
      onNoAlternateAddress();
    }
  };
  if (isValidatingShippingAddress) {
    return (
      <div
        className="flex w-full p-4 items-center justify-center rounded bg-status-warning-100 border border-status-warning-500"
        data-testid="validation-loading"
      >
        <LoadingSpinner />
      </div>
    );
  }
  if (!isShippingAddressCompleted) {
    return (
      <p
        className="p-2 text-black rounded bg-red-300 text-sm"
        data-testid="address-required"
      >
        <FormattedMessage defaultMessage="Address is required." />
      </p>
    );
  }

  if (isShippingAddressWithinRange) {
    return (
      <div
        className="w-full p-2 rounded bg-green-300"
        data-testid="address-success"
      >
        <Typography size="paragraph" isBold>
          <FormattedMessage defaultMessage="Great news! We can ship to your address." />
        </Typography>
      </div>
    );
  }

  return (
    <div className="w-full p-4 rounded bg-status-warning-100 border border-status-warning-500">
      {isResidential ? (
        <Typography color={sharedColors.neutral[900]} size="paragraph">
          <FormattedMessage defaultMessage="Please note: our cold storage delivery partners don’t ship to your residential address. However, you will have the option to provide an alternative shipping address later if a practitioner prescribes you treatment." />
        </Typography>
      ) : (
        <div
          className="flex flex-col space-y-2"
          data-testid="address-out-of-range"
        >
          <div className="flex gap-2 items-center">
            <Warning />
            <Typography
              color={sharedColors.neutral[900]}
              isBold
              size="paragraph"
            >
              <FormattedMessage
                defaultMessage="Please use a different address"
                description="error message for address validation"
              />
            </Typography>
          </div>
          <Typography color={sharedColors.neutral[900]} size="paragraph">
            <FormattedMessage
              defaultMessage="Our delivery partner doesn’t ship to this address, please use a different address. Find an <a>alternative suburb we ship to here.</a>"
              values={{
                a: (chunk) => (
                  <a
                    className="underline cursor"
                    href={config.alternativeShippingAddressPost}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunk}
                  </a>
                ),
              }}
            />
          </Typography>
          <Typography color={sharedColors.neutral[900]} size="paragraph">
            <FormattedMessage defaultMessage="Please note, our delivery partner can’t deliver to post offices, PO boxes, or parcel lockers." />
          </Typography>
          <Typography color={sharedColors.neutral[900]} size="paragraph">
            <FormattedMessage
              defaultMessage=" If you need support, <a>please send us a message.</a>"
              values={{
                a: (chunk) => (
                  <a
                    className="underline cursor"
                    href={config.zendeskRequestUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunk}
                  </a>
                ),
              }}
            />
          </Typography>
        </div>
      )}
      {!!onNoAlternateAddress && (
        <div className="text-center mt-4">
          <LinkButton onClick={onOpenModal}>
            <FormattedMessage defaultMessage="I don't have an alternative address" />
          </LinkButton>
          <NoDeliveryModal
            isOpen={showModal}
            onClose={onCloseModal}
            onReturnToProfile={handleReturnToProfile}
          />
        </div>
      )}
    </div>
  );
};
