import { Maybe } from '@customer-frontend/graphql-types';

export type ProductVariantInventorySku = {
  variants: Array<{
    inventory?: Maybe<{
      sku?: Maybe<string>;
    }>;
  }>;
};

export const hasSku = (
  product: ProductVariantInventorySku,
  sku: string,
): boolean =>
  product.variants.some(
    (v) =>
      v.inventory?.sku === sku ||
      v.inventory?.sku === `${sku}-R` ||
      // skus often have -dev ending in dev environment
      v.inventory?.sku === `${sku}-dev` ||
      v.inventory?.sku === `${sku}-R-dev`,
  );

const saxendaSKUPilot = 'WLPSAX10';
const saxendaSkuPilot12Month = 'WLPSAX12';
const saxendaSKUJuniper = 'MRPSAXV1';
const saxendaSkuJuniper12Month = 'MRPSAXV12';
const saxendaSkuJuniperNewPrice = 'MRPSAXV8';
const saxendaSKUFlexiplans = 'saxenda-rx';
const saxendaSkuJuniperStockShortage = 'WGT-JUN-SXNDA-06';

export const isSaxenda = (product?: ProductVariantInventorySku): boolean => {
  if (!product) {
    return false;
  }

  return [
    saxendaSKUPilot,
    saxendaSkuPilot12Month,
    saxendaSKUJuniper,
    saxendaSkuJuniper12Month,
    saxendaSkuJuniperNewPrice,
    saxendaSKUFlexiplans,
    saxendaSkuJuniperStockShortage,
  ].some((sku) => hasSku(product, sku));
};

// TODO: update to all supported SKU's
export const isPersonalisedWeightProduct = (
  product?: ProductVariantInventorySku | null,
): boolean => Boolean(product && isSaxenda(product));

// TODO: update to include all GLP-1 SKU's (or perhaps store this against the product)
export const productRequiresColdStorage = (
  product?: ProductVariantInventorySku,
): boolean => Boolean(product && isSaxenda(product));

const personalisedHairSku = 'HRMX1FN0.490';

export const isPersonalisedHairProduct = (
  product?: ProductVariantInventorySku,
): boolean => Boolean(product && hasSku(product, personalisedHairSku));
