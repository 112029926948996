import { Box, useStyleConfig } from '@chakra-ui/react';
import { ToastPalette, ToastProps, ToastIcon } from './types';
import { getConfig, getIcon } from './utils';
import { useThemeExtension } from '../../theme/shared';
import { Typography } from '..';
import { ToastTimer } from './timer';

export const Toast: React.FC<ToastProps> = ({
  type,
  message,
  palette,
  duration: durationProp,
}) => {
  const themeVariant = useThemeExtension<ToastPalette>(
    'toast.defaultStyle',
    'default',
  );
  const config = getConfig(type, palette || themeVariant);
  const styles = useStyleConfig('Toast', { variant: config });
  const duration = durationProp || 5000;

  const iconStyle = useThemeExtension<ToastIcon>('toast.iconStyle', 'solid');
  const icon = getIcon(type, iconStyle);

  return (
    <Box __css={styles}>
      {icon}
      <Typography size="paragraph" isBold inheritColor>
        {message}
      </Typography>
      <ToastTimer duration={duration} />
    </Box>
  );
};
