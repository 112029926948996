import { UpdateMedicareDetailsReturn } from './types';
import { GqlErrorCode } from '@eucalyptusvc/errors';
import { MutationUpdateMedicareDetailsArgs } from '@customer-frontend/graphql-types';
import { gql, useMutation } from '@apollo/client';
import { MedicareComponentAttributes } from '../../components/types';
import { splitMedicareCardExpiryString } from '@customer-frontend/utils';
interface Response {
  isMedicareCardValid: boolean;
}

export const updateMedicareDetailsMutation = gql`
  mutation UpdateMedicareDetails(
    $familyName: String!
    $firstName: String!
    $medicareNumber: String!
    $medicareIrn: Int!
    $validToMonth: Int!
    $validToYear: Int!
    $sex: MedicareSexType!
    $dateOfBirth: String!
  ) {
    updateMedicareDetails(
      familyName: $familyName
      firstName: $firstName
      medicareNumber: $medicareNumber
      medicareIrn: $medicareIrn
      validToMonth: $validToMonth
      validToYear: $validToYear
      sex: $sex
      dateOfBirth: $dateOfBirth
    )
  }
`;

export const useUpdateMedicareDetails = (
  skipErrorNotification: boolean,
  afterApiCall?: (result: boolean, errorCodes: GqlErrorCode[]) => void,
): UpdateMedicareDetailsReturn => {
  const [updateMedicareDetails, { loading }] = useMutation<
    Response,
    MutationUpdateMedicareDetailsArgs
  >(updateMedicareDetailsMutation, {
    context: {
      skipErrorNotification,
    },
    onCompleted: () => {
      if (afterApiCall) {
        afterApiCall(true, []);
      }
    },
    onError: (response) => {
      if (afterApiCall) {
        const errorCodes: GqlErrorCode[] = [];
        for (const error of response.graphQLErrors) {
          const errorCode = error.extensions?.code;
          if (errorCode && Object.values(GqlErrorCode).includes(errorCode)) {
            errorCodes.push(errorCode);
          }
        }
        afterApiCall(false, errorCodes);
      }
    },
  });

  const updateMedicareDetailsWrapper = async (
    medicareComponentAttrs: MedicareComponentAttributes,
  ): Promise<void> => {
    const {
      firstName,
      lastName: familyName,
      sex,
      birthday: dateOfBirth,
      medicareNumber,
      medicareIrn,
      medicareCardExpiry,
    } = medicareComponentAttrs;
    const { validToMonth, validToYear } =
      splitMedicareCardExpiryString(medicareCardExpiry);
    const medicareMutationData: MutationUpdateMedicareDetailsArgs = {
      firstName,
      familyName,
      sex,
      dateOfBirth,
      medicareNumber: medicareNumber ?? '',
      medicareIrn: Number(medicareIrn),
      validToMonth,
      validToYear,
    };

    await updateMedicareDetails({
      variables: {
        ...medicareMutationData,
      },
    });
  };

  return {
    updateMedicareDetails: updateMedicareDetailsWrapper,
    loading,
  };
};
