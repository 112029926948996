import { QuizCode } from '@customer-frontend/quiz';
import { generalQuizVerificationHooksConfig } from './general-quiz-verification-hooks-config';
import { GeneralQuizVerificationFn, VerificationResult } from './types';
import { useNoVerification } from './use-no-verification';

export const useGeneralQuizVerification = (
  quizCode: QuizCode | undefined,
): VerificationResult => {
  const hookToUse = getVerificationFnToUse(quizCode);
  return hookToUse();
};

const getVerificationFnToUse = (
  quizCode: QuizCode | undefined,
): GeneralQuizVerificationFn => {
  if (quizCode) {
    const verificationFnForQuizCode =
      generalQuizVerificationHooksConfig?.[quizCode];
    return verificationFnForQuizCode || useNoVerification;
  }
  return useNoVerification;
};
