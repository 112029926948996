import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  InitiateZipCheckoutMutation,
  InitiateZipCheckoutMutationVariables,
  InitiateZipConsultationPaymentMutation,
  InitiateZipConsultationPaymentMutationVariables,
} from '@customer-frontend/graphql-types';

export function useZipCheckout(): MutationTuple<
  InitiateZipCheckoutMutation,
  InitiateZipCheckoutMutationVariables
> {
  return useMutation<
    InitiateZipCheckoutMutation,
    InitiateZipCheckoutMutationVariables
  >(gql`
    mutation InitiateZipCheckout(
      $redirectUrl: String!
      $address: AddressCreateWithoutUserInput!
      $orderId: String!
      $couponCode: String
      $phone: String
    ) {
      initiateZipCheckout(
        redirectUrl: $redirectUrl
        address: $address
        orderId: $orderId
        couponCode: $couponCode
        phone: $phone
      ) {
        redirectUrl
      }
    }
  `);
}

export function useZipConsultationPayment(): MutationTuple<
  InitiateZipConsultationPaymentMutation,
  InitiateZipConsultationPaymentMutationVariables
> {
  return useMutation<
    InitiateZipConsultationPaymentMutation,
    InitiateZipConsultationPaymentMutationVariables
  >(gql`
    mutation InitiateZipConsultationPayment(
      $consultationId: String!
      $redirectUrl: String!
      $couponCode: String
    ) {
      initiateZipConsultationPayment(
        consultationId: $consultationId
        redirectUrl: $redirectUrl
        couponCode: $couponCode
      ) {
        redirectUrl
      }
    }
  `);
}
