let fonts;

switch (process.env.REACT_APP_BRAND) {
  case 'juniper-jp':
    fonts = { textFont: 'Noto Sans JP', headingFont: 'Noto Sans JP' };
    break;
  default:
    fonts = { textFont: 'Atlas Grotesk', headingFont: 'Nib Pro' };
}

export const { textFont, headingFont } = fonts;
