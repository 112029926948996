import React from 'react';
import { BooleanFlagKey } from '../config';
import { useFeatureFlagClient } from './provider';

interface Props {
  children: React.ReactNode;
  flagKey: BooleanFlagKey;
}

export const FeatureFlagBoolean = ({
  children,
  flagKey,
}: Props): React.ReactElement | null => {
  const client = useFeatureFlagClient();
  const flagValue = client.getBoolean(flagKey);
  return flagValue ? <> {children} </> : null;
};
