import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { CancelOtcSubMutationVariables } from '@customer-frontend/graphql-types';

const cancelSubscription = gql`
  mutation CancelOtcSub(
    $type: ProblemType!
    $cancelReason: String
    $cancelOtherReason: String
  ) {
    cancelSubscription(
      type: $type
      cancelReason: $cancelReason
      cancelOtherReason: $cancelOtherReason
    ) {
      id
    }
  }
`;

export function useCancelSubscription(
  options: MutationHookOptions<Response, CancelOtcSubMutationVariables>,
): MutationTuple<Response, CancelOtcSubMutationVariables> {
  return useMutation<Response, CancelOtcSubMutationVariables>(
    cancelSubscription,
    options,
  );
}
