import { sharedColors } from '../shared/palette';

// primary
const primary100 = '#ebeaf2';
const primary200 = '#CDCBDF';
const primary300 = '#9c97bf';
const primary400 = '#6A5DA6';
const primary500 = '#392F80';
const primary600 = '#231A66';

// secondary
const electric100 = '#5254F0';
const electric200 = '#1214D2';
const electric300 = '#090b90';
const cream100 = '#FFF8F0';

// tertiary
const palePurple = '#F0EFFE';
const lavender = '#C7BBEB';
const lilac = '#dadaf0';

// reserved
const fertility = '#FBBA91';
const conceiving = '#C6A5AC';
const pregnancy = '#E28E4E';
const postpartum = '#D76E6E';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    electric: {
      100: electric100,
      200: electric200,
      300: electric300,
    },
    cream: {
      100: cream100,
    },
  },
  tertiary: {
    palePurple,
    lavender,
    lilac,
  },
  reserved: {
    fertility,
    conceiving,
    pregnancy,
    postpartum,
  },
};
