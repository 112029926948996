import { useEnvironment } from '@customer-frontend/environment';
import { uiStorages } from '@customer-frontend/ui-storage';
import { useUrlQuery } from '@customer-frontend/utils';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getConfig } from '@customer-frontend/config';
import { useShowZendeskWidget } from '@customer-frontend/consultation';
import { ProblemType } from '@customer-frontend/graphql-types';
import { Logger } from '@customer-frontend/logger';
import { type ContentItem, ProcessContent } from './process-content';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import clsx from 'clsx';

interface StartWelcomeProps {
  problemType?: ProblemType;
  onComplete: () => void;
  isSyncExperience: boolean;
  logger: Logger;
  isLoading?: boolean;
  processContent: ContentItem[];
  applyLayout?: boolean;
  welcomeElement?: () => React.ReactElement;
}

export const StartWelcome = ({
  problemType,
  onComplete,
  isLoading,
  isSyncExperience,
  logger,
  processContent,
  applyLayout = true,
  welcomeElement: WelcomeElement,
}: StartWelcomeProps): ReactElement => {
  const config = getConfig();
  const environment = useEnvironment();
  const hasWelcomeContent = !!(isSyncExperience && WelcomeElement);

  useShowZendeskWidget({ problemType });
  const [showWelcomeElement, setShowWelcomeElement] =
    useState(hasWelcomeContent);

  const shouldRenderBackButton = hasWelcomeContent
    ? !showWelcomeElement
    : false;

  useBackButtonBehaviour(
    shouldRenderBackButton
      ? () => setShowWelcomeElement(!showWelcomeElement)
      : null,
  );

  const params = useUrlQuery();
  const productSkuPrefix = params.get('medPref');

  // offering_id=OFFERING_ID&sequence_selection=SEQUENCE_SET_ID,SEQUENCE_ID&sequence_selection=SEQUENCE_SET_ID,SEQUENCE_ID
  const offeringId = params.get('offering_id');
  const sequenceSelections = params.getAll('sequence_selection');

  if (offeringId && sequenceSelections) {
    if (sequenceSelections) {
      uiStorages.local.setValue(
        'offeringSelection',
        JSON.stringify({
          offeringId,
          sequenceSelections: sequenceSelections.map((pair) => {
            const [sequenceSetId, sequenceId] = pair.split(',');

            if (!sequenceSetId) {
              logger.error('expected sequence set id');
            }

            if (!sequenceId) {
              logger.error('expected sequence id');
            }

            return {
              sequenceSetId,
              sequenceId,
            };
          }),
        }),
      );
    } else {
      logger.error(
        'found an offering id in query params, but saw no sequence selections',
        {
          offeringId,
        },
      );
    }
  }

  if (productSkuPrefix) {
    uiStorages.local.setValue('medPrefSku', productSkuPrefix);
  }

  if (showWelcomeElement) {
    return (
      <section
        className={clsx('max-w-screen-sm px-5 mx-auto space-y-4', {
          'pb-8 pt-8 md:pt-16': applyLayout,
        })}
      >
        {WelcomeElement && <WelcomeElement />}
        <Button
          isFullWidth
          onClick={() => setShowWelcomeElement(false)}
          palette={getPrimaryButtonPalette(config.brand)}
        >
          <FormattedMessage
            description="Label for next button"
            defaultMessage="Next"
          />
        </Button>
      </section>
    );
  }

  return (
    <section
      className={clsx('max-w-screen-sm px-5 mx-auto', {
        'pb-8 pt-8 md:pt-16': applyLayout,
      })}
    >
      <div className="flex flex-col items-center space-y-2">
        <ProcessContent
          isSyncExperience={isSyncExperience}
          content={processContent}
        />
        <Button
          isFullWidth
          onClick={onComplete}
          isLoading={isLoading}
          palette={getPrimaryButtonPalette(config.brand)}
        >
          {isSyncExperience ? (
            <FormattedMessage defaultMessage="Next" />
          ) : (
            <FormattedMessage defaultMessage="Start the quiz" />
          )}
        </Button>
      </div>
      <div className="space-y-12 mt-3">
        <Typography size="small-text" element="p" textAlign="center">
          <FormattedMessage
            defaultMessage="By clicking above you agree to our <a>Terms & Conditions </a>and <b>Privacy Policy</b>."
            values={{
              a: (chunks) => {
                return (
                  <a
                    href={`${environment.landingPageUrl}${config.urlPaths.termsAndConditions}`}
                    className="text-link"
                    target="_blank"
                    rel="roreferrer noreferrer"
                  >
                    {chunks}
                  </a>
                );
              },
              b: (chunks) => {
                return (
                  <a
                    href={`${environment.landingPageUrl}${config.urlPaths.privacyPolicy}`}
                    className="text-link"
                    target="_blank"
                    rel="roreferrer noreferrer"
                  >
                    {chunks}
                  </a>
                );
              },
            }}
          />
        </Typography>
      </div>
    </section>
  );
};
