import { forwardRef } from 'react';
import { RadioGroup, Stack } from '@chakra-ui/react';
import { BaseRadioButtonGroupProps } from './types';
import { FormWrapper } from '../input/form-wrapper';
import { RadioButton } from '../radio-button';

const BaseRadioButtonGroup = (
  {
    label,
    name,
    disabled,
    showBox = false,
    errorMessage,
    stackDirection = 'column',
    spacing = 2,
    required,
    onChange,
    options,
    value,
  }: BaseRadioButtonGroupProps,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  return (
    <FormWrapper
      label={label}
      required={required}
      disabled={!!disabled}
      errorMessage={errorMessage}
      showMessageErrorIcon
    >
      <RadioGroup
        mt={label ? '4px' : 0}
        ref={ref}
        onChange={onChange}
        value={value}
        name={name}
        width="full"
      >
        <Stack spacing={spacing} direction={stackDirection}>
          {options.map((option) => {
            return (
              <RadioButton
                key={option.value}
                disabled={disabled}
                {...option}
                showBox={showBox}
                error={!!errorMessage}
                isSelected={option.value === value}
              />
            );
          })}
        </Stack>
      </RadioGroup>
    </FormWrapper>
  );
};

/** Use a **RadioButtonGroup** when you need the user to select exactly one
 * option from a set. Option layout can be set using the `stackDirection` prop
 * (see table below).
 */
export const RadioButtonGroup = forwardRef<
  HTMLInputElement,
  BaseRadioButtonGroupProps
>(BaseRadioButtonGroup);
