import React from 'react';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';
import {
  BuildReadingRouteParams,
  ReadingCollectionList,
} from '@customer-frontend/education';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import {
  EducationReadingCollectionQuery,
  EducationReadingCollectionQueryVariables,
} from '@customer-frontend/graphql-types';

interface ReadingCollectionProps {
  title: string;
  description?: string;
  collectionId: string;
  buildReadingRoute: (
    readingId: string,
    params?: BuildReadingRouteParams,
  ) => string;
}

export const ReadingCollection = ({
  title,
  description,
  collectionId,
  buildReadingRoute,
}: ReadingCollectionProps): React.ReactElement => {
  const history = useHistory();

  const { data, loading, error } = useQuery<
    EducationReadingCollectionQuery,
    EducationReadingCollectionQueryVariables
  >(
    gql`
      query EducationReadingCollection($id: ID!) {
        educationCollection(id: $id) {
          id
          name
          description
          readings {
            id
            content
            completedAt
            startedAt
            timeToReadMs
          }
        }
      }
    `,
    {
      variables: {
        id: collectionId,
      },
    },
  );
  const readings = data?.educationCollection?.readings;

  if (loading) {
    return (
      <div className="flex justify-center p-10">
        <LoadingSpinner palette="default" />
      </div>
    );
  }

  const handleItemClick = (readingId: string): void => {
    history.push(
      buildReadingRoute(readingId, {
        collectionId,
      }),
    );
  };

  return (
    <section className="space-y-8">
      <div className="space-y-4">
        <Typography size="lg" isBold>
          {title}
        </Typography>
        {description && (
          <Typography size="medium-paragraph">{description}</Typography>
        )}
      </div>
      {!readings?.length || error ? (
        <Typography size="medium-paragraph">
          <FormattedMessage defaultMessage="No articles available right now." />
        </Typography>
      ) : (
        <ReadingCollectionList
          readings={readings}
          onItemClick={handleItemClick}
        />
      )}
    </section>
  );
};
