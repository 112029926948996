import React from 'react';
import { UseFormMethods, DeepMap, FieldError } from 'react-hook-form';
import { emailIsValid, requiredValidation } from '@customer-frontend/utils';
import {
  Button,
  LinkButton,
  TextInput,
  Typography,
  Card,
} from '@eucalyptusvc/design-system';

import { PlanPaymentFormData } from './types';

export const LoginForm = ({
  register,
  errors,
  onSignupClick,
  onLoginFormSubmit,
  loading,
}: {
  register: UseFormMethods['register'];
  errors: DeepMap<PlanPaymentFormData, FieldError>;
  onSignupClick: () => void;
  onLoginFormSubmit: () => void;
  loading: boolean;
}): React.ReactElement => {
  return (
    <Card>
      <div className="space-y-4 mb-6">
        <Typography size="lg" isBold>
          Log in to your account
        </Typography>
        <Typography size="medium-paragraph">
          Not a member?{' '}
          <LinkButton onClick={onSignupClick}>Create an account</LinkButton>
        </Typography>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <TextInput
            required
            autoFocus
            ref={register({
              ...requiredValidation('email'),
              ...emailIsValid,
            })}
            label="Email"
            type="email"
            name="loginEmail"
            errorMessage={errors?.loginEmail?.message}
          />
        </div>
        <div>
          <TextInput
            required
            ref={register({
              ...requiredValidation('password'),
            })}
            label="Password"
            name="loginPassword"
            type="password"
            className="font-password tracking-wider"
            errorMessage={errors?.loginPassword?.message}
          />
        </div>
        <Button
          isSubmit
          level="primary"
          onClick={onLoginFormSubmit}
          isLoading={loading}
        >
          Login
        </Button>
      </div>
    </Card>
  );
};
