import {
  SignupMutation,
  SignupMutationVariables,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';

const signupDocument = gql`
  mutation Signup(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phone: String
    $id: ID
    $marketingCommsConsent: Boolean
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phone: $phone
      id: $id
      marketingCommsConsent: $marketingCommsConsent
    ) {
      token
      user {
        id
        email
        firstName
        lastName
        shortAddressableName
        fullName
        phone
        role
        isPasswordSet
        defaultPaymentGateway
        birthday
        preferredUnitSystem
        residentialAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
          company
          building
        }
        address {
          id
          line1
          line2
          city
          postalCode
          state
          country
          company
          building
        }
        zip {
          valid
        }
      }
    }
  }
`;

export function useSignupMutation(
  options?: MutationHookOptions<SignupMutation, SignupMutationVariables>,
): MutationTuple<SignupMutation, SignupMutationVariables> {
  return useMutation<SignupMutation, SignupMutationVariables>(
    signupDocument,
    options,
  );
}
