import {
  Button,
  LoadingSpinner,
  TextInput,
  Typography,
} from '@eucalyptusvc/design-system';
import { useEffect } from 'react';
import {
  useCreateAccountCpJuniperMutation,
  useEmailPhonePasswordStatusQuery,
} from '@customer-frontend/graphql-types';
import { useForm, UseFormMethods, RegisterOptions } from 'react-hook-form';
import {
  matchesValue,
  useMinLengthValidation,
  useRequiredValidation,
  useValidateIntlMobileNumber,
} from '@customer-frontend/utils';

import {
  TermsAndConditions,
  TermsAndConditionsProps,
} from './terms-and-conditions';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import { Layout } from './layout';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { useGetDefaultClinicianNounByCountryCode } from '../logic/cp-redesign-utils';
import { FormattedMessage, useIntl } from 'react-intl';

interface CreateAccountPageProps extends TermsAndConditionsProps {
  className?: string;
  onSubmit: () => Promise<void>;
}

export const CreateAccountPage: React.FC<CreateAccountPageProps> = ({
  className = '',
  termsAndConditionsPath,
  privacyPolicyPath,
  onSubmit,
}: CreateAccountPageProps) => {
  useHistoryGoBackBehaviour();
  const config = getConfig();

  const { data, loading } = useEmailPhonePasswordStatusQuery();
  const profile = data?.profile;
  const { email, phone, isPasswordSet } = profile ?? {};

  const [createProfile, { loading: createAccountLoading }] =
    useCreateAccountCpJuniperMutation();

  const { register, handleSubmit, errors, setValue, watch } = useForm<{
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
  }>({
    shouldUnregister: false,
    defaultValues: {},
  });

  useEffect(() => {
    setValue('email', email ?? '');
    setValue('phone', phone ?? '');
  }, [setValue, email, phone]);

  const password = watch().password;

  const submit = handleSubmit(async ({ phone, password }) => {
    await createProfile({
      variables: {
        phone,
        password: isPasswordSet ? undefined : password,
      },
    });
    await onSubmit();
  });

  const clinicianTitle = useGetDefaultClinicianNounByCountryCode(
    config.countryCode,
  );
  const { formatMessage } = useIntl();

  const phoneNumberValidation = useValidateIntlMobileNumber(
    data?.validPhoneRegions?.map(({ countryCode }) => countryCode) ?? [],
  );

  return (
    <Layout className={className || 'py-10 md:py-12'}>
      <Typography size="lg" isBold textAlign="center">
        <FormattedMessage
          defaultMessage="Based on your answers, you’re eligible to speak to a {clinicianTitle}"
          values={{ clinicianTitle }}
        />
      </Typography>
      <div className="mt-4 mb-8">
        <Typography size="medium-paragraph" textAlign={'center'}>
          <FormattedMessage defaultMessage="Create an account to get started." />
        </Typography>
      </div>
      {loading ? (
        <div className="flex justify-center p-5">
          <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={submit}>
          <div className="space-y-4">
            <TextInput
              ref={register}
              label={formatMessage({
                defaultMessage: 'Email',
              })}
              name="email"
              disabled
            />
            {config.shouldPresentPhoneFieldAtCreateAccount && (
              <PhoneInput
                errorMessage={errors?.phone?.message}
                register={register}
                phoneNumberValidation={phoneNumberValidation}
              />
            )}

            {!isPasswordSet && (
              <>
                <PasswordInput
                  errorMessage={errors?.password?.message}
                  register={register}
                />
                <ConfirmPasswordInput
                  password={password}
                  errorMessage={errors?.confirmPassword?.message}
                  register={register}
                />
              </>
            )}
            {config.countryCode !== 'GB' && (
              <div className="py-2">
                <TermsAndConditions
                  termsAndConditionsPath={termsAndConditionsPath}
                  privacyPolicyPath={privacyPolicyPath}
                />
              </div>
            )}

            <Button
              palette={getPrimaryButtonPalette(config.brand)}
              isFullWidth
              isSubmit
              isLoading={createAccountLoading}
              isDisabled={createAccountLoading}
            >
              <FormattedMessage defaultMessage="Next" />
            </Button>
          </div>
        </form>
      )}
    </Layout>
  );
};

const PhoneInput = ({
  errorMessage,
  register,
  phoneNumberValidation,
}: {
  errorMessage: string | undefined;
  register: UseFormMethods['register'];
  phoneNumberValidation?: RegisterOptions;
}): React.ReactElement => {
  const { formatMessage } = useIntl();

  const phoneLabel = formatMessage({
    defaultMessage: 'Phone',
  });
  const phoneValidation = {
    ...useRequiredValidation(phoneLabel),
    ...phoneNumberValidation,
  };

  return (
    <TextInput
      ref={register(phoneValidation)}
      label={formatMessage({
        defaultMessage: 'Phone',
      })}
      name="phone"
      errorMessage={errorMessage}
    />
  );
};

const PasswordInput = ({
  errorMessage,
  register,
}: {
  errorMessage: string | undefined;
  register: UseFormMethods['register'];
}): React.ReactElement => {
  const { formatMessage } = useIntl();

  const passwordLabel = formatMessage({
    defaultMessage: 'Password',
  });
  const passwordValidation = {
    ...useRequiredValidation(passwordLabel),
    ...useMinLengthValidation(passwordLabel, 8),
  };

  return (
    <TextInput
      name="password"
      ref={register(passwordValidation)}
      label={passwordLabel}
      type="password"
      errorMessage={errorMessage}
    />
  );
};

const ConfirmPasswordInput = ({
  password,
  errorMessage,
  register,
}: {
  password: string;
  errorMessage: string | undefined;
  register: UseFormMethods['register'];
}): React.ReactElement => {
  const { formatMessage } = useIntl();

  const confirmPasswordLabel = formatMessage({
    defaultMessage: 'Confirm password',
  });
  const confirmPasswordValidation = {
    ...useRequiredValidation(confirmPasswordLabel),
    ...matchesValue(password, {
      name: 'confirmPassword',
      message: formatMessage({
        defaultMessage: 'Passwords do not match',
      }),
    }),
  };

  return (
    <TextInput
      name="confirmPassword"
      ref={register(confirmPasswordValidation)}
      label={formatMessage({
        defaultMessage: 'Confirm password',
      })}
      type="password"
      errorMessage={errorMessage}
    />
  );
};
