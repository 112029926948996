import { gql } from '@apollo/client';
import { experimentPaymentPlanFragment } from './experimentPaymentPlan';

export const consultationFragment = gql`
  fragment ConsultationInfo on Consultation {
    id
    type
    status
    isApproved
    stage
    isUpfrontPayment
    patientNotes
    rejectReason
    createdAt
    completedAt
    medium
    chatThread {
      id
    }
    allowPatientToSendMessageToDoctor
    canSchedulePractitionerBooking
    requiresPrescriberCall
    payment {
      id
      charge {
        id
        createdAt
        amount
      }
    }
    patientQuestions {
      id
      createdAt
      updatedAt
      value
    }
    treatment {
      id
      status
      refillsLeft
      type
      experimentPaymentPlan {
        ...ExperimentPaymentPlan
      }
      experimentAvailablePaymentPlans {
        paymentPlan
        discountPercentage
        cadence
        months
        amount {
          total
          totalSaved
          perMonth
        }
      }
      plan {
        id
        amount
      }
      otcSchedules {
        id
        productId
        createdAt
        updatedAt
        cadence
        isActive
        quantity
        product {
          id
          name
          friendlyName
          photo {
            id
            url
          }
          variants {
            id
            price
          }
        }
      }
      product {
        id
        name
        friendlyName
        productType
        safetyInformation
        shortDescription
        deliveryInformation
        usage
        units
        photo {
          id
          url
        }
        cmi {
          id
          url
        }
        plan {
          id
          intervalCount
          interval
          amount
        }
        faqs {
          id
          title
          markdown
          videoEmbedUrl
        }
        variants {
          id
          price
          isRefill
          inventory {
            id
            sku
          }
        }
      }
    }
    customer {
      id
      email
      firstName
      birthday
      healthCareDetails {
        id
        hasIndividualHealthcareIdentifier
      }
    }
    quizResponses {
      id
      question {
        id
        type
        title
        shortcode
        options {
          value
        }
      }
      response {
        answers {
          value
          imageUrl
        }
        flag {
          level
        }
      }
    }
    doctorQuestions(orderBy: { createdAt: asc }) {
      id
      createdAt
      updatedAt
      value
      allowedAnswerType
      answer {
        id
        createdAt
        updatedAt
        value
      }
      attachments {
        id
        mimetype
        signedUrl
        filename
      }
    }
    doctor {
      id
      firstName
      lastName
      shortClinicianName
      fullClinicianName
      fullName
      role
      bio
      provider {
        id
        qualifications
        clinicianTitle
        clinicianType
      }
      avatar {
        id
        createdAt
        updatedAt
        filename
        mimetype
        url
      }
    }
    quizApplication {
      id
      submittedAt
      answers(orderBy: { createdAt: asc }) {
        id
        answer
        answersArray
        question {
          id
          shortcode
        }
      }
      quiz {
        id
        questions {
          id
          title
          type
          description
          required
          options
          imgUrl
          shortcode
          questionOptions {
            id
            questionId
            option
            position
          }
        }
        logics {
          id
          from {
            id
          }
          elseJump {
            id
          }
          jumps {
            id
            conditions(orderBy: { order: asc }) {
              id
              question {
                id
              }
              value
              operator
              nextCondition
            }
            to {
              id
            }
          }
        }
        skips {
          id
          from {
            id
          }
          conditions(orderBy: { order: asc }) {
            id
            question {
              id
            }
            value
            operator
            nextCondition
          }
        }
        questionsOrder
      }
    }
    order {
      id
      paymentStatus
      lineItems {
        id
        quantity
        variant {
          id
          price
          product {
            id
            name
            friendlyName
            productType
            shortDescription
            photo {
              id
              url
            }
          }
        }
      }
    }
    pathologyRequests {
      id
      createdAt
      customerStatedCollectedAt
      result {
        id
        lab
        releasedAt
        file {
          id
          url
        }
      }
      referral {
        id
        url
        signedUrl
      }
      panels {
        id
        tests {
          id
          name
          result {
            id
            note
            result {
              type
              value
            }
          }
        }
      }
    }
    reminders {
      id
      doneAt
      canceled
      scheduledAt
      createdAt
    }
    screeningQuizApplication {
      id
    }
  }
  ${experimentPaymentPlanFragment}
`;
