import { StyleFunctionProps, SystemStyleObject } from '@chakra-ui/theme-tools';
import { getWithFallbacks } from '../../shared';

const headingLineHeight = '125%';

export const text = {
  baseStyle: (props: StyleFunctionProps): SystemStyleObject => ({
    fontFamily: getWithFallbacks('Castledown'),
    fontWeight: props.isBold ? 700 : 400,
    lineHeight: '150%',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontStyle: props.isItalic ? 'italic' : 'normal',
  }),
  variants: {
    'landing-paragraph': {
      fontSize: '1.5rem', // 24px
    },
    'large-paragraph': {
      fontSize: '1.125rem', // 18px
    },
    'medium-paragraph': {
      fontSize: '1rem', // 16px
    },
    paragraph: {
      fontSize: '0.875rem', // 14px
    },
    'small-text': {
      fontSize: '0.75rem', // 12px
    },
  },
};

export const heading = {
  baseStyle: {
    fontFamily: getWithFallbacks('Grenette'),
    wordBreak: 'break-word',
  },
  variants: {
    '2xl': (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '2.5rem' : '3rem',
      fontWeight: props.isBold ? 600 : 400,
      lineHeight: headingLineHeight,
    }),
    xl: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '2rem' : '2.5rem',
      fontWeight: props.isBold ? 600 : 400,
      lineHeight: headingLineHeight,
    }),
    lg: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '1.5rem' : '2rem',
      fontWeight: props.isBold ? 600 : 400,
      lineHeight: headingLineHeight,
    }),
    md: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '1.25rem' : '1.5rem',
      fontWeight: props.isBold ? 600 : 400,
      lineHeight: headingLineHeight,
    }),
    sm: (props: StyleFunctionProps): SystemStyleObject => ({
      fontSize: props.isMobile ? '1.125rem' : '1.25rem',
      fontWeight: props.isBold ? 600 : 400,
      lineHeight: headingLineHeight,
    }),
    xs: (props: StyleFunctionProps): SystemStyleObject => ({
      fontFamily: getWithFallbacks('Castledown'),
      fontSize: '1.125rem', // 18px
      fontWeight: props.isBold ? 900 : 400,
      lineHeight: headingLineHeight,
    }),
  },
};
