import Cookies from 'js-cookie';
import { cookieDisabled } from '@customer-frontend/utils';
import { IUiStorage } from './IUiStorage';
import { UiStorageType } from './type';

class CookieUiStorage implements IUiStorage {
  constructor(private readonly expireInDays: number = 7) {}
  isSupported(): boolean {
    return !cookieDisabled();
  }
  public getType(): UiStorageType {
    return 'cookie';
  }
  public setValue(key: string, value: string): void {
    Cookies.set(key, value, {
      expires: this.expireInDays,
      path: '/',
      sameSite: 'strict',
    });
  }
  public getValue(key: string): string | undefined {
    return Cookies.get(key);
  }
  public clearValue(key: string): void {
    Cookies.remove(key);
  }
  public clear(): void {
    throw new Error('Not supported');
  }
}

export const cookieUiStorage = new CookieUiStorage();
