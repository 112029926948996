import { getConfig } from '@customer-frontend/config';
import { useIntl } from 'react-intl';

export const useWeightProgramName = (): string => {
  const config = getConfig();
  const { formatMessage } = useIntl();

  return config.brand === 'pilot'
    ? formatMessage({
        defaultMessage: 'Metabolic Reset',
      })
    : formatMessage({
        defaultMessage: 'Weight Reset',
      });
};
