import { getConfig } from '@customer-frontend/config';
import holdingPhoneLgaGraphic from '../../assets/programme/holding-phone-lga-graphic.webp';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  UIInteractionElementName,
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventService,
} from '@customer-frontend/events';

import { ReactComponent as LineChart } from '../../assets/line-chart.svg';
import { ReactComponent as Detail } from '../../assets/detail.svg';
import { ReactComponent as Truck } from '../../assets/truck.svg';
import googleButton from '../../assets/google-button.png';
import appleButton from '../../assets/apple-button.png';
import { Typography, useResponsive } from '@eucalyptusvc/design-system';
import clsx from 'clsx';

type MobileAppPromptSharedProps = {
  appleClickElementName: UIInteractionElementName;
  googleClickElementName: UIInteractionElementName;
};

export const MobileAppPrompt = (
  props: { portrait?: boolean } & MobileAppPromptSharedProps,
): React.ReactElement => {
  const { isMobile } = useResponsive();

  const portrait = props.portrait || isMobile;

  return (
    <div
      className={clsx(
        'border border-primary-300 rounded-sm bg-white flex overflow-hidden',
        portrait ? 'flex-col-reverse' : 'flex-row-reverse',
      )}
    >
      <div className={clsx(portrait ? 'w-full' : 'w-2/3')}>
        <MobileAppPromptContent {...props} />
      </div>
      <img
        src={holdingPhoneLgaGraphic}
        alt={''}
        className={clsx(
          'max-h-72 object-cover',
          portrait ? 'w-full' : 'w-1/3 h-auto',
        )}
      />
    </div>
  );
};

const MobileAppPromptContent = (
  props: MobileAppPromptSharedProps,
): React.ReactElement => {
  const { formatMessage } = useIntl();
  const config = getConfig();
  const eventService = useEventService();

  const handleAppStoreClick = (): void => {
    eventService.uiInteractionTracked({
      elementName: props.appleClickElementName,
      elementType: UIInteractionElementType.BUTTON,
      interaction: UIInteractionInteraction.CLICKED,
      pageUrl: window.location.toString(),
    });

    window.open(config.appStoreLinks?.appStore, '_blank');
  };

  const handleGoogleStoreClick = (): void => {
    eventService.uiInteractionTracked({
      elementName: props.googleClickElementName,
      elementType: UIInteractionElementType.BUTTON,
      interaction: UIInteractionInteraction.CLICKED,
      pageUrl: window.location.toString(),
    });

    window.open(config.appStoreLinks?.playStore, '_blank');
  };

  return (
    <div className="py-6 pl-6 pr-6 space-y-3">
      <div className="flex items-center space-x-2">
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage
            defaultMessage="Download the app"
            description="Title of card that prompts users to download the app"
          />
        </Typography>
      </div>
      <ul className="space-y-3">
        <li className="flex items-center space-x-2">
          <Truck width={16} height={16} />
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="Track your order status" />
          </Typography>
        </li>
        <li className="flex items-center space-x-2">
          <LineChart width={16} height={16} />
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="Set a goal and track your progress" />
          </Typography>
        </li>
        <li className="flex items-center space-x-2">
          <Detail width={16} height={16} />
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="Follow a personalised program" />
          </Typography>
        </li>
      </ul>
      <div className="flex items-center space-x-2 pt-3">
        {config.appStoreLinks?.appStore && (
          <button className="max-w-40" onClick={handleAppStoreClick}>
            <img
              src={appleButton}
              alt={formatMessage({ defaultMessage: 'App store button' })}
            />
          </button>
        )}
        {config.appStoreLinks?.playStore && (
          <button className="max-w-40" onClick={handleGoogleStoreClick}>
            <img
              src={googleButton}
              alt={formatMessage({
                defaultMessage: 'Google play store button',
              })}
            />
          </button>
        )}
      </div>
    </div>
  );
};
