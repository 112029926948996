import {
  useRefillSubscriptionV2,
  useSubscriptionV2,
} from '@customer-frontend/services';
import { Button, useNotification } from '@eucalyptusvc/design-system';
import React from 'react';
import { getErrorMessageDescriptorsFromError } from '@customer-frontend/graphql-client';
import { useIntl } from 'react-intl';

type RetryPaymentButtonProps = {
  subscriptionId: string;
};

export const RetryPaymentButton: React.FC<RetryPaymentButtonProps> = ({
  subscriptionId,
}) => {
  const notify = useNotification();
  const { formatMessage } = useIntl();
  const { refetch: refetchSubscription } = useSubscriptionV2({
    variables: {
      id: subscriptionId,
    },
  });

  const handleRefillSubscription = async (
    subscriptionId: string,
  ): Promise<void> => {
    try {
      setRefillLoading(true);
      await refillSubscription({
        variables: {
          subscriptionId,
        },
      });

      notify.success({
        message: 'Retry payment succeeded',
      });
      await refetchSubscription();
    } catch (error) {
      const descriptions = getErrorMessageDescriptorsFromError(error);
      descriptions.forEach((descriptor) =>
        notify.error({ message: formatMessage(descriptor) }),
      );
    } finally {
      setRefillLoading(false);
    }
  };

  const [refillLoading, setRefillLoading] = React.useState(false);
  const [refillSubscription] = useRefillSubscriptionV2();
  return (
    <Button
      isFullWidth
      level="primary"
      onClick={() => handleRefillSubscription(subscriptionId)}
      isLoading={refillLoading}
      isDisabled={refillLoading}
    >
      Retry payment
    </Button>
  );
};
