import debounce from 'lodash.debounce';
import { ApolloClient, gql, useApolloClient, useQuery } from '@apollo/client';
import {
  OfferingSelectionInput,
  PaymentGateway,
  SequenceSelectionInput,
  SubstituteConfirmInitiateZipCheckoutMutation,
  SubstituteConfirmInitiateZipCheckoutMutationVariables,
  SubstitutePurchaseConfirmAddressRangeQuery,
  SubstitutePurchaseConfirmAddressRangeQueryVariables,
  SubstitutePurchaseConfirmQuery,
  SubstitutePurchaseConfirmQueryVariables,
} from '@customer-frontend/graphql-types';
import {
  DeliveryInformation,
  DeliveryInstructions,
  DeliveryInstructionsFields,
  deliveryInstructionsToFields,
  fieldsToDeliveryInstructions,
  formatCentsToCurrency,
  ShippingValidationStatus,
} from '@customer-frontend/order';
import { OrderTimeline } from '@customer-frontend/treatment';
import {
  Accordion,
  Button,
  ButtonPalette,
  Card,
  CardPalette,
  Checkbox,
  Divider,
  LoadingSpinner,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { ReactComponent as CircleTickOutline } from '../assets/circle-tick-outline.svg';
import { ReactComponent as ShippingTruck } from '../assets/shipping-truck.svg';
import { ReactComponent as Syringe } from '../assets/syringe.svg';
import { ReactComponent as Tablet } from '../assets/tablet.svg';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  FormPaymentFields,
  PaymentMethods,
  PractitionerFollowUpCard,
  PurchaseOfferingsIntentHandlerInput,
  TitrationExplainerModalButton,
} from '@customer-frontend/page-templates';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { v4 } from 'uuid';
import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import {
  mustBeTrue,
  unicodeToBase64,
  useTitle,
} from '@customer-frontend/utils';
import { AddressFields, IntlAddressInput } from '@customer-frontend/intl';
import { uiStorages } from '@customer-frontend/ui-storage';
import {
  StripeProvider,
  useHistoryGoBackBehaviour,
} from '@customer-frontend/services';
import { Logger } from '@customer-frontend/logger';
import { InclusionsList } from './inclusions-list';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

type FormFields = {
  shippingAddress: {
    line1?: string;
    line2?: string;
    city?: string;
    postalCode?: string;
    state?: string;
    prefecture?: string;
    municipality?: string;
    townArea?: string;
    country?: string;
  };
  couponCode?: string;
  agreedToTerms: boolean;
  shippingAddressProximity?: 'inRange' | 'outOfRange';
  deliveryInstructions?: DeliveryInstructionsFields;
  payment: FormPaymentFields;
};

const validateShippingAddressRange = debounce(
  async (
    shippingAddress: AddressFields,
    apollo: ApolloClient<unknown>,
    onComplete: (p: FormFields['shippingAddressProximity']) => void,
  ) => {
    const line1 = shippingAddress?.line1;
    const line2 = shippingAddress?.line2;
    const suburb = shippingAddress?.city;
    const postcode = shippingAddress?.postalCode;

    if (!(line1 && postcode && suburb)) {
      return;
    }

    const query = await apollo.query<
      SubstitutePurchaseConfirmAddressRangeQuery,
      SubstitutePurchaseConfirmAddressRangeQueryVariables
    >({
      query: gql`
        query SubstitutePurchaseConfirmAddressRange(
          $line1: String!
          $line2: String
          $suburb: String!
          $postcode: String!
        ) {
          shippingAddressWithinRange(
            line1: $line1
            line2: $line2
            postcode: $postcode
            suburb: $suburb
          )
        }
      `,
      variables: {
        line1,
        line2,
        suburb,
        postcode,
      },
    });

    onComplete(
      query.data.shippingAddressWithinRange ? 'inRange' : 'outOfRange',
    );
  },
  1000,
);

function Inner({
  logger,
  routes,
  palette,
}: SubstitutePurchaseConfirmProps): JSX.Element | null {
  const config = getConfig();
  const apollo = useApolloClient();
  const environment = useEnvironment();
  const notify = useNotification();
  const [purchaseGroupId] = useState(v4());
  const { formatMessage, formatDate } = useIntl();
  const [appliedCouponCode, setAppliedCouponCode] = useState<string>();
  const form = useForm<FormFields>();
  const [addressValidating, setAddressValidating] = useState(false);
  const { consultationId } = useParams<{ consultationId: string }>();
  useHistoryGoBackBehaviour();

  useTitle(
    formatMessage({
      defaultMessage: 'Confirm your treatment plan',
      description: 'Page title for the Substitute purchase confirm page',
    }),
  );

  const { supportShippingAddressValidation, collectDeliveryInstructions } =
    config.purchasePrompts;

  const [payload, setPayload] = useState<{
    pricingSessionId: string;
    selections: OfferingSelectionInput[];
  }>();
  const history = useHistory();

  const stripe = useStripe();
  const stripeElements = useElements();

  const query = useQuery<
    SubstitutePurchaseConfirmQuery,
    SubstitutePurchaseConfirmQueryVariables
  >(
    gql`
      query SubstitutePurchaseConfirm(
        $consultationId: String!
        $offeringSelections: [OfferingSelectionInput!]!
        $noPayload: Boolean!
        $couponCodes: [String!]
        $pricingSessionId: ID!
      ) {
        profile {
          id
          defaultPaymentGateway
          address {
            id
            city
            line1
            line2
            state
            country
            postalCode
            prefecture
            municipality
            deliveryInstructions
            townArea
          }
          residentialAddress {
            id
            city
            line1
            line2
            state
            country
            postalCode
            prefecture
            municipality
            townArea
          }
          ...PaymentMethods
        }
        consultation(id: $consultationId) {
          id
          purchasePrompt {
            id
            ... on SubstitutePurchasePrompt {
              pricingSessionId
              substitutions {
                id
                substitution {
                  id
                  offering {
                    id
                    friendlyName
                    photoUrl
                  }
                  sequenceSelections {
                    id
                    sequence {
                      id
                      products {
                        id
                        deliveryInformation
                      }
                      ... on PrescribableSequence {
                        addressValidationExempt
                      }
                    }
                    sequenceSet {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        initialPurchasePrice(
          consultationId: $consultationId
          pricingSessionId: $pricingSessionId
          offeringSelections: $offeringSelections
          couponCodes: $couponCodes
        ) @skip(if: $noPayload) {
          id
          amount
          coupons {
            id
            code
            outcome
          }
          originalAmount
          discountAmount
          sessionCreditAmount
        }
        offeringsTimeline(offerings: $offeringSelections)
          @skip(if: $noPayload) {
          id
          date
          totalAmount
          products {
            id
            friendlyName
            erxMedicines {
              id
              itemForm
              itemStrength
            }
          }
        }
      }
      ${PaymentMethods.fragment}
    `,
    {
      errorPolicy: 'all',
      variables: {
        consultationId,
        noPayload: !payload,
        couponCodes: appliedCouponCode ? [appliedCouponCode] : undefined,
        pricingSessionId: payload?.pricingSessionId ?? '',
        offeringSelections: payload?.selections ?? [],
      },
      onCompleted(data) {
        if (
          data.initialPurchasePrice?.coupons &&
          data.initialPurchasePrice.coupons.length > 1
        ) {
          logger.error('expected none or one coupon');
        }

        if (!form.formState.isDirty) {
          let address: FormFields['shippingAddress'] = {};

          const shippingAddress = data.profile?.address;
          const residentialAddress = data.profile?.residentialAddress;

          if (residentialAddress) {
            address = {
              city: residentialAddress.city ?? undefined,
              line1: residentialAddress.line1 ?? undefined,
              line2: residentialAddress.line2 ?? undefined,
              state: residentialAddress.state ?? undefined,
              country: residentialAddress.country ?? config.country,
              postalCode: residentialAddress.postalCode ?? undefined,
              prefecture: residentialAddress.prefecture ?? undefined,
              municipality: residentialAddress.municipality ?? undefined,
              townArea: residentialAddress.townArea ?? undefined,
            };
          }

          if (shippingAddress) {
            address = {
              city: shippingAddress.city ?? undefined,
              line1: shippingAddress.line1 ?? undefined,
              line2: shippingAddress.line2 ?? undefined,
              state: shippingAddress.state ?? undefined,
              country: shippingAddress.country ?? config.country,
              postalCode: shippingAddress.postalCode ?? undefined,
              prefecture: shippingAddress.prefecture ?? undefined,
              municipality: shippingAddress.municipality ?? undefined,
              townArea: shippingAddress.townArea ?? undefined,
            };
          }

          let deliveryInstructions;
          if (collectDeliveryInstructions) {
            deliveryInstructions = deliveryInstructionsToFields(
              data.profile?.address?.deliveryInstructions,
            );
          }

          form.reset({
            shippingAddress: address,
            deliveryInstructions,
          });

          if (supportShippingAddressValidation) {
            validateShippingAddressRange(address, apollo, (p) => {
              form.setValue('shippingAddressProximity', p);
            });
          }
        }
      },
    },
  );

  const data = query.data ?? query.previousData;
  const profile = data?.profile;
  const timeline = data?.offeringsTimeline ?? [];
  const consultation = data?.consultation;
  const ipp = data?.initialPurchasePrice;

  const coupon = ipp?.coupons?.[0];

  useEffect(() => {
    const purchasePrompt = consultation?.purchasePrompt;
    setPayload((s) => {
      if (s) {
        // We don't expect this to change after the page has already loaded.
        return s;
      }

      if (purchasePrompt?.__typename !== 'SubstitutePurchasePrompt') {
        return s;
      }

      const selection = purchasePrompt.substitutions?.[0]?.substitution;
      if (!selection?.offering) {
        return s;
      }

      if (!selection.sequenceSelections) {
        return s;
      }

      const sequenceSelections: SequenceSelectionInput[] = [];
      for (const ss of selection.sequenceSelections) {
        if (ss.sequence?.id && ss.sequenceSet?.id) {
          sequenceSelections.push({
            sequenceId: ss.sequence.id,
            sequenceSetId: ss.sequenceSet.id,
          });
        }
      }

      return {
        pricingSessionId: purchasePrompt.pricingSessionId,
        selections: [{ offeringId: selection.offering.id, sequenceSelections }],
      };
    });
  }, [consultation]);

  const ffAddressValidationExemption = useFeatureFlagBoolean(
    'FF_ADDRESS_VALIDATION_EXEMPTION',
  );

  if (query.loading && !data?.offeringsTimeline) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!consultation?.purchasePrompt) {
    logger.error(
      `no purchase prompt for consultation id "${consultationId}", redirecting to profile`,
    );
    return <Redirect to={routes.profile} />;
  }

  const purchasePrompt = consultation.purchasePrompt;

  if (purchasePrompt.__typename !== 'SubstitutePurchasePrompt') {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" type was expected to be SubstitutePurchasePrompt but is "${purchasePrompt.__typename}"`,
    );
    return <Redirect to={routes.profile} />;
  }

  // TODO: This will currently disallow flexi-OTC-addons.
  const substitutions = purchasePrompt.substitutions;
  if (substitutions?.length !== 1) {
    logger.error(
      `exactly one substitution is allowed for purchase prompt "${purchasePrompt.id}" redirecting to profile`,
    );
    return <Redirect to={routes.profile} />;
  }

  const offeringSelection = substitutions[0]?.substitution;
  if (!offeringSelection) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering selection`,
    );
    return <Redirect to={routes.profile} />;
  }

  const exemptFromShippingValidation =
    offeringSelection?.sequenceSelections?.every((ss) => {
      if (ffAddressValidationExemption === true) {
        return true;
      }

      if (ss.sequence?.__typename !== 'PrescribableSequence') {
        return true;
      }

      if (ss.sequence.addressValidationExempt) {
        return true;
      }

      return false;
    });

  const offering = offeringSelection?.offering;
  if (!offering) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (!stripe) {
    logger.error(`stripe is not set`);
    return <Redirect to={routes.profile} />;
  }

  if (!stripeElements) {
    logger.error(`stripeElements is not set`);
    return <Redirect to={routes.profile} />;
  }

  if (!uiStorages.local.isSupported()) {
    logger.error('local storage is not available');
    return <Redirect to={routes.profile} />;
  }

  let localisedTotal = '';
  if (typeof ipp?.amount === 'number') {
    localisedTotal = formatCentsToCurrency(ipp.amount);
  }

  let localisedSubtotal = '';
  if (typeof ipp?.originalAmount === 'number') {
    localisedSubtotal = formatCentsToCurrency(ipp.originalAmount);
  }

  let localisedDiscount = '';
  if (typeof ipp?.discountAmount === 'number') {
    localisedDiscount = formatCentsToCurrency(-ipp.discountAmount);
  }

  let localisedSessionCredit = '';
  if (typeof ipp?.sessionCreditAmount === 'number') {
    localisedSessionCredit = formatCentsToCurrency(-ipp.sessionCreditAmount);
  }

  const erxMedicineStrengths = new Set<string>();

  const orders: Parameters<typeof OrderTimeline>[0]['orders'] = timeline.map(
    (tli, idx) => {
      let price = formatCentsToCurrency(tli.totalAmount);
      let showPricePredictedDisclaimer = false;
      let secondaryLabel;
      let primaryLabelAdornment = <Syringe className="h-4 w-4" />;
      if (idx === 0) {
        price = localisedTotal;
        secondaryLabel = formatMessage(
          {
            defaultMessage: 'Processing on {date}',
            description: 'Substitute purchase page offering image alt text',
          },
          {
            date: formatDate(tli.date, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }),
          },
        );
      } else {
        showPricePredictedDisclaimer = true;
      }

      const names = new Set<string>();
      tli.products?.forEach((p) => {
        if (p.friendlyName) {
          names.add(p.friendlyName);
        }
        p.erxMedicines.forEach((m) => {
          const itemForm = m.itemForm?.toLowerCase();
          const itemStrength = m.itemStrength?.trim();

          if (itemForm?.includes('tablet')) {
            primaryLabelAdornment = <Tablet className="h-4 w-4" />;
          } else if (itemForm?.includes('injectable')) {
            primaryLabelAdornment = <Syringe className="h-4 w-4" />;
          }

          if (itemStrength) {
            erxMedicineStrengths.add(itemStrength);
          }
        });
      });

      return {
        id: tli.id,
        stage: 'circleStatic',
        price,
        showPricePredictedDisclaimer,
        primaryLabel: Array.from(names).join(', '),
        primaryLabelAdornment,
        secondaryLabel,
      };
    },
  );

  const formCouponCode = form.watch('couponCode');

  const onSubmit = form.handleSubmit(async (data) => {
    if (payload === undefined) {
      return logger.error('payload is undefined');
    }

    if (typeof ipp?.amount !== 'number') {
      return logger.error('ipp is not a number');
    }

    let gateway: PaymentGateway | undefined = undefined;

    if (profile?.defaultPaymentGateway) {
      gateway = profile.defaultPaymentGateway;
    }
    if (data.payment?.method) {
      gateway = data.payment.method;
    }
    if (gateway !== 'STRIPE' && gateway !== 'ZIP') {
      return logger.error(`gateway of type "${gateway}" is not supported`);
    }

    const errMsg = formatMessage({
      defaultMessage: 'Failed to confirm your payment',
      description: 'Substitute purchase page error message copy',
    });

    const url = new URL(
      routes.handlePurchaseOfferingsIntent,
      window.location.origin,
    );

    const shippingAddress = data.shippingAddress;
    if (!shippingAddress.country) {
      shippingAddress.country = config.country;
    }

    const purchaseOfferingsInput: PurchaseOfferingsIntentHandlerInput = {
      gateway,
      offerings: payload.selections,
      pricingSessionId: payload.pricingSessionId,
      consultationId,
      purchaseGroupId,
      expectedChargeAmount: ipp.amount,
      shippingAddress,
      source: 'OP',
      consent: true,
    };

    if (data.deliveryInstructions && purchaseOfferingsInput.shippingAddress) {
      purchaseOfferingsInput.shippingAddress.deliveryInstructions =
        fieldsToDeliveryInstructions(data.deliveryInstructions);
    }

    const localStorageKeyForPurchaseOfferingsInput = 'purchaseOfferingsInput';
    uiStorages.local.setValue(
      localStorageKeyForPurchaseOfferingsInput,
      unicodeToBase64(JSON.stringify(purchaseOfferingsInput)),
    );

    if (coupon?.outcome === 'SUCCESS') {
      if (appliedCouponCode) {
        purchaseOfferingsInput.couponCode = appliedCouponCode;
      } else {
        // couponCode of undefined will persist any existing coupon codes
        purchaseOfferingsInput.couponCode = undefined;
      }
    } else {
      purchaseOfferingsInput.couponCode = null;
    }

    url.searchParams.set(
      'purchaseOfferingsInputStorageKey',
      encodeURIComponent(localStorageKeyForPurchaseOfferingsInput),
    );

    if (
      ipp.amount !== 0 &&
      gateway === 'STRIPE' &&
      data.payment?.paymentDetailsCompleted
    ) {
      const { error } = await stripe.confirmSetup({
        elements: stripeElements,
        confirmParams: {
          return_url: url.toString(),
        },
      });

      if (error) {
        notify.error({
          message: error.message ?? errMsg,
        });
      }
    } else if (ipp.amount !== 0 && gateway === 'ZIP' && !profile?.zip?.valid) {
      try {
        const resp = await apollo.mutate<
          SubstituteConfirmInitiateZipCheckoutMutation,
          SubstituteConfirmInitiateZipCheckoutMutationVariables
        >({
          mutation: initiateZipCheckoutMutation,
          variables: {
            input: {
              offeringSelections: payload.selections,
              pricingSessionId: payload.pricingSessionId,
              couponCodes: appliedCouponCode ? [appliedCouponCode] : undefined,
              redirectUrl: url.toString(),
            },
          },
          errorPolicy: 'all',
          context: {
            skipErrorNotification: true,
          },
        });

        if (!resp?.data?.initiateFlexiZipCheckout?.redirectUrl) {
          throw new Error('Unable to initiate zip checkout');
        }

        window.location.assign(resp.data.initiateFlexiZipCheckout.redirectUrl);
      } catch {
        notify.error({ message: errMsg });
        return;
      }
    } else if (
      (gateway === 'STRIPE' && !data.payment?.paymentDetailsCompleted) ||
      (gateway === 'ZIP' && profile?.zip?.valid) ||
      ipp.amount === 0
    ) {
      // We're using prefilled details here or we're not charging, so we just redirect without hitting Stripe/Zip
      history.push({
        pathname: url.pathname,
        search: url.search,
      });
    }
  });

  const shippingAddress = form.watch('shippingAddress');
  const shippingAddressFilled =
    !!shippingAddress?.postalCode &&
    !!shippingAddress?.city &&
    !!shippingAddress?.line1;
  const shippingAddressInRange =
    form.watch('shippingAddressProximity') === 'inRange';

  const deliveryInformation = Array.from(
    new Set(
      offeringSelection.sequenceSelections?.flatMap(
        (ss) =>
          ss.sequence?.products?.map((p) => p.deliveryInformation ?? '') ?? '',
      ),
    ),
  ).join('\n\n');

  return (
    <form
      onSubmit={onSubmit}
      className="max-w-screen-md px-4 md:px-0 pt-10 md:pt-14 pb-10 md:pb-24 mx-auto flex flex-col items-center gap-8"
    >
      <div className="self-start">
        <Typography isBold size="lg">
          <FormattedMessage
            defaultMessage="Confirm your order"
            description="Substitute purchase page title"
          />
        </Typography>
      </div>

      <div className="w-full">
        <Card palette={palette?.orderSummaryCard}>
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Your order summary"
              description="Substitute purchase page hero card title"
            />
          </Typography>
          <Divider variant="separator" />
          <div
            className={clsx('flex justify-between', {
              'mb-5': ipp?.amount === ipp?.originalAmount,
            })}
          >
            <div className="flex gap-4">
              <img
                src={offering.photoUrl}
                className="h-16 rounded"
                alt={formatMessage({
                  defaultMessage: 'Offering image',
                  description:
                    'Substitute purchase page offering image alt text',
                })}
              />
              <div className="space-y-2">
                <Typography isBold size="sm">
                  {offering.friendlyName}
                </Typography>
                <Typography size="medium-paragraph">
                  <FormattedMessage
                    defaultMessage="Your first order"
                    description="Substitute purchase page first order copy"
                  />
                </Typography>
              </div>
            </div>
            <Typography size="large-paragraph">
              <span className="whitespace-nowrap">{localisedSubtotal}</span>
            </Typography>
          </div>

          {ipp?.amount !== ipp?.originalAmount && (
            <>
              <Divider variant="separator" />
              <div className="w-full flex items-center justify-between">
                <div>
                  <Typography size="paragraph">
                    <FormattedMessage
                      defaultMessage="Subtotal"
                      description="Follow-up or review purchase page subtotal amount"
                    />
                  </Typography>
                </div>
                <div>
                  <Typography size="medium-paragraph">
                    {localisedSubtotal}
                  </Typography>
                </div>
              </div>

              <Divider variant="separator" />

              <div className="space-y-4">
                {!!ipp?.discountAmount && (
                  <div className="w-full flex items-center justify-between">
                    <div>
                      <Typography size="paragraph">
                        <FormattedMessage
                          defaultMessage="Discount code applied"
                          description="Follow-up or review purchase page discount amount"
                        />
                      </Typography>
                    </div>
                    <div>
                      <Typography size="medium-paragraph">
                        {localisedDiscount}
                      </Typography>
                    </div>
                  </div>
                )}
                {!!ipp?.sessionCreditAmount && (
                  <div className="w-full flex items-center justify-between">
                    <div>
                      <Typography size="paragraph">
                        <FormattedMessage
                          defaultMessage="Amount already paid"
                          description="Follow-up or review purchase page already paid amount"
                        />
                      </Typography>
                    </div>
                    <div>
                      <Typography size="medium-paragraph">
                        {localisedSessionCredit}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>

              <Divider variant="separator" />
              <div className="w-full flex items-center justify-between mb-5">
                <div>
                  <Typography isBold size="paragraph">
                    <FormattedMessage
                      defaultMessage="Total due today"
                      description="Follow-up or review purchase page payment amount"
                    />
                  </Typography>
                </div>
                <div>
                  <Typography isBold size="medium-paragraph">
                    {localisedTotal}
                  </Typography>
                </div>
              </div>
            </>
          )}

          <Accordion
            trimBottom
            title={
              <Typography isBold size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="Your treatment plan schedule"
                  description="Substitute purchase page timeline title"
                />
              </Typography>
            }
            content={
              <>
                {erxMedicineStrengths.size > 1 && (
                  <div className="mb-4">
                    <TitrationExplainerModalButton />
                  </div>
                )}
                <OrderTimeline orders={orders} truncate={6} />
                {orders.some((o) => o.showPricePredictedDisclaimer) && (
                  <div className="mb-2">
                    <Typography size="small-text">
                      <FormattedMessage defaultMessage="* These prices don't reflect the application of discount codes. Medication costs and the cost of our services may vary month-to-month depending on the type of medication, dosage, medical and other support required and the relevant associate pharmacy dispensing the medication." />
                    </Typography>
                  </div>
                )}
                <div className="p-2">
                  <PractitionerFollowUpCard cardPalette={palette?.nestedCard} />
                </div>
              </>
            }
          />
          <Divider variant="separator" mt="sm" />
          <InclusionsList />
        </Card>
      </div>

      <div className="w-full">
        <Card>
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Shipping details"
              description="Substitute purchase page shipping details"
            />
          </Typography>
          <Divider variant="separator" mt="sm" />
          {supportShippingAddressValidation &&
            !exemptFromShippingValidation && (
              <input
                type="hidden"
                name="shippingAddressProximity"
                ref={form.register({
                  validate: (v) => v === 'inRange',
                })}
              />
            )}
          <IntlAddressInput
            name="shippingAddress"
            registerField={form.register}
            errors={form.errors.shippingAddress}
            onChange={async (address) => {
              if (supportShippingAddressValidation) {
                setAddressValidating(true);
                validateShippingAddressRange(address, apollo, (p) => {
                  form.setValue('shippingAddressProximity', p);
                  setAddressValidating(false);
                });
              }
            }}
            useAutoComplete
          />
          {supportShippingAddressValidation &&
            !exemptFromShippingValidation && (
              <ShippingValidationStatus
                isResidential={false}
                isValidatingShippingAddress={addressValidating}
                isShippingAddressCompleted={shippingAddressFilled}
                isShippingAddressWithinRange={shippingAddressInRange}
              />
            )}
          {collectDeliveryInstructions && (
            <div>
              <Divider variant="separator" />
              <DeliveryInstructions
                registerField={form.register}
                watch={form.watch}
                name="deliveryInstructions"
                errors={form.errors.deliveryInstructions ?? {}}
              />
            </div>
          )}
        </Card>
      </div>

      <div className="w-full">
        {deliveryInformation && (
          <DeliveryInformation
            Icon={ShippingTruck}
            markdown={deliveryInformation}
          />
        )}
      </div>

      {ipp?.amount !== 0 && (
        <div className="w-full">
          <Card>
            <Typography isBold size="md">
              <FormattedMessage
                defaultMessage="Payment details"
                description="Substitute purchase page card details form title"
              />
            </Typography>
            <Divider variant="separator" mt="sm" />
            {!(
              appliedCouponCode === undefined && coupon?.outcome === 'SUCCESS'
            ) && (
              <div className="mb-4">
                <div className="flex gap-4">
                  <div className="md:w-72">
                    <TextInput
                      ref={form.register({
                        maxLength: {
                          value: 100,
                          message: formatMessage({
                            defaultMessage:
                              'Discount code must be less than 100 characters',
                            description:
                              'Substitute purchase page discount code validation message',
                          }),
                        },
                      })}
                      errorMessage={form.errors.couponCode?.message}
                      name="couponCode"
                      label={formatMessage({
                        defaultMessage: 'Discount code',
                        description:
                          'Substitute purchase page discount code field label',
                      })}
                    />
                  </div>
                  <div className="pt-6">
                    <Button
                      level="secondary"
                      isLoading={query.loading}
                      isDisabled={
                        !formCouponCode || formCouponCode === appliedCouponCode
                      }
                      onClick={async () => {
                        const isValid = await form.trigger('couponCode');
                        if (!isValid) {
                          return;
                        }
                        setAppliedCouponCode(formCouponCode);
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Apply"
                        description="Substitute purchase page apply coupon code button copy"
                      />
                    </Button>
                  </div>
                </div>

                {(() => {
                  if (query.loading) {
                    return null;
                  }

                  let couponMessage: string | undefined;
                  let showSuccess = false;
                  switch (coupon?.outcome) {
                    case undefined:
                      break;
                    case 'EXPIRED':
                      couponMessage = formatMessage(
                        {
                          defaultMessage: 'Discount code {coupon} has expired',
                        },
                        { coupon: appliedCouponCode },
                      );
                      break;
                    case 'GENERIC_FAILURE':
                      couponMessage = formatMessage(
                        {
                          defaultMessage:
                            'Discount code {coupon} could not be applied',
                        },
                        { coupon: appliedCouponCode },
                      );
                      break;
                    case 'NOT_FOUND':
                      couponMessage = formatMessage(
                        { defaultMessage: 'Discount code {coupon} not found' },
                        { coupon: appliedCouponCode },
                      );
                      break;
                    case 'SUCCESS':
                      showSuccess = true;
                      couponMessage = formatMessage(
                        { defaultMessage: 'Discount code {coupon} applied' },
                        { coupon: appliedCouponCode },
                      );
                      break;
                  }

                  if (!couponMessage) {
                    return null;
                  }

                  return (
                    <div
                      className={clsx('flex gap-1 items-center mt-2', {
                        'text-status-success-500': showSuccess,
                        'text-status-error-500': !showSuccess,
                      })}
                    >
                      {showSuccess && (
                        <CircleTickOutline className="fill-current w-4 h-4" />
                      )}
                      <Typography inheritColor size="small-text" isBold>
                        {couponMessage}
                      </Typography>
                    </div>
                  );
                })()}
              </div>
            )}
            <div className="mb-1">
              <Typography isBold size="paragraph">
                <FormattedMessage
                  defaultMessage="Enter card details"
                  description="Substitute purchase page card details field label"
                />
              </Typography>
            </div>
            {profile && (
              <PaymentMethods
                control={form.control}
                errors={form.errors}
                fragment={profile}
                name="payment"
              />
            )}
          </Card>
        </div>
      )}

      {config.purchasePrompts.showTermsAndConditionsCheckbox && (
        <div className="mt-6">
          <Checkbox
            isChecked={false}
            ref={form.register({
              ...mustBeTrue(
                formatMessage({
                  defaultMessage:
                    'To continue, you must agree to the terms of service',
                  description:
                    'Substitute purchase page terms of service required error message',
                }),
              ),
            })}
            errorMessage={form.errors?.agreedToTerms?.message}
            name="agreedToTerms"
            label={
              <Typography size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="I have read and understand the <a>Terms of Service</a>, <b>Terms of Sale</b>, and <c>Privacy Notice</c>"
                  description="Substitute purchase page terms of service"
                  values={{
                    a: (chunks) => {
                      return (
                        <a
                          className="underline"
                          href={`${environment.landingPageUrl}${config.urlPaths.termsOfService}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {chunks}
                        </a>
                      );
                    },
                    b: (chunks) => (
                      <a
                        className="underline"
                        href={`${environment.landingPageUrl}${config.urlPaths.termsOfSale}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    ),
                    c: (chunks) => (
                      <a
                        className="underline"
                        href={`${environment.landingPageUrl}${config.urlPaths.privacyNotice}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </Typography>
            }
          />
        </div>
      )}

      <Button
        palette={palette?.confirmButton}
        isFullWidth
        isSubmit
        isDisabled={typeof ipp?.amount !== 'number'}
        isLoading={form.formState.isSubmitting}
      >
        <FormattedMessage
          defaultMessage="Confirm order"
          description="Substitute purchase page confirm order button"
        />
      </Button>
    </form>
  );
}

type SubstitutePurchaseConfirmProps = {
  routes: {
    profile: string;
    handlePurchaseOfferingsIntent: string;
    orderConfirmed: string;
  };
  palette?: {
    confirmButton?: ButtonPalette;
    card?: CardPalette;
    orderSummaryCard?: CardPalette;
    nestedCard?: CardPalette;
  };
  logger: Logger;
};

export const SubstitutePurchaseConfirm = (
  props: SubstitutePurchaseConfirmProps,
): React.ReactElement => {
  return (
    <StripeProvider api="paymentIntents" logger={props.logger}>
      <Inner {...props} />
    </StripeProvider>
  );
};

const initiateZipCheckoutMutation = gql`
  mutation SubstituteConfirmInitiateZipCheckout(
    $input: InitiateFlexiZipCheckoutInput!
  ) {
    initiateFlexiZipCheckout(input: $input) {
      redirectUrl
    }
  }
`;
