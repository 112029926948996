import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  StyleFunctionProps,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

type ModalStyleProps = {
  colors: {
    closeButtonColor: string;
    text: string;
  };
};

const baseStyleOverlay: SystemStyleObject = {
  background: 'rgba(39, 45, 51, 70%)',
  zIndex: 'modal',
};

const baseStyleDialogContainer = (
  textColor: string,
  props: StyleFunctionProps,
): SystemStyleObject => {
  const { isCentered, scrollBehavior } = props;

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: isCentered ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
    color: textColor,
  };
};

const baseStyleDialog: SystemStyleObject = {
  borderRadius: '8px',
  bg: 'white',
  color: 'inherit',
  zIndex: '100000000000',
  my: 'auto',
  padding: '32px',
};

const baseStyleHeader: SystemStyleObject = {
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  // default styling overrides
  paddingInlineStart: 0,
  paddingInlineEnd: 0,
  paddingTop: 0,
  paddingBottom: 0,
};

const baseStyleCloseButton = (buttonColor: string): SystemStyleObject => ({
  color: buttonColor,
  flexGrow: 0,
  marginLeft: '16px',
  height: '1rem',
  width: '1rem',
  svg: {
    height: '100%',
    width: '100%',
  },
  position: 'relative',
  top: 'inherit',
  right: 'inherit',
});

const baseStyleBody: SystemStyleFunction = (props) => {
  const { scrollBehavior } = props;
  return {
    px: 0,
    pt: 0,
    flex: 1,
    ml: '-3px', // Allow for shadows to spill over container
    pl: '3px',
    mr: '-3px',
    pr: '3px',
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
  };
};

const baseStyleFooter: SystemStyleObject = {
  padding: '0px',
};

const getBaseStyle = (
  buttonColor: string,
  textColor: string,
): PartsStyleFunction => {
  const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
    overlay: baseStyleOverlay,
    dialogContainer: baseStyleDialogContainer(textColor, props),
    dialog: baseStyleDialog,
    header: baseStyleHeader,
    closeButton: baseStyleCloseButton(buttonColor),
    body: baseStyleBody(props),
    footer: baseStyleFooter,
  });
  return baseStyle;
};

const sizes = {
  sm: {
    dialog: {
      width: '400px',
      maxWidth: '400px',
    },
  },
  'sm-mobile': {
    dialog: {
      padding: '24px',
      margin: 'auto 16px',
    },
  },
  md: {
    dialog: {
      width: '600px',
      maxWidth: '600px',
    },
  },
  'md-mobile': {
    dialog: {
      padding: '24px',
      margin: 'auto 16px',
    },
  },
};

const defaultProps = {
  size: 'md',
};

export const getModalStyles = (
  props: ModalStyleProps,
): Record<string, SystemStyleObject | PartsStyleFunction> => ({
  baseStyle: getBaseStyle(props.colors.closeButtonColor, props.colors.text),
  sizes,
  defaultProps,
});
