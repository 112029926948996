import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@eucalyptusvc/design-system';
import { RichText } from '@customer-frontend/contentful';
import { EducationReading } from '@customer-frontend/graphql-types';
import { HeroSection } from '@customer-frontend/education';
import { useIntersectionObserver } from '@customer-frontend/services';
import { FinishedReadingsModal } from './finished-readings-modal';
import { FormattedMessage } from 'react-intl';

type ContentDetailProps = {
  reading: EducationReading;
  headerClass: string;
  programRoute?: string;
  programId?: string;
  collectionRoute?: string;
  collectionId?: string;
  nextReadingId?: string;
  nextReadingLoading: boolean;
  onReadingComplete?: () => void;
};

export const ContentDetail: React.FC<ContentDetailProps> = ({
  headerClass,
  reading,
  programRoute,
  programId,
  collectionRoute,
  collectionId,
  nextReadingId,
  nextReadingLoading,
  onReadingComplete,
}) => {
  const [contentRead, setContentRead] = useState(false);
  const contentEndRef = useRef<HTMLDivElement>(null);
  const contentEndEntry = useIntersectionObserver(contentEndRef, {
    rootMargin: '0px 0px 400px 0px', // entry observed when 400px above end of content marker
  });

  useEffect(() => {
    if (contentEndEntry?.isIntersecting && !contentRead && onReadingComplete) {
      onReadingComplete();
      setContentRead(true);
    }
  }, [contentEndEntry?.isIntersecting, contentRead, onReadingComplete]);

  const history = useHistory();
  const backToProgram = useCallback(() => {
    if (programRoute) {
      history.push(programRoute);
    }
  }, [history, programRoute]);
  const backToFaqs = useCallback(() => {
    if (collectionRoute) {
      history.push(collectionRoute);
    }
  }, [history, collectionRoute]);

  const [showModal, setShowModal] = useState(false);

  const handleNextReading = useCallback(() => {
    if (nextReadingId && nextReadingId !== reading.id) {
      history.push(`./${nextReadingId}${history.location.search}`);
    }

    setShowModal(true);
  }, [history, nextReadingId, reading.id]);

  return (
    <>
      <section className={headerClass}>
        <HeroSection reading={reading} />
      </section>
      <section className="max-w-screen-sm mx-auto px-4 py-10 sm:py-14">
        <RichText document={reading.content?.fields?.bodyRichText} />
        <div ref={contentEndRef} />
        {programId && programRoute && (
          <div className="pt-8 space-y-4">
            <Button
              isFullWidth
              level="primary"
              isDisabled={nextReadingLoading}
              onClick={handleNextReading}
            >
              <FormattedMessage defaultMessage="Next reading" />
            </Button>
            <Button isFullWidth level="secondary" onClick={backToProgram}>
              <FormattedMessage
                defaultMessage="Take a break"
                description="CTA to stop reading & return to home"
              />
            </Button>
            <FinishedReadingsModal
              showModal={showModal}
              setShowModal={setShowModal}
              backToProgram={backToProgram}
            />
          </div>
        )}

        {collectionId && collectionRoute && (
          <div className="pt-8">
            <Button isFullWidth level="secondary" onClick={backToFaqs}>
              <FormattedMessage defaultMessage="Back to help articles" />
            </Button>
          </div>
        )}
      </section>
    </>
  );
};
