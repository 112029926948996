import { useIntl } from 'react-intl';
import type { Maybe, ProblemType } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';

export const useFormatProblemType = (
  problemType?: Maybe<ProblemType>,
  shortHand?: boolean,
): string | undefined => {
  const { formatMessage } = useIntl();
  const shortHandTitles: Partial<Record<ProblemType, string>> = {
    ERECTILE: formatMessage({
      defaultMessage: 'ED',
      description: 'Acronym representing "Erectile dysfunction"',
    }),
    PREMATURE: formatMessage({
      defaultMessage: 'PE',
      description: 'Acronym representing "Premature ejaculation"',
    }),
  };

  if (!problemType) {
    return undefined;
  }

  if (shortHand && shortHandTitles[problemType]) {
    return shortHandTitles[problemType];
  }

  switch (problemType) {
    case 'ACNE':
      return formatMessage({ defaultMessage: 'Acne' });
    case 'ANTI_AGEING':
      return formatMessage({ defaultMessage: 'Anti-ageing' });
    case 'BREASTFEEDING':
      return formatMessage({ defaultMessage: 'Breastfeeding' });
    case 'COLD_SORES':
      return formatMessage({ defaultMessage: 'Cold sores' });
    case 'CONTRACEPTION':
      return formatMessage({ defaultMessage: 'Contraception' });
    case 'DEPRECATED_OTC':
      return formatMessage({ defaultMessage: 'Over the counter' });
    case 'ERECTILE':
      return formatMessage({ defaultMessage: 'Erectile dysfunction' });
    case 'FERTILITY':
      return formatMessage({ defaultMessage: 'Fertility' });
    case 'FERTILITY_HORMONE':
      return formatMessage({ defaultMessage: 'Fertility hormone' });
    case 'FERTILITY_NAVIGATOR':
      return formatMessage({ defaultMessage: 'Fertility navigator' });
    case 'FERTILITY_NAVIGATOR_PREGNANCY':
      return formatMessage({ defaultMessage: 'Fertility navigator pregnancy' });
    case 'GENETIC_TEST':
      return formatMessage({ defaultMessage: 'Genetic test' });
    case 'HAIR':
      return formatMessage({ defaultMessage: 'Hair loss' });
    case 'HERPES':
      return formatMessage({ defaultMessage: 'Herpes' });
    case 'INFANT_SLEEP':
      return formatMessage({ defaultMessage: 'Infant sleep' });
    case 'MENOPAUSE':
      return formatMessage({ defaultMessage: 'Menopause' });
    case 'MENTAL_HEALTH_GOOD':
    case 'MENTAL_HEALTH_MEH':
    case 'MENTAL_HEALTH_SAD':
      return formatMessage({ defaultMessage: 'Mental health' });
    case 'POSTPARTUM':
      return formatMessage({ defaultMessage: 'Postpartum' });
    case 'POSTPARTUM_MENTAL_HEALTH':
      return formatMessage({ defaultMessage: 'Postpartum mental health' });
    case 'POSTPARTUM_NUTRITION':
      return formatMessage({ defaultMessage: 'Postpartum nutrition' });
    case 'POSTPARTUM_PHYSIO':
      return formatMessage({ defaultMessage: 'Postpartum physio' });
    case 'PRECONCEPTION_FERTILITY':
      return formatMessage({ defaultMessage: 'Preconception fertility' });
    case 'PRECONCEPTION_MENTAL_HEALTH':
      return formatMessage({ defaultMessage: 'Preconception mental health' });
    case 'PRECONCEPTION_NUTRITION':
      return formatMessage({ defaultMessage: 'Preconception nutrition' });
    case 'PRECONCEPTION_PHYSIO':
      return formatMessage({ defaultMessage: 'Preconception physio' });
    case 'PREGNANCY':
      return formatMessage({ defaultMessage: 'Pregnancy' });
    case 'PREGNANCY_MENTAL_HEALTH':
      return formatMessage({ defaultMessage: 'Pregnancy mental health' });
    case 'PREGNANCY_NUTRITION':
      return formatMessage({ defaultMessage: 'Pregnancy nutrition' });
    case 'PREGNANCY_PHYSIO':
      return formatMessage({ defaultMessage: 'Pregnancy physio' });
    case 'PREMATURE':
      return formatMessage({ defaultMessage: 'Premature ejaculation' });
    case 'SKIN_GENERAL':
      return formatMessage({ defaultMessage: 'Skin' });
    case 'SLEEP':
      return formatMessage({ defaultMessage: 'Sleep' });
    case 'STI':
      return formatMessage({ defaultMessage: 'STI test' });
    case 'UTI':
      return formatMessage({ defaultMessage: 'UTI' });
    case 'WEIGHT_LOSS':
      return formatMessage({ defaultMessage: 'Weight loss' });
    case 'WOMENS_HEALTH':
      return formatMessage({ defaultMessage: 'Women’s health' });
    default:
      return undefined;
  }
};

export const useFormatInlineProblemType = (
  problemType?: Maybe<ProblemType>,
): string | undefined => {
  const { locale } = getConfig();
  const formattedProblemType = useFormatProblemType(problemType);
  return formattedProblemType?.toLocaleLowerCase(locale);
};
