import { getAppEnv } from '@customer-frontend/types';
import { ensureEnv, Environment } from '@customer-frontend/environment';
import { getConfig } from '@customer-frontend/config';

const appEnv = getAppEnv(ensureEnv(`REACT_APP_APPLICATION_ENVIRONMENT`));

const config = getConfig();

export const environment: Environment = {
  appEnv,
  datadogToken: 'pub9d124d4d6cd237c7b21f5b61f8bb9d43',
  datadogRumApplicationId: ensureEnv('REACT_APP_DATADOG_RUM_APPLICATION_ID'),
  datadogRumToken: ensureEnv('REACT_APP_DATADOG_RUM_TOKEN'),
  apiUrl: ensureEnv(`REACT_APP_API_URL`),
  launchDarklyClientID: ensureEnv(`REACT_APP_LAUNCH_DARKLY_CLIENT_ID`),
  googlePlacesApiKey: ensureEnv(`REACT_APP_GOOGLE_PLACES_API_KEY`),
  landingPageUrl: ensureEnv(`REACT_APP_LANDING_PAGE_URL`),
  segmentKey: ensureEnv(`REACT_APP_SEGMENT_PUBLIC_KEY`),
  stripe: ensureEnv(`REACT_APP_STRIPE_PUBLIC_KEY`),
  isProd: appEnv === 'production',
  educationProgramId: ensureEnv('REACT_APP_EDUCATION_PROGRAM_ID'),
  zipMerchantId: config.enableZip
    ? ensureEnv('REACT_APP_ZIP_MERCHANT_ID')
    : undefined,
  batchedOzempicProductId: config.batchedOzempicEnabled
    ? ensureEnv('REACT_APP_BATCHED_OZEMPIC_PRODUCT_ID')
    : undefined,
  streamAppKey: process.env.REACT_APP_STREAM_APP_KEY,
};
