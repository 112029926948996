import { MaskType } from '../types';
import { IMask } from 'react-imask';

export const getMaskConfigFromType = (
  maskType: MaskType,
): IMask.AnyMaskedOptions => {
  switch (maskType) {
    case 'date':
      return {
        mask: Date,
        pattern: 'D`/M`/Y',
        overwrite: false,
        lazy: false,
        blocks: {
          Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
          },
          M: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          D: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
          },
        },
        format: function (date) {
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          const strDay = day < 10 ? `0${day}` : `${day}`;
          const strMonth = month < 10 ? `0${month}` : `${month}`;

          return [strDay, strMonth, year].join('/');
        },
        parse: function (str) {
          const dayMonthYear = str.split('/').map((v) => parseInt(v, 10));
          return new Date(
            dayMonthYear[2],
            dayMonthYear[1] - 1,
            dayMonthYear[0],
          );
        },
      };
    case 'expiry':
      return {
        mask: 'M`/Y',
        overwrite: false,
        lazy: false,
        blocks: {
          Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
          },
          M: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
        },
      };
    default:
      throw new Error('Unknown input mask type');
  }
};
