import { Config } from '../type';
import { defaultConfig } from './default';

export const KinConfig: Config = {
  ...defaultConfig,
  analyticsBrand: 'kin',
  brand: 'kin',
  loginUrl: '/login',
  title: 'Kin',
  stripeApi: 'CHARGE',
  country: 'Australia',
  countryCode: 'AU',
  locale: 'en-AU',
  currency: 'AUD',
  supportEmail: 'hello@kinfertility.com.au',
  helpCentreUrl: 'https://help.kinfertility.com.au/hc/en-us',
  zendeskRequestUrl: 'https://help.kinfertility.com.au/hc/en-us/requests/new',
  problemTypeZendeskParams: {
    BREASTFEEDING: { ticket_form_id: '15566433439257' },
    CONTRACEPTION: { ticket_form_id: '15566430915353' },
    FERTILITY: { ticket_form_id: '15566436543897' },
    FERTILITY_HORMONE: { ticket_form_id: '15566436543897' },
    FERTILITY_NAVIGATOR: { ticket_form_id: '15566436543897' },
    FERTILITY_NAVIGATOR_PREGNANCY: { ticket_form_id: '15566436543897' },
    INFANT_SLEEP: { ticket_form_id: '15566393965593' },
    POSTPARTUM: { ticket_form_id: '15566433439257' },
    POSTPARTUM_MENTAL_HEALTH: { ticket_form_id: '15566433439257' },
    POSTPARTUM_NUTRITION: { ticket_form_id: '15566433439257' },
    POSTPARTUM_PHYSIO: { ticket_form_id: '15566433439257' },
    PRECONCEPTION_FERTILITY: { ticket_form_id: '15566433439257' },
    PRECONCEPTION_MENTAL_HEALTH: { ticket_form_id: '15566433439257' },
    PRECONCEPTION_NUTRITION: { ticket_form_id: '15566433439257' },
    PRECONCEPTION_PHYSIO: { ticket_form_id: '15566433439257' },
    PREGNANCY: { ticket_form_id: '15566393965593' },
    PREGNANCY_MENTAL_HEALTH: { ticket_form_id: '15566393965593' },
    PREGNANCY_NUTRITION: { ticket_form_id: '15566393965593' },
    PREGNANCY_PHYSIO: { ticket_form_id: '15566393965593' },
    UTI: { ticket_form_id: '15566393965593' },
    WOMENS_HEALTH: { ticket_form_id: '15566393965593' },
  },
  contactUsUrl: 'https://kinfertility.com.au/contact',
  otcSubscriptions: true,
  enableMedicare: true,
  urlPaths: {
    ...defaultConfig.urlPaths,
    privacyPolicy: '/privacy',
  },
};
