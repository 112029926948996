import {
  Maybe,
  QuizApplicationResponse,
} from '@customer-frontend/graphql-types';

export function getResponseFromShortCode(
  shortcode: string,
  answers: Maybe<QuizApplicationResponse[]>,
): QuizApplicationResponse | undefined {
  return answers?.find(
    (answer) =>
      answer.question?.shortcode?.toUpperCase() === shortcode.toUpperCase(),
  );
}
