import { z } from 'zod';
import { uiStorages } from '@customer-frontend/ui-storage';
import { useLocation } from 'react-router-dom';
import { type Logger } from '@customer-frontend/logger';

const preselectionSchema = z.object({
  offeringId: z.string(),
  sequenceSelections: z.array(
    z.object({
      sequenceSetId: z.string(),
      sequenceId: z.string(),
    }),
  ),
});

export function usePreselectedOfferingSelection(
  logger: Logger,
): z.infer<typeof preselectionSchema> | undefined {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offeringSelectionsQueryParam = queryParams.get('offeringSelections');
  if (offeringSelectionsQueryParam?.length) {
    let queryParamObject: unknown | undefined;
    try {
      queryParamObject = JSON.parse(offeringSelectionsQueryParam);
    } catch {
      logger.error('offering selection in URL params badly formatted', {
        offeringSelections: offeringSelectionsQueryParam,
      });
    }
    if (queryParamObject !== undefined) {
      const queryParamParseResult =
        preselectionSchema.safeParse(queryParamObject);
      if (queryParamParseResult.success) {
        return queryParamParseResult.data;
      } else {
        logger.error('failed to parse offering selection from URL params', {
          error: queryParamParseResult.error,
        });
      }
    }
  }

  const preselectedOfferingSelectionString =
    uiStorages.local.getValue('offeringSelection');

  if (preselectedOfferingSelectionString?.length) {
    const parsed = preselectionSchema.safeParse(
      JSON.parse(preselectedOfferingSelectionString),
    );

    if (parsed.success) {
      return parsed.data;
    } else {
      logger.error('failed to parse offering selection from local storage', {
        error: parsed.error,
      });
    }
  }

  return undefined;
}

export function clearPreselectedOfferingSelection(): void {
  uiStorages.local.clearValue('offeringSelection');
}
