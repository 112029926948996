import { heading, text } from './typography';
import { button } from './button';
import { ImageUpload } from './image-upload';
import {
  getAvatarComponent,
  getFormComponents,
  getInputComponents,
  getSpinnerComponent,
  getSelectStyles,
  getWithFallbacks,
  getToastComponent,
  getOptionComponents,
  getModalStyles,
  getCardStyles,
  getDividerStyles,
  getAccordionStyle,
  getDrawerStyles,
  getNavTabsComponent,
} from '../../shared';
import { colors } from '../palette';

export const components = {
  Text: text,
  ImageUpload,
  Button: button,
  Heading: heading,
  Accordion: getAccordionStyle({
    colors: {
      icon: colors.primary[400],
      text: colors.primary[500],
      border: colors.primary[200],
      focusColor: colors.primary[400],
    },
  }),
  Modal: getModalStyles({
    colors: {
      closeButtonColor: colors.primary[500],
      text: colors.primary[500],
    },
  }),
  Card: getCardStyles({
    colors: {
      font: colors.primary[500],
      backgroundAlternate: colors.primary[100],
      backgroundTertiary: colors.secondary.cream[100],
    },
    border: {
      style: '1px solid',
      borderRadius: '4px',
      borderColor: colors.secondary.brown[100],
      borderColorTertiary: colors.secondary.cream[200],
    },
  }),
  Divider: getDividerStyles({ color: colors.secondary.brown[100] }),
  Drawer: getDrawerStyles({}),
  ...getFormComponents({
    colors: {
      container: colors.primary[500],
    },
  }),
  ...getInputComponents({
    colors: {
      bg: colors.secondary.cream[200],
      border: colors.secondary.brown[100],
      borderFocused: colors.primary[500],
      shadow: colors.primary[200],
    },
    font: getWithFallbacks('Atlas Grotesk'),
  }),
  ...getAvatarComponent({
    font: getWithFallbacks('Atlas Grotesk'),
    color: {
      default: colors.secondary.brown[100],
      alternate: colors.primary[500],
    },
    bgColor: {
      default: colors.primary[500],
      alternate: colors.primary[200],
    },
  }),
  ...getOptionComponents({
    colors: {
      textColor: colors.primary[500],
      border: {
        default: colors.primary[300],
        focus: colors.primary[500],
        active: colors.primary[400],
      },
      background: colors.primary[100],
      focusColor: colors.primary[200],
      iconBackground: {
        checked: colors.primary[500],
        hover: colors.primary[100],
      },
      iconBorder: {
        default: colors.primary[300],
        focus: colors.primary[500],
        active: colors.primary[500],
      },
    },
  }),
  ...getSpinnerComponent({ color: colors.primary[500] }),
  Select: getSelectStyles({
    colors: {
      focus: colors.primary[200],
      border: colors.primary[500],
      icon: colors.primary[500],
    },
    borderRadius: '4px',
  }),
  ...getToastComponent({ borderRadius: '0.25rem' }),
  ...getNavTabsComponent({
    baseStyle: {
      fontSize: '0.875rem',
      fontWeight: '500',
    },
    borderAround: {
      borderColor: colors.primary[500],
      borderRadius: '0.25rem',
      color: colors.primary[500],
      _activeLink: {
        background: colors.primary[500],
        color: colors.neutral.white,
      },
    },
    borderBottom: {
      borderColor: colors.primary[200],
      color: colors.primary[200],
      _activeLink: {
        borderColor: colors.primary[500],
        color: colors.primary[500],
      },
    },
  }),
};
