import { FC } from 'react';
import clsx from 'clsx';
import { Typography } from '@eucalyptusvc/design-system';
import { useTrackerTheme } from '../provider';

export type EmptyChartStateVariant = 'simplified' | 'enhanced';

type EmptyChartStateProps = {
  title: React.ReactNode;
  body: React.ReactNode;
  variant?: EmptyChartStateVariant;
};

export const EmptyChartState: FC<EmptyChartStateProps> = ({
  title,
  body,
  variant = 'enhanced',
}) => {
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.trackerChartEmptyState;

  return (
    <div className="relative flex flex-col justify-center items-center">
      <img
        src={trackerTheme?.backgroundImage[variant]}
        className="w-full"
        alt=""
      />
      <div className="absolute px-3 mx-auto max-w-full w-96">
        <div
          className={clsx(
            'mx-auto p-4 rounded-lg text-center space-y-1',
            trackerTheme?.containerClassName,
          )}
        >
          <Typography size="paragraph" isBold>
            {title}
          </Typography>
          <Typography size="small-text" element="p">
            {body}
          </Typography>
        </div>
      </div>
    </div>
  );
};
