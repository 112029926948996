import { forwardRef, useState, useCallback, useEffect } from 'react';
import { Radio } from '@chakra-ui/react';
import { BaseRadioButtonProps } from './types';
import { getVariant } from './utils';
import { useResponsive } from '../../hooks';
import { Typography } from '../typography';

const BaseRadioButton = (
  {
    value,
    name,
    label,
    isSelected = false,
    disabled,
    showBox = false,
    error = false,
    onChange,
    palette = 'default',
  }: BaseRadioButtonProps,
  ref: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  const { isMobile } = useResponsive();
  const [isRadioSelected, setIsRadioSelected] = useState<boolean>(isSelected);
  useEffect(() => {
    setIsRadioSelected(isSelected);
  }, [isSelected]);

  const handleRadioToggle = useCallback(
    (e: React.SyntheticEvent): void => {
      setIsRadioSelected(!isRadioSelected);
      if (onChange) {
        onChange(e, value);
      }
    },
    [onChange, isRadioSelected, value],
  );

  const boxPadding = isMobile
    ? '1.25rem' // 20px Mobile
    : '1rem'; // 16px Desktop
  const padding = showBox ? boxPadding : 0;

  const variant = getVariant(error, isRadioSelected, showBox);

  return (
    <Radio
      name={name}
      value={value}
      ref={ref}
      isDisabled={disabled}
      isChecked={isRadioSelected}
      onChange={handleRadioToggle}
      variant={variant}
      colorScheme={palette}
      p={padding}
      spacing="0.5rem"
      size="lg"
    >
      <Typography size="medium-paragraph">{label}</Typography>
    </Radio>
  );
};

/** For the majority of cases it would be best to use **RadioButtonGroup**
 * instead. In the rare event that you ever need a single **RadioButton**,
 * this is a controlled component; pass `name` and `isSelected` true/false.
 *
 * To group related RadioButton, give each the same `name` and a unique
 * `value`. But the **RadioButtonGroup** component is designed to handle
 * this common use-case. */
export const RadioButton = forwardRef<HTMLInputElement, BaseRadioButtonProps>(
  BaseRadioButton,
);
