import { gql, useQuery } from '@apollo/client';
import {
  FollowUpOrReviewPurchaseReviewQuery,
  FollowUpOrReviewPurchaseReviewQueryVariables,
  OfferingSelectionInput,
  SequenceSelectionInput,
} from '@customer-frontend/graphql-types';
import { OrderTimeline } from '@customer-frontend/treatment';
import {
  Accordion,
  AccordionPalette,
  Button,
  ButtonPalette,
  Card,
  CardPalette,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { Logger } from '@customer-frontend/logger';
import { PractitionerFollowUpCard } from './practitioner-follow-up-card';
import { TitrationExplainerModalButton } from './titration-explainer-modal-button';
import {
  ConfirmReviewedInformationForm,
  ReviewedInformationFormFields,
} from './confirm-reviewed-information-form';
import { DoctorInfo, PrescriberType } from '@customer-frontend/doctor';
import { getConfig } from '@customer-frontend/config';
import { useForm } from 'react-hook-form';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { FaqMarkdownItem, FaqPanel } from '@customer-frontend/consultation';
import { ReactComponent as Syringe } from '../assets/syringe.svg';
import { ReactComponent as InfoCircle } from '../assets/info-circle.svg';
import { ReactComponent as Tablet } from '../assets/tablet.svg';
import { getZendeskRequestUrl, useTitle } from '@customer-frontend/utils';
import { InclusionsList } from './inclusions-list';
import { useBackButtonBehaviour } from '@customer-frontend/services';

type FollowUpOrReviewPurchaseReviewProps = {
  consultationId: string;
  logger: Logger;
  routes: {
    profile: string;
    doctorsNote: string;
    pharmacyInformation: string;
    confirmFollowUpAndReview: string;
  };
  palette?: {
    card?: CardPalette;
    nestedCard?: CardPalette;
    confirmButton?: ButtonPalette;
    accordion?: AccordionPalette;
  };
};

export const FollowUpOrReviewPurchaseReview = ({
  consultationId,
  logger,
  routes,
  palette,
}: FollowUpOrReviewPurchaseReviewProps): JSX.Element | null => {
  const config = getConfig();
  const history = useHistory();
  const { formatMessage, formatDate } = useIntl();
  useBackButtonBehaviour(() => history.push(routes.profile));

  useTitle(
    formatMessage({
      defaultMessage: 'Review your treatment plan',
      description: 'Page title for the Follow-up/Review purchase review page',
    }),
  );

  const [selections, setSelections] = useState<OfferingSelectionInput[]>();
  const reviewedInformationForm = useForm<ReviewedInformationFormFields>({
    shouldUnregister: false,
  });

  const query = useQuery<
    FollowUpOrReviewPurchaseReviewQuery,
    FollowUpOrReviewPurchaseReviewQueryVariables
  >(
    gql`
      ${DoctorInfo.fragment}
      query FollowUpOrReviewPurchaseReview(
        $consultationId: String!
        $offeringSelections: [OfferingSelectionInput!]!
        $noSelections: Boolean!
      ) {
        profile {
          id
          shortAddressableName
        }
        consultation(id: $consultationId) {
          id
          type
          patientNotes
          doctor {
            fullClinicianName
            ...DoctorInfoComponent
          }
          purchasePrompt {
            id
            ... on FurPurchasePrompt {
              proposedOfferingSelection {
                id
                offering {
                  id
                  friendlyName
                  photoUrl
                  advertisedShippingCadence
                }
                sequenceSelections {
                  id
                  sequence {
                    id
                    faqs {
                      id
                      question
                      answerMarkdown
                      rank
                    }
                    externalLinks {
                      id
                      text
                      url
                    }
                  }
                  sequenceSet {
                    id
                  }
                }
              }
            }
          }
        }
        offeringsTimeline(offerings: $offeringSelections)
          @skip(if: $noSelections) {
          id
          date
          totalAmount
          products {
            id
            friendlyName
            erxMedicines {
              id
              itemForm
              itemStrength
            }
          }
        }
      }
    `,
    {
      errorPolicy: 'all',
      variables: {
        consultationId,
        noSelections: !selections,
        offeringSelections: selections ?? [],
      },
    },
  );

  const profile = query.data?.profile;
  const timeline = query.data?.offeringsTimeline ?? [];
  const consultation = query.data?.consultation;

  useEffect(() => {
    const purchasePrompt = consultation?.purchasePrompt;
    setSelections((s) => {
      if (s) {
        // We don't expect this to change after the page has already loaded.
        return s;
      }

      if (purchasePrompt?.__typename !== 'FurPurchasePrompt') {
        return s;
      }

      const selection = purchasePrompt.proposedOfferingSelection;
      if (!selection?.offering) {
        return s;
      }

      if (!selection.sequenceSelections) {
        return s;
      }

      const sequenceSelections: SequenceSelectionInput[] = [];
      for (const ss of selection.sequenceSelections) {
        if (ss.sequence?.id && ss.sequenceSet?.id) {
          sequenceSelections.push({
            sequenceId: ss.sequence.id,
            sequenceSetId: ss.sequenceSet.id,
          });
        }
      }

      return [{ offeringId: selection.offering.id, sequenceSelections }];
    });
  }, [consultation]);

  if (query.loading && !query.data?.offeringsTimeline) {
    return (
      <div className="flex pt-6 flex-col items-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!consultation?.purchasePrompt) {
    logger.error(
      `no purchase prompt for consultation id "${consultationId}", redirecting to profile`,
    );
    return <Redirect to={routes.profile} />;
  }

  const purchasePrompt = consultation.purchasePrompt;

  if (purchasePrompt.__typename !== 'FurPurchasePrompt') {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" type was expected to be FollowUpOrReviewPurchasePrompt but is "${purchasePrompt.__typename}"`,
    );
    return <Redirect to={routes.profile} />;
  }

  const offeringSelection = purchasePrompt.proposedOfferingSelection;
  if (!offeringSelection) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering selection`,
    );
    return <Redirect to={routes.profile} />;
  }

  const offering = offeringSelection?.offering;
  if (!offering) {
    logger.error(
      `purchase prompt with id "${purchasePrompt.id}" has no offering`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (!consultation.doctor) {
    logger.error(
      `consultation with id "${consultation.id}" does not have a doctor`,
    );
    return <Redirect to={routes.profile} />;
  }

  if (!consultation.patientNotes) {
    logger.error(
      `consultation with id "${consultation.id}" does not have patient notes`,
    );
    return <Redirect to={routes.profile} />;
  }

  const zendeskRequestUrl = getZendeskRequestUrl({
    problemType: consultation.type,
  });

  const faqData =
    offeringSelection.sequenceSelections?.flatMap(
      (ss) => ss.sequence?.faqs ?? [],
    ) ?? [];

  const faqs: FaqMarkdownItem[] =
    faqData.map((faq) => {
      return {
        title: faq?.question,
        markdown: faq?.answerMarkdown,
        rank: faq?.rank,
      };
    }) ?? [];

  const externalLinks =
    offeringSelection.sequenceSelections?.flatMap(
      (ss) => ss.sequence?.externalLinks ?? [],
    ) || [];

  const allNames = new Set<string>();
  const erxMedicineStrengths = new Set<string>();

  const orders: Parameters<typeof OrderTimeline>[0]['orders'] = timeline.map(
    (tli, idx) => {
      const price = formatCentsToCurrency(tli.totalAmount);
      let secondaryLabel;
      let primaryLabelAdornment = <Syringe className="h-4 w-4" />;
      if (idx === 0) {
        secondaryLabel = formatMessage(
          {
            defaultMessage: 'Processes on {date}',
            description:
              'Follow-up or review purchase page order shipping date',
          },
          {
            date: formatDate(tli.date, {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            }),
          },
        );
      }

      const names = new Set<string>();
      tli.products?.forEach((p) => {
        if (p.friendlyName) {
          allNames.add(p.friendlyName);
          names.add(p.friendlyName);
        }
        p.erxMedicines.forEach((m) => {
          const itemForm = m.itemForm?.toLowerCase();
          const itemStrength = m.itemStrength?.trim();

          if (itemForm?.includes('tablet')) {
            primaryLabelAdornment = <Tablet className="h-4 w-4" />;
          } else if (itemForm?.includes('injectable')) {
            primaryLabelAdornment = <Syringe className="h-4 w-4" />;
          }

          if (itemStrength) {
            erxMedicineStrengths.add(itemStrength);
          }
        });
      });

      return {
        id: tli.id,
        stage: 'circleStatic',
        price,
        primaryLabel: Array.from(names).join(', '),
        primaryLabelAdornment,
        secondaryLabel,
      };
    },
  );

  return (
    <section className="max-w-screen-md px-4 pt-10 md:pt-14 pb-10 md:pb-24 mx-auto flex flex-col items-center gap-8">
      <div className="self-start flex flex-col gap-4">
        <Typography isBold size="lg">
          <FormattedMessage
            defaultMessage="Review and confirm your treatment plan, {addressableName}"
            description="Follow-up or review purchase page title"
            values={{ addressableName: profile?.shortAddressableName }}
          />
        </Typography>
      </div>
      <div className="bg-status-info-100 p-4 flex gap-2 rounded border border-status-info-500 text-primary-500">
        <InfoCircle className="fill-current h-4 w-4 mt-0.5" />
        <div className="flex-1">
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Based on your consult, your {isPrescriber, select, true {prescriber} other {practitioner}} has updated your treatment plan. Please review and confirm to continue."
              description="Follow-up or review purchase page explainer"
              values={{
                isPrescriber:
                  config.prescriberType === PrescriberType.PRESCRIBER,
              }}
            />
          </Typography>
        </div>
      </div>

      <div className="self-start flex flex-col gap-4">
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Before confirming your order, please review your treatment plan, the letter from {doctorName} and medicine safety information from your dispensing clinic. If you have any questions, please contact our Medical Support team: <a>{medicalSupportEmail}</a>{br}{br}<i>Note: you will not receive your treatment until you confirm your order.</i>"
            description="Confirm purchase page description"
            values={{
              doctorName: consultation.doctor.shortClinicianName,
              medicalSupportEmail: config.medicalEmail,
              i: (chunks) => <i>{chunks}</i>,
              a: (chunks) => (
                <a className="text-link" href={`mailto:${config.medicalEmail}`}>
                  {chunks}
                </a>
              ),
              br: <br />,
            }}
          />
        </Typography>
      </div>

      <div className="w-full">
        <Card palette={palette?.card}>
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Your treatment plan"
              description="Follow-up or review purchase page hero card title"
            />
          </Typography>
          <Divider variant="separator" />
          <div className="flex gap-4 mb-5">
            <img src={offering.photoUrl} className="h-16 rounded" alt="" />
            <div className="space-y-2">
              <Typography isBold size="sm">
                {offering.friendlyName}
              </Typography>
              <Typography size="medium-paragraph">
                {Array.from(allNames).join(', ')}
              </Typography>
              <Typography isBold size="medium-paragraph">
                {offering.advertisedShippingCadence}
              </Typography>
            </div>
          </div>
          <Accordion
            trimBottom
            title={
              <Typography isBold size="medium-paragraph">
                <FormattedMessage
                  defaultMessage="Treatment plan schedule"
                  description="Substitute purchase page timeline title"
                />
              </Typography>
            }
            content={
              <>
                {erxMedicineStrengths.size > 1 && (
                  <div className="mb-4">
                    <TitrationExplainerModalButton />
                  </div>
                )}
                <OrderTimeline orders={orders} truncate={6} />
                <div className="p-2">
                  <PractitionerFollowUpCard cardPalette={palette?.nestedCard} />
                </div>
              </>
            }
          />
          <Divider variant="separator" mt="sm" />
          <div className="mb-6">
            <InclusionsList />
          </div>
          <div className="space-y-2 mb-4">
            <Button
              level="secondary"
              onClick={() => history.push(routes.doctorsNote)}
              isFullWidth
            >
              <FormattedMessage
                defaultMessage="View letter from {doctorName}"
                description="Button text to go to the doctor letter page"
                values={{ doctorName: consultation.doctor.shortClinicianName }}
              />
            </Button>
            <Button
              level="secondary"
              onClick={() => history.push(routes.pharmacyInformation)}
              isFullWidth
            >
              <FormattedMessage
                defaultMessage="View pharmacy safety information"
                description="Button text to go to the offerings safety information page"
              />
            </Button>
          </div>
          {externalLinks.length > 0 && (
            <div className="flex flex-col items-center gap-2 mb-4">
              {externalLinks.map((link) => (
                <a
                  key={link.id}
                  href={link.url}
                  className="text-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography size="medium-paragraph">{link.text}</Typography>
                </a>
              ))}
            </div>
          )}
          <ConfirmReviewedInformationForm form={reviewedInformationForm} />
        </Card>
      </div>
      <Button
        palette={palette?.confirmButton}
        isFullWidth
        onClick={async () => {
          const hasTickedConfirmation = await reviewedInformationForm.trigger();
          if (!hasTickedConfirmation) {
            return;
          }
          history.push(routes.confirmFollowUpAndReview);
        }}
      >
        <FormattedMessage
          defaultMessage="Continue to confirmation"
          description="Follow-up or review purchase page continue button copy"
        />
      </Button>

      <div className="w-full">
        <div className="pb-6">
          <Typography isBold size="lg">
            <FormattedMessage
              defaultMessage="FAQs"
              description="Consultation Payment FAQ title"
            />
          </Typography>
        </div>
        <FaqPanel faqs={faqs} accordionPalette={palette?.accordion} />
      </div>
      {config.purchasePrompts.showZendeskSupportCard && (
        <div className="w-full">
          <Card palette="tertiary">
            <div className="flex flex-col space-y-6">
              <div className="flex items-center space-x-4">
                {config.localisedAssets?.zendeskMessageUsLogo && (
                  <img src={config.localisedAssets?.zendeskMessageUsLogo} />
                )}
                <Typography isBold size="md">
                  <FormattedMessage
                    defaultMessage="Still have questions?"
                    description="Follow-up or review purchase page zendesk button copy"
                  />
                </Typography>
              </div>

              <Button
                onClick={() => {
                  window.open(zendeskRequestUrl, '_blank');
                }}
                isFullWidth
                palette="alternate"
              >
                <FormattedMessage defaultMessage="Message us" />
              </Button>
            </div>
          </Card>
        </div>
      )}
    </section>
  );
};
