import { MessageDescriptor, defineMessage } from 'react-intl';
import { ReactComponent as JuniperBrain } from '../assets/juniper-doctors-note-organ-brain.svg';
import { ReactComponent as JuniperLiver } from '../assets/juniper-doctors-note-organ-liver.svg';
import { ReactComponent as JuniperStomach } from '../assets/juniper-doctors-note-organ-stomach.svg';
import { ReactComponent as JuniperPancreas } from '../assets/juniper-doctors-note-organ-pancreas.svg';
import { ReactComponent as PilotBrain } from '../assets/pilot-doctors-note-organ-brain.svg';
import { ReactComponent as PilotLiver } from '../assets/pilot-doctors-note-organ-liver.svg';
import { ReactComponent as PilotStomach } from '../assets/pilot-doctors-note-organ-stomach.svg';
import { ReactComponent as PilotPancreas } from '../assets/pilot-doctors-note-organ-pancreas.svg';
import { Brand } from '@customer-frontend/types';
import { getConfig } from '@customer-frontend/config';

export enum Organ {
  BRAIN = 'Brain',
  LIVER = 'Liver',
  STOMACH = 'Stomach',
  PANCREAS = 'Pancreas',
}

export const OrganImage: React.FC<{
  organ: Organ;
  isLarge?: boolean;
}> = ({ organ, isLarge }) => {
  const sizeClass = isLarge ? 'h-14 w-14' : 'h-10 w-10';
  const images: {
    [key in Brand]?: {
      brain: React.ReactElement;
      liver: React.ReactElement;
      stomach: React.ReactElement;
      pancreas: React.ReactElement;
    };
  } = {
    pilot: {
      brain: <PilotBrain className={sizeClass} />,
      liver: <PilotLiver className={sizeClass} />,
      stomach: <PilotStomach className={sizeClass} />,
      pancreas: <PilotPancreas className={sizeClass} />,
    },
    juniper: {
      brain: <JuniperBrain className={sizeClass} />,
      liver: <JuniperLiver className={sizeClass} />,
      stomach: <JuniperStomach className={sizeClass} />,
      pancreas: <JuniperPancreas className={sizeClass} />,
    },
  };

  const config = getConfig();
  const brain = images[config.brand]?.brain;
  const liver = images[config.brand]?.liver;
  const pancreas = images[config.brand]?.pancreas;
  const stomach = images[config.brand]?.stomach;
  switch (organ) {
    case Organ.BRAIN:
      return <>{brain}</>;
    case Organ.LIVER:
      return <>{liver}</>;
    case Organ.PANCREAS:
      return <>{pancreas}</>;
    default:
      return <>{stomach}</>;
  }
};

export const organCardData: {
  organ: Organ;
  image: JSX.Element;
  organText: MessageDescriptor;
  shortDescription: MessageDescriptor;
  longDescription: MessageDescriptor;
}[] = [
  {
    organ: Organ.BRAIN,
    image: <OrganImage organ={Organ.BRAIN} />,
    organText: defineMessage({ defaultMessage: 'Brain' }),
    shortDescription: defineMessage({ defaultMessage: 'Reduces hunger cues' }),
    longDescription: defineMessage({
      defaultMessage:
        '{medicationName} signals to the brain to reduce appetite and hunger messages through the changes to the hormones insulin and glucagon.',
    }),
  },
  {
    organ: Organ.LIVER,
    image: <OrganImage organ={Organ.LIVER} />,
    organText: defineMessage({ defaultMessage: 'Liver' }),
    shortDescription: defineMessage({
      defaultMessage: 'Efficiently process fat',
    }),
    longDescription: defineMessage({
      defaultMessage:
        'Through the effects on the hormones insulin and glucagon, {medicationName} indirectly modifies the way the liver processes sugar and other nutrients like fats and protein. This ultimately leads to an increased usage of fats and a more efficient metabolism.',
    }),
  },
  {
    organ: Organ.STOMACH,
    image: <OrganImage organ={Organ.STOMACH} />,
    organText: defineMessage({ defaultMessage: 'Stomach' }),
    shortDescription: defineMessage({
      defaultMessage: 'Feel fuller for longer',
    }),
    longDescription: defineMessage({
      defaultMessage:
        '{medicationName} slows the emptying of the stomach, which makes you feel fuller for longer and also reduces the sudden rise in blood sugar after eating, which can impact your metabolism and weight.',
    }),
  },
  {
    organ: Organ.PANCREAS,
    image: <OrganImage organ={Organ.PANCREAS} />,
    organText: defineMessage({ defaultMessage: 'Pancreas' }),
    shortDescription: defineMessage({ defaultMessage: 'Reduce blood sugar' }),
    longDescription: defineMessage({
      defaultMessage:
        '{medicationName} increases the release of insulin from cells within the pancreas as well as regulating another hormone involved in blood sugar control called glucagon. Together, these changes lead to a reduction in blood sugar and improvement in how the body manages the nutrients that we eat.',
    }),
  },
];
