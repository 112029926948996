export enum PrescriberType {
  PRESCRIBER = 'prescriber',
  DOCTOR = 'doctor',
}

enum ExtraTitleTypes {
  JUST_FULL_NAME = 'JUST_FULL_NAME',
  DOCTOR_LAST_NAME = 'DOCTOR_LAST_NAME',
}

export const TitleType = Object.assign({}, ExtraTitleTypes, PrescriberType);
export type TitleType = ExtraTitleTypes | PrescriberType;
