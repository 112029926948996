import { sharedColors } from '../shared/palette';

// primary
const primary100 = '#FFFFFF';
const primary200 = '#E6E6E6';
const primary300 = '#CCCCCC';
const primary400 = '#737373';
const primary500 = '#404040';
const primary600 = '#000000';

// secondary
const beige100 = '#F8F7F3';
const beige200 = '#DDD0C0';
const beige300 = '#C7B49C';
const yellow100 = '#FFFCEC';
const yellow200 = '#FFF5B8';
const yellow300 = '#FFEF8D';

// tertiary (skin goals)
const blue = '#7CABDA';
const red = '#ED7575';
const mauve = '#C88099';
const green = '#7FC187';
const yellow = '#FFCB44';

export const colors = {
  ...sharedColors,
  primary: {
    100: primary100,
    200: primary200,
    300: primary300,
    400: primary400,
    500: primary500,
    600: primary600,
  },
  secondary: {
    biege: {
      100: beige100,
      200: beige200,
      300: beige300,
    },
    yellow: {
      100: yellow100,
      200: yellow200,
      300: yellow300,
    },
  },
  tertiary: {
    blue,
    red,
    mauve,
    green,
    yellow,
  },
  reserved: {},
};
