import { useMemo } from 'react';
import {
  Markdown,
  Typography,
  AccordionList,
  AccordionPalette,
  EmbeddedVideo,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

export type FaqItem = {
  title: string;
  content: React.ReactElement;
};

export type FaqMarkdownItem = {
  title?: string | null;
  markdown?: string | null;
  videoUrl?: string | null;
  rank?: number | null;
};

type FaqPanelProps = {
  faqs: FaqMarkdownItem[];
  staticFaqs?: FaqItem[];
  accordionPalette?: AccordionPalette;
};

export const FaqPanel = ({
  faqs,
  staticFaqs = [],
  accordionPalette = 'default',
}: FaqPanelProps): React.ReactElement => {
  const updatedFaqs = useMemo(
    () =>
      faqs
        .slice()
        .sort((a, b) => {
          if (typeof a.rank === 'number' && typeof b.rank === 'number') {
            return a.rank - b.rank;
          }
          return 0;
        })
        .reduce((faqs, current) => {
          if (current.title && current.markdown) {
            faqs.push({
              title: current.title,
              content: (
                <div className="space-y-2">
                  {current.videoUrl && <EmbeddedVideo url={current.videoUrl} />}
                  <Markdown src={current.markdown} formatWhitespace />
                </div>
              ),
            });
          }
          return faqs;
        }, [] as FaqItem[]),
    [faqs],
  );

  const combinedFaqs = [...updatedFaqs, ...staticFaqs];

  return combinedFaqs.length ? (
    <AccordionList
      palette={accordionPalette}
      items={combinedFaqs}
      allowMultipleOpen
    />
  ) : (
    <Typography size="medium-paragraph">
      <FormattedMessage
        defaultMessage="There are currently no FAQs for this treatment."
        description="Text for when the FAQs list is empty"
      />
    </Typography>
  );
};
