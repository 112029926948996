import React from 'react';
import { QuizApplication } from '@customer-frontend/graphql-types';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '@eucalyptusvc/design-system';
import { useQuizApplication } from '@customer-frontend/services';

export const CompleteQuiz = ({
  onQuizApplicationLoaded,
}: {
  onQuizApplicationLoaded: (quizApplication: QuizApplication) => void;
}): React.ReactElement => {
  const { quizApplicationId } = useParams<{ quizApplicationId: string }>();
  const { data: quizApplicationData } = useQuizApplication(quizApplicationId);
  const quizApplication = quizApplicationData?.getQuizApplication;

  React.useEffect(() => {
    if (!quizApplication) {
      return;
    }

    onQuizApplicationLoaded(quizApplication as unknown as QuizApplication);
  }, [quizApplication, onQuizApplicationLoaded]);

  return (
    <div className="flex justify-center p-5">
      <LoadingSpinner />
    </div>
  );
};
