import { gql, useQuery } from '@apollo/client';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import { IhiQuery, ProblemType } from '@customer-frontend/graphql-types';
import { isMedicareEnabled } from '../../utils';

export const IHI_HOOK_QUERY = gql`
  query IHI {
    profile {
      id
      residentialAddress {
        id
      }
      healthCareDetails {
        id
        hasMedicareDetails
        hasIndividualHealthcareIdentifier
      }
    }
  }
`;

export const useIHI = (
  problemType?: ProblemType,
): {
  canRouteToMedicare: boolean;
  canSkipMedicareForm: boolean;
  medicareDetailsCollected: boolean;
  ihiCollected: boolean;
  ihiDataLoading: boolean;
  hasResidentialAddress: boolean;
} => {
  const featureFlagClient = useFeatureFlagClient();
  const { data, loading } = useQuery<IhiQuery>(IHI_HOOK_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const ihiCollected =
    data?.profile?.healthCareDetails?.hasIndividualHealthcareIdentifier ??
    false;
  const medicareDetailsCollected =
    data?.profile?.healthCareDetails?.hasMedicareDetails ?? false;

  const skipDisabledTypes = featureFlagClient.getJson<ProblemType[]>(
    'DISABLE_SKIP_MEDICARE_FORM',
    { defaultValue: [] },
  );

  const isMedicareCollectionEnabled = isMedicareEnabled(
    featureFlagClient,
    problemType,
  );

  const canSkipMedicareForm =
    !problemType ||
    !skipDisabledTypes ||
    !skipDisabledTypes.includes(problemType);

  const canRouteToMedicare =
    !medicareDetailsCollected && !ihiCollected && isMedicareCollectionEnabled;

  const hasResidentialAddress = !!data?.profile?.residentialAddress;

  return {
    canSkipMedicareForm,
    canRouteToMedicare,
    medicareDetailsCollected,
    ihiCollected,
    hasResidentialAddress,
    ihiDataLoading: loading,
  };
};
