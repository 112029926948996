import type { ImageUploadValue } from '@eucalyptusvc/design-system';

export const parseImageUploadString = (
  value: string,
): ImageUploadValue | null => {
  try {
    return JSON.parse(value);
  } catch {
    return null;
  }
};
