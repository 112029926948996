import { getConfig } from '@customer-frontend/config';
import { WeightTreatmentCategory } from '@customer-frontend/graphql-types';
import { WISTIA_VIDEOS, WistiaVideo } from '@customer-frontend/video';
import { Card, Markdown, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Info } from '../assets/info.svg';

type OverviewContentProps = {
  markdown: string;
  weightTreatmentCategory?: WeightTreatmentCategory | null;
  handleVideoPlayed: (id: string, name: string) => void;
};

export const OverviewContent: React.FC<OverviewContentProps> = ({
  markdown,
  weightTreatmentCategory,
  handleVideoPlayed,
}) => {
  const config = getConfig();
  return (
    <div className="space-y-6 pt-6">
      {weightTreatmentCategory === 'ozempic' && (
        <Card palette="tertiary">
          <div className="flex items-center space-x-2 mb-2">
            <Info />
            <Typography size="medium-paragraph" isBold>
              <FormattedMessage defaultMessage="Important" />
            </Typography>
          </div>

          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Ozempic is administered <strong>once</strong> a week via a titrating dosing schedule."
              values={{ strong: (chunks) => <strong>{chunks}</strong> }}
            />
          </Typography>
        </Card>
      )}
      <div className="text-black">
        <Markdown src={markdown} formatWhitespace />
      </div>

      {config.brand === 'juniper' && weightTreatmentCategory === 'saxenda' && (
        <WistiaVideo
          videoId={WISTIA_VIDEOS.WEIGHT_DIABETES_RISK}
          videoClassName="rounded-b-none"
          onPlay={handleVideoPlayed}
        >
          <div className="rounded rounded-t-none bg-primary-500 p-4">
            <Typography size={'paragraph'} isBold color="white">
              <FormattedMessage defaultMessage="Weight and diabetes risk" />
            </Typography>
            <Typography size="paragraph" color="white">
              <FormattedMessage defaultMessage="With Dr Atkinson, Juniper GP" />
            </Typography>
          </div>
        </WistiaVideo>
      )}
    </div>
  );
};
