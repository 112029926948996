import { TextInput, Typography } from '@eucalyptusvc/design-system';
import { UseShippingFormFields } from '@customer-frontend/order';

import { useRequiredValidation } from '@customer-frontend/utils';
import clsx from 'clsx';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

interface PersonalDetailsFormProps {
  headerComponent?: React.ReactNode;
  register: UseFormMethods['register'];
  errors: DeepMap<UseShippingFormFields, FieldError>;
  palette?: 'default' | 'alternate';
}

const rowClasses = 'flex flex-col md:flex-row md:mb-3 mt-0 md:space-x-3';
const columnSubClasses = 'w-full mb-3 md:mb-0';

const messages = defineMessages({
  firstName: {
    defaultMessage: 'First name',
  },
  lastName: {
    defaultMessage: 'Last name',
  },
  phone: {
    defaultMessage: 'Phone',
  },
});

export const PersonalDetailsForm = ({
  errors,
  palette,
  register,
  headerComponent,
}: PersonalDetailsFormProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const firstNameValidation = useRequiredValidation(
    formatMessage(messages.firstName),
  );
  const lastNameValidation = useRequiredValidation(
    formatMessage(messages.lastName),
  );
  const phoneValidation = useRequiredValidation(formatMessage(messages.phone));

  return (
    <div>
      {headerComponent || (
        <Typography element="h3" size="md" isBold>
          <FormattedMessage defaultMessage="Personal details" description="" />
        </Typography>
      )}
      <div className={clsx(rowClasses, 'mt-3')}>
        <div className={clsx(columnSubClasses, 'md:w-1/2')}>
          <TextInput
            ref={register(firstNameValidation)}
            label={formatMessage(messages.firstName)}
            name="firstName"
            placeholder={formatMessage(messages.firstName)}
            errorMessage={errors?.firstName?.message}
            palette={palette}
          />
        </div>
        <div className={clsx(columnSubClasses, 'md:w-1/2')}>
          <TextInput
            ref={register(lastNameValidation)}
            label={formatMessage(messages.lastName)}
            name="lastName"
            placeholder={formatMessage(messages.lastName)}
            errorMessage={errors?.lastName?.message}
            palette={palette}
          />
        </div>
      </div>
      <div className={rowClasses}>
        <div className={clsx(columnSubClasses)}>
          <TextInput
            ref={register(phoneValidation)}
            label={formatMessage(messages.phone)}
            name="phone"
            placeholder={formatMessage(messages.phone)}
            errorMessage={errors?.phone?.message}
            palette={palette}
          />
        </div>
      </div>
    </div>
  );
};
