import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDelayTreatment } from '@customer-frontend/services';
import { useNotification } from '@eucalyptusvc/design-system';
import { DelayTrigger } from './types';

export const useDelayTreatmentProps = ({
  treatmentId,
  trigger,
}: {
  treatmentId: string;
  trigger: DelayTrigger;
}): {
  onDelay: (delayedDate: string) => void;
  loading: boolean;
} => {
  const notify = useNotification();
  const { formatMessage } = useIntl();

  const [delayTreatment, { loading }] = useDelayTreatment({
    onCompleted: () => {
      notify.success({
        message: formatMessage({
          defaultMessage: 'Your next shipment has been successfully delayed',
          description:
            'Success message when user successfully requests to delay their next shipment',
        }),
      });
    },
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage: 'Unable to delay treatment. Please try again later.',
          description:
            'Error text shown when their is an error delaying the shipment of their next treatment',
        }),
      });
    },
  });

  const onDelay = useCallback(
    (delayedDate: string) => {
      delayTreatment({
        variables: {
          trigger,
          id: treatmentId,
          newDate: delayedDate,
        },
      });
    },
    [treatmentId, trigger, delayTreatment],
  );

  return { onDelay, loading };
};
