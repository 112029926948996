import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import {
  MakeOptional,
  Maybe,
  ProblemType,
} from '@customer-frontend/graphql-types';
import debounce from 'lodash.debounce';
import React, { useCallback } from 'react';
import {
  DeepMap,
  FieldError,
  useForm,
  UseFormMethods,
  useWatch,
} from 'react-hook-form';
import { useDebounce, useEffectOnce } from 'react-use';
import {
  ShippingAddressValidationInput,
  useShippingAddressValidation,
} from '../use-shipping-address-validation';
import { UseShippingFormFields } from './types';
import { DELIVERY_OPTIONS } from './constants';

export type UseShippingFormProps = {
  firstName?: Maybe<string>;
  lastName?: Maybe<string>;
  phone?: Maybe<string>;
  enableAddressValidation?: boolean;
  shippingIsSameAsHomeByDefault?: boolean;
} & MakeOptional<
  UseShippingFormFields,
  'shippingAddress' | 'residentialAddress'
>;

export interface UseShippingFormReturn<T> {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  handleSubmit: UseFormMethods['handleSubmit'];
  errors: DeepMap<UseShippingFormFields & T, FieldError>;
  onHomeAddressChanged: (
    data: UseShippingFormFields['residentialAddress'],
  ) => void;
  handleOnUseHomeAddressChange: () => void;
  onShippingAddressChanged: (
    data: UseShippingFormFields['shippingAddress'],
  ) => void;
  isSameAsHome: boolean;
  validate: () => Promise<boolean>;
  validateAddress: () => Promise<boolean>;
  isValidatingShippingAddress: boolean;
  isShippingAddressWithinRange: boolean;
  isShippingAddressCompletedForPostcodeValidation: boolean;
  sendNoAlternativeAddressEvent: (
    userId: string,
    email: string,
    consultationId: string,
    problemType: ProblemType,
  ) => void;
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean;
          shouldDirty: boolean;
        }>
      | undefined,
  ) => void;
}

export const useShippingForm = <T>({
  shippingAddress,
  residentialAddress,
  firstName,
  lastName,
  phone,
  enableAddressValidation = false,
  shippingIsSameAsHomeByDefault = true,
}: UseShippingFormProps): UseShippingFormReturn<T> => {
  const event = useEventService();
  const config = getConfig();
  const [isSameAsHome, setIsSameAsHome] = React.useState(false);

  useEffectOnce(() => {
    if (shippingIsSameAsHomeByDefault && residentialAddress) {
      handleOnUseHomeAddressChange();
    }
  });

  const {
    handleAddressValidation,
    isValidatingShippingAddress,
    isShippingAddressWithinRange,
  } = useShippingAddressValidation();

  const {
    register,
    watch,
    handleSubmit,
    errors,
    setValue,
    getValues,
    trigger,
    control,
  } = useForm<UseShippingFormFields>({
    defaultValues: {
      firstName,
      lastName,
      phone,
      shippingAddress: {
        line1: shippingAddress?.line1 ?? '',
        line2: shippingAddress?.line2 ?? '',
        city: shippingAddress?.city ?? '',
        postalCode: shippingAddress?.postalCode ?? '',
        state: shippingAddress?.state ?? '',
        country: config.country,
        deliveryOption: shippingAddress?.deliveryOption ?? DELIVERY_OPTIONS[0],
        deliveryOther: shippingAddress?.deliveryOther ?? '',
      },
      residentialAddress: {
        line1: '',
        line2: '',
        city: '',
        postalCode: '',
        state: '',
        country: config.country,
      },
    },
  });

  const shippingAddressWatch = useWatch<
    UseShippingFormFields['shippingAddress']
  >({
    control,
    name: 'shippingAddress',
  });

  const isShippingAddressCompletedForPostcodeValidation =
    !!shippingAddressWatch?.line1 &&
    !!shippingAddressWatch.city &&
    !!shippingAddressWatch.postalCode;

  const clearShippingAddress = (): void => {
    setValue('shippingAddress', {
      line1: '',
      line2: '',
      city: '',
      postalCode: '',
      state: '',
    });
  };

  const validateShippingAddress = useCallback(
    async (address: ShippingAddressValidationInput): Promise<void> => {
      await handleAddressValidation(address);
    },
    [handleAddressValidation],
  );

  useDebounce(
    () => {
      if (
        enableAddressValidation &&
        isShippingAddressCompletedForPostcodeValidation
      ) {
        handleAddressValidation({
          line1: shippingAddressWatch?.line1 || '',
          line2: shippingAddressWatch?.line2,
          postalCode: shippingAddressWatch?.postalCode || '',
          city: shippingAddressWatch?.city || '',
        });
      }
    },
    1000,
    [
      enableAddressValidation,
      isShippingAddressCompletedForPostcodeValidation,
      shippingAddressWatch?.line1,
      shippingAddressWatch?.line2,
      shippingAddressWatch?.postalCode,
      shippingAddressWatch?.city,
      validateShippingAddress,
    ],
  );

  const getHomeAddress = React.useCallback(() => {
    if (residentialAddress) {
      return residentialAddress;
    }
    return getValues().residentialAddress;
  }, [getValues, residentialAddress]);

  const onHomeAddressChanged = React.useCallback(
    (data: Partial<UseShippingFormFields['residentialAddress']>) => {
      if (isSameAsHome) {
        setValue('shippingAddress', {
          line1: data?.line1 ?? '',
          line2: data?.line2 ?? '',
          city: data?.city ?? '',
          postalCode: data?.postalCode ?? '',
          state: data?.state ?? '',
          country: config.country,
        });
      }
    },
    [setValue, isSameAsHome, config.country],
  );

  const setShippingAddressWithRD = (): void => {
    const homeAddress = getHomeAddress();
    setValue('shippingAddress', {
      line1: homeAddress?.line1 ?? '',
      line2: homeAddress?.line2 ?? '',
      city: homeAddress?.city ?? '',
      postalCode: homeAddress?.postalCode ?? '',
      state: homeAddress?.state ?? '',
      country: config.country,
    });
  };

  const handleOnUseHomeAddressChange = (): void => {
    if (isSameAsHome) {
      setIsSameAsHome(false);
      clearShippingAddress();
    } else {
      setIsSameAsHome(true);
      setShippingAddressWithRD();
    }
  };

  const debouncedTrigger = debounce(trigger, 1000);

  const onShippingAddressChanged = React.useCallback(
    (data: Partial<UseShippingFormFields['shippingAddress']>) => {
      const homeAddress = getHomeAddress();
      const isSame =
        homeAddress?.line1 === data?.line1 &&
        !!homeAddress?.line2 === !!data?.line2 &&
        homeAddress?.city === data?.city &&
        homeAddress?.state === data?.state &&
        homeAddress?.postalCode === data?.postalCode;
      setIsSameAsHome(isSame);

      if (data?.postalCode) {
        debouncedTrigger();
      }
    },
    // The following is needed to not include the debounced trigger, as it's not memoized and breaks the callback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getHomeAddress],
  );

  const sendNoAlternativeAddressEvent = (
    userId: string,
    email: string,
    consultationId: string,
    problemType: ProblemType,
  ): void => {
    const shippingAddress = getValues().shippingAddress;
    event.weightLossPatient.noAlternateAddress({
      userId,
      email,
      consultationId,
      problemType,
      postalCode: shippingAddress.postalCode || '',
      city: shippingAddress.city || '',
    });
  };

  const validateAddress = async (): Promise<boolean> => {
    if (enableAddressValidation) {
      await handleAddressValidation({
        line1: shippingAddressWatch?.line1 || '',
        line2: shippingAddressWatch?.line2,
        postalCode: shippingAddressWatch?.postalCode || '',
        city: shippingAddressWatch?.city || '',
      });
      return isShippingAddressWithinRange;
    }
    return true;
  };
  return {
    register,
    watch,
    errors: errors as DeepMap<UseShippingFormFields & T, FieldError>,
    handleSubmit,
    onHomeAddressChanged,
    handleOnUseHomeAddressChange,
    onShippingAddressChanged,
    isSameAsHome,
    validate: trigger,
    isValidatingShippingAddress,
    isShippingAddressWithinRange,
    isShippingAddressCompletedForPostcodeValidation,
    validateAddress,
    sendNoAlternativeAddressEvent,
    setValue,
  };
};
