import type {
  PartsStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

type DrawerStyleProps = {
  colors?: {
    bg?: string;
  };
};

const DIALOG_TRAMLINE_MOBILE_SIZE = '36px';

const baseStyleDialog = (props: DrawerStyleProps): SystemStyleObject => ({
  bg: props?.colors?.bg,
});

export const getDrawerStyles = (
  props: DrawerStyleProps,
): Record<string, SystemStyleObject | PartsStyleFunction> => ({
  baseStyle: {
    dialog: baseStyleDialog(props),
  },
  sizes: {
    xs: {
      dialog: {
        maxW: `calc(100vw - ${DIALOG_TRAMLINE_MOBILE_SIZE})`,
      },
    },
  },
});
