import { useMemo } from 'react';
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';
import { Toast, ToastType, useResponsive } from '@eucalyptusvc/design-system';
import { ToastConfigParams, UseNotification } from './types';

const getToastConfig = (
  type: ToastType,
  params: ToastConfigParams,
): UseToastOptions => {
  const duration = params.duration || 5000;

  return {
    render: (): React.ReactNode => (
      <Toast type={type} message={params.message} duration={duration} />
    ),
    duration,
    position: params.isMobile ? 'top' : 'bottom',
    // containerStyle: {
    //   marginTop: params.isMobile ? '1rem' : '5rem', // To confirm with design
    // },
  };
};

const useNotification = (): UseNotification => {
  const { isMobile } = useResponsive();
  const toast = useChakraToast();

  const result: UseNotification = useMemo(
    () => ({
      error: (args): void => {
        toast(getToastConfig('error', { ...args, isMobile }));
      },
      warning: (args): void => {
        toast(getToastConfig('warning', { ...args, isMobile }));
      },
      info: (args): void => {
        toast(getToastConfig('info', { ...args, isMobile }));
      },
      success: (args): void => {
        toast(getToastConfig('success', { ...args, isMobile }));
      },
    }),
    [isMobile, toast],
  );

  return result;
};

export default useNotification;
