import { useState, useEffect, ReactElement, FC } from 'react';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { useFormContext, useWatch, RegisterOptions } from 'react-hook-form';
import { TextInput, ToggleTabs } from '@eucalyptusvc/design-system';
import { QuestionProps } from './types';
import {
  combineRules,
  imperialToMetric,
  useValidNumber,
  useMinNumberValue,
  useRequiredValidation,
} from '@customer-frontend/utils';
import {
  DefaultUnitSystemQuery,
  QuizQuestion,
  UnitSystem,
} from '@customer-frontend/graphql-types';
import { useAuth } from '@customer-frontend/auth';
import { useParams } from 'react-router-dom';
import { useEventService } from '@customer-frontend/events';
import { useQuizApplication } from '@customer-frontend/services';

const MetricForm: FC<QuestionProps> = ({
  question,
  isLoading,
}: QuestionProps) => {
  const { metricInputLabel } = question.options;
  const { register, errors, setValue } = useFormContext();
  const validationRules = useGetMetricValidationRules(question);

  return (
    <TextInput
      autoFocus
      name={question.id}
      ref={register(validationRules)}
      onBlur={(e) => {
        // Mobile Safari gives non-useful error when > 1 decimal place so we round on blur
        const value = parseFloat(e.target.value);
        if (!Number.isNaN(value)) {
          setValue(question.id, value.toFixed(1));
        }
      }}
      type="number"
      inputMode="decimal"
      step="0.1"
      label={metricInputLabel}
      disabled={isLoading}
      required={question.required ?? false}
      errorMessage={errors[question.id]?.message}
    />
  );
};

const ImperialForm: FC<QuestionProps> = ({
  question,
  isLoading,
}: QuestionProps) => {
  const {
    imperialMajorInputLabel,
    imperialMinorInputLabel,
    imperialMajorToMinorConversion,
    imperialMinorToMetricConversion,
  } = question.options;
  const majorFormQuestionId = `${question.id}-imperial-major`;
  const minorFormQuestionId = `${question.id}-imperial-minor`;

  const { register, errors, control, setValue } = useFormContext();
  register(question.id);

  const values = useWatch({
    name: [majorFormQuestionId, minorFormQuestionId],
    control,
  });

  const minorFormValue = values[minorFormQuestionId];
  const majorFormValue = values[majorFormQuestionId];

  const metricValue = imperialToMetric(
    majorFormValue,
    minorFormValue,
    imperialMajorToMinorConversion,
    imperialMinorToMetricConversion,
  );

  const majorValidationRules = useGetImperialValidationRules(question, 'MAJOR');
  const minorValidationRules = useGetImperialValidationRules(question, 'MINOR');

  useEffect(() => {
    setValue(question.id, metricValue);
  }, [question.id, setValue, metricValue]);

  return (
    <div className="space-y-4">
      <TextInput
        autoFocus
        name={majorFormQuestionId}
        ref={register(majorValidationRules)}
        type="number"
        inputMode="numeric"
        disabled={isLoading}
        label={imperialMajorInputLabel}
        required={question.required ?? false}
        errorMessage={errors[majorFormQuestionId]?.message}
      />
      <TextInput
        name={minorFormQuestionId}
        ref={register(minorValidationRules)}
        onBlur={(e) => {
          // Mobile Safari gives non-useful error when > 1 decimal place so we round on blur
          const value = parseFloat(e.target.value);
          if (!Number.isNaN(value)) {
            setValue(minorFormQuestionId, value.toFixed(1));
          }
        }}
        type="number"
        inputMode="decimal"
        step="0.1"
        disabled={isLoading}
        label={imperialMinorInputLabel}
        errorMessage={errors[minorFormQuestionId]?.message}
      />
    </div>
  );
};

export function UnitisedNumericQuestionInput(
  props: QuestionProps,
): ReactElement | null {
  const { data, loading } = useQuery<DefaultUnitSystemQuery>(
    gql`
      query DefaultUnitSystem {
        profile {
          id
          preferredUnitSystem
        }
      }
    `,
  );

  const { question } = props;
  const { metricButtonLabel, imperialButtonLabel } = question.options;
  const event = useEventService();
  const { loggedInUser } = useAuth();
  const { quizApplicationId } = useParams<{ quizApplicationId: string }>();
  const { data: quizApplicationData } = useQuizApplication(quizApplicationId);
  const [activeUnit, setActiveUnit] = useState<UnitSystem | null>(null);

  const quizApp = quizApplicationData?.getQuizApplication;
  const consultation = quizApp?.consultation;
  const consultationId = consultation?.id || '';

  useEffect(() => {
    if (!loading) {
      if (data?.profile?.preferredUnitSystem) {
        setActiveUnit(data.profile.preferredUnitSystem);
      } else {
        setActiveUnit('METRIC');
      }
    }
  }, [data?.profile?.preferredUnitSystem, loading]);

  useEffect((): void => {
    if (activeUnit) {
      event.dispatchQuizUnitEvent({
        userId: loggedInUser?.id || '',
        consultationId: consultationId,
        unitSystem: activeUnit,
        shortCode: question.shortcode || '',
      });
    }
  }, [activeUnit, consultationId, event, loggedInUser?.id, question.shortcode]);

  if (loading) {
    return (
      <>
        <div className="flex flex-row gap-1">
          <div className="flex-auto h-10 bg-gray-200 rounded animate-pulse" />
          <div className="flex-auto h-10 bg-gray-200 rounded animate-pulse" />
        </div>
        <div className="w-20 h-4 mt-5 bg-gray-200 rounded animate-pulse" />
        <div className="w-full mt-2 h-10 bg-gray-200 rounded animate-pulse" />
        <div className="w-20 h-4 mt-5 bg-gray-200 rounded animate-pulse" />
        <div className="w-full mt-2 h-10 bg-gray-200 rounded animate-pulse" />
      </>
    );
  }

  if (!activeUnit) {
    return null;
  }

  return (
    <div className="mb-8 space-y-4">
      <ToggleTabs
        options={[
          {
            id: 'METRIC',
            label: metricButtonLabel,
          },
          {
            id: 'IMPERIAL',
            label: imperialButtonLabel,
          },
        ]}
        initialSelected={activeUnit}
        onChange={(id: string): void => {
          if (id === 'IMPERIAL') {
            setActiveUnit('IMPERIAL');
          }
          if (id === 'METRIC') {
            setActiveUnit('METRIC');
          }
        }}
      />
      {activeUnit === 'METRIC' && <MetricForm {...props} />}
      {activeUnit === 'IMPERIAL' && <ImperialForm {...props} />}
    </div>
  );
}

const useGetImperialValidationRules = (
  question: QuizQuestion,
  unit: 'MAJOR' | 'MINOR',
): RegisterOptions => {
  const { required } = question;
  const { formatMessage } = useIntl();

  const answerRequiredValidation = useRequiredValidation(
    formatMessage({
      defaultMessage: 'answer',
    }),
  );

  const rules = [
    required && unit === 'MAJOR' ? answerRequiredValidation : undefined,
    useMinNumberValue(unit === 'MAJOR' ? 1 : 0),
    useValidNumber(),
  ];

  return combineRules(...rules);
};

const useGetMetricValidationRules = (
  question: QuizQuestion,
): RegisterOptions => {
  const { required } = question;
  const { formatMessage } = useIntl();

  const answerRequiredValidation = useRequiredValidation(
    formatMessage({
      defaultMessage: 'answer',
    }),
  );

  const rules = [
    required ? answerRequiredValidation : undefined,
    useMinNumberValue(0),
    useValidNumber(),
  ];

  return combineRules(...rules);
};
