import React from 'react';
import { QuestionProps } from './types';
import { CheckboxQuestionInput } from './checkbox-question-input';
import { DateQuestionInput } from './date-question-input';
import { EmailQuestionInput } from './email-question-input';
import { RadioImgQuestionInput } from './radio-img-question-input';
import { RadioQuestionInput } from './radio-question-input';
import { TextfieldQuestionInput } from './textfield-question-input';
import { TextareaQuestionInput } from './textarea-question-input';
import { NumberQuestionInput } from './number-question-input';
import { DropdownQuestionInput } from './dropdown-question-input';
import { UnitisedNumericQuestionInput } from './unitised-numeric-question-input';

export function QuestionInput(props: QuestionProps): React.ReactElement {
  switch (props.question.type) {
    case 'CHECKBOX':
      return <CheckboxQuestionInput {...props} />;
    case 'DATE':
      return <DateQuestionInput {...props} />;
    case 'EMAIL':
      return <EmailQuestionInput {...props} />;
    case 'RADIO':
      return <RadioQuestionInput {...props} />;
    case 'RADIO_IMG':
      return <RadioImgQuestionInput {...props} />;
    case 'NUMBER':
      return <NumberQuestionInput {...props} />;
    case 'TEXTAREA':
      return <TextareaQuestionInput {...props} />;
    case 'TEXTFIELD':
      return <TextfieldQuestionInput {...props} />;
    case 'UNITISED_NUMERIC':
      return <UnitisedNumericQuestionInput {...props} />;
    case 'DROPDOWN':
      return <DropdownQuestionInput {...props} />;
    default:
      return <></>;
  }
}
