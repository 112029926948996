import { Card, Divider, Button, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DownloadAppIcon } from '../assets/icon/juniper-download-app.svg';
import { useEnvironment } from '@customer-frontend/environment';

export const DownloadAppCard = (): React.ReactElement => {
  const environment = useEnvironment();

  return (
    <div className="col-span-2 md:col-span-1 w-full bg-primary-100">
      <Card>
        <div className="flex flex-col space-y-3 md:space-y-4">
          <div className="flex flex-row items-center space-x-2">
            <DownloadAppIcon />
            <Typography size="md" isBold>
              <FormattedMessage defaultMessage="Download the app" />
            </Typography>
          </div>
          <Divider />
          <div className="flex items-center space-x-3">
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="It's the only tool you need to navigate your pathway to lasting weight loss." />
            </Typography>
          </div>
          <Button
            onClick={() =>
              window.open(
                `${environment.landingPageUrl}/mobile-download`,
                '_blank',
              )
            }
            level="primary"
            palette={'alternate'}
            isFullWidth
          >
            <FormattedMessage defaultMessage="Download now" />
          </Button>
        </div>
      </Card>
    </div>
  );
};
