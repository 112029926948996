import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import {
  SwitchPurchasedOfferingMutation,
  SwitchPurchasedOfferingMutationVariables,
  SequenceSelectionInput,
  SwitchOfferingsPageQuery,
  SwitchOfferingsPageQueryVariables,
} from '@customer-frontend/graphql-types';
import {
  Button,
  ButtonPalette,
  Divider,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import { ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as InfoCircle } from '../../assets/info-circle.svg';
import clsx from 'clsx';
import { sharedColors } from '@eucalyptusvc/design-system/src/theme/shared';
import { useEventService } from '@customer-frontend/events';
import { Logger } from '@customer-frontend/logger';
import {
  OfferingVerticalBottomSection,
  OfferingVerticalTopSection,
  offeringVerticalFragment,
} from '../../flexiplans/offering-verticals';

export function OfferingSwitchPage(props: {
  offeringPlanRoute: (purchaseId: string) => string;
  offeringSwitchReceiptRoute: (purchaseId: string) => string;
  profileRoute: string;
  colors: {
    textClassName: string;
    highlightBgColorClassName: string;
  };
  variables: {
    backButtonPalette: ButtonPalette;
  };
  logger: Logger;
}): ReactElement {
  const history = useHistory();
  const notifications = useNotification();
  const events = useEventService();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const { data } = useSuspenseQuery<
    SwitchOfferingsPageQuery,
    SwitchOfferingsPageQueryVariables
  >(
    gql`
      query SwitchOfferingsPage($purchaseId: ID!) {
        purchase(id: $purchaseId) {
          id
          canBeSwitched
          contexts {
            id
            sequence {
              id
            }
          }
          switchCandidates {
            id
            advertisedCadencePricingDelta
            offering {
              ...OfferingVertical
              sequenceSets {
                id
                defaultSequence {
                  id
                }
              }
            }
          }
          offering {
            id
            upsells {
              id
              offering {
                id
              }
            }
            downsells {
              id
              offering {
                id
              }
            }
          }
        }
      }
      ${offeringVerticalFragment}
    `,
    {
      variables: { purchaseId },
    },
  );

  const [select, { loading: selectLoading }] = useMutation<
    SwitchPurchasedOfferingMutation,
    SwitchPurchasedOfferingMutationVariables
  >(gql`
    mutation SwitchPurchasedOffering($input: SwitchPurchasedOfferingInput!) {
      switchPurchasedOffering(input: $input) {
        oldPurchase {
          id
          canBeSwitched
        }
      }
    }
  `);
  const [selectedOfferingId, setSelectedOfferingId] = useState<string>();
  const { formatMessage } = useIntl();

  if (!selectedOfferingId && data.purchase?.offering?.id) {
    setSelectedOfferingId(data.purchase.offering.id);
  }

  // protects against customers navigating back from the receipt screen and
  // attempting to switch from a superseded purchased
  if (!data.purchase?.canBeSwitched) {
    return <Redirect to={props.profileRoute} />;
  }

  return (
    <section className="pt-10 md:pt-14 pb-10 md:pb-24 flex flex-col gap-6">
      <div className="max-w-screen-md px-4 mx-auto flex flex-col gap-10 w-full">
        <Typography size="lg" isBold>
          <FormattedMessage defaultMessage="Change your plan" />
        </Typography>
        <div className="w-full border border-status-info-500 bg-status-info-100 rounded p-4 flex flex-row gap-2 items-center text-neutral-900">
          <InfoCircle
            className="h-5 flex-shrink-0"
            fill={sharedColors.neutral[900]}
          />
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Changes made to your plan will affect your next order" />
          </Typography>
        </div>
      </div>
      <div className={clsx('overflow-y-scroll', props.colors.textClassName)}>
        {/* needed to separate columns into a horizontal set of div's to get the boxes aligned vertically across switch candidates */}
        <div className="flex flex-row gap-4 w-fit mx-auto px-4">
          {data.purchase?.switchCandidates?.map((s) => {
            if (!s.offering) {
              props.logger.error('No offering found for switch candidate', {
                switchCandidateId: s.id,
              });
              return null;
            }
            const isUpsell = data.purchase?.offering?.upsells?.some(
              (u) => u.offering?.id === s.offering?.id,
            );

            return (
              <div
                key={s.id}
                className="flex w-[328px] flex-shrink-0 border-x border-t border-primary-300 rounded-t overflow-clip border-b border-b-primary-200"
              >
                <OfferingVerticalTopSection
                  offering={s.offering}
                  isUpsell={isUpsell ?? false}
                  pricingDelta={s.advertisedCadencePricingDelta}
                  imgClassName="h-52 aspect-[328/208]"
                />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row gap-4 w-fit mx-auto px-4">
          {data.purchase?.switchCandidates?.map((s) => {
            if (!s.offering) {
              props.logger.error('No offering found for switch candidate', {
                switchCandidateId: s.id,
              });
              return null;
            }

            return (
              <div
                key={s.id}
                className="flex w-[328px] flex-shrink-0 border-x border-primary-300"
              >
                <OfferingVerticalBottomSection offering={s.offering} />
              </div>
            );
          })}
        </div>
        <div className="flex flex-row gap-4 w-fit mx-auto px-4">
          {data.purchase?.switchCandidates?.map((s) => {
            if (!s.offering) {
              props.logger.error('No offering found for switch candidate', {
                switchCandidateId: s.id,
              });
              return null;
            }
            const offeringId = s.offering.id;

            let ribbonCopy: ReactElement | undefined;

            const isUpsell = data.purchase?.offering?.upsells?.some(
              (u) => u.offering?.id === s.offering?.id,
            );

            if (offeringId !== data.purchase?.offering?.id) {
              if (isUpsell) {
                ribbonCopy = <FormattedMessage defaultMessage="Upgrade" />;
              } else {
                ribbonCopy = <FormattedMessage defaultMessage="Downgrade" />;
              }
            }

            return (
              <div
                key={s.id}
                className="w-[328px] flex-shrink-0 p-4 md:p-6 border-primary-300 border-x border-b rounded-b bg-white"
              >
                <button
                  onClick={() => {
                    events.switchOfferingSelectedEvent({ offeringId });
                    setSelectedOfferingId(offeringId);
                  }}
                  className={clsx(
                    'border border-neutral-black rounded p-4 flex flex-row w-full justify-between relative',
                    {
                      'border-opacity-100 ring-1 ring-black':
                        offeringId === selectedOfferingId,
                      'border-opacity-30': offeringId !== selectedOfferingId,
                    },
                  )}
                >
                  {ribbonCopy && (
                    <div
                      className={clsx(
                        'absolute -top-4 left-3 px-2 pb-1 rounded text-primary-600',
                        props.colors.highlightBgColorClassName,
                      )}
                    >
                      <Typography size="small-text" isBold inheritColor>
                        {ribbonCopy}
                      </Typography>
                    </div>
                  )}
                  <div className="flex flex-col space-y-4 text-primary-600 items-start">
                    <Typography size="medium-paragraph" isBold textAlign="left">
                      <FormattedMessage
                        defaultMessage="{advertisedName} plan"
                        values={{
                          advertisedName: s.offering.advertisedName,
                        }}
                      />
                    </Typography>
                    <Typography size="large-paragraph" isBold>
                      {s.advertisedCadencePricingDelta}
                    </Typography>
                  </div>
                  <div className="border border-primary-600 rounded-full ml-3">
                    <div
                      className={clsx(
                        'w-5 h-5 rounded-full border-neutral-white border-[3px]',
                        {
                          'bg-primary-600': offeringId === selectedOfferingId,
                          'bg-neutral-white': offeringId !== selectedOfferingId,
                        },
                      )}
                    />
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="max-w-screen-md px-4 mx-auto flex flex-col w-full">
        <div className="text-center flex flex-col justify-center items-center">
          <Typography size="paragraph">
            <FormattedMessage defaultMessage="Plan renews each billing cycle. You can change your plan anytime." />
          </Typography>
        </div>
        <Divider variant="separator" mb="xl" mt="md" />
        <div className="flex flex-row sm:gap-44 gap-6">
          <div className="flex-1">
            <Button
              isFullWidth
              level="secondary"
              palette={props.variables.backButtonPalette}
              eventElementName="switchOfferingPageBackButton"
              onClick={() => history.push(props.offeringPlanRoute(purchaseId))}
            >
              <FormattedMessage defaultMessage="Go back" />
            </Button>
          </div>
          <div className="flex-1">
            <Button
              isFullWidth
              palette="alternate"
              isLoading={selectLoading}
              eventElementName="switchOfferingPageContinueButton"
              isDisabled={
                !selectedOfferingId ||
                selectedOfferingId === data.purchase?.offering?.id
              }
              onClick={async () => {
                if (!selectedOfferingId) {
                  notifications.error({
                    message: formatMessage({
                      defaultMessage:
                        'Something went wrong. Please contact support.',
                    }),
                  });
                  throw new Error('No offering selected');
                }

                const switchCandidate = data?.purchase?.switchCandidates?.find(
                  (s) => s.offering?.id === selectedOfferingId,
                );
                if (!switchCandidate) {
                  notifications.error({
                    message: formatMessage({
                      defaultMessage:
                        'Something went wrong. Please contact support.',
                    }),
                  });
                  throw new Error('Switch candidate could not be found');
                }

                const sequenceSelections: SequenceSelectionInput[] = [];
                const currentRxSequenceId = data.purchase?.contexts?.find(
                  (c) => c.sequence?.__typename === 'PrescribableSequence',
                )?.sequence?.id;

                for (const sequenceSet of switchCandidate.offering
                  ?.sequenceSets ?? []) {
                  for (const sequence of sequenceSet.sequences || []) {
                    if (
                      sequence.__typename === 'PrescribableSequence' &&
                      currentRxSequenceId &&
                      currentRxSequenceId === sequence.id
                    ) {
                      sequenceSelections.push({
                        sequenceSetId: sequenceSet.id,
                        sequenceId: currentRxSequenceId,
                      });
                      break;
                    }

                    if (
                      sequence.__typename !== 'PrescribableSequence' &&
                      sequenceSet.defaultSequence?.id === sequence.id
                    ) {
                      sequenceSelections.push({
                        sequenceSetId: sequenceSet.id,
                        sequenceId: sequence.id,
                      });
                      break;
                    }
                  }
                }

                const { data: selectData } = await select({
                  variables: {
                    input: {
                      purchaseId,
                      offeringSelection: {
                        offeringId: selectedOfferingId,
                        sequenceSelections,
                      },
                    },
                  },
                });

                if (!selectData?.switchPurchasedOffering?.oldPurchase?.id) {
                  notifications.error({
                    message: formatMessage({
                      defaultMessage:
                        'Something went wrong. Please contact support.',
                    }),
                  });

                  throw new Error('No new purchase id found');
                }

                history.push(
                  props.offeringSwitchReceiptRoute(
                    selectData.switchPurchasedOffering.oldPurchase.id,
                  ),
                );
              }}
            >
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
