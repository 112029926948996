import React from 'react';
import {
  formatDiabetesRiskLevel,
  getRiskLevelExplanationCopy,
  getTestRequestReasonCopy,
  getTestRequestOutcomeCopy,
} from './utils';
import {
  Card,
  Markdown,
  Button,
  Typography,
  Divider,
} from '@eucalyptusvc/design-system';

import {
  DoctorInfo,
  DoctorModal,
  formatDoctorName,
  PrescriberType,
  TitleType,
} from '@customer-frontend/doctor';
import { PathologyLetterProps } from './types';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';

const getWeightLossMarkdown = ({
  doctor,
  customer,
  customerAttributes,
}: Pick<
  PathologyLetterProps,
  'doctor' | 'customer' | 'customerAttributes'
>): string => {
  const { diabetesRiskLevel, diabetesRiskProbability } =
    customerAttributes.diabetesRisk;

  const formattedDiabetesRiskLevel = formatDiabetesRiskLevel(diabetesRiskLevel);
  const riskLevelExplanationCopy = getRiskLevelExplanationCopy(
    diabetesRiskLevel,
    diabetesRiskProbability,
  );
  const testRequestReasonCopy = getTestRequestReasonCopy(diabetesRiskLevel);
  const testRequestOutcomeCopy = getTestRequestOutcomeCopy(diabetesRiskLevel);
  const doctorName = formatDoctorName(doctor, PrescriberType.DOCTOR);

  return `Hi ${customer.shortAddressableName ?? 'there'},

I've been assigned as your practitioner and wanted to say thank you for your responses.

**I've completed a risk assessment for diabetes and your results have come back as '${formattedDiabetesRiskLevel} risk'.** ${riskLevelExplanationCopy}

To ensure we are putting your health first, we need to have some blood tests done to ${testRequestReasonCopy}

Once you have completed the tests, please let me know so we can take a look at the results and ${testRequestOutcomeCopy}

Sincerely,

${doctorName}`;
};

const getHairMarkdown = ({
  doctor,
  customer,
}: Pick<PathologyLetterProps, 'doctor' | 'customer'>): string => {
  const doctorName = formatDoctorName(doctor, PrescriberType.DOCTOR);
  return `Hi ${customer.shortAddressableName ?? 'there'},

As part of your hair loss treatment, I'll need you to have a blood test done (ideally within the next month) at your nearest pathology collection centre. This will help me identify if there are any underlying contributors to your hair loss that we need to address.

**Some things to remember:**

- Double check the details on your referral form are correct. 
- If you have Medicare, the cost of your test will be covered.
- This blood test should only take 5-10 minutes.
- This is a **fasting blood test** - don't eat for 12 hours prior (water is fine).

Once you have completed the test, your pathology collection centre will send the results to me and I can determine if we need to adjust your treatment.

If you haven't heard from Software within 5-7 business days of your pathology test, contact our support team.

Sincerely,

${doctorName}`;
};

const getMarkdown = (props: PathologyLetterProps): string | null => {
  switch (props.consultationType) {
    case 'WEIGHT_LOSS':
      return getWeightLossMarkdown(props);
    case 'HAIR':
      return getHairMarkdown(props);
    default:
      return null;
  }
};

export const PathologyLetter: React.FC<PathologyLetterProps> = (props) => {
  const [showModal, setShowModal] = React.useState(false);

  const config = getConfig();

  const markdown = getMarkdown(props);

  if (!markdown) {
    return null;
  }

  return (
    <Card>
      <Typography size="lg" isBold>
        Pathology referral
      </Typography>
      <Divider variant="separator" mt="xs" />
      <DoctorInfo
        doctor={props.doctor}
        details={[
          props.doctor.provider?.qualifications,
          formatDate(mapBrandToAdaptersBrand(config.brand), props.date, {
            dateStyle: 'medium',
            timeStyle: 'short',
          }),
        ]}
        className="mb-6"
      />
      {props.doctor.bio && (
        <div className="mb-4">
          <Button level="secondary" onClick={() => setShowModal(true)}>
            View practitioners profile
          </Button>
          <DoctorModal
            doctor={props.doctor}
            titleType={TitleType.DOCTOR}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          />
        </div>
      )}
      <Markdown src={markdown} formatWhitespace />
    </Card>
  );
};
