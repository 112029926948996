import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useAuth } from '@customer-frontend/auth';
import {
  Card,
  Button,
  Divider,
  Typography,
  LoadingSpinner,
} from '@eucalyptusvc/design-system';

import { PathologyLocations } from './pathology-locations';
import { PathologyReferralDownload } from './pathology-referral-download';
import { ConfirmPathologyProps } from './types';
import { PathologyLetter } from './pathology-letter';
import { AwaitingPathologyTestModal } from './modal';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import {
  ConfirmPathologyQuery,
  ConfirmPathologyQueryVariables,
} from '@customer-frontend/graphql-types';
import { ReactComponent as CircleTick } from '../assets/circle-tick.svg';
import { FormattedMessage } from 'react-intl';

const CONFIRM_PATHOLOGY_QUERY_DOCUMENT = gql`
  query ConfirmPathology($consultationId: String!) {
    consultation(id: $consultationId) {
      id
      type
      doctor {
        id
        fullName
        fullClinicianName
        shortClinicianName
        lastName
        avatar {
          id
          url
        }
        bio
        provider {
          id
          clinicianTitle
          qualifications
        }
      }
      pathologyRequests {
        id
        createdAt
        customerStatedCollectedAt
        referral {
          id
          signedUrl
        }
      }
    }
    customerAttributes {
      id
      diabetesRisk {
        diabetesRiskLevel
        diabetesRiskProbability
      }
    }
  }
`;

export const ConfirmPathologyTemplate = ({
  consultationId,
  returnUrl,
  supportEmail,
  instructionsTitle,
  resultsInstructions,
}: ConfirmPathologyProps): React.ReactElement | null => {
  const config = getConfig();
  const history = useHistory();

  const { data, loading } = useQuery<
    ConfirmPathologyQuery,
    ConfirmPathologyQueryVariables
  >(CONFIRM_PATHOLOGY_QUERY_DOCUMENT, {
    variables: { consultationId },
    fetchPolicy: 'cache-first',
  });

  const { loggedInUser: customer } = useAuth();
  const [showModal, setShowModal] = React.useState(false);

  const consultation = data?.consultation;
  const doctor = consultation?.doctor;
  const pathologyRequest = consultation?.pathologyRequests?.[0];
  const customerStatedCollectedAt = pathologyRequest?.customerStatedCollectedAt;
  const signedReferralPdfUrl = pathologyRequest?.referral?.signedUrl;

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-5 flex flex-col items-center mb-12">
      {doctor &&
        customer &&
        consultation?.type &&
        pathologyRequest &&
        data?.customerAttributes && (
          <PathologyLetter
            consultationType={consultation.type}
            customer={customer}
            doctor={doctor}
            customerAttributes={data.customerAttributes}
            date={new Date(pathologyRequest.createdAt)}
          />
        )}
      <Card>
        <Typography size="lg" isBold>
          {instructionsTitle}
        </Typography>
        <Divider variant="separator" />
        <div className="space-y-5">
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="1) Download and print your referral form below." />
          </Typography>
          <PathologyReferralDownload
            signedReferralPdfUrl={signedReferralPdfUrl}
            customerLastName={customer?.lastName}
          />
        </div>
        <Divider variant="separator" />
        <div className="space-y-5">
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="2) Take to your nearest pathology collection center. Don't forget to take your Medicare card." />
          </Typography>
          <PathologyLocations />
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="If you're having problems finding a center, please email us at <a>{supportEmail}</a>"
              values={{
                a: (chunks) => (
                  <a href={`mailto:${supportEmail}`} className="underline">
                    {chunks}
                  </a>
                ),
                supportEmail,
              }}
            />
          </Typography>
        </div>
        <Divider variant="separator" />
        {resultsInstructions}
        <div className="mt-4">
          {customerStatedCollectedAt ? (
            <div className="flex flex-row items-center space-x-2">
              <CircleTick className="text-status-success-500" />
              <Typography size="medium-paragraph">
                <span>
                  <FormattedMessage defaultMessage="You've completed your blood test." />
                </span>
              </Typography>
            </div>
          ) : (
            <Button
              level="primary"
              palette={getPrimaryButtonPalette(config.brand)}
              onClick={() => setShowModal(true)}
              isFullWidth
            >
              <FormattedMessage
                defaultMessage="I've done my blood test"
                description="CTA for patients to confirm theyve done a test"
              />
            </Button>
          )}
        </div>
        {pathologyRequest && (
          <AwaitingPathologyTestModal
            showModal={showModal}
            setShowModal={setShowModal}
            pathologyRequest={pathologyRequest}
          />
        )}
      </Card>
      <Button
        level="secondary"
        isFullWidth
        palette={getSecondaryButtonPalette(config.brand)}
        onClick={() => {
          history.push(returnUrl);
        }}
      >
        <FormattedMessage
          defaultMessage="Back to profile"
          description="CTA that links back to the profile (home) page"
        />
      </Button>
    </div>
  );
};
