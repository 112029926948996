import { gql } from '@apollo/client';
import { useOtcSubscriptionV2Query as useSubscriptionV2 } from '@customer-frontend/graphql-types';
import { subscriptionPlanV2Fragment } from './subscription-plan-v2-fragment';

export const subscriptionV2Query = gql`
  query OtcSubscriptionV2($id: String!) {
    subscriptionV2(id: $id) {
      id
      createdAt
      updatedAt
      nextPaymentAt
      status
      subscriptionPlan {
        ...OtcSubscriptionPlanV2Info
      }
    }
  }
  ${subscriptionPlanV2Fragment}
`;

export { useSubscriptionV2 };
