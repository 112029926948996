import { gql } from '@apollo/client';

export const addPatientQuestionToConsultationMutation = gql`
  mutation AddPatientQuestionToConsultation(
    $consultationId: String!
    $question: String!
    $trigger: String!
  ) {
    addPatientQuestionToConsultation(
      id: $consultationId
      question: $question
      trigger: $trigger
    ) {
      id
      status
    }
  }
`;
