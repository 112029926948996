import { ContentfulContentType } from '@customer-frontend/contentful';
import { EducationReading } from '@customer-frontend/graphql-types';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { TimeToRead } from '../time-to-read';
import { convertMsToMinutes } from '../utils';
import { VFC } from 'react';
import { useEducationTheme } from '../../provider';
import { NextReadingCardTemplate } from './next-reading-card-template';
import { FormattedMessage, useIntl } from 'react-intl';

type NextReadingCardContentProps = {
  onBeginPress: () => void;
  reading: EducationReading;
};

export const NextReadingCardContent: VFC<NextReadingCardContentProps> = ({
  onBeginPress,
  reading,
}) => {
  const theme = useEducationTheme();
  const cardTheme = theme.brand.nextReadingDashboardCard;
  const readingContent = reading.content as ContentfulContentType;
  const readingTitle = readingContent.fields?.title;
  const timeToReadMinutes = reading.timeToReadMs
    ? convertMsToMinutes(reading.timeToReadMs)
    : 1;
  const readingAlreadyStarted = reading.startedAt;
  const { formatMessage } = useIntl();

  const header = readingAlreadyStarted ? (
    <>
      {cardTheme?.readStatusIcon.inProgress}
      <Typography size="md" isBold>
        <FormattedMessage
          description="Header to prompt patient to continue reading"
          defaultMessage="Continue reading"
        />
      </Typography>
    </>
  ) : (
    <>
      {cardTheme?.readStatusIcon.unread}
      <Typography size="md" isBold>
        <FormattedMessage
          description="Header to prompt patient to start recommended reading"
          defaultMessage="Recommended reading"
        />
      </Typography>
    </>
  );
  const beginButtonText = readingAlreadyStarted
    ? formatMessage({
        defaultMessage: 'Continue',
        description: 'Label for continue button',
      })
    : formatMessage({
        defaultMessage: 'Start reading',
        description: 'Label for start reading button',
      });

  return (
    <NextReadingCardTemplate header={header} showDivider>
      <div className="flex space-y-5 flex-col flex-1">
        <div className="space-y-2">
          <Typography
            size="large-paragraph"
            isItalic
            color={cardTheme?.textColor}
          >
            {readingTitle}
          </Typography>
          <TimeToRead
            icon={cardTheme?.timeToRead.icon}
            colorClassName={cardTheme?.timeToRead.colorClassName}
            textColor={cardTheme?.textColor}
            minutes={timeToReadMinutes}
          />
        </div>
        <Button onClick={onBeginPress}>{beginButtonText}</Button>
      </div>
    </NextReadingCardTemplate>
  );
};
