import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { Typography, ListButton } from '@eucalyptusvc/design-system';

interface ControlledRadioButtonProps {
  children: React.ReactNode;
  control: Control;
  name: string;
  value: string;
  label: string;
  disabled?: boolean;
  disableInnerFlex?: boolean;
  className?: string;
}

export const ControlledRadioButton = (
  props: ControlledRadioButtonProps,
): JSX.Element => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ onChange }): JSX.Element => (
        <ListButton
          {...props}
          isSubmit
          onClick={() => {
            onChange(props.value);
          }}
        >
          <Typography size="medium-paragraph">{props.label}</Typography>
          {props.children}
        </ListButton>
      )}
    />
  );
};
