import clsx from 'clsx';
import { useInitialiseWistia } from './use-initialise-wistia';
import { WistiaVideoProps } from './types';
import { getPlayerColor } from './utils';

// https://wistia.com/support/developers/player-api

export const WistiaVideo = ({
  videoId,
  children,
  videoClassName,
  ...rest
}: WistiaVideoProps): React.ReactElement => {
  useInitialiseWistia({ videoId, ...rest });

  return (
    <div>
      <div
        className="wistia_responsive_padding relative"
        style={{
          paddingTop: '50% ', // Necessary for wistia video to render
        }}
      >
        <div className="wistia_responsive_wrapper h-full w-full left-0 top-0 absolute">
          <div
            className={clsx(
              `wistia_embed wistia_async_${videoId} playlistLinks=wistia_links wistia_embed_initialize playerColor=${getPlayerColor()} h-full w-full relative rounded overflow-hidden`,
              videoClassName,
            )}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
