import React, { useRef } from 'react';
import { Input } from '@chakra-ui/react';
import { BaseInputProps, TextInputProps } from '../types';
import { useCleave } from '../utils';
import { FormWrapper } from '../form-wrapper';

const BaseTextInput = (
  {
    hint,
    name,
    label,
    value,
    onBlur,
    onChange,
    disabled,
    required,
    className,
    onKeyPress,
    placeholder,
    errorMessage,
    defaultValue,
    type = 'text',
    palette = 'default',
    ...textProps
  }: TextInputProps,
  forwardedRef: React.Ref<HTMLInputElement>,
): React.ReactElement => {
  const ref = useRef<null | HTMLInputElement>(null);
  useCleave({ ref, inputType: type, onChange });

  return (
    <FormWrapper
      hint={hint}
      label={label}
      required={required}
      disabled={!!disabled}
      errorMessage={errorMessage}
      showInputIcon
      palette={palette}
    >
      <Input
        ref={(el: HTMLInputElement): void => {
          ref.current = el;
          if (forwardedRef instanceof Function) {
            forwardedRef(el);
          }
        }}
        data-peer // Required to dim hint when disabled
        name={name}
        type={type}
        value={value}
        onBlur={onBlur}
        variant="outline" // TODO: Explore new variants
        onChange={onChange}
        className={className}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...textProps}
      />
    </FormWrapper>
  );
};

/** Use a **TextInput** when you need to collect data from a user, often as
 * part of a form. Controlled component so it is easy to integrate with a form
 * library. */
export const TextInput = React.forwardRef<
  HTMLInputElement,
  BaseInputProps<HTMLInputElement>
>(BaseTextInput);
