import React from 'react';
import { UseFormMethods, DeepMap, FieldError } from 'react-hook-form';
import { emailIsValid, requiredValidation } from '@customer-frontend/utils';
import {
  Card,
  LinkButton,
  LoadingSpinner,
  TextInput,
  Typography,
} from '@eucalyptusvc/design-system';

import { PlanPaymentFormData } from './types';
import { V2SubscriptionPlanPaymentProfileQuery } from '@customer-frontend/graphql-types';

export const ConfirmDetailsForm = ({
  register,
  errors,
  onLogoutClick,
  loading,
  profileData,
}: {
  register: UseFormMethods['register'];
  errors: DeepMap<PlanPaymentFormData, FieldError>;
  onLogoutClick: () => void;
  loading: boolean;
  profileData?: V2SubscriptionPlanPaymentProfileQuery;
}): React.ReactElement | null => {
  const { fullName } = profileData?.profile ?? {};

  if (loading) {
    return (
      <Card>
        <div className="flex p-3 items-center justify-center">
          <LoadingSpinner palette="default" />
        </div>
      </Card>
    );
  }

  if (!profileData) {
    return null;
  }

  return (
    <Card>
      <div className="space-y-2 mb-6">
        <Typography size="lg" isBold>
          Your account
        </Typography>
        <Typography size="medium-paragraph">
          Logged in as {fullName}{' '}
          <LinkButton onClick={onLogoutClick}>Logout</LinkButton>
        </Typography>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="col-span-1">
          <TextInput
            required
            ref={register({
              ...requiredValidation('first name'),
            })}
            label="First name"
            name="firstName"
            placeholder="First name"
            errorMessage={errors?.firstName?.message}
          />
        </div>
        <div className="col-span-1">
          <TextInput
            required
            ref={register({
              ...requiredValidation('last name'),
            })}
            label="Last name"
            name="lastName"
            placeholder="Last name"
            errorMessage={errors?.lastName?.message}
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <TextInput
            required
            ref={register({
              ...requiredValidation('email'),
              ...emailIsValid,
            })}
            label="Email"
            name="email"
            placeholder="Email"
            errorMessage={errors?.email?.message}
            disabled
          />
        </div>
        <div className="col-span-1 md:col-span-2">
          <TextInput
            required
            ref={register({
              ...requiredValidation('phone'),
            })}
            label="Mobile number"
            name="phone"
            placeholder="Phone"
            errorMessage={errors?.phone?.message}
          />
        </div>
      </div>
    </Card>
  );
};
