import { useTheme } from '@chakra-ui/react';

/* Processes a brand's theme extensions. Returns the value of the last matching
 * key, or a defaultValue, or undefined.
 *
 * For example, passing ['globals.defaultVariant', 'button.defaultVariant']
 * returns the value associated with `button.defaultVariant` if both keys are
 * defined in the brand's extensions.
 */
export const useThemeExtension = <T = string>(
  keys: string | [string, ...string[]],
  defaultValue: T,
): T => {
  const theme = useTheme();

  // Single key
  if (typeof keys === 'string') {
    // Access values using dot notation
    // Inspired by https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
    const value = keys
      .split('.')
      .reduce((a, b) => (a && a[b]) ?? undefined, theme.extensions);
    if (value) {
      return value;
    }
  } else {
    // Multiple keys, checked in reverse order
    for (let i = keys.length - 1; i >= 0; i--) {
      const value = keys[i]
        .split('.')
        .reduce((a, b) => (a && a[b]) ?? undefined, theme.extensions);
      if (value) {
        return value;
      }
    }
  }

  return defaultValue;
};
