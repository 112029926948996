import { gql } from '@apollo/client';

export const discountCodeFormFragment = gql`
  fragment DiscountCodeForm on DiscountCode {
    id
    code
    amount
    stages
    type
    products {
      id
      variants {
        id
      }
    }
  }
`;
