import { gql, useMutation } from '@apollo/client';
import {
  UpdatePathologyCustomerStatedCollectedAtMutation,
  UpdatePathologyCustomerStatedCollectedAtMutationHookResult,
  UpdatePathologyCustomerStatedCollectedAtMutationOptions,
  UpdatePathologyCustomerStatedCollectedAtMutationVariables,
} from '@customer-frontend/graphql-types';

const updatePathologyCustomerStatedCollectedAtDocument = gql`
  mutation UpdatePathologyCustomerStatedCollectedAt(
    $collectedAt: String!
    $pathologyRequestId: String!
  ) {
    updatePathologyCustomerStatedCollectedAt(
      collectedAt: $collectedAt
      pathologyRequestId: $pathologyRequestId
    ) {
      id
      customerStatedCollectedAt
    }
  }
`;

export function useUpdatePathologyCustomerStatedCollectedAt(
  options?: UpdatePathologyCustomerStatedCollectedAtMutationOptions,
): UpdatePathologyCustomerStatedCollectedAtMutationHookResult {
  return useMutation<
    UpdatePathologyCustomerStatedCollectedAtMutation,
    UpdatePathologyCustomerStatedCollectedAtMutationVariables
  >(updatePathologyCustomerStatedCollectedAtDocument, options);
}
