import {
  getLinkButtonVariants,
  getListButtonVariants,
  getToggleTabsVariants,
} from '../../shared/components';
import { colors } from '../palette';

export const button = {
  baseStyle: {
    fontWeight: 700,
    borderRadius: '0.25rem', // 4px
    _focus: {
      outline: 'none',
      boxShadow: `0 0 0 0.188rem ${colors.tertiary.lavender}`, // 3px
    },
    _disabled: {
      opacity: 0.3,
    },
  },
  sizes: {
    sm: {
      h: '2.125rem', // 34px
      px: '1rem', // 16px
    },
    md: {
      h: '3rem', // 48px
      px: '1.5rem', // 24px
    },
    lg: {
      h: '3.5rem', // 56px
      px: '1.5rem', // 24px
    },
  },
  variants: {
    primary: {
      color: 'white',
      bg: colors.secondary.electric[100],
      _hover: {
        bg: colors.secondary.electric[200],
        _disabled: {
          bg: colors.secondary.electric[100],
        },
      },
      _focus: {
        bg: colors.secondary.electric[200],
      },
    },
    'primary-alternate': {
      color: colors.secondary.cream[100],
      bg: colors.primary[500],
      _hover: {
        bg: colors.primary[600],
        _disabled: {
          bg: colors.primary[500],
        },
      },
      _focus: {
        bg: colors.primary[600],
      },
    },
    'primary-white': {
      color: colors.secondary.electric[100],
      bg: 'white',
      _hover: {
        _disabled: {
          bg: 'white',
        },
      },
      _focus: {
        boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
      },
    },
    secondary: {
      color: colors.secondary.electric[100],
      bg: 'transparent',
      border: `0.063rem solid ${colors.secondary.electric[100]}`, // 1px
      _hover: {
        bg: colors.tertiary.palePurple,
      },
      _focus: {
        bg: colors.tertiary.palePurple,
      },
    },
    'secondary-alternate': {
      color: colors.primary[500],
      bg: 'transparent',
      border: `0.063rem solid ${colors.primary[500]}`, // 1px
      _hover: {
        bg: colors.tertiary.palePurple,
      },
      _focus: {
        bg: colors.tertiary.palePurple,
        boxShadow: `0 0 0 0.188rem ${colors.tertiary.lavender}`, // 3px
      },
    },
    'secondary-white': {
      color: 'white',
      bg: 'transparent',
      border: `0.063rem solid white`, // 1px
      _hover: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
      _focus: {
        bg: 'rgba(255, 255, 255, 0.3)',
        boxShadow: `0 0 0 0.188rem ${colors.primary[200]}`, // 3px
      },
      _disabled: {
        bg: 'rgba(255, 255, 255, 0.3)',
      },
    },
    ...getLinkButtonVariants({
      default: {
        color: colors.secondary.electric[200],
        hoverColor: colors.secondary.electric[300],
        focusColor: colors.primary[300],
      },
      alternate: {
        color: colors.primary[500],
        hoverColor: colors.primary[600],
        focusColor: colors.primary[300],
      },
      white: {
        color: colors.tertiary.lilac,
        hoverColor: colors.tertiary.palePurple,
        focusColor: colors.primary[200],
      },
    }),
    ...getListButtonVariants({
      textColor: colors.primary[600],
      focusColor: colors.primary[200],
      focusBackground: colors.primary[100],
      border: {
        default: colors.primary[300],
        hover: colors.primary[500],
        active: colors.primary[500],
        error: colors.status.error[500],
      },
      background: colors.neutral.white,
    }),
    ...getToggleTabsVariants({
      focusColor: colors.primary[200],
      fontColor: colors.primary[500],
      selectedColor: colors.primary[100],
      borderColor: {
        default: colors.primary[300],
        selected: colors.primary[500],
      },
    }),
  },
};
