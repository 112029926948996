import React from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { QuestionProps } from './types';
import { Checkbox, Typography } from '@eucalyptusvc/design-system';
import { QuestionPopup } from '../question-popup';
import { getConfig } from '@customer-frontend/config';

function sortOptions<T extends { position?: number | null }>(
  options: T[] = [],
): T[] {
  return [...options] //Prisma array type is readonly so it needs to be spread into new array
    .sort((a, b) => {
      if (a?.position && b?.position) {
        return a.position - b.position;
      }
      return 0;
    });
}

export function CheckboxQuestionInput({
  question,
  isLoading,
}: QuestionProps): React.ReactElement {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const isPilot = config.brand === 'pilot';
  const { register, watch, errors } = useFormContext();
  const { required } = question;
  const options = sortOptions(question.questionOptions);
  const errorMessage = errors[question.id]?.message;

  return (
    <div
      className={clsx({
        'border-2 rounded-lg -m-2 p-2': errorMessage,
        'border-red-100 text-red-100': isPilot,
        'border-red-700 text-red-700': !isPilot,
      })}
    >
      {options.map(
        ({ option, id, popup }) =>
          option && (
            <div key={id} className="mb-3 last:mb-0">
              <Checkbox
                showBox
                disabled={isLoading}
                name={question.id}
                label={option}
                value={id}
                ref={register({
                  validate: {
                    required: function (
                      currentValue: string[],
                    ): boolean | string {
                      if (
                        required &&
                        (!currentValue || currentValue.length === 0)
                      ) {
                        return formatMessage({
                          defaultMessage: 'Please select at least one option',
                          description:
                            'Error message asking the user to ensure they select an option',
                        });
                      }
                      return true;
                    },
                  },
                })}
                iconRight={popup && <QuestionPopup inline {...popup} />}
                isChecked={watch()[question.id]?.includes(option)}
              />
            </div>
          ),
      )}
      {errorMessage && (
        <Typography size="small-text" inheritColor>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
}
