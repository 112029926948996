export const OTHER_DELIVERY_INSTRUCTIONS = 'Other';

export const DELIVERY_OPTIONS = [
  'Leave in a safe place',
  'Leave at front door',
  'Leave at back door',
  'Leave at front gate',
  'Leave with reception',
];

export const DELIVERY_DROPDOWN_OPTIONS = [
  ...DELIVERY_OPTIONS,
  OTHER_DELIVERY_INSTRUCTIONS,
].map((option) => ({ label: option, value: option }));
