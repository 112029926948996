import { gql } from '@apollo/client';

export const completeItemMutationDocument = gql`
  mutation CompleteChecklistItem($code: String!, $checklistId: String!) {
    completeChecklistItem(code: $code, checklistId: $checklistId) {
      id
      completed
    }
  }
`;

export const uncompleteItemMutationDocument = gql`
  mutation UnCompleteChecklistItem($code: String!, $checklistId: String!) {
    unCompleteChecklistItem(code: $code, checklistId: $checklistId) {
      id
      completed
    }
  }
`;
