import { Brand } from '@customer-frontend/types';
import React, { useContext } from 'react';
import { getBrandTheme, ThemeContext } from './theme';

const WeightDashboardThemeContext = React.createContext<ThemeContext>({});

export const useWeightDashboardTheme = (): ThemeContext =>
  useContext(WeightDashboardThemeContext);

export const WeightDashboardThemeProvider = ({
  children,
  brand,
}: {
  children: React.ReactNode;
  brand: Brand;
}): React.ReactElement => {
  return (
    <WeightDashboardThemeContext.Provider
      value={{
        brand: getBrandTheme(brand),
      }}
    >
      {children}
    </WeightDashboardThemeContext.Provider>
  );
};
