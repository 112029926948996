import { gql } from '@apollo/client';

export const customerAttributesFragment = gql`
  fragment CustomerAttributesInfo on CustomerAttributes {
    id
    birthday
    age
    conditions
    medications
    allergies
    height
    weight
    bmi
    sex
    smoking
    diabetesRisk {
      diabetesRiskLevel
      diabetesRiskProbability
    }
    menopause {
      menopauseSymptoms
    }
    mentalHealthConditions
    pregnancy {
      pregnant
    }
    hair {
      familyHistoryHairLoss
    }
  }
`;
