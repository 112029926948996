import { Maybe } from '@customer-frontend/graphql-types';
import { PrescriberType, TitleType } from '../types';
import { useIntl } from 'react-intl';
import { getConfig } from '@customer-frontend/config';

interface DoctorFullClinicianName {
  fullName?: Maybe<string>;
  fullClinicianName: string;
  shortClinicianName: string;
}

const collapseSpaces = (s: string): string => s.replace(/\s\s+/g, ' ').trim();

export const formatDoctorName = (
  doctor: DoctorFullClinicianName,
  titleType: TitleType,
): string => {
  const doctorName = buildDoctorName(doctor, titleType);
  return collapseSpaces(doctorName);
};

const buildDoctorName = (
  doctor: DoctorFullClinicianName,
  titleType: TitleType,
): string => {
  switch (titleType) {
    case PrescriberType.PRESCRIBER:
      return `Your prescriber (${doctor.fullName})`;
    case PrescriberType.DOCTOR:
      return doctor.fullClinicianName;
    case TitleType.JUST_FULL_NAME: {
      if (!doctor.fullName) {
        throw new Error(`clinician does not have a full name available`);
      }
      return doctor.fullName;
    }
    case TitleType.DOCTOR_LAST_NAME:
      return doctor.shortClinicianName;
  }
};

export const formatDoctorNameWithPossesive = (
  doctor?: DoctorFullClinicianName | null,
): string => {
  if (doctor) {
    return formatDoctorName(doctor, PrescriberType.DOCTOR);
  } else {
    return `Your practitioner`;
  }
};

export const formatFirstNameWithPossesive = (
  firstName?: string | null,
): string => {
  if (firstName) {
    return firstName + `'s`;
  } else {
    return 'Your';
  }
};

export const usePresciberNameFromDoctor = (
  doctor?: {
    fullName?: Maybe<string>;
    fullClinicianName: string;
  } | null,
): string => {
  const { formatMessage } = useIntl();
  const config = getConfig();
  if (!doctor) {
    return formatMessage(
      {
        defaultMessage:
          'Your {isPrescriber, select, true {prescriber} other {practitioner}}',
      },
      {
        isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
      },
    );
  }

  const prescriberName = formatMessage(
    {
      defaultMessage: 'Your prescriber ({name})',
      description: 'Referring to the users prescriber using their real name',
    },
    {
      name: doctor.fullName,
    },
  );

  return config.prescriberType === PrescriberType.PRESCRIBER
    ? prescriberName
    : doctor.fullClinicianName;
};
