import React, { PropsWithChildren } from 'react';
import {
  Box,
  UnorderedList,
  OrderedList,
  ListItem as ChakraListItem,
} from '@chakra-ui/react';
import { Typography } from '../typography';
import { LinkComponentProps, MarkdownProps } from './types';

const getMarginProps = (margin: string, index?: number): { mt?: string } => {
  return { mt: index === 0 ? undefined : margin };
};

export function Image({
  src,
  alt,
}: {
  src?: string;
  alt?: string;
}): React.ReactElement {
  return (
    <img
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      alt={alt}
      src={src}
    />
  );
}

export function Link({
  linkColor,
  linkTarget = 'self',
  ...rest
}: PropsWithChildren<LinkComponentProps>): React.ReactElement {
  return (
    <a
      style={{ color: linkColor, textDecoration: 'underline' }}
      target={linkTarget === 'blank' ? '_blank' : '_self'}
      rel={linkTarget === 'blank' ? 'noopener noreferrer' : undefined}
      {...rest}
    />
  );
}

export function Paragraph({
  children,
  formatWhitespace,
  index,
  inheritColor,
  textSize = 'medium-paragraph',
}: PropsWithChildren<{
  formatWhitespace: boolean;
  index?: number;
  inheritColor?: boolean;
  textSize?: MarkdownProps['textSize'];
}>): React.ReactElement {
  const marginProps = formatWhitespace ? getMarginProps('1rem', index) : {};

  return (
    <Box {...marginProps}>
      <Typography size={textSize} inheritColor={inheritColor}>
        <span style={{ lineHeight: '140%', wordBreak: 'break-word' }}>
          {children}
        </span>
      </Typography>
    </Box>
  );
}

export function Header({
  children,
  size,
  index,
  inheritColor,
}: {
  children: React.ReactNode;
  size: 'xs' | 'sm' | 'md' | 'lg';
  index?: number;
  inheritColor?: boolean;
}): React.ReactElement {
  const marginProps = getMarginProps('1.5rem', index);

  return (
    <Box {...marginProps}>
      <Typography size={size} inheritColor={inheritColor}>
        {children}
      </Typography>
    </Box>
  );
}

export function List({
  children,
  type,
  index,
}: {
  children: React.ReactNode;
  type: 'ordered' | 'unordered';
  index?: number;
}): React.ReactElement {
  const marginProps = getMarginProps('1rem', index);

  return type === 'ordered' ? (
    <OrderedList spacing={1} {...marginProps} ml={'1.2rem'}>
      {children}
    </OrderedList>
  ) : (
    <UnorderedList spacing={1} {...marginProps} ml={'1.2rem'}>
      {children}
    </UnorderedList>
  );
}

export function ListItem({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <ChakraListItem>
      <Typography size="paragraph" inheritColor>
        {children}
      </Typography>
    </ChakraListItem>
  );
}
