import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { FeatureFlagBoolean } from '@customer-frontend/feature-flags';
import {
  Maybe,
  WeightTreatmentCategory,
} from '@customer-frontend/graphql-types';

import { useSubmitPatientPharmacistQuestion } from '@customer-frontend/services';
import {
  Button,
  RadioButtonGroup,
  TextArea,
  Typography,
} from '@eucalyptusvc/design-system';
import { ReactComponent as Tick } from '../../assets/tick.svg';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

type PrescriberQuestionFormFields = {
  prescriberQuestion: string;
  contactMethod: string;
};

export function PrescriberQuestionForm({
  treatment,
}: {
  treatment: {
    id: string;
    weightTreatmentCategory?: Maybe<WeightTreatmentCategory>;
  };
}): React.ReactElement | null {
  const [enableSend, setEnableSend] = useState(true);
  const [questionSent, setQuestionSent] = useState(false);

  const config = getConfig();
  const { formatMessage } = useIntl();
  const { register, handleSubmit, watch, control } =
    useForm<PrescriberQuestionFormFields>({
      shouldUnregister: false,
    });

  const onSubmit = handleSubmit(async (data) => {
    await submitPharmacyQuestionMutation({
      variables: {
        treatmentId: treatment.id,
        contactMethod: data.contactMethod === 'email' ? 'EMAIL' : 'PHONE',
        messageText: data.prescriberQuestion,
      },
    });
  });

  const [submitPharmacyQuestionMutation, { loading }] =
    useSubmitPatientPharmacistQuestion({
      onCompleted: () => {
        setQuestionSent(true);
      },
      onError: () => {
        setQuestionSent(false);
      },
      context: {
        skipErrorNotification: true,
      },
    });

  useEffect(() => {
    const { contactMethod, prescriberQuestion } = watch();
    setEnableSend(!!prescriberQuestion && !!contactMethod);
  }, [enableSend, watch]);

  const isOzempicProduct = treatment.weightTreatmentCategory === 'ozempic';

  if (!config.clinicianMessagingSupported) {
    return null;
  }

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      {questionSent && (
        <div className="bg-primary-100 p-2 mt-4 flex space-x-2 items-start">
          <span>
            <Tick />
          </span>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="Thanks! Your message has been sent. You may still continue with payment. The {isPrescriber, select, true {prescriber} other {practitioner}} will respond within 24 hours. If you are experiencing a medical emergency, please call {emergencyPhoneNumber}."
              values={{
                isPrescriber:
                  config.prescriberType === PrescriberType.PRESCRIBER,
                emergencyPhoneNumber: config.emergencyNumber,
              }}
            />
          </Typography>
        </div>
      )}
      {!questionSent && (
        <div className="space-y-3">
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Any questions for {isOzempicProduct, select, true {your Medical Support team} other {the pharmacy}}?"
              description="A question asking if the user has any questions for the pharmacy"
              values={{
                isOzempicProduct,
              }}
            />
          </Typography>
          {isOzempicProduct ? (
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Juniper's Medical Support team of nurses and pharmacists can answer any questions you have about the medication. The team will get back to your questions via email."
                description="Text informing the user that Juniper's Medical Support will respond to their questions via email"
              />
            </Typography>
          ) : (
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="Please note that your {isPrescriber, select, true {prescriber} other {practitioner}} will receive information about your allergies, medical conditions and medications to help them answer your question."
                description="Text informing the user that the prescriber will receive their information on"
                values={{
                  isPrescriber:
                    config.prescriberType === PrescriberType.PRESCRIBER,
                }}
              />
            </Typography>
          )}
          <FeatureFlagBoolean flagKey="PT_866_PHARMACY_COPY">
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="If you do not have a question to ask, but would like to receive additional counselling via a phone call from our pharmacists, simply type “yes” in the free text field below." />
            </Typography>
          </FeatureFlagBoolean>
          <TextArea
            name="prescriberQuestion"
            placeholder={formatMessage({
              defaultMessage: 'Type your response here',
            })}
            ref={register}
            rows={4}
          />
          {!isOzempicProduct && (
            <Controller
              control={control}
              name="contactMethod"
              as={
                <RadioButtonGroup
                  name="contactMethod"
                  label={formatMessage({
                    defaultMessage: 'I want to receive a response by',
                    description: 'Label for contact method selection',
                  })}
                  options={[
                    {
                      label: formatMessage({
                        defaultMessage: 'Email',
                      }),
                      value: 'email',
                    },
                    {
                      label: formatMessage({
                        defaultMessage: 'Phone',
                      }),
                      value: 'phone',
                    },
                  ]}
                />
              }
            />
          )}
          <div className="space-y-3 mt-4">
            <Button
              isSubmit
              isFullWidth
              level="secondary"
              isLoading={loading}
              isDisabled={!enableSend || loading}
            >
              <FormattedMessage defaultMessage="Send message" />
            </Button>
          </div>
        </div>
      )}
    </form>
  );
}
