import React, { PropsWithChildren } from 'react';

import { Environment } from './type';

export const EnvironmentContext = React.createContext<
  Readonly<Environment> | undefined
>(undefined);

/**
 * Use this to access environment variables. Brand specific config that is consistent within environments of that brand should live in `@customer-frontend/config`.
 */
export const useEnvironment = (): Readonly<Environment> => {
  const environment = React.useContext(EnvironmentContext);
  if (!environment) {
    throw new Error(`EnvironmentProvider is missing`);
  }
  return environment;
};

export const EnvironmentProvider: React.FC<PropsWithChildren<Environment>> = ({
  children,
  ...environment
}) => {
  return (
    <EnvironmentContext.Provider value={environment}>
      {children}
    </EnvironmentContext.Provider>
  );
};
