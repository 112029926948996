import { Typography } from '@eucalyptusvc/design-system';
import { useEducationTheme } from '../../';
import { VFC } from 'react';
import { NextReadingCardTemplate } from './next-reading-card-template';
import { FormattedMessage } from 'react-intl';

export const ReadingsFinished: VFC = () => {
  const theme = useEducationTheme();
  const cardTheme = theme.brand.nextReadingDashboardCard;

  return (
    <NextReadingCardTemplate
      header={
        <>
          {cardTheme?.readStatusIcon.read}
          <Typography size="md" isBold>
            <FormattedMessage
              defaultMessage="You've earned a break"
              description="Message to patient that they've earned a break from reading education articles"
            />
          </Typography>
        </>
      }
    >
      <Typography size="medium-paragraph">
        <FormattedMessage defaultMessage="You've completed all of your recommended readings. We'll let you know when more become available." />
      </Typography>
    </NextReadingCardTemplate>
  );
};
