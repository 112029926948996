import { Box, Img } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, ButtonPalette } from '../button';
import { Typography } from '../typography';
import { ReviewImageProps } from './types';
import { useThemeExtension } from '../../theme/shared';
import { ImageInstructions } from './image-instructions';

export const ReviewImage = ({
  styles,
  isLoading,
  reviewInstructions,
  hasSubmit,
  imgUrl,
  onRetake,
}: ReviewImageProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const textColor = useThemeExtension<string>('image-upload.textColor', '#000');

  const submitButtonPalette = useThemeExtension<ButtonPalette>(
    'image-upload.submitButtonPalette',
    'default',
  );

  const retakeButtonPalette = useThemeExtension<ButtonPalette>(
    'image-upload.retakeButtonPalette',
    'default',
  );

  return (
    <Box sx={styles.sectionContainer}>
      <Typography size="md" isBold textAlign="center">
        <FormattedMessage defaultMessage="Review and share photo" />
      </Typography>
      <Box sx={styles.imageActionContainer}>
        <Img
          sx={styles.image}
          src={`${imgUrl}`}
          alt={formatMessage({ defaultMessage: 'review image' })}
        />
        <div>
          <Box sx={styles.instructions}>
            <ImageInstructions
              textColor={textColor}
              reviewInstructions={reviewInstructions}
            />
          </Box>
          <Box sx={styles.actions}>
            {hasSubmit && (
              <Button
                palette={submitButtonPalette}
                isLoading={isLoading}
                isDisabled={isLoading}
                isSubmit
                isFullWidth
              >
                <FormattedMessage
                  defaultMessage="Confirm"
                  description="Button label for retaking a photo"
                />
              </Button>
            )}
            <Button
              palette={retakeButtonPalette}
              level="secondary"
              isFullWidth
              isDisabled={isLoading}
              onClick={onRetake}
            >
              <FormattedMessage
                defaultMessage="Retake photo"
                description="Button label for retaking a photo"
              />
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
};
