import React from 'react';
import {
  Card,
  CardPalette,
  Divider,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { ReactComponent as Star } from '../../assets/icons/star.svg';
import { ReactComponent as Cart } from '../../assets/icons/shipping-cart.svg';
import { ReactComponent as DoctorHat } from '../../assets/icons/doctor-hat.svg';
import { ReactComponent as ThumbsUp } from '../../assets/icons/thumbs-up.svg';
import { ReactComponent as ChatBubble } from '../../assets/icons/chat-bubble.svg';
import { ReactComponent as Scale } from '../../assets/icons/scale.svg';
import { MedicationPreferenceProduct } from '../../utils';
import { getConfig } from '@customer-frontend/config';
import { formatCentsToCurrency } from '@customer-frontend/order';
import { ProblemType } from '@customer-frontend/graphql-types';

const getTreatmentItems = (
  problemType: ProblemType,
): {
  icon: React.ReactElement;
  text: { defaultMessage: string };
}[] => {
  const items = [
    {
      icon: <Cart />,
      text: defineMessage({
        defaultMessage: 'Free and discreet delivery',
      }),
    },
    {
      icon: <DoctorHat />,
      text: defineMessage({
        defaultMessage: 'Ongoing practitioner and medical support',
      }),
    },
  ];

  if (problemType === 'WEIGHT_LOSS') {
    items.push(
      {
        icon: <ChatBubble />,
        text: defineMessage({
          defaultMessage: '1:1 Health coaching in your pocket',
        }),
      },
      {
        icon: <ThumbsUp />,
        text: defineMessage({
          defaultMessage: 'Private Facebook community',
        }),
      },
    );
  }

  return items;
};

interface TreatmentDetailsProps {
  medicationPreferenceProduct: MedicationPreferenceProduct;
  palette?: CardPalette;
  isMultipleMedication?: boolean;
  problemType: ProblemType;
  isSyncConsult?: boolean;
  isUpfrontPayment?: boolean;
}

export const TreatmentDetails: React.FC<TreatmentDetailsProps> = ({
  medicationPreferenceProduct,
  palette,
  isMultipleMedication,
  problemType,
  isSyncConsult,
  isUpfrontPayment,
}) => {
  const config = getConfig();
  const { formatMessage } = useIntl();
  const treatmentItems = getTreatmentItems(problemType);
  const showAsterisk = isMultipleMedication || isUpfrontPayment;

  let treatmentTitle = medicationPreferenceProduct.friendlyName;

  if (problemType === 'WEIGHT_LOSS') {
    if (isMultipleMedication) {
      treatmentTitle = formatMessage({
        defaultMessage: 'Weight Reset Program with medication',
      });
    } else {
      treatmentTitle = formatMessage({
        defaultMessage:
          'Weight Reset Program with Ozempic, Semaglutide, or other available medications',
      });
    }
  }

  return (
    <Card palette={palette}>
      <div className="flex flex-col space-y-4">
        <Typography size="md" isBold>
          <FormattedMessage defaultMessage="Your program" />
        </Typography>
        <div className="flex space-x-4">
          {medicationPreferenceProduct.photo?.url && (
            <img
              src={medicationPreferenceProduct.photo?.url}
              className="rounded h-20 w-20 bg-primary-200"
            />
          )}
          <div className="flex flex-col justify-center gap-1">
            <Typography isBold size="medium-paragraph">
              {treatmentTitle}
            </Typography>
            <div className="flex gap-1 items-center">
              <Typography isBold size="xs">
                {formatCentsToCurrency(
                  medicationPreferenceProduct.variants[0].price,
                )}
              </Typography>
              <Typography size="paragraph">
                <FormattedMessage
                  defaultMessage="for your first order{showAsterisk, select, true {*} other {}}"
                  values={{
                    showAsterisk,
                  }}
                />
              </Typography>
            </div>
            {showAsterisk && (
              <div>
                <Typography size="paragraph">
                  <FormattedMessage defaultMessage="*If the prescribed medication is different to this amount, we'll organise a refund or additional payment to correct any discrepancies." />
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          {treatmentItems.map(({ icon, text }) => (
            <div className="flex items-center gap-2" key={formatMessage(text)}>
              {icon}
              <Typography size="medium-paragraph">
                {formatMessage(text)}
              </Typography>
            </div>
          ))}
          {config.hasMobileApp && (
            <div className="flex items-center gap-2">
              <Star />
              <Typography size="medium-paragraph">
                {formatMessage({
                  defaultMessage: 'Members-only app',
                })}
              </Typography>
            </div>
          )}
          {config.scalesProvided && (
            <div className="flex items-center gap-2">
              <Scale />
              <Typography size="medium-paragraph">
                {formatMessage({
                  defaultMessage: 'Free digital scale with your first order',
                })}
              </Typography>
            </div>
          )}
        </div>
        {isSyncConsult ? (
          <>
            <Divider variant="separator" />
            <Typography size="paragraph">
              <FormattedMessage defaultMessage="If you’re found unsuitable during your consult or decide not to proceed with our program after your practitioner call, we will refund you the amount in full." />
            </Typography>
          </>
        ) : (
          <Card palette="default" size="sm">
            <div className="mb-2">
              <Typography size="medium-paragraph" isBold>
                <FormattedMessage defaultMessage="A message about suitability" />
              </Typography>
            </div>
            <Typography size="paragraph">
              <FormattedMessage defaultMessage="After payment, your practitioner will assess whether you're suitable for medication. If you're not suitable, you'll be fully refunded." />
            </Typography>
          </Card>
        )}
      </div>
    </Card>
  );
};
