import React from 'react';
import { EducationReading } from '@customer-frontend/graphql-types';
import { useEducationTheme } from '../../provider';

interface ReadStatusIconProps {
  status: EducationReading['readStatus'];
}

export const ReadStatusIcon = ({
  status,
}: ReadStatusIconProps): React.ReactElement | null => {
  const theme = useEducationTheme();
  const { unread, read, inProgress } =
    theme.brand.moduleAccordionItem?.listItem.readStatusIcon ?? {};

  let icon: JSX.Element | undefined;

  switch (status) {
    case 'IN_PROGRESS':
      icon = inProgress;
      break;
    case 'UNREAD':
      icon = unread;
      break;
    case 'READ':
      icon = read;
      break;
  }

  return icon ?? null;
};
