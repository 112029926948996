import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { sub } from 'date-fns';
import {
  combineRules,
  useRequiredValidation,
  useValidMaskedDate,
  useMaxMaskedDateExclusive,
  useMinMaskedDateInclusive,
} from '@customer-frontend/utils';
import { TextInput } from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';

type IntlDOBInputProps = {
  register: UseFormMethods<{ birthday: string }>['register'];
  errorMessage?: string;
};

const minDate = new Date('1900-01-01');
const maxDate = sub(new Date(), { years: 18 });

export const IntlDOBInput = ({
  register,
  errorMessage,
}: IntlDOBInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const {
    dateConfig: { type, placeholder },
  } = getConfig();

  const birthdayLabel = formatMessage({
    defaultMessage: 'Date of birth',
    description: 'Label for date field to enter date of birth',
  });
  const birthdayValidation = combineRules(
    useRequiredValidation(birthdayLabel),
    useValidMaskedDate(),
    useMaxMaskedDateExclusive(maxDate),
    useMinMaskedDateInclusive(minDate),
  );

  return (
    <TextInput
      name="birthday"
      label={birthdayLabel}
      ref={register(birthdayValidation)}
      errorMessage={errorMessage}
      placeholder={placeholder}
      type={type}
      inputMode="numeric"
    />
  );
};
