import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Modal,
  RadioButtonGroup,
  TextInput,
  Typography,
  useNotification,
} from '@eucalyptusvc/design-system';
import type {
  PausePurchaseMutation,
  PausePurchaseMutationVariables,
} from '@customer-frontend/graphql-types';

enum PauseDescription {
  LEFTOVER = 'leftover',
  SIDE_EFFECTS = 'sideEffects',
  NO_RESULTS = 'noResults',
  CURED = 'cured',
  TOO_EXPENSIVE = 'tooExpensive',
  BAD_SERVICE = 'badService',
  OTHER = 'other',
}

const pausePurchaseMutation = gql`
  mutation PausePurchase($input: PausePurchaseInput!) {
    pausePurchase(input: $input) {
      purchase {
        id
        status
      }
    }
  }
`;

type PausePurchaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  purchaseId: string;
  onComplete: () => void;
};

export const PausePurchaseModal = ({
  isOpen,
  onClose,
  onComplete,
  purchaseId,
}: PausePurchaseModalProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const notify = useNotification();
  const [pauseDescription, setPauseDescription] = useState('');
  const [otherPauseDescription, setOtherPauseDescription] = useState('');
  const [pausePurchase, { loading }] = useMutation<
    PausePurchaseMutation,
    PausePurchaseMutationVariables
  >(pausePurchaseMutation, {
    onCompleted: () => {
      notify.success({
        message: formatMessage({
          defaultMessage: 'Your subscription has been cancelled',
          description:
            'Success message on FAQs after the user selects to pause their purchase',
        }),
      });
      onComplete();
    },
    onError: () => {
      notify.error({
        message: formatMessage({
          defaultMessage:
            'Your request to cancel your subscription has failed. Please try again.',
          description:
            'Error message when user attempts to pause their purchase and fails',
        }),
      });
    },
    context: {
      skipErrorNotification: true,
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography isBold size="md">
          <FormattedMessage
            defaultMessage="Cancel your subscription"
            description="Modal title for confirming pausing purchase from FAQs"
          />
        </Typography>
      }
    >
      <div className="space-y-4">
        <RadioButtonGroup
          name="selectCancellationDescription"
          onChange={(value) => setPauseDescription(value)}
          spacing={2}
          stackDirection="column"
          options={[
            { label: 'I have some left', value: PauseDescription.LEFTOVER },
            { label: "I've reached my goal", value: PauseDescription.CURED },
            {
              label: 'Not getting the results I wanted',
              value: PauseDescription.NO_RESULTS,
            },
            {
              label: "I'm experiencing side effects",
              value: PauseDescription.SIDE_EFFECTS,
            },
            {
              label: "It's too expensive",
              value: PauseDescription.TOO_EXPENSIVE,
            },
            {
              label: 'Not satisfied with the service',
              value: PauseDescription.BAD_SERVICE,
            },
            { label: 'Other', value: PauseDescription.OTHER },
          ]}
          value={pauseDescription}
          showBox
        />
        {pauseDescription === PauseDescription.OTHER && (
          <TextInput
            value={otherPauseDescription}
            onChange={(e) => setOtherPauseDescription(e.target.value)}
          />
        )}
        <div className="space-x-4 flex justify-end">
          <Button
            onClick={() => {
              onClose();
            }}
          >
            <FormattedMessage
              defaultMessage="Cancel"
              description="Button text to cancel pausing purchase from FAQs"
            />
          </Button>
          <Button
            isSubmit
            isLoading={loading}
            isDisabled={!pauseDescription}
            onClick={() => {
              pausePurchase({
                variables: {
                  input: {
                    purchaseId,
                    description:
                      pauseDescription === PauseDescription.OTHER
                        ? otherPauseDescription ?? pauseDescription
                        : pauseDescription,
                  },
                },
              });
            }}
          >
            <FormattedMessage
              defaultMessage="Confirm"
              description="Button text to confirm pausing purchase from FAQs"
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
