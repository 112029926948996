import { gql, useQuery } from '@apollo/client';
import { TrackerQuery } from '@customer-frontend/graphql-types';
import { useHistory } from 'react-router-dom';

export const WEIGHT_LOSS_TRACKER_QUERY = gql`
  query Tracker {
    profile {
      id
      isBeingTreated(problemType: WEIGHT_LOSS)
    }
    tracker {
      id
      weightLoss {
        id
        lastCheckIn
        momentum
        waist {
          id
          value
          effectiveFrom
        }
        weight {
          id
          value
          effectiveFrom
        }
      }
    }
  }
`;

export const useWeightLossTracker = (
  redirectOnNotActivePatient: string,
): {
  data?: TrackerQuery['tracker']['weightLoss'];
  loading: boolean;
} => {
  const { data, loading } = useQuery<TrackerQuery>(
    WEIGHT_LOSS_TRACKER_QUERY,
    {},
  );

  const history = useHistory();
  if (data?.profile && !data.profile.isBeingTreated) {
    history.push(redirectOnNotActivePatient);
  }

  return { data: data?.tracker?.weightLoss, loading };
};
