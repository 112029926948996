import {
  DiscountType,
  ExperimentPaymentPlanVariant,
  Maybe,
  OtcScheduleCadence,
  ProductType,
} from '@customer-frontend/graphql-types';

import { calculateDiscountedPriceForProduct } from '../discounts';
import { calculatePaymentPlanChargeAmount } from '../experiment-payment-plan';
import { CartLineItem } from './types';

export const getLineItems = ({
  consultation,
  discount,
}: {
  consultation?: {
    id: string;
    isUpfrontPayment?: boolean | null;
    treatment?: Maybe<{
      id: string;
      experimentPaymentPlan?: Maybe<{
        id: string;
        paymentPlan: ExperimentPaymentPlanVariant;
        discountPercentage: number;
      }>;
      experimentAvailablePaymentPlans?: Maybe<
        {
          paymentPlan: ExperimentPaymentPlanVariant;
          discountPercentage: number;
          months: number;
        }[]
      >;
      otcSchedules: {
        cadence: OtcScheduleCadence;
        quantity: number;
        product: {
          id: string;
        };
        isActive: boolean;
      }[];
    }>;
    order?: Maybe<{
      id: string;
      lineItems?: Maybe<
        {
          id: string;
          quantity: number;
          variant: {
            price: number;
            product: {
              id: string;
              name: string;
              productType: ProductType;
              photo?: Maybe<{
                url: string;
              }>;
              shortDescription?: Maybe<string>;
            };
          };
        }[]
      >;
    }>;
  } | null;
  discount?: Maybe<{
    amount: number;
    type: DiscountType;
    products: {
      id: string;
    }[];
  }>;
}): CartLineItem[] => {
  if (!consultation) {
    return [];
  }

  const orderLineItems = consultation?.order?.lineItems;
  const otcSchedules = getActiveOtcScheduleProducts(consultation.treatment);
  const experimentPaymentPlan = consultation.treatment?.experimentPaymentPlan;

  return (
    orderLineItems?.map((lineItem) => {
      const isRecurring = otcSchedules.some(
        (product) =>
          product.id === lineItem.variant.product.id &&
          product.cadence === 'RECURRING',
      );

      let experimentPaymentPlanAmount: CartLineItem['experimentPaymentPlanAmount'];
      const isRecurringOrRx =
        isRecurring || lineItem.variant.product.productType === 'RX';
      if (experimentPaymentPlan && isRecurringOrRx) {
        experimentPaymentPlanAmount = {
          ...calculatePaymentPlanChargeAmount({
            recurringCharge: lineItem.variant.price,
            oneTimeCharge: 0,
            experimentPaymentPlan,
          }),
          months:
            consultation.treatment?.experimentAvailablePaymentPlans?.find(
              (p) => p.paymentPlan === experimentPaymentPlan.paymentPlan,
            )?.months ?? 0,
        };
      }

      const productSpecificDiscount = calculateDiscountedPriceForProduct({
        product: {
          id: lineItem.variant.product.id,
          price: lineItem.variant.price,
          quantity: lineItem.quantity,
        },
        discount,
      });

      return {
        id: lineItem.variant.product.id,
        name: lineItem.variant.product.name,
        price: lineItem.variant.price,
        productType: lineItem.variant.product.productType,
        photoUrl: lineItem.variant.product.photo?.url,
        shortDescription:
          lineItem.variant.product.shortDescription ?? undefined,
        isRecurring,
        experimentPaymentPlanAmount,
        productSpecificDiscount:
          productSpecificDiscount.amountSaved > 0
            ? productSpecificDiscount
            : undefined,
        quantity: lineItem.quantity,
      };
    }) ?? []
  );
};

export const isOtc = (lineItem: {
  productType?: ProductType | null;
}): boolean => lineItem.productType === 'OTC';

export const getSortedLineItems = (
  lineItems: CartLineItem[],
): CartLineItem[] => {
  return [...lineItems].sort((a) => {
    return isOtc(a) ? 1 : -1;
  });
};

export const getActiveOtcScheduleProducts = <T extends Record<string, unknown>>(
  treatment?: {
    otcSchedules: {
      cadence: OtcScheduleCadence;
      quantity: number;
      product: T;
      isActive: boolean;
    }[];
  } | null,
): (T & { cadence: OtcScheduleCadence; quantity: number })[] => {
  return (
    treatment?.otcSchedules
      ?.filter((sched) => sched.isActive)
      .map((sched) => ({
        ...sched.product,
        cadence: sched.cadence,
        quantity: sched.quantity,
      })) || []
  );
};
