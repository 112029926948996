import React from 'react';

export type BackButtonBehaviour = {
  callback: () => void;
};

export const SetBackButtonBehaviourContext = React.createContext<
  React.Dispatch<React.SetStateAction<BackButtonBehaviour | null>>
>(() => {
  /* do nothing */
});

export function useBackButtonBehaviour(callback: (() => void) | null): void {
  const setBackButtonBehaviour = React.useContext(
    SetBackButtonBehaviourContext,
  );

  React.useEffect(() => {
    if (callback) {
      setBackButtonBehaviour({ callback });
    } else {
      setBackButtonBehaviour(null);
    }

    return (): void => {
      setBackButtonBehaviour(null);
    };
  }, [setBackButtonBehaviour, callback]);
}
