import { useAuth } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import {
  MedicareAnswerOpted,
  MedicareCollectionForm,
  MedicareFormOnPage,
  saveMedicareAnsOptedToSS,
  useIHI,
} from '@customer-frontend/healthcare-collection';
import { getSecondaryButtonPalette } from '@customer-frontend/quiz';
import { useConsultation } from '@customer-frontend/services';
import {
  Button,
  CardPalette,
  LoadingSpinner,
  Modal,
  Typography,
  TypographySize,
} from '@eucalyptusvc/design-system';
import { ReactElement, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Layout } from './layout';
import { PageHeader } from './page-header';
import { useShowZendeskWidget } from '@customer-frontend/consultation';
import { getReadableBrandName } from '@customer-frontend/utils';

type CollectMedicarePageProps = {
  className?: string;
  cardPalette?: CardPalette;
  headerSize?: TypographySize;
  gotoNextRoute: () => void;
  secondaryTextColor?: string;
};

export const CollectMedicarePage = ({
  className,
  cardPalette,
  headerSize,
  gotoNextRoute,
  secondaryTextColor = 'text-secondary-navy-200',
}: CollectMedicarePageProps): ReactElement => {
  const { brand, title, supportEmail, helpArticles } = getConfig();
  const event = useEventService();
  const { loggedInUser } = useAuth();

  const [showMedicareRequiredModal, setShowMedicareRequiredModal] =
    useState<boolean>(false);
  const { consultationId } = useParams<{ consultationId: string }>();
  const consultation = useConsultation(consultationId);
  const isInitial = consultation?.data?.consultation.stage === 'INITIAL';
  const problemType = consultation?.data?.consultation.type;

  useShowZendeskWidget({ problemType });

  const { canSkipMedicareForm } = useIHI(problemType);

  const getCurrentPage = (): MedicareFormOnPage => {
    if (consultation?.data?.consultation.stage === 'REVIEW') {
      return MedicareFormOnPage.postReviewQuiz;
    } else if (consultation?.data?.consultation.stage === 'FOLLOW_UP') {
      return MedicareFormOnPage.postFollowUpQuiz;
    } else {
      return MedicareFormOnPage.postQuiz;
    }
  };

  const onSaveHandler = (areMedicareDetailsValid: boolean): void => {
    saveMedicareAnsOptedToSS(MedicareAnswerOpted.SubmittedMedicare);
    if (areMedicareDetailsValid) {
      gotoNextRoute();
    }
  };

  const onDontHaveHandler = (): void => {
    if (canSkipMedicareForm) {
      saveMedicareAnsOptedToSS(MedicareAnswerOpted.NoMedicare);
      event.medicare.collectionIneligible({
        userId: loggedInUser?.id,
        email: loggedInUser?.email,
        problemType,
        trigger: getCurrentPage(),
        consultationId,
      });
      gotoNextRoute();
    } else {
      setShowMedicareRequiredModal(true);
    }
  };

  const onLaterHandler = (): void => {
    saveMedicareAnsOptedToSS(MedicareAnswerOpted.GiveMedicareLater);
    gotoNextRoute();
  };
  const { formatMessage } = useIntl();

  if (consultation?.loading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Layout className={className}>
      <PageHeader
        title={formatMessage({
          defaultMessage: 'Confirm your identity',
        })}
        description={formatMessage(
          {
            defaultMessage:
              'Your {brand} practitioner requires your Medicare card to verify your identity before issuing a prescription.',
            description:
              'Explains why local healthcare details are required, specifically for electronic script generation',
          },
          {
            brand: getReadableBrandName(brand),
          },
        )}
      />
      <MedicareCollectionForm
        validationLevel="all"
        submitButtonText="Confirm details"
        onPage={MedicareFormOnPage.postQuiz}
        afterSubmit={onSaveHandler}
        cardPalette={cardPalette}
        headerSize={headerSize}
        problemType={problemType}
        secondaryTextColor={secondaryTextColor}
      />
      <div className="space-y-2">
        <Button
          level="secondary"
          palette={getSecondaryButtonPalette(brand)}
          isFullWidth
          onClick={onDontHaveHandler}
        >
          <FormattedMessage
            description="Label for button when patient does not have their public health insurance details with them"
            defaultMessage="I don’t have a Medicare card"
          />
        </Button>
        {isInitial && canSkipMedicareForm && (
          <>
            <Button isFullWidth onClick={onLaterHandler}>
              <FormattedMessage
                description="Label for button when patient will provide their public health details later"
                defaultMessage="Skip for now"
              />
            </Button>
          </>
        )}
      </div>
      <Modal
        isOpen={showMedicareRequiredModal}
        onClose={() => setShowMedicareRequiredModal(false)}
        title={
          <Typography size="md" isBold>
            {isInitial ? (
              <FormattedMessage
                defaultMessage="You can still join {brandTitle} without a Medicare card"
                values={{
                  brandTitle: title,
                }}
              />
            ) : (
              <FormattedMessage defaultMessage="You can still continue without a Medicare card" />
            )}
          </Typography>
        }
      >
        <div className="space-y-6">
          <div className="space-y-2">
            <Typography size="paragraph">
              <FormattedMessage
                defaultMessage=" We require your <strong>Individual Healthcare Identifier (IHI)</strong> to generate an electronic prescription if your practitioner prescribes your treatment. "
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </Typography>

            <Typography size="paragraph">
              {helpArticles?.ihiLinkingNoMedicare && (
                <FormattedMessage
                  defaultMessage="Please follow instructions <here>here</here> to find your IHI details. Once completed, you’ll need to contact us via the chat button on the bottom right corner of your screen or email us at <a>{supportEmail}</a> "
                  values={{
                    here: (chunks) => (
                      <a
                        className="text-link"
                        href={helpArticles.ihiLinkingNoMedicare}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    ),
                    a: (chunks) => (
                      <a className="text-link" href={`mailto:${chunks}`}>
                        {chunks}
                      </a>
                    ),
                    supportEmail,
                  }}
                />
              )}
            </Typography>
            <Typography size="paragraph">
              <FormattedMessage defaultMessage="We’ll assist you in linking your IHI with your Juniper account." />
            </Typography>
          </div>
          <div className="pt-4">
            <Button
              palette="alternate"
              onClick={() => setShowMedicareRequiredModal(false)}
              isFullWidth
            >
              <FormattedMessage defaultMessage="Got it" />
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
