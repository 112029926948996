import React from 'react';
import { Logger } from '@customer-frontend/logger';
import { EmptyChartState, EmptyChartStateVariant } from './empty-chart-state';
import { FormattedMessage } from 'react-intl';

type Props = {
  logger: Logger;
  children: React.ReactNode;
  variant?: EmptyChartStateVariant;
};

type State = {
  hasError: boolean;
  error?: Error;
};

export class ChartErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    this.props.logger.error(
      'Reached error boundary. Displaying "upgrade browser" message.',
      error,
    );
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <EmptyChartState
          title={
            <FormattedMessage defaultMessage="Please update your browser to view" />
          }
          body={
            <FormattedMessage defaultMessage="We're unable to display your progress chart. You'll need to update your web browser to the latest version." />
          }
          variant={this.props.variant}
        />
      );
    }

    return this.props.children;
  }
}
