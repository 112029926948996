import { colors } from './palette';
import { components } from './components';
import { BrandTheme } from '../types';

const theme: BrandTheme = {
  colors,
  components,
  extensions: {
    modal: {
      color: colors.primary[600],
    },
    'image-upload': {
      textColor: colors.secondary.cream[100],
      iconStyle: 'bold',
      placeholderColor: colors.primary[400],
      selectButtonStyle: 'primary',
      selectButtonPalette: 'white',
      submitButtonPalette: 'white',
      retakeButtonPalette: 'white',
    },
  },
};

export default theme;
