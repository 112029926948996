import { GqlErrorCode } from '@eucalyptusvc/errors';
import { defineMessages } from 'react-intl';

export const errorMessageDescriptors = defineMessages<string>({
  [GqlErrorCode.CANNOT_RESCHEDULE_ORDER]: {
    defaultMessage: 'Failed to reschedule order.',
    description:
      'Error message when there is a failure to reschedule their order',
  },
  [GqlErrorCode.CURRENT_PASSWORD_DOES_NOT_MATCH]: {
    defaultMessage: 'Current password does not match.',
    description:
      'Error message when a user is trying to update their password, but the current password they provided does not match their saved password',
  },
  [GqlErrorCode.EMAIL_ALREADY_IN_USE]: {
    defaultMessage:
      'Something went wrong. If you already have an account, please log in or if you have forgotten your password, reset your password.',
    description:
      'Error message when user attempts to sign up with an existing email address, that asks them to either try login or reset their password',
  },
  [GqlErrorCode.EMAIL_ALREADY_IN_USE_WITH_UNSET_PASSWORD]: {
    defaultMessage: 'Something went wrong. Please reset your password.',
    description:
      'Error message when user login fails that asks them to reset their password',
  },
  [GqlErrorCode.EMAIL_RESERVED_INTERNAL]: {
    defaultMessage:
      'Your Euc email is reserved for your admin account. Please use an alias by using a + after your name, like "first.last+test@eucalyptus.vc"',
    description:
      'Error message when an employee attempts to sign up with a non-aliased internal email',
  },
  [GqlErrorCode.INCORRECT_EMAIL_OR_PASSWORD]: {
    defaultMessage: 'Incorrect email or password',
    description: 'Error message when user login fails',
  },
  [GqlErrorCode.INTERNAL_SERVER_ERROR]: {
    defaultMessage: 'Something went wrong',
    description: 'Generic error message when a request fails',
  },
  [GqlErrorCode.INVALID_EMAIL]: {
    defaultMessage: 'Please enter a valid email address',
    description: 'Error message when user provides an invalid email address',
  },
  [GqlErrorCode.INVALID_MOBILE_NUMBER]: {
    defaultMessage: 'Please enter a valid phone number',
    description: 'Error message when user provides an invalid phone number',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_DATE_INVALID]: {
    defaultMessage: 'Please enter a valid date',
    description: 'Error message shown when user provides an invalid date',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_DATE_MUST_BE_PAST]: {
    defaultMessage:
      'Date may not occur in the future, please enter a date in the past',
    description:
      'Error message when user provides a date in the future, but it needs to in the past or present',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_HEIGHT_CENTIMETERS]: {
    defaultMessage: 'Please enter your height in centimeters',
    description:
      'Error message when user provides their height in a unit other than centimeters',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_HEIGHT_NUMBER]: {
    defaultMessage: 'Please enter a number for height',
    description:
      'Error message when user provides something that is not a number for their height',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_REQUIRED]: {
    defaultMessage: 'Answer is required',
    description:
      'Error message when user does not provide a required answer to a question',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_WAIST_CLOTHING_SIZE]: {
    defaultMessage: 'Waist size must be a number or a supported clothing size.',
    description:
      'Error message when user answers their waist size in an unsupported measurement unit',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_WAIST_INCHES]: {
    defaultMessage: 'Waist size must be in inches',
    description:
      'Error message when user provides an answer for their waist size that is not in inches',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_WAIST_NUMBER]: {
    defaultMessage: 'Waist size must be a number',
    description:
      'Error message when user provides an answer for their waist size that is not a number',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_WEIGHT_KILOGRAMS]: {
    defaultMessage: 'Weight must be in kilograms',
    description:
      'Error message when user provides an answer for their weight that is not in kilograms',
  },
  [GqlErrorCode.INVALID_QUIZ_ANSWER_WEIGHT_NUMBER]: {
    defaultMessage: 'Weight must be a number',
    description:
      'Error message when user provides an answer for their weight that is not a number',
  },
  [GqlErrorCode.PAYMENT_CARD_DECLINED]: {
    defaultMessage: 'Your payment method was declined.',
    description:
      'Error message when user tries to make a payment with a payment method that was declined',
  },
  [GqlErrorCode.PAYMENT_CARD_EXPIRED]: {
    defaultMessage: 'Your card has expired.',
    description:
      'Error message when user tries to make a payment with an expired card',
  },
  [GqlErrorCode.PAYMENT_CARD_INVALID]: {
    defaultMessage: 'Your card was invalid',
    description:
      'Error message when user tries to make a payment with an invalid card',
  },
  [GqlErrorCode.PAYMENT_CARD_NUMBER_INCORRECT]: {
    defaultMessage: 'Your card number is incorrect.',
    description:
      'Error message when user tries to make a payment but their card number was incorrect',
  },
  [GqlErrorCode.PAYMENT_INCORRECT_SECURITY_CODE]: {
    defaultMessage: "Your card's security code is incorrect.",
    description:
      'Error message when user tries to make a payment but the security code for their card number was incorrect',
  },
  [GqlErrorCode.PAYMENT_INSUFFICIENT_FUNDS]: {
    defaultMessage: 'Your payment method has insufficient funds.',
    description:
      'Error message when a user tries to make a payment but their payment method has insufficient funds',
  },
  [GqlErrorCode.PAYMENT_METHOD_NOT_ATTACHED]: {
    defaultMessage: 'Please add a payment method to your profile.',
    description:
      'Error message when there is no payment method attached to the users payment profile',
  },
  [GqlErrorCode.REFILL_COOLDOWN_PERIOD]: {
    defaultMessage:
      'Unable to refill treatment - You are still in the cooldown period',
    description:
      'Error message when user attempts to get their next shipment early, but they have recently gotten another shipment of the same medication early so we do not want them to have more medication yet',
  },
  [GqlErrorCode.REQUEST_PROCESSING]: {
    defaultMessage: 'Your request is being processed.',
    description:
      'Error message when the user attempts to send a duplicate request when we are already processing the same request (this can happen when they double click a confirm button for example).',
  },
  [GqlErrorCode.RESET_PASSWORD_LINK_EXPIRED]: {
    defaultMessage: 'The reset password link in your email has expired.',
    description:
      'Error message when user tries to reset their password, but the link has expired.',
  },
  [GqlErrorCode.SCRIPT_EXPIRED_FOLLOW_UP_CREATED]: {
    defaultMessage:
      'Your script has expired. Please complete a follow up consultation',
    description:
      'Error message when user tries to pay for an order but their script has expired. They will need to complete another consultation with the practitioner to get a new script',
  },
  [GqlErrorCode.SHORT_PASSWORD]: {
    defaultMessage: 'Password must be at least 8 characters',
  },
  [GqlErrorCode.TOO_MANY_ATTEMPTS]: {
    defaultMessage:
      'You have provided the incorrect details too many times. Please try again in 5 minutes. Alternatively, reset your password or contact support if the issue persists.',
    description:
      'Error message when user tries to login unsuccesfully too many times',
  },
  [GqlErrorCode.UNSET_PASSWORD]: {
    defaultMessage:
      'Something went wrong. If you already have an account, please log in or if you have forgotten your password, reset your password.',
    description:
      'Error message when user attempts to sign up with an existing email address, that asks them to either try login or reset their password',
  },
  [GqlErrorCode.ADDRESS_STATE_REQUIRED]: {
    defaultMessage: 'State is required.',
    description:
      'Error message when user attempts to input an address without a state, when it is required.',
  },
  [GqlErrorCode.WEAK_PASSWORD_SYMBOLS]: {
    defaultMessage:
      "Password requires at least one symbol (* @ ! # % & ( ) ^ ~ '{ }') and one uppercase letter",
  },
  [GqlErrorCode.WEAK_PASSWORD_WELL_KNOWN]: {
    defaultMessage:
      'Password does not meet the password policy due to being common and wellknown passwords.',
    description:
      'Error message when user attempts to sign up with a unsecure/commonly used password',
  },
  [GqlErrorCode.SHIPPING_ADDRESS_OUT_OF_RANGE]: {
    defaultMessage: 'Shipping address is out of range for delivery.',
    description:
      'Error message when user attempts to pay for order, but their shipping address is out of range for delivery.',
  },
});
