import { Typography } from '@eucalyptusvc/design-system';

import clsx from 'clsx';

type ProgressStepsProps = {
  lineColour: string;
  steps: Array<{
    key: string;
    complete: boolean;
    Icon: React.FC;
    title?: React.ReactElement;
    description?: React.ReactElement;
  }>;
};

export const ProgressSteps = ({
  steps,
  lineColour,
}: ProgressStepsProps): React.ReactElement => {
  return (
    <>
      {steps.map((step, index) => {
        const { key, Icon, title, description, complete } = step;
        const isLast = index === steps.length - 1;
        return (
          <div key={key} className="flex gap-4">
            <div>
              <Icon />
              {!isLast && (
                <div
                  style={{ borderColor: lineColour }}
                  className={clsx('w-0.5 ml-4 -mt-1 h-full border', {
                    'border-dashed': !complete,
                  })}
                />
              )}
            </div>
            <div className="mt-0.5 pb-8">
              <div>
                <Typography size="medium-paragraph" isBold>
                  {title}
                </Typography>
              </div>
              {description && (
                <div>
                  <Typography size="medium-paragraph">{description}</Typography>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
