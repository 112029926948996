import { Modal, Typography } from '@eucalyptusvc/design-system';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as InfoCircle } from '../assets/info-circle.svg';

export function TitrationExplainerModalButton(): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex items-center gap-2">
        <InfoCircle className="fill-current h-4 w-4 -mb-1" />
        <button type="button" onClick={() => setOpen(true)}>
          <Typography size="small-text" isBold decoration="underline">
            <FormattedMessage defaultMessage="Why does my dosage change?" />
          </Typography>
        </button>
      </div>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        title={
          <Typography isBold size="md">
            <FormattedMessage
              defaultMessage="Why does my dosage change?"
              description="Modal title explaining dosage changes"
            />
          </Typography>
        }
      >
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="The ingredients used by our pharmacists are low-risk in terms of potential side-effects, and you will always start on a lower dose to further mitigate this risk.{br}{br}The process of introducing the medication to your body is called titration. Titration is a way for clinicians to personalise medication doses so you can obtain the intended benefits of your treatment while minimising side effects.{br}{br}If you find that a lower dose is working to achieve a healthy weight, you may not need to move up to the higher dosages. You can speak to your prescriber about this"
            description="Modal body explaining dosage changes"
            values={{ br: <br /> }}
          />
        </Typography>
      </Modal>
    </>
  );
}
