import clsx from 'clsx';
import { Typography } from '@eucalyptusvc/design-system';
import type { PendingQuestionAlertProps } from './types';
import { getConfig } from '@customer-frontend/config';

export const PendingQuestionAlert = ({
  chatTheme,
  requiredAlertIcon,
  questionType,
  scrollToQuestion,
}: PendingQuestionAlertProps): JSX.Element => {
  const config = getConfig();

  const isPhotoQuestion =
    questionType === 'IMAGE' ||
    (config.brand === 'software' && questionType === 'OBSERVATION');

  return (
    <a
      className={clsx(
        'flex w-full p-4 gap-4 mt-4 items-center cursor-pointer fixed md:relative md:top-auto left-0 mb-6',
        chatTheme.requiredAlertBannerClasses
          ? chatTheme.requiredAlertBannerClasses
          : 'top-12 sm:top-16',
        chatTheme.requiredAlertClasses,
      )}
      onClick={scrollToQuestion}
    >
      <div className="w-6">{requiredAlertIcon && requiredAlertIcon}</div>
      <div className="flex flex-col gap-1">
        <Typography size="paragraph">
          {isPhotoQuestion
            ? 'Your practitioner requested photos. Photos are required to prescribe a treatment plan.'
            : 'Your practitioner requested an answer.'}
        </Typography>
        <Typography size="paragraph" isBold decoration="underline">
          {isPhotoQuestion ? 'Upload now' : 'Answer'}
        </Typography>
      </div>
    </a>
  );
};
