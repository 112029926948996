import { useMutation } from '@apollo/client';
import { MutationReopenApplicationArgs } from '@customer-frontend/graphql-types';
import { reopenApplication as reopenApplicationMutation } from '@customer-frontend/services';

export const useReopenApplication = (): {
  reopenApplication: (id: string) => void;
  loading: boolean;
} => {
  const [mutateFunction, { loading }] =
    useMutation<MutationReopenApplicationArgs>(reopenApplicationMutation);

  const reopenApplication = (id: string): ReturnType<typeof mutateFunction> => {
    const variables = { variables: { id } };
    return mutateFunction(variables);
  };

  return { reopenApplication, loading };
};
