import { getConfig } from '@customer-frontend/config';
import { useEnvironment } from '@customer-frontend/environment';
import { Typography } from '@eucalyptusvc/design-system';
import { useIntl } from 'react-intl';

const useFooterRoutes = (): {
  display: string;
  url: string;
}[] => {
  const environment = useEnvironment();
  const config = getConfig();
  const { formatMessage } = useIntl();

  return [
    {
      display: formatMessage({
        defaultMessage: 'About us',
        description: 'Navigation link to about us page',
      }),
      url: `${environment.landingPageUrl}${config.urlPaths.about}`,
    },
    {
      display: formatMessage({
        defaultMessage: 'Privacy policy',
        description: 'Navigation link to privacy policy page',
      }),
      url: `${environment.landingPageUrl}${config.urlPaths.privacyPolicy}`,
    },
    {
      display: formatMessage({
        defaultMessage: "T&C's",
        description: 'Navigation link, Acronym for terms & conditions page',
      }),
      url: `${environment.landingPageUrl}${config.urlPaths.termsAndConditions}`,
    },
  ];
};

const ProfileLayout = ({
  children,
}: React.PropsWithChildren<unknown>): React.ReactElement => {
  const footerRoutes = useFooterRoutes();

  return (
    <div className="flex flex-col h-full">
      <section className="flex-grow max-w-2xl my-8 sm:my-12 px-4 mx-auto w-full">
        {children}
      </section>
      <footer className="flex flex-none justify-between border-t border-primary-200 pt-2 px-4 pb-4 md:pb-16 flex-col md:flex-row">
        {footerRoutes.map((route) => (
          <div className="flex items-center" key={route.display}>
            {/* eslint-disable-next-line */}
            <span className="text-3xl">&bull;</span>
            <a
              className="mt-1 ml-1 underline text-primary-350"
              href={route.url}
            >
              <Typography element="span" size="medium-paragraph">
                {route.display}
              </Typography>
            </a>
          </div>
        ))}
      </footer>
    </div>
  );
};

export default ProfileLayout;
