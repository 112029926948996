import { gql, useQuery } from '@apollo/client';
import { SafetyInfo } from '@customer-frontend/consultation';
import {
  ProfileTreatmentPharmacyInformationPageQuery,
  ProfileTreatmentPharmacyInformationPageQueryVariables,
} from '@customer-frontend/graphql-types';
import { EscriptsConsent } from '@customer-frontend/order';
import {
  Card,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

const resolvePharmacyInformationData = ({
  prescribedSequence,
  treatment,
}: {
  prescribedSequence?: {
    sequence?: {
      __typename?: string;
      safetyInformation?: {
        cmiUrl: string;
        cmiCopyMarkdown: string;
      } | null;
    } | null;
  } | null;
  treatment?: {
    product: {
      cmi?: {
        url: string;
      } | null;
      safetyInformation?: string | null;
    };
  } | null;
}): {
  pharmacySafetyInformation?: {
    cmiUrl?: string;
    safetyInformation?: string;
  };
} => {
  if (!treatment) {
    return {};
  }

  if (
    prescribedSequence &&
    prescribedSequence.sequence?.__typename === 'PrescribableSequence'
  ) {
    return {
      pharmacySafetyInformation: {
        cmiUrl: prescribedSequence.sequence.safetyInformation?.cmiUrl,
        safetyInformation:
          prescribedSequence.sequence.safetyInformation?.cmiCopyMarkdown,
      },
    };
  }

  if (treatment) {
    return {
      pharmacySafetyInformation: {
        cmiUrl: treatment.product.cmi?.url,
        safetyInformation: treatment.product.safetyInformation ?? undefined,
      },
    };
  }

  return {};
};

export const TreatmentPharmacyInformationPageTemplate: React.FC = () => {
  const { treatmentId } = useParams<{ treatmentId: string }>();
  const resp = useQuery<
    ProfileTreatmentPharmacyInformationPageQuery,
    ProfileTreatmentPharmacyInformationPageQueryVariables
  >(
    gql`
      query ProfileTreatmentPharmacyInformationPage($id: String!) {
        treatment(id: $id) {
          id
          status
          product {
            id
            safetyInformation
            cmi {
              id
              url
            }
            variants {
              id
              inventory {
                id
                sku
              }
            }
          }
          consultation {
            id
            customer {
              id
              firstName
              healthCareDetails {
                id
                hasIndividualHealthcareIdentifier
              }
            }
            prescribedSequence {
              id
              sequence {
                id
                ... on PrescribableSequence {
                  safetyInformation {
                    id
                    cmiUrl
                    cmiCopyMarkdown
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: treatmentId,
      },
    },
  );

  const { pharmacySafetyInformation } = resolvePharmacyInformationData({
    prescribedSequence: resp.data?.treatment?.consultation?.prescribedSequence,
    treatment: resp.data?.treatment,
  });

  const consultation = resp.data?.treatment?.consultation;
  const customer = consultation?.customer;

  if (resp.loading || !resp.data) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="space-y-6 pb-6">
      <Typography isBold size="lg" element="h1">
        <FormattedMessage defaultMessage="A note from your pharmacy" />
      </Typography>
      <Card>
        <Typography isBold size="md">
          <FormattedMessage defaultMessage="Important safety information" />
        </Typography>
        <Divider variant="separator" mt="xs" />
        {pharmacySafetyInformation && (
          <div className="space-y-6">
            <SafetyInfo
              safetyInformation={pharmacySafetyInformation.safetyInformation}
              cmiUrl={pharmacySafetyInformation.cmiUrl}
            />
            {!!customer?.healthCareDetails
              ?.hasIndividualHealthcareIdentifier && <EscriptsConsent />}
          </div>
        )}
      </Card>
    </div>
  );
};
