import { type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  Typography,
  Divider,
  Markdown,
} from '@eucalyptusvc/design-system';
import {
  DoctorInfo,
  PrescriberType,
  TitleType,
} from '@customer-frontend/doctor';
import { getConfig } from '@customer-frontend/config';
import { OfferingDoctorLetterConsultationFragment } from '@customer-frontend/graphql-types';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { SendMessage } from './send-message';
import { AnyQuestions } from './any-questions';
import { gql } from '@apollo/client';

type DoctorLetterProps = {
  consultation: OfferingDoctorLetterConsultationFragment;
  profileRoute: string;
  showSendMessage?: boolean;
};

export function DoctorLetter({
  consultation,
  profileRoute,
  showSendMessage = false,
}: DoctorLetterProps): ReactElement {
  const config = getConfig();
  const { doctor, patientNotes } = consultation;

  return (
    <div>
      <Card>
        <section className="space-y-4">
          {patientNotes && (
            <>
              <Typography isBold size="md">
                <FormattedMessage
                  defaultMessage="Recommendations from your {isPrescriber, select, true {prescriber} other {practitioner}} around your treatment"
                  values={{
                    isPrescriber:
                      config.prescriberType === PrescriberType.PRESCRIBER,
                  }}
                />
              </Typography>

              <Divider variant="separator" mt="xs" />

              <div className="mb-8">
                <DoctorInfo
                  doctor={doctor}
                  titleType={
                    config.prescriberType === PrescriberType.DOCTOR
                      ? TitleType.DOCTOR
                      : TitleType.JUST_FULL_NAME
                  }
                  details={
                    consultation.completedAt
                      ? [
                          formatDate(
                            mapBrandToAdaptersBrand(config.brand),
                            consultation.completedAt,
                            { dateStyle: 'medium', timeStyle: 'short' },
                          ),
                        ]
                      : []
                  }
                />
              </div>

              <div className="whitespace-pre-wrap">
                <Markdown src={patientNotes} />
              </div>
            </>
          )}

          {showSendMessage && (
            <>
              <Divider my="md" />

              {config.clinicianMessagingSupported &&
              consultation.allowPatientToSendMessageToDoctor ? (
                <SendMessage
                  doctor={doctor}
                  consultationId={consultation.id}
                  profileRoute={profileRoute}
                />
              ) : (
                <AnyQuestions />
              )}
            </>
          )}
        </section>
      </Card>
    </div>
  );
}

DoctorLetter.fragment = gql`
  fragment OfferingDoctorLetterConsultation on Consultation {
    id
    patientNotes
    completedAt
    allowPatientToSendMessageToDoctor
    doctor {
      id
      ...DoctorInfoComponent
      ...OfferingSendMessageDoctor
    }
  }

  ${DoctorInfo.fragment}
  ${SendMessage.fragment}
`;
