import { Maybe } from '@customer-frontend/graphql-types';
import { AddressFields } from '@customer-frontend/intl';

export const getFullAddressString = (
  residentialAddress: Maybe<Partial<AddressFields>> | Record<string, never>,
): string => {
  if (!!residentialAddress && 'line1' in residentialAddress) {
    const { line1, line2, city, postalCode, state } = residentialAddress;

    const streetAddress = [line1, line2].filter((value) => !!value).join(' ');

    return [streetAddress, city, postalCode, state]
      .filter((value) => !!value)
      .join(', ');
  }
  return '';
};
