import {
  isValid,
  differenceInCalendarDays,
  formatDistanceStrict,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns';
import { ObservedMeasurement, Maybe } from '@customer-frontend/graphql-types';
import { ButtonPalette, CardPalette } from '@eucalyptusvc/design-system';
import { ObservedMeasurementSet } from '../types';

export const hasCheckedInToday = (lastCheckIn: string | null): boolean => {
  const lastCheckInDate = lastCheckIn ? parseISO(lastCheckIn) : null;
  const validCheckIn = lastCheckInDate && isValid(lastCheckInDate);

  return (
    validCheckIn != null &&
    lastCheckInDate != null &&
    differenceInCalendarDays(new Date(), lastCheckInDate) <= 0
  );
};

export const getLast2Measurements = (
  measurements: Maybe<Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[]>,
): ObservedMeasurementSet => {
  const [previous, current] = measurements?.slice(-2) ?? [];

  return { previous, current };
};

export const getInitialAndCurrent = (
  measurements: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[],
): {
  initial?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>;
  current?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>;
} => {
  return {
    initial: measurements[0],
    current:
      measurements.length > 1
        ? measurements[measurements.length - 1]
        : undefined,
  };
};

/**
 * Sets formatDistanceStrict to 'day' level granularity, even with
 * the logic to submit only once per calendar day, this will
 * favor 'yesterday' over 'X hours ago'
 */
export const formatDaysAgo = (from: Date): string => {
  if (isToday(from)) {
    return 'today';
  }

  if (isYesterday(from)) {
    return 'yesterday';
  }

  return formatDistanceStrict(from, new Date(), {
    addSuffix: true,
  });
};

export type Trajectory = 'DECREASE' | 'NEUTRAL' | 'INCREASE';

const getTrajectory = (difference: number): Trajectory => {
  if (difference > 0) {
    return 'DECREASE';
  }
  if (difference < 0) {
    return 'INCREASE';
  }
  return 'NEUTRAL';
};

export const calculateTrajectory = (
  previous?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'> | null,
  current?: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'> | null,
): { trajectory: Trajectory; difference: number } => {
  if (previous == null || current == null) {
    return { trajectory: 'NEUTRAL', difference: 0 };
  }
  const difference = Number((previous.value - current.value).toFixed(2));
  return { trajectory: getTrajectory(difference), difference };
};

export const measurementWasMadeToday = (
  measurement: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>,
): boolean => {
  return (
    differenceInCalendarDays(
      new Date(),
      parseISO(measurement.effectiveFrom),
    ) === 0
  );
};

export interface ReachOutToHealthCoachTextCopy {
  title: string;
  description1: string;
  description2?: string;
  buttonText: string;
  cardPalette: CardPalette;
  buttonPalette: ButtonPalette;
  url: string;
}
