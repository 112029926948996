import { useState, useEffect } from 'react';
import { DiscountCodeFormFragment } from '@customer-frontend/graphql-types';

export const useOrderPaidFreeProductToast = (
  discountCode?: DiscountCodeFormFragment,
): { showConfetti: boolean; showToast: boolean } => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (
      discountCode?.products.length &&
      discountCode.type === 'PERCENTAGE' &&
      discountCode.amount === 100
    ) {
      setShowConfetti(true);
      setShowToast(true);
    }
  }, [discountCode]);

  return {
    showConfetti,
    showToast,
  };
};
