import React from 'react';
import clsx from 'clsx';
import { useWeightDashboardTheme } from './../provider';

export const TabContainer = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const theme = useWeightDashboardTheme();

  return (
    <div
      className={clsx(
        'w-full border-b',
        theme.brand?.tabContainer?.borderColorClassName,
        theme.brand?.tabContainer?.bgColorClassName,
      )}
    >
      <div className="max-w-screen-xl mx-auto flex px-4">{children}</div>
    </div>
  );
};
