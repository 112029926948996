import { gql } from '@apollo/client';

export const subscriptionPlanFragment = gql`
  fragment OtcSubscriptionPlanInfo on SubscriptionPlan {
    id
    active
    amount
    cancelAt
    cancelAtPeriodEnd
    currentPeriodEnd
    currentPeriodStart
    daysUntilDue
    interval
    intervalCount
    product {
      id
      name
      friendlyName
      photo {
        id
        url
      }
    }
    sku
    startDate
    status
  }
`;
