import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { ReactivateOtcSubMutationVariables } from '@customer-frontend/graphql-types';

const reactivateSubscription = gql`
  mutation ReactivateOtcSub($type: ProblemType!) {
    reactivateSubscription(type: $type) {
      id
    }
  }
`;

export function useReactivateSubscription(
  options: MutationHookOptions<Response, ReactivateOtcSubMutationVariables>,
): MutationTuple<Response, ReactivateOtcSubMutationVariables> {
  return useMutation<Response, ReactivateOtcSubMutationVariables>(
    reactivateSubscription,
    options,
  );
}
