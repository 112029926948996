import { Accordion as ChakraAccordion } from '@chakra-ui/react';
import { useThemeExtension } from '../../theme/shared';
import { AccordionItem } from './accordion-item';
import { AccordionListProps, AccordionPalette } from './types';

/**
 * Accordions are a stacked list of headings with associated content that is
 * shown or hidden when the heading is clicked.
 *
 * In most cases you'll need an **AccordionList**, which handles multiple
 * items; if you only have a single item then you can use an **Accordion**
 * instead.
 *
 * By default, **AccordionList** allows multiple items to be expanded at once.
 */
export const AccordionList = ({
  items,
  defaultOpenIndex,
  allowMultipleOpen = true,
  palette,
  headingLevel = 3,
  size = 'md',
  trimBottom,
}: AccordionListProps): React.ReactElement => {
  const themeStyle = useThemeExtension<AccordionPalette>(
    'accordion.defaultStyle',
    'default',
  );
  const variant = palette || themeStyle;

  return (
    <ChakraAccordion
      allowToggle
      variant={variant}
      allowMultiple={allowMultipleOpen}
      defaultIndex={defaultOpenIndex}
    >
      {items.map(({ id, title, ...accordionProps }, idx) => (
        <AccordionItem
          key={id ?? (typeof title === 'string' ? title : undefined)}
          title={title}
          size={size}
          headingLevel={headingLevel}
          trimBottom={idx === items.length - 1 && trimBottom}
          {...accordionProps}
        />
      ))}
    </ChakraAccordion>
  );
};
