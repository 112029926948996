import { SystemStyleObject } from '@chakra-ui/theme-tools';

interface ComponentTokens {
  color: string;
  hoverColor: string;
  focusColor: string;
  textDecoration?: string;
}

interface LinkButtonThemeProps {
  default: ComponentTokens;
  alternate: ComponentTokens;
  white: ComponentTokens;
}

const getLinkButtonVariantConfig = (
  props: ComponentTokens,
): SystemStyleObject => {
  const textDecoration = props.textDecoration ? props.textDecoration : 'none';
  return {
    borderRadius: '0.25rem',
    color: props.color,
    textDecoration,
    _hover: {
      textDecoration,
      color: props.hoverColor,
    },
    _focus: {
      boxShadow: `0 0 0 0.188rem ${props.focusColor}`, // 3px
    },
  };
};

export const getLinkButtonVariants = (
  props: LinkButtonThemeProps,
): SystemStyleObject => ({
  link: getLinkButtonVariantConfig(props.default),
  'link-alternate': getLinkButtonVariantConfig(props.alternate),
  'link-white': getLinkButtonVariantConfig(props.white),
});
