import { sharedColors } from '../shared/palette';

let theme;

switch (process.env.REACT_APP_BRAND) {
  case 'juniper-jp':
    theme = {
      primary100: '#F1F6E8',
      primary200: '#CBDAAC',
      primary300: '#A7C1A0',
      primary400: '#81996e',
      primary500: '#133F26',
      lime100: '#E7FCAA',
      lime200: '#C5FF47',
      lime300: '#99E000',
      purple100: '#ECEBFF',
      purple200: '#C6C2FF',
      purple300: '#8279FF',
      cream: '#fdfcf4',
    };
    break;
  default:
    theme = {
      primary100: '#F1F5E9',
      primary200: '#D0D8BC',
      primary300: '#ABBAA7',
      primary400: '#829473',
      primary500: '#133F26',
      lime100: '#f2ffca',
      lime200: '#dff49f',
      lime300: '#c6db89',
      purple100: '#E4DCEB',
      purple200: '#D5BBE9',
      purple300: '#BA82E6',
      cream: '#FDFCF4',
    };
}

export const colors = {
  ...sharedColors,
  primary: {
    100: theme.primary100,
    200: theme.primary200,
    300: theme.primary300,
    400: theme.primary400,
    500: theme.primary500,
  },
  secondary: {
    lime: {
      100: theme.lime100,
      200: theme.lime200,
      300: theme.lime300,
    },
    purple: {
      100: theme.purple100,
      200: theme.purple200,
      300: theme.purple300,
    },
    burgundy: {
      100: '#C77A7A',
      200: '#8E1948',
      300: '#671435',
    },
  },
  tertiary: {
    cream: theme.cream,
    olive: '#7A7F2F',
  },
  reserved: {},
};
