import { UnorderedList, ListItem, Box } from '@chakra-ui/react';
import { Typography } from '../typography';
import { ImageInstructionProps } from './types';
import {
  FormattedMessage,
  useIntl,
  defineMessage,
  MessageDescriptor,
} from 'react-intl';

export const defaultInstructions: MessageDescriptor[] = [
  defineMessage({ defaultMessage: 'The photo is not blurry or dark' }),
  defineMessage({ defaultMessage: `It's only you in the photo` }),
  defineMessage({ defaultMessage: 'The photo is current' }),
];

export const ImageInstructions = ({
  textColor,
  reviewInstructions = defaultInstructions,
}: ImageInstructionProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Box mb="1rem">
        <Typography size="medium-paragraph" color={textColor}>
          <FormattedMessage defaultMessage="Ensure that:" />
        </Typography>
      </Box>
      <UnorderedList ml="1.5rem" color={textColor}>
        {reviewInstructions?.map((instruction) => {
          const instructionString =
            typeof instruction === 'string'
              ? instruction
              : formatMessage(instruction);
          return (
            <ListItem key={instructionString}>
              <Typography size="medium-paragraph">
                {instructionString}
              </Typography>
            </ListItem>
          );
        })}
      </UnorderedList>
    </>
  );
};
