import { Markdown, Modal } from '@eucalyptusvc/design-system';
import { DoctorModalProps } from './types';
import { DoctorInfo } from '../doctor-info';

export const DoctorModal = ({
  doctor,
  isOpen,
  onClose,
  titleType,
}: DoctorModalProps): React.ReactElement | null => {
  if (!doctor) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <DoctorInfo
          doctor={doctor}
          details={[doctor.provider?.qualifications]}
          titleType={titleType}
        />
      }
    >
      {doctor.bio && <Markdown src={doctor.bio} />}
    </Modal>
  );
};
