import { gql, useApolloClient } from '@apollo/client';
import { User, PathologyLocation } from '@customer-frontend/graphql-types';
import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

export function usePathologyLocations(): AsyncState<
  | {
      pathologyLocations: PathologyLocation[];
    }
  | undefined
> {
  const client = useApolloClient();

  return useAsync(async () => {
    const { data: profileData } = await client.query<{ profile: User }>({
      query: gql`
        query UsePathologyLocationsProfile {
          profile {
            id
            residentialAddress {
              id
              postalCode
            }
            address {
              id
              postalCode
            }
          }
        }
      `,
    });

    const postcode =
      profileData.profile.residentialAddress?.postalCode ||
      profileData.profile.address?.postalCode;

    if (!postcode) {
      return undefined;
    }

    const { data: pathologyLocationData } = await client.query<
      { pathologyLocations: PathologyLocation[] },
      { postcode: string }
    >({
      query: gql`
        query PathologyLocations($postcode: String!) {
          pathologyLocations(postcode: $postcode) {
            display
            url
          }
        }
      `,
      variables: {
        postcode,
      },
    });

    return pathologyLocationData;
  }, [client]);
}
