import { FormattedMessage } from 'react-intl';
import { MessageBubble } from '../message-bubble';
import { useGetDefaultClinicianNounByCountryCode } from '@customer-frontend/post-quiz';

import type { QuestionAnswerProps } from './types';
import { getConfig } from '@customer-frontend/config';

export const QuestionAnswer = ({
  type,
  answer,
  chatTheme,
  consultationId,
  consultationQuestionId,
}: QuestionAnswerProps): JSX.Element => {
  const config = getConfig();
  const isImage = type === 'IMAGE';
  const isObservation = type === 'OBSERVATION';

  const clinicianNoun = useGetDefaultClinicianNounByCountryCode(
    config.countryCode,
  );

  return (
    <MessageBubble
      className="self-end w-4/5 xl:w-3/4"
      chatTheme={chatTheme}
      type="sent"
      senderName="You"
      date={answer.createdAt ? new Date(answer.createdAt) : undefined}
      isFirst={true}
      isLast={true}
      isImage={isImage || isObservation}
      consultationId={consultationId}
      consultationQuestionId={consultationQuestionId}
    >
      {isImage ? (
        <FormattedMessage
          defaultMessage="*You sent a photo to the {clinicianName}*"
          description="Confirmation message informing patients their photo has been sent"
          values={{
            clinicianName: clinicianNoun,
          }}
        />
      ) : (
        answer.value
      )}
    </MessageBubble>
  );
};
