import { ClinicianType, User } from '@customer-frontend/graphql-types';
import { useIntl } from 'react-intl';

export const formatName = (
  user?: Pick<User, 'firstName' | 'lastName'> | null,
): string => {
  return `${user?.firstName} ${user?.lastName}`.replace(/\s\s+/g, ' ');
};

export const useClinicianNoun = (
  type: ClinicianType | undefined,
): string | undefined => {
  const { formatMessage } = useIntl();

  switch (type) {
    case 'DOCTOR':
      return formatMessage({ defaultMessage: 'doctor' });
    case 'NURSE':
      return formatMessage({ defaultMessage: 'nurse' });
    case 'PHARMACIST':
      return formatMessage({ defaultMessage: 'pharmacist' });
  }
};

/**
 *
 * @deprecated use useClinicianNoun instead
 */
export const clinicianNoun = (type: ClinicianType | undefined): string => {
  if (!type) {
    throw Error('Unable to load clinician type');
  }

  switch (type) {
    case 'DOCTOR':
      return 'doctor';
    case 'NURSE':
      return 'nurse';
    case 'PHARMACIST':
      return 'pharmacist';
  }
};
