import { Card, CardPalette, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import { ReactComponent as Doctor } from '../assets/doctor.svg';

export function PractitionerFollowUpCard({
  cardPalette,
}: {
  cardPalette?: CardPalette;
}): JSX.Element {
  const config = getConfig();

  return (
    <Card size="sm" palette={cardPalette}>
      <div className="flex content-center gap-2 mb-2">
        <Doctor className="fill-current w-6 h-6" />
        <Typography size="medium-paragraph" isBold>
          <FormattedMessage
            defaultMessage="Follow ups with your {isPrescriber, select, true {prescriber} other {practitioner}}"
            description="Card with consultation follow-up information disclaimer title"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>
      </div>
      <Typography size="medium-paragraph">
        <FormattedMessage
          defaultMessage="Our team will check-in throughout your treatment to review your progress. They will organise a follow-up consult with your {isPrescriber, select, true {prescriber} other {practitioner}} to change your treatment if it's not working for you."
          description="Card with consultation follow-up information disclaimer body"
          values={{
            isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
          }}
        />
      </Typography>
    </Card>
  );
}
