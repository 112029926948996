import React, { useState } from 'react';
import {
  ScheduleCallPractitionerBookingWindowFragment,
  UpdateProfilePhoneNumberMutation,
  UpdateProfilePhoneNumberMutationVariables,
} from '@customer-frontend/graphql-types';
import { BrandTheme, Button, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDate } from '@eucalyptusvc/lib-localization';
import { Brand, mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { RiEditLine } from 'react-icons/ri';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { useTheme } from '@chakra-ui/react';
import { getConfig } from '@customer-frontend/config';
import { IntlMobileNumberInput } from '@customer-frontend/intl';
import { notificationService } from '@customer-frontend/notifications';

const getFormattedDateString = (date: Date): string => {
  const language = navigator.language;
  const formatter = new Intl.DateTimeFormat(language, {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  });

  if (language.startsWith('en-')) {
    const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

    const suffixes = new Map([
      ['one', 'st'],
      ['two', 'nd'],
      ['few', 'rd'],
      ['other', 'th'],
    ]);
    const formatOrdinals = (n: number) => {
      const rule = pr.select(n);
      const suffix = suffixes.get(rule);
      return `${n}${suffix}`;
    };

    const dayName = date.toLocaleDateString(language, { weekday: 'long' });
    const monthName = date.toLocaleDateString(language, { month: 'long' });

    return `${dayName} ${formatOrdinals(date.getDate())} ${monthName}`;
  } else {
    return formatter.format(date);
  }
};

export const CallSummaryCard = ({
  brand,
  bookingWindow,
  userPhoneNumber,
  customerHasCallScheduled,
  countryCodes,
}: {
  brand: Brand;
  bookingWindow: Pick<
    ScheduleCallPractitionerBookingWindowFragment,
    'startAt' | 'endAt'
  >;
  userPhoneNumber: string;
  customerHasCallScheduled: boolean;
  countryCodes: string[];
}): React.ReactElement => {
  const theme = useTheme<BrandTheme>();

  const config = getConfig();
  const { formatMessage } = useIntl();

  const [showEditingPhoneNumber, setShowEditingPhoneNumber] =
    useState<boolean>(false);

  const { register, handleSubmit, errors, formState, reset } = useForm<{
    phone: string;
  }>({
    defaultValues: {
      phone: userPhoneNumber,
    },
  });

  const updateProfilePhoneNumber = gql`
    mutation UpdateProfilePhoneNumber($phone: String!) {
      updateProfile(phone: $phone) {
        id
        phone
      }
    }
  `;
  const [updateProfile, { loading }] = useMutation<
    UpdateProfilePhoneNumberMutation,
    UpdateProfilePhoneNumberMutationVariables
  >(updateProfilePhoneNumber, {
    onCompleted: (response) => {
      if (response.updateProfile) {
        setShowEditingPhoneNumber(false);
        reset({
          phone: response.updateProfile.phone ?? userPhoneNumber,
        });
      }
    },
  });

  const savePhoneNumber = async (data: { phone: string }) => {
    try {
      await updateProfile({
        variables: {
          phone: data?.phone,
        },
        context: {
          skipErrorNotification: true,
        },
      });
    } catch {
      notificationService.show({
        type: 'error',
        message: formatMessage({
          defaultMessage: 'Unable to update phone number',
          description:
            'Error message shown when the user change mobile number on call summary card',
        }),
      });
    }
  };

  return (
    <section>
      {!customerHasCallScheduled && (
        <div className="py-3 bg-primary-100 text-center rounded-t-md text-primary-600 border-b border-primary-200">
          <Typography size="medium-paragraph" inheritColor>
            <FormattedMessage defaultMessage="The next available medical appointment is:" />
          </Typography>
        </div>
      )}

      <div
        className={clsx(
          'bg-white',
          'px-8',
          'py-6',
          {
            'rounded-t-md': customerHasCallScheduled,
          },
          'text-left',
        )}
      >
        <section>
          <div className="flex flex-col">
            <div className="mb-2 uppercase">
              <Typography size="small-text" color={theme.colors.neutral[600]}>
                <FormattedMessage defaultMessage="Date" />
              </Typography>
            </div>
            <Typography size="md" isBold color={theme.colors.primary[600]}>
              {getFormattedDateString(new Date(bookingWindow.startAt))}
            </Typography>
          </div>

          <div className="flex flex-col">
            <div className="mt-5 mb-2 uppercase">
              <Typography size="small-text" color={theme.colors.neutral[600]}>
                <FormattedMessage defaultMessage="Window" />
              </Typography>
            </div>

            <Typography size="md" isBold color={theme.colors.primary[600]}>
              <FormattedMessage
                defaultMessage="{startAt} - {endAt}"
                values={{
                  startAt: formatDate(
                    mapBrandToAdaptersBrand(brand),
                    bookingWindow.startAt,
                    { hour: 'numeric' },
                  ),
                  endAt: formatDate(
                    mapBrandToAdaptersBrand(brand),
                    bookingWindow.endAt,
                    {
                      hour: 'numeric',
                      timeZoneName: 'short',
                    },
                  ),
                }}
              />
            </Typography>
          </div>
        </section>
      </div>

      {!showEditingPhoneNumber && (
        <div className="bg-primary-100 flex justify-center items-center py-3 text-center text-primary-600 rounded-md rounded-t-none border-t border-primary-200">
          <Typography size="medium-paragraph" inheritColor>
            <FormattedMessage
              defaultMessage="A {brand} practitioner will call you on <strong>{userPhoneNumber}</strong><button>{changePhoneIcon}</button>"
              values={{
                brand: config.title,
                userPhoneNumber,
                strong: (chunk) => <strong>{chunk}</strong>,
                changePhoneIcon: <RiEditLine />,
                button: (chunk) => (
                  <span className="h-full align-middle">
                    <button
                      className="px-2 text-xl"
                      onClick={() => setShowEditingPhoneNumber(true)}
                    >
                      {chunk}
                    </button>
                  </span>
                ),
              }}
            />
          </Typography>
        </div>
      )}

      {showEditingPhoneNumber && (
        <div className="bg-primary-100 text-neutral-900 p-6 rounded-md rounded-t-none">
          <form className="flex flex-col">
            <IntlMobileNumberInput
              countryCodes={countryCodes}
              register={register}
              errorMessage={errors?.phone?.message}
            />
            <div className="mt-4 mb-3">
              <Button
                isFullWidth
                palette="default"
                level="primary"
                onClick={handleSubmit(savePhoneNumber)}
                isLoading={loading}
                isDisabled={!formState.isDirty || !!errors?.phone?.message}
              >
                <FormattedMessage
                  description="Save new phone number"
                  defaultMessage="Save number"
                />
              </Button>
            </div>

            <Button
              isFullWidth
              palette="default"
              level="secondary"
              onClick={() => setShowEditingPhoneNumber(false)}
            >
              <FormattedMessage
                description="Cancel change phone number"
                defaultMessage="Cancel"
              />
            </Button>
          </form>
        </div>
      )}
    </section>
  );
};
