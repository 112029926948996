import { inMemUiStorage } from './inMemUiStorage';
import { localUiStorage } from './localUiStorage';
import { sessionUiStorage } from './sessionUiStorage';
import { cookieUiStorage } from './cookieUiStorage';
import { IUiStorage } from './IUiStorage';
import { UiStorageType } from './type';

export * from './type';
export * from './IUiStorage';

const STORAGE_MAP: Record<UiStorageType, IUiStorage> = {
  cookie: cookieUiStorage,
  in_mem: inMemUiStorage,
  local_storage: localUiStorage,
  session_storage: sessionUiStorage,
};

export interface GetUiStorage {
  (type: UiStorageType): IUiStorage;
}

/**
 *
 * @param type
 * @param fallback
 * @returns
 */
const getUiStorage: GetUiStorage = (type: UiStorageType) => {
  if (STORAGE_MAP[type].isSupported()) {
    return STORAGE_MAP[type];
  }
  if (STORAGE_MAP.session_storage.isSupported()) {
    return STORAGE_MAP.session_storage;
  }
  return STORAGE_MAP.in_mem;
};

export const uiStorages = {
  get: getUiStorage,
  isSupported: (type: UiStorageType): boolean => {
    return STORAGE_MAP[type].isSupported();
  },
  local: getUiStorage('local_storage'),
  session: getUiStorage('session_storage'),
  mem: getUiStorage('in_mem'),
  cookie: getUiStorage('cookie'),
};
