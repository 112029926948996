import React from 'react';
import { Token, PaymentRequestTokenEvent } from '@stripe/stripe-js';
import { gql, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  LoadingSpinner,
  Typography,
  Card,
  useNotification,
} from '@eucalyptusvc/design-system';

import { useAuth } from '@customer-frontend/auth';
import { SignupForm } from './signup-form';
import { LoginForm } from './login-form';
import {
  useLogin,
  useSignupMutation,
  useOtcSubscriptionPlanV2ById,
  useUpdateProfileMutation,
  useUpdateShippingAddress,
} from '@customer-frontend/services';
import { AccountFormVariant, PlanPaymentFormData } from './types';
import { ConfirmDetailsForm } from './confirm-details-form';
import {
  StripePaymentForm,
  useStripeTokenHandler,
  getStripePaymentMethod,
  formatCentsToCurrency,
} from '@customer-frontend/order';
import { useSubscribePlanV2Mutation } from '@customer-frontend/graphql-types';
import { SubscriptionPlanCard } from '@customer-frontend/subscriptions';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { useEventService } from '@customer-frontend/events';
import { scrollToRef } from '@customer-frontend/utils';
import { IntlAddressInput } from '@customer-frontend/intl';

const profileDocument = gql`
  query V2SubscriptionPlanPaymentProfile {
    profile {
      id
      firstName
      lastName
      fullName
      email
      phone
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
      }
      savedPaymentMethods {
        createdAt
        default
        gateway
        id

        ... on SavedCardPaymentMethod {
          brand
          mask
          expiry
        }
        ... on SavedPayPalPaymentMethod {
          email
        }
        ... on SavedZipPaymentMethod {
          valid
        }
      }
    }
  }
`;

export const SubscriptionV2PlanPayment = ({
  confirmRoute,
  profileRoute,
  tickIcon,
}: {
  confirmRoute: string;
  profileRoute: string;
  tickIcon: React.ReactElement;
}): React.ReactElement | null => {
  const { subscriptionPlanId } = useParams<{ subscriptionPlanId: string }>();
  const config = getConfig();
  const { data: subscriptionPlanData, loading: subscriptionPlanLoading } =
    useOtcSubscriptionPlanV2ById({ variables: { id: subscriptionPlanId } });
  const { isLoggedIn, login, logout } = useAuth();
  const { login: loginMutation, loading: loginLoading } = useLogin();
  const history = useHistory();
  const notify = useNotification();

  const { data: profileData, loading: profileLoading } = useQuery(
    profileDocument,
    {
      skip: !isLoggedIn,
      onCompleted: (completedData) => {
        const { firstName, lastName, email, phone } =
          completedData.profile ?? {};
        reset({
          firstName: firstName ?? '',
          lastName: lastName ?? '',
          email: email ?? '',
          phone: phone ?? '',
          shippingAddress: completedData?.profile?.address ?? {
            country: 'Australia',
          },
        });
        setEditingCardDetails(!stripePaymentMethod);
        setAccountFormVariant('CONFIRM_DETAILS');
      },
    },
  );

  const planData = subscriptionPlanData?.subscriptionPlanV2ById;
  const savedPaymentMethods = profileData?.profile?.savedPaymentMethods;
  const stripePaymentMethod = getStripePaymentMethod(savedPaymentMethods);

  const [signup] = useSignupMutation({
    onCompleted: ({ signup }) => {
      if (signup?.token && signup?.user && login) {
        login(signup?.token, signup?.user);
      }
    },
  });

  const [updateProfile] = useUpdateProfileMutation();
  const [updateShippingAddress] = useUpdateShippingAddress();
  const [subscribePlan] = useSubscribePlanV2Mutation();
  const { otc: otcEvents } = useEventService();

  const [accountFormVariant, setAccountFormVariant] =
    React.useState<AccountFormVariant>('SIGNUP');
  const [loading, setLoading] = React.useState(false);
  const [editingCardDetails, setEditingCardDetails] = React.useState(
    !stripePaymentMethod,
  );

  const onTokenReceived = async (token?: Token): Promise<void> => {
    const { data: subscriptionResponse } = await subscribePlan({
      variables: {
        subscriptionPlanId,
        token: token?.id,
      },
    });
    if (!subscriptionResponse?.subscribePlanV2?.subscriptionOrders) {
      // errors thrown by graphql client
      return;
    }
    const latestSubscriptionOrder =
      subscriptionResponse?.subscribePlanV2?.subscriptionOrders[0];

    await otcEvents.purchase({
      transaction_id: latestSubscriptionOrder.id,
      value: planData?.price || 0,
      currency: config.currency,
      items:
        latestSubscriptionOrder.order?.lineItems?.map((orderLineItem) => ({
          price: orderLineItem.variant.price,
          id: orderLineItem.variant.id,
          quantity: 1,
        })) ?? [],
    });
  };

  const { handleTokenReceived } = useStripeTokenHandler({
    onTokenReceived,
    detailsChanged: editingCardDetails,
  });

  const {
    register,
    handleSubmit,
    errors,
    watch,
    trigger: validateForm,
    reset,
    getValues,
    setError,
  } = useForm<PlanPaymentFormData>({
    defaultValues: {
      shippingAddress: {
        country: 'Australia',
      },
    },
  });

  const loginFormRef = React.useRef<HTMLDivElement>(null);

  const handleLogin = async (): Promise<void> => {
    const formValues = getValues();
    const { loginEmail, loginPassword } = formValues;

    if (!loginEmail || !loginPassword) {
      notify.error({
        message: 'Email and password are required',
      });

      if (!loginEmail) {
        setError('loginEmail', {
          message: 'Email is required',
          shouldFocus: true,
        });
      }

      if (!loginPassword) {
        setError('loginPassword', {
          message: 'Password is required',
          shouldFocus: true,
        });
      }

      return;
    }

    try {
      await loginMutation({
        email: loginEmail,
        password: loginPassword,
      });
    } catch {
      // errors handled by graphql client
    }
  };

  const handleFormSubmit = (
    stripeEvent?: PaymentRequestTokenEvent,
  ): (() => Promise<void>) =>
    handleSubmit(async (data) => {
      try {
        setLoading(true);
        switch (accountFormVariant) {
          case 'SIGNUP':
            await signup({
              variables: {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                password: data.password,
                phone: data.phone,
              },
            });
            break;
          case 'CONFIRM_DETAILS':
            await updateProfile({
              variables: {
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
              },
            });
            break;
          case 'LOGIN':
            notify.warning({
              message: 'You must log in first',
            });
            setLoading(false);
            scrollToRef(loginFormRef);
            return;
        }

        const { line1, line2, city, postalCode, state } = data.shippingAddress;

        await updateShippingAddress({
          variables: {
            address: {
              line1,
              line2,
              city,
              postalCode,
              state,
              country: 'Australia',
            },
          },
        });

        await handleTokenReceived(stripeEvent);
        history.push(confirmRoute);
      } catch {
        // errors handled by graphql client
      } finally {
        setLoading(false);
      }
    });

  const handleLoginClick = (): void => {
    setAccountFormVariant('LOGIN');
  };

  const handleSignupClick = (): void => {
    setAccountFormVariant('SIGNUP');
  };

  const handleLogoutClick = (): void => {
    setAccountFormVariant('LOGIN');
    logout();
    reset({
      shippingAddress: {
        country: 'Australia',
      },
    });
    setEditingCardDetails(true);
  };

  // This handles Apple or Google Pay payments
  const handleAlternativePaymentMethod = async (
    stripeEvent: PaymentRequestTokenEvent,
  ): Promise<void> => {
    const valid = await validateForm();
    if (!valid) {
      stripeEvent.complete('fail');
      return;
    }

    await handleFormSubmit(stripeEvent)();
  };

  if (subscriptionPlanLoading) {
    return (
      <div className="flex items-center justify-center p-3">
        <LoadingSpinner />
      </div>
    );
  }

  if (!planData) {
    notify.warning({
      message: 'Could not find subscription plan',
    });
    history.push(profileRoute);
    return null;
  }

  return (
    <div className="space-y-6 mb-12">
      <Typography size="xl" isBold element="h1">
        Subscription payment
      </Typography>
      {config.brand === 'pilot' && (
        <Divider variant="separator" palette="alternate" />
      )}
      <SubscriptionPlanCard
        {...planData}
        photoUrl={planData.photo.url}
        price={planData.price}
        includePromise
        tickIcon={tickIcon}
      />
      <form onSubmit={handleFormSubmit()} className="space-y-6" noValidate>
        {accountFormVariant === 'SIGNUP' && (
          <SignupForm
            register={register}
            errors={errors}
            watch={watch}
            onLoginClick={handleLoginClick}
          />
        )}
        {accountFormVariant === 'LOGIN' && (
          <div ref={loginFormRef}>
            <LoginForm
              register={register}
              errors={errors}
              onSignupClick={handleSignupClick}
              onLoginFormSubmit={handleLogin}
              loading={loginLoading}
            />
          </div>
        )}
        {accountFormVariant === 'CONFIRM_DETAILS' && (
          <ConfirmDetailsForm
            register={register}
            errors={errors}
            onLogoutClick={handleLogoutClick}
            loading={profileLoading}
            profileData={profileData}
          />
        )}

        <Card>
          <div className="space-y-4">
            <Typography size="lg" isBold>
              Shipping Address
            </Typography>
            <IntlAddressInput
              className="mt-3"
              name="shippingAddress"
              registerField={register}
              errors={errors.shippingAddress ?? {}}
              useAutoComplete
            />
          </div>
        </Card>

        <Card>
          <div className="space-y-3 mb-5">
            <Typography size="lg" isBold>
              Payment details
            </Typography>
            <StripePaymentForm
              totalPrice={planData.price}
              savedPaymentMethods={profileData?.profile?.savedPaymentMethods}
              isEditingStripeDetails={editingCardDetails}
              onEdit={(): void => setEditingCardDetails(true)}
              onAlternativePaymentMethod={(stripeEvent): Promise<void> =>
                handleAlternativePaymentMethod(stripeEvent)
              }
            />
          </div>
          <Button
            isSubmit
            level="primary"
            palette={getPrimaryButtonPalette(config.brand)}
            isDisabled={loading || loginLoading || profileLoading}
            isLoading={loading}
            isFullWidth
          >
            Confirm {formatCentsToCurrency(planData.price)} payment
          </Button>
        </Card>
      </form>
    </div>
  );
};
