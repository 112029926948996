import { Config } from '../type';
import { defaultConfig } from './default';

const HELP_ARTICLE_URL = 'https://help.myjuniper.com/hc/en-us/articles/';

export const JuniperAuConfig: Config = {
  ...defaultConfig,
  analyticsBrand: 'juniper',
  brand: 'juniper',
  loginUrl: '/login',
  title: 'Juniper',
  stripeApi: 'CHARGE',
  country: 'Australia',
  countryCode: 'AU',
  locale: 'en-AU',
  currency: 'AUD',
  emergencyNumber: '000',
  healthlineEnabled: true,
  freeShippingEnabled: false,
  supportEmail: 'hello@myjuniper.com',
  medicalEmail: 'medicalteam@myjuniper.com',
  rejectionContactLink: 'mailto:medicalteam@myjuniper.com',
  helpCentreUrl: 'https://help.myjuniper.com/hc/en-us',
  zendeskRequestUrl: 'https://help.myjuniper.com/hc/en-us/requests/new',
  problemTypeZendeskParams: {
    MENOPAUSE: { ticket_form_id: '15566097179929' },
    SLEEP: { ticket_form_id: '15566123499801' },
    WEIGHT_LOSS: { ticket_form_id: '15500132356889' },
  },
  weightCompoundingZendeskParams: {
    ticket_form_id: '15500132356889',
    tf_15542333724569: 'compounding_query',
  },
  weightPausingAccountZendeskParams: {
    ticket_form_id: '15500132356889',
    tf_15542333724569: 'change_or_edit_account',
    tf_15562527346841: 'account_pause_clone',
  },
  weightRefillNowZendeskParams: {
    ticket_form_id: '15500132356889',
    tf_15542333724569: 'medical_support',
    tf_15562018357657: 'med_err_clone',
  },
  weightResumeNowZendeskParams: {
    ticket_form_id: '15500132356889',
    tf_15542333724569: 'change_or_edit_account',
    tf_15562527346841: 'account_restart_clone',
  },
  weightRescheduleZendeskParams: {
    ticket_form_id: '15500132356889',
    tf_15542333724569: 'my_order_or_delivery',
    tf_15545890373145: 'order_redirect_clone',
  },
  weightInitialPurchaseContactParams: {
    ticket_form_id: '15500132356889',
    tf_15542333724569: 'getting_started',
  },
  trustpilotLink: 'https://au.trustpilot.com/review/myjuniper.com',
  contactUsUrl: 'https://www.myjuniper.com/contact',
  consultationPriceCents: 2900,
  weightFacebookCommunityUrl:
    'https://www.facebook.com/groups/juniperweightresetcommunity',
  otcSubscriptions: true,
  weightFaqReadingCollectionId: '5402325b-bab9-44b9-8622-eb3c54b2b430',
  enableMarketingConsent: true,
  enableMedicare: true,
  enableZip: true,
  localisedAssets: {
    juniperArchway: '/assets/juniper-archway-2-gb.svg',
    juniperProductDesktopImage: '/assets/juniper-product-desktop-gb.png',
    juniperProductMobileImage: '/assets/juniper-product-mobile-gb.jpg',
    appWeightTracking: '/assets/app-weight-tracking-gb.png',
    medicationBox: '/assets/medication-box-gb.png',
    phoneCall: '/assets/phone-call.svg',
    zendeskMessageUsLogo: '/assets/heart-pictogram.svg',
    program: {
      boxPen: '/assets/program/box-pen.webp',
      boxPen2x: '/assets/program/box-pen@2x.webp',
      boxPen3x: '/assets/program/box-pen@3x.webp',
      chat: '/assets/program/chat.webp',
      chat2x: '/assets/program/chat@2x.webp',
      chat3x: '/assets/program/chat@3x.webp',
      team: '/assets/program/team.webp',
      team2x: '/assets/program/team@2x.webp',
      team3x: '/assets/program/team@3x.webp',
      food: '/assets/program/food.webp',
      food2x: '/assets/program/food@2x.webp',
      food3x: '/assets/program/food@3x.webp',
      community: '/assets/program/community.webp',
      community2x: '/assets/program/community@2x.webp',
      community3x: '/assets/program/community@3x.webp',
      members: '/assets/program/members.webp',
      members2x: '/assets/program/members@2x.webp',
      members3x: '/assets/program/members@3x.webp',
      statisticOne: '/assets/program/statistic-one.svg',
      statisticTwo: '/assets/program/statistic-two.svg',
      statisticThree: '/assets/program/statistic-three.svg',
      staceyBeforeAfter: '/assets/program/staceybeforeafter.webp',
      staceyBeforeAfter2x: '/assets/program/staceybeforeafter@2x.webp',
      staceyBeforeAfter3x: '/assets/program/staceybeforeafter@3x.webp',
      rebekahBeforeAfter: '/assets/program/rebekahbeforeafter.webp',
      rebekahBeforeAfter2x: '/assets/program/rebekahbeforeafter@2x.webp',
      rebekahBeforeAfter3x: '/assets/program/rebekahbeforeafter@3x.webp',
    },
    consultation: {
      practitioners: {
        one: '/assets/consultation/care-team-stella.webp',
        two: '/assets/consultation/care-team-georgia.webp',
        three: '/assets/consultation/care-team-drvickers.webp',
        four: '/assets/consultation/care-team-lydia.webp',
        five: '/assets/consultation/care-team-ben.webp',
        six: '/assets/consultation/care-team-kim.webp',
      },
      initialScreening: '/assets/consultation/initial-screening-au.webp',
      paymentForTreatment: '/assets/consultation/payment-for-treatment-au.webp',
      medicalScreening: '/assets/consultation/medical-screening-au.webp',
      treatmentDelivery: '/assets/consultation/treatment-delivery-au.webp',
    },
  },
  externalSupportNumber: '13 11 14',
  externalSupportLabel: 'Lifeline Australia',
  coldStorageAddressValidation: true,
  trustPilotEnabled: true,
  showOnboardingQuiz: true,
  appStoreLinks: {
    appStore:
      'https://apps.apple.com/us/app/juniper-womens-weight-loss/id1661064221',
    playStore:
      'https://play.google.com/store/apps/details?id=vc.eucalyptus.juniper',
  },
  pharmacyInvoicesEnabled: true,
  collectAddressPage: true,
  confirmAnswersPage: true,
  consultationCreateAccountPage: true,
  saxendaDrNote: true,
  urlPaths: {
    ...defaultConfig.urlPaths,
    termsOfService: '/terms-and-conditions',
    termsOfSale: '/terms-and-conditions',
    privacyPolicy: '/privacy',
    privacyNotice: '/privacy',
  },
  helpArticles: {
    ihiLinkingWithMedicare: `${HELP_ARTICLE_URL}21305579084313`,
    ihiLinkingNoMedicare: `${HELP_ARTICLE_URL}21305796887193`,
    healthInsurance: `${HELP_ARTICLE_URL}15380120642841`,
    cancelTreatment: `${HELP_ARTICLE_URL}4408460472345`,
    healthcareIdentifier: `${HELP_ARTICLE_URL}20399476631321`,
    pharmacyInvoices: `${HELP_ARTICLE_URL}12576233233177`,
  },
  hasMobileApp: true,
  alternativeShippingAddressPost:
    'https://help.myjuniper.com/hc/en-us/articles/4408460416665',
  purchasePrompts: {
    initialPurchaseContents: 'expanded',
    initialPurchaseConfirmPersonalDetails: false,
    showCheckoutReassurance: true,
    initialPurchaseAddresses: new Set(['shipping'] as const),
    initialPurchaseShowSimplifiedShippingAddressForm: true,
    collectDeliveryInstructions: true,
    collectDeliveryInstructionsUpfront: false,
    supportShippingAddressValidation: true,
    showMarketingOptInCheckbox: false,
    showDeliveryConstraintCard: true,
    showTermsAndConditionsCheckbox: false,
    showZendeskSupportCard: true,
  },
  addressConfig: {
    numberBeforeStreet: true,
    showStateInput: true,
    validateShippingAddressWithinRange: true,
  },
  purchaseActivationFlow: {
    moneyBackGuaranteeDays: 30,
  },
};
