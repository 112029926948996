import { Box, useStyleConfig } from '@chakra-ui/react';
import { CardProps } from './types';
import { useGetThemeSize } from './utils';

/**
 * A **Card** is used to group related content and create separation within a page.
 */
export const Card = ({
  palette = 'default',
  children,
  size,
  isFullWidth,
  isFullHeight,
}: CardProps): React.ReactElement => {
  const cardSize = useGetThemeSize(size);
  const styles = useStyleConfig('Card', { variant: palette, size: cardSize });

  return (
    <Box
      __css={styles}
      w={isFullWidth ? 'full' : undefined}
      h={isFullHeight ? 'full' : undefined}
    >
      {children}
    </Box>
  );
};
