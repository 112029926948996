import { useQuery, gql } from '@apollo/client';
import { ConsultationTypeQueryResult } from '@customer-frontend/graphql-types';

export function useConsultationType(
  consultationId: string,
): ConsultationTypeQueryResult {
  return useQuery(
    gql`
      query ConsultationType($id: String!) {
        consultation(id: $id) {
          id
          type
        }
      }
    `,
    {
      variables: {
        id: consultationId,
      },
      skip: !consultationId,
      fetchPolicy: 'cache-first',
    },
  );
}
