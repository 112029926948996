import {
  useQuery,
  gql,
  QueryResult,
  FetchPolicy,
  OperationVariables,
} from '@apollo/client';
import { Consultation, ProblemType } from '@customer-frontend/graphql-types';

type HookArgs = {
  variables: {
    type: ProblemType | null;
  };
  skip?: boolean;
  fetchPolicy?: FetchPolicy;
};

type ConsulationsData = {
  consultations: Consultation[];
};

const LATEST_CONSULATION_QUERY = gql`
  query LatestConsultation($type: ProblemType!) {
    consultations(
      where: { type: { equals: $type } }
      orderBy: { createdAt: desc }
      take: 1
    ) {
      id
      type
      status
      stage
      doctor {
        id
        lastName
        shortClinicianName
        fullClinicianName
        fullName
      }
      quizApplication {
        id
      }
    }
  }
`;

export const useLatestConsultation = ({
  variables,
  skip,
  fetchPolicy,
}: HookArgs): QueryResult<ConsulationsData> => {
  return useQuery<ConsulationsData, OperationVariables>(
    LATEST_CONSULATION_QUERY,
    {
      variables: {
        type: variables.type,
      },
      skip: skip || !variables.type,
      fetchPolicy,
    },
  );
};
