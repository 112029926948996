import { UserInfo } from '@customer-frontend/auth';
import {
  ChecklistAttribute,
  ChecklistType,
  ProblemType,
  UnitSystem,
  ConsultationStatus,
  QuizPurpose,
} from '@customer-frontend/graphql-types';
import { MedicareFormOnPage } from '@customer-frontend/healthcare-collection';

export type UserResolver = () => UserInfo | undefined;

/**
 * ESegmentDestination is an enum of supported Segment analytics destinations.
 */
export enum ESegmentDestination {
  ALL = 'All',
  KLAVIYO = 'Klaviyo',
  FACEBOOK_PIXEL = 'Facebook Pixel',
  GOOGLE_ADS = 'Google AdWords New',
  GOOGLE_ANALYTICS = 'Google Analytics',
  FULLSTORY = 'FullStory',
  REDSHIFT_JUNIPER_UK = 'Redshift Juniper UK',
  REDSHIFT_JUNIPER = 'Redshift Juniper',
  REDSHIFT_PILOT = 'Redshift Pilot',
  TIKTOK_CONVERSIONS = 'TikTok Conversions',
  GOOGLE_TAG_MANAGER = 'Google Tag Manager',
  HOTJAR = 'Hotjar',
  AMPLITUDE = 'Amplitude (Actions)',
  CUSTOMERIO = 'Customer.io (Actions)',
  PINTEREST = 'Pinterest',
}

/**
 * ISegmentEventDestination is the configuration definition for events routed from the Segment API call.
 */
export type ISegmentEventDestination = {
  [key in ESegmentDestination]?: boolean;
};

/**
 * EEucalyptusEvent is an enum of all known Eucalyptus event namespaces.
 */
export enum EEucalyptusEvent {
  ACCOUNT_CREATED = 'Account Created',
  CONSULTATION_CREATED = 'Consultation Created',
  CONSULTATION_PAID = 'Consultation Paid',
  CONSULTATION_PAYMENT_FAILED = 'Consultation Payment Failed',
  CONSULTATION_SURVEY_COMPLETED = 'Consultation Survey Completed',
  FOLLOW_UP_CONSULTATION_SURVEY_STARTED = 'Follow-up Consultation Survey Started',
  FOLLOW_UP_REMINDER_CLICKED = 'Follow-up Reminder Clicked',
  ORDER_PAID = 'Order Paid',
  FEATURE_EXPOSED = 'Feature Exposed',
  PSAT_RESPONSE = 'PSAT Response',
  OUT_OF_RANGE_SHIPPING_ADDRESS_CAPTURED = 'Out of Range Shipping Address Captured',
  EXPERIMENTAL_QUIZ_RESPONSE_UNIT_SYSTEM = 'Experimental Quiz Response Unit System',
  MEDICARE_COLLECTION_DEFERRED = 'Medicare Collection Deferred',
  MEDICARE_COLLECTION_INELIGIBLE = 'Medicare Collection Ineligible',
  MEDICARE_FORMAT_INVALID = 'Medicare Format Invalid',
  MEDICARE_FORMAT_VALID = 'Medicare Format Valid',
  QUIZ_STARTED = 'Quiz Started',
  QUIZ_COMPLETED = 'Quiz Completed',
  OTC_PURCHASE_EVENT = 'purchase',
  EDUCATION_ARTICLE_VIEWED = 'Education Article Viewed',
  EDUCATION_ARTICLE_READ = 'Education Article Read',
  CHECKLIST_GENERATED = 'Checklist Generated',
  CALENDAR_REMINDER_ADDED = 'Calendar Reminder Added',
  CROSS_SELL_STARTED = 'Cross Sell Started',
  DOCTOR_MESSAGE_VIDEO_PLAYED = 'Doctor Message Video Played',
  DOCTOR_MESSAGE_VIDEO_PAUSED = 'Doctor Message Video Paused',
  DOCTOR_MESSAGE_VIDEO_COMPLETED = 'Doctor Message Video Completed',
  VIDEO_PLAYED = 'Video Played',
  UI_INTERACTION_TRACKED = 'UI Interaction Tracked',
  CALL_RECORDING_CONSENT_APPROVED = 'Call Recording Consent Approved',
  CUSTOMER_OFFERINGS_PURCHASED = 'Customer Offerings Purchased',
  SWITCH_OFFERING_SELECTED = 'Switch Offering Selected',
  PAF_ADDONS_VIEWED = 'PAF Add-Ons Viewed',
  PAF_CART_ITEM_QUANTITY_MODIFIED = 'PAF Cart Item Quantity Modified',
  QUIZ_RESET_VIEWED = 'Quiz Reset Viewed',
}

/**
 * EFacebookEvent is an enum of all known Facebook event namespaces.
 */
export enum EFacebookEvent {
  PURCHASE = 'Purchase',
  ORDER_PAID = 'Order Paid',
}

/**
 * IEucalyptusPaymentEvent is the content required for an event containing payment information.
 */
export interface IEucalyptusPaymentEvent {
  /**
   * currency is the ISO 4217 representation of the currency used.
   */
  currency: string;
  /**
   * value is the amount paid including any taxes for an item.
   */
  value: number | string;
}

/**
 * IEucalyptusEvent is the base content provided in all analytics events.
 */
export interface IEucalyptusEvent
  extends Record<string, unknown>,
    IAccountEventContactDetails,
    IAccountEventIDDetails,
    IAccountEventNameDetails {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IAccountEventContactDetails is the content required for an Acount event containing user contact information.
 */
export interface IAccountEventContactDetails {
  /**
   * email is the users email address.
   */
  email?: string | null;
  /**
   * phoneNumber is the users phone number.
   */
  phoneNumber?: string | null;
}

/**
 * IAccountEventIDDetails is the contents required for an Account event containing user identifiers.
 */
export interface IAccountEventIDDetails {
  /**
   * userId is the account entity primary key.
   */
  userId?: string;
}

/**
 * IAccountEventNameDetails is the content required for an Account event containing user name information.
 */
export interface IAccountEventNameDetails {
  /**
   * firstName is the users given first name.
   */
  firstName?: string | null;
  /**
   * lastName is the users given lastName.
   */
  lastName?: string | null;
}

/**
 * IAccountEventAddressDetails is the content required for Account event containing user address information.
 */
export interface IAccountEventAddressDetails {
  /**
   * city is the addresses fully qualified regional city name.
   */
  city?: string;
  /**
   * country is the addresses fully qualified country name.
   */
  country?: string;
  /**
   * line1 is the addresses building information segment.
   */
  line1?: string;
  /**
   * line2 is the addresses street information segment.
   */
  line2?: string;
  /**
   * postalCode is the addresses civic identitier.
   */
  postalCode?: string;
  /**
   * state is the addresses abbreviated regional identifier.
   */
  state?: string;
}

/**
 * IAccountEvent is the base content provided in all Account analytics events.
 */
export interface IAccountEvent extends IEucalyptusEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IAccountCreatedEvent is the content supported for the Account Created event.
 */
export interface IAccountCreatedEvent
  extends IAccountEvent,
    IAccountEventAddressDetails,
    IAccountEventNameDetails {
  currency?: string;
  value?: number;
}

export interface ICustomerOfferingsPurchasedEvent extends IEucalyptusEvent {
  source: 'OP' | 'CP';
  pricingSessionId: string;
  purchaseGroupId: string;
  orderId: string; // purchase group id
  coupon?: string;
  total: number; // the amount in dollars/euros/etc after discounts (ie, 195.50)
  amount: number; // the amount in cents/pence/etc after discounts (ie, 19550)
  discount: number; // total discount associated with the session in dollars/euros (ie 15.50)
  currency: string;
  problemTypes: string[];
  offerings: Array<{
    id: string;
    problemTypes: string[];
    products: Array<{
      sku: string;
      problemTypes: string[];
      quantity: number;
      price: number; // price before discount
      discount?: number; // optional amount to discount against price
      name: string;
    }>;
  }>;
  productNames: string;
}

export interface IQuizStartedEvent extends IEucalyptusEvent {
  quizApplicationId: string;
  deprecated_consultation_id?: string;
  problemType: string;
  status?: string;
  label?: string;
  medium?: string;
  action_source: 'website';
  quizDefault: boolean;
  quizCode: string;
}

export interface IQuizCompletedEvent extends IEucalyptusEvent {
  quizApplicationId: string;
  quizPurpose: QuizPurpose;
  deprecated_consultation_id: string | undefined;
  problemType: ProblemType;
  status: ConsultationStatus | undefined;
  quizDefault: boolean;
  quizCode: string;
}

/**
 * IConsultationEvent is the base content provided in all Consultation analytics events.
 */
export interface IConsultationEvent extends IEucalyptusEvent {
  /**
   * consultationId is the primary key for the consultation entity.
   */
  consultationId: string;
  /**
   * problemType is the name of the medical condition being service.
   */
  problemType: string;
}

export type ICallRecordingConsentEvent = IConsultationEvent;

/**
 * IConsultationCreatedEvent is the content supported for the Consultation Created event.
 */
export interface IConsultationCreatedEvent extends IConsultationEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IConsultationPaid is the content supported for the Consultation Paid event.
 */
export interface IConsultationPaidEvent
  extends IConsultationEvent,
    IEucalyptusPaymentEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IConsultationSurveyCompletedEvent is the content supported for the Consultation Survey Completed event.
 */
export interface IConsultationSurveyCompletedEvent extends IConsultationEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IConsultationPaymentFailedEvent is the content supported for the Consultation Payment Failed event.
 */
export interface IConsultationPaymentFailedEvent
  extends IConsultationEvent,
    IEucalyptusPaymentEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IFollowUpEvent is the base content provided in all Follow-up analytics events.
 */
export interface IFollowUpEvent extends IEucalyptusEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IFollowUpConsultationSurveyStarted is the content supported for the Follow-up Consultation Survey Started event.
 */
export interface IFollowUpConsultationSurveyStarted extends IFollowUpEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IFollowUpReminderClicked is the content supported for the Follow-up Reminder Clicked event.
 */
export interface IFollowUpReminderClicked extends IFollowUpEvent {} // eslint-disable-line @typescript-eslint/no-empty-interface

/**
 * IOrderEvent is the base content provided in all Order analytics events.
 */
export interface IOrderEvent extends IEucalyptusEvent {
  /**
   * orderId is the primary key for the order entity.
   */
  orderId: string;
}

/**
 * IOrderPaidEvent is the content supported for the Order Paid event.
 */
export interface IOrderPaidEvent
  extends IOrderEvent,
    IAccountEventAddressDetails,
    IEucalyptusPaymentEvent {
  consultationId?: string;
}

export interface IFeatureFlagExposedEvent extends IEucalyptusEvent {
  flagKey: string;
  flagStringValue: string;
  additionalAttributes: string;
}

export interface ICrossSellStartedEvent extends IEucalyptusEvent {
  initialType?: string;
  clickedType: string;
  numberInList: number;
  recommendedTypes: string[];
}

export enum PsatQuestionCategory {
  OverallSatisfaction = 'Overall Satisfaction',
  DoctorQuality = 'Doctor Quality',
  ExtendedResponse = 'Extended Response',
}

export interface IPsatResponseEvent extends IConsultationEvent {
  context: string;
  doctorId: string;
  category: PsatQuestionCategory;
  questionText: string;
  answer: string;
}

export interface IOutOfRangeShippingAddressCapturedEvent
  extends IConsultationEvent,
    IAccountEventAddressDetails {}

export interface IDispatchQuizUnitEvent extends IEucalyptusEvent {
  userId: string;
  consultationId: string;
  unitSystem: UnitSystem;
  shortCode: string;
}

export interface IMedicareCollectionIneligibleEvent extends IEucalyptusEvent {
  problemType: ProblemType | undefined;
  consultationId: string | undefined;
  trigger: MedicareFormOnPage;
}

export interface IMedicareCollectionDeferredEvent extends IEucalyptusEvent {
  problemType: ProblemType | undefined;
  consultationId: string | undefined;
}

export interface IMedicareFormatEvent extends IEucalyptusEvent {
  problemType: ProblemType | undefined;
  consultationId: string | undefined;
  trigger: MedicareFormOnPage;
}

export interface IOtcPurchaseEvent extends IEucalyptusEvent {
  transaction_id: string;
  value: number;
  currency: string;
  items: IPurchaseEventItem[];
}
interface IPurchaseEventItem {
  id: string;
  quantity: number;
  price: number;
}

export type EducationArticleSource = 'contentful';

export interface IEducationArticleViewedEvent extends IEucalyptusEvent {
  userId: string;
  readingId: string;
  educationArticleId: string;
  educationArticleSource: EducationArticleSource;
  educationProgramId: string | undefined;
}

export interface IEducationArticleReadEvent extends IEucalyptusEvent {
  userId: string;
  readingId: string;
  educationArticleId: string;
  educationArticleSource: EducationArticleSource;
  educationProgramId: string | undefined;
}

export interface IChecklistGeneratedEvent extends IEucalyptusEvent {
  type: ChecklistType;
  contentType?: string | null;
  attributes?: ChecklistAttribute | null;
}

export interface ICalendarReminderAddedEvent extends IEucalyptusEvent {
  calendarType: string;
  itemCode: string;
  source: string;
}

export interface IDoctorVideoEvent extends IEucalyptusEvent {
  doctorMessageId: string;
  consultationId: string;
  resourceId: string;
  filename: string;
}

export interface IVideoPlayed extends IEucalyptusEvent {
  wistiaVideoId: string;
  videoName?: string;
  stage: string;
  consultationId?: string;
  consultationStatus?: ConsultationStatus;
  problemType?: ProblemType;
  quizApplicationId?: string;
}

export type ContactConsent = 'EMAIL' | 'NONE';

export interface SwitchOfferingSelected extends IEucalyptusEvent {
  offeringId: string;
}

export interface PafAddonsViewed extends IEucalyptusEvent {
  offeringIds: string[];
  pricingSessionId: string;
}

export interface PafCartItemQuantityModified extends IEucalyptusEvent {
  offeringId: string;
  quantity: number;
  pricingSessionId: string;
  quantityChangeSource: 'ADDON_CARD' | 'ADDON_PAGE' | 'ORDER_SUMMARY';
}

export interface IQuizResetViewedEvent extends IEucalyptusEvent {
  oldQuizApplicationId: string;
  hadConsult: boolean;
}
