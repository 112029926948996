import { Typography, Checkbox } from '@eucalyptusvc/design-system';
import { DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import { UseShippingFormFields } from '../logic';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AddressFields, IntlAddressInput } from '@customer-frontend/intl';

interface AddressDetailsFormProps {
  hasResidentialAddress: boolean;
  errors: DeepMap<UseShippingFormFields, FieldError>;
  register: UseFormMethods['register'];
  onHomeAddressChanged: (data: Partial<AddressFields>) => void;
  handleOnUseHomeAddressChange: (e: React.SyntheticEvent) => void;
  onShippingAddressChanged: (data: Partial<AddressFields>) => void;
  isSameAsHome: boolean;
  homeHeaderComponent?: React.ReactNode;
  shippingHeaderComponent?: React.ReactNode;
  palette?: 'default' | 'alternate';
}

export const AddressDetailsForm = ({
  errors,
  palette,
  register,
  isSameAsHome,
  homeHeaderComponent,
  onHomeAddressChanged,
  hasResidentialAddress,
  shippingHeaderComponent,
  onShippingAddressChanged,
  handleOnUseHomeAddressChange,
}: AddressDetailsFormProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {!hasResidentialAddress && (
        <div>
          {homeHeaderComponent || (
            <div className="mb-4">
              <Typography element="h3" size="md" isBold>
                <FormattedMessage defaultMessage="Home address" />
              </Typography>
            </div>
          )}
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="This is for the purpose of your medical record. <strong>Please ensure the details are truthful.</strong>"
              description=""
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
          <div className="mt-6">
            <IntlAddressInput
              className="mt-3"
              name="residentialAddress"
              errors={errors.residentialAddress ?? {}}
              registerField={register}
              useAutoComplete
              onChange={onHomeAddressChanged}
              palette={palette}
            />
          </div>
        </div>
      )}
      <div>
        {shippingHeaderComponent || (
          <div className="mb-4">
            <Typography element="h3" size="md" isBold>
              <FormattedMessage defaultMessage="Shipping address" />
            </Typography>
          </div>
        )}
        <div className="mt-6">
          <Checkbox
            palette="alternate"
            isChecked={isSameAsHome}
            label={formatMessage({
              defaultMessage: 'Use your home address',
              description:
                'Label for checkbox that sets the shipping address to the users home address',
            })}
            onChange={handleOnUseHomeAddressChange}
          />
          <IntlAddressInput
            className="mt-3"
            name="shippingAddress"
            errors={errors.shippingAddress ?? {}}
            registerField={register}
            useAutoComplete
            onChange={onShippingAddressChanged}
            palette={palette}
          />
        </div>
      </div>
    </div>
  );
};
