import { gql, QueryResult, useQuery } from '@apollo/client';
import { User } from '@customer-frontend/graphql-types';
import { subscriptionPlanV2Fragment } from './subscription-plan-v2-fragment';

export const GET_PROFILE_SUBSCRIPTIONS_V2_QUERY = gql`
  query UseProfileSubscriptionsV2 {
    profile {
      id
      needsSubscriptionMigration
      subscriptions {
        id
        status
        nextPaymentAt
        subscriptionOrders {
          id
          status
          createdAt
        }
        subscriptionPlan {
          ...OtcSubscriptionPlanV2Info
        }
      }
    }
  }
  ${subscriptionPlanV2Fragment}
`;

export const useProfileSubscriptionsV2 = (): QueryResult<{ profile: User }> => {
  return useQuery<{ profile: User }>(GET_PROFILE_SUBSCRIPTIONS_V2_QUERY, {
    fetchPolicy: 'network-only',
  });
};
