import React from 'react';
import { Card, Typography } from '@eucalyptusvc/design-system';
import { ObservedMeasurement, Maybe } from '@customer-frontend/graphql-types';
import { TrackerChart } from '../chart';
import { getInitialAndCurrent } from '../../utils';
import { EmptyTrackerCardContent } from './empty-tracker-card-content';
import { useTrackerTheme } from '../../provider';
import {
  getChartContentTitleClassName,
  getCurrentWeightTitleClassName,
  getDateClassName,
  getWeightDifferencePercentClassName,
  getCurrentWeightValueClassName,
} from './styles';
import { TrackerMetric } from '../types';
import clsx from 'clsx';
import { ChartErrorBoundary } from '../chart-error-boundary';
import { Logger } from '@customer-frontend/logger';
import { defineMessage, MessageDescriptor, FormattedMessage } from 'react-intl';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

export const NO_DATA_CONTENT_FOR_METRIC: {
  [key in TrackerMetric]: {
    title: MessageDescriptor;
    message: MessageDescriptor;
  };
} = {
  weight: {
    title: defineMessage({ defaultMessage: 'No measurements recorded yet' }),
    message: defineMessage({
      defaultMessage:
        "Once you've started our program, it's time to check-in and track your weight here.",
    }),
  },
  waist: {
    title: defineMessage({ defaultMessage: 'No measurements recorded yet' }),
    message: defineMessage({
      defaultMessage:
        'Weight is only half of the detail. A waist measurement will provide you and your practitioner a more accurate look at your overall progress.',
    }),
  },
};

const LoadingState = (): React.ReactElement => (
  <div className="animate-pulse flex flex-col space-y-6">
    <div className="w-32 bg-gray-200 h-8 rounded-md" />
    <div className="w-44 bg-gray-200 h-16 rounded-md" />
    <div className="w-full bg-gray-200 h-72 rounded-md " />
  </div>
);

const Content = ({
  title,
  data,
  unit,
  metric,
  logger,
}: {
  title: string;
  unit: string;
  data: Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[];
  metric?: TrackerMetric;
  logger: Logger;
}): React.ReactElement => {
  const theme = useTrackerTheme();

  const config = getConfig();

  const titleClassName = getChartContentTitleClassName(theme);
  const currentWeightTitleClassName = getCurrentWeightTitleClassName(theme);
  const currentWeightValueClassName = getCurrentWeightValueClassName(theme);
  const dateClassName = getDateClassName(theme);
  const weightDifferencePercentClassName = getWeightDifferencePercentClassName(
    theme,
    metric,
  );

  const { initial, current } = getInitialAndCurrent(data);

  const percentDifference =
    initial && current
      ? ((current.value - initial.value) / initial.value) * 100
      : 0;

  return (
    <div className="flex flex-col">
      <div className={titleClassName}>
        <Typography size="md" isBold element="h2" inheritColor>
          {title}
        </Typography>
      </div>
      <div>
        <div className={currentWeightTitleClassName}>
          <Typography size="small-text" isBold inheritColor>
            <span className="uppercase">Current</span>
          </Typography>
        </div>
        <div className="flex items-center space-x-2 mb-8">
          <div className={currentWeightValueClassName}>
            <Typography size="xl" isBold element="h3" inheritColor>
              {(current ?? initial)?.value.toFixed(1)}
              {unit}
            </Typography>
          </div>
          {!!current && (
            <div className={weightDifferencePercentClassName}>
              <Typography size="paragraph" element="span" inheritColor>
                {percentDifference.toFixed(1)}%
              </Typography>
            </div>
          )}
        </div>
        <ChartErrorBoundary logger={logger} variant="simplified">
          <TrackerChart data={data} metric={metric} />
        </ChartErrorBoundary>
        <div
          className={clsx('flex w-full mt-2 justify-between', dateClassName)}
        >
          <Typography size="paragraph" element="span">
            {formatDate(
              mapBrandToAdaptersBrand(config.brand),
              initial?.effectiveFrom,
              { month: 'short', day: 'numeric' },
            )}
          </Typography>
          <Typography size="paragraph" element="span">
            {!!current &&
              formatDate(
                mapBrandToAdaptersBrand(config.brand),
                current?.effectiveFrom,
                { month: 'short', day: 'numeric' },
              )}
          </Typography>
        </div>
      </div>
    </div>
  );
};

interface TrackerCardProps {
  title: string;
  unit: string;
  loading: boolean;
  data: Maybe<Pick<ObservedMeasurement, 'effectiveFrom' | 'value'>[]>;
  metric: TrackerMetric;
  logger: Logger;
}

export const TrackerCard = ({
  loading,
  data,
  title,
  unit,
  metric,
  logger,
}: TrackerCardProps): React.ReactElement | null => {
  const theme = useTrackerTheme();
  const cardPalette = theme?.brand?.trackerCard?.palette;

  if (loading) {
    return (
      <Card palette={cardPalette}>
        <LoadingState />
      </Card>
    );
  }

  if (!data?.length) {
    return (
      <EmptyTrackerCardContent
        chartTitle={title}
        emptyTitle={
          <FormattedMessage {...NO_DATA_CONTENT_FOR_METRIC[metric].title} />
        }
        emptyMessage={
          <FormattedMessage {...NO_DATA_CONTENT_FOR_METRIC[metric].message} />
        }
      />
    );
  }

  return (
    <Card palette={cardPalette}>
      <Content
        data={data}
        title={title}
        unit={unit}
        metric={metric}
        logger={logger}
      />
    </Card>
  );
};
