import { FC } from 'react';
import { Rectangle } from 'recharts';
import { useTrackerTheme } from '../../../provider';

type CustomCursorProps = {
  points?: { x: number; y: number }[];
  height?: number;
};

export const CustomCursor: FC<CustomCursorProps> = ({ points, height }) => {
  const theme = useTrackerTheme();
  const trackerTheme = theme.brand?.enhancedTrackerChart;

  const xPoint = points?.[0]?.x;
  const yPoint = points?.[0]?.y;

  if (xPoint === undefined || yPoint === undefined) {
    return null;
  }

  return (
    <Rectangle
      fill={trackerTheme?.cursor?.color}
      stroke={trackerTheme?.cursor?.color}
      x={xPoint}
      y={yPoint}
      width={1}
      height={height}
    />
  );
};
