import { useEventService } from '@customer-frontend/events';
import { useMemo } from 'react';

interface DoctorVideoEventsParams {
  consultationId: string;
  consultationQuestionId?: string;
  resourceId: string;
  filename: string;
}

interface DoctorVideoEvents {
  playedEvent: () => Promise<void>;
  pausedEvent: () => Promise<void>;
  completedEvent: () => Promise<void>;
}

export const useDoctorVideoEvents = ({
  consultationId,
  consultationQuestionId,
  resourceId,
  filename,
}: DoctorVideoEventsParams): DoctorVideoEvents => {
  const event = useEventService();

  return useMemo<DoctorVideoEvents>(() => {
    if (!consultationQuestionId) {
      // Not a doctor message
      return {
        playedEvent: () => Promise.resolve(),
        pausedEvent: () => Promise.resolve(),
        completedEvent: () => Promise.resolve(),
      };
    }

    const params = {
      consultationId,
      resourceId,
      filename,
      doctorMessageId: consultationQuestionId,
    };

    return {
      playedEvent: () => event.doctorMessageVideo.played(params),
      pausedEvent: () => event.doctorMessageVideo.paused(params),
      completedEvent: () => event.doctorMessageVideo.completed(params),
    };
  }, [
    consultationId,
    consultationQuestionId,
    event.doctorMessageVideo,
    filename,
    resourceId,
  ]);
};
