import { gql, useQuery, useSuspenseQuery } from '@apollo/client';
import { useFeatureFlagClient } from '@customer-frontend/feature-flags';
import {
  OrderSummaryGippFragment,
  ProblemType,
  PurchaseActivationFlowGippQuery,
  PurchaseActivationFlowGippQueryVariables,
  PurchaseActivationFlowOfferingVerticalsFragment,
  PurchaseActivationFlowProblemTypeQuery,
  PurchaseActivationFlowProblemTypeQueryVariables,
  PurchaseActivationFlowProviderQuery,
  PurchaseActivationFlowProviderQueryVariables,
} from '@customer-frontend/graphql-types';
import { Logger } from '@customer-frontend/logger';
import { notificationService } from '@customer-frontend/notifications';
import { useLocalStorage } from 'react-use';
import { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { OfferingHighlights } from './offering-highlights';
import { useBackButtonBehaviour } from '@customer-frontend/services';
import {
  clearDiscountParams,
  usePersistedDiscountFromURL,
} from '@customer-frontend/order';

type Stage = {
  key: 'review' | 'select_plan' | 'addons' | 'add_details' | 'confirm';
  available: boolean;
  label: string;
};

type OfferingSelection = {
  offeringId: string;
  sequenceSelections: Array<{
    sequenceSetId: string;
    sequenceId: string;
  }>;
  addons: Omit<OfferingSelection, 'addons'>[];
};

const orderSummaryGIPPFragment = gql`
  fragment OrderSummaryGIPP on InitialPurchasePriceList {
    id
    amount
    coupons {
      id
      code
      outcome
    }
    discountAmount
    originalAmount
    sessionCreditAmount
    offeringPriceLists {
      id
      offering {
        id
        advertisedName
        friendlyName
        photoUrl
        advertisedCostCadence
      }
      sequencePriceLists {
        id
        sequence {
          id
          friendlyName
          ...OfferingHighlightsSequence
        }
        sequenceSet {
          id
          sequences {
            id
          }
        }
        pricedItems {
          id
          unitPrice
          quantity
          variant {
            id
            public
            product {
              id
              name
              deliveryInformation
              photo {
                id
                url
              }
            }
          }
        }
      }
    }
  }
  ${OfferingHighlights.sequenceFragment}
`;

type PurchaseActivationFlowState = {
  consultationId: string;
  consultationProblemType?: ProblemType;
  pricingSessionId: string;
  routes: {
    review(id: string): string;
    tierSelection(id: string): string;
    addons(id: string): string;
    addDetails(id: string): string;
    confirm(id: string): string;
    receipt(id: string): string;
    profile: string;
    practitionerLetter(id: string): string;
    pharmacyInformation(id: string): string;
    handlePurchaseOfferingsIntent: string;
  };
  logger?: Logger;

  // list of stages to be shown in the header
  headerStages: Stage[];
  // used by the header component to highlight current stage
  currentStageIdx: number;
  // to be used to populate the order summary on the right hand side of the page
  // includes pricing information and coupon code results
  // and maybe for receipts
  orderSummary: OrderSummaryGippFragment | undefined | null;
  // true when we're gipping, should drive a loading spinner
  loadingOrderSummary: boolean;
  // - will be called when customer selects different offering
  // - sets loadingOrderSummary as true, clears orderSummary
  // - triggers a gipp to be run
  // - updates orderSummary
  onOfferingSelectionUpdated(
    selection: Omit<OfferingSelection, 'addons'>,
  ): void;
  onPurchaseConfirmed(): void;
  addonSelections: OfferingSelection['addons'];
  currentOfferingSelection: OfferingSelection | undefined;
  promptedOfferingSelection: OfferingSelection | undefined;
  // re-runs gipp
  onDiscountCodeAdded(code: string): void;
  // re-runs gipp without code
  onDiscountCodeRemoved(code: string): void;
  onAddonOfferingSelection(
    selection: Omit<OfferingSelection, 'addons'>,
    quantity: number,
  ): void;
  previousPage(): void;
  nextPage(): void;
};

const notImplemented = () => {
  throw new Error('default value');
};

const PurchaseActivationFlowContext =
  createContext<PurchaseActivationFlowState>({
    consultationId: '',
    pricingSessionId: '',
    headerStages: [],
    currentStageIdx: 0,
    orderSummary: undefined,
    consultationProblemType: undefined,
    loadingOrderSummary: false,
    routes: {
      review: notImplemented,
      tierSelection: notImplemented,
      addons: notImplemented,
      addDetails: notImplemented,
      confirm: notImplemented,
      receipt: notImplemented,
      profile: '',
      practitionerLetter: notImplemented,
      pharmacyInformation: notImplemented,
      handlePurchaseOfferingsIntent: '',
    },
    currentOfferingSelection: undefined,
    promptedOfferingSelection: undefined,
    addonSelections: [],
    onOfferingSelectionUpdated() {
      throw new Error('not implemented.');
    },
    onAddonOfferingSelection() {
      throw new Error('not implemented');
    },
    onPurchaseConfirmed() {
      throw new Error('not implemented.');
    },
    onDiscountCodeAdded() {
      throw new Error('not implemented.');
    },
    onDiscountCodeRemoved() {
      throw new Error('not implemented.');
    },
    previousPage() {
      throw new Error('not implemented.');
    },
    nextPage() {
      throw new Error('not implemented.');
    },
  });

type PurchaseActivationFlowProviderProps = {
  consultationId: string;
  logger: Logger;
  routes: PurchaseActivationFlowRoutes;
};

function useHeaderStages({
  tiersEnabled,
  addonsEnabled,
  needsDetails,
  offeringHasVerticals,
}: {
  tiersEnabled: boolean;
  addonsEnabled: boolean;
  needsDetails: boolean;
  offeringHasVerticals: boolean;
}): Stage[] {
  const intl = useIntl();

  const stages: Stage[] = [
    {
      key: 'review',
      available: true,
      label: intl.formatMessage({
        defaultMessage: 'Review',
        description:
          'Header label for the review section in the purchase activation flow',
      }),
    },
    {
      key: 'select_plan',
      available: tiersEnabled && offeringHasVerticals,
      label: intl.formatMessage({
        defaultMessage: 'Select Plan',
        description:
          'Header label for the select plan section in the purchase activation flow',
      }),
    },
    {
      key: 'addons',
      available: addonsEnabled,
      label: intl.formatMessage({
        defaultMessage: 'Add-ons',
        description:
          'Header label for the add-ons section in the purchase activation flow',
      }),
    },
    {
      key: 'add_details',
      available: needsDetails,
      label: intl.formatMessage({
        defaultMessage: 'Add Details',
        description:
          'Header label for the add details section in the purchase activation flow',
      }),
    },
    {
      key: 'confirm',
      available: true,
      label: intl.formatMessage({
        defaultMessage: 'Confirm',
        description:
          'Header label for the confirm in the purchase activation flow',
      }),
    },
  ];

  return stages.filter((s) => s.available);
}

function getCurrentStageIdx({
  currentRoute,
  routes,
  consultationId,
  headerStages,
}: {
  currentRoute: string;
  consultationId: string;
  routes: PurchaseActivationFlowRoutes;
  headerStages: Stage[];
}): number {
  switch (currentRoute) {
    case routes.review(consultationId):
      return headerStages.findIndex((s) => s.key === 'review');
    case routes.tierSelection(consultationId):
      return headerStages.findIndex((s) => s.key === 'select_plan');
    case routes.addDetails(consultationId):
      return headerStages.findIndex((s) => s.key === 'add_details');
    case routes.addons(consultationId):
      return headerStages.findIndex((s) => s.key === 'addons');
    case routes.confirm(consultationId):
      return headerStages.findIndex((s) => s.key === 'confirm');
    default:
      return -1;
  }
}

function getRouteForStage({
  routes,
  stage,
  consultationId,
}: {
  routes: PurchaseActivationFlowRoutes;
  stage?: Stage;
  consultationId: string;
}): string {
  switch (stage?.key) {
    case 'review':
      return routes.review(consultationId);
    case 'select_plan':
      return routes.tierSelection(consultationId);
    case 'add_details':
      return routes.addDetails(consultationId);
    case 'addons':
      return routes.addons(consultationId);
    case 'confirm':
      return routes.confirm(consultationId);
    default:
      throw new Error(`no route for stage "${stage}"`);
  }
}

export function PurchaseActivationFlowProvider({
  children,
  routes,
  consultationId,
  logger,
}: React.PropsWithChildren<PurchaseActivationFlowProviderProps>) {
  const ffClient = useFeatureFlagClient();
  const tiersEnabled = ffClient.getBoolean(
    'FF_FLEXI_BRAND_TIER_SELECTION_ENABLED',
  );
  const location = useLocation();
  const { formatMessage } = useIntl();
  const history = useHistory();
  useBackButtonBehaviour(() => history.goBack());

  const consultProblemTypeResponse = useSuspenseQuery<
    PurchaseActivationFlowProblemTypeQuery,
    PurchaseActivationFlowProblemTypeQueryVariables
  >(
    gql`
      query PurchaseActivationFlowProblemType($consultationId: String!) {
        consultation(id: $consultationId) {
          id
          type
        }
      }
    `,
    {
      variables: {
        consultationId,
      },
      fetchPolicy: 'cache-first',
    },
  );

  if (!consultProblemTypeResponse?.data?.consultation?.type) {
    throw new Error('expected to find a problem type after suspense call');
  }

  const { data } = useSuspenseQuery<
    PurchaseActivationFlowProviderQuery,
    PurchaseActivationFlowProviderQueryVariables
  >(
    gql`
      query PurchaseActivationFlowProvider(
        $consultationId: String!
        $problemType: ProblemType!
        $stage: PafStage!
      ) {
        profile {
          id
          phone
          address {
            id
          }
        }
        pafAddonSection(problemType: $problemType, stage: $stage) {
          id
        }
        consultation(id: $consultationId) {
          id
          purchasePrompt {
            id
            ... on ConfirmPurchasePrompt {
              id
              purchaseGroup {
                id
                pricingSessionId
                purchases {
                  id
                  offering {
                    id
                    ...PurchaseActivationFlowOfferingVerticals
                  }
                  contexts {
                    id
                    sequence {
                      id
                    }
                    sequenceSet {
                      id
                    }
                  }
                }
              }
            }
            ... on SubstitutePurchasePrompt {
              id
              pricingSessionId
              substitutions {
                id
                substitution {
                  id
                  offering {
                    id
                    ...PurchaseActivationFlowOfferingVerticals
                  }
                  sequenceSelections {
                    id
                    sequence {
                      id
                    }
                    sequenceSet {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }

      fragment PurchaseActivationFlowOfferingVerticals on Offering {
        upsells {
          id
        }
        downsells {
          id
        }
      }
    `,
    {
      variables: {
        consultationId,
        problemType: consultProblemTypeResponse.data.consultation.type,
        stage: 'INITIAL',
      },
    },
  );

  let promptedOfferingVerticals:
    | PurchaseActivationFlowOfferingVerticalsFragment
    | undefined;
  let promptedOfferingSelection: OfferingSelection | undefined;
  const purchasePrompt = data?.consultation?.purchasePrompt;
  let pricingSessionId: string | undefined;

  switch (purchasePrompt?.__typename) {
    case 'ConfirmPurchasePrompt': {
      if (purchasePrompt.purchaseGroup.purchases?.length !== 1) {
        throw new Error(
          'expected exactly 1 purchase in confirm prompt purchase group',
        );
      }
      const purchase = purchasePrompt.purchaseGroup.purchases[0];
      if (!purchase.offering) {
        throw new Error('expected purchase to have a linked offering');
      }
      pricingSessionId = purchasePrompt.purchaseGroup.pricingSessionId;
      promptedOfferingVerticals = purchase.offering;
      promptedOfferingSelection = {
        offeringId: purchase.offering.id,
        sequenceSelections: [],
        addons: [],
      };
      for (const c of purchase.contexts ?? []) {
        if (c.sequence?.id && c.sequenceSet?.id) {
          promptedOfferingSelection.sequenceSelections.push({
            sequenceId: c.sequence.id,
            sequenceSetId: c.sequenceSet.id,
          });
        }
      }
      break;
    }
    case 'SubstitutePurchasePrompt': {
      pricingSessionId = purchasePrompt.pricingSessionId;
      const firstSubstitution = purchasePrompt.substitutions?.[0]?.substitution;
      if (!firstSubstitution?.offering) {
        throw new Error('expected substitution to have a linked offering');
      }
      promptedOfferingVerticals = firstSubstitution.offering;
      promptedOfferingSelection = {
        offeringId: firstSubstitution.offering.id,
        sequenceSelections: [],
        addons: [],
      };
      for (const s of firstSubstitution.sequenceSelections ?? []) {
        if (s.sequence?.id && s.sequenceSet?.id) {
          promptedOfferingSelection.sequenceSelections.push({
            sequenceId: s.sequence.id,
            sequenceSetId: s.sequenceSet.id,
          });
        }
      }
      break;
    }
    default:
      throw new Error(
        `purchase prompt type "${purchasePrompt?.__typename} not supported"`,
      );
  }

  const [
    offeringSelections = [promptedOfferingSelection],
    setOfferingSelections,
    clearOfferingSelections,
  ] = useLocalStorage(`consultation/${consultationId}/paf/offeringSelection`, [
    promptedOfferingSelection,
  ]);

  const { clearCode, persistedDiscountCode } = usePersistedDiscountFromURL();
  const [couponCodes, setCouponCodes] = useState<string[] | undefined>(() => {
    return persistedDiscountCode ? [persistedDiscountCode] : undefined;
  });
  const [debouncedOfferingSelections, setDebouncedOfferingSelections] =
    useState(offeringSelections);
  const shouldDebounce = useRef(false);

  // Debounces subsequent offering selections to avoid request
  // storms if the customer spams quantity selection changes.
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (!shouldDebounce.current) {
      orderSummaryRef.current = undefined;
      shouldDebounce.current = true;
      setDebouncedOfferingSelections(offeringSelections);
    } else {
      timer = setTimeout(() => {
        orderSummaryRef.current = undefined;
        shouldDebounce.current = false;
        setDebouncedOfferingSelections(offeringSelections);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [offeringSelections]);

  const { data: gippData, loading: loadingOrderSummary } = useQuery<
    PurchaseActivationFlowGippQuery,
    PurchaseActivationFlowGippQueryVariables
  >(
    gql`
      query PurchaseActivationFlowGIPP(
        $consultationId: String!
        $pricingSessionId: ID!
        $offeringSelections: [OfferingSelectionInput!]!
        $couponCodes: [String!]
      ) {
        initialPurchasePrice(
          consultationId: $consultationId
          pricingSessionId: $pricingSessionId
          offeringSelections: $offeringSelections
          couponCodes: $couponCodes
        ) {
          id
          ...OrderSummaryGIPP
        }
      }
      ${orderSummaryGIPPFragment}
    `,
    {
      variables: {
        consultationId,
        pricingSessionId,
        offeringSelections: debouncedOfferingSelections,
        couponCodes,
      },
      onCompleted(data) {
        for (const c of data?.initialPurchasePrice?.coupons ?? []) {
          if (c.code === persistedDiscountCode) {
            clearCode();
            clearDiscountParams();
          }
          let message: string | undefined;
          switch (c.outcome) {
            case 'EXPIRED':
              message = formatMessage(
                { defaultMessage: 'Discount code {coupon} has expired' },
                { coupon: c.code },
              );
              break;
            case 'GENERIC_FAILURE':
              message = formatMessage(
                {
                  defaultMessage: 'Discount code {coupon} could not be applied',
                },
                { coupon: c.code },
              );
              break;
            case 'NOT_FOUND':
              message = formatMessage(
                { defaultMessage: 'Discount code {coupon} not found' },
                { coupon: c.code },
              );
              break;
          }
          if (message) {
            notificationService.show({
              type: 'error',
              message,
            });
          }
        }
      },
    },
  );

  const orderSummaryRef = useRef(gippData?.initialPurchasePrice);
  if (gippData?.initialPurchasePrice) {
    orderSummaryRef.current = gippData.initialPurchasePrice;
  }

  // We don't want to stop showing the details page after they added details
  const [needsDetails] = useState(
    !data?.profile?.phone || !data?.profile.address?.id,
  );

  const headerStages = useHeaderStages({
    tiersEnabled,
    addonsEnabled: !!data?.pafAddonSection?.id,
    needsDetails,
    offeringHasVerticals:
      !!promptedOfferingVerticals.downsells?.length ||
      !!promptedOfferingVerticals?.upsells?.length,
  });

  const currentStageIdx = getCurrentStageIdx({
    headerStages,
    currentRoute: location.pathname,
    consultationId,
    routes,
  });

  return (
    <PurchaseActivationFlowContext.Provider
      value={{
        routes,
        consultationId,
        consultationProblemType:
          consultProblemTypeResponse.data.consultation?.type,
        pricingSessionId,
        currentStageIdx,
        headerStages,
        loadingOrderSummary,
        logger,
        orderSummary: orderSummaryRef.current,
        promptedOfferingSelection,
        currentOfferingSelection: offeringSelections[0],
        addonSelections: offeringSelections[0].addons ?? [],
        onDiscountCodeRemoved(code) {
          const newCodes =
            gippData?.initialPurchasePrice?.coupons
              ?.filter((c) => c.outcome === 'SUCCESS' && c.code !== code)
              .map((c) => c.code) ?? [];
          setCouponCodes(newCodes);
        },
        onDiscountCodeAdded(code) {
          const gippCodes =
            gippData?.initialPurchasePrice?.coupons
              ?.filter((c) => c.outcome === 'SUCCESS')
              .map((c) => c.code) ?? [];
          setCouponCodes([...gippCodes, code]);
        },
        onOfferingSelectionUpdated(s) {
          setOfferingSelections([
            {
              ...s,
              addons: offeringSelections[0].addons ?? [],
            },
          ]);
        },
        onAddonOfferingSelection(selection, quantity) {
          const addons: OfferingSelection['addons'] = [];
          const existingAddons = offeringSelections[0].addons ?? [];

          // put the existing add-ons back
          for (const addon of existingAddons) {
            if (addon.offeringId !== selection.offeringId) {
              addons.push(addon);
            }
          }

          for (let i = 0; i < quantity; i += 1) {
            addons.push(selection);
          }

          setOfferingSelections([
            {
              ...offeringSelections[0],
              addons,
            },
          ]);
        },
        onPurchaseConfirmed() {
          clearOfferingSelections();
        },
        previousPage() {
          if (currentStageIdx <= 0) {
            return;
          }
          const previousStage = headerStages[currentStageIdx - 1];
          const newRoute = getRouteForStage({
            consultationId,
            routes,
            stage: previousStage,
          });
          history.push(newRoute);
        },
        nextPage() {
          if (currentStageIdx < 0) {
            return;
          }
          const currentStage = headerStages[currentStageIdx];
          if (currentStage.key === 'confirm') {
            return history.push(routes.receipt(consultationId));
          }
          const nextStage = headerStages[currentStageIdx + 1];
          const newRoute = getRouteForStage({
            consultationId,
            routes,
            stage: nextStage,
          });
          history.push(newRoute);
        },
      }}
    >
      {children}
    </PurchaseActivationFlowContext.Provider>
  );
}

export function usePurchaseActivationFlow() {
  return useContext(PurchaseActivationFlowContext);
}

export type PurchaseActivationFlowRoutes =
  PurchaseActivationFlowState['routes'];
