export const getTranslationFile = (
  locale: string,
): Promise<Record<string, unknown>> | undefined => {
  // German
  if (locale.toLowerCase().match('de')) {
    return import('./de.json');
  }
  // Japanese
  if (locale.toLowerCase().match('ja')) {
    return import('./ja.json');
  }
};
