import { gql } from '@apollo/client';
import { discountCodeFormFragment } from './discountCodeForm';

export const treatmentFragment = gql`
  ${discountCodeFormFragment}
  fragment TreatmentInfo on Treatment {
    id
    updatedAt
    status
    type
    canRefill
    nextPayment
    refillsLeft
    hasUnfulfilledOrder
    canPause
    canReschedule
    weightTreatmentCategory
    treatmentDiscount {
      id
      discountCode {
        ...DiscountCodeForm
      }
    }
    consultation {
      id
      type
      doctor {
        id
        firstName
        lastName
        fullClinicianName
        fullName
      }
    }
    customer {
      id
      email
    }
    plan {
      id
      amount
      products {
        id
        variants {
          id
          inventory {
            id
            sku
          }
        }
      }
    }

    product {
      id
      name
      description
      safetyInformation
      friendlyName
      productType

      drug {
        id
        name
      }

      variants {
        id
        price
        inventory {
          id
          sku
        }
      }

      faqs {
        id
        title
        markdown
      }

      cmi {
        id
        url
      }

      photo {
        id
        url
      }
    }

    script {
      id
      createdAt
    }

    orders(orderBy: { createdAt: desc }) {
      id
      fulfillment
      updatedAt
    }

    otcSchedules {
      id
      createdAt
      updatedAt
      cadence
      isActive
      quantity
      product {
        id
        name
        productType
        variants {
          id
          price
        }
        photo {
          id
          url
        }
      }
    }

    experimentPaymentPlan {
      id
      nextPaymentAmount
      planEndDate
      paymentPlan
      discountPercentage
      months
      otcSchedules {
        id
        product {
          id
          name
        }
      }
    }
    experimentAvailablePaymentPlans {
      paymentPlan
      cadence
    }
  }
`;
