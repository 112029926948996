import {
  gql,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';
import {
  MoveConsultationPaidToOrderPaidMutation,
  MoveConsultationPaidToOrderPaidMutationVariables,
  MutationMoveConsultationPaidToOrderPaidArgs,
} from '@customer-frontend/graphql-types';

const moveConsultationPaidToOrderPaid = gql`
  mutation MoveConsultationPaidToOrderPaid(
    $consultationId: String!
    $doctorId: String
    $productId: String
  ) {
    moveConsultationPaidToOrderPaid(
      consultationId: $consultationId
      doctorId: $doctorId
      productId: $productId
    ) {
      success
    }
  }
`;

export function useMoveConsultationPaidToOrderPaid(
  options: MutationHookOptions<
    MoveConsultationPaidToOrderPaidMutation,
    MutationMoveConsultationPaidToOrderPaidArgs
  >,
): MutationTuple<
  MoveConsultationPaidToOrderPaidMutation,
  MutationMoveConsultationPaidToOrderPaidArgs
> {
  return useMutation<
    MoveConsultationPaidToOrderPaidMutation,
    MoveConsultationPaidToOrderPaidMutationVariables
  >(moveConsultationPaidToOrderPaid, options);
}
