import React from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner } from '@eucalyptusvc/design-system';

export const CompleteGeneralQuiz = ({
  profileRoute,
}: {
  profileRoute: string;
}): React.ReactElement => {
  const history = useHistory();

  React.useEffect(() => {
    return history.push(profileRoute);
  }, [history, profileRoute]);

  return (
    <div className="flex justify-center p-5">
      <LoadingSpinner />
    </div>
  );
};
