import { add } from 'date-fns';
import { MedicareComponentAttributes } from '../types';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

export type DevPatient =
  | 'aoul-copeland'
  | 'beryl-hewitt'
  | 'beverly-gay'
  | 'brian-alexander'
  | 'brooke-corbett'
  | 'claude-jacobson'
  | 'daniella-lamb'
  | 'desiree-bowles'
  | 'faye-jenkins'
  | 'gretel-boyette'
  | 'herb-sheep'
  | 'horacio-green'
  | 'jermiah-clapp'
  | 'layla-lanier'
  | 'lisa-barber'
  | 'luke-kelley';

// Original data source - https://www.notion.so/eucalyptus-vc/Medicare-Test-Data-e536de4f5e15492aaa1dc2b43e2c82be#34ef7f4720d4480487ddb4e6a2b38ce1
export const getDevMedicarePatientDetails = (
  patient: DevPatient,
): MedicareComponentAttributes => {
  const config = getConfig();

  const medicareCardExpiry = formatDate(
    mapBrandToAdaptersBrand(config.brand),
    add(new Date(), { years: 1 }),
    { month: '2-digit', year: 'numeric' },
  );

  switch (patient) {
    case 'aoul-copeland':
      return {
        firstName: 'AOUL',
        lastName: 'COPELAND',
        birthday: '16/07/1962',
        sex: 'M',
        medicareNumber: '6950158192',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'beryl-hewitt':
      return {
        firstName: 'BERYL',
        lastName: 'HEWITT',
        birthday: '17/02/1976',
        sex: 'F',
        medicareNumber: '2950216382',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'beverly-gay':
      return {
        firstName: 'BEVERLY',
        lastName: 'GAY',
        birthday: '04/08/1976',
        sex: 'F',
        medicareNumber: '4950177982',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'brian-alexander':
      return {
        firstName: 'BRIAN',
        lastName: 'ALEXANDER',
        birthday: '03/09/1948',
        sex: 'M',
        medicareNumber: '4950177892',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'brooke-corbett':
      return {
        firstName: 'BROOKE',
        lastName: 'CORBETT',
        birthday: '10/11/1963',
        sex: 'F',
        medicareNumber: '4950177522',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'claude-jacobson':
      return {
        firstName: 'CLAUDE',
        lastName: 'JACOBSON',
        birthday: '20/03/1964',
        sex: 'M',
        medicareNumber: '2950215952',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'daniella-lamb':
      return {
        firstName: 'DANIELLA',
        lastName: 'LAMB',
        birthday: '20/10/1964',
        sex: 'F',
        medicareNumber: '3950132722',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'desiree-bowles':
      return {
        firstName: 'DESIREE',
        lastName: 'BOWLES',
        birthday: '19/07/1958',
        sex: 'F',
        medicareNumber: '4950177252',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'faye-jenkins':
      return {
        firstName: 'FAYE',
        lastName: 'JENKINS',
        birthday: '09/02/1971',
        sex: 'F',
        medicareNumber: '4950177432',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'gretel-boyette':
      return {
        firstName: 'GRETEL',
        lastName: 'BOYETTE',
        birthday: '06/09/1976',
        sex: 'F',
        medicareNumber: '2950216012',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'herb-sheep':
      return {
        firstName: 'HERB',
        lastName: 'SHEEP',
        birthday: '04/06/1969',
        sex: 'M',
        medicareNumber: '4950177342',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'jermiah-clapp':
      return {
        firstName: 'JEREMIAH',
        lastName: 'CLAPP',
        birthday: '21/05/1978',
        sex: 'M',
        medicareNumber: '3950132902',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'layla-lanier':
      return {
        firstName: 'LAYLA',
        lastName: 'LANIER',
        birthday: '10/10/1956',
        sex: 'F',
        medicareNumber: '4950177702',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'lisa-barber':
      return {
        firstName: 'LISA',
        lastName: 'BARBER',
        birthday: '05/03/1957',
        sex: 'F',
        medicareNumber: '6950158282',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'luke-kelley':
      return {
        firstName: 'LUKE',
        lastName: 'KELLEY',
        birthday: '08/09/1960',
        sex: 'M',
        medicareNumber: '4950177612',
        medicareIrn: '1',
        medicareCardExpiry,
      };
    case 'horacio-green':
    default:
      return {
        firstName: 'HORACIO',
        lastName: 'GREEN',
        birthday: '05/05/1978',
        sex: 'M',
        medicareNumber: '5950096792',
        medicareIrn: '1',
        medicareCardExpiry,
      };
  }
};
