import clsx from 'clsx';
import { Typography } from '@eucalyptusvc/design-system';
import { ContentfulContentType } from '@customer-frontend/contentful';
import { ReactComponent as ClockOutlineIcon } from '../assets/icons/clock-outline.svg';
import { TimeToRead } from './time-to-read';
import { EducationReading } from '@customer-frontend/graphql-types';
import { convertMsToMinutes } from './utils';

type HeroSectionProps = {
  reading: EducationReading;
};

export const HeroSection: React.FC<HeroSectionProps> = ({ reading }) => {
  const content = reading.content as ContentfulContentType;
  const heroImage = content?.fields?.hero?.fields;
  const heroFileUrl = heroImage?.file?.url;
  const heroTitle = heroImage?.title;
  const timeToReadMinutes = reading.timeToReadMs
    ? convertMsToMinutes(reading.timeToReadMs)
    : 1;

  return (
    <>
      <div
        className={clsx(
          'relative z-10 pt-10 pb-14 px-4 sm:pt-20 sm:pb-28 mx-auto flex flex-col md:flex-row gap-4',
          {
            'max-w-screen-sm': !heroFileUrl,
            'max-w-screen-lg': heroFileUrl,
          },
        )}
      >
        <div className="flex-1 flex flex-col space-y-6">
          <TimeToRead
            icon={<ClockOutlineIcon />}
            colorClassName="text-neutral-white"
            textColor="white"
            minutes={timeToReadMinutes}
          />
          <Typography size="2xl" color="white" isBold>
            {content?.fields?.title}
          </Typography>
        </div>
        {heroFileUrl && (
          <div className="flex-1">
            <img
              className="rounded-2xl"
              src={heroFileUrl}
              alt={heroTitle ?? ''}
            />
          </div>
        )}
      </div>
      {heroFileUrl && (
        <hr className="opacity-20 -mt-44 w-screen absolute z-0" />
      )}
    </>
  );
};
