import { ToastPalette, ToastType, ToastIcon } from '.';
import { ReactComponent as StatusError } from '../../assets/status-error.svg';
import { ReactComponent as StatusErrorOutline } from '../../assets/status-error-outline.svg';
import { ReactComponent as StatusSuccess } from '../../assets/status-success.svg';
import { ReactComponent as StatusSuccessOutline } from '../../assets/status-success-outline.svg';
import { ReactComponent as StatusWarning } from '../../assets/status-warning.svg';
import { ReactComponent as StatusWarningOutline } from '../../assets/status-warning-outline.svg';
import { ReactComponent as StatusInfo } from '../../assets/status-info.svg';
import { ReactComponent as StatusInfoOutline } from '../../assets/status-info-outline.svg';

export const getConfig = (type: ToastType, palette: ToastPalette): string => {
  if (palette === 'default') {
    return type;
  }
  return `${type}-${palette}`;
};

type StatusIconDict = { [k: string]: { [k: string]: JSX.Element } };
const statusIcons: StatusIconDict = {
  success: {
    solid: <StatusSuccess width="16" />,
    outline: <StatusSuccessOutline width="16" />,
  },
  error: {
    solid: <StatusError width="16" />,
    outline: <StatusErrorOutline width="16" />,
  },
  warning: {
    solid: <StatusWarning width="16" />,
    outline: <StatusWarningOutline width="16" />,
  },
  info: {
    solid: <StatusInfo width="16" />,
    outline: <StatusInfoOutline width="16" />,
  },
};

export const getIcon = (type: ToastType, iconStyle: ToastIcon): JSX.Element => {
  const icon = statusIcons[type][iconStyle];
  return icon;
};
