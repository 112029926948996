import moment from 'moment';

export const isConsultOlderThanOneMonth = (consultation: {
  quizApplication?: {
    submittedAt?: string | null;
  } | null;
}): boolean => {
  const submittedAt = consultation.quizApplication?.submittedAt;
  if (!submittedAt) {
    return false;
  }

  const consultSubmittedAtDate = moment(new Date(submittedAt));

  if (!consultSubmittedAtDate.isValid()) {
    return false;
  }

  return consultSubmittedAtDate.isBefore(moment().subtract(1, 'M'));
};
