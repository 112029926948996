import React from 'react';
import { useAuth } from '@customer-frontend/auth';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { useResponsive } from '@eucalyptusvc/design-system';

const useMobileAppBannerEnabled = (): boolean => {
  const { isMobile } = useResponsive();
  const mobileAppPromptEnabled = useFeatureFlagBoolean(
    'MOBILE_DOWNLOAD_BANNER',
  );
  const { isLoggedIn } = useAuth();
  const shouldShowDownloadBanner =
    isMobile && mobileAppPromptEnabled && isLoggedIn;
  return shouldShowDownloadBanner;
};

export type DownloadAppBanner = {
  show: boolean;
  setShow: (show: boolean) => void;
};

const DownloadAppBannerContext = React.createContext<DownloadAppBanner>({
  show: false,
  setShow() {
    // noop
  },
});

export function DownloadAppBannerContextProvider(props: {
  children: React.ReactNode;
}): React.ReactElement {
  const [show, setShowState] = React.useState(false);
  const enabled = useMobileAppBannerEnabled();

  const setShow = React.useCallback(
    (newShow: boolean) => {
      setShowState(newShow && enabled);
    },
    [enabled],
  );

  const value = React.useMemo<DownloadAppBanner>(
    () => ({
      show,
      setShow,
    }),
    [setShow, show],
  );

  return (
    <DownloadAppBannerContext.Provider value={value}>
      {props.children}
    </DownloadAppBannerContext.Provider>
  );
}

export function useDownloadAppBannerContext(): DownloadAppBanner {
  return React.useContext(DownloadAppBannerContext);
}
