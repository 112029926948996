import { Typography } from '@eucalyptusvc/design-system';
import { Banner } from '../../banner';
import { FormattedMessage } from 'react-intl';
import { useAuth } from './provider';

export const ImpersonatingUserBanner = (): React.ReactElement | null => {
  const { isImpersonatingUser } = useAuth();

  if (!isImpersonatingUser) {
    return null;
  }

  return (
    <Banner classname="bg-primary-200">
      <Typography isBold size="medium-paragraph" textAlign="center">
        <FormattedMessage defaultMessage="You are impersonating a patient, please be careful with your actions." />
      </Typography>
    </Banner>
  );
};
