import { getConfig } from '@customer-frontend/config';
import { ConsultationStatus } from '@customer-frontend/graphql-types';
import { getPrimaryButtonPalette } from '@customer-frontend/quiz';
import { Typography, Button } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

export const CannotBookErrorMessage = ({
  status,
  clinicianName,
  goBackToProfile,
}: {
  status: ConsultationStatus | undefined;
  clinicianName: string | undefined;
  goBackToProfile: () => void;
}): React.ReactElement => {
  const { supportEmail, brand } = getConfig();

  let errorMessageComponent: React.ReactElement;

  switch (status) {
    case 'DOCTOR_ASSIGNED':
      errorMessageComponent = (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="{hasClinicianName, select, true {{clinicianName}} other {Your practitioner}} is reviewing your information and will be in touch soon. If you need support, please reach out to our team at <a>{supportEmail}</a>."
            description="On scheduling phone call pages"
            values={{
              a: (chunks) => (
                <a href={`mailto:${supportEmail}`} className="underline">
                  {chunks}
                </a>
              ),
              supportEmail,
              clinicianName,
              hasClinicianName: !!clinicianName,
            }}
          />
        </Typography>
      );
      break;
    case 'CUSTOMER_COMPLETED':
      errorMessageComponent = (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Your consult has now finished. You can no longer schedule a call. If this is a mistake, please reach out to our team at <a>{supportEmail}</a>."
            description="On scheduling phone call pages"
            values={{
              a: (chunks) => (
                <a href={`mailto:${supportEmail}`} className="underline">
                  {chunks}
                </a>
              ),
              supportEmail,
            }}
          />
        </Typography>
      );
      break;
    default:
      errorMessageComponent = (
        <Typography size="medium-paragraph">
          <FormattedMessage
            defaultMessage="Sorry, you can no longer schedule a call. If this is a mistake, please reach out to our team at <a>{supportEmail}</a>."
            description="On scheduling phone call pages"
            values={{
              a: (chunks) => (
                <a href={`mailto:${supportEmail}`} className="underline">
                  {chunks}
                </a>
              ),
              supportEmail,
            }}
          />
        </Typography>
      );
      break;
  }

  return (
    <>
      {errorMessageComponent}
      <Button
        isFullWidth
        onClick={goBackToProfile}
        palette={getPrimaryButtonPalette(brand)}
      >
        <FormattedMessage defaultMessage="Back to profile" />
      </Button>
    </>
  );
};
