import { useEffect, useMemo, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import {
  ShippingValidationStatus,
  useShippingAddressValidation,
} from '@customer-frontend/order';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'react-use';

import {
  useProfileAddress,
  useUpdateResidentialAddress,
} from '@customer-frontend/services';
import { notificationService } from '@customer-frontend/notifications';
import {
  getPrimaryButtonPalette,
  getSecondaryButtonPalette,
} from '@customer-frontend/quiz';
import { getConfig } from '@customer-frontend/config';
import { Maybe, ResidentialAddress } from '@customer-frontend/graphql-types';
import { getFullAddressString } from '@customer-frontend/utils';
import { IntlAddressInput, AddressFields } from '@customer-frontend/intl';

type AddressFormData = {
  residentialAddress: {
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    state: string;
    country: string;
  };
};

export const AddressForm = ({
  country,
  submitButtonText,
  afterSubmit,
  validateAddressAsShipping = false,
}: {
  country: string;
  submitButtonText: string;
  afterSubmit?: (validAddress: boolean) => void;
  validateAddressAsShipping?: boolean;
}): JSX.Element => {
  const config = getConfig();
  const { data, loading } = useProfileAddress();
  const addressData = data?.profile?.residentialAddress;
  const shippingAddress = data?.profile?.address;

  const [useShippingAddress, setUseShippingAddress] = useState(false);
  const [fullResidentialAddress, setFullResidentialAddress] = useState('');
  const residentialAddress: Maybe<ResidentialAddress> | Record<string, never> =
    useMemo(() => {
      const address = addressData || {};
      setFullResidentialAddress(getFullAddressString(address));
      return address;
    }, [addressData]);

  const defaultValues = {
    residentialAddress: {
      ...residentialAddress,
      country,
    },
  };

  const { register, errors, setValue, handleSubmit, watch } =
    useForm<AddressFormData>({
      defaultValues,
    });

  useEffect(() => {
    const address =
      useShippingAddress && shippingAddress?.line1
        ? shippingAddress
        : {
            line1: residentialAddress.line1,
            line2: residentialAddress.line2,
            postalCode: residentialAddress.postalCode,
            state: residentialAddress.state,
            city: residentialAddress.city,
          };
    setValue('residentialAddress', {
      ...address,
    });
    setFullResidentialAddress(getFullAddressString(address));
  }, [setValue, residentialAddress, useShippingAddress, shippingAddress]);

  const addressWatch = watch().residentialAddress;

  const canValidatePostcode =
    !!addressWatch?.line1 && !!addressWatch?.city && !!addressWatch?.postalCode;

  const {
    isShippingAddressWithinRange,
    isValidatingShippingAddress,
    handleAddressValidation,
  } = useShippingAddressValidation();

  useDebounce(
    () => {
      if (
        validateAddressAsShipping &&
        canValidatePostcode &&
        (!shippingAddress?.line1 ||
          (shippingAddress?.line1 && useShippingAddress))
      ) {
        handleAddressValidation({
          line1: addressWatch.line1 || '',
          line2: addressWatch.line2,
          postalCode: addressWatch.postalCode || '',
          city: addressWatch.city || '',
        });
      }
    },
    1000,
    [
      canValidatePostcode,
      addressWatch.line1,
      addressWatch.line2,
      addressWatch.postalCode,
      addressWatch.city,
      validateAddressAsShipping,
    ],
  );

  const onShippingAddressChanged = useCallback(
    (data: AddressFields) => {
      setValue('residentialAddress', {
        ...data,
      });
      setFullResidentialAddress(getFullAddressString(data));
    },
    [setValue],
  );

  const { formatMessage } = useIntl();
  const [updateResidentialMutation, { loading: updateLoading }] =
    useUpdateResidentialAddress({});
  const submit = handleSubmit(async ({ residentialAddress }) => {
    try {
      // required because react hook form wont set values for disabled fields
      if (shippingAddress?.line1 && useShippingAddress) {
        await updateResidentialMutation({
          variables: {
            residentialAddress: {
              line1: shippingAddress.line1 ?? residentialAddress.line1,
              line2: shippingAddress.line2 || undefined,
              city: shippingAddress.city ?? residentialAddress.city,
              postalCode:
                shippingAddress.postalCode ?? residentialAddress.postalCode,
              state: shippingAddress.state ?? residentialAddress.state,
              country: country,
            },
          },
        });
      } else {
        await updateResidentialMutation({
          variables: {
            residentialAddress: {
              line1: residentialAddress.line1,
              line2: residentialAddress.line2 || undefined,
              city: residentialAddress.city,
              postalCode: residentialAddress.postalCode,
              state: residentialAddress.state,
              country: country, // required because react hook form wont set values for disabled fields
            },
          },
        });
      }
      if (afterSubmit) {
        afterSubmit(true);
      }
    } catch (err) {
      notificationService.show({
        type: 'error',
        message: formatMessage(
          {
            defaultMessage:
              '{hasError, select, true {{errorMessage}} other {Unable to update residential address}}',
          },
          { errorMessage: err?.message, hasError: !!err?.message },
        ),
      });
      if (afterSubmit) {
        afterSubmit(false);
      }
    }
  });

  const [toggleSimplifiedForm, setToggleSimplifiedForm] = useState(true);

  if (loading) {
    return (
      <div className="flex justify-center py-5">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <form onSubmit={submit} autoComplete="off">
      {shippingAddress?.line1 && (
        <Checkbox
          showBox
          isChecked={useShippingAddress}
          label={formatMessage({
            defaultMessage: 'Same as delivery address',
          })}
          onChange={({ target }) => setUseShippingAddress(target.checked)}
        />
      )}
      <div className="space-y-4 mt-4 mb-2">
        <div className="col-span-1 md:col-span-2">
          <IntlAddressInput
            className="mt-3"
            name="residentialAddress"
            registerField={register}
            errors={errors.residentialAddress ?? {}}
            useAutoComplete
            onChange={onShippingAddressChanged}
            showSimplifiedForm={toggleSimplifiedForm}
            disabled={useShippingAddress}
          />
          {fullResidentialAddress && (
            <Typography size="paragraph">{fullResidentialAddress}</Typography>
          )}
        </div>
        {validateAddressAsShipping &&
          canValidatePostcode &&
          !isShippingAddressWithinRange && (
            <ShippingValidationStatus
              isValidatingShippingAddress={isValidatingShippingAddress}
              isShippingAddressWithinRange={isShippingAddressWithinRange}
              isShippingAddressCompleted={canValidatePostcode}
              isResidential
            />
          )}
      </div>

      <div className="space-y-4">
        <Button
          isFullWidth
          isSubmit
          isLoading={updateLoading}
          palette={getPrimaryButtonPalette(config.brand)}
        >
          {submitButtonText}
        </Button>
        {toggleSimplifiedForm && (
          <Button
            isFullWidth
            palette={getSecondaryButtonPalette(config.brand)}
            level="secondary"
            isDisabled={updateLoading}
            onClick={(): void => setToggleSimplifiedForm(false)}
          >
            <FormattedMessage
              description="Label for entering address manually"
              defaultMessage="Enter manually"
            />
          </Button>
        )}
      </div>
    </form>
  );
};
