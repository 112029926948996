import { Button as ChakraButton } from '@chakra-ui/react';
import {
  UIInteractionElementType,
  UIInteractionInteraction,
  useEventServiceDirectContext,
} from '@customer-frontend/events';
import { ListButtonProps } from '../types';

/**
 * List buttons are primarily for the quiz, where selecting a radio button is an unnecessary extra step.
 */
export const ListButton: React.FC<ListButtonProps> = ({
  children,
  isDisabled = false,
  onClick = (): void => {
    // noop
  },
  isSubmit,
  eventElementName,
  eventElementAttributes,
  ...buttonProps
}) => {
  const eventService = useEventServiceDirectContext();

  const handleClick: ListButtonProps['onClick'] = (e) => {
    const currentPageUrl = window.location.toString();

    onClick(e);

    if (eventElementName && eventService) {
      eventService.uiInteractionTracked({
        ...eventElementAttributes,
        elementName: eventElementName,
        elementType: UIInteractionElementType.LIST_BUTTON,
        interaction: UIInteractionInteraction.CLICKED,
        pageUrl: currentPageUrl,
      });
    }
  };

  return (
    <ChakraButton
      colorScheme="brand"
      type={isSubmit ? 'submit' : 'button'}
      variant="list-button"
      isDisabled={isDisabled}
      onClick={handleClick}
      isFullWidth
      h="initial" // Ignore sizes config
      px="initial" // Ignore sizes config
      {...buttonProps}
    >
      {/*
          We wrap strings in a span tag as Chrome translations 
          cause DOM mutation which causes it to be out of sync 
          with React's VDOM. 
          
          More details: https://github.com/chakra-ui/chakra-ui/issues/8238
       */}
      {typeof children === 'string' ? <span>{children}</span> : children}
    </ChakraButton>
  );
};
