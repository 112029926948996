import React from 'react';
import { Toast, ToastProps } from '@eucalyptusvc/design-system';

const hash =
  (n1: ToastProps) =>
  (n2: ToastProps): boolean =>
    n1.type + n1.message === n2.type + n2.message;

export const Notifications = ({
  messages,
}: {
  messages: ToastProps[];
}): JSX.Element => {
  const uniqueMessages = messages.reduce(
    (existing, current) =>
      existing.some(hash(current)) ? existing : existing.concat(current),
    [] as ToastProps[],
  );

  return (
    <div className="fixed w-full flex flex-col justify-center items-center left-0 z-50 pointer-events-none space-y-4 bottom-0 mb-4">
      {uniqueMessages.map((props: ToastProps) => (
        <Toast key={props.message} {...props} />
      ))}
    </div>
  );
};
