import React from 'react';
import { NavTabList, NavTab } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';
import { NavTabVariants } from '@eucalyptusvc/design-system/src/theme/shared';

export type ConsultationNotesTabsProps = {
  routes: {
    doctorsNote: string;
    pharmacyInformation: string;
  };
  variant?: NavTabVariants;
};

export const ConsultationNotesTabs: React.FC<ConsultationNotesTabsProps> = ({
  routes,
  variant = 'border-around',
}) => {
  return (
    <NavTabList variant={variant}>
      <NavTab to={routes.doctorsNote} variant={variant}>
        <FormattedMessage
          defaultMessage="Practitioner letter"
          description="Tab title for viewing letter from practitioner"
        />
      </NavTab>

      <NavTab to={routes.pharmacyInformation} variant={variant}>
        <FormattedMessage
          defaultMessage="Pharmacy information"
          description="Tab title for viewing pharmacy information"
        />
      </NavTab>
    </NavTabList>
  );
};
