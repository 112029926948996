import { SystemStyleObject } from '@chakra-ui/react';

interface SpinnerThemeProps {
  color: string;
}

export const getSpinnerComponent = (
  props: SpinnerThemeProps,
): SystemStyleObject => ({
  Spinner: {
    variants: {
      default: {
        color: props.color,
      },
      alternate: {
        color: 'white',
      },
    },
  },
});
