import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  useRequiredValidation,
  useValidateIntlMobileNumber,
} from '@customer-frontend/utils';
import { TextInput } from '@eucalyptusvc/design-system';

type IntlMobileNumberInputProps = {
  register: UseFormMethods<{ phone: string }>['register'];
  errorMessage?: string;
  countryCodes: string[];
};

export const IntlMobileNumberInput = ({
  register,
  errorMessage,
  countryCodes,
}: IntlMobileNumberInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const phoneLabel = formatMessage({
    defaultMessage: 'Mobile number',
    description: 'Label for mobile number attribute within details form',
  });
  const phoneValidation = {
    ...useRequiredValidation(phoneLabel),
    ...useValidateIntlMobileNumber(countryCodes),
  };

  return (
    <TextInput
      ref={register(phoneValidation)}
      label={phoneLabel}
      name="phone"
      errorMessage={errorMessage}
    />
  );
};
