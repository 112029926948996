/**
 * decodes a base64 representation of a unicode string back to unicode
 *
 * @note this should be preferred over "atob" as "atob" is limited to single byte/ASCII characters
 */
export function base64ToUnicode(base64String: string): string {
  const binString = atob(base64String);
  const bytes = new Uint8Array(
    binString.split('').map((s, i) => {
      const r = s.codePointAt(0);
      if (typeof r === 'undefined') {
        throw new Error(
          `expected to find code point at index: ${i} for string: "${binString}"`,
        );
      }
      return r;
    }),
  );
  return new TextDecoder().decode(bytes);
}

/**
 * encodes a string containing any unicode character to base64
 *
 * @note this should be preferred over "btoa" as "btoa" is limited to single byte/ASCII characters
 */
export function unicodeToBase64(utf8String: string): string {
  const bytes = new TextEncoder().encode(utf8String);
  const str = bytes.reduce((str, byte) => str + String.fromCodePoint(byte), '');
  return btoa(str);
}
