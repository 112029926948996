import { ReactElement } from 'react';
import { ConsultationQuestionAttachment } from '@customer-frontend/graphql-types';
import { useDoctorVideoEvents } from './use-doctor-video-events';

export const MediaAttachment = ({
  content,
  consultationId,
  consultationQuestionId,
}: {
  content: ConsultationQuestionAttachment;
  consultationId: string;
  consultationQuestionId?: string;
}): ReactElement | null => {
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#structure_of_a_mime_type
  const [type] = content.mimetype.split('/');

  const { playedEvent, pausedEvent, completedEvent } = useDoctorVideoEvents({
    resourceId: content.id,
    filename: content.filename,
    consultationId,
    consultationQuestionId,
  });

  if (type === 'video' && content.signedUrl) {
    // TODO: w-48 wont work for landscape videos. No better way of presenting these just yet
    return (
      <video
        controls
        src={`${content.signedUrl}#t=0.1`}
        className="w-48 rounded-lg"
        preload="metadata"
        onPlay={playedEvent}
        onPause={pausedEvent}
        onEnded={completedEvent}
      />
    );
  }

  // Show nothing to user if not supported.
  return null;
};
