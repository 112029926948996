import { Document } from '@contentful/rich-text-types';
import { EducationReading } from '@customer-frontend/graphql-types';
import { useIntl } from 'react-intl';

export const convertBodyRichTextToString = (
  bodyRichText?: Document,
): string | undefined => {
  return bodyRichText?.content
    .map((content) =>
      content.content
        .map((content) => (content as { value?: string })?.value)
        .join(' '),
    )
    .join(' ');
};

export const calculateTotalReadingTime = (
  readings: EducationReading[],
): number => {
  return readings.reduce(
    (partialSum, reading) => partialSum + (reading?.timeToReadMs || 0),
    0,
  );
};

export const convertMsToMinutes = (ms: number): number => {
  return Math.ceil(ms / 60000);
};

// Still used by software (non-internationalized brands)
export const formatTimeToRead = (minutes: number): string => {
  return `${minutes} min${minutes !== 1 ? 's' : ''}`;
};

export const useFormatLocalizedTimeToReadFn = (): ((
  minutes: number,
) => string) => {
  const { formatMessage } = useIntl();

  return (minutes: number) =>
    formatMessage(
      {
        defaultMessage: '{minutes} {minutes, plural, one {min} other {mins}}',
      },
      {
        minutes,
      },
    );
};
