import { useBreakpoint } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { Breakpoint, ResponsiveContextValue } from './types';

const isMobileUA =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|Mobile|Opera Mini/i.test(
    navigator?.userAgent ?? '',
  );

const defaultForUA: Breakpoint = isMobileUA ? 'sm' : 'md';

const defaultValue: ResponsiveContextValue = {
  breakpoint: defaultForUA,
  isMobile: isMobileUA,
  isDesktop: !isMobileUA,
};

const ResponsiveContext = React.createContext(defaultValue);

export const ResponsiveProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const breakpoint = useBreakpoint(defaultForUA) as Breakpoint;
  const isMobile = ['sm', 'base'].includes(breakpoint);

  const context = useMemo<ResponsiveContextValue>(
    () => ({
      breakpoint,
      isMobile,
      isDesktop: !isMobile,
    }),
    [breakpoint, isMobile],
  );

  return (
    <ResponsiveContext.Provider value={context}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export const useResponsive = (): ResponsiveContextValue =>
  useContext(ResponsiveContext);
