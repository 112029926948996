import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { TextInput } from '@eucalyptusvc/design-system';
import {
  combineRules,
  useRequiredValidation,
  useValidMaskedDate,
  useMaxMaskedDateExclusive,
  useMinMaskedDateInclusive,
} from '@customer-frontend/utils';
import { useIntl } from 'react-intl';

const YOUNGEST_BIRTH_YEAR = new Date();
YOUNGEST_BIRTH_YEAR.setFullYear(new Date().getFullYear() - 14);

export function DobFormInput({
  name,
  register,
  placeholder,
  errorMessage,
}: {
  name: string;
  register: UseFormMethods['register'];
  placeholder?: string;
  errorMessage: string | undefined;
}): React.ReactElement {
  const { formatMessage } = useIntl();

  const birthdayLabel = formatMessage({
    defaultMessage: 'Date of birth',
    description: 'Label for date field to enter date of birth',
  });
  const birthdayValidation = combineRules(
    useRequiredValidation(birthdayLabel),
    useValidMaskedDate(),
    useMaxMaskedDateExclusive(YOUNGEST_BIRTH_YEAR),
    useMinMaskedDateInclusive(new Date('1900-01-01')),
  );

  return (
    <TextInput
      name={name}
      label={birthdayLabel}
      type="masked-date"
      ref={register(birthdayValidation)}
      placeholder={placeholder}
      errorMessage={errorMessage}
    />
  );
}
