import { gql } from '@apollo/client';
import {
  Maybe,
  OfferingVerticalFragment,
} from '@customer-frontend/graphql-types';
import { Modal, Typography } from '@eucalyptusvc/design-system';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as InfoCircle } from '../assets/info-circle.svg';
import clsx from 'clsx';
import { appBlurbMessages } from './app-blurb';

type OfferingVerticalTopSectionProps = {
  offering: OfferingVerticalFragment;
  isUpsell: boolean;
  pricingDelta?: Maybe<string>;
  imgClassName: string;
};

export const OfferingVerticalTopSection = (
  props: OfferingVerticalTopSectionProps,
): React.ReactElement => (
  <div className="bg-white flex flex-col">
    <img
      src={props.offering.photoUrl}
      className={clsx('object-cover', props.imgClassName)}
    />
    <div className="p-4 md:p-6 flex flex-col gap-2 justify-between h-full">
      <div className="flex flex-col gap-2">
        <div className="text-primary-600">
          <Typography size="md" isBold inheritColor>
            {props.offering.advertisedName}
          </Typography>
        </div>
        <Typography size="medium-paragraph">
          {props.offering.description}
        </Typography>
      </div>
    </div>
  </div>
);

const Ul = ({ children }: { children: React.ReactNode }) => (
  <ul className="flex flex-col gap-4 pl-2">{children}</ul>
);

const Li = ({ children }: { children: React.ReactNode }) => (
  <li className="flex flex-row gap-2 items-start">
    <div className="text-status-success-500">
      <Check width={14} className="pt-1" fill="currentColor" />
    </div>
    <div className="text-neutral-black">{children}</div>
  </li>
);

const InfoModalButton = (props: { onClick: () => void }) => (
  <button onClick={props.onClick} className="text-neutral-700 p-0.5 ml-1">
    <InfoCircle
      width={14}
      height={14}
      fill="currentColor"
      className="-mb-0.5"
    />
  </button>
);

type OfferingVerticalBottomSectionProps = {
  offering: OfferingVerticalFragment;
};

export const OfferingVerticalBottomSection = (
  props: OfferingVerticalBottomSectionProps,
): React.ReactElement => {
  const intl = useIntl();
  const appBlurbContentItems = props.offering.tags.includes('show-app-blurb')
    ? [
        {
          title: intl.formatMessage(appBlurbMessages.support.title),
          description: intl.formatMessage(appBlurbMessages.support.description),
        },
        {
          title: intl.formatMessage(appBlurbMessages.tools.title),
          description: intl.formatMessage(appBlurbMessages.tools.description),
        },
        {
          title: intl.formatMessage(appBlurbMessages.content.title),
          description: intl.formatMessage(appBlurbMessages.content.description),
        },
      ]
    : [];

  const [modalContent, setModalContent] = useState<{
    title: string;
    description: string;
  }>();

  return (
    <div className="p-4 md:p-6 bg-white h-full w-full flex flex-col gap-4">
      {props.offering.contents.length > 0 && (
        <div className="flex flex-col gap-4 text-neutral-700">
          <Typography size="paragraph" isBold inheritColor>
            <FormattedMessage defaultMessage="Included in this plan" />
          </Typography>
          <Ul>
            {props.offering.contents.map((c) => (
              <Li key={c.id}>
                <Typography size="paragraph" element="span" inheritColor>
                  <span>{c.description}</span>
                  {c.tooltip && (
                    <InfoModalButton
                      onClick={() => {
                        if (!c.tooltip) {
                          return;
                        }

                        setModalContent({
                          title: c.description,
                          description: c.tooltip,
                        });
                      }}
                    />
                  )}
                </Typography>
              </Li>
            ))}
          </Ul>
          <Modal
            size="sm"
            isOpen={modalContent !== undefined}
            onClose={() => setModalContent(undefined)}
            title={
              <Typography size="medium-paragraph" isBold>
                {modalContent?.title}
              </Typography>
            }
          >
            <Typography size="paragraph">
              {modalContent?.description}
            </Typography>
          </Modal>
        </div>
      )}
      {appBlurbContentItems.length > 0 && (
        <div className="flex flex-col gap-4 text-neutral-700">
          <Typography size="paragraph" isBold inheritColor>
            <FormattedMessage defaultMessage="Provided for every plan" />
          </Typography>
          <Ul>
            {appBlurbContentItems.map((item) => (
              <Li key={item.title}>
                <Typography size="paragraph" element="span" inheritColor>
                  <span>{item.title}</span>
                  {item.description && (
                    <InfoModalButton
                      onClick={() => {
                        if (!item.description) {
                          return;
                        }

                        setModalContent({
                          title: item.title,
                          description: item.description,
                        });
                      }}
                    />
                  )}
                </Typography>
              </Li>
            ))}
          </Ul>
        </div>
      )}
    </div>
  );
};

export const offeringVerticalFragment = gql`
  fragment OfferingVertical on Offering {
    id
    photoUrl
    advertisedName
    advertisedStartingAmount
    description
    firstOrderGiftCopy
    contents {
      id
      description
      tooltip
      rank
    }
    tags
    sequenceSets {
      id
      sequences {
        id
        tags
      }
    }
  }
`;
