import React from 'react';
import Cards from './assets/cards.png';
import { LegitScriptSeal, ZipLogo } from '@customer-frontend/order';
import { useIntl } from 'react-intl';

export const PaymentIcons = ({
  renderSeal = true,
  renderZip = false,
  renderCards = true,
}: {
  renderSeal?: boolean;
  renderZip?: boolean;
  renderCards?: boolean;
}): React.ReactElement => {
  const { formatMessage } = useIntl();
  const onlyOneActive =
    Number(renderSeal) + Number(renderZip) + Number(renderCards) === 1;
  return (
    <div
      className={
        !onlyOneActive
          ? 'flex justify-between items-center'
          : 'flex justify-end items-center'
      }
    >
      {renderZip && <ZipLogo />}
      {renderCards && (
        <div className="w-24 h-auto">
          <img
            src={Cards}
            alt={formatMessage({
              description:
                'Alt text for explaining the available card types that can be used to process payment',
              defaultMessage: 'Accepted card types: Visa, Mastercard, Amex',
            })}
          />
        </div>
      )}
      {renderSeal && <LegitScriptSeal />}
    </div>
  );
};
