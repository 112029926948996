import { gql } from '@apollo/client';
import { experimentPaymentPlanFragment } from '../fragments/experimentPaymentPlan';

export const changeExperimentPaymentPlan = gql`
  mutation ChangeExperimentPaymentPlan(
    $treatmentId: String!
    $paymentPlan: ExperimentPaymentPlanVariant!
    $version: Int
  ) {
    changeExperimentPaymentPlan(
      treatmentId: $treatmentId
      paymentPlan: $paymentPlan
      version: $version
    ) {
      id
      experimentPaymentPlan {
        ...ExperimentPaymentPlan
      }
    }
  }
  ${experimentPaymentPlanFragment}
`;
