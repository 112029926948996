import { gql } from '@apollo/client';

export const updateShippingAddressDocument = gql`
  mutation UpdateShippingAddress($address: AddressCreateWithoutUserInput!) {
    updateShipping(address: $address) {
      id
      address {
        id
        line1
        line2
        city
        postalCode
        state
        country
        building
        company
      }
    }
  }
`;
