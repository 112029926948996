import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { MutationPayConsultationArgs } from '@customer-frontend/graphql-types';

const payConsultationMutation = gql`
  mutation PayConsultationPayment(
    $id: String!
    $couponCode: String
    $token: String
    $gateway: PaymentGateway
    $zipCheckoutId: String
    $stripePaymentMethodId: String
  ) {
    payConsultation(
      id: $id
      couponCode: $couponCode
      token: $token
      gateway: $gateway
      zipCheckoutId: $zipCheckoutId
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      id
    }
  }
`;

export function usePayConsultation(
  options?: MutationHookOptions<Response, MutationPayConsultationArgs>,
): MutationTuple<Response, MutationPayConsultationArgs> {
  return useMutation<Response, MutationPayConsultationArgs>(
    payConsultationMutation,
    options,
  );
}
