import { Avatar as ChakraAvatar } from '@chakra-ui/react';
import { AvatarPalette, AvatarProps } from './types';
import { useThemeExtension } from '../../theme/shared';

/** Used wherever we want to help identify a user, e.g. patients or doctors. */
export const Avatar = ({
  firstName = '',
  lastName = '',
  src,
  palette,
  size = 'md',
  isSquare = false,
}: AvatarProps): JSX.Element => {
  const name = `${firstName} ${lastName}`.trim();

  const colorProfile = useThemeExtension<AvatarPalette>(
    'avatar.defaultStyle',
    'default',
  );
  const variant = palette || colorProfile;

  return (
    <ChakraAvatar
      name={name}
      src={src}
      size={size}
      variant={variant}
      borderRadius={isSquare ? '4px' : '50%'}
    />
  );
};
