import {
  User,
  MutationUpdateProfileArgs,
} from '@customer-frontend/graphql-types';
import {
  gql,
  MutationTuple,
  useMutation,
  MutationHookOptions,
} from '@apollo/client';
import { userInfoFragment } from '../../graphql';

interface Response {
  updateProfile: User;
}

const updateProfileDocument = gql`
  mutation UseUpdateProfile(
    $firstName: String
    $lastName: String
    $phone: String
    $password: String
    $address: AddressCreateWithoutUserInput
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password: $password
      address: $address
    ) {
      ...UserInfo
    }
  }
  ${userInfoFragment}
`;

export function useUpdateProfileMutation(
  options?: MutationHookOptions<Response, MutationUpdateProfileArgs>,
): MutationTuple<Response, MutationUpdateProfileArgs> {
  return useMutation<Response, MutationUpdateProfileArgs>(
    updateProfileDocument,
    options,
  );
}
