import { gql } from '@apollo/client';
import type {
  ActiveReminderPatientQuestionsFragment,
  ActiveReminderRemindersFragment,
  Maybe,
} from '@customer-frontend/graphql-types';

export function activeReminder<
  T extends ActiveReminderRemindersFragment,
>(consultation?: {
  reminders?: Maybe<T[]>;
  patientQuestions?: Maybe<ActiveReminderPatientQuestionsFragment[]>;
}): T | null {
  if (consultation?.reminders) {
    const reminder = consultation.reminders
      .filter((reminder) => !reminder.canceled)
      .reduce<T | null>(
        (a, b) => (a && a.scheduledAt > b.scheduledAt ? a : b),
        null,
      );

    if (reminder?.doneAt) {
      const doneTime = new Date(reminder.doneAt).getTime();
      const hasProgressed =
        consultation.patientQuestions?.some(
          (message) => new Date(message.createdAt).getTime() > doneTime,
        ) ?? false;

      return hasProgressed ? null : reminder;
    } else if (reminder === undefined) {
      return null;
    } else {
      return reminder;
    }
  }
  return null;
}

activeReminder.fragment = gql`
  fragment ActiveReminderReminders on ConsultationReminder {
    id
    canceled
    doneAt
    scheduledAt
  }

  fragment ActiveReminderPatientQuestions on ConsultationPatientQuestion {
    id
    createdAt
  }
`;
