import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';
import {
  BuildReadingRouteParams,
  ModuleAccordionItem,
  NextReadingHeroCard,
} from '@customer-frontend/education';
import { getConfig } from '@customer-frontend/config';
import { useAddUserToSegmentIfNotAlreadyIn } from '@customer-frontend/services';
import { FormattedMessage } from 'react-intl';
import { useWeightProgramName } from '@customer-frontend/utils';
import { gql, useQuery } from '@apollo/client';
import {
  EducationProgramQuery,
  EducationProgramQueryVariables,
} from '@customer-frontend/graphql-types';

interface ProgramPageTemplateProps {
  programId: string;
  buildReadingRoute: (
    readingId: string,
    params?: BuildReadingRouteParams,
  ) => string;
}

export const ProgramPageTemplate: FC<ProgramPageTemplateProps> = ({
  programId,
  buildReadingRoute,
}) => {
  const config = getConfig();
  const history = useHistory();
  const { data, loading } = useQuery<
    EducationProgramQuery,
    EducationProgramQueryVariables
  >(
    gql`
      query EducationProgram($id: ID!) {
        educationProgram(id: $id) {
          id
          name
          nextUnreadReading {
            id
            content
            startedAt
            readStatus
            timeToReadMs
          }
          modules {
            id
            name
            description
            readings {
              id
              content
              readStatus
              timeToReadMs
            }
          }
        }
      }
    `,
    {
      variables: {
        id: programId,
      },
    },
  );
  useAddUserToSegmentIfNotAlreadyIn(
    'WEIGHT_DASHBOARD_LEARN_TAB_ACTION_NOT_REQUIRED',
  );

  const educationModules = data?.educationProgram?.modules;
  const nextUnreadReading = data?.educationProgram?.nextUnreadReading;
  const programName = useWeightProgramName();

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  const handleItemClick = (readingId: string): void => {
    history.push(
      buildReadingRoute(readingId, {
        programId,
      }),
    );
  };

  if (!educationModules?.length) {
    return null;
  }

  return (
    <div className="space-y-6 md:space-y-10">
      {nextUnreadReading && (
        <NextReadingHeroCard
          reading={nextUnreadReading}
          onReadingClick={handleItemClick}
        />
      )}
      <div className="space-y-4">
        <div className="space-y-4">
          <Typography
            size={config.brand === 'pilot' ? 'lg' : 'md'}
            element="h2"
            isBold
          >
            <FormattedMessage defaultMessage="All readings" />
          </Typography>
          <Typography size="medium-paragraph">
            {nextUnreadReading ? (
              <FormattedMessage
                defaultMessage="Reading one a day will help you make the most out of your {programName} program. You can opt to skip ahead or revisit completed readings."
                values={{ programName }}
              />
            ) : (
              <FormattedMessage defaultMessage="Congratulations on completing all your readings. Stay tuned for more." />
            )}
          </Typography>
        </div>
        <div className="space-y-4">
          {educationModules.map((module) => (
            <ModuleAccordionItem
              key={module.id}
              module={module}
              onReadingClick={handleItemClick}
              openByDefault={module.readings?.some(
                (reading) => reading.id === nextUnreadReading?.id,
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
