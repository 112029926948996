import { useAuth } from '@customer-frontend/auth';
import { getConfig } from '@customer-frontend/config';
import {
  MedicareCollectionForm,
  MedicareEnabled,
  MedicareFormOnPage,
} from '@customer-frontend/healthcare-collection';
import { getSecondaryButtonPalette } from '@customer-frontend/quiz';
import {
  useBackButtonBehaviour,
  useConsultation,
} from '@customer-frontend/services';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ConsultationConfirmationPageView } from './view';

interface ConsultationConfirmationPage {
  MainIcon: React.FC;
  isConsultFree: boolean;
  onClick: () => void;
  profilePath: string;
  children?: React.ReactNode;
}

export const ConsultationConfirmationPage: React.FC<
  ConsultationConfirmationPage
> = ({ MainIcon, onClick, isConsultFree, children, profilePath }) => {
  useBackButtonBehaviour(null);
  const config = getConfig();
  const { consultationId } = useParams<{ consultationId: string }>();
  const { data: consultationData } = useConsultation(consultationId);
  const { loggedInUser } = useAuth();

  return (
    <ConsultationConfirmationPageView
      MainIcon={MainIcon}
      onCtaClick={onClick}
      isConsultFree={isConsultFree}
      email={loggedInUser?.email}
      buttonPalette={getSecondaryButtonPalette(config.brand)}
      profilePath={profilePath}
    >
      {children}
      <MedicareEnabled problemType={consultationData?.consultation?.type}>
        <MedicareCollectionForm
          validationLevel="all"
          submitButtonText="Save"
          bodySize="medium-paragraph"
          onPage={MedicareFormOnPage.consultPaid}
          problemType={consultationData?.consultation?.type}
        />
      </MedicareEnabled>
    </ConsultationConfirmationPageView>
  );
};
