import { ValidateResult, RegisterOptions } from 'react-hook-form';
import { isMatch, parse, isBefore, isAfter, isSameDay, format } from 'date-fns';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { Maybe } from '@customer-frontend/graphql-types';
import { getConfig } from '@customer-frontend/config';
import { useIntl } from 'react-intl';

// Leaving this as a function to avoid issues with circular dependencies.
const getMaskeDateFormat = (): string => getConfig().dateConfig.format;

const phoneUtil = PhoneNumberUtil.getInstance();

const validateNotWhitespaceOnly =
  (errorMessage: string) =>
  (value: string): ValidateResult =>
    typeof value === 'string' ? value.trim().length > 0 || errorMessage : true;

export const validEmailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * @deprecated please use `useEmailValidation` to support translated strings
 */
export const emailIsValid = {
  pattern: {
    value: validEmailRegex,
    message: 'Please enter a valid email',
  },
};
export const useEmailValidation = (): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    pattern: {
      value: validEmailRegex,
      message: formatMessage({
        defaultMessage: 'Please enter a valid email',
        description:
          'Error message prompting user their email is invalid & to enter a valid one',
      }),
    },
  };
};

export function mustBe<TValue>(value: Maybe<TValue>): value is TValue {
  return value !== null && value !== undefined;
}

export const mustBeTrue = (errorMessage: string): RegisterOptions => {
  return {
    validate: {
      mustBeTrue: (value?: boolean): true | string => {
        if (value === true) {
          return true;
        }
        return errorMessage;
      },
    },
  };
};

export const matchesValue = (
  compVal: string,
  { name, message }: { name: string; message: string },
): RegisterOptions => {
  return {
    validate: {
      [name]: (value: string): boolean | string => {
        return value === compVal || message;
      },
    },
  };
};

/**
 * @deprecated please use `useRequiredValidation` to support translated strings
 */
export const requiredValidation = (fieldName: string): RegisterOptions => ({
  // https://github.com/react-hook-form/react-hook-form/issues/1650#issuecomment-630485265
  // As per maintainer of react-hook-form, for suggested means of validating a value is not just whitespace
  validate: validateNotWhitespaceOnly(
    `${fieldName.charAt(0).toUpperCase() + fieldName.substring(1)} is required`,
  ),
  required: `${
    fieldName.charAt(0).toUpperCase() + fieldName.substring(1)
  } is required`,
});
export const useRequiredValidation = (fieldName: string): RegisterOptions => {
  // https://github.com/react-hook-form/react-hook-form/issues/1650#issuecomment-630485265
  // As per maintainer of react-hook-form, for suggested means of validating a value is not just whitespace
  const { formatMessage } = useIntl();

  const errorMessage = formatMessage(
    {
      defaultMessage: '{field} is required',
      description:
        'Error message informing user the input field is a required field',
    },
    {
      field: fieldName,
    },
  );

  return {
    validate: validateNotWhitespaceOnly(errorMessage),
    required: errorMessage,
  };
};

/**
 * @deprecated please use `useMinLengthValidation` to support translated strings
 */
export const minLengthValidation = (
  fieldName: string,
  length: number,
): RegisterOptions => ({
  minLength: {
    value: length,
    message: `${fieldName} must be at least ${length} characters`,
  },
});
export const useMinLengthValidation = (
  fieldName: string,
  length: number,
): RegisterOptions => {
  const { formatMessage } = useIntl();
  return {
    minLength: {
      value: length,
      message: formatMessage(
        {
          defaultMessage: '{formValue} must be at least {length} characters',
          description:
            'Error message informing users the text theyve provided is too short in length',
        },
        {
          formValue: fieldName,
          length,
        },
      ),
    },
  };
};

/**
 * @deprecated please use `useMaxLengthValidation` to support translated strings
 */
export const maxLengthValidation = (
  fieldName: string,
  length: number,
): RegisterOptions => ({
  maxLength: {
    value: length,
    message: `${fieldName} must be at most ${length} characters`,
  },
});
export const useMaxLengthValidation = (
  fieldName: string,
  length: number,
): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    maxLength: {
      value: length,
      message: formatMessage(
        {
          defaultMessage: '{formValue} must be at most ${length} characters',
          description:
            'Error message informing users the text theyve provided is too long in length',
        },
        {
          formValue: fieldName,
          length,
        },
      ),
    },
  };
};

/**
 * @deprecated please use `useValidMaskedDate` to support translated strings
 */
export const validMaskedDate = (): RegisterOptions => ({
  validate: {
    validMaskedDate: (value: string): boolean | string => {
      return (
        isMatch(value, getMaskeDateFormat()) ||
        `Please enter a valid date in format ${getMaskeDateFormat().toLocaleUpperCase()}`
      );
    },
  },
});
export const useValidMaskedDate = (): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    validate: {
      validMaskedDate: (value: string): boolean | string => {
        return (
          isMatch(value, getMaskeDateFormat()) ||
          formatMessage(
            {
              defaultMessage: 'Please enter a valid date in format {format}',
              description:
                'Error message prompting users the format of the date theyve provided is incorrect',
            },
            { format: getMaskeDateFormat().toLocaleUpperCase() },
          )
        );
      },
    },
  };
};

/**
 * @deprecated please use `useMaxMaskedDateExclusive` to support translated strings
 */
export const maxMaskedDateExclusive = (maxDate: Date): RegisterOptions => ({
  validate: {
    maxMaskedDateExclusive: (value: string): boolean | string => {
      if (!isMatch(value, getMaskeDateFormat())) {
        // Ignore the validation when input is not a valid date
        return true;
      }
      return (
        isBefore(parse(value, getMaskeDateFormat(), new Date()), maxDate) ||
        `Please enter a date before ${format(maxDate, getMaskeDateFormat())}`
      );
    },
  },
});
export const useMaxMaskedDateExclusive = (maxDate: Date): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    validate: {
      maxMaskedDateExclusive: (value: string): boolean | string => {
        if (!isMatch(value, getMaskeDateFormat())) {
          // Ignore the validation when input is not a valid date
          return true;
        }
        return (
          isBefore(parse(value, getMaskeDateFormat(), new Date()), maxDate) ||
          formatMessage(
            {
              defaultMessage: 'Please enter a date before {beforeDate}',
              description:
                'Error message prompting users to input a date before a given date',
            },
            {
              beforeDate: format(maxDate, getMaskeDateFormat()),
            },
          )
        );
      },
    },
  };
};

/**
 * @deprecated please use `useValidNumber` to support translated strings
 */
export const validNumber = (): RegisterOptions => {
  return {
    validate: {
      validNumber: (value: string): boolean | string => {
        return !Number.isNaN(value) || `Value must be a valid number`;
      },
    },
  };
};
export const useValidNumber = (): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    validate: {
      validNumber: (value: string): boolean | string => {
        return (
          !Number.isNaN(value) ||
          formatMessage({
            defaultMessage: 'Value must be a valid number',
            description:
              'Error message informing users their input must be a number',
          })
        );
      },
    },
  };
};

/**
 * @deprecated please use `useMinNumberValue` to support translated strings
 */
export const minNumberValue = (min: number): RegisterOptions => {
  return {
    validate: {
      minNumberValue: (value: string): boolean | string => {
        return Number(value) >= min || `Value must be greater than ${min}`;
      },
    },
  };
};
export const useMinNumberValue = (min: number): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    validate: {
      minNumberValue: (value: string): boolean | string => {
        return (
          Number(value) >= min ||
          formatMessage(
            {
              defaultMessage: 'Value must be greater than {minValue}',
              description:
                'Error message informing users the number theyve provided must be greater than a given value',
            },
            { minValue: min },
          )
        );
      },
    },
  };
};

/**
 * @deprecated please use `useMaxMaskedDateInclusive` to support translated strings
 */
export const maxMaskedDateInclusive = (maxDate: Date): RegisterOptions => ({
  validate: {
    maxMaskedDateInclusive: (value: string): boolean | string => {
      if (!isMatch(value, getMaskeDateFormat())) {
        // Ignore the validation when input is not a valid date
        return true;
      }
      const date = parse(value, getMaskeDateFormat(), new Date());
      return (
        isBefore(date, maxDate) ||
        isSameDay(date, maxDate) ||
        `Please enter a date on or before ${format(
          maxDate,
          getMaskeDateFormat(),
        )}`
      );
    },
  },
});
export const useMaxMaskedDateInclusive = (maxDate: Date): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    validate: {
      maxMaskedDateInclusive: (value: string): boolean | string => {
        if (!isMatch(value, getMaskeDateFormat())) {
          // Ignore the validation when input is not a valid date
          return true;
        }
        const date = parse(value, getMaskeDateFormat(), new Date());
        return (
          isBefore(date, maxDate) ||
          isSameDay(date, maxDate) ||
          formatMessage(
            {
              defaultMessage: 'Please enter a date on or before {date}',

              description:
                'Error message prompting users to input a date before (or on) a given date',
            },
            {
              date: format(maxDate, getMaskeDateFormat()),
            },
          )
        );
      },
    },
  };
};

/**
 * @deprecated please use `useMinMaskedDateInclusive` to support translated strings
 */
export const minMaskedDateInclusive = (minDate: Date): RegisterOptions => ({
  validate: {
    minMaskedDateInclusive: (value: string): boolean | string => {
      if (!isMatch(value, getMaskeDateFormat())) {
        // Ignore the validation when input is not a valid date
        return true;
      }
      const date = parse(value, getMaskeDateFormat(), new Date());
      return (
        isAfter(date, minDate) ||
        isSameDay(date, minDate) ||
        `Please enter a date on or after ${format(
          minDate,
          getMaskeDateFormat(),
        )}`
      );
    },
  },
});

export const useMinMaskedDateInclusive = (minDate: Date): RegisterOptions => {
  const { formatMessage } = useIntl();

  return {
    validate: {
      minMaskedDateInclusive: (value: string): boolean | string => {
        if (!isMatch(value, getMaskeDateFormat())) {
          // Ignore the validation when input is not a valid date
          return true;
        }
        const date = parse(value, getMaskeDateFormat(), new Date());
        return (
          isAfter(date, minDate) ||
          isSameDay(date, minDate) ||
          formatMessage(
            {
              defaultMessage: 'Please enter a date on or after {date}',
              description:
                'Error message prompting users to input a date after a given date',
            },
            {
              date: format(minDate, getMaskeDateFormat()),
            },
          )
        );
      },
    },
  };
};

export const medicareNumberValidationLogic = (
  medicareNumber: string,
): string | boolean => {
  medicareNumber = medicareNumber.replace(/\s/g, '');
  const multipliers = [1, 3, 7, 9, 1, 3, 7, 9];
  if (medicareNumber.length !== 10) {
    return 'Medicare number has to be 10 digits';
  }
  const medicareNumbers = medicareNumber
    .split('')
    .map((number) => parseInt(number));
  if (medicareNumbers[0] < 2 || medicareNumbers[0] > 6) {
    return 'Medicare number has to start with digits in the range 2 - 6';
  }
  if (medicareNumbers[9] === 0) {
    return 'Issue number has to be bigger than 0';
  }
  const first8 = medicareNumbers.slice(0, 8);
  const first8Sum = first8.reduce((currentSum, number, index) => {
    return currentSum + number * multipliers[index];
  }, 0);
  const remainder = first8Sum % 10;
  if (medicareNumbers[8] !== remainder) {
    return 'Medicare number format is invalid. Please try again.';
  }
  return true;
};

export const medicareNumberValidation = (): RegisterOptions => {
  return {
    validate: {
      medicareNumberValidation: medicareNumberValidationLogic,
    },
  };
};

export const medicareIrnValidationLogic = (
  medicareIrn: number,
): string | boolean => {
  if (medicareIrn < 1 || medicareIrn > 9) {
    return 'Medicare IRN has to be between 1 and 9';
  }
  return true;
};

export const medicareIrnValidation = (): RegisterOptions => {
  return {
    validate: {
      medicareIrnValidation: medicareIrnValidationLogic,
    },
  };
};

export const medicareCardExpiryValidationLogic = (
  medicareCardExpiry: string,
): string | boolean => {
  const splitMedicareCardExpiry = medicareCardExpiry.split('/');
  if (splitMedicareCardExpiry.length !== 2) {
    return 'Please ensure the Medicare card expiry value is in a correct format';
  }
  const [validToMonthStr, validToYearStr] = splitMedicareCardExpiry;
  const validToMonth = Number(validToMonthStr);
  const validToYear = Number(validToYearStr);
  const minExpYear = new Date().getFullYear();
  const maxExpYear = minExpYear + 10;
  if (Number.isNaN(validToMonth) || Number.isNaN(validToYear)) {
    return 'Medicare expiry value is incorrect';
  }
  if (
    validToMonth < 0 ||
    validToMonth > 12 ||
    validToYear < minExpYear ||
    validToYear > maxExpYear
  ) {
    return 'Medicare expiry value is incorrect';
  }
  return true;
};

export const medicareCardExpiryValidation = (): RegisterOptions => {
  return {
    validate: {
      medicareCardExpiryValidation: medicareCardExpiryValidationLogic,
    },
  };
};

export const combineRules = (
  ...rules: Maybe<RegisterOptions>[]
): RegisterOptions =>
  rules.filter(mustBe).reduce((result, current) => {
    return {
      ...result,
      ...current,
      validate: {
        ...result.validate,
        ...current.validate,
      },
    };
  }, {});

export const capitaliseString = (string: string): string =>
  string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase();

export const validateUkMobileNumber = (): RegisterOptions => {
  return {
    validate: {
      validateUkMobileNumber: (v): boolean | string => {
        const validUkPhoneRegex = /^(\+447|07)[0-9]{9}$/;
        const stripped = v.replace(/\s+/g, '');
        return (
          validUkPhoneRegex.test(stripped) || 'Please use a valid mobile number'
        );
      },
    },
  };
};

const validateIntlMobileNumber = (
  number: string,
  countryCode: string,
): boolean => {
  try {
    const parsedNumber = phoneUtil.parse(number, countryCode);
    const isValidForCountry = phoneUtil.isValidNumberForRegion(
      parsedNumber,
      countryCode,
    );
    const isMobileNumber =
      phoneUtil.getNumberType(parsedNumber) === PhoneNumberType.MOBILE;

    return isValidForCountry && isMobileNumber;
  } catch (err) {
    return false;
  }
};

export const validateIntlMobileNumberAgainstCountryCodes = (
  number: string,
  countryCodes: string[],
): boolean => {
  for (const countryCode of countryCodes) {
    if (validateIntlMobileNumber(number, countryCode)) {
      return true;
    }
  }
  return false;
};

export const useValidateIntlMobileNumber = (
  countryCodes: string[],
): RegisterOptions => {
  const { formatMessage } = useIntl();

  const errorMessage = formatMessage({
    defaultMessage: 'Please use a valid mobile number',
    description:
      'Error message informing users that their mobile number is invalid & to enter a valid format',
  });

  return {
    validate: {
      validateIntlMobileNumber: (v): boolean | string => {
        for (const countryCode of countryCodes) {
          if (validateIntlMobileNumber(v, countryCode)) {
            return true;
          }
        }
        return errorMessage;
      },
    },
  };
};
