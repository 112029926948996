import { gql } from '@apollo/client';

export const shoppingCart = gql`
  fragment ShoppingCart on OtcShoppingCart {
    id
    items {
      id
      quantity
      variant {
        id
        name
        price
        product {
          id
          name
          photo {
            id
            url
          }
        }
        inventory {
          id
          sku
        }
      }
    }
  }
`;
