import './styles.css';

const ANIMATION_DELAY_MS = 500;

export const ToastTimer = ({
  duration,
}: {
  duration: number;
}): React.ReactElement => {
  return (
    <div
      style={{
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        animation: `slideAnimation linear`,
        animationFillMode: 'forwards', // persists the final state of animation
        animationDuration: `${duration - ANIMATION_DELAY_MS}ms`,
        animationDelay: `${ANIMATION_DELAY_MS}ms`,
      }}
    />
  );
};
