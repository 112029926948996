import { useTitle as useTitleLib } from 'react-use';
import { getConfig } from '@customer-frontend/config';
import { capitaliseString } from '@customer-frontend/utils';

export const useTitle = (title?: string): void => {
  const { brand } = getConfig();
  const brandTitle = capitaliseString(brand);
  const fullTitle = title ? `${title} | ${brandTitle}` : brandTitle;
  useTitleLib(fullTitle, { restoreOnUnmount: true });
};
