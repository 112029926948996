import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import {
  ConfirmOrderDocument,
  ConfirmOrderMutation,
  ConfirmOrderMutationHookResult,
  ConfirmOrderMutationVariables,
} from '@customer-frontend/graphql-types';

export const confirmOrderMutationDocument = gql`
  mutation ConfirmOrder(
    $consultationId: String!
    $firstName: String
    $lastName: String
    $address: AddressCreateWithoutUserInput
    $phone: String
    $token: String
    $gateway: PaymentGateway
    $zipCheckoutId: String
    $couponCode: String
    $stripePaymentMethodId: String
  ) {
    confirmOrder(
      consultationId: $consultationId
      firstName: $firstName
      lastName: $lastName
      address: $address
      phone: $phone
      token: $token
      gateway: $gateway
      stripePaymentMethodId: $stripePaymentMethodId
      couponCode: $couponCode
      zipCheckoutId: $zipCheckoutId
    ) {
      id
    }
  }
`;

export function useConfirmOrder(
  options?: MutationHookOptions<
    ConfirmOrderMutation,
    ConfirmOrderMutationVariables
  >,
): ConfirmOrderMutationHookResult {
  return useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(
    ConfirmOrderDocument,
    options,
  );
}
