import { Card, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';

interface ProcessSectionCardProps {
  number: number;
  imgSrc: string;
  imgAlt?: string;
  title: string;
  content: string;
}

const ProcessSectionCard: React.FC<ProcessSectionCardProps> = ({
  number,
  imgSrc,
  imgAlt,
  title,
  content,
}) => {
  return (
    <Card size="sm">
      <div className="flex">
        <div className="w-6 h-6 mr-2 bg-primary-500 rounded-full flex items-center justify-center">
          <Typography color="white" size="paragraph" isBold>
            {number}
          </Typography>
        </div>
        <Typography isBold size="xs">
          {title}
        </Typography>
      </div>
      <div className="mt-4 flex items-center">
        <div className="mr-4 h-20 w-20">
          <img className="rounded" src={imgSrc} alt={imgAlt} />
        </div>
        <div className="flex-1">
          <Typography size="paragraph">{content}</Typography>
        </div>
      </div>
    </Card>
  );
};

export type ContentItem = {
  title: string;
  description: string;
  image: string;
  imageAlt?: string;
};

export const ProcessContent = ({
  isSyncExperience,
  content,
}: {
  isSyncExperience: boolean;
  content: ContentItem[];
}): React.ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div>
      {isSyncExperience ? (
        <div className="mb-6">
          <Typography isBold size="lg" textAlign="center">
            <FormattedMessage defaultMessage="How your consult will work" />
          </Typography>
        </div>
      ) : (
        <div className="mb-6">
          <div className="mb-2">
            <Typography isBold size="lg" textAlign="center">
              {formatMessage({
                defaultMessage:
                  "Let's take the first step on your journey with us",
              })}
            </Typography>
          </div>
          <Typography size="medium-paragraph" textAlign="center" color="black">
            {formatMessage({ defaultMessage: "Here's what to expect:" })}
          </Typography>
        </div>
      )}
      <div className="space-y-2 mb-4">
        {content.map((contentItem, i) => (
          <ProcessSectionCard
            key={contentItem.title}
            number={i + 1}
            imgSrc={contentItem.image}
            imgAlt={contentItem.imageAlt}
            title={contentItem.title}
            content={contentItem.description}
          />
        ))}
      </div>
    </div>
  );
};
