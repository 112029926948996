import { gql, useQuery } from '@apollo/client';
import { getConfig } from '@customer-frontend/config';
import {
  PharmacyInformationPageTemplateQuery,
  PharmacyInformationPageTemplateQueryVariables,
} from '@customer-frontend/graphql-types';
import { EscriptsConsent } from '@customer-frontend/order';
import { useHistoryGoBackBehaviour } from '@customer-frontend/services';
import {
  AccordionPalette,
  Button,
  ButtonPalette,
  Card,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { PrescriberQuestionForm } from './prescriber-question-form';
import { FaqPanel, SafetyInfo } from '@customer-frontend/consultation';
import { ConsultationNotesTabs } from '../notes-tabs';
import { useTitle } from '@customer-frontend/utils';

export const PharmacySafetyInformationPage = ({
  consultationId,
  routes,
  palette,
}: {
  consultationId: string;
  routes: {
    profile: string;
    plan: string;
    doctorsNote: string;
    pharmacySafetyInformation: string;
  };
  palette: {
    backButton: ButtonPalette;
    faqAccordion: AccordionPalette;
  };
}): React.ReactElement => {
  useHistoryGoBackBehaviour();
  const history = useHistory();
  const config = getConfig();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Pharmacy Safety Information',
      description: 'Page title for the Pharmacy Safety Information page',
    }),
  );

  const resp = useQuery<
    PharmacyInformationPageTemplateQuery,
    PharmacyInformationPageTemplateQueryVariables
  >(
    gql`
      fragment PharmacyInformationPageProduct on Product {
        id
        plan {
          id
          interval
          intervalCount
        }
        variants {
          id
          isRefill
          price
          inventory {
            id
            sku
          }
        }
        photo {
          id
          url
        }
        cmi {
          id
          url
        }
        faqs {
          id
          title
          markdown
          videoEmbedUrl
        }
        name
        shortDescription
        safetyInformation
      }

      query PharmacyInformationPageTemplate($id: String!) {
        consultation(id: $id) {
          id
          customer {
            id
            firstName
            healthCareDetails {
              id
              hasIndividualHealthcareIdentifier
            }
          }
          treatment {
            id
            status
            weightTreatmentCategory
            product {
              ...PharmacyInformationPageProduct
            }
          }
        }
      }
    `,
    {
      variables: {
        id: consultationId,
      },
    },
  );

  const consultation = resp.data?.consultation;
  const customer = consultation?.customer;
  const treatment = consultation?.treatment;

  if (resp.loading || !resp.data) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (!consultation || !treatment?.id) {
    return <Redirect to={routes.profile} />;
  }

  if (
    treatment?.status &&
    !['CREATED', 'FOLLOW_UP', 'REVIEW'].includes(treatment.status)
  ) {
    return <Redirect to={routes.profile} />;
  }

  return (
    <div className="space-y-6">
      <ConsultationNotesTabs
        routes={{
          doctorsNote: routes.doctorsNote,
          pharmacyInformation: routes.pharmacySafetyInformation,
        }}
        variant="border-around"
      />

      <Typography isBold size="lg" element="h2">
        <FormattedMessage
          defaultMessage="A note from your pharmacy"
          description="Heading for pharmacy safety information note"
        />
      </Typography>

      <Card>
        <Typography isBold size="md">
          <FormattedMessage defaultMessage="Important safety information" />
        </Typography>
        <Divider variant="separator" mt="xs" />
        <div className="space-y-6">
          <SafetyInfo
            safetyInformation={treatment.product.safetyInformation}
            cmiUrl={treatment.product.cmi?.url}
          />
          {!!customer?.healthCareDetails?.hasIndividualHealthcareIdentifier && (
            <EscriptsConsent />
          )}
        </div>
      </Card>

      {config.clinicianMessagingSupported && (
        <Card>
          <PrescriberQuestionForm treatment={treatment} />
        </Card>
      )}

      <Button
        isFullWidth
        onClick={() => history.push(routes.plan)}
        palette={palette.backButton}
      >
        <FormattedMessage
          defaultMessage="Back to treatment plan"
          description="Button text indicating back to the treatment plan page"
        />
      </Button>

      <Typography size="lg" isBold>
        <FormattedMessage
          defaultMessage="FAQs"
          description="Acronym describing frequently asked questions"
        />
      </Typography>

      <FaqPanel
        accordionPalette={palette.faqAccordion}
        faqs={treatment.product?.faqs ?? []}
      />
    </div>
  );
};
