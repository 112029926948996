import React from 'react';
import { Button, Modal, Typography } from '@eucalyptusvc/design-system';
import { FormattedMessage } from 'react-intl';

interface NoDeliveryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReturnToProfile: () => void;
}

export const NoDeliveryModal = ({
  isOpen,
  onClose,
  onReturnToProfile,
}: NoDeliveryModalProps): React.ReactElement => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography size="lg" isBold>
          <FormattedMessage defaultMessage="It's not you, it's us!" />
        </Typography>
      }
    >
      <Typography size="paragraph">
        <FormattedMessage defaultMessage="Unfortunately we can't deliver to your address yet. But, we're working hard to expand our delivery network. We'll get in touch when we expand to your area" />
      </Typography>
      <div className="mt-4 flex justify-center">
        <Button level="secondary" onClick={onReturnToProfile}>
          <FormattedMessage defaultMessage="← Back to profile" />
        </Button>
      </div>
    </Modal>
  );
};
