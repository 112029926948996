import { SystemStyleObject } from '@chakra-ui/react';
import { space } from '../space';

type CardThemeProps = {
  colors: {
    font: string;
    backgroundAlternate: string;
    backgroundTertiary?: string;
  };
  border: {
    style?: string;
    borderColor?: string;
    borderColorAlternate?: string;
    borderColorTertiary?: string;
    borderRadius: string;
  };
  boxShadow?: string;
};

export const getCardStyles = (props: CardThemeProps): SystemStyleObject => ({
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: props.border.borderRadius,
    color: props.colors.font,
    border: props.border.style,
    borderColor: props.border.borderColor,
    boxShadow: props.boxShadow,
  },
  variants: {
    default: {
      bg: 'white',
    },
    alternate: {
      bg: props.colors.backgroundAlternate,
      borderColor:
        props.border.borderColorAlternate ?? props.border.borderColor,
    },
    tertiary: {
      bg: props.colors.backgroundTertiary ?? 'white',
      borderColor: props.border.borderColorTertiary ?? props.border.borderColor,
    },
  },
  sizes: {
    '2xs': {
      padding: space['2xs'],
    },
    xs: {
      padding: space.xs,
    },
    sm: {
      padding: space.sm,
    },
    md: {
      padding: space.md,
    },
    lg: {
      padding: space.lg,
    },
    xl: {
      padding: space.xl,
    },
    '2xl': {
      padding: space['2xl'],
    },
    '3xl': {
      padding: space['3xl'],
    },
    '4xl': {
      padding: space['4xl'],
    },
  },
});
