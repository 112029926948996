import { FormattedMessage } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import {
  Maybe,
  InitialAttributesQuery,
} from '@customer-frontend/graphql-types';
import {
  convertAndFormatWeight,
  convertHeight,
} from '@customer-frontend/utils';
import { Divider, Typography } from '@eucalyptusvc/design-system';
import moment from 'moment';
import {
  getQuizAnswerByShortcode,
  getQuizAnswersByShortcode,
} from '../../utils/get-quiz-answers';

export const InitialAttributes: React.FC<{
  responses?: Maybe<
    {
      question?: { shortcode?: string | null } | null;
      response?: { answers?: { value: string }[] | null } | null;
    }[]
  >;
}> = ({ responses }) => {
  const { data } = useQuery<InitialAttributesQuery>(
    gql`
      query InitialAttributes {
        profile {
          id
          fullName
          preferredUnitSystem
        }
      }
    `,
  );
  const profile = data?.profile;

  const unformattedDob = getQuizAnswerByShortcode(responses, 'dob');
  const unformattedHeight = getQuizAnswerByShortcode(responses, 'height');
  const unformattedWeight = getQuizAnswerByShortcode(responses, 'weight');
  const ethnicity = getQuizAnswersByShortcode(responses, 'ethnicity');
  const sex = getQuizAnswerByShortcode(responses, 'sex');
  const phone = getQuizAnswerByShortcode(responses, 'phone');

  const unformattedPregnancy =
    getQuizAnswerByShortcode(responses, 'pregnant') ?? 'None of the above';
  const formattedPregnancy: string | null =
    unformattedPregnancy === 'None of the above' ? null : unformattedPregnancy;

  return (
    <div className="text-neutral-900 space-y-2">
      {profile?.fullName && (
        <div className="capitalize">
          <Typography size="large-paragraph" isBold>
            {profile.fullName}
          </Typography>
          <Divider variant="separator" my="sm" />
        </div>
      )}
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2 text-neutral-900">
        {unformattedDob && (
          <li>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="<strong>Age:</strong> {age}"
                values={{
                  age: moment().diff(
                    moment(unformattedDob).startOf('d'),
                    'year',
                  ),
                  strong: (chunk) => <strong>{chunk}</strong>,
                }}
              />
            </Typography>
          </li>
        )}
        {unformattedHeight && (
          <li>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="<strong>Height:</strong> {height}"
                values={{
                  height: convertHeight(
                    unformattedHeight,
                    profile?.preferredUnitSystem,
                  ),
                  strong: (chunk) => <strong>{chunk}</strong>,
                }}
              />
            </Typography>
          </li>
        )}
        {sex && (
          <li>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="<strong>Sex at birth:</strong> {sex}"
                values={{
                  sex,
                  strong: (chunk) => <strong>{chunk}</strong>,
                }}
              />
            </Typography>
          </li>
        )}
        {unformattedWeight && (
          <li>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="<strong>Weight:</strong> {weight}"
                values={{
                  weight: convertAndFormatWeight(
                    unformattedWeight,
                    profile?.preferredUnitSystem,
                  ),
                  strong: (chunk) => <strong>{chunk}</strong>,
                }}
              />
            </Typography>
          </li>
        )}
        {phone && (
          <li>
            <Typography size="medium-paragraph">
              <FormattedMessage
                defaultMessage="<strong>Phone:</strong> {phone}"
                values={{
                  phone,
                  strong: (chunk) => <strong>{chunk}</strong>,
                }}
              />
            </Typography>
          </li>
        )}
        {formattedPregnancy && (
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="<strong>Pregnancy Status:</strong> {formattedPregnancy}"
              values={{
                formattedPregnancy,
                strong: (chunk) => <strong>{chunk}</strong>,
              }}
            />
          </Typography>
        )}
        {ethnicity?.length && (
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="<strong>Ethnicity:</strong> {ethnicity}"
              values={{
                ethnicity: ethnicity.map((e) => e.value).join(', '),
                strong: (chunk) => <strong>{chunk}</strong>,
              }}
            />
          </Typography>
        )}
      </ul>
    </div>
  );
};
