import { Typography } from '@eucalyptusvc/design-system';

export const PageHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}): JSX.Element => {
  return (
    <div className="mb-8 text-center space-y-2">
      <Typography size="lg" isBold>
        {title}
      </Typography>
      <Typography size="paragraph">{description}</Typography>
    </div>
  );
};
