import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MarkdownProps } from './types';
import { Header, Link, List, Paragraph, Image, ListItem } from './components';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { useThemeExtension } from '../../theme/shared';
import { Divider } from '../dividers';

/** The **Markdown** component accepts
 * [Markdown](https://www.markdownguide.org/getting-started/) content as a
 * string, converting the short-hand syntax to other design system components
 * e.g. Typography.
 *
 * Text, headings, images, links, and lists are currently supported. Other
 * Markdown features such as tables and quotes are not yet implemented.
 *
 * [Markdown cheat-sheet](https://www.markdownguide.org/cheat-sheet/)
 */
export function Markdown({
  src,
  formatWhitespace,
  inheritColor = true,
  linkColor: linkColorProp,
  linkTarget = 'self',
  textSize,
}: MarkdownProps): React.ReactElement {
  const linkColorConfig = useThemeExtension<string>(
    'markdown.linkColor',
    'inherit',
  );
  const linkColor = linkColorProp ?? linkColorConfig;

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      includeElementIndex
      components={{
        img: Image,
        code: (props): React.ReactElement => (
          <Paragraph
            {...props}
            formatWhitespace={!!formatWhitespace}
            inheritColor={inheritColor}
            textSize={textSize}
          />
        ),
        p: (props): React.ReactElement => (
          <Paragraph
            {...props}
            formatWhitespace={!!formatWhitespace}
            inheritColor={inheritColor}
            textSize={textSize}
          />
        ),
        h1: (props): React.ReactElement => (
          <Header {...props} size="lg" inheritColor={inheritColor} />
        ),
        h2: (props): React.ReactElement => (
          <Header {...props} size="md" inheritColor={inheritColor} />
        ),
        h3: (props): React.ReactElement => (
          <Header {...props} size="sm" inheritColor={inheritColor} />
        ),
        h4: (props): React.ReactElement => (
          <Header {...props} size="xs" inheritColor={inheritColor} />
        ),
        a: (props): React.ReactElement => (
          <Link linkColor={linkColor} {...props} linkTarget={linkTarget} />
        ),
        ul: (props): React.ReactElement => <List {...props} type="unordered" />,
        ol: (props): React.ReactElement => <List {...props} type="ordered" />,
        li: (props): React.ReactElement => <ListItem {...props} />,
        hr: (props): React.ReactElement => (
          <Divider {...props} variant="separator" />
        ),
      }}
    >
      {src}
    </ReactMarkdown>
  );
}
