import { type ReactElement } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Card,
  Divider,
  LoadingSpinner,
  Typography,
} from '@eucalyptusvc/design-system';
import { getConfig } from '@customer-frontend/config';
import { PrescriberType } from '@customer-frontend/doctor';
import {
  OfferingNotesQuery,
  OfferingNotesQueryVariables,
} from '@customer-frontend/graphql-types';
import { SafetyInfo } from '@customer-frontend/consultation';
import { DoctorLetter } from './doctor-letter';
import { useTitle } from '@customer-frontend/utils';

type OfferingNotesPageProps = {
  profileRoute: string;
};

export function OfferingNotesPage({
  profileRoute,
}: OfferingNotesPageProps): ReactElement {
  const config = getConfig();
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const { formatMessage } = useIntl();

  useTitle(
    formatMessage({
      defaultMessage: 'Clinical notes',
      description: 'Page title for the Offering clinical notes page',
    }),
  );

  const { data, loading } = useQuery<
    OfferingNotesQuery,
    OfferingNotesQueryVariables
  >(gql`
    query OfferingNotes {
      profile {
        id
        purchases {
          id
          contexts {
            id
            consultations {
              id
              ...OfferingDoctorLetterConsultation
            }
            sequence {
              id
              ... on PrescribableSequence {
                safetyInformation {
                  id
                  cmiCopyMarkdown
                  cmiUrl
                }
              }
            }
          }
        }
      }
    }

    ${DoctorLetter.fragment}
  `);

  const contexts = data?.profile?.purchases?.find(
    (p) => p.id === purchaseId,
  )?.contexts;
  const consultation = contexts?.find((c) => c.consultations?.length)
    ?.consultations?.[0];
  const prescribableContext = contexts?.find(
    (c) => c.sequence?.__typename === 'PrescribableSequence',
  );
  const prescribableSequence =
    prescribableContext?.sequence?.__typename === 'PrescribableSequence'
      ? prescribableContext?.sequence
      : undefined;
  const safetyInformation = prescribableSequence?.safetyInformation;

  if (loading) {
    return (
      <div className="flex justify-center p-5">
        <LoadingSpinner />
      </div>
    );
  }

  if (!consultation || !prescribableSequence) {
    return <Redirect to={profileRoute} />;
  }

  return (
    <article className="space-y-8">
      <section className="space-y-4">
        <Typography isBold size="lg">
          <FormattedMessage
            defaultMessage="Notes from your {isPrescriber, select, true {prescriber} other {practitioner}}"
            values={{
              isPrescriber: config.prescriberType === PrescriberType.PRESCRIBER,
            }}
          />
        </Typography>

        <DoctorLetter consultation={consultation} profileRoute={profileRoute} />
      </section>

      {safetyInformation && (
        <section className="space-y-4">
          <Typography isBold size="lg">
            <FormattedMessage defaultMessage="A note from your pharmacy" />
          </Typography>

          <Card>
            <section className="space-y-4">
              <Typography isBold size="md">
                <FormattedMessage defaultMessage="Important safety information" />
              </Typography>

              <Divider variant="separator" mt="xs" />

              <SafetyInfo
                safetyInformation={safetyInformation.cmiCopyMarkdown}
                cmiUrl={safetyInformation.cmiUrl}
              />
            </section>
          </Card>
        </section>
      )}
    </article>
  );
}
