import { gql, useMutation, MutationTuple } from '@apollo/client';
import {
  LoginMutation,
  LoginMutationVariables,
} from '@customer-frontend/graphql-types';

const loginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    auth: login(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        shortAddressableName
        fullName
        email
      }
    }
  }
`;

export function useLoginMutation(): MutationTuple<
  LoginMutation,
  LoginMutationVariables
> {
  return useMutation<LoginMutation, LoginMutationVariables>(loginDocument);
}
