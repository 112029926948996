import {
  CSSObject,
  ThemingProps,
  useStyleConfig,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils';
import { useResponsive } from '../../hooks';

export const useResponsiveStyleConfig = (
  themeKey: string,
  props: ThemingProps & Dict,
): CSSObject => {
  const { breakpoint } = useResponsive();
  const styles = useStyleConfig(themeKey, { breakpoint, ...props });

  return styles;
};

export const useResponsiveMultiStyleConfig = (
  themeKey: string,
  props: ThemingProps & Dict,
): Record<string, CSSObject> => {
  const { breakpoint } = useResponsive();
  const styles = useMultiStyleConfig(themeKey, { breakpoint, ...props });

  return styles;
};
