import { formatCentsToCurrency } from '@customer-frontend/order';
import { FormattedSubscriptionPlan } from './types';
import { getConfig } from '@customer-frontend/config';
import { mapBrandToAdaptersBrand } from '@customer-frontend/types';
import { formatDate } from '@eucalyptusvc/lib-localization';

const MONTHLY_1_CADENCE = 28; // candeces in days used in monto for billing subs
const MONTHLY_3_CADENCE = 87;

const getSupplyAmount = (subscriptionPlan: {
  interval: string;
  intervalCount: number;
}): string => {
  if (
    (subscriptionPlan.interval === 'day' &&
      subscriptionPlan.intervalCount === MONTHLY_1_CADENCE) ||
    subscriptionPlan.intervalCount === MONTHLY_3_CADENCE
  ) {
    const intervalCountInMonths = Math.round(
      subscriptionPlan.intervalCount / 30,
    );
    const intervalString = intervalCountInMonths === 1 ? 'month' : 'months';
    return `${intervalCountInMonths} ${intervalString} `;
  } else if (subscriptionPlan.intervalCount !== 1) {
    return `${subscriptionPlan.intervalCount} ${subscriptionPlan.interval}s`;
  } else {
    return subscriptionPlan.interval;
  }
};

export const getBillingDescription = (subscriptionPlan: {
  interval: string;
  intervalCount: number;
}): string => {
  return 'Billed every ' + getSupplyAmount(subscriptionPlan);
};

export function formatSubscriptionPlan(plan: {
  id: string;
  amount?: number | null;
  product?: {
    name: string;
    friendlyName?: string | null;
    photo?: {
      url: string;
    } | null;
  } | null;
  interval: string;
  intervalCount: number;
  status: string;
  currentPeriodEnd: string;
  cancelAt?: string | null;
}): FormattedSubscriptionPlan {
  const config = getConfig();

  return {
    id: plan.id,
    product: {
      price: plan.amount ? formatCentsToCurrency(plan.amount) : undefined,
      name:
        plan.product?.friendlyName ??
        plan.product?.name ??
        'Subscription Product',
      photo: plan.product?.photo
        ? {
            url: plan.product.photo.url,
          }
        : undefined,
    },
    billingDescription: getBillingDescription(plan),
    nextPaymentDate: formatDate(
      mapBrandToAdaptersBrand(config.brand),
      plan.currentPeriodEnd,
      { dateStyle: 'medium' },
    ),
    supplyAmount: getSupplyAmount(plan),
    isActive: plan.status !== 'canceled',
    cancelledAt:
      plan.status === 'canceled' && plan.cancelAt
        ? `Cancelled on ${formatDate(
            mapBrandToAdaptersBrand(config.brand),
            plan.cancelAt,
            { dateStyle: 'medium' },
          )}`
        : undefined,
  };
}
