import { SystemStyleObject } from '@chakra-ui/theme-tools';

interface BorderStates {
  default: string;
  hover: string;
  active: string;
  error: string;
}

interface ComponentTokens {
  textColor: string;
  focusColor: string;
  border: BorderStates;
  background: string;
  focusBackground: string;
  activeBackground?: string; // Required for software
}

const getListButtonVariantConfig = (
  props: ComponentTokens,
): SystemStyleObject => {
  return {
    borderRadius: '0.5rem', // 8px
    padding: '1rem', // 16px
    bg: props.background,
    fontWeight: 400,
    color: props.textColor,
    border: `1px solid ${props.border.default}`,
    _hover: {
      bg: props.focusBackground,
      border: `1px solid ${props.border.hover}`,
    },
    _active: {
      bg: props.activeBackground,
      border: `1px solid ${props.border.active}`,
      boxShadow: `0 0 0 0.188rem ${props.focusColor}`, // 3px
    },
    _focus: {
      bg: props.activeBackground,
      border: `1px solid ${props.border.active}`,
      boxShadow: `0 0 0 0.188rem ${props.focusColor}`, // 3px
    },
    _disabled: {
      opacity: 0.5,
      bg: `${props.background} !important`,
    },
  };
};

export const getListButtonVariants = (
  props: ComponentTokens,
): SystemStyleObject => ({
  'list-button': getListButtonVariantConfig(props),
});
