import { SystemStyleObject } from '@chakra-ui/react';

interface ColorOptions {
  default: string;
  alternate: string;
}

interface AvatarThemeProps {
  font: string;
  color: ColorOptions;
  bgColor: ColorOptions;
}

export const getAvatarComponent = (
  props: AvatarThemeProps,
): SystemStyleObject => ({
  Avatar: {
    baseStyle: {
      label: { fontFamily: props.font },
    },
    sizes: {
      xs: {
        container: {
          w: '1.75rem', // 28px
          h: '1.75rem', // 28px
        },
      },
      sm: {
        container: {
          w: '2rem', // 32px
          h: '2rem', // 32px
        },
      },
      md: {
        container: {
          w: '3rem', // 48px
          h: '3rem', // 48px
        },
      },
      lg: {
        container: {
          w: '4rem', // 64px
          h: '4rem', // 64px
        },
      },
    },
    variants: {
      default: {
        container: {
          bg: props.bgColor.default,
          color: props.color.default,
        },
        label: {
          color: props.color.default,
        },
      },
      alternate: {
        container: {
          bg: props.bgColor.alternate,
          color: props.color.alternate,
        },
        label: {
          color: props.color.alternate,
        },
      },
    },
  },
});
