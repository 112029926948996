import { colors } from './palette';
import { components } from './components';
import { BrandTheme } from '../types';

const theme: BrandTheme = {
  colors,
  components,
  extensions: {
    toast: {
      iconStyle: 'outline',
    },
    modal: {
      isTitleBold: true,
    },
    'image-upload': {
      iconStyle: 'thin',
      selectButtonStyle: 'secondary',
      selectButtonPalette: 'default',
    },
  },
};

export default theme;
