import { useConsultationFlowConfig } from '@customer-frontend/config';
import { ProblemType } from '@customer-frontend/graphql-types';
import { useEffect } from 'react';

export const useShowZendeskWidget = ({
  problemType,
  disabled,
}: {
  problemType?: ProblemType | null;
  disabled?: boolean;
}): void => {
  const consultationFlowConfig = useConsultationFlowConfig(problemType);

  useEffect(() => {
    if (disabled) {
      safeHideShow('hide');
      return;
    }

    if (consultationFlowConfig?.zendeskEnabled) {
      safeHideShow('show');
      return () => {
        safeHideShow('hide');
      };
    }
  }, [consultationFlowConfig?.zendeskEnabled, problemType, disabled]);
};

function safeHideShow(hideShow: 'hide' | 'show') {
  try {
    window?.zE?.('messenger', hideShow);
  } catch {
    // no op
  }
}
