import React from 'react';
import { Typography } from '@eucalyptusvc/design-system';
import {
  convertHeight,
  convertAndFormatWeight,
  formatISODateToLocale,
} from '@customer-frontend/utils';
import {
  Maybe,
  UnitSystem,
  QuizResponse,
  QuizQuestionType,
} from '@customer-frontend/graphql-types';
import { ImageResponse } from './image-response';
import { FormattedMessage } from 'react-intl';

const formatQuestionAnswer = (
  questionType: Maybe<QuizQuestionType>,
  answer: string,
  imageUrl: Maybe<string>,
  shortCode: Maybe<string>,
  preferredUnitSystem: Maybe<UnitSystem>,
): React.ReactElement => {
  let response = answer;

  if (questionType === 'IMAGEUPLOAD') {
    return <ImageResponse imageUrl={imageUrl} />;
  }

  if (questionType === 'DATE') {
    response = formatISODateToLocale(response);
  }

  if (shortCode === 'height') {
    response = convertHeight(response, preferredUnitSystem);
  } else if (shortCode === 'weight') {
    response = convertAndFormatWeight(response, preferredUnitSystem);
  }

  return (
    <Typography size="paragraph" element="p">
      {response}
    </Typography>
  );
};

export const getFormattedQuizResponse = (
  quizQuestion: QuizResponse,
  preferredUnitSystem: Maybe<UnitSystem>,
): React.ReactElement => {
  const answers = quizQuestion?.response?.answers;
  const answerText = answers?.map((answer) => answer.value).join(', ');

  if (!answerText) {
    return (
      <Typography size="paragraph" element="p">
        <FormattedMessage
          defaultMessage="No Answer"
          description="Fallback answer for quiz questions"
        />
      </Typography>
    );
  }

  // We only support one image upload per IMAGEUPLOAD question, hence why we grab the first answer
  const answerImageUrl = answers?.[0]?.imageUrl;

  const shortCode = quizQuestion?.question?.shortcode;

  return formatQuestionAnswer(
    quizQuestion?.question?.type,
    answerText,
    answerImageUrl,
    shortCode,
    preferredUnitSystem,
  );
};
