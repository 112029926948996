import React from 'react';
import { LoadingSpinner, Typography } from '@eucalyptusvc/design-system';

interface ConsultPayPageViewDeps {
  renderPaymentForm: () => React.ReactNode;
  title: string;
  description: string;
}

export interface ConsultPayPageViewProps {
  isLoading?: boolean;
  LayoutComponent?: React.FC<{ children: React.ReactNode }>;
  children?: React.ReactNode;
}

type DefaultLayoutProps = {
  children: React.ReactNode;
};

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  return <div className="max-w-xl mx-auto py-10 md:py-12 px-5">{children}</div>;
};

export const ConsultPayPageView: React.FC<
  ConsultPayPageViewProps & ConsultPayPageViewDeps
> = ({
  isLoading,
  renderPaymentForm,
  children,
  LayoutComponent = DefaultLayout,
  title,
  description,
}) => {
  return (
    <LayoutComponent>
      <Typography size="lg" isBold textAlign="center">
        {title}
      </Typography>
      <div className="mt-4">
        <Typography size="medium-paragraph" textAlign={'center'}>
          {description}
        </Typography>
      </div>
      {children}
      <div className="mt-8">
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          renderPaymentForm()
        )}
      </div>
    </LayoutComponent>
  );
};
