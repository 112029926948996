import {
  getAvatarComponent,
  getFormComponents,
  getInputComponents,
  getSpinnerComponent,
  getSelectStyles,
  getWithFallbacks,
  getToastComponent,
  getOptionComponents,
  getModalStyles,
  getCardStyles,
  getDividerStyles,
  getAccordionStyle,
  getDrawerStyles,
  getNavTabsComponent,
} from '../../shared';
import { colors } from '../palette';
import { button } from './button';
import { ImageUpload } from './image-upload';
import { heading, text } from './typography';

export const components = {
  Text: text,
  ImageUpload,
  Button: button,
  Heading: heading,
  Card: getCardStyles({
    colors: {
      font: colors.primary[600],
      backgroundAlternate: colors.secondary.cream[100],
      backgroundTertiary: colors.secondary.sky[100],
    },
    border: {
      borderRadius: '8px',
      borderColorTertiary: colors.secondary.sky[200],
    },
    boxShadow:
      '0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 0px 10px rgba(0, 0, 0, 0.1)',
  }),
  Divider: getDividerStyles({
    color: colors.primary[200],
  }),
  ...getOptionComponents({
    colors: {
      textColor: colors.primary[600],
      border: {
        default: colors.primary[300],
        focus: colors.primary[300],
        active: colors.primary[400],
      },
      background: colors.primary[100],
      focusColor: colors.primary[200],
      iconBackground: {
        checked: colors.primary[400],
        hover: colors.primary[100],
      },
      iconBorder: {
        default: colors.primary[400],
        focus: colors.primary[300],
        active: colors.primary[400],
      },
    },
  }),
  Drawer: getDrawerStyles({}),
  Accordion: getAccordionStyle({
    colors: {
      icon: colors.secondary.navy[300],
      text: colors.secondary.navy[300],
      border: colors.secondary.navy[100],
      focusColor: colors.primary[200],
    },
  }),
  Modal: getModalStyles({
    colors: {
      closeButtonColor: colors.primary[400],
      text: colors.primary[600],
    },
  }),
  ...getFormComponents({
    colors: {
      container: 'white',
    },
  }),
  ...getInputComponents({
    colors: {
      bg: 'white',
      text: colors.neutral[900],
      border: colors.primary[400],
      borderFocused: colors.primary[400],
      shadow: colors.primary[200],
    },
    font: getWithFallbacks('Inter'),
  }),
  ...getAvatarComponent({
    font: getWithFallbacks('Inter'),
    color: {
      default: colors.secondary.cream[100],
      alternate: colors.primary[400],
    },
    bgColor: {
      default: colors.primary[400],
      alternate: colors.secondary.cream[100],
    },
  }),
  ...getSpinnerComponent({ color: colors.primary[400] }),
  Select: getSelectStyles({
    colors: {
      text: colors.neutral[900],
      border: colors.primary[400],
      focus: colors.primary[200],
      icon: colors.primary[400],
    },
    borderRadius: '4px',
  }),
  ...getToastComponent({ borderRadius: '0.25rem' }),
  ...getNavTabsComponent({
    baseStyle: {
      fontSize: '1rem',
      fontWeight: '600',
    },
    borderAround: {
      borderColor: colors.neutral.white,
      color: colors.neutral.white,
      borderRadius: '0.25rem',
      _activeLink: {
        background: colors.neutral.white,
        color: colors.primary[600],
      },
    },
    borderBottom: {
      borderColor: colors.neutral.white,
      color: `${colors.neutral.white}B3`,
      _activeLink: {
        borderColor: colors.secondary.sky[300],
        color: colors.neutral.white,
      },
    },
  }),
};
