import { useControllableState } from '@chakra-ui/hooks';
import clsx from 'clsx';

type QuantityInputProps = {
  defaultValue?: number;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
};

export const QuantityInput = ({
  defaultValue = 1,
  value: valueProp,
  min = 1,
  max = Infinity,
  disabled,
  onChange,
}: QuantityInputProps): JSX.Element => {
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const handleDecrement = (): void => {
    if (value > min) {
      setValue(value - 1);
    }
  };

  const handleIncrement = (): void => {
    if (value < max) {
      setValue(value + 1);
    }
  };

  const isMinusButtonDisabled = value <= min;
  const isPlusButtonDisabled = value >= max;
  const disabledClass = 'text-gray-300';

  return (
    <div className="flex items-center rounded-full border border-primary-600 p-1 font-semibold max-w-max">
      <button
        className={clsx('w-7 h-7 text-xl', {
          [disabledClass]: isMinusButtonDisabled,
        })}
        disabled={isMinusButtonDisabled || disabled}
        onClick={handleDecrement}
      >
        {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
        -
      </button>
      <span className={clsx('w-5 text-sm text-center')}>{value}</span>
      <button
        className={clsx('w-7 h-7 text-xl', {
          [disabledClass]: isPlusButtonDisabled,
        })}
        disabled={isPlusButtonDisabled || disabled}
        onClick={handleIncrement}
      >
        {/* eslint-disable-line react/jsx-no-literals, formatjs/no-literal-string-in-jsx */}
        +
      </button>
    </div>
  );
};
